import {
  // getTheme,
  // Icon,
  IconButton,
  IIconProps,
  // ITheme,
  ITooltipHostStyles,
  // mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as React from "react";
import { useMemo, useState } from "react";
import { LimaActionIconItemDetail } from "../../../../limaComponents/LimaActionIcons/LimaActionIconItemDetail";

interface IActaListActionsProps {
  detailShow?: boolean;
  onDetailDisabled?: boolean;
  onDetailClickClb?: () => void;
  fovoriteShow?: boolean;
  isFavorite?: boolean;
  onFavoriteClickClb?: () => void;
  removeShow?: boolean;
  onRemoveClickClb?: () => void;
}

// const theme: ITheme = getTheme();
// const { palette, fonts } = theme;

// const classNames = mergeStyleSets({
//   chevron: {
//     alignSelf: "center",
//     marginLeft: 10,
//     color: palette.neutralTertiary,
//     fontSize: fonts.large.fontSize,
//     flexShrink: 0,
//   },
// });

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
const favoriteNotIcon: IIconProps = { iconName: "FavoriteStar" };
const favoriteAddIcon: IIconProps = { iconName: "AddFavorite" };
const favoriteYesIcon: IIconProps = { iconName: "FavoriteStarFill" };
const favoriteRemoveIcon: IIconProps = { iconName: "Unfavorite" };
// const removeIcon: IIconProps = { iconName: "PageRemove" };
const removeIcon: IIconProps = { iconName: "Clear" };
// const detailIcon: IIconProps = { iconName: "ChevronRightMed" };

const ActaListActions: React.FunctionComponent<IActaListActionsProps> = (props: IActaListActionsProps) => {
  const tooltipId = useId("tooltip");
  const favoriteId = useId("fav");
  const removeId = useId("rem");
  const [favoritHover, setFavoriteHover] = useState<boolean>(false);

  //-----------------------
  // REACTIONS
  //--------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const favoritFinalIcon = useMemo((): IIconProps => {
    if (props.isFavorite === undefined || props.isFavorite === false) {
      if (favoritHover === false) return favoriteNotIcon;
      else return favoriteAddIcon;
    } else {
      if (favoritHover === false) return favoriteYesIcon;
      else return favoriteRemoveIcon;
    }
  }, [props.isFavorite, favoritHover]);

  //-------------------
  // HANDLERS
  //-------------------

  const onDetailClick = () => {
    if (props.onDetailClickClb !== undefined) props.onDetailClickClb();
  };

  const onFavoriteClick = () => {
    if (props.onFavoriteClickClb !== undefined) props.onFavoriteClickClb();
  };

  const onRemoveClick = () => {
    if (props.onRemoveClickClb !== undefined) props.onRemoveClickClb();
  };
  //-------------------
  // functions
  //-------------------

  return (
    <>
      {(props.fovoriteShow === undefined || props.fovoriteShow === true) && (
        <TooltipHost
          content="Set or reset favorite"
          id={favoriteId}
          calloutProps={calloutProps}
          styles={hostStyles}
          setAriaDescribedBy={false}
        >
          <IconButton
            iconProps={favoritFinalIcon}
            aria-label="Favorite"
            onClick={onFavoriteClick}
            onMouseEnter={() => setFavoriteHover(true)}
            onMouseLeave={() => setFavoriteHover(false)}
          />
        </TooltipHost>
      )}
      {(props.removeShow === undefined || props.removeShow === true) && (
        <TooltipHost
          content="Remove from list"
          id={removeId}
          calloutProps={calloutProps}
          styles={hostStyles}
          setAriaDescribedBy={false}
        >
          <IconButton iconProps={removeIcon} aria-label="Remove" onClick={onRemoveClick} />
        </TooltipHost>
      )}
      {(props.detailShow === undefined || props.detailShow === true) && (
        <TooltipHost
          content="Continue to file options"
          id={tooltipId}
          calloutProps={calloutProps}
          styles={hostStyles}
          setAriaDescribedBy={false}
        >
          <LimaActionIconItemDetail onClick={onDetailClick} disabled={props.onDetailDisabled === true} />
          {/* <IconButton
            iconProps={detailIcon}
            aria-label="Detail show"
            disabled={props.onDetailDisabled === true}
            onClick={onDetailClick}
          /> */}
        </TooltipHost>
      )}
    </>
  );
};

export default ActaListActions;
