import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconDataItem = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <path
        d="M10.557 8.423C10.068 8.686 10 8.925 10 9c0 .075.068.314.557.577.46.247 1.145.423 1.943.423s1.484-.176 1.943-.423C14.932 9.314 15 9.075 15 9c0-.075-.068-.314-.557-.577C13.982 8.176 13.298 8 12.5 8s-1.484.176-1.943.423ZM15 13v-2.024c-.695.337-1.573.524-2.5.524s-1.805-.187-2.5-.524V13c0 .075.068.313.557.577.46.247 1.145.423 1.943.423s1.484-.176 1.943-.423c.489-.264.557-.502.557-.577Z"
        fill="#212121"
      />
      <path
        d="M4 4.5A2.5 2.5 0 0 1 6.5 2H18a2.5 2.5 0 0 1 2.5 2.5v14.25a.75.75 0 0 1-.75.75H5.5a1 1 0 0 0 1 1h13.25a.75.75 0 0 1 0 1.5H6.5A2.5 2.5 0 0 1 4 19.5v-15Zm5.846 2.602C9.16 7.472 8.5 8.11 8.5 9v4c0 .891.66 1.528 1.346 1.898.718.386 1.657.602 2.654.602.997 0 1.936-.216 2.654-.602.687-.37 1.346-1.007 1.346-1.898V9c0-.891-.66-1.528-1.346-1.898-.718-.386-1.657-.602-2.654-.602-.997 0-1.936.216-2.654.602Z"
        fill="#212121"
      />
    </svg>
  );
};
