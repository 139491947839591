import { Stack } from "office-ui-fabric-react";
// import { useEffect,useState} from "react";
// import { useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
// import limaStore from "../../../../store/limaStore";
import { TaskData } from "../../../../types/Task";
// import { ChooseXcopyTypV4 } from "../Tasks/components/ChooseXcopyTypeV4";
import ItemLinkingV4 from "../../Tasks/components/ItemLinkingV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IWorkingCopiesAddNewV4Props {
  setParamsCallBack?: (linkingTaskData: TaskData) => void;
  linkedData: TaskData;
}

export const WorkingCopiesAddNewV4: React.FunctionComponent<IWorkingCopiesAddNewV4Props> = (
  props: IWorkingCopiesAddNewV4Props
) => {
  // const [linkingData, setLinkingData] = useState<TaskData>({
  //   xcopytype: XCOPY_TYPE.FULLXCOPY,
  //   commentIds: [],
  //   paraIds: [],
  //   actaKey: limaStore.actaId,
  //   attachements: [],
  //   xcopyKey: "",
  // });
  const { t } = useTranslation();

  //------------------
  // reactions
  //------------------

  // // useEffect(() => {
  // // if (props.setParamsCallBack !== undefined) props.setParamsCallBack(linkingData);
  // // }, [linkingData]);
  // useEffect(() => {
  //   // const newTaskDate: TaskData = { ...linkingData, actaKey: limaStore.actaId };
  //   // setLinkingData(newTaskDate);
  //   setLinkingData();
  // });

  // useEffect(() => {
  //   if (props.setParamsCallBack !== undefined && linkingData !== null) {
  //     window.console.log("BBBBB", linkingData);
  //     props.setParamsCallBack(linkingData);
  //   }
  //   // setLinkingData(newTaskDate);
  //   // setParamsCallBack(newTaskDate);
  // }, [linkingData]);

  //-------------------
  // Handlers
  //-------------------

  // const onChangeCreateXcopy = (type: XCOPY_TYPE) => {
  //   setLinkingData({ ...linkingData, xcopytype: type });
  // };

  return (
    <Stack>
      <Stack.Item>{t("tpmain:component.linking.infoLbl")}</Stack.Item>
      {/* {linkingData !== null && ( */}
      <Stack.Item>
        <ItemLinkingV4
          itemData={props.linkedData}
          isItemOwner={true}
          onlyCreateXcopy={true}
          itemsLinkingCallBack={(newTaskDate: TaskData) => {
            window.console.log("AAAAAAAAAAAAAAA", newTaskDate);
            if (props.setParamsCallBack !== undefined && newTaskDate !== null) {
              window.console.log("BBBBB", newTaskDate);
              props.setParamsCallBack(newTaskDate);
            }
          }}
        />
      </Stack.Item>
      {/* )} */}
    </Stack>
  );
};
