import { List } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { actaListOK } from "../../../../../api/schema/getActaList";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import FileItemOpenList from "./FileItemOpenList";

interface LimaFileListProps {
  items: actaListOK[];
  remove?: (actaId: string, edgeId: string) => void;
}

const LimaFileList: FunctionComponent<LimaFileListProps> = ({ items, remove }: LimaFileListProps) => {
  const onRenderCell = (item: actaListOK): JSX.Element => {
    log.info(limaLogTag.LimaFileList, `onRenderCell: rendering item `, item);
    return <FileItemOpenList item={item} remove={remove} changeItem={onUpdateSingleItem} />;
  };
  const [listItems, setListItems] = useState<actaListOK[]>();

  useEffect(() => {
    setListItems(items);
  }, [items]);

  const onUpdateSingleItem = (changeItem: actaListOK) => {
    //console.log("Changeing single item ", changeItem);
    const newListItems: actaListOK[] = listItems.map((item: actaListOK) => {
      if (item.id == changeItem.id) return changeItem;
      return item;
    });
    setListItems(newListItems);
  };

  return <List items={listItems} onRenderCell={onRenderCell} />;
};

export default LimaFileList;
