/**
 * LINKING COMPONENT BASE COMPONENT THAT CAN LINK ITEM TO ANYTHING..
 *
 * @TODO REWRITE
 */

import {
  ActionButton,
  ChoiceGroup,
  IChoiceGroupOption,
  IIconProps,
  Label,
  Stack,
  StackItem,
  Toggle,
} from "@fluentui/react";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { reaction } from "mobx";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { WORDITEMTYPE as WORDITEMTYPE } from "../../../../../api/apilinks";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../../limaCommon/limaWordInteract";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../../store/limaStore";
import { TaskData, XCOPY_TYPE } from "../../../../../types/Task";
import { SelectedInWord } from "../../../../../types/wordObjects";
import {
  isTaskDataLinkedDocOpen,
  isTaskLinked,
  taskLinkedItems,
  taskLinkedTo,
} from "../../../../../limaCommon/limaTaskHelpers";
import { observer } from "mobx-react-lite";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";

interface TaskLinkingProps {
  taskData: TaskData;
  taskKey?: string;
  isTaskOwner?: boolean;
  onlyCreateXcopy?: boolean; // just for copy create
  enableAlwaysLinkParts?: boolean; // enable to link parts even not partCopy
  itemsLinkingCallBack: (newTaskDate: TaskData) => void;
}

const removeIcon: IIconProps = { iconName: "ReceiptReply" }; //ReceiptReply//RemoveContent
const removeAllIcon: IIconProps = { iconName: "ReceiptUndelivered" }; //ReceiptUndelivered//DependencyRemove

const addIcon: IIconProps = { iconName: "ReceiptForward" }; //ReceiptForward//RectangularClipping
const docLinkedIcon: IIconProps = { iconName: "PageLink" };

/**
 * Task linking component care about linking para na comments
 * enable add linkign and remove only for new object, or when acta is open an user is doc owner
 * or when xcopy is open and already linked
 * @param param0
 * @returns JSX copoment
 */
const CreateLinking: FunctionComponent<TaskLinkingProps> = ({
  taskData,
  taskKey,
  isTaskOwner,
  onlyCreateXcopy,
  enableAlwaysLinkParts,
  itemsLinkingCallBack, //TODO: cahnge to special item ...Origunaly only from task Data so taskDataReturn
}: TaskLinkingProps) => {
  const [selectedItems, setselectedItems] = useState<boolean>(false);
  const [enabledAddLinking, setEnabledAddLinking] = useState<boolean>(false); //IF document is open and not yet lined

  const [innerShowHighLighted, setInnerShowHightLighted] = useState<boolean>(false);
  // const [linkedItemsCount, setLinkedItemsCount] = useState<number>(
  //   taskData && taskData.paraIds && taskData.commentIds && taskData.paraIds.length + taskData.commentIds.length
  // );
  const [linkedItemsCount, setLinkedItemsCount] = useState<number>(0);
  const [xcopyType, setXcopyType] = useState<XCOPY_TYPE>(XCOPY_TYPE.NOXCOPY);
  const [docLinked, setDocLinked] = useState<boolean>(false);

  const [innerEnabledXcopyCreate, setInnerEnabledXcopyCreate] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const disposer1 = reaction(
      () => limaStore.selectedInWord,
      (selectedInWord) => {
        log.info(limaLogTag.TaskLinking, `UEF: reaction on change selection `, selectedInWord);
        if (taskData !== null && enabledAddLinking) {
          setselectedItems(limaStore.selectedInWord.length > 0 ? true : false);
        } else if (selectedItems) setselectedItems(false);
      }
    );

    const disposer2 = reaction(
      () => limaStore.docxIsLoading,
      (docxIsLoading) => {
        log.info(limaLogTag.TaskLinking, `UEF: reaction on change docxIsLoading`, docxIsLoading);
        if (docxIsLoading === false) checkDefaultSetup();
      }
    );

    log.info(limaLogTag.TaskLinking, `UEF: cehck enable linking `, taskData);

    if (taskData === null) {
      log.info(limaLogTag.TaskLinking, `UEF: reseting task data data===null `, taskData);
      itemsLinkingCallBack({
        ...taskData,
        actaKey: "",
        xcopyKey: "",
        commentIds: [],
        paraIds: [],
        xcopytype: XCOPY_TYPE.NOXCOPY,
      });
    }
    checkDefaultSetup();
    return function cleanup() {
      disposer1();
      disposer2();
    };
  }, []);

  useEffect(() => {
    log.info(limaLogTag.TaskLinking, "UEF: somethign changed", limaStore.xcopyId, taskKey, taskData);
    checkDefaultSetup();
  }, [limaStore.xcopyId, taskKey, taskData.xcopyKey, taskData.xcopytype]);

  /**
   * update highlihgt para in document
   * @param {boolean} checked optional if provided is highlighted defined by param, otherwise it get from state
   */
  const updateHightligts = (checked: boolean) => {
    const hightlightItems: SelectedInWord[] = [];
    taskData.paraIds.forEach((item: string) => hightlightItems.push({ type: WORDITEMTYPE.para, id: item }));
    taskData.commentIds.forEach((item: string) => hightlightItems.push({ type: WORDITEMTYPE.comm, id: item }));
    const highlight: HIGHLIGHT_TYPE =
      taskData.xcopytype == XCOPY_TYPE.TRIMXCOPY ? HIGHLIGHT_TYPE.TRIM : HIGHLIGHT_TYPE.HIGHLIGHT;
    if (checked === true) {
      limaStore.setLocationChangeCallBack(() => {
        void highlightTaskLinks3(hightlightItems, false, highlight);
      });
      return highlightTaskLinks3(hightlightItems, true, highlight);
    } else {
      limaStore.setLocationChangeCallBack(null);
      return highlightTaskLinks3(hightlightItems, false, highlight);
    }
  };

  //Predefined values based on current data
  const checkDefaultSetup = () => {
    log.info(limaLogTag.TaskLinking, "checkDoc: limaStore", limaStore, limaStore.openedDocType);
    log.info(limaLogTag.TaskLinking, "checkDoc: taskData", taskData);
    setEnabledAddLinking(false);

    // on create new xcopy from document - partial or full,
    if (onlyCreateXcopy !== undefined && onlyCreateXcopy == true) {
      // statuText = statuText + " - only create copy";
      setInnerEnabledXcopyCreate(true);
    }
    if (taskData.actaKey != null && taskData.actaKey != "") {
      setDocLinked(true);
    }
    setLinkedItemsCount(taskLinkedItems(taskData));
    log.info(limaLogTag.TaskLinking, "checkDoc: Finishw >>");
  };

  //------- INTERACT--------------
  //PARA LINKING
  const onAddParaLinking = () => {
    log.info(limaLogTag.TaskLinking, "onAddParaLinking: to link", limaStore.selectedInWord);

    let tempTaskData: TaskData = taskData;
    let update = false;

    if (!limaStore.actaIsOpen && !limaStore.xcopyIsOpen) {
      log.warn(limaLogTag.TaskLinking, "onAddParaLinking: no doc current open", limaStore);
      return;
    }

    //If undefined reset taskData
    if (!taskData) {
      tempTaskData = {
        actaKey: "",
        commentIds: [],
        paraIds: [],
        xcopyKey: "",
        xcopytype: XCOPY_TYPE.NOXCOPY,
        attachements: [],
      };
    }

    log.info(limaLogTag.TaskLinking, "onAddParaLinking: checking actaid, xcopyid, and open state", limaStore);

    //ADD selected Items to linking -> to paraIds, commentsIds
    //check for any change
    if (limaStore.selectedInWord.length > 0) {
      log.info(limaLogTag.TaskLinking, "onAddParaLinking: selected exists", tempTaskData);
      if (!taskData || !tempTaskData.paraIds) tempTaskData.paraIds = [];
      if (!taskData || !taskData.commentIds) tempTaskData.commentIds = [];
      limaStore.selectedInWord.forEach((item: SelectedInWord) => {
        if (item.type === WORDITEMTYPE.para) {
          !tempTaskData.paraIds.includes(item.id) && tempTaskData.paraIds.push(item.id);
        } else if (item.type === WORDITEMTYPE.comm) {
          !tempTaskData.commentIds.includes(item.id) && tempTaskData.commentIds.push(item.id);
        }
      });
      update = true;
    }

    if (update) {
      log.info(limaLogTag.TaskLinking, "onAddParaLinking: store setTaskData", tempTaskData);

      log.info(limaLogTag.TaskLinking, "onAddParaLinking: store setTaskData", tempTaskData);
      itemsLinkingCallBack(tempTaskData);
      setLinkedItemsCount(taskLinkedItems(tempTaskData));

      setDocLinked(true);
      // !limaStore.xcopyIsOpen && setDocLinked(true);
      // !limaStore.xcopyIsOpen && setInnerEnabledXcopyCreate(true);
    }

    if (!selectedItems) {
      log.info(limaLogTag.TaskLinking, "onAddParaLinking: set selected items true");
      setselectedItems(true);
    }

    // if (!limaStore.xcopyIsOpen && !innerEnabledDocLinking && tempTaskData.actaKey) {
    //   setInnerEnabledDocLinking(true);
    //   //setInnerEnabledXcopyCreate(true);
    // }

    if (innerShowHighLighted === true) void updateHightligts(true);
  };

  const onRemoveParaLinking = () => {
    log.info(limaLogTag.TaskLinking, "onRemoveParaLinking: to link", limaStore.selectedInWord);

    // const tempTaskData: TaskData = taskData;
    let update = false;

    if (limaStore.selectedInWord.length > 0) {
      log.info(limaLogTag.TaskLinking, "onRemoveParaLinking: selected exists", taskData);
      if (!taskData || !taskData.paraIds) taskData.paraIds = [];
      if (!taskData || !taskData.commentIds) taskData.commentIds = [];
      limaStore.selectedInWord.forEach((item: SelectedInWord) => {
        if (item.type === WORDITEMTYPE.para) {
          taskData.paraIds = taskData.paraIds.filter((itemId) => itemId !== item.id);
        } else if (item.type === WORDITEMTYPE.comm) {
          taskData.commentIds = taskData.commentIds.filter((itemId) => itemId !== item.id);
        }
      });
      update = true;
    }

    if (update) {
      log.info(limaLogTag.TaskLinking, "onRemoveParaLinking: store setTaskData", taskData);

      itemsLinkingCallBack(taskData);
      setLinkedItemsCount(taskLinkedItems(taskData));
      // setLinkedItemsCount(
      //   taskData && taskData.paraIds && taskData.commentIds && taskData.paraIds.length + taskData.commentIds.length
      // );
    }
    if (selectedItems && taskData.paraIds.length == 0 && taskData.commentIds.length == 0) {
      log.info(limaLogTag.TaskLinking, "onRemoveParaLinking: set selected items false");
      setselectedItems(false);
    }
    if (innerShowHighLighted === true) {
      if (taskData.paraIds.length == 0 && taskData.commentIds.length == 0) void updateHightligts(false);

      void updateHightligts(true);
    }
  };

  const onClearParaLinking = () => {
    log.info(limaLogTag.TaskLinking, "onClearParaLinking: Clear all");
    itemsLinkingCallBack({ ...taskData, commentIds: [], paraIds: [] });
    if (selectedItems === true) setselectedItems(false);
    if (innerShowHighLighted === true) void updateHightligts(false);
    setLinkedItemsCount(0);
  };

  //DOC LINKING
  const onClearDocLinking = () => {
    log.info(limaLogTag.TaskLinking, "onClearDocLinking: Clear DocLinking");

    setDocLinked(false);
    setXcopyType(XCOPY_TYPE.NOXCOPY);
    itemsLinkingCallBack({
      ...taskData,
      actaKey: "",
      xcopyKey: "",
      commentIds: [],
      paraIds: [],
      xcopytype: XCOPY_TYPE.NOXCOPY,
    });
    // innerEnabledXcopyCreate && setInnerEnabledXcopyCreate(false);
    setLinkedItemsCount(0);
    //} else {
    //setTaskData({ ...taskData, actaKey: "", xcopyKey: "", xcopytype: XCOPY_TYPE.NOXCOPY });
    //}

    if (selectedItems === true) setselectedItems(false);
    if (innerShowHighLighted === true) void updateHightligts(false);
  };

  const onSetDocLinking = () => {
    log.info(limaLogTag.TaskLinking, "onSetDocLinking: Set DocLinking");
    itemsLinkingCallBack({
      ...taskData,
      actaKey: limaStore.actaId,
      xcopyKey: limaStore.xcopyId,
      xcopytype: limaStore.xcopyType,
    });

    setDocLinked(true);

    if (selectedItems) setselectedItems(false);
    if (innerShowHighLighted) void updateHightligts(false);
  };

  const onCreateXcopy = (checked: boolean) => {
    setInnerEnabledXcopyCreate(checked);
    if (checked == false) {
      onClearDocLinking();
    }

    //TODO:
    //if set false -> remove linking
    //id set true -> cleanup linking
  };

  const onChangeCreateXcopy = (newType: XCOPY_TYPE) => {
    log.info(
      limaLogTag.TaskLinking,
      "CreateXcopy: draw xcopyCreate linking",
      newType,
      newType === XCOPY_TYPE.MAINXCOPY ? false : true
    );
    setXcopyType(newType);
    if (newType === XCOPY_TYPE.MAINXCOPY) {
      itemsLinkingCallBack({
        ...taskData,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        xcopyKey: limaStore.xcopyId,
        xcopytype: newType,
        actaKey: limaStore.actaId,
      });
    } else {
      itemsLinkingCallBack({
        ...taskData,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        xcopyKey: null,
        xcopytype: newType,
        actaKey: limaStore.actaId,
      });
    }
    //Update if visible
    if (innerShowHighLighted == true) {
      void updateHightligts(false);
      setInnerShowHightLighted(false);
      // updateHightligts(true);
    }
  };

  //ELEMENTS
  //DOC LINKING
  /**
   *
   * @returns Action button to add link to current document or remove
   *
   */
  const DocLinking = (): JSX.Element => {
    log.info(limaLogTag.TaskLinking, "DocLinking: draw doc linking", docLinked);
    const taskLinkedToItem = taskLinkedTo(taskData);
    //if (docLinked) {
    if (taskLinkedToItem !== null && taskLinkedToItem !== XCOPY_TYPE.NOXCOPY) {
      return (
        <>
          <ActionButton
            iconProps={docLinkedIcon}
            allowDisabledFocus
            disabled={!isTaskOwner}
            onClick={onClearDocLinking}
          >
            {taskLinkedToItem == XCOPY_TYPE.MAINXCOPY //limaStore.openedDocType === OPENEDFILETYPE_ENUM.COPY
              ? t("tpmain:component.linking.removelinkingBtnDOCLbl")
              : t("tpmain:component.linking.removelinkingBtnXcopyLbl")}
          </ActionButton>
          {!isTaskOwner && (
            <>
              <br />
              <LimaNotice>({t("tpmain:component.linking.removelinkingNoticeLbl")})</LimaNotice>
            </>
          )}
        </>
      );
    } else {
      return (
        // <StackItem>
        <ActionButton
          iconProps={docLinkedIcon}
          allowDisabledFocus
          // disabled={linkedTypeFixed}
          onClick={onSetDocLinking}
        >
          {limaStore.openedDocType == OPENEDFILETYPE_ENUM.COPY
            ? t("tpmain:component.linking.addlinkingBtnXcopyLbl")
            : t("tpmain:component.linking.addlinkingBtnDOCLbl")}
        </ActionButton>
        // </StackItem>
      );
    }
  };

  const LinkingToDocParts = observer((): JSX.Element => {
    //Enable linkign
    //Create new copy -> partial copy
    //Task create -> always
    if (taskData.xcopyKey === null || taskData.xcopyKey === "") {
      //Task
      if (enableAlwaysLinkParts && taskData.actaKey !== "" && taskData.actaKey !== null) {
        log.info(limaLogTag.TaskLinking, "LinkingToDocParts: SHOW - Always link");
        // return <>SHOW - Always link</>;
      } else if (innerEnabledXcopyCreate && xcopyType === XCOPY_TYPE.TRIMXCOPY) {
        log.info(limaLogTag.TaskLinking, "LinkingToDocParts: SHOW - link on Trim copy");
        // return <>SHOW - link on Trim copy</>;
      } else return <></>;
    }
    //Task edit -> if is linked
    else {
      if (onlyCreateXcopy === true) {
        log.info(limaLogTag.TaskLinking, "LinkingToDocParts: SHOW - onlyCreateXcopy");
        // return <>SHOW - onlyCreateXcopy</>;
      } else if (
        isTaskDataLinkedDocOpen(taskData, limaStore.actaId, limaStore.xcopyId) &&
        taskData.xcopytype !== XCOPY_TYPE.TRIMXCOPY
      ) {
        log.info(limaLogTag.TaskLinking, "LinkingToDocParts: SHOW - link on document is open");
        // return <>SHOW - link on document is open</>;
      } else {
        return <>{t("tpmain:component.linking.linkingDisabledLbl")}</>;
      }
    }

    return (
      <Stack>
        <StackItem>
          <Stack horizontal>
            <StackItem>
              <Label key="linkedParaLbl">
                {t("tpmain:component.linking.linkedparagraphsLbl")} [{linkedItemsCount > 0 ? linkedItemsCount : "-"}]
              </Label>
            </StackItem>
            <StackItem>
              <Toggle
                key="linkedParaShow"
                onText={t("tpmain:component.linking.linkedparagraphsHighlightLbl")}
                offText={t("tpmain:component.linking.linkedparagraphsHiddenLbl")}
                checked={innerShowHighLighted}
                // disabled={!selectedItems && linkedItemsCount <= 0}
                onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
                  log.info(limaLogTag.TaskLinking, "on toggle click: checked,taskdata", checked, taskData);
                  setInnerShowHightLighted(checked);
                  void updateHightligts(checked);
                }}
                // role="checkbox"
              />
            </StackItem>
          </Stack>
        </StackItem>

        <Stack horizontal>
          <StackItem>
            <ActionButton
              iconProps={addIcon}
              allowDisabledFocus
              // disabled={!selectedItems}
              onClick={onAddParaLinking}
            >
              {t("tpmain:component.linking.addlinkingBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={removeIcon}
              allowDisabledFocus
              disabled={linkedItemsCount <= 0}
              onClick={onRemoveParaLinking}
            >
              {t("tpmain:component.linking.removelinkingBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={removeAllIcon}
              allowDisabledFocus
              disabled={linkedItemsCount <= 0}
              onClick={onClearParaLinking}
            >
              {t("tpmain:component.linking.removealllinkingBtnLbl")}
            </ActionButton>
          </StackItem>
        </Stack>
      </Stack>
    );
  });

  /**
   *
   * @returns Choice group of creating xcopyType
   */
  const CreateXcopyType = (): JSX.Element => {
    log.info(limaLogTag.TaskLinking, "CreateXcopyType: draw xcopyCreate linking");
    return (
      <ChoiceGroup
        key="genXcopy"
        defaultSelectedKey={xcopyType}
        // disabled={linkedTypeFixed}
        value={taskData.xcopytype}
        options={[
          {
            key: XCOPY_TYPE.FULLXCOPY,
            text: t("tpmain:component.linking.createcopytypeFullLbl"),
            defaultChecked: xcopyType === XCOPY_TYPE.FULLXCOPY ? true : false,
          },
          {
            key: XCOPY_TYPE.TRIMXCOPY,
            text: t("tpmain:component.linking.createcopytypeTrimLbl"),
            defaultChecked: xcopyType === XCOPY_TYPE.TRIMXCOPY ? true : false,
          },
        ]}
        onChange={(_ev, option: IChoiceGroupOption) => onChangeCreateXcopy(XCOPY_TYPE[option.key])}
        label={t("tpmain:component.linking.createcopytypeLbl")}
        required={true}
      />
    );
  };

  /**
   *
   * @returns Togle switching create xcopy or just link
   */
  const CreateXcopyOrLink = (): JSX.Element => {
    log.info(limaLogTag.TaskLinking, "CreateXcopyOrLink: draw xcopyCreate linking");
    return (
      <Toggle
        key="createXcopy"
        label={t("tpmain:component.linking.createXcopyLinkingLabelLbl")}
        inlineLabel
        onText={t("tpmain:component.linking.createXcopyLinkingLbl")}
        offText={t("tpmain:component.linking.dontCreateXcopyLinkingLbl")}
        checked={innerEnabledXcopyCreate}
        onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
          log.info(limaLogTag.TaskLinking, "on toggle createXcopy click: checked,taskdata", checked, taskData);
          onCreateXcopy(checked);
        }}
      />
    );
  };

  /**
   *
   * @returns true if is possible to link to current document
   */
  const showLinking = (): boolean => {
    //is already linked?
    if (taskData.actaKey != null && taskData.actaKey != "") {
      return false;
    }
    //not yet Linked?
    else {
      if (limaStore.isMainCopyOpen() === true || limaStore.isAnyActiveWorkingcopyOpen() === true) return true;
      return false;
    }
  };

  const ShowAlreadyLinked = (): JSX.Element => {
    return (
      <>
        <StackItem>Document is already linked</StackItem>
        <StackItem>
          <DocLinking />
        </StackItem>
        <StackItem>
          <LinkingToDocParts />
        </StackItem>
      </>
    );
  };

  /**
   *
   * @returns return not linked switch
   */
  const ShowNotLinked = (): JSX.Element => {
    return (
      <>
        {showLinking ? (
          onlyCreateXcopy ? (
            <>
              {/* <StackItem>Only create XCopy</StackItem> */}
              <StackItem>
                <CreateXcopyType />
                {/* {xcopyType === XCOPY_TYPE.TRIMXCOPY && <LinkingToDocParts />} */}
              </StackItem>
              <StackItem>
                <LinkingToDocParts />
              </StackItem>
            </>
          ) : (
            <>
              <StackItem>
                <CreateXcopyOrLink />
              </StackItem>

              {innerEnabledXcopyCreate === false ? (
                <StackItem>
                  <DocLinking />
                </StackItem>
              ) : (
                <StackItem>
                  <CreateXcopyType />
                </StackItem>
              )}
              <StackItem>
                <LinkingToDocParts />
              </StackItem>
            </>
          )
        ) : (
          "No linkable document open"
        )}
      </>
    );
  };

  return (
    <Stack>
      {/* <StackItem>{">>>text" + infoStatusText}</StackItem> */}
      {isTaskLinked(taskData) ? <ShowAlreadyLinked /> : <ShowNotLinked />}
    </Stack>
  );
};

export default CreateLinking;
