import React = require("react");
import { FunctionComponent } from "react";

import { ProgressIndicator, Separator, Stack, Toggle } from "office-ui-fabric-react";
import { mergeStyleSets, ITheme, getTheme } from "office-ui-fabric-react/lib/Styling";

import { log, tag } from "missionlog";
import { observer } from "mobx-react";

import limaJudgeStore from "../../../../store/limaJudgeStore";
import JudgeActParaList from "./JudgeActParaList";
import { advice_textsearch } from "../../../../types/advice";
import { useTranslation } from "react-i18next";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

log.init({ JudgmentSearchPage: "JudgmentSearchPage" });

const theme: ITheme = getTheme();
const { fonts } = theme;

const classNames = mergeStyleSets({
  itemName: [
    fonts.medium,
    {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
});

const JudgmentSearchPage: FunctionComponent = () => {
  const { t } = useTranslation(["tpadvice"]);

  const onChangeJudgeParsEnable = (ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(tag.JudgmentSearchPage, "onChangeActParsEnable: toggle is", checked ? "checked" : "not checked");
    log.info(tag.JudgmentSearchPage, "onChangeActParsEnable: env", ev);
    if (checked) {
      limaJudgeStore.setInlinSearch(true);
      void limaJudgeStore.getActSigns();
    } else {
      limaJudgeStore.setInlinSearch(false);
      limaJudgeStore.setFullList([]);
      limaJudgeStore.setList([]);
      limaJudgeStore.setTotalItemCount(0);
    }
  };

  const ShowProgress: FunctionComponent = observer(() => {
    return (
      <>
        {limaJudgeStore.loadingData && (
          <ProgressIndicator label="Search act paragraph" description="I am searching on server" />
        )}
      </>
    );
  });

  const RenderTopLbl = observer((): JSX.Element => {
    log.info(tag.JudgmentSearchPage, "RenderTopLbl: observer top show");
    //TODO: rewrite to Array of items
    return (
      <div key="topLbl" className={classNames.itemName}>
        {limaJudgeStore.searchActPar.map((item: advice_textsearch) => `§ ${item.parID} ${item.actID}`)}
      </div>
    );
  });

  const ShowHeader: FunctionComponent = observer(() => {
    return <>{limaJudgeStore.totalItemCount > 0 ? <RenderTopLbl /> : <></>}</>;
  });

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.advisorLbl"), t("tpmain:menu.advisor.judgementsearchLbl")]} />
      <Separator alignContent="center">{t("tpadvice:juedgesearch.label")}</Separator>
      <Stack horizontal horizontalAlign="center">
        <Toggle
          key="searchActParInstantToggle"
          onText={t("tpadvice:juedgesearch.searchtoggleonlbl")}
          offText={t("tpadvice:juedgesearch.searchtoggleofflbl")}
          onChange={onChangeJudgeParsEnable}
          role="checkbox"
          defaultChecked={limaJudgeStore.isInlineSearchActive}
        />
      </Stack>
      <Stack horizontalAlign="center">
        <ShowHeader />
      </Stack>
      <ShowProgress />
      <Separator alignContent="center">Judge List</Separator>
      <JudgeActParaList />
    </>
  );
};

export default JudgmentSearchPage;
