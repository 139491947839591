import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { LIMA_LICENCE_TYPE, LimaCompLicUsers } from "../../../../../types/LimaLicences";
import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import {
  LIMA_ACTION_BUTTON_TYPE,
  LimaActionIconDefault,
} from "../../../../../limaComponents/LimaActionIcons/LimaActionIconDefault";
import LimaLicPermShow from "../../../../../limaComponents/LimaLicPermShow";
import { LIMAENUM_AccesLicPermIdent } from "../../../../../limaCommon/limaPermissions";
import LimaActionButtonV4 from "../../../Components/LimaActionButtonV4";
import limaUsersStore from "../../../../../store/limaUsersStore";
import limaStore from "../../../../../store/limaStore";

interface IUserLicenceItemProps {
  user: LimaCompLicUsers;
  onUpdate?: (updateUser: LimaCompLicUsers) => void;
  isLogged?: boolean;
  onLoggout?: (user: LimaCompLicUsers) => void;
  limaLicenceTypeOptions: IDropdownOption[];
  onRemove?: (remUser: LimaCompLicUsers) => void;
}

// const limaLicenceTypeOptions: IDropdownOption[] = [
//   { key: LIMA_LICENCE_TYPE.CONCURENT, text: "Concurent", data: { icon: "Contact" } },
//   { key: LIMA_LICENCE_TYPE.PER_USER, text: "Per user", data: { icon: "TaskSolid" } },
//   { key: LIMA_LICENCE_TYPE.FREEUSER, text: "Free", data: { icon: "Copy" } },
//   { key: LIMA_LICENCE_TYPE.NONE, text: "No licence", data: { icon: "Copy" } },
// ];

const UserLicenceItem: FunctionComponent<IUserLicenceItemProps> = (props: IUserLicenceItemProps) => {
  const [user, setUser] = useState<LimaCompLicUsers>(props.user);
  // const [userKey, setUserKey] = useState<string | null>(props.user.userKey);
  const updatedUser = useMemo(() => {
    window.console.log(">>>> userset", user);
    if (user.company2userKey !== props.user.company2userKey) return true;
    // if (user.dtimestamp !== props.user.dtimestamp) return true;
    // if (user.licenceType !== props.user.licenceType) return true;
    if (user.licenceusedid !== props.user.licenceusedid) return true;
    // if (user.userEmail !== props.user.userEmail) return true;
    if (user.userKey !== props.user.userKey) return true;
    return false;
  }, [user]);
  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);
  //---------------------
  //    HANDLERS
  //---------------------

  const onUserSlected = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    window.console.log(">>>> user", option, option.key);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    setUser({ ...user, userKey: String(option.key), userName: option.data.userName, userEmail: option.data.userEmail });
    window.console.log(">>>> user", option, option.key);
    // setUserKey(String(option.key));
  };

  const onLicenceChanged = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    setUser({ ...user, licenceusedid: String(option.key), licenceType: LIMA_LICENCE_TYPE[option.data.type] });
  };

  const onUpdate = (): void => {
    if (props.onUpdate !== undefined) {
      props.onUpdate(user);
    }
  };

  const onRemove = (): void => {
    if (props.onRemove !== undefined) {
      props.onRemove(user);
    }
  };
  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack horizontal>
      <Stack.Item style={{ width: "60%", alignSelf: "center" }}>
        {props.user.userKey !== null ? (
          props.user.userName
        ) : (
          <Dropdown
            defaultSelectedKey={user.userKey}
            onChange={onUserSlected}
            options={[
              {
                key: limaStore.userId,
                text: "YOU",
                data: { userName: limaStore.userName, userEmail: limaStore.userEmail },
              },
              ...limaUsersStore.fullList.map((user): IDropdownOption => {
                return {
                  key: user._key,
                  text: user.userName,
                  data: { userName: user.userName, userEmail: user.userEmail },
                };
              }),
            ]}
          ></Dropdown>
        )}
      </Stack.Item>
      {/* <Stack.Item style={{ width: "30%" }}>{props.user.userEmail}</Stack.Item> */}
      <Stack.Item style={{ width: "10%" }}>
        {props.isLogged !== undefined && props.isLogged === true && (
          <LimaLicPermShow
            accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserLogoutLicence}
            // dontShowOnNoLic={true}
            noLicComponentShow={<>LOGGED</>}
          >
            <LimaActionIconDefault
              size={22}
              type={LIMA_ACTION_BUTTON_TYPE.SESSION_OTHER_SIGNED_USER}
              title="Logout user"
              onClick={() => props.onLoggout(props.user)}
            />
          </LimaLicPermShow>
        )}
      </Stack.Item>
      <Stack.Item style={{ width: "30%" }}>
        <Dropdown
          defaultSelectedKey={user.licenceusedid}
          options={props.limaLicenceTypeOptions}
          onChange={onLicenceChanged}
        />
      </Stack.Item>
      <Stack.Item style={{ width: "15%" }}>
        {props.user.company2userKey === null ? (
          <LimaActionButtonV4
            disabled={user.userKey === null || user.licenceusedid === null || user.licenceusedid === ""}
            onClick={onUpdate}
            toolTipShow={false}
          >
            Add
          </LimaActionButtonV4>
        ) : updatedUser == true ? (
          <LimaActionButtonV4 onClick={onUpdate} toolTipShow={false}>
            Update
          </LimaActionButtonV4>
        ) : (
          <LimaActionButtonV4 onClick={onRemove} toolTipShow={false}>
            Remove
          </LimaActionButtonV4>
        )}
      </Stack.Item>
    </Stack>
  );
};
export default UserLicenceItem;

//----------------------
//   REACT QUERY
//----------------------
