import * as React from "react";
import { FunctionComponent } from "react";
// import { initializeIcons } from "@fluentui/react/lib/Icons";

import ProgressLogin from "./Header/ProgressLogin";
import Header from "./Header/HeaderFn";
import { HashRouter as Router } from "react-router-dom";

import MainMenu from "./Menu/MainMenu";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { appTheme } from "./Theme/appTheme";
import TaskPaneMainRouterSwitch from "./components/TaskPaneMainRouterSwitch";
import { LimaMenu } from "../limaComponents/LimaMenu";
// import LimaBreadCrumbV4 from "../limaComponents/LimaBreadCrumbV4";
import limaStore, { UI_VERSION } from "../store/limaStore";
import { observer } from "mobx-react-lite";
import LimaBreadCrumbV4Ext from "../limaComponents/LimaBreadCrumbV4Ext";
import LimaActaWordDocCompare from "../limaComponents/LimaActionIcons/LimaActaWordDocCompare";
import LimaActaWordOpenNewWindow from "../limaComponents/LimaActionIcons/LimaActaWordOpenNewWindow";
import LimaActaWordDocSomeExistText from "../limaComponents/LimaActionIcons/LimaActaWordDocSomeExistText";
// import { ScrollablePane } from "@fluentui/react";

type AppProps = {
  title: string;
  isOfficeInitialized: boolean;
};

const App: FunctionComponent<AppProps> = ({ title, isOfficeInitialized }: AppProps) => {
  if (!isOfficeInitialized) {
    return (
      <ProgressLogin
        title={title}
        logo="assets/LimaLogo300.png"
        message="Please sideload your addin to see app body."
      />
    );
  }

  //-----------------
  // OBSERVERS
  //-----------------

  const MenuUI_Version = observer((): JSX.Element => {
    // window.console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>", limaStore.ui_version);
    if (limaStore.userLicenced === false) return <></>;
    if (limaStore.ui_version === UI_VERSION.VERSION_2) return <MainMenu />;
    else return <LimaMenu />;
  });

  const LimaBreadCrumbV4ExtObs = observer((): JSX.Element => {
    if (limaStore.logged) return <LimaBreadCrumbV4Ext />;
    return <></>;
  });

  const LimaActaWordDocCompareObs = observer((): JSX.Element => {
    //window.console.log("dilalog dialog content observer");
    if (limaStore.logged && limaStore.userLicenced === true && limaStore.isWordContainsSomethingOnStart() === true) {
      if (limaStore.isWordDocIdentInDocument() === true && limaStore.isWordDocIdentIsOpenInLima() === false) {
        return <LimaActaWordDocCompare />;
      } else if (limaStore.isWordDocIdentInDocument() !== true) {
        return <LimaActaWordDocSomeExistText />;
      }
    }

    return <></>;
  });

  const LimaActaWordOpenNewWindowObs = observer((): JSX.Element => {
    //window.console.log("dilalog dialog content observer");
    if (limaStore.logged && limaStore.openFileNewWindow === true) {
      return <LimaActaWordOpenNewWindow />;
    }
    return <></>;
  });

  return (
    <ThemeProvider theme={appTheme} applyTo="body">
      <Router>
        <div style={{ height: "100vh", overflow: "hidden" }}>
          <div style={{ height: "100vh", overflow: "hidden", display: "flex", flexDirection: "column" }}>
            <div
              style={{
                marginBottom: 5,
                paddingBottom: 3,
                borderBottom: "#ebebeb",
                borderBottomStyle: "inset",
                borderBottomWidth: "thin",
              }}
            >
              <Header />
              <MenuUI_Version />
              <LimaBreadCrumbV4ExtObs />
              <LimaActaWordDocCompareObs />
              <LimaActaWordOpenNewWindowObs />
            </div>
            <div style={{ flex: "1 0 auto", height: 0, overflowY: "auto" }}>
              <TaskPaneMainRouterSwitch />
            </div>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
