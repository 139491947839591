import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import TaskItem from "./components/TaskItem";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TaskAddPageProps {}

const TaskAddPage: FunctionComponent<TaskAddPageProps> = () => {
  const { t } = useTranslation(["tpmain"]);
  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.editLbl"), t("tpmain:menu.edit.addtaskLbl")]} />
      <TaskItem />
    </>
  );
};

export default TaskAddPage;
