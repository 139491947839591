import {
  IconButton,
  IIconProps,
  IStackTokens,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  Text,
  Icon,
  mergeStyleSets,
} from "@fluentui/react";
import { log } from "missionlog";
import moment = require("moment");
import { FunctionComponent, useState } from "react";
import React = require("react");
// import Moment from "react-moment";
import { urlPutXcopyHistItem } from "../../../../../api/apilinks";
import { httpPutAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import { XcopyHistory } from "../../../../../types/XcopyHistory";

const UnsavedChangeIcon: IIconProps = { iconName: "UnsyncOccurence" };
const SyncedChangeIcon: IIconProps = { iconName: "SyncOccurence" };
const AddMajorVersion: IIconProps = { iconName: "CircleAddition" };
const AddMinorVersion: IIconProps = { iconName: "AddTo" };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FileItemOpenHistFileNameProps {
  historyItem: XcopyHistory;
  enableChangeVersions: boolean;
}

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 2,
};

const classNames = mergeStyleSets({
  items: {
    alignSelf: "center",
    marginLeft: 10,
    flexShrink: 0,
  },
  item_time: {
    alignSelf: "center",
    flexShrink: 0,
    fontSize: 16,
    cursor: "default",
  },
  item_freeICOn: {
    alignSelf: "center",
    width: 32,
  },
  itemVersionHandlerContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: 87,
  },
});

const FileItemOpenHistFileName: FunctionComponent<FileItemOpenHistFileNameProps> = ({
  historyItem,
  enableChangeVersions,
}: FileItemOpenHistFileNameProps) => {
  const [ahName, setAhName] = useState<string | null>(historyItem.historyName);
  const [ahVersionMajor, setAhVersionMajor] = useState<number>(historyItem.versionMajor);
  const [ahVersionMinor, setAhVersionMinor] = useState<number>(historyItem.versionMinor);

  const [ahNameOrigin, setAhNameOrigin] = useState<string>(historyItem.historyName);
  const [ahNameSaved, setAhNameSaved] = useState<boolean>(true);
  const [ahNameIsSaving, setAhNameIsSaving] = useState<boolean>(false);

  const handleChangeNameSave = async () => {
    let response: HttpResponse<XcopyHistory>;
    const loaderID = limaLoadeStore.add("Change Name save");
    try {
      setAhNameIsSaving(true);
      const updateHistName: XcopyHistory = {
        _id: historyItem._id,
        _key: historyItem._key,
        actaId: historyItem.actaId,
        xcopyId: historyItem.xcopyId,
        historyName: ahName,
        historyTimeStamp: historyItem.historyTimeStamp,
        cTimeStamp: historyItem.cTimeStamp,
        cIssuedBy: historyItem.cIssuedBy,
        dtimestamp: historyItem.dtimestamp,
        dIssuedBy: historyItem.dIssuedBy,
        versionMajor: ahVersionMajor,
        versionMinor: ahVersionMinor,
      };

      // response = await httpPut(urlPutXcopyHistItem(), updateHistName, loaderID.ac);
      response = await httpPutAuth<XcopyHistory>(urlPutXcopyHistItem(), updateHistName, loaderID.ac);
      log.info(limaLogTag.FileItemOpenHistFileName, "handleChangeNameSave: asketo to put something", response);

      if (response.parsedBody !== undefined) {
        log.info(
          limaLogTag.FileItemOpenHistFileName,
          "handleChangeNameSave: parsedBody is not null",
          response.parsedBody
        );
        setAhNameOrigin(ahName);
        setAhNameSaved(true);
      }
    } catch (error) {
      log.error(limaLogTag.FileItemOpenHistFileName, "getHistoryLinks: error whe calling ", error);
    } finally {
      setAhNameIsSaving(false);
      limaLoadeStore.remove(loaderID.k);
    }
  };

  const handleAddMinorVersion = () => {
    setAhVersionMinor(ahVersionMinor + 1);
    ahNameSaved && setAhNameSaved(false);
  };

  const handleAddMajorVersion = () => {
    setAhVersionMajor(ahVersionMajor + 1);
    setAhVersionMinor(0);
    ahNameSaved && setAhNameSaved(false);
  };

  const changeNameHandler = (_e: React.FormEvent, value: string) => {
    setAhName(value);
    console.log("text", value === ahNameOrigin); //ahNameOrigin
    setAhNameSaved(value === ahNameOrigin); //ahNameOrigin
  };

  const onTextFieldKey: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event: React.KeyboardEvent
  ) => {
    console.log("value", event.key);
    if (event.key == "Enter") {
      void handleChangeNameSave();
    }
  };

  return (
    <Stack horizontal tokens={stackTokens}>
      <Stack.Item grow={6}>
        <TextField
          // required
          value={ahName !== undefined && ahName !== null && ahName !== "" && ahName}
          placeholder={"version"}
          onChange={changeNameHandler}
          // className={classNames.itemNameTextField}
          underlined={true}
          borderless={true}
          onKeyDown={onTextFieldKey}
        />
      </Stack.Item>
      <Stack.Item grow={2} className={classNames.items}>
        <Text variant="mediumPlus">
          {ahVersionMajor}.{ahVersionMinor}
        </Text>
      </Stack.Item>
      <Stack.Item grow={2} className={classNames.itemVersionHandlerContainer}>
        {enableChangeVersions ? (
          <>
            <IconButton
              iconProps={AddMajorVersion}
              title={"Add major version"}
              onClick={handleAddMajorVersion}
              disabled={!enableChangeVersions}
            />
            .
            <IconButton
              iconProps={AddMinorVersion}
              title={"Add minor version"}
              onClick={handleAddMinorVersion}
              disabled={!enableChangeVersions}
            />
          </>
        ) : (
          <></>
        )}
      </Stack.Item>
      <Stack.Item grow={3} className={classNames.items}>
        <Icon
          iconName={"Clock"}
          aria-hidden="true"
          title={moment(historyItem.historyTimeStamp).format("Do MMMM YYYY [at] h:mma")}
        />
      </Stack.Item>
      <Stack.Item grow={1}>
        {ahNameIsSaving ? (
          <Spinner size={SpinnerSize.small} />
        ) : ahNameSaved ? (
          <IconButton iconProps={SyncedChangeIcon} title={"Saved"} />
        ) : (
          <IconButton iconProps={UnsavedChangeIcon} title={"Save change"} onClick={handleChangeNameSave} />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default FileItemOpenHistFileName;
