import { DefaultButton, List, Separator, Shimmer, Toggle } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { urlGetActaBasicCheck } from "../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../api/httpAPI";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import limaLoadeStore from "../../../../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";
import { LimaDocTypeENUM } from "../../../../types/docxData";
import { ANALYSED_TEXT_FOUND, SentanceAnalyseData } from "../../../../types/SentanceAnalyseData";
import CommitmentComponent from "./components/CommitmentComponent";
import IssueComponent from "./components/IssueComponent";
import TimeTaskComponent from "./components/TimeTaskComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdviceCheckDocPageProps {}

const enum CHECK_DOC_ERROR_TYPE {
  NONE,
  NO_XCOPY_OPEN,
  VERSION_OPEN,
}

const AdviceCheckDocPage: FunctionComponent<AdviceCheckDocPageProps> = () => {
  const [sentanceAnalyseData, setSentanceAnalyseData] = useState<SentanceAnalyseData[]>();
  const [useFullSentances, setUseFullSentances] = useState<boolean>(true);
  const [useMissingSubj, setUseMissingSubj] = useState<boolean>(true);
  const [useMissingObj, setUseMissingObj] = useState<boolean>();
  const [useCommitments, setUseCommitments] = useState<boolean>(true);
  const [useTimeterms, setUseTimeterms] = useState<boolean>();

  const [checkErr, setCheckErr] = useState<CHECK_DOC_ERROR_TYPE | null>(null);
  const { t } = useTranslation();
  const { isLoading, error, refetch } = useQuery<SentanceAnalyseData[], Error>(
    [
      "getActaCheck",
      {
        userid: limaStore.userId || null,
        actaid: limaStore.actaId || null,
        xcopyid: limaStore.xcopyId || null,
      },
    ],
    () =>
      getActaCheck(
        // limaStore.userId || null,
        limaStore.xcopyId || null,
        LimaDocTypeENUM.XCOPY,
        useFullSentances,
        useMissingSubj,
        useMissingObj,
        useCommitments,
        useTimeterms
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.AttachementComponent, "useQuery get attachements ", err);
      },
      onSuccess: (data: SentanceAnalyseData[]) => {
        log.info(limaLogTag.AdviceCheckDocPage, "AdviceCheckDocPage loaded: ", data);
        setSentanceAnalyseData(data);
      },
    }
  );

  React.useEffect(() => {
    //queryFinish && setQueryFinish(false);
    log.info(
      limaLogTag.AdviceCheckDocPage,
      "AdviceCheckDocPage UEF: ",
      checkErr,
      limaStore.xcopyId,
      limaStore.xcopyIsOpen,
      limaStore.openedDocType
    );

    if (limaStore.openedDocType !== OPENEDFILETYPE_ENUM.MAIN_COPY) {
      if (
        limaStore.openedDocType === OPENEDFILETYPE_ENUM.MAIN_COPY_VERSION &&
        (checkErr !== CHECK_DOC_ERROR_TYPE.VERSION_OPEN || checkErr === null)
      ) {
        setCheckErr(CHECK_DOC_ERROR_TYPE.VERSION_OPEN);
      } else if (
        limaStore.openedDocType === OPENEDFILETYPE_ENUM.COPY_VERSION &&
        (checkErr !== CHECK_DOC_ERROR_TYPE.VERSION_OPEN || checkErr === null)
      ) {
        setCheckErr(CHECK_DOC_ERROR_TYPE.VERSION_OPEN);
      } else if (limaStore.openedDocType !== OPENEDFILETYPE_ENUM.COPY) {
        setCheckErr(CHECK_DOC_ERROR_TYPE.NO_XCOPY_OPEN);
      } else if (checkErr !== CHECK_DOC_ERROR_TYPE.NONE || checkErr === null) {
        setCheckErr(CHECK_DOC_ERROR_TYPE.NONE);
      } else {
        log.warn(limaLogTag.AdviceCheckDocPage, "UEF: unknow doc???");
      }
    } else {
      if (checkErr !== CHECK_DOC_ERROR_TYPE.NONE) {
        setCheckErr(CHECK_DOC_ERROR_TYPE.NONE);
      }
    }
  }, [limaStore.xcopyId, limaStore.openedDocType]);

  React.useEffect(() => {
    //queryFinish && setQueryFinish(false);
    if (checkErr != null && checkErr === CHECK_DOC_ERROR_TYPE.NONE) void refetch();
  }, [checkErr]);

  if (isLoading == true) {
    log.info(limaLogTag.AdviceCheckDocPage, "searching for history");
    return (
      <>
        <div>... Analyze current document ...</div>
        <div>
          <Shimmer width="80%" />
          <Shimmer width="80%" />
          <Shimmer width="80%" />
        </div>
      </>
    );
  }

  if (error) {
    log.error(limaLogTag.AdviceCheckDocPage, "loaded error: ", error);
    return <>Error Getting data </>;
  }

  // if (status === "success" && !queryFinish) {
  //   log.info(limaLogTag.AdviceCheckDocPage, "AdviceCheckDocPage loaded: ", data);
  //   setQueryFinish(true);
  //   setSentanceAnalyseData(data);
  // }

  // console.log("DATA", sentanceAnalyseData);

  const onRenderCell = (item: SentanceAnalyseData): JSX.Element => {
    //log.info(limaLogTag.TasksList, `onRenderCell: on render `, item);
    // console.log("render", item);
    if (item.found === ANALYSED_TEXT_FOUND.TIME_FOR_TASK) {
      return <TimeTaskComponent item={item} />;
    } else if (item.found === ANALYSED_TEXT_FOUND.COMMITMENT) {
      return <CommitmentComponent item={item} />;
    } else if (item.found === ANALYSED_TEXT_FOUND.ISSUE) {
      return <IssueComponent item={item} />;
    } else {
      return (
        <span>
          {item.found} / {item.paraId} / {item.sentace}
        </span>
      );
    }
  };

  const ReportError = (): JSX.Element => {
    let errorTxt = "";

    switch (checkErr) {
      case CHECK_DOC_ERROR_TYPE.NO_XCOPY_OPEN:
        errorTxt = "No document open";
        break;
      case CHECK_DOC_ERROR_TYPE.VERSION_OPEN:
        errorTxt = "Is opened not final veriosn of doc";
        break;
    }

    return <>ERROR: {errorTxt}</>;
  };

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.advisorLbl"), t("tpmain:menu.advisor.checkactaLbl")]} />
      {checkErr === CHECK_DOC_ERROR_TYPE.NONE ? (
        <DefaultButton
          key="btnCheckDoc"
          //disabled={checkErr !== CHECK_DOC_ERROR_TYPE.NONE}
          className="ms-welcome__action"
          iconProps={{ iconName: "Zoom" }}
          onClick={() => {
            log.info(limaLogTag.AdviceCheckDocPage, "on refetch data");
            void refetch();
          }}
        >
          {t("tpadvice:checkdoc.recheckButtonLbl")}
        </DefaultButton>
      ) : (
        <ReportError />
      )}
      <LimaHideableSection name={t("tpadvice:checkdoc.settings.paramsPartLbl")}>
        <Toggle
          key="sentancesOnly"
          onText={t("tpadvice:checkdoc.settings.sentanceonlyONLbl")}
          offText={t("tpadvice:checkdoc.settings.sentanceonlyOFFLbl")}
          role="checkbox"
          checked={useFullSentances}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.AdviceCheckDocPage, "on refetch data", checked);
            setUseFullSentances(!useFullSentances);
          }}
        />
        <Toggle
          key="subjectmissing"
          onText={t("tpadvice:checkdoc.settings.missingsubjectONLbl")}
          offText={t("tpadvice:checkdoc.settings.missingsubjectOFFLbl")}
          role="checkbox"
          checked={useMissingSubj}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.AdviceCheckDocPage, "useMissingSubj change to ", checked);
            setUseMissingSubj(!useMissingSubj);
          }}
        />
        <Toggle
          key="objectmissing"
          onText={t("tpadvice:checkdoc.settings.missingobjectONLbl")}
          offText={t("tpadvice:checkdoc.settings.missingobjectOFFLbl")}
          role="checkbox"
          checked={useMissingObj}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.AdviceCheckDocPage, "useMissingObj change to ", checked);
            setUseMissingObj(!useMissingObj);
          }}
        />
        <Toggle
          key="commitments"
          onText={t("tpadvice:checkdoc.settings.commitmentsONLbl")}
          offText={t("tpadvice:checkdoc.settings.commitmentsOFFLbl")}
          role="checkbox"
          checked={useCommitments}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.AdviceCheckDocPage, "useCommitments change to ", checked);
            setUseCommitments(!useCommitments);
          }}
        />
        <Toggle
          key="timeterms"
          onText={t("tpadvice:checkdoc.settings.timetermssONLbl")}
          offText={t("tpadvice:checkdoc.settings.timetermssOFFLbl")}
          role="checkbox"
          checked={useTimeterms}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.AdviceCheckDocPage, "useTimeterms change to ", checked);
            setUseTimeterms(!useTimeterms);
          }}
        />
      </LimaHideableSection>
      <Separator>{t("tpadvice:checkdoc.info.foundItemsLbl")}</Separator>
      {checkErr === CHECK_DOC_ERROR_TYPE.NONE && <List items={sentanceAnalyseData} onRenderCell={onRenderCell} />}
    </>
  );
};

export default AdviceCheckDocPage;

//----------------------
//   REACT QUERY
//----------------------

// type Params = {
//   queryKey: [string, { userid: string; actaid: string }];
// };

//async function getActaCheck(userid: string, actaid: string) {
async function getActaCheck(
  // userid: string,
  baseId: string,
  baseType: LimaDocTypeENUM,
  fullsent: boolean,
  subjmis: boolean,
  objmis: boolean,
  commits: boolean,
  timeterms: boolean
) {
  // const [, { userid, actaid }] = params.queryKey;

  const loaderID = limaLoadeStore.add("Getting Check doc data");
  let response: HttpResponse<SentanceAnalyseData[]>;
  try {
    //response = await httpGet<SentanceAnalyseData[]>(urlGetActaBasicCheck(actaid, userid));
    response = await httpGetAuth<SentanceAnalyseData[]>(
      urlGetActaBasicCheck(baseId, baseType, fullsent, subjmis, objmis, commits, timeterms),
      loaderID.ac
    );

    log.info(limaLogTag.AdviceCheckDocPage, "getActaCheck: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.AdviceCheckDocPage, "getActaCheck: parsedBody is not null", response.parsedBody);
      const userListCopies = response.parsedBody;
      return userListCopies;
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.AdviceCheckDocPage, "getActaCheck: parsedBody is null");
      return null;
    }
  } catch (response) {
    log.error(limaLogTag.AdviceCheckDocPage, "getActaCheck Error call", response);
    // throw new Error("Problem geting basic Stat");
    return null;
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
}
