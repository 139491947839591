import { log } from "missionlog";
import moment = require("moment");
import { IconButton, IIconProps, Separator, Shimmer, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useInfiniteQuery } from "react-query";
import { urlGetUserListStatistic } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { StatConverToText } from "../../../../../limaCommon/limaStat";
import limaLoadeStore, { LoaderItemEnd } from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { Statistic } from "../../../../../types/Statistic";

interface UserStatisticListProps {
  userId: string;
  startDate?: Date;
}

const extendStatList: IIconProps = { iconName: "SearchIssue" };

const UserStatisticList: FunctionComponent<UserStatisticListProps> = ({
  userId,
  startDate,
}: UserStatisticListProps) => {
  const offset = 20;
  //const [statData, setStatData] = useState<Statistic[]>([]);
  //const [loading, setLoading] = useState<boolean>(true);
  const { data, error, fetchNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery<Statistic[], Error>(
    // "userListStatistic",
    ["userListStatistic", { userid: limaStore.userId, foruserid: userId, startDate: startDate, offset: offset }],
    ({ pageParam = 0 }) =>
      getUserListStat({
        // userid: limaStore.userId,
        foruserid: userId,
        startDate: startDate,
        offset: offset,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        pageParam: pageParam,
      }),
    {
      //getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
      // refetchOnWindowFocus: false,
      // keepPreviousData: true,
      // refetchOnReconnect: false,
      getNextPageParam: (lastPage, pages) => {
        console.log("getNexPAge: ", lastPage);
        console.log("getNexPAge2: ", pages);
        if (lastPage?.length >= offset) {
          console.log("getNexPAge: ? ", lastPage?.length, offset);
          return pages.length * offset;
        } else return undefined;
      },
      // getNextPageParam: () => {
      //   setStatDataStart(statDataStart + offset);
      //   return statDataStart + offset;
      // },
    }
  );

  useEffect(() => {
    console.log("DRAWING: FIRST ", userId, startDate);
    // refetch();
  }, []);
  console.log("DRAWING: INFO ", userId, startDate);

  // if (status === "loading") {
  //   // setLoading(true);
  //   // log.info(limaLogTag.UserStatisticList, "stat is loading: ", data);
  //   // return (
  //   //   <>
  //   //     <div>... getting stat ...</div>
  //   //     <div>
  //   //       <Shimmer width="80%" />
  //   //       <Shimmer width="80%" />
  //   //       <Shimmer width="80%" />
  //   //     </div>
  //   //   </>
  //   // );
  // }
  // if (status === "error") {
  //   return <div>{error!.message}</div>;
  // }
  // if (status === "success") {
  //   log.info(limaLogTag.UserStatisticList, "stat loaded: ", data);
  //   setStatData(data);
  // }

  // useEffect(() => {
  //   getData();
  //   //setUseDate(startDate);
  // }, [statData]);

  // const getData = () => {
  //   setLoading(true);
  //   refetch()
  //     .then(() => {
  //       if (status === "success") {
  //         log.info(limaLogTag.UserStatisticList, "stat loaded: ", data);
  //         setStatDataStart(statDataStart + offset);
  //         setStatData(statData.concat(data));
  //       }
  //     })
  //     .catch((error) => {
  //       log.error(limaLogTag.UserStatisticList, "stat error: ", error);
  //     })
  //     .finally(() => setLoading(false));
  // };

  const getStatTextLine = (item: Statistic) => {
    return <>{StatConverToText(item)}</>;
  };

  const showLoading = (): JSX.Element => {
    log.info(limaLogTag.UserStatisticList, "stat is loading: ", data);
    return (
      <>
        <div>... getting stat ...</div>
        <div>
          <Shimmer width="80%" />
          <Shimmer width="80%" />
          <Shimmer width="80%" />
        </div>
      </>
    );
  };

  return (
    <>
      Basic statistic list
      <Separator />
      {status === "loading" ? (
        <p>Loading...</p>
      ) : status === "error" ? (
        <p>Error: {error.message}</p>
      ) : (
        <Stack>
          {data &&
            data.pages.map((group: Statistic[], i: number) =>
              // <div key={i}>{group.map((item: Statistic) => getStatTextLine(item))}</div>
              group.map((item: Statistic, j) => {
                return <Stack.Item key={String(i) + "-" + String(j)}>{getStatTextLine(item)}</Stack.Item>;
              })
            )}

          {/* {isFetchingNextPage ? (
              <div>Loading more...</div>
            ) : hasNextPage ? (
              <div>Error: {error.message}</div>
            ) : (
              <div>
                {data &&
                  data.pages.map((group, i) => (
                    <React.Fragment key={i}>{group.map((item: Statistic) => getStatTextLine(item))}</React.Fragment>
                  ))}
              </div>
            )} */}

          <Stack.Item>
            <IconButton
              iconProps={extendStatList}
              // disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => {
                void fetchNextPage();
                console.log("addd");
              }}
            />
            {isFetching && !isFetchingNextPage && showLoading}
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default UserStatisticList;

//----------------------
//   REACT QUERY
//----------------------

// type Params = {
//   pageParam: number;
//   queryKey: [string, { userid: string; foruserid: string; startDate: Date; offset: number }];
// };
type Params2 = { foruserid: string; startDate: Date; offset: number; pageParam: number };

const getUserListStat = async (params: Params2): Promise<Statistic[]> => {
  console.log("HELLO", params);
  const { foruserid, startDate, offset } = params;
  let startDateLocal = startDate;
  if (startDateLocal === undefined || startDateLocal === null) startDateLocal = moment().toDate();
  if (params.pageParam === undefined) params.pageParam = 0;

  console.log("DATA ", params.pageParam, params);
  const loaderID = limaLoadeStore.add("Getting stat from DB");
  let response: HttpResponse<Statistic[]>;
  try {
    response = await httpGetAuth<Statistic[]>(
      urlGetUserListStatistic(foruserid, startDateLocal.toISOString(), params.pageParam, offset),
      loaderID.ac
    );

    log.info(limaLogTag.UserStatisticList, "getUserListStat: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.UserStatisticList, "getUserListStat: parsedBody is not null", response.parsedBody);
      const userListCopies = response.parsedBody;
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return userListCopies;
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.UserStatisticList, "getUserListStat Error no data", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      return null;
    }
  } catch (response) {
    log.error(limaLogTag.UserStatisticList, "getUserListStat Error call", response);
    // throw new Error("Problem geting basic Stat");
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    return null;
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
