import { log } from "missionlog";
import moment = require("moment");
//import moment = require("moment");
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useQuery } from "react-query";
import { urlGetUserPerm, urlUpdateUserPerm } from "../../../../../api/apilinks";
import { httpGetAuth, httpPostAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import {
  LIMAUSER_ACTA_PERMISSIONS,
  LIMAUSER_COMPANY_PERMISSIONS,
  LIMAUSER_GENERAL_PERMISSIONS,
  LIMAUSER_PERMISSIONS_TYPE,
} from "../../../../../limaCommon/limaPermissions";
import limaLoadeStore, { LoaderItemEnd } from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import {
  ILimaPermission,
  limaPermissions,
  limaPermissionsActa,
  limaPermissionsCompany,
  UserPermissions,
} from "../../../../../types/UserPermissions";
import UserPermissionsList from "./UserPermissionsList";
import { useTranslation } from "react-i18next";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../../limaComponents/LimaNotice";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import { Stack, Text } from "@fluentui/react";
import limaPermissionStore from "../../../../../store/limaPermissionStore";
// import UserPermissionsList from "./UserPermissionsList";

interface UserPermissionEditPageProps {
  userId: string;
  actaId: string;
  companyKey: string;
}

const UserPermissionEditPage: FunctionComponent<UserPermissionEditPageProps> = (props: UserPermissionEditPageProps) => {
  const [permissionsList, setPermissionsList] = useState<ILimaPermission | undefined>(undefined);
  const { t } = useTranslation();

  const { status, error, data, refetch } = useQuery(
    ["userPermissionsList", { userId: props.userId, actaId: props.actaId, companyKey: props.companyKey }],
    () => getUserPermissions(props.userId, props.companyKey, props.actaId),
    {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.UserPermissionsList, "useQuery get permissions ", data);
        setPermissionsList({
          acta_permissions: data.acta_permissions,
          company_permissions: data.company_permissions,
          general_permissions: data.general_permissions,
          isSet: true,
        });
        if (props.userId === limaStore.userId) {
          limaPermissionStore.setPermissions(data.general_permissions, data.company_permissions, data.acta_permissions);
        }
      },
    }
  );

  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    log.info(limaLogTag.UserPermissionsList, "UEF: refetch permissions loading: ", props);
    void refetch();
  }, [props.userId, props.actaId, props.companyKey]);

  if (status === "loading") {
    log.info(limaLogTag.UserPermissionsList, "permissions loading: ", data);
    return LimaLoadingComponnet(5, t("tpuser:userdetial.persmissions.loadingLbl"), false);
  }
  if (status === "error") {
    log.error(limaLogTag.UserPermissionsList, "permissions loading : ", error);
    return (
      <div>
        <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>ERROR: {error}</LimaNotice>
      </div>
    );
  }

  //---------------------
  //    HANDLERS
  //---------------------

  const updatePermissions = (
    type: LIMAUSER_PERMISSIONS_TYPE,
    params: limaPermissions | limaPermissionsActa | limaPermissionsCompany,
    permissionsList: ILimaPermission
  ) => {
    const updatePermissionsList = limaPermissionStore.updatePermission(type, params, permissionsList);
    if (props.userId === limaStore.userId) {
      limaPermissionStore.setPermissions(
        updatePermissionsList.general_permissions,
        updatePermissionsList.company_permissions,
        updatePermissionsList.acta_permissions
      );
    }

    setPermissionsList(updatePermissionsList);
  };

  const addPermission = async (permissionIdent: string, type: LIMAUSER_PERMISSIONS_TYPE) => {
    const paramsBase: limaPermissions = {
      actualState: true,
      cIssuedBy: limaStore.userId,
      cTimeStamp: moment().format(),
      dIssuedBy: "",
      dtimestamp: "",
      _id: null,
      _key: null,
      permissionIdent: permissionIdent,
      userKey: props.userId,
    };

    log.info(limaLogTag.UserPermissionsList, "addPermission: paramsBase", paramsBase);

    //let params: limaPermissions | limaPermissionsActa | limaPermissionsCompany = paramsBase
    if (type === LIMAUSER_PERMISSIONS_TYPE.ACTA) {
      const paramsActa: limaPermissionsActa = { ...paramsBase, actaKey: limaStore.actaId };
      await updatePermission(props.userId, type, paramsActa).then((permission) => {
        log.info(limaLogTag.UserPermissionsList, "updatePermission: ACTA permission", permission);
        // setPermissionsList({ ...permissionsList, acta_permissions: [...permissionsList.acta_permissions, permission] });
        // setPermissionsList(limaPermissionStore.updatePermission(type, paramsActa, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, paramsActa, permissionsList);
      });
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.COMPANY) {
      const paramsCompany: limaPermissionsCompany = { ...paramsBase, companykey: props.companyKey };
      await updatePermission(props.userId, type, paramsCompany).then((permission) => {
        log.info(limaLogTag.UserPermissionsList, "updatePermission: COMPANY permission", permission);
        // setPermissionsList(limaPermissionStore.updatePermission(type, paramsCompany, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, paramsCompany, permissionsList);
      });
    } else if (type === LIMAUSER_PERMISSIONS_TYPE.GENERAL) {
      log.info(limaLogTag.UserPermissionsList, "updatePermission: GENERAL permission");
      await updatePermission(props.userId, type, paramsBase).then((permission) => {
        log.info(limaLogTag.UserPermissionsList, "updatePermission: GENERAL permission", permission);
        // setPermissionsList(limaPermissionStore.updatePermission(type, paramsBase, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, paramsBase, permissionsList);
      });
    }
  };

  const removePermission = (
    permission: limaPermissionsCompany | limaPermissions | limaPermissionsActa,
    type: LIMAUSER_PERMISSIONS_TYPE
  ) => {
    permission.dIssuedBy = limaStore.userId;
    permission.dtimestamp = moment().format();
    permission.actualState = false;

    log.info(limaLogTag.UserPermissionsList, "removePermission: permission", permission, type);

    void updatePermission(props.userId, type, permission).then(() => {
      if (type === LIMAUSER_PERMISSIONS_TYPE.ACTA) {
        log.info(limaLogTag.UserPermissionsList, "removePermission: ACTA permission");
        // setPermissionsList(limaPermissionStore.updatePermission(type, permission, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, permission, permissionsList);
      } else if (type === LIMAUSER_PERMISSIONS_TYPE.COMPANY) {
        log.info(limaLogTag.UserPermissionsList, "removePermission: COMPANY permission");
        // setPermissionsList(limaPermissionStore.updatePermission(type, permission, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, permission, permissionsList);
      } else if (type === LIMAUSER_PERMISSIONS_TYPE.GENERAL) {
        log.info(limaLogTag.UserPermissionsList, "removePermission: GENERAL permission");
        // setPermissionsList(limaPermissionStore.updatePermission(type, permission, permissionsList));
        // if (props.userId === limaStore.userId) {
        //   limaPermissionStore.updatePermission(type, permission);
        // }
        updatePermissions(type, permission, permissionsList);
      }
    });
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      {/* {limaPermissionStore.userPermissons && ( */}
      <>
        <Stack.Item>
          <Text variant="medium">{t("tpuser:userdetial.persmissions.generalPermissionListLbl")}</Text>
        </Stack.Item>
        <Stack.Item>
          <UserPermissionsList
            permissionsList={permissionsList !== undefined ? permissionsList.general_permissions : undefined}
            permissionsEnum={LIMAUSER_GENERAL_PERMISSIONS}
            permissionsEnumTYPE={LIMAUSER_PERMISSIONS_TYPE.GENERAL}
            addPermission={addPermission}
            removePermission={removePermission}
          />
        </Stack.Item>
        <Stack.Item>
          <Text variant="medium">{t("tpuser:userdetial.persmissions.companyPermissionListLbl")}</Text>
        </Stack.Item>
        <Stack.Item>
          <UserPermissionsList
            permissionsList={permissionsList !== undefined ? permissionsList.company_permissions : undefined}
            permissionsEnum={LIMAUSER_COMPANY_PERMISSIONS}
            permissionsEnumTYPE={LIMAUSER_PERMISSIONS_TYPE.COMPANY}
            addPermission={addPermission}
            removePermission={removePermission}
          />
        </Stack.Item>
        <Stack.Item>
          <Text variant="medium">
            {t("tpuser:userdetial.persmissions.actaPermissionListLbl")} - for acta owner only
          </Text>
        </Stack.Item>
        <Stack.Item>
          {limaStore.IamDocumentOwner === true && (limaStore.actaIsOpen === true || limaStore.versionIsOpen) ? (
            <UserPermissionsList
              permissionsList={permissionsList !== undefined ? permissionsList.acta_permissions : undefined}
              permissionsEnum={LIMAUSER_ACTA_PERMISSIONS}
              permissionsEnumTYPE={LIMAUSER_PERMISSIONS_TYPE.ACTA}
              addPermission={addPermission}
              removePermission={removePermission}
            />
          ) : (
            <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>no Acta where u r owner</LimaNotice>
          )}
        </Stack.Item>
      </>
      {/* )} */}
    </Stack>
  );
};

export default UserPermissionEditPage;

/**
 * Storing perrmission to DB
 * @param onUserId
 * @param type
 * @param callParams
 * @returns
 */
const updatePermission = async (
  onUserId: string,
  type: LIMAUSER_PERMISSIONS_TYPE,
  callParams: limaPermissionsCompany | limaPermissions
) => {
  const loaderID = limaLoadeStore.add("update permissons");
  let result: HttpResponse<any>; //TODO: change to type
  try {
    log.info(limaLogTag.UserPermissionsEditPage, "updatePermission: try to send to get something", callParams);
    result = await httpPostAuth<any>(urlUpdateUserPerm(onUserId, type), callParams, loaderID.ac);

    log.info(limaLogTag.UserPermissionsEditPage, "updatePermission: asketo to get something", result);
    if (result.parsedBody !== undefined) {
      log.info(limaLogTag.UserPermissionsEditPage, "updatePermission: parsedBody is not null", result.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.parsedBody;
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.UserPermissionsEditPage, "updatePermission:no data");
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      return null;
    }
  } catch (response) {
    log.error(limaLogTag.UserPermissionsEditPage, "updatePermission: Error call get User", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    return null;
    // throw new Error("Problem geting permissions");
  } finally {
    //this.setLoadingData(false);
  }
};

//----------------------
//   REACT QUERY
//----------------------

const getUserPermissions = async (userId: string, companyKey: string, actaId: string): Promise<UserPermissions> => {
  log.info(limaLogTag.UserPermissionsEditPage, "fetching data getUserPermissions");
  const loaderID = limaLoadeStore.add("Getting permissions from DB");
  try {
    const response: HttpResponse<UserPermissions> = await httpGetAuth<UserPermissions>(
      urlGetUserPerm(userId, companyKey, actaId),
      loaderID.ac
    );
    log.info(limaLogTag.UserPermissionsEditPage, "fetching data getUserPermissions response", response);
    if (!response.ok) {
      //throw response;
      log.error(limaLogTag.UserPermissionsEditPage, "getUserPermissions:no response", response);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      return Promise.reject();
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.UserPermissionsEditPage,
          "getUserPermissions: asketo to get something",
          response.parsedBody
        );
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return Promise.resolve(response.parsedBody);
    }
    //throw new Error("no data");
    log.error(limaLogTag.UserPermissionsEditPage, "getUserPermissions:no response data", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    return Promise.reject();
  } catch (error) {
    log.error(limaLogTag.UserPermissionsEditPage, "getUserPermissions: some other error", error);
    // throw new Error(error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    return Promise.reject();
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
