import { Stack } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");
import limaStore from "../../../../../store/limaStore";
import EditStates from "../../../Edit/States/components/EditStates";
import FileSaveToXCopy from "../../FileSave/components/FileSaveToCopy";
import FileSaveToExists from "../../FileSave/components/FileSaveToExists";

interface FileItemCompareHandlersProps {
  onStore: () => void;
}

const FileItemCompareHandlers: FunctionComponent<FileItemCompareHandlersProps> = () => {
  return (
    <Stack>
      <Stack.Item>
        <EditStates />
      </Stack.Item>
      {limaStore.actaIsOpen && (
        <Stack.Item>
          <FileSaveToExists />
        </Stack.Item>
      )}
      {limaStore.xcopyIsOpen && (
        <Stack.Item>
          <FileSaveToXCopy />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FileItemCompareHandlers;
