import { DatePicker, IDatePickerProps, Label, Stack } from "@fluentui/react";
import { FC, ReactElement } from "react";
import React = require("react");
// import { useTranslation } from "react-i18next";

interface ILimaSimpleDatePickerProps {
  limaLabel?: string;
}

export const LimaSimpleDatePicker: FC<IDatePickerProps & ILimaSimpleDatePickerProps> = (
  props: IDatePickerProps & ILimaSimpleDatePickerProps
): ReactElement => {
  // const { t } = useTranslation();

  return (
    <Stack horizontal>
      {props.limaLabel && (
        <Stack.Item style={{ width: "30%" }}>
          <Label>{`${props.limaLabel} `}</Label>
        </Stack.Item>
      )}
      <Stack.Item grow={1}>
        <DatePicker
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          {...props}

          // onSelectDate={(date) => {
          //   console.log("Date change to ", date);
          //   props.onChange(date);
          //   return date;
          // }}
          // onChange={onChange}
          // onBlur={onBlur}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          // value={value}
        />
      </Stack.Item>
    </Stack>
  );
};
