import { Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LimaIconToolsActs } from "../../../../assets/icons/LimaIconToolsActs";
import { LimaIconToolsCheckdoc } from "../../../../assets/icons/LimaIconToolsCheckdoc";
import { LimaIconToolsJudi } from "../../../../assets/icons/LimaIconToolsJudi";
import { LimaIconToolsKeyWords } from "../../../../assets/icons/LimaIconToolsKeyWords";
import Routes from "../../../Menu/routes";
import LimaToolItem from "./components/LimactaToolItem";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToolsAdvisorPartProps {}

const ToolsAdvisorPart: React.FunctionComponent<IToolsAdvisorPartProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Stack horizontal wrap={true}>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.advisor.actToolLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.AdviceMenuAdviceActSearchRoute}`);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll}
          dontShowOnNoLic={true}
        >
          <LimaIconToolsActs title={t("tpmain:tools.advisor.actToolLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.advisor.judiToolLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.AdviceMenuAdviceJudgeSearchRoute}`);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll}
          dontShowOnNoLic={true}
        >
          <LimaIconToolsJudi title={t("tpmain:tools.advisor.judiToolLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.advisor.keywordsToolLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.AdviceMenuAdviceKeywordsRoute}`);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll}
          dontShowOnNoLic={true}
        >
          <LimaIconToolsKeyWords title={t("tpmain:tools.advisor.keywordsToolLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.advisor.checkdocToolLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.AdviceMenuAdviceCheckDocRoute}`);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll}
          dontShowOnNoLic={true}
        >
          <LimaIconToolsCheckdoc title={t("tpmain:tools.advisor.checkdocToolLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
    </Stack>
  );
};

export default ToolsAdvisorPart;
