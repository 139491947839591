import * as React from "react";
import { useEffect, useState } from "react";
import { urlPostNewCompany, urlPutUpdateCompany } from "../../../../api/apilinks";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { log } from "missionlog";
import { httpPostAuth, httpPutAuth } from "../../../../api/httpAPI";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { ILimaCompany } from "../../../../types/LimaCompany";
import { useMutation, useQuery } from "react-query";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice } from "../../../../limaComponents/LimaNotice";
import { FocusZone, FocusZoneDirection, List, Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { LimaSimpleButtonV4 } from "../../../../limaComponents/LimaSimpleButtonV4";
import CompanyItem from "./CompanyItem";
import { fetchCompanies } from "../../../../api/calls/LimaCompaniesCalls";

// interface ICompaniesEditPageProps {}

interface ICompaniesList {
  companies: ILimaCompany[];
}

const CompaniesEditPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [companiesList, setCompaniesList] = useState<ILimaCompany[]>([]);
  const [isNewLineExists, setIsNewLineExist] = useState<boolean>(false);
  const { mutateAsync: mutatePatchCompany } = useAskToPathCompanyQuery();
  const { mutateAsync: mutatePostCompany } = useAskToPostCompanyQuery();

  const { isLoading, isRefetching, error, refetch } = useQuery(["compsList"], () => fetchCompanies(), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.CompaniesPage, "useQuery get companies ", data);
      // setMessages(data);
      setCompaniesList(data.map((item) => item.company));
    },
  });

  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    log.info(limaLogTag.CompaniesPage, "UEF get companies ");
    void refetch();
  }, []);

  //---------------------
  //    HANDLERS
  //---------------------

  const onAddCompany = (companyName: string) => {
    log.info(limaLogTag.CompaniesPage, "onAddCompany ", companyName);
    void mutatePostCompany(companyName)
      .then((dataOfCreate: ILimaCompany) => {
        log.info(limaLogTag.CompaniesPage, "onAddCompany mutate finish, go to update ", dataOfCreate);
        // setCompaniesList([...setCompaniesList])
        setCompaniesList([
          ...companiesList.map((item) => (item._key === undefined ? { ...item, ...dataOfCreate } : item)),
        ]);
        setIsNewLineExist(false);
      })
      .catch(() => {
        log.error(limaLogTag.UsersListV4, "on create user: error");
      });
  };

  const onUpdateCompany = (company: ILimaCompany) => {
    log.info(limaLogTag.CompaniesPage, "onUpdateCompany ", company);
    void mutatePatchCompany(company)
      .then((companyOut: ILimaCompany) => {
        log.info(limaLogTag.CompaniesPage, "onAddCompany mutate finish, go to update ", companyOut);
        setCompaniesList([
          ...companiesList.map((item) => (item._key === companyOut._key ? { ...item, ...companyOut } : item)),
        ]);
      })
      .catch(() => {
        log.error(limaLogTag.UsersListV4, "on create user: error");
      });
  };

  const onAddCompanyRow = (companyName: string) => {
    if (isNewLineExists === false) {
      const newCompany: ILimaCompany = {
        _id: undefined,
        _key: undefined,
        cIssuedBy: undefined,
        // companylicenceid: undefined,
        complicences: [],
        companyname: companyName,
        cTimeStamp: "",
        dIssuedBy: "",
        dtimestamp: "",
        // licencecount: 0,
      };
      setCompaniesList([...companiesList, newCompany]);
      setIsNewLineExist(true);
    }
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  const onRenderCell = (item: ILimaCompany): JSX.Element => {
    log.info(limaLogTag.CompaniesPage, `onRenderCell: on render `, item);
    //return <>comp - {item.company.companyname}</>;
    return <CompanyItem company={item} onStore={onAddCompany} onUpdate={onUpdateCompany} />;
  };

  const CompaniestList = (props: ICompaniesList): JSX.Element => {
    return (
      // <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
      <List items={props.companies} onRenderCell={onRenderCell} />
      // </ScrollablePane>
    );
  };

  if (isLoading || isRefetching) {
    return LimaLoadingComponnet(3, "tasklist");
  }

  if (error) {
    log.error(limaLogTag.CompaniesPage, "stat loaded error: ", error);
    return <LimaNotice>{t("tpshow:history.errorloadingLbl")}</LimaNotice>;
  }

  return (
    <Stack style={{ padding: 5 }}>
      <Stack.Item>
        <Text variant={"large"}>{t("tpuser:tools.companies.labelLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <LimaSimpleButtonV4 onClick={() => onAddCompanyRow("")} disabled={isNewLineExists}>
          {t("tpuser:tools.companies.addCompanyBtnLbl")}
        </LimaSimpleButtonV4>
      </Stack.Item>
      <Stack.Item>
        <FocusZone direction={FocusZoneDirection.vertical}>
          <CompaniestList companies={companiesList} />
        </FocusZone>
      </Stack.Item>
    </Stack>
  );
};
export default CompaniesEditPage;

//----------------------
//   REACT QUERY
//----------------------

const useAskToPostCompanyQuery = () => {
  return useMutation(postCompany, {
    onMutate: (companyName: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.CompaniesPage, "call mutate useAskToPostCompanyQuery ", companyName);
      return { id: 1 };
    },
    onSuccess: (data: ILimaCompany) => {
      log.info(limaLogTag.CompaniesPage, "call mutate useAskToPostCompanyQuery ", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const postCompany = async (companyName: string): Promise<ILimaCompany> => {
  log.info(limaLogTag.CompaniesPage, "mutate data for postCompany", companyName);
  // const [, { patientID }] = params.queryKey;

  if (companyName === undefined || companyName === null || companyName === "") {
    log.warn(limaLogTag.CompaniesPage, "no companyName ", companyName);
    return null;
  }

  const loaderID = limaLoadeStore.add(`ask to create company`);
  try {
    const response = await httpPostAuth<ILimaCompany>(urlPostNewCompany(), { companyName: companyName }, loaderID.ac);
    log.info(limaLogTag.UserEditContext, "mutate data postCompany response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const useAskToPathCompanyQuery = () => {
  return useMutation(putCompany, {
    onMutate: (company: ILimaCompany) => {
      // A mutation is about to happen!
      log.info(limaLogTag.CompaniesPage, "call mutate useAskToPathCompanyQuery ", company);
      return { id: 1 };
    },
    onSuccess: (data: ILimaCompany) => {
      log.info(limaLogTag.CompaniesPage, "call mutate useAskToPathCompanyQuery ", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const putCompany = async (company: ILimaCompany): Promise<ILimaCompany> => {
  log.info(limaLogTag.CompaniesPage, "mutate data for putCompany", company);
  // const [, { patientID }] = params.queryKey;

  if (company === undefined || company === null) {
    log.warn(limaLogTag.CompaniesPage, "no company ", company);
    return null;
  }
  if (company._key === undefined || company._key === null || company._id === undefined || company._id === null) {
    log.warn(limaLogTag.CompaniesPage, "no id ", company);
    return null;
  }

  const loaderID = limaLoadeStore.add(`ask to update company`);
  try {
    const response = await httpPutAuth<ILimaCompany>(urlPutUpdateCompany(), company, loaderID.ac);
    log.info(limaLogTag.UserEditContext, "fetching data putCompany response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
