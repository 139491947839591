import React = require("react");
// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router";
// import LimaActionButtonV4 from "../Components/LimaActionButtonV4";
// import Routes from "../../Menu/routes";
import { useEffect } from "react";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import UserCompanies from "./components/UserCompanies";

interface IHomeCompLic {}

const HomeCompLic: React.FunctionComponent<IHomeCompLic> = (props: IHomeCompLic) => {
  // const { t } = useTranslation(["tpmain"]);
  // const history = useHistory();

  //-----------------
  // REACTIONS
  //-----------------
  useEffect(() => {
    log.info(limaLogTag.HomeCompLic, `data`, props);
  }, []);

  //-----------------
  // HANDLERS
  //-----------------

  // const onContinue = () => {
  //   history.replace(Routes.HomeV2Route);
  // };

  //-------------------
  // RENDERS
  //------------------

  return (
    <>
      <UserCompanies />
      {/* <LimaActionButtonV4 onClick={onContinue} toolTipShow={false}>
        {t("tpmain:homecomplic.continueBtnLbl")}
      </LimaActionButtonV4> */}
    </>
  );
};

export default HomeCompLic;
