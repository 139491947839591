import {
  ActionButton,
  FontIcon,
  getFocusStyle,
  getTheme,
  IIconProps,
  ITheme,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent } from "react";
import React = require("react");
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { FILE_TYPE, getFileType, LimaAttachement } from "../../../../types/LimaAttachement";

interface AttachmentItemProps {
  attachement: LimaAttachement;
  onDownloadHandler?: () => void;
  onRemoveAtt?: () => void;
}

const getFileIcon: IIconProps = { iconName: "RedEye" };
const removeFileIcon: IIconProps = { iconName: "Cancel" };

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

const classNames = mergeStyleSets({
  stackWrapper: [
    getFocusStyle(theme, { inset: -1 }),
    {
      // flexGrow: 1,
      alignItems: "center",
      display: "flex",
      paddingRight: 30,
      minHeight: 30,
      paddingLeft: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  stackTitle: {},
});

const AttachmentItem: FunctionComponent<AttachmentItemProps> = ({
  attachement,
  onDownloadHandler,
  onRemoveAtt,
}: AttachmentItemProps) => {
  const onClickCallBack = () => {
    log.info(limaLogTag.AttachmentItem, "clicked on ", attachement);
    onDownloadHandler();
  };

  const AttachmentIcon = (fileTypeString: string): JSX.Element => {
    let iconName = "Document";
    switch (getFileType(fileTypeString)) {
      case FILE_TYPE.WORD:
        iconName = "WordDocument";
        break;
      case FILE_TYPE.EXCEL:
        iconName = "ExcelDocument";
        break;
      case FILE_TYPE.TEXT:
        iconName = "TextDocument";
        break;
      case FILE_TYPE.EMAIL:
        iconName = "Mail";
        break;
      case FILE_TYPE.IMAGE:
        iconName = "FileImage";
        break;
      case FILE_TYPE.PDF:
        iconName = "PDF";
        break;
      case FILE_TYPE.OTHER:
    }

    return <FontIcon aria-label={iconName} iconName={iconName} />;
  };

  const title = (): string => {
    return `filename: [${attachement.filename}] .. desc: ${attachement.description}`;
  };

  return (
    <Stack horizontal>
      {onRemoveAtt !== undefined && (
        <Stack.Item grow={1}>
          <ActionButton iconProps={removeFileIcon} onClick={onRemoveAtt}></ActionButton>
        </Stack.Item>
      )}
      <Stack.Item grow={11}>
        <Stack horizontal className={classNames.stackWrapper}>
          <Stack.Item grow={1} title={attachement.fileType}>
            {AttachmentIcon(attachement.fileType)}
          </Stack.Item>
          <Stack.Item grow={10} title={title()}>
            {attachement.name + (attachement.filesize && `(${attachement.filesize})`)}
          </Stack.Item>
          {onDownloadHandler !== undefined && (
            <Stack.Item grow={1}>
              <ActionButton iconProps={getFileIcon} text={"Get"} onClick={onClickCallBack}></ActionButton>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default AttachmentItem;
