import { log } from "missionlog";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useParams } from "react-router-dom";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import TaskItem from "./components/TaskItem";
// import TaskItemEdit2 from "./TaskItemEdit2";
import TasksListV3 from "./TaskListV3";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { User2Task_withActaXcopy } from "../../../../types/User2Task_withActaXcopy";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TaskItemsPage2Props {}

type TaskPageParams = {
  taskid?: string;
};

const TaskItemsPage2: FunctionComponent<TaskItemsPage2Props> = () => {
  const [currentUser2taskId, setCurrentUser2taskId] = React.useState<string | undefined>(undefined);
  const [currentTaskShow, setCurrentTaskShow] = React.useState<boolean>(false);
  const { t } = useTranslation(["tpmain"]);

  let { taskid } = useParams<TaskPageParams>();

  useEffect(() => {
    log.info(limaLogTag.TaskEditPageV2, "useEffect: mounting");
    if (taskid != undefined && taskid !== null && taskid !== ":taskid" && taskid != "taskid") {
      log.info(limaLogTag.TaskEditPageV2, `useEffect: taskid [${taskid}]`);
      // limaTasksStore.setEditTask(limaTasksStore.getTaskByKey(taskid));
      setCurrentUser2taskId(taskid);
      setCurrentTaskShow(true);
    } else taskid = null;
    log.info(limaLogTag.TaskEditPageV2, `useEffect: taskid not set`);
  }, [taskid]);

  const onTaskChange = (task: User2Task_withActaXcopy) => {
    log.info(limaLogTag.TaskEditPageV2, `onTaskChange: clicked -> now`, task, currentUser2taskId);
    setCurrentUser2taskId(task._key);
    setCurrentTaskShow(true);
  };

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.editLbl"), t("tpmain:menu.edit.tasklistLbl")]} />
      {/* {currentTaskShow ? <span>SHOQ{currentUser2taskId}</span> : <span>Hide{currentUser2taskId}</span>} */}
      <LimaHideableSection
        name={t("tptasks:taskedit.taskitem2.currenttaskshowhideLbl")}
        defaultHidden={!currentTaskShow}
        visible={currentTaskShow}
      >
        <TaskItem user2taskId={currentUser2taskId} />
      </LimaHideableSection>
      <LimaHideableSection name={t("tptasks:taskList.taskslistkshowhideLbl")}>
        <TasksListV3 onClickHandler={onTaskChange} onStartFilterClosedTasks={true}></TasksListV3>
      </LimaHideableSection>
    </>
  );
};

export default TaskItemsPage2;
