import { log } from "missionlog";
import { useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { updateTaskData } from "../../../../../api/calls/updateTaskData";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaStore from "../../../../../store/limaStore";
import limaTasksStoreV4 from "../../../../../store/limaTasksStoreV4";
import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../../../types/Task_withActaXcopy";
import { LimaEditItemBaseV4 } from "../../../Tasks/LimaEditItemBaseV4";
import { TaskItemV4 } from "../../../Tasks/TaskItemV4";

interface IToolActionAddTask {
  onDismissClb: () => void;
  showAddTask: boolean;
}

const onStoreTask = () => {
  log.info(limaLogTag.TaskItemV4Single, "onStore: onDetialSaveUpdateCallBack ");
  // window.console.log("ADDDDDDDDDDDDDDDDDDDDDDD");
  void updateTaskData(limaTasksStoreV4.editTask_withActaXcopy, limaStore.userId).then((task: Task_withActaXcopy) => {
    // window.console.log("I HAVE TASK UPDATED", task, filters);
    log.info(limaLogTag.TasksList, "onStore: updated Task", task);
    if (limaTasksStoreV4.editTask_withActaXcopy._key === null || limaTasksStoreV4.editTask_withActaXcopy._key === "") {
      window.console.log("I HAVE TASK UPDATED reload DB");
      //TODO: In future simple way
      void limaTasksStoreV4.getTasksListFromDB().then(() => {
        window.console.log("LOAD FINISH - DO IT BETTER like push new");
      });
    } else {
      window.console.log("I HAVE TASK UPDATED single task");
      limaTasksStoreV4.updateSingleTask_withTask_withActaXcopy(task);
    }
  });
  //limaTasksStore.updateSingleTask(limaTasksStore.editTask.task_withActaXcopy);
};

export const ToolActionAddTask: React.FunctionComponent<IToolActionAddTask> = (props: IToolActionAddTask) => {
  const [enableStore, setEnableStore] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <LimaEditItemBaseV4
      open={props.showAddTask}
      panelName={t("tptasks:taskedit.taskitemV4.taskEditPanelLbl")}
      editDetailEnableStore={enableStore}
      saveLbl={t("tptasks:taskedit.taskitemV4.saveTaskBtn")}
      onSaveUpdateCallBack={() => {
        log.info(limaLogTag.ToolActionAddTask, "ToolActionAddTask onSaveUpdateCallBack ....");
        // if (onDetialSaveUpdateCallBack !== undefined) onDetialSaveUpdateCallBack();
        onStoreTask();
      }}
      onDetialDismissCallBack={() => {
        log.info(limaLogTag.ToolActionAddTask, "ToolActionAddTask onDetialDismissCallBack ....");
        props.onDismissClb();
      }}
      detail={
        <TaskItemV4
          task={Task_withActaXcopy_getNull()}
          enableStore={(enable: boolean) => {
            window.console.log(`TASIEM SINGLE ENABLED STORE ${String(enable)}`);
            setEnableStore(enable);
          }}
        />
      }
    ></LimaEditItemBaseV4>
  );
};
