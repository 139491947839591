import { Dropdown, Icon, IDropdownOption, Label, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAllCopy4User, urlGetAllLinkedUsers, urlGetSimpleTaskList } from "../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../api/httpAPI";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import limaLoadeStore from "../../../../store/limaLoaderStore";
// import limaStore from "../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { isTask, Task } from "../../../../types/Task";
import { isUser, IUser } from "../../../../types/User";
import { isXcopy, Xcopy, XcopyWithActa } from "../../../../types/Xcopy";

interface AccessItemChoserProps {
  itemId?: string;
  type?: LIMA_ITEM_TYPE;
  enableChange?: boolean;
  onChangeCallBack?: (type: LIMA_ITEM_TYPE, id: string) => void;
}

const limaTypeOptions: IDropdownOption[] = [
  { key: LIMA_ITEM_TYPE.USER, text: "User", data: { icon: "Contact" } },
  { key: LIMA_ITEM_TYPE.TASK, text: "Task", data: { icon: "TaskSolid" } },
  { key: LIMA_ITEM_TYPE.XCOPY, text: "Xcopy", data: { icon: "Copy" } },
];

// const stackTokens: IStackTokens = { childrenGap: 20 };
const iconStyles = { marginRight: "8px" };

const AccessItemChooser: FunctionComponent<AccessItemChoserProps> = ({ type, enableChange, onChangeCallBack }) => {
  const { t } = useTranslation(["tpmain"]);
  const [selectedType, setSelectedType] = useState<IDropdownOption>();
  const [selectedTypeLIMA, setSelectedTypeLIMA] = useState<LIMA_ITEM_TYPE>(type);
  const [selectedItems, setSelectedItems] = useState<IDropdownOption[]>();
  const [selectedItem, setSelectedItem] = useState<IDropdownOption>();

  // const useGetLinkedTasks = (userId: string) => {
  const { isLoading, error, refetch } = useQuery("getTask4me", () => getItems4User(selectedTypeLIMA), {
    enabled: false,
    // queryHash: "getTaskOrUser4me",
    refetchOnWindowFocus: false,
    onSuccess: (data: Task[] | IUser[] | XcopyWithActa[]) => {
      log.info(limaLogTag.AccessItemChooser, "useQuery/onSuccess data,type ", data);
      const newItems: IDropdownOption[] = data.map(
        (item: Task | IUser | XcopyWithActa, index: number): IDropdownOption => {
          if (isTask(item)) return { key: item._key, text: item.taskName };
          else if (isUser(item)) return { key: item._key, text: item.userName };
          else if (isXcopy(item))
            return { key: item._key, text: `(${item.xcopytype}) ${item.linkedActa[0].actaname} [${item.xcopyIdent}]` };
          else return { key: index, text: String(index) + "_Unknown" };
        }
      );
      log.info(limaLogTag.AccessItemChooser, "useQuery/onSuccess data,type ", newItems);
      setSelectedItems(newItems);
    },
  });

  useEffect(() => {
    log.info(limaLogTag.AccessItemChooser, "UEF on change selectedTypeLIMA ", selectedTypeLIMA);
    if (selectedTypeLIMA !== undefined) void refetch();
  }, [selectedTypeLIMA]);

  useEffect(() => {
    if (type !== undefined) {
      const found = limaTypeOptions.filter((item) => item.key === type);
      found.length > 0 && setSelectedType(found[0]);
      //void refetch();
    }
  }, [type]);

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          option.data && option.data.icon && (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
          )
        }
        <span>{option.text}</span>
      </div>
    );
  };

  const onChange = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    log.info(limaLogTag.AccessItemChooser, "on change of type: ", LIMA_ITEM_TYPE[item.key]);
    setSelectedType(item);
    setSelectedTypeLIMA(LIMA_ITEM_TYPE[item.key]);
    setSelectedItem(null);
    onChangeCallBack(selectedTypeLIMA, null);
    // onChangeCallBack(LIMA_ITEM_TYPE[item.key], "");
  };

  const onChangeItem = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    log.info(limaLogTag.AccessItemChooser, "on change of item: ", selectedTypeLIMA, String(item.key));
    setSelectedItem(item);
    onChangeCallBack(selectedTypeLIMA, String(item.key));
  };

  return (
    <Stack>
      <Stack.Item>
        <Label>{t("tpmain:component.linking.typeLbl")}</Label>
        <Dropdown
          placeholder={t("tpmain:component.linking.typePlaceholderDD")}
          // label="Type"
          ariaLabel={t("tpmain:component.linking.typeAriaDD")}
          selectedKey={selectedType ? selectedType.key : undefined}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onChange}
          // onRenderPlaceholder={onRenderPlaceholder}
          // onRenderTitle={onRenderTitle}
          onRenderOption={onRenderOption}
          // onRenderCaretDown={onRenderCaretDown}
          // styles={dropdownStyles}
          options={limaTypeOptions}
          disabled={!enableChange}
        />
      </Stack.Item>
      <Stack.Item>
        <Label>{t("tpmain:component.linking.itemLbl")}</Label>
        {!selectedItems ? (
          <>{t("tpmain:component.linking.itemNoItemsLbl")}</>
        ) : isLoading === true ? (
          <>{LimaLoadingComponnet(1)}</>
        ) : error === true ? (
          <>{t("tpmain:component.linking.itemErrorLbl")}</>
        ) : (
          <Dropdown
            placeholder={t("tpmain:component.linking.itemPlaceholderDD")}
            // label="Item"
            ariaLabel={t("tpmain:component.linking.itemAriaDD")}
            selectedKey={selectedItem ? selectedItem.key : undefined}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onChangeItem}
            // onRenderPlaceholder={onRenderPlaceholder}
            // onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
            // onRenderCaretDown={onRenderCaretDown}
            // styles={dropdownStyles}
            options={selectedItems}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default AccessItemChooser;

/**
 *  change type ->
 *  recall Items
 *  Show Items in list
 *
 */

//----------------------
//   REACT QUERY
//----------------------

//----QUERIES

const getItems4User = async (type: LIMA_ITEM_TYPE): Promise<Task[] | IUser[] | Xcopy[]> => {
  log.info(limaLogTag.AccessItemChooser, "fetching data for user for: ", type);
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add(`Check get task list`);
  // if (userId === undefined || userId === null || userId === "") {
  //   return [];
  // }
  let response: HttpResponse<Task[] | IUser[] | Xcopy[]>;
  try {
    if (type === LIMA_ITEM_TYPE.TASK) response = await httpGetAuth<Task[]>(urlGetSimpleTaskList(), loaderID.ac);
    else if (type === LIMA_ITEM_TYPE.USER) response = await httpGetAuth<IUser[]>(urlGetAllLinkedUsers(), loaderID.ac);
    else if (type === LIMA_ITEM_TYPE.XCOPY)
      response = await httpGetAuth<XcopyWithActa[]>(getAllCopy4User(true, true), loaderID.ac);

    log.info(limaLogTag.AccessItemChooser, "fetching data getUserByEmail response", response);
    if (!response.ok) {
      throw response;
    }
    // if (response.parsedBody !== undefined) {
    //   if (Array.isArray(response.parsedBody))
    //     log.info(limaLogTag.UserLinkingPage, "fetching data getUserByEmail response.parsedBody", response.parsedBody);

    return response.parsedBody;
    // }
    // throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
