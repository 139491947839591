import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { ICompanyLicenceItem, LimaEnum_licenceType } from "../../../../types/LimaLicences";
import { Dropdown, IDropdownOption, SpinButton, Stack, TextField, Toggle } from "@fluentui/react";
import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";

interface ICompanyLicenceItemProps {
  licenceItem: ICompanyLicenceItem;
  index: number;
  onRemove: (index: number) => void;
  onChange: (index: number, item: ICompanyLicenceItem) => void;
}

const options: IDropdownOption[] = [
  { key: LimaEnum_licenceType.FREEUSER, text: "Free user" },
  { key: LimaEnum_licenceType.BASIC, text: "Basic" },
  { key: LimaEnum_licenceType.ADVANCED, text: "Advanced" },
  { key: LimaEnum_licenceType.NONE, text: "---" },
];

const CompanyLicenceItem: FunctionComponent<ICompanyLicenceItemProps> = (props: ICompanyLicenceItemProps) => {
  const [licenceInnerItem, setLicenceInnerItem] = useState<ICompanyLicenceItem>(props.licenceItem);
  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    props.onChange(props.index, licenceInnerItem);
  }, [licenceInnerItem]);

  useEffect(() => {
    setLicenceInnerItem(props.licenceItem);
  }, [props.licenceItem]);

  //---------------------
  //    HANDLERS
  //---------------------
  // const onChangeItem = () => {
  //   props.onChange(props.index, props.licenceItem);
  // };

  const onRemoveItem = () => {
    props.onRemove(props.index);
  };

  const onLicenceTypeChange = (_e: React.FormEvent<HTMLDivElement>, type?: IDropdownOption) => {
    if (type !== undefined && typeof type.key === "string") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setLicenceInnerItem({ ...licenceInnerItem, licenceType: LimaEnum_licenceType[type.key] });
    }

    window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  };

  const onLicenceIdentChange = (_?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
    setLicenceInnerItem({ ...licenceInnerItem, licenceid: text });
    window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  };

  const onLicenceCountChange = (_event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string): void => {
    window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
    if (Number(newValue) < 0) newValue = String(0);
    if (Number(newValue) > 100) newValue = String(100);
    setLicenceInnerItem({ ...licenceInnerItem, licencecount: Number(newValue) });
    window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  };

  const onLicenceConcChange = (_ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    //this.setState({ showing: !showing });
    window.console.log(">>>> update onLicenceConcChange", checked);
    setLicenceInnerItem({ ...licenceInnerItem, concurent: checked });
    window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  };

  // const onLicenceCountIncrement = () => {
  //   window.console.log(">>>> update onLicenceConcChange", licenceInnerItem.licencecount);
  //   if (licenceInnerItem.licencecount < 100)
  //     setLicenceInnerItem({ ...licenceInnerItem, licencecount: licenceInnerItem.licencecount++ });
  //   window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  // };

  // const onLicenceCountDecrement = () => {
  //   window.console.log(">>>> update onLicenceConcChange", licenceInnerItem.licencecount);
  //   if (licenceInnerItem.licencecount > 0)
  //     setLicenceInnerItem({ ...licenceInnerItem, licencecount: licenceInnerItem.licencecount-- });
  //   window.console.log(">>>> update onLicenceConcChange", licenceInnerItem);
  // };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack horizontal>
      <Stack.Item style={{ width: "25%", margin: 2 }}>
        <Dropdown defaultSelectedKey={props.licenceItem.licenceType} options={options} onChange={onLicenceTypeChange} />
      </Stack.Item>
      <Stack.Item style={{ width: "30%", margin: 2 }}>
        <TextField
          // eslint-disable-next-line react/jsx-no-bind
          value={licenceInnerItem.licenceid}
          onChange={onLicenceIdentChange}
        />
      </Stack.Item>
      <Stack.Item style={{ width: "10%", margin: 2 }}>
        <Toggle checked={licenceInnerItem.concurent} onChange={onLicenceConcChange}></Toggle>
      </Stack.Item>
      <Stack.Item style={{ width: "25%", margin: 2 }}>
        <SpinButton
          value={String(licenceInnerItem.licencecount)}
          min={0}
          max={100}
          step={1}
          onChange={onLicenceCountChange}
        />
      </Stack.Item>
      <Stack.Item style={{ width: "10%", margin: 5 }}>
        <LimaActionButtonV4 onClick={onRemoveItem} toolTipShow={false}>
          Remove
        </LimaActionButtonV4>
      </Stack.Item>
    </Stack>
  );
};
export default CompanyLicenceItem;

//----------------------
//   REACT QUERY
//----------------------
