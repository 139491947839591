import { mergeStyleSets, Stack } from "@fluentui/react";
import moment = require("moment");
import * as React from "react";
import { useTranslation } from "react-i18next";
import { actaListOK } from "../../../../api/schema/getActaList";
import limaActasStore from "../../../../store/limaActasStore";
import limaTasksStoreV4 from "../../../../store/limaTasksStoreV4";
import { LimaObjectTypesEnum } from "../../../../types/BASE_Enums";
import { ITimePerActa, ITimerPartion } from "../../../../types/Timer";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../../types/User2Task_withActaXcopy";

interface ITimerPartionProps {
  partionItem: ITimePerActa;
}

const classNames = mergeStyleSets({
  timeOnStart: {
    flex: "none",
    paddingRight: 15,
  },
  timeOnEnd: {
    flex: "none",
    paddingLeft: 15,
  },
  // historyLbl: {
  //   cursor: "pointer",
  // },
  actaDetailSubItemRow: {
    paddingLeft: 8,
  },
  actaDetailItemRow: {
    paddingTop: 8,
  },
});

const TimerPartion: React.FunctionComponent<ITimerPartionProps> = (props: ITimerPartionProps) => {
  const { t } = useTranslation();

  const actaName = (actaId: string, long?: boolean): JSX.Element => {
    const itemActa: actaListOK = limaActasStore.getActa(actaId);

    if (itemActa === null) return <></>;
    if (long === true) {
      // return <div>{`${t("tpmain:timer.maindocumentLbl")} ${itemActa.name}`}</div>;
      return <div>{`${t("tpmain:timer.maindocumentLbl")}`}</div>;
    }
    return <div style={{ fontWeight: itemActa.isOwner ? 600 : 400 }}>{`${itemActa.name}`}</div>;
  };

  const copyName = (actaKey: string): JSX.Element => {
    const itemActa: actaListOK = limaActasStore.getActa(actaKey);
    if (itemActa === null) return <></>;
    return <div>{`${t("tpmain:timer.copyofdocumentLbl")} ${itemActa.name}`}</div>;
  };

  const taskName = (taskKey: string): JSX.Element => {
    const itemTask: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements =
      limaTasksStoreV4.getTaskByKey(taskKey);
    if (itemTask === null) return <></>;
    return (
      <div>{`${t("tpmain:timer.tasklinkedtodocumentLbl")} "${
        itemTask.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskName
      }"`}</div>
    );
  };

  return (
    <Stack>
      <Stack.Item className={classNames.actaDetailItemRow}>
        <Stack horizontal>
          <Stack.Item>{actaName(props.partionItem.itemMainCopyId)}</Stack.Item>
          <Stack.Item className={classNames.timeOnEnd}>
            {moment.utc(props.partionItem.actaTimerValue * 1000).format("HH:mm:ss")}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {props.partionItem.timerPartitions.map((item: ITimerPartion, index: number) => {
          return (
            <Stack key={`tv${item.itemId}_${index}`} horizontal className={classNames.actaDetailSubItemRow}>
              {/* <Stack.Item>{`${item.itemId}/${item.type}`}</Stack.Item> */}
              <Stack.Item className={classNames.timeOnStart}>
                {moment.utc(item.timerValue * 1000).format("HH:mm:ss")}
              </Stack.Item>
              {item.type === LimaObjectTypesEnum.TASK && <Stack.Item>{taskName(item.itemId)}</Stack.Item>}
              {item.type === LimaObjectTypesEnum.XCOPY && <Stack.Item>{copyName(item.itemMainCopyId)}</Stack.Item>}
              {item.type === LimaObjectTypesEnum.ACTA && <Stack.Item>{actaName(item.itemId, true)}</Stack.Item>}
            </Stack>
          );
        })}
      </Stack.Item>
    </Stack>
  );
};

export default TimerPartion;
