import { getTheme, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { LimaSimleTextLink } from "../../../../limaComponents/LimaSimleTextLink";
import Routes from "../../../Menu/routes";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserInviteFinishV4Props {}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  toolsStackName: [
    fonts.large,
    {
      marginLeft: 8,
      marginBottom: 10,
      fontWeight: 600,
      fontColor: "#8E8E93",
    },
  ],
  toolsStacWhole: {
    margin: 5,
  },
  toolsStacItemTextLink: {
    marginTop: 10,
  },
});

const UserInviteFinishV4: React.FunctionComponent<IUserInviteFinishV4Props> = () => {
  const { t } = useTranslation();
  // const history = useHistory();

  return (
    <Stack className={classNames.toolsStacWhole}>
      <Stack.Item className={classNames.toolsStackName}>{t("tpuser:adduser.invitationSendMessageLbl")}</Stack.Item>
      <Stack.Item className={classNames.toolsStacItemTextLink}>
        <LimaSimleTextLink linkTo={Routes.UserListRoute} bold={true}>
          {t("tpuser:adduser.linktoUserListLbl")}
        </LimaSimleTextLink>
      </Stack.Item>
      <Stack.Item className={classNames.toolsStacItemTextLink}>
        <LimaSimleTextLink linkTo={Routes.UserInviteRoute} bold={true}>
          {t("tpuser:adduser.linktoBackToInvitationsLbl")}
        </LimaSimleTextLink>
      </Stack.Item>
      <Stack.Item className={classNames.toolsStacItemTextLink}>
        <LimaSimleTextLink linkTo={Routes.UserInvitionsRoute} bold={true}>
          {t("tpuser:adduser.linktoAllInvitationsLbl")}
        </LimaSimleTextLink>
      </Stack.Item>
    </Stack>
  );
};

export default UserInviteFinishV4;
