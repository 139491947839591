import { Text, mergeStyleSets, getTheme, ITheme, DefaultButton, IButtonProps } from "@fluentui/react";
import { FunctionComponent, ReactElement, ReactNode } from "react";
import React = require("react");

type ILimaSimleTextLinkProps = {
  // //noticeType?: LIMA_TEXTNOTICETYPE;
  // linkTo: string;
  // props: IButtonProps;
  children?: ReactNode;
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  baseTextLink: [
    fonts.medium,
    {
      color: "#0078D4",
    },
  ],
  baseTextHoover: {
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});

export const LimaSimpleButtonV4: FunctionComponent<ILimaSimleTextLinkProps & IButtonProps> = (props): ReactElement => {
  return (
    <DefaultButton {...props}>
      <Text
        variant="small"
        className={`${classNames.baseTextLink} ${props.disabled !== true && classNames.baseTextHoover}`}
      >
        {props.children}
      </Text>
    </DefaultButton>
  );
};
