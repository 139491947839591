import { Stack } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LimaIconDataItem } from "../../../../assets/icons/LimaIconDataItem";
import { LimaIconToolsAddTask } from "../../../../assets/icons/LimaIconToolsAddTask";
import { LimaIconToolsCompare } from "../../../../assets/icons/LimaIconToolsCompare";
import { LimaIconToolsHistory } from "../../../../assets/icons/LimaIconToolsHistory";
import { LimaIconToolsSearch } from "../../../../assets/icons/LimaIconToolsSearch";
import { LimaIconToolsTaskslist } from "../../../../assets/icons/LimaIconToolsTaskslist";
import Routes from "../../../Menu/routes";
import LimaToolItem from "./components/LimactaToolItem";
import { ToolActionAddTask } from "./components/ToolActionAddTask";
import { LimaIconToolsEqualParas } from "../../../../assets/icons/LimaIconToolsEqualParas";
// import limaPermissionStore from "../../../../store/limaPermissionStore";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToolsActionsPartProps {}

const ToolsActionsPart: React.FunctionComponent<IToolsActionsPartProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showAddTask, setShowAddTask] = useState<boolean>(false);

  return (
    <>
      <Stack horizontal wrap={true}>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.addTaskToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              setShowAddTask(true);
            }}
          >
            <LimaIconToolsAddTask title={t("tpmain:tools.actions.addTaskToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.tasksListToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.ToolsTaskListRoute}`);
            }}
          >
            <LimaIconToolsTaskslist title={t("tpmain:tools.actions.tasksListToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>

        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.searchToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.EditMenuSearchRoute}`);
            }}
          >
            <LimaIconToolsSearch title={t("tpmain:tools.actions.searchToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.comparelLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.EditMenuStatesPage}`);
            }}
          >
            <LimaIconToolsCompare title={t("tpmain:tools.actions.comparelLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.historyToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.ShowMenuHistoryRoutev4}`);
            }}
          >
            <LimaIconToolsHistory title={t("tpmain:tools.actions.historyToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.dataitemsToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.FileMenuDataRoute}`);
            }}
          >
            <LimaIconDataItem title={t("tpmain:tools.actions.dataitemsToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
        <Stack.Item>
          <LimaToolItem
            toolName={t("tpmain:tools.actions.equalParasToolLbl")}
            toolItemWidth={75}
            onClickClb={() => {
              history.push(`${Routes.ToolsLimaEqualChangeRoute}`);
            }}
            accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsActionsIdentParas}
          >
            <LimaIconToolsEqualParas title={t("tpmain:tools.actions.equalParasToolLbl")} size={32} />
          </LimaToolItem>
        </Stack.Item>
      </Stack>
      <ToolActionAddTask showAddTask={showAddTask} onDismissClb={() => setShowAddTask(false)} />
    </>
  );
};

export default ToolsActionsPart;
