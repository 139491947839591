import { PrimaryButton, Spinner, SpinnerSize, Stack, Toggle } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getEqualsPara } from "../../../../api/schema/getEqualsPara";
import limaLoadeStore, { LoaderItemEnd, LoaderListItem } from "../../../../store/limaLoaderStore";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { HttpResponse, httpPostAuth, updateURL } from "../../../../api/httpAPI";
import { urlGetEqualParas2SSE, urlPOSTUpdateSinglePara } from "../../../../api/apilinks";
// import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import limaStore from "../../../../store/limaStore";
import { SingleSameParaItem } from "./SingleSameParaItem";
import { postUpdatePara, postUpdatePara_Response } from "../../../../api/schema/postUpdatePara";
import { onFileOpenHandlerWrapper } from "../../../../limaCommon/limaFileOpen";
import { XCOPY_TYPE } from "../../../../types/Task";
import { useTranslation } from "react-i18next";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../limaComponents/LimaNotice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaEqualParasList2Props {
  paraKey: string;
  xcopyHistoryTimeStamp: string;
}

export const LimaEqualParasList2: React.FunctionComponent<ILimaEqualParasList2Props> = (
  props: ILimaEqualParasList2Props
) => {
  const { t } = useTranslation();
  const [onlySameParaCount, setOnlySameParaCount] = useState<boolean>(true);
  const [foundData, setFoundData] = useState<getEqualsPara[]>([]);
  const [controller, setContoller] = useState<AbortController>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<LoaderListItem>();

  const { mutate } = useMutation(
    async (message: postUpdatePara) => updateOtherFile(message),

    {
      onSuccess: (data: postUpdatePara_Response) => {
        //setMessages([...messages, data]);
        log.info(limaLogTag.LimaEqualParasList, "useMutation updateOtherFile", data);
      },
    }
  );

  //-------------------
  //  REACTIONS
  //-------------------

  useEffect(() => {
    log.info(limaLogTag.LimaEqualParasList, `UEF/parakey: on mount `);
    const innerController = new AbortController();
    setContoller(innerController);
    return () => innerController.abort();
  }, []);

  useEffect(() => {
    log.info(
      limaLogTag.LimaEqualParasList,
      `UEF/parakey: search for [${props.paraKey}]/[${props.xcopyHistoryTimeStamp}]`
    );

    loadData();
  }, [props.xcopyHistoryTimeStamp, props.paraKey]);

  useEffect(() => {
    log.info(limaLogTag.LimaEqualParasList, `UEF/foundData: current foundData `, foundData);
  }, [foundData]);

  //------------------
  // QUERY PROCESSING
  //------------------

  //-------------------
  //  HANDLERS
  //-------------------

  const onFileOpen = (updateCallItem: getEqualsPara) => {
    log.info(limaLogTag.LimaEqualParasList, `onFileOpen: opeening xcopy [${updateCallItem.mainXcopyKey}]`);
    //TODO:
    onFileOpenHandlerWrapper(
      updateCallItem.actaKey,
      updateCallItem.actaName,
      updateCallItem.mainXcopyKey,
      null,
      null,
      XCOPY_TYPE.MAINXCOPY,
      false,
      true,
      true
    )
      .then(() => {
        log.info(
          limaLogTag.LimaEqualParasList,
          "onFileOpen: finish",
          updateCallItem.actaKey,
          updateCallItem.actaName,
          updateCallItem.mainXcopyKey,
          null,
          null,
          XCOPY_TYPE.MAINXCOPY
        );
      })
      .catch((e) => {
        log.info(
          limaLogTag.LimaEqualParasList,
          "onFileOpen: Error",
          e,
          updateCallItem.actaKey,
          updateCallItem.actaName,
          updateCallItem.mainXcopyKey,
          null,
          null,
          XCOPY_TYPE.MAINXCOPY
        );
      });
  };

  const onShowCompareFile = () => {
    log.info(limaLogTag.LimaEqualParasList, `onShowCompareFile: NOT YET IMPLEMENTED`);
  };

  const onUpdateFile = (updateCallItem: getEqualsPara) => {
    log.info(limaLogTag.LimaEqualParasList, `onUpdateFile: NOT YET IMPLEMENTED`);
    mutate({
      originActaKey: limaStore.actaId,
      originXcopyKey: limaStore.mainXcopyId,
      originParaKey: props.paraKey,
      originTimeStamp: limaStore.docTimeStamp, //it can be null
      originActaname: limaStore.actaName,
      destinationActaKey: updateCallItem.actaKey,
      destinationXcopyKey: updateCallItem.mainXcopyKey,
      destinationParaKey: updateCallItem.equalParaKey,
      destinationTimeStamp: updateCallItem.lastChange,
      destinationActaname: updateCallItem.actaName,
    });
  };

  const loadData = () => {
    if (
      limaStore.xcopyId !== null &&
      limaStore.xcopyId !== "" &&
      props.xcopyHistoryTimeStamp !== null &&
      props.paraKey !== null
    ) {
      setFoundData([]);
      setIsLoading(true);
      fetchData();
    }
  };

  const stopLoading = async () => {
    controller.abort();
    setIsLoading(false);
    limaLoadeStore.remove(loader.k, LoaderItemEnd.OK);
  };

  const fetchData = async () => {
    const loaderID = limaLoadeStore.add("update equal para");
    setLoader(loaderID);
    await fetchEventSource(
      updateURL(urlGetEqualParas2SSE(props.paraKey, limaStore.mainXcopyId, true, props.xcopyHistoryTimeStamp)),
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${limaStore.access_token}`,
        },
        signal: controller.signal,
        async onopen(res) {
          if (res.ok && res.headers.get("content-type") === EventStreamContentType && res.status === 200) {
            log.info(limaLogTag.LimaEqualParasList, `UEF/foundData: Connection made `, res);
            // window.console.log("Connection made ", res);
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            log.error(limaLogTag.LimaEqualParasList, `UEF/foundData: Client side error `, res);
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
            // window.console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          log.info(limaLogTag.LimaEqualParasList, `UEF/foundData: data `, event.data);
          window.console.log(event.data);
          const parsedData: getEqualsPara = JSON.parse(event.data);
          setFoundData((data) => [...data, parsedData]);
          if (parsedData.totalCount === parsedData.index) {
            stopLoading();
            limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
          }
        },
        onclose() {
          log.warn(limaLogTag.LimaEqualParasList, `UEF/foundData: Connection closed by the server `);
          // window.console.log("Connection closed by the server");
          setIsLoading(false);
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        },
        onerror(err) {
          log.error(limaLogTag.LimaEqualParasList, `UEF/foundData: There was an error from server `, err);
          // window.console.log("There was an error from server", err);
          setIsLoading(false);
          limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        },
      }
    );
  };
  //-------------------
  //  RENDERS
  //-------------------

  return (
    <Stack style={{ padding: 5 }}>
      <Stack.Item>
        <Toggle
          key="sameParaCountOnly"
          label={t("tpfile:tools.equalsParas.searchedLimitParaCountTogleLbl")}
          role="checkbox"
          checked={onlySameParaCount}
          disabled={true}
          onChange={(_ev: React.FormEvent<HTMLElement>, checked: boolean) => {
            log.info(limaLogTag.LimaEqualParasList, "change only same para", checked);
            setOnlySameParaCount(!onlySameParaCount);
          }}
        />
      </Stack.Item>
      {(props.xcopyHistoryTimeStamp === null || props.paraKey === null) && (
        <Stack.Item>
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>
            {t("tpfile:tools.equalsParas.paravarianchoosetLbl")}
          </LimaNotice>
        </Stack.Item>
      )}
      {(limaStore.xcopyId === null || limaStore.xcopyId === "") && (
        <Stack.Item>
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>{t("tpfile:tools.equalsParas.nodocopenLbl")}</LimaNotice>
        </Stack.Item>
      )}
      {isLoading && (
        <Stack.Item>
          <Spinner size={SpinnerSize.small} label="Wait, wait..." ariaLive="assertive" labelPosition="right" />
        </Stack.Item>
      )}
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item style={{ padding: 5 }}>
            <PrimaryButton
              text={t("tpfile:tools.equalsParas.reloadEqualsBtnLbl")}
              onClick={loadData}
              disabled={isLoading}
              // title={t("tpfile:tools.equalsParas.reloadBtnTitl")}
            />
          </Stack.Item>
          <Stack.Item style={{ padding: 5 }}>
            <PrimaryButton
              text={t("tpfile:tools.equalsParas.reloadStopEqualsBtnLbl")}
              onClick={stopLoading}
              disabled={!isLoading}
              // title={t("tpfile:tools.equalsParas.reloadBtnTitl")}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {`${t("tpfile:tools.equalsParas.searchedDocCountLbl")} ${foundData.length}`}
        {foundData.length > 0 && `[${foundData[0].totalCount}]`}
      </Stack.Item>
      {/* <Stack.Item>count foundData: {foundData.length}</Stack.Item> */}
      <Stack.Item>
        <Stack>
          {foundData.map((item, index) => (
            <Stack.Item key={`eqlchk_${index}`}>
              <SingleSameParaItem
                item={item}
                onOpenFile={onFileOpen}
                onUpdateFile={onUpdateFile}
                onShowCompareFile={onShowCompareFile}
              />
            </Stack.Item>
          ))}
        </Stack>
      </Stack.Item>
      {isLoading && <Stack.Item>...</Stack.Item>}
    </Stack>
  );
};

//----------------------
//   REACT QUERY
//----------------------

async function updateOtherFile(setSinglePara: postUpdatePara): Promise<postUpdatePara_Response> {
  let response: HttpResponse<postUpdatePara_Response>;
  // const user2Item: User2Task | User2Xcopy | User2User;
  // if (item.itemid === null || item.itemid === undefined) {
  //   log.error(limaLogTag.AccessComponent, "useMutation post missing id ", setSinglePara);
  //   throw null;
  // }

  const loaderID = limaLoadeStore.add("update equal para");
  try {
    log.info(limaLogTag.LimaEqualParasList, "useMutation post update para", setSinglePara);
    response = await httpPostAuth<postUpdatePara_Response>(urlPOSTUpdateSinglePara(), setSinglePara, loaderID.ac);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
}
