import * as React from "react";
import { FunctionComponent } from "react";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { LimaIconSaveOther } from "../../assets/icons/LimaIconSaveOther";

export interface ILimaActionIconSaveOtherProps {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaActionIconSaveOther: FunctionComponent<ILimaActionIconSaveOtherProps> = (
  props: ILimaActionIconSaveOtherProps
) => {
  const tooltipId = useId("toolTip-Savetoother");
  const calloutProps = { gapSpace: 0 };

  return (
    <TooltipHost content={props.title} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <div
        className={`${classNamesCommon.LimaActionIconButton} ${
          limaStore.docxIsLoading
            ? classNamesCommon.LimaActionIconButton_wait
            : classNamesCommon.LimaActionIconButton_enabled
        }`}
        onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick();
          }
        }}
      >
        {/* <LimaIconFileOpen /> */}
        {/* <Icon {...compareCopyIcon} style={{ color: "#0078D4", fontSize: 22 }} /> */}
        <LimaIconSaveOther color="#0078d4" size={24} />
      </div>
    </TooltipHost>
  );
};
