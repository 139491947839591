import { Text, mergeStyleSets, getTheme, ITheme } from "@fluentui/react";
import { ReactElement, ReactNode } from "react";
import React = require("react");
import { Link } from "react-router-dom";

type ILimaSimleTextLinkProps = {
  //noticeType?: LIMA_TEXTNOTICETYPE;
  linkTo: string;
  children?: ReactNode;
  bold?: boolean;
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  baseTextLink: [
    fonts.medium,
    {
      color: "#0078D4",
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  ],
  baseTextLinkBold: {
    fontWeight: 500,
  },
});

export const LimaSimleTextLink = ({ linkTo, children, bold }: ILimaSimleTextLinkProps): ReactElement => {
  return (
    <Link to={linkTo}>
      <Text variant="small" className={`${classNames.baseTextLink} ${bold === true && classNames.baseTextLinkBold}`}>
        {children}
      </Text>
    </Link>
  );
};
