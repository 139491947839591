import React = require("react");
import { FunctionComponent } from "react";

import { ProgressIndicator, Separator, Stack, Toggle } from "office-ui-fabric-react";
import { mergeStyleSets, ITheme, getTheme } from "office-ui-fabric-react/lib/Styling";

import { log, tag } from "missionlog";
import { observer } from "mobx-react";

import KeywordsList from "./KeywordsList";
import limaKeywordsStore from "../../../../store/limaKeywordStore";
import { adviceKeywordStringList } from "../../../../types/keywords";
import { useTranslation } from "react-i18next";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

log.init({ KeywordSearchPage: "KeywordSearchPage" });

const theme: ITheme = getTheme();
const { fonts } = theme;

const classNames = mergeStyleSets({
  itemName: [
    fonts.medium,
    {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
});

const KeywordSearchPage: FunctionComponent = () => {
  const { t } = useTranslation(["tpadvice"]);
  const onChangeKeywordParsEnable = (ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(tag.KeywordSearchPage, "onChangeActParsEnable: toggle is", checked ? "checked" : "not checked");
    log.info(tag.KeywordSearchPage, "onChangeActParsEnable: env", ev);
    if (checked) {
      limaKeywordsStore.setInlinSearch(true);
      void limaKeywordsStore.getKeywordsList();
    } else {
      limaKeywordsStore.setInlinSearch(false);
      limaKeywordsStore.setFullList([]);
      limaKeywordsStore.setList([]);
      limaKeywordsStore.setTotalItemCount(0);
    }
  };

  const ShowProgress: FunctionComponent = observer(() => {
    return (
      <>
        {limaKeywordsStore.loadingData && (
          <ProgressIndicator label="Search act paragraph" description="I am searching on server" />
        )}
      </>
    );
  });

  const RenderTopLbl = observer((): JSX.Element => {
    log.info(tag.KeywordSearchPage, "RenderTopLbl: observer top show");
    //TODO: rewrite to Array of items
    return (
      <div key="topLbl" className={classNames.itemName}>
        {limaKeywordsStore.keywordsListString.map((item: adviceKeywordStringList) => item.base + ",")}
      </div>
    );
  });

  const ShowHeader: FunctionComponent = observer(() => {
    return <>{limaKeywordsStore.totalItemCount > 0 ? <RenderTopLbl /> : <></>}</>;
  });

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.advisorLbl"), t("tpmain:menu.advisor.keywordsearchLbl")]} />
      <Separator alignContent="center">{t("tpadvice:keywordsearch.label")}</Separator>
      <Stack horizontal horizontalAlign="center">
        <Toggle
          key="searchActParInstantToggle"
          onText={t("tpadvice:keywordsearch.searchtoggleonlbl")}
          offText={t("tpadvice:keywordsearch.searchtoggleofflbl")}
          onChange={onChangeKeywordParsEnable}
          role="checkbox"
          defaultChecked={limaKeywordsStore.isInlineSearchActive}
        />
      </Stack>
      <Stack horizontalAlign="center">
        <ShowHeader />
      </Stack>
      <ShowProgress />
      <Separator alignContent="center">Judge List</Separator>
      <KeywordsList />
    </>
  );
};

export default KeywordSearchPage;
