import {
  FocusZone,
  FocusZoneDirection,
  getTheme,
  ITheme,
  List,
  mergeStyleSets,
  Separator,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useRef } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { validTypes } from "../../../../types/LimaAttachement";
import AttachmentDDFileItem from "./AttachmentDDFileItem";

interface AttachementAddProps {
  fileUploaddHandler?: (file: File, name: string, descr: string) => void;
  validFiles: File[];
  setValidFiles: React.Dispatch<React.SetStateAction<File[]>>;
  unsupportedFiles: File[];
  setUnsupportedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  container: {
    color: "red",
    display: "flex",
  },
  dropContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 75,
    width: "75%",
    borderColor: "#4aa1f3",
    borderStyle: "dashed",
    borderWidth: 1,
  },
  fileInput: {
    display: "none",
  },
  containerButton: {
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 100,
    flexShrink: 0,
    minWidth: 40,
  },
  containerButtonUpload: {
    height: 100,
    selectors: {
      "&:hover": {
        cursor: "pointer",
        background: palette.neutralLight,
      },
    },
  },
  containerButtonSorry: {
    height: 100,
    backgroundColor: "red",
  },
  containerButtonNone: {
    display: "none",
  },
  fileUploadLine: {
    display: "flex",
  },
  validAttFile: {},
  invalidAttFile: {
    color: "red",
    textDecoration: "underline",
  },
  remFileUpload: {
    selectors: {
      ":hover": {
        cursor: "pointer",
      },
    },
  },
});

const AttachementAdd: FunctionComponent<AttachementAddProps> = ({
  fileUploaddHandler,
  validFiles,
  setValidFiles,
  unsupportedFiles,
  setUnsupportedFiles,
}: AttachementAddProps) => {
  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  // const [validFiles, setValidFiles] = useState<File[]>([]);
  // const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>();

  const fileInputClicked = () => {
    log.info(limaLogTag.AttachementAdd, `fileInputClicked:`);
    if (fileInputRef != undefined) fileInputRef.current.click();
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log(files);
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      log.info(limaLogTag.AttachementAdd, `handleFiles: processing file id ${i} name ${files[i].name}`);
      if (validateFile(files[i])) {
        // setSelectedFiles((prevArray: File[]) => [...prevArray, files[i]]);
        setValidFiles((prevArray: File[]) => [...prevArray, files[i]]);
      } else {
        // files[i]["invalid"] = true;
        // setSelectedFiles((prevArray: File[]) => [...prevArray, files[i]]);
        setUnsupportedFiles((prevArray: File[]) => [...prevArray, files[i]]);
      }
    }
  };

  const removeFileFromList = (fileToRemove: File, filesList: File[], setAction: (list: File[]) => void) => {
    log.info(limaLogTag.AttachementAdd, "removeFileFromList file type", fileToRemove.name);
    filesList = filesList.filter((item) => item !== fileToRemove);
    log.info(limaLogTag.AttachementAdd, "removeFileFromList list", filesList);
    setAction(filesList);

    // validFiles.splice(validFileIndex, 1);
    // // update validFiles array
    // setValidFiles([...validFiles]);
    // //filesToUploadHandler([...validFiles]);
    // const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    // selectedFiles.splice(selectedFileIndex, 1);
    // // update selectedFiles array
    // setSelectedFiles([...selectedFiles]);

    // const unsupportedFileIndex = unsupportedFiles.findIndex((e) => e.name === name);
    // if (unsupportedFileIndex !== -1) {
    //   unsupportedFiles.splice(unsupportedFileIndex, 1);
    //   // update unsupportedFiles array
    //   setUnsupportedFiles([...unsupportedFiles]);
    // }
  };

  const onRenderCell = (item: File, index: number | undefined): JSX.Element => {
    return (
      <AttachmentDDFileItem
        key={"f_" + String(index)}
        file={item}
        removeCallBack={() => removeFileFromList(item, validFiles, setValidFiles)}
        uploadCallBack={(name: string, descr: string) => onUploadHandler(item, name, descr, validFiles, setValidFiles)}
      />
    );
  };

  const onRenderCellUnsuported = (item: File, index: number | undefined): JSX.Element => {
    return (
      <AttachmentDDFileItem
        key={"f_" + String(index)}
        file={item}
        removeCallBack={() => removeFileFromList(item, unsupportedFiles, setUnsupportedFiles)}
      />
    );
  };

  const onUploadHandler = (
    item: File,
    name: string,
    desc: string,
    filesList: File[],
    setAction: (list: File[]) => void
  ) => {
    fileUploaddHandler(item, name, desc);
    removeFileFromList(item, filesList, setAction);
  };

  return (
    <Stack>
      <Stack.Item>
        <Separator>{t("tptasks:taskedit.taskitem2.attachment.insertSeparatorLbl")}</Separator>

        <input ref={fileInputRef} type="file" className={classNames.fileInput} multiple onChange={filesSelected} />
        <div className={classNames.container}>
          <div
            className={classNames.dropContainer}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            {t("tptasks:taskedit.taskitem2.attachment.drofilesLbl")}
          </div>
        </div>
      </Stack.Item>
      {validFiles && validFiles.length > 0 && (
        <Stack.Item>
          <Separator>{t("tptasks:taskedit.taskitem2.attachment.validFilesLbl")}</Separator>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={validFiles} onRenderCell={onRenderCell} />
          </FocusZone>
        </Stack.Item>
      )}
      {unsupportedFiles && unsupportedFiles.length > 0 && (
        <Stack.Item>
          <Separator>{t("tptasks:taskedit.taskitem2.attachment.unsupportedFilesLbl")}</Separator>
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={unsupportedFiles} onRenderCell={onRenderCellUnsuported} />
          </FocusZone>
          <>
            {unsupportedFiles.map((item: File, index: number) => (
              <AttachmentDDFileItem
                key={"f_" + String(index)}
                file={item}
                removeCallBack={() => removeFileFromList(item, unsupportedFiles, setUnsupportedFiles)}
              />
            ))}
          </>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default AttachementAdd;

//---------------
// FILE PROCESSING
//----------------

const validateFile = (file: File): boolean => {
  if (validTypes.indexOf(file.type) === -1) {
    log.warn(limaLogTag.AttachementAdd, "Unsuported file type", file.type);
    return false;
  }
  return true;
};

// const fileType = (fileName: string): string => {
//   return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
// };

// const fileSize = (size: number): string => {
//   if (size === 0) return "0 Bytes";
//   const k = 1024;
//   const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//   const i = Math.floor(Math.log(size) / Math.log(k));
//   return String(parseFloat((size / Math.pow(k, i)).toFixed(2))) + " " + sizes[i];
// };
