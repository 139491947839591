import * as React from "react";
import { useState, FunctionComponent, useEffect } from "react";

import { TextField } from "office-ui-fabric-react/lib/TextField";
import { DefaultButton } from "office-ui-fabric-react";
import { Stack, IStackProps, IStackStyles } from "office-ui-fabric-react/lib/Stack";

import limaUsersStore from "../../../store/limaUsersStore";

import { log } from "missionlog";
import limaStore from "../../../store/limaStore";
import { LIMAENUM_AccesLicPermIdent } from "../../../limaCommon/limaPermissions";
import { ICallUser, IUser, User_getNull } from "../../../types/User";
import { httpDeleteByPUTAuth, httpPutAuth } from "../../../api/httpAPI";
import limaLoadeStore from "../../../store/limaLoaderStore";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { useMutation } from "react-query";
import { urlPatchOrDeleteUser } from "../../../api/apilinks";
import LimaLicPermShow from "../../../limaComponents/LimaLicPermShow";
//import { urlPostOrPatchOrDeleteUser } from "../../../api/apilinks";

export interface UserContextEditProps {
  userId: string;
}

const stackTokens = { childrenGap: 30 };

const stackStyles: Partial<IStackStyles> = {
  root: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 25,
    marginRight: 25,
  },
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { width: 290 } },
};

const UserContextEdit: FunctionComponent<UserContextEditProps> = ({ userId }: UserContextEditProps) => {
  const [user, setUser] = useState<IUser>(User_getNull());
  const { mutateAsync: mutatePatchUser } = useAskToPatchUserQuery();
  const { mutateAsync: mutateDeleteUser } = useAskToDelUserQuery();

  //TODO: Rewrite
  const onUpdate = () => {
    log.info(limaLogTag.UserEditContext, "onUpade: updating user in DB", user);
    void mutatePatchUser({
      userEmail: user.userEmail,
      userName: user.userName,
      userId: userId,
      lang: user.lang,
    });
  };

  const onRemove = () => {
    log.info(limaLogTag.UserEditContext, "onRemove: removing user in DB", user);
    void mutateDeleteUser({
      userEmail: user.userEmail,
      userName: user.userName,
      userId: userId,
      lang: null,
    });
  };

  const onNameChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    // log.warn(limaLogTag.UserEditContext, "REMOVED ON REFACTOR ", newValue);
    log.info(limaLogTag.UserEditContext, "onNameChange: updated to ", newValue);
    setUser({ ...user, userName: newValue });
    log.info(limaLogTag.UserEditContext, "onNameChange: current ", user);
  };

  const onEmailChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    // log.warn(limaLogTag.UserEditContext, "REMOVED ON REFACTOR ");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    log.info(limaLogTag.UserEditContext, "onEmailChange: updated to ", newValue);
    setUser({ ...user, userEmail: newValue });
    log.info(limaLogTag.UserEditContext, "onEmailChange: current ", user);
  };

  useEffect(() => {
    log.info(limaLogTag.UserEditContext, "useEffect: on moutn");
    limaUsersStore
      .getUserFromDB(userId)
      .then((data) => {
        log.info(limaLogTag.UserEditContext, "useEffect: getting user data", data);
        setUser(data);
      })
      .catch(() => {
        log.error(limaLogTag.UserEditContext, "useEffect: dome error");
      });
  }, [userId]);

  return (
    <>
      <Stack horizontal wrap tokens={stackTokens} styles={stackStyles} className="item__layer">
        <Stack {...columnProps}>
          <TextField label="User Name:" underlined required value={user.userName} onChange={onNameChange} />
          <TextField label="Email:" underlined required value={user.userEmail} onChange={onEmailChange} />
        </Stack>
        <Stack {...columnProps}>
          <>
            {limaStore.showTestingInfo && (
              <>
                <TextField label="user ID:" underlined disabled value={user._key} />
                <TextField
                  label="Created date:"
                  underlined
                  disabled
                  value={new Date(user.ctimeStamp).toLocaleDateString("en-GB")}
                />
                <TextField label="Created by:" underlined disabled value={user.cissuedBy} />
              </>
            )}
            <LimaLicPermShow accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserAddLinkUser}>
              <DefaultButton
                key="btnUpdate"
                className="ms-welcome__action"
                iconProps={{ iconName: "UserSync" }}
                onClick={onUpdate}
              >
                Update
              </DefaultButton>
            </LimaLicPermShow>
            <LimaLicPermShow accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserRemoveLinkUser}>
              <DefaultButton
                key="btnDelete"
                className="ms-welcome__action"
                iconProps={{ iconName: "UserSync" }}
                onClick={onRemove}
              >
                REMOVE USER
              </DefaultButton>
            </LimaLicPermShow>
          </>
        </Stack>
      </Stack>
    </>
  );
};

export default UserContextEdit;

const useAskToPatchUserQuery = () => {
  return useMutation(patchUser, {
    onMutate: ({ userEmail, userName, userId }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserEditContext, "call mutate useAskToPatchUserQuery ", userEmail, userName, userId);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserEditContext, "call mutate useAskToPatchUserQuery ", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const useAskToDelUserQuery = () => {
  return useMutation(delUser, {
    onMutate: ({ userEmail, userName, userId }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserEditContext, "call mutate useAskToDelUserQuery ", userEmail, userName, userId);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserEditContext, "call mutate useAskToDelUserQuery ", data);
      limaUsersStore.removeListSingleItem(data._key);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

//--------------QUERIES -------------
const patchUser = async ({ userEmail, userName, userId }: ICallUser): Promise<IUser> => {
  log.info(limaLogTag.UserEditContext, "fetching data for patchUser", userEmail, userName, userId);
  // const [, { patientID }] = params.queryKey;

  if (userId === undefined || userId === null || userId === "") {
    log.warn(limaLogTag.UserEditContext, "no id ", userId);
    return null;
  }

  const user: IUser = {
    _id: "",
    _key: userId,
    cissuedBy: null,
    ctimeStamp: null,
    dissuedBy: null,
    dtimestamp: null,
    userEmail: userEmail,
    userName: userName,
    lang: null,
  };

  const loaderID = limaLoadeStore.add(`ask to patchUser`);
  try {
    const response = await httpPutAuth<IUser>(urlPatchOrDeleteUser(), user, loaderID.ac);
    log.info(limaLogTag.UserEditContext, "fetching data patchUser response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

const delUser = async ({ userEmail, userName, userId }: ICallUser): Promise<IUser> => {
  log.info(limaLogTag.UserEditContext, "fetching data for delUser", userEmail, userName, userId);
  // const [, { patientID }] = params.queryKey;

  if (userId === undefined || userId === null || userId === "") {
    log.warn(limaLogTag.UserEditContext, "no id ", userId);
    return null;
  }

  const user: IUser = {
    _id: "",
    _key: userId,
    cissuedBy: null,
    ctimeStamp: null,
    dissuedBy: null, //remove by autho limaStore.userId,
    dtimestamp: null,
    userEmail: null,
    userName: null,
    lang: null,
  };

  const loaderID = limaLoadeStore.add(`ask to deleteUser`);
  try {
    const response = await httpDeleteByPUTAuth<IUser>(urlPatchOrDeleteUser(), user, loaderID.ac);
    log.info(limaLogTag.UserEditContext, "fetching data deleteUser response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
