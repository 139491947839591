import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
var parser = require("fast-xml-parser");
import { log, tag } from "missionlog";

log.init({ limaFile: "limaFile" });

interface Node {
  Properties: {
    attr?: Record<string, string>[];
    property: NodeProperty[];
  };
}

interface NodeProperty {
  attr?: Record<string, string>[];
  "vt:lpwstr": string;
}

/**
 * Reading local file and send it to server to check
 * @param file File identified file
 * @param cbDocId callbackID where will be ID stored
 * @returns Promiss String or null
 */
//export const uploadFromLocal = (file: File, cbDocId?: any, cbLoader?: any, cbFilePicker?: any) => {
export const getFileFromLocal = (file: File): Promise<string | null> => {
  log.info(tag.limaFile, "getFileFromLocal: start");

  return new Promise((resolve, reject) => {
    if (!file) reject;

    let reader = new FileReader(); //Reading local file
    reader.onload = async (readerEvt) => {
      let binaryString: string = readerEvt.target.result as string;
      //log.info(tag.limaUploadDoc, "binary string:", binaryString);

      try {
        resolve(binaryString);
      } catch (err) {
        log.error(tag.limaUploadDoc, "uploadFromLocal: loadDocxFromLocal call error:", err);
        reject;
      }
    };

    reader.onerror = reject;
    //reader.readAsArrayBuffer(file);
    reader.readAsBinaryString(file);
  });
};

/**
 * This is not working due FS accssess to local file restriction
 * @param filename
 * @returns
 */
export const getDocxData = (fileBinary: string): Promise<LimaDocData | null> => {
  var JSZip = require("jszip");

  return new Promise((resolve, reject) => {
    if (!fileBinary) reject;

    JSZip.loadAsync(fileBinary)
      .then(function (zip) {
        log.info(tag.limaFile, "getDocxData/loadAsync: data ", zip);
        try {
          zip
            .file("docProps/custom.xml")
            .async("string")
            .then((dataOut) => {
              log.info(tag.limaFile, "getDocxData/zipfile: data in docProps/custom.xml  ", dataOut);
              resolve(parseXML(dataOut));
            })
            .catch(() => {
              log.info(tag.limaFile, "getDocxData/zipfile: some issue in data in docProps/custom.xml  ");
              resolve(null);
            });
        } catch (err) {
          log.info(tag.limaFile, "getDocxData/TRY: some issue  ", err);
          reject(null);
        }

        // zip.file("docProps/custom.xml").async("string");
      })
      .catch(() => {
        log.info(tag.limaFile, "getDocxData/loadAsync: some issue in data in docProps/custom.xml  ");
        reject(null);
      });
  });
};

/**
 * Parase XML from DocX file
 * @param xmlString XML as string from File on input
 * @returns LimaDocData or Null
 */
export const parseXML = (xmlString: string): LimaDocData | null => {
  var outLimaPar: LimaDocData = { limactaDocID: "", limactaDocType: LimaDocTypeENUM.none, limactaDocName: "" };

  var options = {
    attributeNamePrefix: "@_",
    attrNodeName: "attr", //default is 'false'
    textNodeName: "#text",
    ignoreAttributes: false,
    ignoreNameSpace: false,
    allowBooleanAttributes: false,
    parseNodeValue: true,
    parseAttributeValue: false,
    parseTagValue: true,
    trimValues: false,
    cdataTagName: "__cdata", //default is 'false'
    cdataPositionChar: "\\c",
    parseTrueNumberOnly: true,
    arrayMode: false, //"strict"
    // attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
    // tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
    // tagValueProcessor: (tagName: any, tagValue: any, jPath: any, hasAttributes: any, isLeafNode: any) => {
    //   console.log("tagVal proc tagName", tagName);
    //   console.log("tagVal proc tagValue", tagValue);
    //   console.log("tagVal proc jPath", jPath);
    //   console.log("tagVal proc hasAttributes", hasAttributes);
    //   console.log("tagVal proc isLeafNode", isLeafNode);
    //   //if (isLeafNode) return tagValue;
    //   return tagName;
    // },
    stopNodes: ["parse-me-as-string"],
  };

  try {
    var tObj = parser.getTraversalObj(xmlString, options);
    log.info(tag.limaFile, "parseXML: data traversal ", tObj);
    var jsonObj = parser.convertToJson(tObj, options);
    log.info(tag.limaFile, "parseXML: data json ", jsonObj);

    var jsonObj2: Node = parser.parse(xmlString, options, true);
    log.info(tag.limaFile, "parseXML: data json ", jsonObj2);

    jsonObj2.Properties.property.forEach((item: NodeProperty) => {
      log.info(tag.limaFile, "parseXML: data item ", item);
      if (item.attr["@_name"] === "limactaDocID") {
        log.info(tag.limaFile, "parseXML: data item Found limactaDocID = ", item.attr["@_name"]);
        log.info(tag.limaFile, "parseXML: data item Found limactaDocID value ", item["vt:lpwstr"]);
        outLimaPar.limactaDocID = item["vt:lpwstr"].toString();
      } else if (item.attr["@_name"] === "limactaDocName") {
        log.info(tag.limaFile, "parseXML: data item Found limactaDocName = ", item.attr["@_name"]);
        log.info(tag.limaFile, "parseXML: data item Found limactaDocName value ", item["vt:lpwstr"]);
        outLimaPar.limactaDocName = item["vt:lpwstr"].toString();
      } else if (item.attr["@_name"] === "limactaDocType") {
        log.info(tag.limaFile, "parseXML: data item Found limactaDocType = ", item.attr["@_name"]);
        log.info(tag.limaFile, "parseXML: data item Found limactaDocType value ", item["vt:lpwstr"]);
        //TODO: FINISH ENUM ON GET TYPES
        if (item["vt:lpwstr"].toString() == "acta") outLimaPar.limactaDocType = LimaDocTypeENUM.ACTA;
        else if (item["vt:lpwstr"].toString() == "version") outLimaPar.limactaDocType = LimaDocTypeENUM.VERSION;
        else if (item["vt:lpwstr"].toString() == "xcopy") outLimaPar.limactaDocType = LimaDocTypeENUM.XCOPY;
        else if (item["vt:lpwstr"].toString() == "appendix") outLimaPar.limactaDocType = LimaDocTypeENUM.APPENDIX;
      } else {
        log.info(tag.limaFile, "parseXML: data item not same limactaDocID = ", item.attr["@_name"]);
      }
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
  if (outLimaPar.limactaDocID === "") return null;

  return outLimaPar;
};
