import React = require("react");
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { onLogged_withCompany } from "../../../../limaCommon/limaUser";
import { ENUM_LogstoreLogType } from "../../../../store/limaLogsStore";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../limaComponents/LimaNotice";
import { Stack, Text } from "@fluentui/react";
import limaStore from "../../../../store/limaStore";
import { useHistory } from "react-router";
import Routes from "../../../Menu/routes";
import { ILimaUserLicences, LimaCompLicActive } from "../../../../types/LimaLicences";
import { limaQuery_getComLicUState } from "../../../../api/calls/limaLicenceCalls";
// import { useQuery } from "react-query";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import LimaFC_CompLicItem from "./LimaFC_CompLicItem";
import limaPermissionStore from "../../../../store/limaPermissionStore";
import limaLogsStore from "../../../../store/limaLogsStore";

interface IUserCompanies {
  companyId?: string;
}

// const theme: ITheme = getTheme();
// const { palette, fonts } = theme;

//TODO: Cleanup on styles
// const classNames = mergeStyleSets({
//   chevron: {
//     alignSelf: "center",
//     marginLeft: 10,
//     color: palette.neutralTertiary,
//     fontSize: fonts.large.fontSize,
//     flexShrink: 0,
//   },
// });

const UserCompanies: React.FunctionComponent<IUserCompanies> = (props: IUserCompanies) => {
  const { t } = useTranslation(["tpmain"]);
  const history = useHistory();
  const [companies, setCompanies] = useState<LimaCompLicActive[] | null>(null);
  const [errorTxt, setErrorTxt] = useState<string | null>(null);

  //-----------------
  // REACTIONS
  //-----------------
  useEffect(() => {
    log.info(limaLogTag.HomeCompLic, `UEF: UserCompanies on start `, props);
    if (limaStore.userId !== null) {
      log.info(limaLogTag.HomeCompLic, `UEF: UserCompanies for user `, limaStore.userId);
      limaQuery_getComLicUState()
        .then((data: LimaCompLicActive[]) => {
          setCompanies(data);
          log.info(limaLogTag.HomeCompLic, `data`, data);
          if (errorTxt !== null) setErrorTxt(null);
          //kdyz je jen jedna firma a ma dost licenci pokracuj
        })
        .catch(() => {
          limaLogsStore.add(
            "error geting licences",
            "Some error on getting licences",
            ENUM_LogstoreLogType.ERROR,
            limaLogTag.HomeCompLic
          );
          setErrorTxt("Some Error");
        });
      if (errorTxt !== null) setErrorTxt(null);
    } else {
      log.error(limaLogTag.HomeCompLic, `UEF: UserCompanies no userId `, limaStore.userId);
      limaLogsStore.add(
        "error geting licences states",
        "Some error on getting licences states",
        ENUM_LogstoreLogType.ERROR,
        limaLogTag.HomeCompLic
      );
      setErrorTxt("Some Error");
    }
  }, []);

  useEffect(() => {
    log.info(limaLogTag.HomeCompLic, `companies update triggerd`, companies);
  }, [companies]);

  //-----------------
  // HANDLERS
  //-----------------

  const onContinue = (usedlicence: ILimaUserLicences) => {
    limaStore.setUserLicenced(true);
    limaStore.setUserCompanyKey(usedlicence.companyKey);
    limaStore.setUserLicenceUsedId(usedlicence.licenceid);
    limaPermissionStore.setLicence(usedlicence);
    void onLogged_withCompany();

    history.replace(Routes.HomeV2Route);
  };

  // const onShowDetail = (companyKey: string) => {
  //   if (showCompanyDetail === null) {
  //     setShowCompayDetail(companyKey);
  //   } else if (showCompanyDetail === companyKey) {
  //     setShowCompayDetail(null);
  //   } else if (showCompanyDetail !== companyKey) {
  //     setShowCompayDetail(companyKey);
  //   }
  // };

  //-------------------
  // RENDERS
  //------------------

  if (companies === null) {
    return LimaLoadingComponnet(3, "Loading companies licences");
  }

  if (errorTxt !== null) {
    log.error(limaLogTag.HomeCompLic, "loaded error: ", errorTxt);
    return (
      <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>
        {t("tpmain:error.errorLoadingDataLbl")}: {errorTxt}{" "}
      </LimaNotice>
    );
  }

  return (
    <Stack style={{ padding: 10 }}>
      <Stack.Item>
        <Text variant="large">{t("tpmain:homecomplic.companies.labelLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="medium">*{t("tpmain:homecomplic.companies.labelinfoLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          {companies.map((item, index) => (
            <Stack.Item key={`${item.companyKey}_${index}`}>
              <Stack>
                <Stack.Item>
                  <Stack>
                    <Stack.Item>
                      <b>{`${item.companyName}`}</b>
                    </Stack.Item>
                    <Stack.Item>
                      <LimaFC_CompLicItem company={item} onContinue={onContinue} />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          ))}
        </Stack>
      </Stack.Item>
      <>{errorTxt !== null && <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>{errorTxt}</LimaNotice>}</>
    </Stack>
  );
};

export default UserCompanies;
