import * as React from "react";
import { FunctionComponent } from "react";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";
import { ITooltipHostStyles, Icon, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

export interface ILimaActionIconItemDetailProps {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaActionIconItemDetail: FunctionComponent<ILimaActionIconItemDetailProps> = observer(
  (props: ILimaActionIconItemDetailProps) => {
    const tooltipId = useId("toolTip-DetailOpen");
    const calloutProps = { gapSpace: 0 };

    return (
      <TooltipHost content={props.title} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
        <div
          className={`${classNamesCommon.LimaActionIconButton} ${
            limaStore.docxIsLoading
              ? classNamesCommon.LimaActionIconButton_wait
              : props.disabled
              ? classNamesCommon.LimaActionIconButton_disabled
              : classNamesCommon.LimaActionIconButton_enabled
          }`}
          onClick={() => {
            if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
          //title={props.title !== undefined && props.title}
        >
          <Icon
            iconName="ChevronRightMed"
            aria-label="Detail show"
            className={classNamesCommon.LimaActionIconiconBase}
          />
        </div>
      </TooltipHost>
    );
  }
);
