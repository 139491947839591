import { log } from "missionlog";
import { ReactElement, useEffect, useMemo } from "react";
import React = require("react");
import { useHistory, useParams } from "react-router-dom";
import { actaListOK, isType_ActaListOK } from "../../../api/schema/getActaList";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import { MenuSelectedENUM } from "../../../limaComponents/LimaMenu";
import limaActasStore from "../../../store/limaActasStore";
// import limaBreadCrumbStore from "../../../store/limaBreadCrumbStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../store/limaStore";
// import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStore";
import { LIMA_ITEM_TYPE, LimaObjectTypesEnum } from "../../../types/BASE_Enums";
import { TASKSTATE, XCOPY_TYPE } from "../../../types/Task";
import TagsComponent from "../../CommonComponents/Tags/TagsComponent";
import AccessComponentV4 from "../Components/Access/AccessComponentV4";
import TasksListV4, { TASKFILTERITEM_ENUM } from "../Tasks/TaskListV4";
import FileListCopiesV4 from "./WorkingCoppies/FileListCopiesV4";
import DetailActaInfoV4 from "./components/DetailActaInfoV4";
import FileVersionsV4 from "./Versions/FileVersionsV4";
import { useTranslation } from "react-i18next";
import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStoreV4";
import limaBreadCrumbStore, {
  LimaBreadCrumbACTIONTYPE,
  LimaBreadCrumbPageENUM,
} from "../../../store/limaBreadCrumbStore";
import FileItemChangeOwnerV4 from "./Info/FileItemChangeOwnerV4";
import { onFileOpenHandlerWrapper } from "../../../limaCommon/limaFileOpen";
import Routes from "../../Menu/routes";
import limaTimerStore from "../../../store/limaTimerStore";
import KnowledgePageV4 from "../Knowledge/KnowledgePageV4";
import WorkingCopiesDetailV4 from "./WorkingCoppies/WorkingCopiesDetailV4";

interface FileItemV4Props {
  fileitemid: string;
  type: XCOPY_TYPE;
}

export const FileItemV4 = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { fileitemid, type } = useParams<FileItemV4Props>();
  // const location = useLocation();

  //--------------
  // Reactions
  //--------------

  useEffect(() => {
    log.info(limaLogTag.FileItemV4, `UEF: on file item fileitemid=[${fileitemid}] type=[${type}]`);
    log.info(
      limaLogTag.FileItemV4,
      `UEF: on file item fileitemid=[${fileitemid}] limastoreactaid=[${limaStore.actaId}]`
    );
    log.info(
      limaLogTag.FileItemV4,
      `UEF: on file item fileitemid=[${fileitemid}] limaStore.menufile=[${limaStore.menu_fileId}]`
    );
    // window.console.log(location.pathname);
    // limaBreadCrumbStore.setActionCrumb(fileitemid);

    //Select menu file if open
    if (fileitemid === limaStore.menu_fileId) limaStore.setMenuOpen(MenuSelectedENUM.FILE);
    else if (limaStore.menuOpen === MenuSelectedENUM.FILE) limaStore.setMenuOpen(MenuSelectedENUM.NONE);

    // if (type === OPENEDFILETYPE_ENUM.MAIN_COPY) {
    if (type === XCOPY_TYPE.MAINXCOPY) {
      limaBreadCrumbStore.setCurrentPage({ pageId: fileitemid, pageType: LimaBreadCrumbPageENUM.FILE_MAINCOPY });
      // } else if (type === OPENEDFILETYPE_ENUM.COPY) {
    } else if (type === XCOPY_TYPE.FULLXCOPY || type === XCOPY_TYPE.TRIMXCOPY) {
      limaBreadCrumbStore.setCurrentPage({ pageId: fileitemid, pageType: LimaBreadCrumbPageENUM.FILE_COPY });
    }

    return () => {
      log.info(limaLogTag.FileItemV4, `UEF: set Action visibility false on destruct`);
      // limaBreadCrumbStore.setActionIsVisible(false);
      if (limaStore.menuOpen === MenuSelectedENUM.FILE) limaStore.setMenuOpen(MenuSelectedENUM.NONE);
      limaBreadCrumbStore.setCurrentPage({ pageId: "", pageType: LimaBreadCrumbPageENUM.OTHER });
    };
  }, [fileitemid]);

  const fileItem = useMemo((): actaListOK | string | null => {
    log.debug(limaLogTag.FileItemV4, `useEffect: fileItemID [${fileitemid}]`);
    // let item = null;

    if (fileitemid !== undefined && fileitemid !== null && fileitemid !== "") {
      // if (type === OPENEDFILETYPE_ENUM.MAIN_COPY) {
      if (type === XCOPY_TYPE.MAINXCOPY) {
        return limaActasStore.getActa(fileitemid);
        // } else if (type === OPENEDFILETYPE_ENUM.COPY) {
      } else if (type === XCOPY_TYPE.TRIMXCOPY || type === XCOPY_TYPE.FULLXCOPY) {
        return fileitemid;
      }
    }

    return null;
  }, [fileitemid, type]);

  const isMainXcopyOwner = useMemo((): boolean => {
    // if (type === OPENEDFILETYPE_ENUM.MAIN_COPY) {
    if (type === XCOPY_TYPE.MAINXCOPY) {
      return limaActasStore.isOwnerActaId(fileitemid);
    } else {
      return false;
    }
  }, [fileitemid, type]);

  /**
   * Takes care of breadcrumb
   */
  useEffect(() => {
    log.info(
      limaLogTag.FileItemV4,
      "UEF-breadcrumbset setup action icons in breadcrumber",
      fileitemid,
      type,
      isMainXcopyOwner
    );
    limaBreadCrumbStore.setCurrentActions([]);
    log.info(limaLogTag.FileItemV4, "UEF-breadcrumbset setup action icons in breadcrumber is open", limaStore.xcopyId);

    //THIS WILL NOT HAPPEN
    if (type === XCOPY_TYPE.NOXCOPY) {
      limaBreadCrumbStore.addToCurrentActions({
        actionType: LimaBreadCrumbACTIONTYPE.FILE_OPEN,
        actionAction: () => {
          // window.console.log(">>>>> HELLO OPEN FILE type=NONE", fileitemid, type);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isActionEnabled: (_openfileId: string, _openfileType: OPENEDFILETYPE_ENUM, _currentPath: string) => {
          // window.console.log(">>>>> IS ACION ENABLEB type=NONE", true, fileitemid, type);
          return true;
        },
      });
    }
    //IN CASE OF COPIES
    else if (type === XCOPY_TYPE.FULLXCOPY || type === XCOPY_TYPE.TRIMXCOPY) {
      limaBreadCrumbStore.addToCurrentActions({
        actionType: LimaBreadCrumbACTIONTYPE.FILE_OPEN,
        isActionEnabled: (openfileId: string, openfileType: OPENEDFILETYPE_ENUM, _currentPath: string) => {
          if (limaStore.menuOpen === MenuSelectedENUM.HOME || limaStore.menuOpen === MenuSelectedENUM.TOOLS)
            return false;
          log.info(
            limaLogTag.FileItemV4,
            `>>>>> SAVE IS ACION ENABLEB COPY current openXCOPY'${openfileId}/${openfileType}' and open page'${fileitemid}'`,
            openfileId,
            openfileType,
            fileitemid,
            limaStore.xcopyId,
            type
          );
          return openfileId === fileitemid &&
            (openfileType === OPENEDFILETYPE_ENUM.COPY ||
              openfileType === OPENEDFILETYPE_ENUM.COPY_FULL ||
              openfileType === OPENEDFILETYPE_ENUM.COPY_TRIM)
            ? false
            : true;
        },
        actionAction: () => {
          const actaId = limaActasStore.getActaIdForXcopyIdAndXcopyType(fileitemid, OPENEDFILETYPE_ENUM.COPY);
          const actaName = limaActasStore.getActaNameActaId(actaId);

          limaStore.openFileNewWindowConfirmFnc = () => {
            log.info(limaLogTag.ActaListV4, `onClick: clicked COPY  -> Open in new window`, fileItem);
            void onFileOpenHandlerWrapper(actaId, actaName, fileitemid, null, null, type, false, false, true, true);
            log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileitemid}`);

            limaStore.setOpenFileNewWindow(false);
          };
          limaStore.openFileCurrentWindowConfirmFnc = () => {
            log.info(limaLogTag.ActaListV4, `onClick: clicked COPY -> Open in current window`, fileItem);
            void onFileOpenHandlerWrapper(
              actaId,
              actaName,
              fileitemid,
              null,
              null,
              type,
              false,
              true,
              false,
              false
            ).then(() => {
              log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileitemid}`);
              limaTimerStore.setCurrentActivePartion({
                itemId: fileitemid,
                itemMainCopyId: fileitemid,
                type: LimaObjectTypesEnum.ACTA,
                userKey: limaStore.userId,
                _key: null,
              });
              limaStore.setOpenFileNewWindow(false);
            });
          };

          limaStore.setOpenFileNewWindow(true);

          // window.console.log(">>>>> HELLO OPEN FILE COPY ", actaId, actaName, type, fileitemid);
          // void onFileOpenHandlerWrapper(
          //   actaId,
          //   actaName,
          //   fileitemid,
          //   null,
          //   null,
          //   limaStore.xcopyType,
          //   false,
          //   true,
          //   false
          // );
          // //TODO: Correct cop trim/full
          // //limaStore.setMenuFile(fileitemid, `copy ${actaName}`, XCOPY_TYPE.FULLXCOPY);
          // limaTimerStore.setCurrentActivePartion({
          //   itemId: fileitemid,
          //   type: LimaObjectTypesEnum.XCOPY,
          //   itemMainCopyId: actaId,
          //   userKey: limaStore.userId,
          //   _key: null,
          // });
        },
      });

      limaBreadCrumbStore.addToCurrentActions({
        actionType: LimaBreadCrumbACTIONTYPE.COPY_SAVE,
        isActionEnabled: (openfileId: string, openfileType: OPENEDFILETYPE_ENUM, _currentPath: string) => {
          if (limaStore.menuOpen === MenuSelectedENUM.HOME || limaStore.menuOpen === MenuSelectedENUM.TOOLS)
            return false;

          if (_currentPath.startsWith("/fileSaveFinishRt") === true) return false;
          // else {
          //   log.info(limaLogTag.FileItemV4,">>>>>> route", _currentPath);
          // }
          log.info(
            limaLogTag.FileItemV4,
            `>>>>> SAVE IS ACION ENABLEB COPY current openXCOPY'${openfileId}/${openfileType}' and open page'${fileitemid}'`,
            openfileId,
            openfileType,
            fileitemid,
            limaStore.xcopyId,
            type
          );
          return openfileId === fileitemid &&
            (openfileType === OPENEDFILETYPE_ENUM.COPY ||
              openfileType === OPENEDFILETYPE_ENUM.COPY_FULL ||
              openfileType === OPENEDFILETYPE_ENUM.COPY_TRIM)
            ? true
            : false;
        },
        actionAction: () => {
          log.info(limaLogTag.FileItemV4, ">>>>> HELLO SAVE FILE COPY ", fileitemid, type);
          history.push(`${Routes.FileSaveExitCopyRoute_base}${fileitemid}`);
        },
      });
      // } else if (type === OPENEDFILETYPE_ENUM.MAIN_COPY && isMainXcopyOwner === true) {
    }
    //IN CASE OF MAIN DOCUMENT AND OWNER
    else if (type === XCOPY_TYPE.MAINXCOPY && isMainXcopyOwner === true) {
      limaBreadCrumbStore.addToCurrentActions({
        actionType: LimaBreadCrumbACTIONTYPE.FILE_OPEN,
        isActionEnabled: (openfileId: string, openfileType: OPENEDFILETYPE_ENUM, _currentPath: string) => {
          //TODO IN FUTURE: openfileId - XcopyID, fileitemid - actaid
          if (limaStore.menuOpen === MenuSelectedENUM.HOME || limaStore.menuOpen === MenuSelectedENUM.TOOLS)
            return false;

          const mainXcopyId = limaActasStore.getMainCopyIdForActaId(fileitemid);
          window.console.log(
            ">>>>> IS ACION ENABLEB MAINXCOPY OPEN ",
            openfileId,
            openfileType,
            fileitemid,
            mainXcopyId,
            type
          );
          return openfileId === mainXcopyId && openfileType === OPENEDFILETYPE_ENUM.MAIN_COPY ? false : true;
        },
        actionAction: () => {
          const mainXcopyId = limaActasStore.getMainCopyIdForActaId(fileitemid);
          const actaName = limaActasStore.getActaNameActaId(fileitemid);
          // window.console.log(">>>>> HELLO OPEN FILE MAINXCOPY ", fileitemid, type, mainXcopyId);

          limaStore.openFileNewWindowConfirmFnc = () => {
            log.info(limaLogTag.ActaListV4, `onClick: clicked MAINCOPY -> Open in new window`, fileItem);
            void onFileOpenHandlerWrapper(
              fileitemid,
              actaName,
              mainXcopyId,
              null,
              null,
              type,
              false,
              false,
              true,
              true
            );
            log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileitemid}`);

            limaStore.setOpenFileNewWindow(false);
          };
          limaStore.openFileCurrentWindowConfirmFnc = () => {
            log.info(limaLogTag.ActaListV4, `onClick: clicked MAINCOPY -> Open in current window`, fileItem);
            void onFileOpenHandlerWrapper(
              fileitemid,
              actaName,
              mainXcopyId,
              null,
              null,
              type,
              false,
              true,
              false,
              false
            ).then(() => {
              log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileitemid}`);
              limaTimerStore.setCurrentActivePartion({
                itemId: fileitemid,
                itemMainCopyId: fileitemid,
                type: LimaObjectTypesEnum.ACTA,
                userKey: limaStore.userId,
                _key: null,
              });
              limaStore.setOpenFileNewWindow(false);
            });
          };

          limaStore.setOpenFileNewWindow(true);

          //log.info(limaLogTag.FileItemV4, `Set active timer to actakez from task file ${fileitemid}`);
        },
      });
      limaBreadCrumbStore.addToCurrentActions({
        actionType: LimaBreadCrumbACTIONTYPE.MAIN_COPY_SAVE,
        isActionEnabled: (openfileId: string, openfileType: OPENEDFILETYPE_ENUM, _currentPath: string) => {
          if (limaStore.menuOpen === MenuSelectedENUM.HOME || limaStore.menuOpen === MenuSelectedENUM.TOOLS)
            return false;
          // window.console.log(">>>> ROUTEPATH ", location.pathname);
          if (_currentPath.startsWith("/fileSaveFinishRt") === true) return false;
          // else {
          //   log.info(limaLogTag.FileItemV4,">>>>>> route", _currentPath);
          // }
          const mainXcopyId_ofDetial = limaActasStore.getMainCopyIdForActaId(fileitemid);
          log.info(
            limaLogTag.FileItemV4,
            `>>>>> SAVE IS ACION ENABLEB MAIN COPY current openXCOPY'${openfileId}/${openfileType}' and open page'${fileitemid}/${mainXcopyId_ofDetial}'`,
            openfileId,
            mainXcopyId_ofDetial,
            openfileType,
            fileitemid,
            limaStore.xcopyId,
            type
          );
          return openfileId === mainXcopyId_ofDetial && openfileType === OPENEDFILETYPE_ENUM.MAIN_COPY ? true : false;
        },
        actionAction: () => {
          // window.console.log(">>>>> HELLO SAVE FILE MAINCOPY ", fileitemid, type);
          history.push(`${Routes.FileSaveNewVersionRoute}`);
        },
      });
    } else {
      log.warn(limaLogTag.FileItemV4, "UEF-breadcrumbset dont know what to do", fileitemid, type);
    }
  }, [fileitemid, type]);
  //--------------
  // Handlers
  //--------------

  //--------------
  // Renders
  //--------------

  if (fileItem === undefined || fileItem === null) {
    return <>NULL{String(fileItem)}</>;
  }

  const RenderMainCopy = () => {
    if (isType_ActaListOK(fileItem)) {
      return (
        <>
          {/* <DetailActaInfoV4 itemId={fileItem.id} itemType={type} /> */}
          <DetailActaInfoV4 itemId={fileItem.mainXcopy} itemType={type} />
          {isMainXcopyOwner && (
            // <LimaHideableSection2 name={t("tpfile:fileitemV4.sectionKnowledgeLbl")} headerCount={`-`}>
            <KnowledgePageV4 xcopyType={XCOPY_TYPE.MAINXCOPY} xcopyId={fileitemid} />
            // </LimaHideableSection2>
          )}
          <TasksListV4
            defaultFilters={[
              {
                taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
                taksItemFilterValue: TASK_LINKED2ME_ENUM.NOTONLYMY,
              },
              { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC, taksItemFilterValue: true },
              { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE, taksItemFilterValue: TASKSTATE.OPEN },
            ]}
            includeHidable={true}
          ></TasksListV4>
          <FileListCopiesV4 actaId={fileItem.id} item={fileItem} />
          {isMainXcopyOwner && (
            <LimaHideableSection2
              name={t("tpfile:fileitemV4.sectionVersionsLbl")}
              headerCount={String(fileItem.histCount)}
            >
              <FileVersionsV4 item={fileItem} />
            </LimaHideableSection2>
          )}
          {isMainXcopyOwner && (
            <TagsComponent
              linkedId={fileItem.mainXcopy}
              linkedType={LIMA_ITEM_TYPE.XCOPY}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              tagsKeyList={fileItem.tags !== undefined && fileItem.tags !== null && fileItem.tags}
            />
          )}
          {isMainXcopyOwner && (
            <LimaHideableSection2 name="Info" addEnabled={false}>
              {fileItem.id && (
                <FileItemChangeOwnerV4
                  ownerKey={fileItem.ownerKey}
                  mainXcopy={fileItem.mainXcopy}
                  xcopyTimeStamp={fileItem.lastchange}
                  changeItem={(newOwner: string) => {
                    window.console.log("<<<< NEW OWER", newOwner);
                  }}
                />
              )}
            </LimaHideableSection2>
          )}
        </>
      );
    }
    return <></>;
  };

  const RenderOtherCopy = () => {
    return (
      <>
        <DetailActaInfoV4 itemId={fileitemid} itemType={type} />
        <KnowledgePageV4 xcopyType={type} xcopyId={fileitemid} />
        <TasksListV4
          defaultFilters={[
            {
              taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
              taksItemFilterValue: TASK_LINKED2ME_ENUM.NOTONLYMY,
            },
            { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC, taksItemFilterValue: true },
            { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE, taksItemFilterValue: TASKSTATE.OPEN },
          ]}
          includeHidable={true}
        ></TasksListV4>
        <WorkingCopiesDetailV4 xcopyId={fileitemid} xcopyType={type} />
        <LimaHideableSection2 name="Sdileni - NOT USE 2" addEnabled={false}>
          {fileitemid && <AccessComponentV4 itemId={fileitemid} type={LIMA_ITEM_TYPE.XCOPY} />}
        </LimaHideableSection2>
      </>
    );
  };

  return (
    <>
      {type === XCOPY_TYPE.MAINXCOPY && <RenderMainCopy />}
      {(type === XCOPY_TYPE.TRIMXCOPY || type === XCOPY_TYPE.FULLXCOPY) && <RenderOtherCopy />}
    </>
  );
};
