export interface getEqualsPara {
  actaKey: string;
  mainXcopyKey: string;
  actaName: string;
  actaParaCount: number;
  lastChange: string;
  equalParaKey: string;
  equalParaText: string;
  compareState: EqualsParaState_ENUM;
  index: number;
  totalCount: number;
}

export enum EqualsParaState_ENUM {
  FOUND_SAME_PARA = "FOUND_SAME_PARA",
  NO_SAME_PARA_IN_ACTA = "NO_SAME_PARA_IN_ACTA",
  ORIGIN_ACTA = "ORIGIN_ACTA",
  NOT_SAME_PARACOUNT_SKIPPED_ACTA = "NOT_SAME_PARACOUNT_SKIPPED_ACTA",
}
