import { BASE_LimaData } from "./BASE_LimaData";
import { XCOPY_TYPE } from "./Task";

export interface Acta extends BASE_LimaData {
  actaname: string;
}

export interface ActaShort {
  id: string;
  actaname: string;
  isOwner: boolean;
}

export interface CheckActaOwner {
  isowner: boolean;
  actaexists: boolean;
  lastversion_major: number;
  lastversion_minor: number;
  type: XCOPY_TYPE;
}

export enum EXPORT_TYPE {
  PDF = "PDF",
  PDFA = "PDFA",
  DOCX = "DOCX",
}
