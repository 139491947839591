import { getFocusStyle, getTheme, IconButton, IIconProps, ITheme, mergeStyleSets } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import limaStore from "../store/limaStore";
import { XCOPY_TYPE } from "../types/Task";

interface LimaFileOpenComponentProps {
  actaId: string;
  actaName: string;
  //isActaOwner: boolean;
  xcopyId: string;
  xcopyType: XCOPY_TYPE;
  historyTimeStamp: string;
  openFileHandler: (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE,
    withoutLoadingFile?: boolean,
    setMenuToFile?: boolean
  ) => void;
}
const theme: ITheme = getTheme();
// const { palette } = theme;

const OpenActaHistoryIcon: IIconProps = { iconName: "Download" };
const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
});

const LimaFileOpenComponent: FunctionComponent<LimaFileOpenComponentProps> = ({
  actaId,
  actaName,
  //isActaOwner,
  xcopyId,
  xcopyType,
  historyTimeStamp,
  openFileHandler,
}: LimaFileOpenComponentProps) => {
  const { t } = useTranslation(["tp"]);

  return (
    <IconButton
      className={classNames.itemCellIcon}
      iconProps={OpenActaHistoryIcon}
      title={t("tpfile:fileopen.openFileIconLbl")}
      disabled={limaStore.docxIsLoading}
      onClick={() =>
        // eslint-disable-next-line react/prop-types
        //
        //openFileHandler(historyItem.actaId, actaName, isActaOwner, null, historyItem.historyTimeStamp)
        openFileHandler(actaId, actaName, xcopyId, historyTimeStamp, null, xcopyType, false, true)
      }
    />
  );
};

export default LimaFileOpenComponent;
