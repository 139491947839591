import { IconButton, IIconProps, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { userAccListDataTypeLinked } from "../../../../dataModels/userAccListDataType";

interface AccessItemProps {
  userItem: userAccListDataTypeLinked;
  onClickCallback: (userId: string, edgeId: string) => void;
}

const showIcon: IIconProps = { iconName: "RedEye" };
const hideIcon: IIconProps = { iconName: "Hide" };

const AccessItem: FunctionComponent<AccessItemProps> = ({ userItem, onClickCallback }: AccessItemProps) => {
  const handleOnClick = () => {
    onClickCallback(userItem.userId, userItem.edgeId);
  };

  return (
    <Stack horizontal>
      <Stack.Item>
        {userItem.userName}[{userItem.userId}] - {userItem.edgeId}
      </Stack.Item>
      <Stack.Item>
        <IconButton
          iconProps={userItem.edgeId !== null && userItem.edgeId !== "" ? hideIcon : showIcon}
          onClick={handleOnClick}
        />
      </Stack.Item>
    </Stack>
  );
};

export default AccessItem;
