import {
  Checkbox,
  DefaultButton,
  // getFocusStyle,
  getTheme,
  IButtonStyles,
  ITheme,
  List,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react";
import moment = require("moment");
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { limaQuery_fetchFileVersions } from "../../../../api/calls/limaFileCalls";
import { actaListOK } from "../../../../api/schema/getActaList";
import { openAppendixFromTo2 } from "../../../../limaCommon/limaAppendix";
import { onFileOpenHandlerWrapper } from "../../../../limaCommon/limaFileOpen";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { classNamesCommon } from "../../../../limaComponents/LimaComponentsCommonStyles";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import limaStore from "../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { XCOPY_TYPE } from "../../../../types/Task";
import { XcopyHistory } from "../../../../types/XcopyHistory";
// import {
//   HistAppendixStateENUM,
//   HistAppendixStateItem,
//   HistAppendixStateItemV4,
// } from "../../../Menu/File/FileOpen/components/FileItemHistAppendix";
import FileItemHistItemV4 from "./FileItemHistItemV4";

interface IFileVersionsV4Props {
  item: actaListOK;
  listOnly?: boolean;
  onLoadFinishClb?: (items: XcopyHistory[]) => void;
}

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    marginTop: 10,
    marginLeft: "5%",
    width: "90%",
  },
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  versionCheckBox: {
    /*margin: 5,*/
    paddingTop: 5,
  },
  appendixInfoFirstLine: [fonts.mediumPlus, { marginLeft: "5%", marginTop: 15, fontWeight: 600 }],
  appendixInfoSecondLine: [fonts.medium, { marginLeft: "5%" }],
});

const enum CheckBoxStateENUM {
  CHECKED,
  UNCHECKED,
  DISABLED,
}

const FileVersionsV4: FunctionComponent<IFileVersionsV4Props> = ({
  item,
  listOnly,
  onLoadFinishClb: listItemsClb,
}: IFileVersionsV4Props) => {
  const [createAppendixEnabled, setCreateAppendixEnabled] = useState<boolean>(false);
  const [histList, setHistoryList] = useState<XcopyHistory[]>([]);
  const [appendixIncludeVersions, setAppendixIncludeVersions] = useState<string[]>([]);
  const { t } = useTranslation();
  const { isLoading, refetch } = useQuery(
    ["filehistory", item.id],
    // () => fetchFileVersions(item.id, isDescOrderHistory),
    () => limaQuery_fetchFileVersions(item.id, true),
    {
      enabled: false,

      onSuccess: (data: XcopyHistory[]) => {
        log.info(limaLogTag.FileListVersions, "useQuery get message ", data);
        setHistoryList(data);
        if (listItemsClb !== undefined) {
          log.info(limaLogTag.FileListVersions, "useQuery callback");
          listItemsClb(data);
        } else {
          log.info(limaLogTag.FileListVersions, "useQuery callback not set");
        }
      },
    }
  );

  //----------------------
  // Reaction
  //----------------------

  useEffect(() => {
    void refetch();
  }, []);

  useEffect(() => {
    if (appendixIncludeVersions.length === 2) setCreateAppendixEnabled(true);
    else if (createAppendixEnabled === true) setCreateAppendixEnabled(false);
  }, [appendixIncludeVersions]);
  //----------------
  // Handlers
  //----------------

  const onAppChecklist = (key: string, checked?: boolean) => {
    log.info(limaLogTag.FileListVersions, `onAppChecklist '${key}' add `, checked);
    if (checked === true) {
      // add to check
      // appendixIncludeVersions.push(id);
      setAppendixIncludeVersions([...appendixIncludeVersions, key]);
    } else if (checked === false && appendixIncludeVersions.length > 0) {
      //remove from check
      // const { id, ...newResponse } = appendixIncludeVersions;
      setAppendixIncludeVersions(appendixIncludeVersions.filter((item) => item !== key));
    }
  };

  const onGetAppendixBtn = () => {
    //insertAppendixFromTo();
    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: ");
    let endActHist: XcopyHistory | null = null;
    let startActHist: XcopyHistory | null = null;

    histList.every((histItem: XcopyHistory) => {
      log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: processing ", histItem);
      if (appendixIncludeVersions.includes(histItem._key)) {
        log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: included ", appendixIncludeVersions, histItem);
        if (startActHist === null) {
          log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: set start ", appendixIncludeVersions, histItem);
          startActHist = histItem;
        } else {
          log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: set start ", appendixIncludeVersions, histItem);
          endActHist = histItem;
        }
      }
      return true;
    });
    log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: start ", startActHist);
    log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: end ", endActHist);

    if (moment(startActHist.cTimeStamp).isAfter(endActHist.cTimeStamp)) {
      log.debug(limaLogTag.FileListVersions, "onGetAppendixBtn: need to switch ");
      const tempItem = startActHist;
      startActHist = endActHist;
      endActHist = tempItem;
    }

    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: start ", startActHist);
    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: end ", endActHist);

    void openAppendixFromTo2(
      startActHist.xcopyId,
      LIMA_ITEM_TYPE.XCOPY,
      startActHist.historyTimeStamp,
      endActHist.xcopyId,
      LIMA_ITEM_TYPE.XCOPY,
      endActHist.historyTimeStamp,
      startActHist.historyName,
      true
    );
  };

  const getCheckBoxState = (histVersionKey: string): CheckBoxStateENUM => {
    if (appendixIncludeVersions.includes(histVersionKey)) {
      return CheckBoxStateENUM.CHECKED;
    } else if (appendixIncludeVersions.length == 2) {
      return CheckBoxStateENUM.DISABLED;
    } else return CheckBoxStateENUM.UNCHECKED;
  };
  //-------------------------
  // Renders
  //-------------------------

  if (isLoading) {
    return LimaLoadingComponnet(5, t("tpfile:fileopen.history.loadingHistoryLbl"));
  }

  const onRenderCell = (singleHistItem: XcopyHistory, index: number): JSX.Element => {
    // if (item !== null) {
    return (
      <Stack className={classNamesCommon.LimaFileLists_listItemWraper} data-is-focusable={true}>
        {listOnly === true ? (
          <></>
        ) : (
          <Checkbox
            title={"Add to appendix"}
            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
              onAppChecklist(singleHistItem._key, checked)
            }
            checked={getCheckBoxState(singleHistItem._key) === CheckBoxStateENUM.CHECKED}
            disabled={getCheckBoxState(singleHistItem._key) === CheckBoxStateENUM.DISABLED}
          />
        )}
        <FileItemHistItemV4
          key={singleHistItem._id}
          // eslint-disable-next-line react/prop-types
          actaName={item.name}
          isActaOwner={item.isOwner}
          historyItem={singleHistItem}
          isLast={index === 0}
          onFileOpenClickHandler={onFileOpenHandlerWrapper}
          xcopyType={XCOPY_TYPE.MAINXCOPY}
        />
      </Stack>
    );
  };

  const AppendixCreateBtn = observer((): JSX.Element => {
    return (
      <DefaultButton
        styles={buttonStyles}
        disabled={!createAppendixEnabled || limaStore.docxIsLoading}
        text={t("tpfile:fileopen.history.createappendixBtn")}
        onClick={() => onGetAppendixBtn()}
      />
    );
  });

  const ShowHistItems = (): JSX.Element => {
    return (
      <>
        <List items={histList} onRenderCell={onRenderCell} />
      </>
    );
  };

  return (
    <>
      <ShowHistItems />
      {listOnly == true ? (
        <></>
      ) : (
        <>
          <div className={classNames.appendixInfoFirstLine}>
            {t("tpfile:fileopen.history.createappendix.labelFirstLineLbl")}
          </div>
          <div className={classNames.appendixInfoSecondLine}>
            {t("tpfile:fileopen.history.createappendix.labelSecondLineLbl")}
          </div>
          <AppendixCreateBtn />
        </>
      )}
    </>
  );
};

export default FileVersionsV4;

//----------------------
//   REACT QUERY
//----------------------
