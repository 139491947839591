import { Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import limaStore from "../../../../store/limaStore";
import Routes from "../../../Menu/routes";
import LimaToolItem from "./components/LimactaToolItem";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToolsOtherPartProps {}

const ToolsOtherPart: React.FunctionComponent<IToolsOtherPartProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Stack horizontal wrap={true}>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "Settings" }}
          toolName={t("tpmain:tools.other.settingLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.EditMenuHelperRoute}`);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "Robot" }}
          toolName={t("tpmain:tools.other.testhelpLbl")}
          toolItemWidth={75}
          onClickClb={() => limaStore.setShowTestingInfo(!limaStore.showTestingInfo)}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}
          dontShowOnNoLic={true}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "Robot" }}
          toolName={t("tpmain:tools.other.testcallsLbl")}
          toolItemWidth={75}
          onClickClb={() => history.push(`${Routes.ToolsTestingCallPageRoute}`)}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}
          dontShowOnNoLic={true}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "Robot" }}
          toolName={t("tpmain:tools.other.testErrorsLbl")}
          toolItemWidth={75}
          onClickClb={() => history.push(`${Routes.ToolsTestingErrsPageRoute}`)}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}
          dontShowOnNoLic={true}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "Robot" }}
          toolName={t("tpmain:tools.other.testDataLbl")}
          toolItemWidth={75}
          onClickClb={() => history.push(`${Routes.ToolsTestingDataPageRoute}`)}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}
          dontShowOnNoLic={true}
        />
      </Stack.Item>
    </Stack>
  );
};

export default ToolsOtherPart;
