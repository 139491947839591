export interface actaListFailed {
  msg: string;
}

export interface actaListOK {
  id: string;
  name: string;
  linkedFrom?: string;
  lastchange?: string;
  edgeId: string;
  histCount?: number;
  histLast?: string;
  ownerKey?: string;
  isOwner: boolean;
  mainXcopy?: string;
  copiesCount?: number;
  copiesList: string[];
  tags?: string[];
  favorite?: boolean;
}

export interface actaListResponse {
  result: string;
  foruser: string;
  actalist: actaListOK[];
}

type isType_ActaListOK_options = actaListOK | string;

export const isType_ActaListOK = (type: isType_ActaListOK_options): type is actaListOK => {
  return (
    (type as actaListOK).id !== undefined &&
    (type as actaListOK).name !== undefined &&
    (type as actaListOK).edgeId !== undefined &&
    (type as actaListOK).isOwner !== undefined
  );
};

/*
  private String id;
  private String name;
  private String linkedFrom;
  private String lastchange;
  private String edgeId;
  private int histCount;
  private String histLast;
  private String ownerKey;
  private Boolean isOwner=false;
  private String mainXcopy;
  private int copiesCount; 
*/
