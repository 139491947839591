import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Tags } from "../../../types/Tags";
import { mergeStyleSets } from "@fluentui/react";

interface ILimaTagMiniCmpProps {
  tagItem: Tags;
  setTagClb: (item: Tags) => void;
}

const classNames = mergeStyleSets({
  tagBase: {
    width: 14,
    overflow: "hidden",
    borderRadius: 2,
    cursor: "pointer",
    height: 7,
  },
  tagBaseHl: {
    borderColor: "black",
    borderWidth: 1,
    borderStyle: "solid",
    margin: 0,
    height: 12,
    borderTopStyle: "none",
    borderTopLeftRadius: "unset",
    borderTopRightRadius: "unset",
  },
  tagBaseHlnone: {
    borderColor: "gray",
    borderWidth: 1,
    borderStyle: "solid",
    margin: 1,
  },
  tagsList: {},
});

const LimaTagMiniCmp: FunctionComponent<ILimaTagMiniCmpProps> = (props: ILimaTagMiniCmpProps) => {
  const [extend, setExtend] = useState<boolean>(false);
  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <div
      className={`${classNames.tagBase} ${extend ? classNames.tagBaseHl : classNames.tagBaseHlnone}`}
      style={{ backgroundColor: props.tagItem.tagcolor }}
      onMouseEnter={() => {
        setExtend(true);
        props.setTagClb(props.tagItem);
      }}
      onMouseLeave={() => {
        setExtend(false);
        props.setTagClb(undefined);
      }}
    ></div>
  );
};

export default LimaTagMiniCmp;

//----------------------
//   REACT QUERY
//----------------------
