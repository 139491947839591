import { Moment } from "moment";
// import moment = require("moment");
import * as moment from "moment";
import { ButtonType, DefaultButton } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { LimaSimpleDatePicker } from "../../../../limaComponents/LimaSimpleDatePicker";
import { useTranslation } from "react-i18next";

interface HistParaDateRangeProps {
  fromDate: Moment;
  toDate: Moment;
  setDate: (fromDate: Moment, toDate: Moment) => void;
  setOnChange?: boolean;
  hideUpdateBtn?: boolean;
}

const HistParaDateRange: FunctionComponent<HistParaDateRangeProps> = ({
  fromDate,
  toDate,
  setDate,
  setOnChange,
  hideUpdateBtn,
}: HistParaDateRangeProps) => {
  const [currentFromDate, setCurrentFromDate] = useState<Moment>(fromDate);
  const [currentToDate, setCurrentToDate] = useState<Moment>(toDate);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentFromDate(fromDate);
    setCurrentToDate(toDate);
  }, []);

  const handlerLoadData = () => {
    setDate(currentFromDate, currentToDate);
  };

  useEffect(() => {
    setOnChange && setDate(currentFromDate, currentToDate);
  }, [currentFromDate, currentToDate]);

  return (
    <>
      <LimaSimpleDatePicker
        key="dateFrom"
        limaLabel={t("tpmain:history.fromDataPickerLbl")}
        // defaultValue={(currentFromDate && currentFromDate.toDate()) || moment().subtract("1", "years").toDate()}
        value={(currentFromDate && currentFromDate.toDate()) || moment().subtract("1", "years").toDate()}
        onSelectDate={(date) => {
          // window.console.log(">>>>XXX ", date);
          setCurrentFromDate(moment(date));
          return date;
        }}
        placeholder={t("tpmain:history.datepickerPlcHld")}
        ariaLabel={t("tpmain:history.datepickerPlcHld")}
      />
      <LimaSimpleDatePicker
        key="dateTo"
        limaLabel={t("tpmain:history.tillDataPickerLbl")}
        value={(currentToDate && currentToDate.toDate()) || moment().toDate()}
        onSelectDate={(date) => {
          // window.console.log(">>>>XXX ", date);
          setCurrentToDate(moment(date));
          return date;
        }}
        placeholder={t("tpmain:history.datepickerPlcHld")}
        ariaLabel={t("tpmain:history.datepickerPlcHld")}
      />
      {/* <DatePicker
        key="dateFrom"
        label="FROM: "
        value={(currentFromDate && currentFromDate.toDate()) || moment().subtract("1", "M").toDate()}
        initialPickerDate={(fromDate && fromDate.toDate()) || moment().toDate()}
        // initialPickerDate={moment(fromDate).toDate()}
        showMonthPickerAsOverlay={true}
        onSelectDate={(date) => {
          console.log("Date change to ", date);
          setCurrentFromDate(moment(date));
          return date;
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
      /> */}
      {/* <DatePicker
        key="dateTo"
        label="TO: "
        value={(currentToDate && currentToDate.toDate()) || moment().toDate()}
        initialPickerDate={(toDate && toDate.toDate()) || moment().toDate()}
        //initialPickerDate={moment(toDate).toDate()}
        showMonthPickerAsOverlay={true}
        onSelectDate={(date) => {
          console.log("Date change to ", date);
          setCurrentToDate(moment(date));
          return date;
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
      /> */}
      {!hideUpdateBtn && (
        <DefaultButton buttonType={ButtonType.hero} iconProps={{ iconName: "ChevronRight" }} onClick={handlerLoadData}>
          {t("tpmain:history.updateBtnLbl")}
        </DefaultButton>
      )}
    </>
  );
};

export default HistParaDateRange;
