import * as React from "react";

import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import { Text } from "office-ui-fabric-react";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { limaActaListGet } from "../../../../limaCommon/limaActaList";
import limaStore from "../../../../store/limaStore";
import { actaListOK } from "../../../../api/schema/getActaList";
// import limaTasksStore from "../../../../store/limaTasksStore";
import { defWelcomeLastFileLimit as DEF_WelcomeLastFileLimit } from "../../../../limaCommon/limaEnv";
// import TasksList from "../../Edit/Tasks/TaskListV2";
// import { User2Task_withActaXcopy } from "../../../../types/User2Task_withActaXcopy";
import Routes from "../../routes";
import { useHistory } from "react-router-dom";
import LimaFileList from "../FileOpen/components/LimaFileList";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { udpateVisilibity, UpdateVisibilityTypes } from "../../../../limaCommon/limaVisibilityCalls";
import { WORDITEMTYPE } from "../../../../api/apilinks";
import TasksListV3 from "../../Edit/Tasks/TaskListV3";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";
import { User2Task_withActaXcopy } from "../../../../types/User2Task_withActaXcopy";
import { useTranslation } from "react-i18next";

const WelcomePage: FunctionComponent = () => {
  const [fileItems, setFileItems] = useState<actaListOK[]>();
  const history = useHistory();
  const { t } = useTranslation(["tpfile"]);

  useEffect(() => {
    log.info(limaLogTag.WelcomePage, "useEffect: mounted");
    void getActaList();
    //limaTasksStore.getTasksList();
  }, []);

  //TODO: REMOVE
  const remove = async (actaId: string, edgeId: string) => {
    log.info(limaLogTag.FileOpen, `remove: actaId['${actaId}'] edgeId ['${edgeId}']`);
    await udpateVisilibity(
      "dUser/" + limaStore.userId,
      "dActa/" + actaId,
      null,
      limaStore.userId,
      null,
      new Date().toISOString(),
      WORDITEMTYPE.acta,
      UpdateVisibilityTypes.COMMON,
      edgeId
    ).then(() => {
      const updatedItems = fileItems.filter((i) => i.edgeId !== edgeId);
      //setActas(updatedItems);
      setFileItems(updatedItems);
    });
  };

  const getActaList = () => {
    //TODO TO Funvtions
    const loaderID = limaLoadeStore.add("getting acta list for welcome page");
    // limaActaListGet(limaStore.userId, DEF_WelcomeLastFileLimit)
    limaActaListGet(DEF_WelcomeLastFileLimit)
      .then((data: actaListOK[] | void) => {
        log.info(limaLogTag.WelcomePage, "getActaList: limaActaListGet finish", data);
        if (data) {
          setFileItems(data);
        }
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      })
      .catch(() => {
        log.error(limaLogTag.WelcomePage, "getActaList: limaActaListGet finish with error");
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      });
    // .finally(() => {

    // });
  };

  const onTaskChange = (task: User2Task_withActaXcopy) => {
    log.info(limaLogTag.WelcomePage, `onTaskChange: clicked -> now`, task);
    history.push(Routes.EditMenuTasks2Route_base + task._key);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Text variant={"large"}>{t("tpfile:home.welcomeLbl")}</Text>
      </div>
      <LimaHideableSection name={t("tpfile:home.lastfilesectLbl")}>
        <LimaFileList items={fileItems} remove={remove} />
      </LimaHideableSection>

      <LimaHideableSection name={t("tpfile:home.yourtaskssectLbl")}>
        <TasksListV3
          onStartFilterClosedTasks={true}
          onStartFilterOnlyMy={true}
          showFilters={false}
          onClickHandler={onTaskChange}
        />
      </LimaHideableSection>
    </>
  );
};

export default WelcomePage;
