import { log } from "missionlog";
import { reaction } from "mobx";
import { Moment } from "moment";
import moment = require("moment");
import { Separator, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimeBreadCrumb } from "../../../limaComponents/LimeBreadCrumb";
// import { LimeBreadCrumb } from "../../../limaComponents/LimeBreadCrumb";
import limaStore from "../../../store/limaStore";
import { SelectedInWord } from "../../../types/wordObjects";
import HistParaDateRange from "./components/HistParaDateRange";
import HistParaItemParaCommentsItems from "./components/HistParaItemParaCommentsItems";
import HistParaItemParaTextItems from "./components/HistParaItemParaTextItems";
import HistParaItemTaskItemsV4 from "./components/HistParaItemTaskItemsV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IHistParaItemPageV4Props {}

const HistParaItemPageV4: FunctionComponent<IHistParaItemPageV4Props> = () => {
  const [dateFrom, setDateFrom] = useState<Moment>(moment().subtract("1", "years"));
  const [dateTo, setDateTo] = useState<Moment>(moment());
  const [selectedItem, setSelectedItem] = useState<SelectedInWord | null>(
    (limaStore.selectedInWord && limaStore.selectedInWord[0]) || null
  );
  const { t } = useTranslation();

  const updateDate = (fromDate: Moment, toDate: Moment) => {
    setDateFrom(fromDate);
    setDateTo(toDate);
  };

  useEffect(() => {
    const disposer = reaction(
      () => limaStore.selectedInWord,
      (selected) => {
        log.info(limaLogTag.HistParaItemPage, "HistParaItemTaskRow show data: ", selected);
        selected && setSelectedItem(selected[0]);
      }
    );
    return function cleanup() {
      disposer();
    };
  }, []);

  return (
    <>
      <Stack>
        <Stack.Item>
          <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.toolsLbl"), t("tpmain:menu.show.showHistoryLbl")]} />
        </Stack.Item>
        <Stack.Item>{t("tpmain:history.historyInfoNotic")}</Stack.Item>
        <Stack.Item>
          <HistParaDateRange fromDate={dateFrom} toDate={dateTo} setDate={updateDate} />
        </Stack.Item>

        <Separator></Separator>
        {limaStore.isMainCopyOpen && (
          <Stack.Item>
            <HistParaItemParaTextItems
              dateFrom={dateFrom}
              dateTo={dateTo}
              //selectedItem={(limaStore.selectedInWord && limaStore.selectedInWord[0]) || null}
              selectedItem={selectedItem}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          <HistParaItemParaCommentsItems
            dateFrom={dateFrom}
            dateTo={dateTo}
            //selectedItem={(limaStore.selectedInWord && limaStore.selectedInWord[0]) || null}
            selectedItem={selectedItem}
          />
        </Stack.Item>
        <Stack.Item>
          <HistParaItemTaskItemsV4 dateFrom={dateFrom} dateTo={dateTo} selectedItem={selectedItem} />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default HistParaItemPageV4;
