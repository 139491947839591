import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsEqualParas = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <path
        d="M4.75 4A2.75 2.75 0 0 0 2 6.75v4.507a5.496 5.496 0 0 1 1.5-.882V6.75c0-.69.56-1.25 1.25-1.25h14.5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-8.275l1.5 1.5h6.775A2.75 2.75 0 0 0 22 17.25V6.75A2.75 2.75 0 0 0 19.25 4H4.75Z"
        fill="#8e8e93"
      />
      <path
        d="M8.663 11a5.529 5.529 0 0 1 1.447 1.5h7.14a.75.75 0 0 0 0-1.5H8.663ZM15.25 15.5H11c0-.52-.072-1.023-.207-1.5h4.457a.75.75 0 0 1 0 1.5ZM6.75 8a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5ZM5.5 20a4.48 4.48 0 0 0 2.607-.832l2.613 2.612a.75.75 0 1 0 1.06-1.06l-2.612-2.613A4.5 4.5 0 1 0 5.5 20Zm0-1.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
        fill="#8e8e93"
      />
    </svg>
  );
};

// export function ic_fluent_slide_search_24_regular(props) {
//   return (
//   <svg width="
//   24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//     <path d="M4.75 4A2.75 2.75 0 0 0 2 6.75v4.507a5.496 5.496 0 0 1 1.5-.882V6.75c0-.69.56-1.25 1.25-1.25h14.5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-8.275l1.5 1.5h6.775A2.75 2.75 0 0 0 22 17.25V6.75A2.75 2.75 0 0 0 19.25 4H4.75Z" fill="#212121"/><path d="M8.663 11a5.529 5.529 0 0 1 1.447 1.5h7.14a.75.75 0 0 0 0-1.5H8.663ZM15.25 15.5H11c0-.52-.072-1.023-.207-1.5h4.457a.75.75 0 0 1 0 1.5ZM6.75 8a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5ZM5.5 20a4.48 4.48 0 0 0 2.607-.832l2.613 2.612a.75.75 0 1 0 1.06-1.06l-2.612-2.613A4.5 4.5 0 1 0 5.5 20Zm0-1.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" fill="#212121"/>
//     </svg>
//   )
// }
