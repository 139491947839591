import { ActionButton, IIconProps, mergeStyleSets, Spinner, Stack, TextField } from "@fluentui/react";
import { log } from "missionlog";
import { FormEvent, FunctionComponent, useState } from "react";
import React = require("react");
import { limaLogTag } from "../../../../limaCommon/limaLog";

interface AttachmentDDFileItemProps {
  file: File;
  removeCallBack: () => void;
  uploadCallBack?: (name: string, descr: string) => void;
}

const removeFileIcon: IIconProps = { iconName: "Cancel" };
const uploadFileIcon: IIconProps = { iconName: "CloudUpload" };

const classNames = mergeStyleSets({
  filename: {
    color: "#bbbbbb",
  },
});

const AttachmentDDFileItem: FunctionComponent<AttachmentDDFileItemProps> = ({
  file,
  removeCallBack,
  uploadCallBack,
}: AttachmentDDFileItemProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [name, setName] = useState<string>(file.name);
  const [desc, setDesc] = useState<string>("");

  const onRemove = () => {
    log.info(limaLogTag.AttachmentDDFileItem, "clicked on ", file);
    removeCallBack();
  };

  const onUpload = () => {
    log.info(limaLogTag.AttachmentDDFileItem, "clicked on ", file);
    setIsUploading(true);
    let nameOut = name;
    if (name === "") {
      nameOut = "file " + file.name;
    }
    uploadCallBack(nameOut, desc);
  };

  const onNameChange = (ev: FormEvent<HTMLInputElement>) => {
    setName((ev.target as HTMLInputElement).value);
  };

  const onDesrcChange = (ev: FormEvent<HTMLInputElement>) => {
    setDesc((ev.target as HTMLInputElement).value);
  };

  return (
    <Stack horizontal>
      {isUploading == false && (
        <Stack.Item grow={1}>
          <ActionButton iconProps={removeFileIcon} onClick={onRemove}></ActionButton>
        </Stack.Item>
      )}
      {isUploading == false && uploadCallBack !== undefined && (
        <Stack.Item grow={1}>
          <ActionButton iconProps={uploadFileIcon} onClick={onUpload}></ActionButton>
        </Stack.Item>
      )}
      {isUploading == true && (
        <Stack.Item grow={2}>
          <Spinner label="uploading..." />
        </Stack.Item>
      )}
      <Stack.Item grow={10}>
        <Stack>
          <Stack.Item className={classNames.filename}>
            <i>
              {file.name}/[{file.size}]
            </i>
          </Stack.Item>
          <Stack.Item>
            <TextField placeholder={"attachement name"} defaultValue={file.name} underlined onChange={onNameChange} />
          </Stack.Item>
          <Stack.Item>
            <TextField placeholder={"attachement description"} underlined onChange={onDesrcChange} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default AttachmentDDFileItem;
