import * as React from "react";

import { log, tag } from "missionlog";
import { FunctionComponent } from "react";
import { Separator } from "office-ui-fabric-react";
import UsersList from "./usersList";

import { observer } from "mobx-react-lite";
import limaStore from "../../../../store/limaStore";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useTranslation } from "react-i18next";

log.init({ UsersListPage: "Users/UsersListPage" });

const UsersListPage: FunctionComponent = () => {
  // log.info(tag.UsersListPage, "hello");
  const { t } = useTranslation();

  //useEffect(() => {}, []);
  const IsActaOpen = observer((): JSX.Element => {
    log.info(tag.UsersListPage, `IsActaOpen: actaId [${limaStore.actaId}]`, limaStore.actaId);
    return (
      <>
        {!(limaStore.actaId != undefined && limaStore.actaId != null && limaStore.actaId !== "") &&
          "- No active acta open"}
      </>
    );
  });

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.usersLbl"), t("tpmain:menu.users.userlistLbl")]} />
      <Separator alignContent="center">
        {t("tpuser:userslist.userslistLbl")}
        <IsActaOpen />
      </Separator>
      <UsersList />
    </>
  );
};

export default UsersListPage;
