import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import { LimaCompLicActive, LimaCompLicUsers } from "../../types/LimaLicences";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { HttpResponse, httpGetAuth, httpPostAuth, httpPutAuth } from "../httpAPI";
import {
  urlGetActiveUsersList,
  urlGetCompanyUsersLicences,
  urlPatchRemoveCompanyUserLicence,
  urlPostUpdateCompanyUserLicence,
} from "../apilinks";
import limaStore from "../../store/limaStore";

/**
 * Method used to get all usersXlicences per company
 * @param companyKey
 * @returns
 */
export const limaQuery_getUsersLicences = async (companyKey: string): Promise<LimaCompLicUsers[]> => {
  log.info(limaLogTag.LimaLicenceCalls, "fetching data for limaQuery_getUsersLicences", companyKey);

  if (companyKey === undefined || companyKey === null || companyKey === "") {
    log.warn(limaLogTag.LimaLicenceCalls, "limaQuery_getUsersLicences: no companyKey", companyKey);
    log.warn(
      limaLogTag.LimaLicenceCalls,
      "limaQuery_getUsersLicences: using limastore user companyKey",
      limaStore.userCompanyKey
    );
    companyKey = limaStore.userCompanyKey;
    if (companyKey === undefined || companyKey === null || companyKey === "") {
      log.warn(limaLogTag.LimaLicenceCalls, "no companyKey", companyKey);
      throw new Error("no company key");
    }
  }

  const loaderID = limaLoadeStore.add("Getting licence users");
  let response: HttpResponse<LimaCompLicUsers[]> = null;
  try {
    response = await httpGetAuth(urlGetCompanyUsersLicences(companyKey), loaderID.ac);

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.LimaLicenceCalls, "limaQuery_getUsersLicences: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.LimaLicenceCalls, "limaQuery_getUsersLicences: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

export const limaQuery_setUserLicence = async (
  updateUser: LimaCompLicUsers,
  companyKey: string
): Promise<LimaCompLicUsers> => {
  log.info(
    limaLogTag.LimaLicenceCalls,
    "limaQuery_setUserLicence: fetching data for limaQuery_setUserLicence",
    updateUser
  );

  if (companyKey === undefined || companyKey === null || companyKey === "") {
    log.warn(limaLogTag.LimaLicenceCalls, "limaQuery_setUserLicence: no companyKey", companyKey);
    return null;
  }

  const loaderID = limaLoadeStore.add("Setting licence users");

  const postData: LimaCompLicUsers = {
    licenceType: updateUser.licenceType,
    company2userKey: updateUser.company2userKey,
    userEmail: updateUser.userEmail,
    userKey: updateUser.userKey,
    userName: updateUser.userName,
    licenceusedid: updateUser.licenceusedid,
    dtimestamp: updateUser.dtimestamp,
  };
  let response: HttpResponse<LimaCompLicUsers> = null;
  try {
    if (updateUser.company2userKey === null || updateUser.company2userKey === "") {
      response = await httpPostAuth(urlPostUpdateCompanyUserLicence(companyKey), postData, loaderID.ac);
    } else {
      response = await httpPutAuth(urlPostUpdateCompanyUserLicence(companyKey), postData, loaderID.ac);
    }

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.LimaLicenceCalls, "limaQuery_setUserLicence: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.LimaLicenceCalls, "limaQuery_setUserLicence: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

export const limaQuery_delUserLicence = async (
  updateUser: LimaCompLicUsers,
  companyKey: string
): Promise<LimaCompLicUsers> => {
  log.info(
    limaLogTag.LimaLicenceCalls,
    "limaQuery_delUserLicence: fetching data for limaQuery_delUserLicence",
    updateUser
  );

  if (companyKey === undefined || companyKey === null || companyKey === "") {
    log.warn(limaLogTag.LimaLicenceCalls, "limaQuery_delUserLicence: no companyKey", companyKey);
    return null;
  }

  if (
    updateUser.company2userKey === undefined ||
    updateUser.company2userKey === null ||
    updateUser.company2userKey === ""
  ) {
    log.warn(limaLogTag.LimaLicenceCalls, "limaQuery_delUserLicence: no company2userKey", companyKey);
    return null;
  }

  const loaderID = limaLoadeStore.add("Setting licence users");

  const postData: LimaCompLicUsers = {
    licenceType: updateUser.licenceType,
    company2userKey: updateUser.company2userKey,
    userEmail: "",
    userKey: "",
    userName: "",
    licenceusedid: updateUser.licenceusedid,
    dtimestamp: updateUser.dtimestamp,
  };
  let response: HttpResponse<LimaCompLicUsers> = null;
  try {
    response = await httpPutAuth(urlPatchRemoveCompanyUserLicence(companyKey), postData, loaderID.ac);

    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.LimaLicenceCalls, "limaQuery_delUserLicence: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.LimaLicenceCalls, "limaQuery_delUserLicence: parsedBody is not null", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * Get current company licences and users
 */
export const limaQuery_getComLicUState = async (companyId?: string): Promise<LimaCompLicActive[]> => {
  // return new Promise(async (resolve, reject): Promise<LimaCompLicActive[] | void> => {
  let response: HttpResponse<LimaCompLicActive[]>;
  log.info(limaLogTag.LimaLicenceCalls, `limactaCallComLicUpdate: starts company id '${companyId}'`);
  const loaderID = limaLoadeStore.add("lima check comp licences processing");

  try {
    response = await httpGetAuth(urlGetActiveUsersList(companyId), loaderID.ac);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
      // reject;
    }
    if (response.parsedBody !== undefined) {
      //if (Array.isArray(response.parsedBody)) {
      //TODO: Why not working
      log.info(limaLogTag.LimaLicenceCalls, "limactaCallComLicUpdate: looks like get some data ", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
      // resolve(response.parsedBody);
      // }
      // log.info(limaLogTag.LimaUser, "limactaCallComLicUpdate: looks no array ", response.parsedBody);
      // limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    } else {
      log.info(limaLogTag.LimaLicenceCalls, "limactaCallComLicUpdate: looks no data ", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw new Error("no no data");
      // reject;
    }
  } catch (error) {
    log.error(limaLogTag.LimaLicenceCalls, "limactaCallComLicUpdate: some error ", error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
    // reject;
  }
  // });
};
