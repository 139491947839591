import { getTheme, ITheme, List, mergeStyleSets, Stack } from "@fluentui/react";
import { log } from "missionlog";
import moment = require("moment");
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
// import Moment from "react-moment";

import { useQuery } from "react-query";
import { urlGetAllTimersBetween } from "../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../api/httpAPI";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { mergeByActa } from "../../../limaCommon/limaTimersHelpers";
import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import { LimaSimpleDatePicker } from "../../../limaComponents/LimaSimpleDatePicker";
import limaLoadeStore, { LoaderItemEnd } from "../../../store/limaLoaderStore";
import limaStore from "../../../store/limaStore";
import { ITimePerActa, ITimerPartion } from "../../../types/Timer";
import TimerPartion from "./components/TimerPartion";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITimersV4reportProps {}

interface ITimersPerActaDateSplit {
  dateStart: moment.Moment;
  timersPerActa: ITimePerActa[];
  timers: ITimerPartion[];
}

const processTimers = (
  fromDate: moment.Moment,
  toDate: moment.Moment,
  partitions: ITimerPartion[]
): ITimersPerActaDateSplit[] => {
  const reportTimers: ITimersPerActaDateSplit[] = [];

  //split by date
  partitions.forEach((item: ITimerPartion) => {
    if (!moment(item.timerDateStart).isBetween(fromDate, toDate)) {
      log.info(limaLogTag.TimersV4report, "processTimers skipping item", item);
    } else {
      const exist_reportTimer = reportTimers.find((reportTimer: ITimersPerActaDateSplit) =>
        reportTimer.dateStart.isSame(item.timerDateStart, "date")
      );
      if (exist_reportTimer !== undefined && exist_reportTimer !== null) {
        exist_reportTimer.timers = [...exist_reportTimer.timers, item];
      } else {
        reportTimers.push({
          dateStart: moment(item.timerDateStart),
          timersPerActa: [],
          timers: [item],
        });
      }
    }
  });

  //merge by acta
  reportTimers.forEach((item: ITimersPerActaDateSplit) => {
    item.timersPerActa = mergeByActa(item.timers);
  });

  return reportTimers;
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts, palette } = theme;

const classNames = mergeStyleSets({
  reportLine: {
    cursor: "default",
    borderBottom: "1px solid #e3e3e3",
    borderTop: "1px solid #e3e3e3",
    paddingTop: 10,
    paddingBottom: 5,
    selectors: {
      "&:hover": { background: palette.neutralLight },
    },
  },
  reportLineLabel: [
    fonts.mediumPlus,
    {
      flex: "none",
    },
  ],
  reportLineData: {
    padding: 5,
  },
});

const TimersV4report: FunctionComponent<ITimersV4reportProps> = () => {
  const { t } = useTranslation();
  const [fromTS, setFromTS] = useState<moment.Moment>(moment().startOf("month"));
  const [toTS, setToTS] = useState<moment.Moment>(moment());
  const [reportData, setReportData] = useState<ITimersPerActaDateSplit[] | null>(null);

  const { isLoading, error, refetch } = useQuery(["attachements", fromTS, toTS], () => fetchReport(fromTS, toTS), {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      log.error(limaLogTag.TimersV4report, "useQuery get attachements ", err);
    },
    onSuccess: (data) => {
      log.info(limaLogTag.TimersV4report, "useQuery get attachements ", data);
      setReportData(processTimers(fromTS, toTS, data));
      // setAttachements(data);
    },
  });

  //--------------------
  // Reactions
  //---------------------

  useEffect(() => {
    log.info(limaLogTag.TimersV4report, "UEF attKeys: mounted ", fromTS, toTS);
    // if (attKeys !== undefined && attKeys !== null && attKeys.length > 0) {
    // setAttachements([]);
    if (fromTS === undefined) setFromTS(moment().startOf("month"));
    if (toTS === undefined) setToTS(moment());
    void refetch();
    // } else {
    //   log.info(limaLogTag.MessageComponent, "moutedbut missing linkedID ");
    // }
  }, []);

  useEffect(() => {
    void refetch();
  }, [fromTS, toTS]);

  //--------------------
  // Renders
  //--------------------

  const renderItem_DatePart = (item: ITimersPerActaDateSplit): JSX.Element => {
    log.info(
      limaLogTag.TimersV4report,
      "renderItem_DatePart data ",
      item.dateStart.format(limaStore.userDateFormat),
      item
    );
    return (
      <Stack.Item className={classNames.reportLine}>
        <div className={classNames.reportLineLabel}>{`${t(
          "tpmain:timer.report.reportDateLbl"
        )}: ${item.dateStart.format(limaStore.userDateFormat)}`}</div>
        <div className={classNames.reportLineData}>
          <List
            items={item.timersPerActa}
            onRenderCell={(partionItem: ITimePerActa) => <TimerPartion partionItem={partionItem} />}
          />
        </div>
      </Stack.Item>
    );
  };

  if (isLoading === true) {
    //return <>Loading</>;
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.TimersV4report, "loaded error: ", error);
    return <>{t("tpmain.error.errorLoadingDataLbl")} </>;
  }

  return (
    <Stack>
      <Stack.Item>{t("tpmain:timer.report.reportLbl")}</Stack.Item>
      <Stack.Item>
        {/* <>From date: {fromTS.toString()}</> */}
        <LimaSimpleDatePicker
          limaLabel={t("tpmain:timer.report.fromDatePickerLbl")}
          // defaultValue={fromTS.toISOString()}
          value={fromTS.toDate()}
          onSelectDate={(date) => {
            window.console.log("Date change from ", date);
            setFromTS(moment(date));
            //return date;
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaSimpleDatePicker
          limaLabel={t("tpmain:timer.report.toDatePickerLbl")}
          // defaultValue={fromTS.toISOString()}
          value={toTS.toDate()}
          onSelectDate={(date) => {
            window.console.log("Date change to ", date);
            setToTS(moment(date));
            //return date;
          }}
        />
      </Stack.Item>
      {/* <>To date: {toTS.toString()}</> */}
      <Stack.Item>{t("tpmain:timer.report.dataPartLbl")}</Stack.Item>
      <>{reportData === null ? <></> : <List items={reportData} onRenderCell={renderItem_DatePart} />}</>
    </Stack>
  );
};

export default TimersV4report;
//----------------------
//   REACT QUERY
//----------------------

const fetchReport = async (from: moment.Moment, to: moment.Moment): Promise<any[]> => {
  log.info(limaLogTag.TimersV4report, "fetching data fetchAtachements");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting attachements from DB");
  try {
    // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
    const response: HttpResponse<any[]> = await httpGetAuth(
      urlGetAllTimersBetween(from.toISOString(), to.toISOString()),
      loaderID.ac
    );
    log.info(limaLogTag.TimersV4report, "fetching data fetchAtachements response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.TimersV4report, "fetching data fetchAtachements response.parsedBody", response.parsedBody);
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
