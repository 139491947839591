/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
import "office-ui-fabric-react/dist/css/fabric.min.css";
import App from "./App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@uifabric/icons";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { limaLogInit, limaLogTag } from "../limaCommon/limaLog";

import { QueryClient, QueryClientProvider } from "react-query";

//import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import limaStore from "../store/limaStore";
import { log } from "missionlog";
// import limaTimerStore from "../store/limaTimerStore";
import { onLoggedSessionUpdate } from "../limaCommon/limaUser";

initializeIcons();

let isOfficeInitialized = false;

const title = "Limacta plugin";

//Initialize logging component
limaLogInit();

keycloak.onAuthSuccess = async function () {
  log.info(limaLogTag.Index, "keycloak: uthetication log in!");
  //onLogged();
  // limaStore.setLogged(keycloak.authenticated);
  // try {
  //   const profile = await keycloak.loadUserProfile();
  //   log.info(limaLogTag.LimaDialog, `processLoginData - user profile:  ${JSON.stringify(profile)}`);
  //   limaStore.setLoginUser(
  //     //messageFromDialogData.userID,
  //     profile["attributes"]["limactauserid"],
  //     profile.username ? profile.username : "",
  //     profile.email ? profile.email : "",
  //     "",
  //     profile.firstName,
  //     profile.lastName
  //   );
  // } catch {
  //   log.error(limaLogTag.LimaDialog, `processLoginData - user profile: expected login data but dont get`);
  // }
  // //get Timers from DB
  // limaTimerStore.getTodayTimers();
};

keycloak.onAuthRefreshSuccess = function () {
  log.info(limaLogTag.Index, "keycloak: onAuthRefreshSuccess!", keycloak.token);
  onLoggedSessionUpdate();
};

keycloak.onAuthLogout = function () {
  log.info(limaLogTag.Index, "keycloak: onAuthLogout!", keycloak.token);
  //keycloak.clearToken();
};

keycloak.onAuthError = function () {
  log.info(limaLogTag.Index, "keycloak: uthetication log in ERROR!");
};

//Keycloak
// const eventLogger = async (event: unknown, error: unknown) => {
//   log.info(limaLogTag.Index, "eventLogger: onKeycloakEvent", event, error);
//   if (event === "onAuthSuccess") {
//     limaStore.setLogged(keycloak.authenticated);
//     log.info(limaLogTag.Index, "eventLogger: onKeycloakEvent Success", keycloak.authenticated);
//     try {
//       const profile = await keycloak.loadUserProfile();
//       log.info(limaLogTag.LimaDialog, `processLoginData - user profile:  ${JSON.stringify(profile)}`);
//       limaStore.setLoginUser(
//         //messageFromDialogData.userID,
//         profile["attributes"]["limactauserid"],
//         profile.username ? profile.username : "",
//         profile.email ? profile.email : "",
//         "",
//         profile.firstName,
//         profile.lastName
//       );
//       //get Timers from DB
//       limaTimerStore.getTodayTimers();
//     } catch {
//       log.error(limaLogTag.LimaDialog, `processLoginData - user profile: expected login data but dont get`);
//     }
//   }
// };

// const tokenLogger = (tokens: any) => {
//   log.info(limaLogTag.Index, "tokenLogger: onKeycloakTokens");
//   if (
//     tokens !== undefined &&
//     typeof tokens === "object" &&
//     tokens !== null &&
//     tokens.token !== undefined &&
//     tokens.token !== null
//   ) {
//     //eslint-disable-next-line
//     limaStore.setAccessToken(tokens.token);
//   }
// };

keycloak.onTokenExpired = () => {
  //console.log('token expired', keycloak.token);
  log.info(limaLogTag.Index, `onTokenExpired: Token has to be refreshed`);
  // keycloak.updateToken(30).success(() => {
  //     console.log('successfully get a new token', keycloak.token);
  //     ...
  // }).error(() => {...});
  keycloak
    .updateToken(30)
    .then((refreshed) => {
      if (refreshed) {
        // console.debug('KeycloakTimout: Token refreshed' + refreshed);
        log.info(limaLogTag.Index, `KeycloakTimout: Token refreshed [${String(refreshed)}]`);
        if (typeof keycloak.token !== "undefined") {
          log.info(limaLogTag.Index, `KeycloakTimout: Token set to token [${keycloak.token}] `);
          limaStore.setAccessToken(keycloak.token);
          limaStore.setLogged(true);
        } else {
          log.info(limaLogTag.Index, `KeycloakTimout: Token is undefined`);
          limaStore.setAccessToken("");
          limaStore.setLogged(false);
        }
      } else if (
        keycloak.tokenParsed !== undefined &&
        keycloak.tokenParsed.exp !== undefined &&
        keycloak.timeSkew !== undefined
      ) {
        log.info(
          limaLogTag.Index,
          `KeycloakTimout: Token not refreshed, valid for [${Math.round(
            keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
          )}] seconds`
        );
        // console.warn(
        //   'Token not refreshed, valid for ' +
        //   Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
        //     ' seconds'
        // );
      }
    })
    .catch(() => {
      log.error(limaLogTag.Index, `KeycloakTimout: Failed to refresh token`);
    });
};

// setTimeout(() => {
//   keycloak
//     .updateToken(30)
//     .then((refreshed) => {
//       if (refreshed) {
//         // console.debug('KeycloakTimout: Token refreshed' + refreshed);
//         log.info(limaLogTag.Index, `KeycloakTimout: Token refreshed [${String(refreshed)}]`);
//         if (typeof keycloak.token !== "undefined") {
//           log.info(limaLogTag.Index, `KeycloakTimout: Token set to token [${keycloak.token}] `);
//           limaStore.setAccessToken(keycloak.token);
//           limaStore.setLogged(true);
//         } else {
//           log.info(limaLogTag.Index, `KeycloakTimout: Token is undefined`);
//           limaStore.setAccessToken("");
//           limaStore.setLogged(false);
//         }
//       } else if (
//         keycloak.tokenParsed !== undefined &&
//         keycloak.tokenParsed.exp !== undefined &&
//         keycloak.timeSkew !== undefined
//       ) {
//         log.info(
//           limaLogTag.Index,
//           `KeycloakTimout: Token not refreshed, valid for [${Math.round(
//             keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
//           )}] seconds`
//         );
//         // console.warn(
//         //   'Token not refreshed, valid for ' +
//         //   Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
//         //     ' seconds'
//         // );
//       }
//     })
//     .catch(() => {
//       log.error(limaLogTag.Index, `KeycloakTimout: Failed to refresh token`);
//       // console.error('Failed to refresh token');
//     });
// }, 30000);

//------RENDER-----

const render = (Component) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  /*<ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      // initOptions={{
      //   checkLoginIframe: false,
      // }}
    >
      <React.StrictMode>*/
  /*{/* </React.StrictMode>
    </ReactKeycloakProvider> } */

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <AppContainer>
          {/* <React.Suspense fallback="...Some Error on page..."> */}
          <Component title={title} isOfficeInitialized={isOfficeInitialized} />
          {/* </React.Suspense> */}
        </AppContainer>
      </QueryClientProvider>
    </I18nextProvider>,
    document.getElementById("container")
  );
};

Office.onReady(() => {
  // If needed, Office.js is ready to be called

  isOfficeInitialized = true;

  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
