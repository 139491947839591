import { FC } from "react";
import { Controller } from "react-hook-form";
import { IDropdownProps, Dropdown, IDropdownOption, Stack, Label } from "@fluentui/react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";

type LimaControledDropDownFieldType = {
  // values: IDropdownOption[];
  // selectedItem: string;
  limaLabel?: string;
};

export const LimaControledDropDownField: FC<HookFormProps & IDropdownProps & LimaControledDropDownFieldType> = (
  props: HookFormProps & IDropdownProps & LimaControledDropDownFieldType
) => {
  /*console.log("LimaControledDropDownField: ", props.options);*/
  return (
    <Stack horizontal>
      {props.limaLabel && (
        <Stack.Item grow={1}>
          <Label>{props.limaLabel}: </Label>
        </Stack.Item>
      )}
      <Stack.Item grow={11}>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={props.defaultValue || ""}
          // render={({ field: { onChange, onBlur, name: _fieldName, value }, fieldState: { error } }) => (
          render={({ field }) => (
            <Dropdown
              {...props}
              // defaultSelectedKey={props.selectedItem}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              selectedKey={field.value}
              // eslint-disable-next-line react/jsx-no-bind
              // onSelect={(item) => {
              //   console.log("Dropdown change to ", item);
              //   field.onChange(item);
              //   return item;
              // }}
              onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                console.log("Dropdown onchange to ", option);
                field.onChange(option.key);
                return option.key;
              }}
              // onChange={field.onChange}
              // value={field.value}
              // onBlur={field.onBlur}
              // name={field.fieldName}
              options={props.options}
            />
          )}
        />
      </Stack.Item>
    </Stack>
  );
};
