import { userLinkingState } from "../api/apilinks";

interface userAccListDataType {
  userId: string;
  edgeId: string;
  userName: string;
  position: string;
}

export interface userAccListDataTypeLinked extends userAccListDataType {
  docEdgeId: string;
}

export const userAccListDataTypeDef: userAccListDataType = {
  userId: "",
  edgeId: "",
  userName: "",
  position: ""
};

export const userAccListDataTypeLinkedDef: userAccListDataTypeLinked = {
  userId: "",
  edgeId: "",
  docEdgeId: "",
  userName: "",
  position: ""
};

export default userAccListDataType;

export interface userReqLinkingRespItem {
  _key: string;
  _id: string;
  _rev: string;
  userName: string;
  userEmail: string;
}
export interface userReqLinkingRespEdge {
  _key: string;
  _id: string;
  _from: string;
  _to: string;
  _rev: string;
  cIssuedBy: string;
  state: string;
  type: string;
  cTimeStamp: string;
}
export interface userReqLinkingResp {
  item: userReqLinkingRespItem;
  edge: userReqLinkingRespEdge;
}

export const userReqLinkingRespItemDef: userReqLinkingRespItem = {
  _key: "",
  _id: "",
  _rev: "",
  userName: "",
  userEmail: ""
};
export const userReqLinkingRespEdgeDef: userReqLinkingRespEdge = {
  _key: "",
  _id: "",
  _from: "",
  _to: "",
  _rev: "",
  cIssuedBy: "",
  state: userLinkingState.NONE,
  type: "",
  cTimeStamp: ""
};

export const userReqLinkingRespDef: userReqLinkingResp = {
  item: userReqLinkingRespItemDef,
  edge: userReqLinkingRespEdgeDef
};

/*
{
  "item": {
      "_key": "102",
      "_id": "dUser/102",
      "_rev": "_bgJgqO2---",
      "userName": "user peter",
      "userEmail": "102@email.com"
  },
  "edge": {
      "_key": "7426109",
      "_id": "eVisible2User/7426109",
      "_from": "dUser/101",
      "_to": "dUser/102",
      "_rev": "_b6BXWkO---",
      "cIssuedBy": "101",
      "state": "OFFERED",
      "type": "user",
      "cTimeStamp": "2021-02-20T18:27:00"
  }
}


{
  "cIssuedBy": "101",
  "_from": "dUser\/101",
  "_rev": "_b6DX2qm---",
  "state": "OFFERED",
  "_id": "eVisible2User\/7431521",
  "_to": "dUser\/102",
  "type": "user",
  "cTimeStamp": "2021-02-20T18:27:00",
  "_key": "7431521"
}

*/
