/**
 * Component shows single file item in file list in file open menu
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
import { log } from "missionlog";
import {
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  ITheme,
  mergeStyleSets,
  Pivot,
  PivotItem,
  StackItem,
  Stack,
  Icon,
  TooltipHost,
  ITooltipHostStyles,
} from "office-ui-fabric-react";
import { useId } from "@fluentui/react-hooks";
import { FunctionComponent, useState } from "react";

import limaStore from "../../../../../store/limaStore";

import { actaListOK } from "../../../../../api/schema/getActaList";

import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { observer } from "mobx-react-lite";
import FileListVersions from "./FileListVersions";
import FileListCopies from "./FileListCopies";
import { onFileOpenHandlerWrapper } from "../../../../../limaCommon/limaFileOpen";
import FileItemInfo from "./FileItemInfo";
import { XCOPY_TYPE } from "../../../../../types/Task";
import Moment from "react-moment";
import moment = require("moment");
import { useTranslation } from "react-i18next";

const Clear: IIconProps = { iconName: "Clear" };
//const FullHistoryIcon: IIconProps = { iconName: "FullHistory" };
const HideActaFullIcon: IIconProps = { iconName: "ChevronUp" };
const ShowActaFullIcon: IIconProps = { iconName: "ChevronDown" };
const OwnerIcon: IIconProps = { iconName: "Crown" };

const theme: ITheme = getTheme();
const { palette } = theme;

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      //paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  // itemsFileWrapper: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   width: "100%",
  // },
  itemFileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",

    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        // cursor: "pointer",
      },
    },
  },
  itemFileContainerOpenEnable: {
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        // background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemFileContainerDisabled: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    // fontWeight: "bold",
    color: palette.neutralDark,
  },
  itemFileIconsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "20%",
  },
  itemDate: {
    fontWeight: "400",
    fontSize: "10px",
    width: "100%",
  },
  itemStyleMax: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "100%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  historyItemsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  historyItemsSort: {
    width: 30,
  },
  historyItemsFiles: {
    flexGrow: 1,
  },
  shimmerWrapper: {
    padding: 2,
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  },
});

type FileItemOpenListProp = {
  item: actaListOK;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeItem: (item: actaListOK) => void;
  remove?: (actaId: string, edgeId: string) => void;
};

const FileItemOpenList: FunctionComponent<FileItemOpenListProp> = ({
  item,
  remove,
  changeItem,
}: FileItemOpenListProp) => {
  const [showHistoryList, setShowHistoryList] = useState<boolean>(false);
  const tooltipId = useId("toolTip");
  const { t } = useTranslation();

  const onShowHideHistoryHandler = () => {
    if (showHistoryList == true) {
      setShowHistoryList(false);
      return;
    }
    setShowHistoryList(true);
    // getHistoryLinks();
  };

  const FileMainFocusBtn = observer((): JSX.Element => {
    // let content = `This document has ${item.histCount} version/s with last change ${moment(item.lastchange)
    //   .utc()
    //   .format("DD/MM/YYYY HH:mm")}`;

    // if (item.copiesCount > 0) content += ` and have ${item.copiesCount} copies`;

    return (
      <div
        key="fileContainer"
        className={
          limaStore.docxIsLoading
            ? classNames.itemFileContainerDisabled
            : classNames.itemFileContainer + (item.isOwner ? " " + classNames.itemFileContainerOpenEnable : "")
        }
        data-is-focusable={true}
        onClick={() => {
          log.info(limaLogTag.FileItemOpenList, `FileMainFocusBtn: loading`, item);
          if (limaStore.docxIsLoading) {
            log.warn(limaLogTag.FileItemOpenList, `onFileClickHandler: another loading in progress`);
          } else if (!item.isOwner) {
            log.warn(limaLogTag.FileItemOpenList, `onFileClickHandler: you are not owner, nothing loadeing`);
          } else {
            // void onFileClickHandler(item.id, item.name, item.isOwner);
            void onFileOpenHandlerWrapper(item.id, item.name, item.mainXcopy, null, null, XCOPY_TYPE.MAINXCOPY);
          }
        }}
      >
        <div>
          {item.name}
          {item.isOwner && <Icon {...OwnerIcon} />}
        </div>

        {item.lastchange != undefined ? (
          <TooltipHost
            // content={content}
            content={t("tpfile:fileopen.fileitem.itemInfoLbl", {
              histCount: item.histCount,
              lastChangeDate: moment(item.lastchange).utc().format("DD/MM/YYYY HH:mm"),
              copiesCount: item.copiesCount,
            })}
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            id={tooltipId}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <div className={classNames.itemDate}>
              last:<Moment format={limaStore.userDateTimeFormat}>{item.lastchange}</Moment> of ({item.histCount})
            </div>
          </TooltipHost>
        ) : (
          <div className={classNames.itemDate}> -- </div>
        )}
      </div>
    );
  });

  const onCopyFileClickHandler = (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE
  ): void => {
    onFileOpenHandlerWrapper(fileId, fileName, xCopyId, timestamp, xcopyName, xcopyType)
      .then(() => {
        log.info(
          limaLogTag.FileItemOpenList,
          "onCopyFileClickHandler: ",
          fileId,
          fileName,
          xCopyId,
          timestamp,
          xcopyName,
          xcopyType
        );
      })
      .catch((e) => {
        log.info(
          limaLogTag.FileItemOpenList,
          "onCopyFileClickHandler: Error",
          e,
          fileId,
          fileName,
          xCopyId,
          timestamp,
          xcopyName,
          xcopyType
        );
      });
  };

  return (
    <div key={item.id} className={classNames.itemsContainer}>
      {/* <div key="acta" className={classNames.itemsFileWrapper}> */}
      <Stack>
        <StackItem>
          <Stack horizontal>
            <StackItem grow={true}>
              <FileMainFocusBtn />
            </StackItem>
            <StackItem>
              <div key="fileIcons" className={classNames.itemFileIconsContainer}>
                <div className={classNames.itemStyle2}>
                  <IconButton
                    className={classNames.itemCellIcon}
                    title={t("tpfile:fileopen.fileitem.showhistoryBtn")}
                    ariaLabel={t("tpfile:fileopen.fileitem.showhistoryBtn")}
                    iconProps={showHistoryList ? HideActaFullIcon : ShowActaFullIcon}
                    onClick={() => onShowHideHistoryHandler()}
                  />
                </div>
                {item.edgeId != null && (
                  <div className={classNames.itemStyle2}>
                    <IconButton
                      className={classNames.itemCellIcon}
                      iconProps={Clear}
                      title={t("tpfile:fileopen.fileitem.removeBtn")}
                      ariaLabel={t("tpfile:fileopen.fileitem.removeBtn")}
                      onClick={() => remove != undefined && remove(item.id, item.edgeId)}
                    />{" "}
                  </div>
                )}
              </div>
            </StackItem>
          </Stack>
        </StackItem>
        {showHistoryList && (
          <StackItem>
            <Pivot aria-label="Acta">
              <PivotItem headerText={t("tpfile:fileopen.fileitem.pivot.xcopyLbl")}>
                <FileListCopies actaId={item.id} item={item} onFileClickHandler={onCopyFileClickHandler} />
              </PivotItem>
              {item.isOwner && (
                <PivotItem headerText={t("tpfile:fileopen.fileitem.pivot.versionsLbl")}>
                  <FileListVersions item={item} onFileClickHandler={onFileOpenHandlerWrapper} />
                </PivotItem>
              )}
              {item.isOwner && (
                <PivotItem headerText={t("tpfile:fileopen.fileitem.pivot.infoLbl")}>
                  <FileItemInfo item={item} changeItem={changeItem} />
                </PivotItem>
              )}
            </Pivot>
          </StackItem>
        )}
      </Stack>
      {/* </div> */}
    </div>
  );
};
export default FileItemOpenList;
