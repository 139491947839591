import * as React from "react";
import { log } from "missionlog";
import { Stack } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import DragAndDrop from "./DragAndDrop";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useTranslation } from "react-i18next";

log.init({ FileImportPage: "File/FileImportPage" });

const stackTokens = { childrenGap: 10 };

const FileImportPage: FunctionComponent = () => {
  const { t } = useTranslation(["tpmain"]);
  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.fileLbl"), t("tpmain:menu.fileImportLbl")]} />
      <Stack tokens={stackTokens}>
        <DragAndDrop />
      </Stack>
    </>
  );
};

export default FileImportPage;
