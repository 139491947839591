import * as React from "react";
// import { Stack } from "office-ui-fabric-react/lib/Stack";
// import { Label } from "office-ui-fabric-react";
import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import { FunctionComponent } from "react";
import { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";

export interface FileSaveExistInfoProps {
  fileName: string;
  type: OPENEDFILETYPE_ENUM;
}

const classNames = mergeStyleSets({
  wrapper: {
    background: "lightgrey",
  },
});

const FileSaveExistInfo: FunctionComponent<FileSaveExistInfoProps> = ({ fileName, type }: FileSaveExistInfoProps) => {
  const typeText = (): string => {
    if (type == OPENEDFILETYPE_ENUM.NONE) return "NO LIMACTA DOC OPEN";
    else if (type == OPENEDFILETYPE_ENUM.ACTA) return "ACTA";
    else if (type == OPENEDFILETYPE_ENUM.COPY) return "COPY";
    else if (type == OPENEDFILETYPE_ENUM.VERSION) return "VERSION";
    return "???";
  };

  return (
    <>
      <Stack.Item className={classNames.wrapper}>
        {fileName && fileName !== "" && (
          <>
            <Text variant="medium">Document name: {fileName}</Text>
            <br />
            <Text variant="medium">Your last change on server: not implemented yet</Text>
            <br />
          </>
        )}

        <Text variant="medium">Now open type: {typeText()}</Text>
      </Stack.Item>
    </>
  );
};
export default FileSaveExistInfo;
