import { Stack } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { getFileAsyncInternal } from "../../../../limaCommon/limaSaveDoc";
import { LimaSimpleButtonV4 } from "../../../../limaComponents/LimaSimpleButtonV4";
import { ENUM_LogstoreLogType } from "../../../../store/limaLogsStore";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
// import limaUsersStore from "../../../../store/limaUsersStore";
import { LimaDocTypeENUM } from "../../../../types/docxData";
// import { IUser } from "../../../../types/User";
import { xcopyHistory_null } from "../../../../types/XcopyHistory";
import Routes from "../../../Menu/routes";
import OnBehalfSaveV4 from "./components/OnBehalfSaveV4";
import { FileStoreFinishV4TypeENUM } from "./FileStoredFinish";
import limaLogsStore from "../../../../store/limaLogsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IWorkingCopySaveExistV4Props {
  xcopyid?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WorkingCopySaveExistV4: FunctionComponent<IWorkingCopySaveExistV4Props> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [onBehalf, setOnBehalf] = useState<string | null>(null);
  const [storeDisabled, setStoreDisabled] = useState<boolean>(false);
  //TPDP: history.push(`${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.XCOPY}/${FileStoreFinishV4TypeENUM.UPDATE}`);

  const { xcopyid } = props.xcopyid === undefined ? useParams<IWorkingCopySaveExistV4Props>() : props;

  //---------------------
  // Handlers
  //---------------------
  const saveExistingXcopy = async () => {
    setStoreDisabled(true);
    // setIsError(false);
    log.info(limaLogTag.WorkingCopySaveExistV4, "saveExistingXcopy: Saving existing file START");

    const loaderID = limaLoadeStore.add(`Storing xcopy to server`);

    await getFileAsyncInternal(
      limaStore.actaId,
      xcopyid,
      LimaDocTypeENUM.XCOPY,
      xcopyid,
      LimaDocTypeENUM.XCOPY,
      "",
      limaStore.userId,
      xcopyHistory_null(), //version
      () => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        setStoreDisabled(false);
        history.push(`${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.XCOPY}/${FileStoreFinishV4TypeENUM.UPDATE}`);
      },
      () => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
        log.error(limaLogTag.WorkingCopySaveExistV4, "saveExistingXcopy: Error Saving existing file");
        limaLogsStore.add(
          "Save exist xcopy error",
          "Some error on saving to exist xcopy  file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.WorkingCopySaveExistV4
        );
        setStoreDisabled(false);

        history.push(
          `${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.XCOPY}/${FileStoreFinishV4TypeENUM.ERROR_UPDATE}`
        );
      },
      limaStore.xcopyId,
      onBehalf
    );
  };

  //------------------
  // Renders
  //-------------------
  return (
    <Stack>
      <Stack.Item>{t("tpfile:filesave.savexcopy.labelLbl")}</Stack.Item>
      <Stack.Item>
        <OnBehalfSaveV4
          onBehalf={onBehalf}
          label={t("tpfile:filesave.savexcopy.storingonbehalfLbl")}
          setOnBehalf={setOnBehalf}
        />
      </Stack.Item>
      <Stack.Item>
        <div style={{ flexGrow: 1 }}>
          <LimaSimpleButtonV4 width={"100%"} disabled={true}>
            {t("tpfile:filesave.savexcopy.discardBtnLbl")}
          </LimaSimpleButtonV4>
        </div>
        <div style={{ flexGrow: 4 }}>
          <LimaSimpleButtonV4 width={"100%"} onClick={saveExistingXcopy} disabled={storeDisabled}>
            {t("tpfile:filesave.savenewxcopy.saveBtnLbl")}
          </LimaSimpleButtonV4>
        </div>
      </Stack.Item>
    </Stack>
  );
};

export default WorkingCopySaveExistV4;
