import { mergeStyleSets, keyframes, Stack, ActionButton, IIconProps, FontIcon } from "@fluentui/react";
import { log } from "missionlog";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import moment = require("moment");
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd, LoaderListItem } from "../../../store/limaLoaderStore";

type FinishedProps = {
  maxLenght: number;
  shortLenght: number;
};

const ErrorIcon: IIconProps = { iconName: "Error" };
const OKIcon: IIconProps = { iconName: "Completed" };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// interface LoaderComponent2Props {
//   showFinishInfo?: boolean;
//   // showRunnuingTasks?: boolean;
//   // showPrograssBar?: boolean;
// }

const removeAllSpinners: IIconProps = { iconName: "RemoveOccurrence" };

const spSlices = keyframes({
  "0%": {
    borderColor: "rgba(64,0,109,0.75) rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.25)",
  },
  "25%": {
    borderColor: "rgba(64,0,109,0.25) rgba(64,0,109,0.75) rgba(64,0,109,0.25) rgba(64,0,109,0.25)",
  },
  "50%": {
    borderColor: "rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.75) rgba(64,0,109,0.25)",
  },
  "75%": {
    borderColor: "rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.75)",
  },
  "100%": {
    borderColor: "rgba(64,0,109,0.75) rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.25)",
  },
});

const classNames = mergeStyleSets({
  loaderWrapper: {},
  loadingSpinWrap: {
    width: 16,
    height: 16,
    clear: "both",
    cursor: "pointer",
  },
  finishedLoading: {
    minHeight: "16px",
    minWidth: "16px",
  },
  loaderSpinner: {
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "8px",
    borderColor: "rgba(64,0,109,0.75) rgba(64,0,109,0.25) rgba(64,0,109,0.25) rgba(64,0,109,0.25)",
    animationName: spSlices,
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
  loaderDetailList: {
    position: "absolute",
    margin: "20px",
    minHeight: "84px",
    maxWidth: 400,
    minWidth: 300,
    display: "block",
    zIndex: "100",
    backgroundColor: "rgba(250,250,250,0.85)",
    border: "1px solid #ddd",
  },

  loaderDetailItem: {
    cursor: "pointer",
    marginLeft: 5,
  },
  loaderDetailTitleItem: {
    fontWeight: 500,
    marginTop: 5,
  },
  loaderFinish: {
    textAlign: "left",
  },
  loaderFinishItem: {
    marginLeft: 5,
    marginTop: 2,
    marginBottom: 2,
  },
  loaderFinishItemLong: {
    cursor: "pointer",
    marginTop: 5,
  },
});

const LoaderComponent2: FunctionComponent = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showLongDetail, setShowLongDetail] = useState<boolean>(false);
  const [loaderItemsNow, setLoaderItemsNow] = useState<number | null>();
  const [timer1, setTimer1] = useState<NodeJS.Timeout | null>(null);

  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    const disposer1 = reaction(
      () => limaLoadeStore.loadersList,
      (loaderListTexts: LoaderListItem[]) => {
        log.info(limaLogTag.LoaderComponent, `UEF: reaction on change loaderListTexts`, loaderListTexts.length);
        if (loaderListTexts.length > 0) {
          log.info(
            limaLogTag.LoaderComponent,
            `UEF: reaction on change hove some ${loaderListTexts.length} ${String(showLoader)}`
          );
          if (showLoader === false) {
            setShowLoader(true);
            log.info(limaLogTag.LoaderComponent, `UEF: reaction SHOW ${String(showLoader)}`);
          }
          setLoaderItemsNow(moment().valueOf());
          // if (showDetail === false) setShowDetail(true);
        } else {
          log.info(
            limaLogTag.LoaderComponent,
            `UEF: reaction on change dont hove some ${loaderListTexts.length} ${String(showLoader)}`
          );
          if (showLoader === true) {
            setShowLoader(false);
            log.info(limaLogTag.LoaderComponent, `UEF: reaction HIDE`);
          }
          setLoaderItemsNow(null);
          if (timer1 !== null) {
            clearInterval(timer1);
            setTimer1(null);
          }
          if (showDetail === true) {
            setShowDetail(false);
            if (showLongDetail == true) setShowLongDetail(false);
          }
        }
      }
    );

    return () => {
      disposer1();
      if (timer1 !== null) {
        clearInterval(timer1);
        setTimer1(null);
      }
    };
  }, []);

  //---------------------
  //    HANDLERS
  //---------------------

  const showHideDetail = () => {
    log.info(limaLogTag.LoaderComponent, `showHideDetail: `, limaLoadeStore);
    if (showDetail === false) {
      log.info(limaLogTag.LoaderComponent, `showHideDetail: show`);

      if (loaderItemsNow === null) setLoaderItemsNow(moment().valueOf());
      if (timer1 === null) {
        const timer = setInterval(() => setLoaderItemsNow(moment().valueOf()), 1000);
        setTimer1(timer);
      }
      setShowDetail(true);
    } else {
      log.info(limaLogTag.LoaderComponent, `showHideDetail: hide`);
      if (loaderItemsNow !== null) setLoaderItemsNow(null);
      if (timer1 !== null) {
        clearInterval(timer1);
        setTimer1(null);
      }
      setShowDetail(false);
      if (showLongDetail == true) setShowLongDetail(false);
    }
  };

  const changeShowLongDetail = () => {
    log.info(limaLogTag.LoaderComponent, `showLongDetail: `);
    if (showLongDetail === false) {
      log.info(limaLogTag.LoaderComponent, `showHideDetail: show`);

      setShowLongDetail(true);
    } else {
      log.info(limaLogTag.LoaderComponent, `showHideDetail: hide`);
      setShowLongDetail(false);
    }
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  const LoaderSpinner = observer((): JSX.Element => {
    if (limaLoadeStore.loadersList.length > 0) {
      return <div title="show loading details" className={classNames.loaderSpinner} onClick={() => showHideDetail()} />;
    } else {
      return <></>;
    }
  });

  const LoaderFinish = observer(({ maxLenght, shortLenght }: FinishedProps): JSX.Element => {
    log.info(
      limaLogTag.LoaderComponent,
      "LoaderFinish: Loader info",
      limaLoadeStore.finishedForeverList,
      limaLoadeStore.getReversedForeverList,
      maxLenght,
      shortLenght
    );
    return (
      <>
        <div className={classNames.loaderFinish}>
          {limaLoadeStore.finishedForeverList.length !== 0 &&
            limaLoadeStore.getReversedForeverList
              .slice(0, shortLenght)
              .map((item: LoaderListItem) => LoaderFinieshedItem(item))}
        </div>
        {maxLenght > shortLenght && (
          <div
            className={classNames.loaderFinishItemLong}
            key="finish_extend"
            title="Show all"
            onClick={changeShowLongDetail}
          >
            ...
          </div>
        )}
      </>
    );
  });

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  const LoaderPartialItem = (item: LoaderListItem, now: number): JSX.Element => {
    const now2 = moment(now);
    //const diff = moment().diff(moment(item.f), "seconds");
    const diff = now2.diff(moment(item.f), "seconds");
    log.info(limaLogTag.LoaderComponent, `LoaderPartialItem: now ${now} now2 ${item.f} diff ${diff}`);
    const trimmedString = item.t.length > 20 ? item.t.substring(0, 20 - 3) + "..." : item.t;

    return (
      <Stack.Item className={classNames.loaderDetailItem}>
        <div title={item.t}>{String(diff) + "s > " + trimmedString}</div>
      </Stack.Item>
    );
  };

  const LoaderFinieshedItem = (item: LoaderListItem): JSX.Element => {
    //const now2 = moment(now);
    const startTime = moment(item.f).format("h:mma");
    // const diff = now2.diff(moment(item.f), "seconds");
    //log.info(limaLogTag.LoaderComponent, `LoaderFinieshedItem: now ${now} now2 ${item.f} diff ${diff}`);
    const fullText = ` ${startTime}[${String((item.f2 / 1000).toFixed(2))} sec] ${item.t}`;
    const trimmedString = item.t.length > 20 ? item.t.substring(0, 20 - 3) + "..." : item.t;

    return (
      <Stack.Item className={classNames.loaderDetailItem}>
        <div title={fullText}>
          <span>{IconFinishLoader(item.end)}</span>
          <span>{` ${trimmedString}`}</span>
        </div>
      </Stack.Item>
    );
  };

  const IconFinishLoader = (state: LoaderItemEnd): JSX.Element => {
    if (state == LoaderItemEnd.OK) return <FontIcon {...OKIcon} />;
    else if (state == LoaderItemEnd.FAILED) return <FontIcon {...ErrorIcon} />;
    return <></>;
  };

  return (
    <Stack>
      <Stack.Item className={classNames.loadingSpinWrap} onClick={() => showHideDetail()}>
        <LoaderSpinner />
      </Stack.Item>

      {showDetail && (
        <div className={classNames.loaderDetailList}>
          <Stack>
            <ActionButton
              iconProps={removeAllSpinners}
              onClick={() => {
                limaLoadeStore.cleanup();
                showHideDetail();
              }}
            >
              Clear
            </ActionButton>
          </Stack>
          <Stack>
            {loaderItemsNow === null
              ? ">>>null"
              : limaLoadeStore.loadersList.map((item: LoaderListItem) => LoaderPartialItem(item, loaderItemsNow))}
          </Stack>

          <Stack>
            <Stack.Item className={classNames.loaderDetailTitleItem}>
              <div>Finished</div>
            </Stack.Item>
            {limaLoadeStore.finishedList === null ? (
              ">>>null"
            ) : (
              <LoaderFinish maxLenght={30} shortLenght={showLongDetail ? 30 : 10} />
            )}
          </Stack>
        </div>
      )}
    </Stack>
  );
};

export default LoaderComponent2;
