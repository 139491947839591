import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import CZlocalesTPmain from "./locales/cs-CZ/tpmain.json";
import ENlocalesTPmain from "./locales/en/tpmain.json";

import CZlocalesTPnotes from "./locales/cs-CZ/tpnotes.json";
import ENlocalesTPnotes from "./locales/en/tpnotes.json";

import CZlocalesTPtasks from "./locales/cs-CZ/tptasks.json";
import ENlocalesTPtasks from "./locales/en/tptasks.json";

import CZlocalesTPfile from "./locales/cs-CZ/tpfile.json";
import ENlocalesTPfile from "./locales/en/tpfile.json";

import CZlocalesTPuser from "./locales/cs-CZ/tpuser.json";
import ENlocalesTPuser from "./locales/en/tpuser.json";

import CZlocalesTPshow from "./locales/cs-CZ/tpshow.json";
import ENlocalesTPshow from "./locales/en/tpshow.json";

import CZlocalesTPadvice from "./locales/cs-CZ/tpadvice.json";
import ENlocalesTPadvice from "./locales/en/tpadvice.json";

import CZlocalesTPsearch from "./locales/cs-CZ/tpsearch.json";
import ENlocalesTPsearch from "./locales/en/tpsearch.json";

import CZlocalesTPerrormsg from "./locales/cs-CZ/tperrormsg.json";
import ENlocalesTPerrormsg from "./locales/en/tperrormsg.json";

const resources = {
  en: {
    tpmain: ENlocalesTPmain,
    tpnotes: ENlocalesTPnotes,
    tptasks: ENlocalesTPtasks,
    tpfile: ENlocalesTPfile,
    tpuser: ENlocalesTPuser,
    tpshow: ENlocalesTPshow,
    tpadvice: ENlocalesTPadvice,
    tpsearch: ENlocalesTPsearch,
    tperrormsg: ENlocalesTPerrormsg,
  },

  "cs-CZ": {
    tpmain: CZlocalesTPmain,
    tpnotes: CZlocalesTPnotes,
    tptasks: CZlocalesTPtasks,
    tpfile: CZlocalesTPfile,
    tpuser: CZlocalesTPuser,
    tpshow: CZlocalesTPshow,
    tpadvice: CZlocalesTPadvice,
    tpsearch: CZlocalesTPsearch,
    tperrormsg: CZlocalesTPerrormsg,
  },
};

//TODO: add lazy load https://react.i18next.com/legacy-v9/step-by-step-guide

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources,
    lng: "en",
    fallbackLng: "en",
    debug: true,
    // have a common namespace used around the full app
    ns: ["tpmain"],
    defaultNS: "tpmain",
    //keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },
    react: {
      useSuspense: true,
    },
  });
export default i18n;
