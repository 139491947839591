import * as React from "react";
import { FunctionComponent, useState } from "react";
import { ILimaCompany, tCompnayForm } from "../../../../types/LimaCompany";
import { Stack, mergeStyleSets } from "@fluentui/react";
import { LimaControledTextFieldV4, LimaTextFiledTitlePlace } from "../../../../limaComponents/LimaControledTextFieldV4";
import { useTranslation } from "react-i18next";
import { useDebounceFn } from "../../../../limaCommon/customHooks/useDebounce";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaOf } from "yup";
import * as yup from "yup";
import { LimaHideableSection2 } from "../../../../limaComponents/LimaHideableSection2";
// import AccessComponentV4 from "../../Components/Access/AccessComponentV4";
// import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";
import { ICompanyLicenceItem, LimaEnum_licenceType } from "../../../../types/LimaLicences";
import CompanyLicenceItem from "./CompanyLicenceItem";

interface ICompanyItemProps {
  company: ILimaCompany;
  onStore: (companyName: string) => void;
  onUpdate: (company: ILimaCompany) => void;
}

const schemaCompany: SchemaOf<tCompnayForm> = yup.object().shape({
  companyname: yup.string().min(3, "minimal lenght").required("This is required field"),
  invitemessage: yup.string(),
  licencecount: yup.number(),
  companylicenceid: yup.string(),
  _key: yup.string(),
});

const classNames = mergeStyleSets({
  companyItemWrapper: {
    marginTop: 10,
  },
});

const CompanyItem: FunctionComponent<ICompanyItemProps> = (props) => {
  const { t } = useTranslation();
  const [companyItem, setCompanyItem] = useState<ILimaCompany>(props.company);
  //--------------
  // Forms
  //--------------

  const methods = useForm<tCompnayForm>({
    defaultValues: {
      companyname: props.company !== undefined ? props.company.companyname : "",
    },
    resolver: yupResolver(schemaCompany),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  // const { setValue, setError, watch } = methods;
  const {
    watch,
    control,
    formState: { isValid },
  } = methods;

  const valueOfTest = watch();

  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    DEBAUNCERS
  //---------------------

  useDebounceFn(
    valueOfTest.companyname, //getValues("taskName"),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    () => {
      // window.console.log(`trigger to taskName`, valueOfTest.taskName);
      log.debug(
        limaLogTag.CompaniesItem,
        `useDebounceFn companyName: trigger to companyName: `,
        valueOfTest.companyname
      );
      setCompanyItem({
        ...companyItem,
        companyname: String(valueOfTest.companyname),
      });
    },
    500
  );

  //---------------------
  //    HANDLERS
  //---------------------

  const onAddCompany = () => {
    if (props.onStore !== undefined) {
      props.onStore(companyItem.companyname);
    }
  };
  const onUpdateCompany = () => {
    if (props.onUpdate !== undefined) {
      window.console.log(">>>> update companye", companyItem);
      props.onUpdate(companyItem);
    }
  };

  const onAddCompanyLicence = () => {
    const newCompItem: ICompanyLicenceItem = {
      concurent: false,
      licencecount: 0,
      licenceid: "",
      licenceType: LimaEnum_licenceType.NONE,
    };
    setCompanyItem({ ...companyItem, complicences: [...companyItem.complicences, newCompItem] });
  };

  const onRemCompanyLicence = (itemIndex: number) => {
    const newArrayAfterRemove = companyItem.complicences.filter((_e, i) => i !== itemIndex);
    setCompanyItem({ ...companyItem, complicences: newArrayAfterRemove });
  };

  const onChangeCompanyLicence = (itemIndex: number, companyLicenceItem: ICompanyLicenceItem) => {
    window.console.log(">>>> update companye", itemIndex, companyLicenceItem);
    window.console.log(">>>> update companye", companyItem.complicences);

    const newComArrayAfterChange = companyItem.complicences.map((item, i) =>
      i !== itemIndex ? item : companyLicenceItem
    );
    setCompanyItem({ ...companyItem, complicences: newComArrayAfterChange });
    window.console.log(">>>> update companye", companyItem.complicences);
  };
  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack className={classNames.companyItemWrapper}>
      <Stack.Item>
        <Stack horizontal style={{ alignItems: "center" }}>
          <Stack.Item>
            <Stack>
              <Stack.Item>
                <LimaControledTextFieldV4
                  control={control}
                  labelPosition={LimaTextFiledTitlePlace.NONE}
                  name="companyname"
                  limaLabel={t("tpuser:tools.companies.company.nameLbl")}
                />
              </Stack.Item>
              {/* <Stack.Item>{props.company._key}</Stack.Item> */}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            {companyItem._key === undefined ? (
              <LimaActionButtonV4 onClick={onAddCompany} toolTipShow={false} disabled={!isValid}>
                Add
              </LimaActionButtonV4>
            ) : (
              <LimaActionButtonV4 onClick={onUpdateCompany} toolTipShow={false} disabled={!isValid}>
                Update
              </LimaActionButtonV4>
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <LimaHideableSection2
          name="Licence"
          addEnabled={false}
          isDisabled={companyItem._key === undefined}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_superadmin}
          dontShowOnNoLic={true}
        >
          <>
            {companyItem._key !== undefined &&
              companyItem.complicences !== undefined &&
              companyItem.complicences.map((compLic: ICompanyLicenceItem, index) => (
                <CompanyLicenceItem
                  key={`complic${index}`}
                  licenceItem={compLic}
                  index={index}
                  onRemove={onRemCompanyLicence}
                  onChange={onChangeCompanyLicence}
                />
              ))}
            <LimaActionButtonV4 onClick={onAddCompanyLicence} toolTipShow={false}>
              Add licence
            </LimaActionButtonV4>
          </>
        </LimaHideableSection2>
      </Stack.Item>
      {/* <Stack.Item>
        <LimaHideableSection2
          name={t("tptasks:taskedit.taskitem.accessPivotLbl")}
          addEnabled={false}
          isDisabled={companyItem._key === undefined}
        >
          {companyItem._key !== undefined && (
            <AccessComponentV4 itemId={companyItem._key} type={LIMA_ITEM_TYPE.COMPANY} showLinkedItem={true} />
          )}
        </LimaHideableSection2>
      </Stack.Item> */}
    </Stack>
  );
};
export default CompanyItem;

//----------------------
//   REACT QUERY
//----------------------
