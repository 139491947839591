import * as React from "react";
import { PrimaryButton, Label, Stack, Text } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import { keyPressHandler } from "../../../../../limaCommon/keyboardHandler";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { saveNewActaToLimactaXcopy } from "../../../../../limaCommon/limaSaveDoc";
// import { urlNewActa } from "../../../../api/apilinks";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../../store/limaStore";
import { ENUM_LogstoreLogType } from "../../../../../store/limaLogsStore";
import { LimaControledTextField } from "../../../../../limaComponents/LimaControledTextField";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { nameof } from "../../../../../limaCommon/utils";
import { httpGetAuth } from "../../../../../api/httpAPI";
import { checkActanameExists } from "../../../../../api/apilinks";
import { useMutation } from "react-query";
import { useDebounceFn } from "../../../../../limaCommon/customHooks/useDebounce";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";
import limaLogsStore from "../../../../../store/limaLogsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FileSaveAsNewXcopyProps {}

type Form = {
  filename: string;
  onBehalf: string | null;
};
const schema: SchemaOf<Form> = yup.object().shape({
  filename: yup.string().required("This is required field"),
  onBehalf: yup.string().nullable(),
});

const FileSaveAsNewXcopy: FunctionComponent<FileSaveAsNewXcopyProps> = () => {
  const [newFileName, setNewFileName] = useState<string>("");
  const [fileSaved, setFileSaved] = useState<boolean>(false);
  const { mutate: mutateCheckName, data: nameExists } = useGetActanameExistQuery();
  //const [onBehalf, _setOnBehalf] = useState<string | null>(null);

  const { t } = useTranslation();
  // const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const { handleSubmit, control, formState, watch, setValue, setError } = useForm<Form>({
    defaultValues: {
      filename: "",
      onBehalf: null,
    },
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    mode: "all",
  });

  const valueOfTest = watch();

  useDebounceFn(
    valueOfTest.filename,
    () => {
      mutateCheckName(valueOfTest.filename);
    },
    500
  );

  const onSaveAsNew = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit(
      (data) => {
        console.log(data);
        log.info(limaLogTag.FileSaveNew, "onSaveAsNew: data", data);
        void saveNewFile(data);
      },
      (err) => {
        console.log(err);
        log.error(limaLogTag.FileSaveNew, "onSaveAsNew: data", err);
      }
    )();
  };

  const saveNewFile = async (data: Form) => {
    const loaderID = limaLoadeStore.add(t("tpfile:filesave.saveasnew.loaderInfo"));

    log.info(limaLogTag.FileSaveNew, "Saving file as new file");
    await saveNewActaToLimactaXcopy(
      data.filename,
      limaStore.userId,
      () => {
        limaLoadeStore.remove(loaderID.k);
        setValue("filename", "");
        setFileSaved(true);
        // setError("filename", {
        //   type: "manual",
        //   message: "Acta saved..",
        // });
      },
      () => {
        limaLoadeStore.remove(loaderID.k);
        limaLogsStore.add(
          "Save new file as new xcopoy error",
          "Some error on saving new file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.FileSaveNew
        );
        setFileSaved(false);
        setError("filename", {
          type: "manual",
          message: t("tpfile:filesave.saveasnew.saveErrorLbl"),
        });
      },
      data.onBehalf
    );
    // }
  };

  return (
    <Stack>
      <Stack.Item>
        <Text variant="medium">{t("tpfile:filesave.saveasnew.savenewLbl")}</Text>
      </Stack.Item>

      <Stack.Item>
        <Label>{t("tpfile:filesave.saveasnew.documentnameLbl")}</Label>
        <LimaControledTextField
          id="filename"
          required={true}
          control={control}
          name={nameof<Form>("filename")}
          value={newFileName}
          autoFocus={true}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setNewFileName(ev.target.value);
            fileSaved && setFileSaved(false);
          }}
          onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => keyPressHandler(ev, "Enter", () => onSaveAsNew())}
        />
      </Stack.Item>
      {nameExists && (
        <Stack.Item>
          <LimaNotice>{t("tpfile:filesave.saveasnew.fileexistsLbl")}</LimaNotice>
        </Stack.Item>
      )}
      {fileSaved && (
        <Stack.Item>
          <Text variant="medium"> {t("tpfile:filesave.saveasnew.savedsuccesLbl")} </Text>
        </Stack.Item>
      )}

      <Stack.Item>
        <PrimaryButton
          text={
            nameExists ? t("tpfile:filesave.saveasnew.uploadEvenExistBtn") : t("tpfile:filesave.saveasnew.uploadBtn")
          }
          onClick={onSaveAsNew}
          disabled={!formState.isValid}
        />
      </Stack.Item>
      {!limaStore.IamDocumentOwner && limaStore.openedDocType !== OPENEDFILETYPE_ENUM.NONE && (
        <Stack.Item>
          <Text variant="small">{t("tpfile:filesave.saveasnew.notOwnerNotificationLbl")}</Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FileSaveAsNewXcopy;

//----------------------
//   REACT QUERY
//----------------------

const useGetActanameExistQuery = () => {
  return useMutation((actaname: string) => getActaNameExists(actaname), {
    onMutate: (actaname: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.FileSaveNew, "call mutate useGetActanameexistQuery", actaname);
      //return { state: 0, exists: false };
      return true;
    },
    onSuccess: (data) => {
      log.info(limaLogTag.FileSaveNew, "call mutate useGetActanameexistQuery", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

//--------------QUERIES -------------

const getActaNameExists = async (actaname: string): Promise<boolean> => {
  log.info(limaLogTag.FileSaveNew, "fetching Check actaname", actaname);
  // const [, { patientID }] = params.queryKey;

  if (actaname === undefined || actaname === null || actaname === "") {
    return false;
  }
  const loaderID = limaLoadeStore.add(`Check actaname`);
  try {
    const response = await httpGetAuth<boolean>(checkActanameExists(actaname), loaderID.ac);
    log.info(limaLogTag.FileSaveNew, "fetching data checkActanameExists response", response);
    if (!response.ok) {
      throw response;
    }
    return response.parsedBody;
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
