//TODO
import * as React from "react";
import { FunctionComponent, useState } from "react";
import limaObjTagsStore from "../../../store/limaObjTagsStore";
import LimaTagMiniCmp from "./LimaTagMiniCmp";
import { Stack } from "@fluentui/react";
import { Tags } from "../../../types/Tags";
import { getColorBaseOnBg } from "../../../limaCommon/limaColor";
import { TagItem } from "office-ui-fabric-react";

interface ILimaTagsMiniListCmpProps {
  tagsIds: string[];
}

const LimaTagsMiniListCmp: FunctionComponent<ILimaTagsMiniListCmpProps> = (props: ILimaTagsMiniListCmpProps) => {
  const [tagToShow, setTagToShow] = useState<Tags | undefined>(undefined);
  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------

  const onChangeDetailTag = (tag: Tags | undefined) => {
    setTagToShow(tag);
  };
  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack style={{ height: "100%" }}>
      <Stack.Item style={{ height: 18 }}>
        {tagToShow !== undefined && (
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 3,
              backgroundColor: tagToShow.tagcolor,
              color: getColorBaseOnBg(tagToShow.tagcolor),
            }}
          >
            {tagToShow.tagname}
          </div>
        )}
      </Stack.Item>
      <Stack.Item style={{ marginRight: 5, marginLeft: 5 }}>
        <Stack horizontal horizontalAlign="end" style={{ alignItems: "flex-end", height: 13 }}>
          {props.tagsIds &&
            props.tagsIds.map((item) => {
              const tagItem = limaObjTagsStore.getTagByKey(item);
              if (TagItem !== null) {
                return <LimaTagMiniCmp tagItem={tagItem} setTagClb={onChangeDetailTag} />;
              }
              return <></>;
            })}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
export default LimaTagsMiniListCmp;

//----------------------
//   REACT QUERY
//----------------------
