import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { ILimaCompany_withUserAndLicence } from "../../types/LimaCompany";
import { HttpResponse, httpGetAuth } from "../httpAPI";
import { urlGetCompanyList } from "../apilinks";

export const fetchCompanies = async (): Promise<ILimaCompany_withUserAndLicence[]> => {
  log.info(limaLogTag.limaCompaniesCalls, "fetching data fetchCompanies");
  // const loaderID = limaLoadeStore.add("Getting messages from DB");
  let response: HttpResponse<ILimaCompany_withUserAndLicence[]>;
  const loaderID = limaLoadeStore.add(`Getting Companies from server`);
  try {
    response = await httpGetAuth<ILimaCompany_withUserAndLicence[]>(urlGetCompanyList(), loaderID.ac);

    if (!response.ok) {
      throw response;
    }
    log.info(limaLogTag.limaCompaniesCalls, "fetchCompanies: I get Data from call:", response);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return response.parsedBody;
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    log.error(limaLogTag.limaCompaniesCalls, "Error call get User", error);
    throw new Error(error);
  }
};
