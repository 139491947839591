import { Stack } from "@fluentui/react";
import { log } from "missionlog";
import { List } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAllCopy4Acta } from "../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../api/httpAPI";
import { actaListOK } from "../../../../api/schema/getActaList";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { createActaXCopy } from "../../../../limaCommon/limaXCopyFile";
import { classNamesCommon } from "../../../../limaComponents/LimaComponentsCommonStyles";
import { LimaHideableSection2 } from "../../../../limaComponents/LimaHideableSection2";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
import { TaskData, XCOPY_TYPE } from "../../../../types/Task";
import { Xcopy2Acta } from "../../../../types/Xcopy";
import WorkingCopyListItemV4 from "./WorkingCopyListItemV4";
import { WorkingCopiesAddNewV4 } from "./WorkingCopiesAddNewV4";
import { useHistory } from "react-router";
import Routes from "../../../Menu/routes";
import { LimaDocTypeENUM } from "../../../../types/docxData";
import { FileStoreFinishV4TypeENUM } from "../Save/FileStoredFinish";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../limaComponents/LimaNotice";

interface FileListCopiesV4Props {
  actaId: string;
  item: actaListOK;
}

const FileListCopiesV4: FunctionComponent<FileListCopiesV4Props> = ({ actaId, item }: FileListCopiesV4Props) => {
  // const { status, error, data } = useQuery<Xcopy2Acta[], Error>(
  //   ["userListCopies", { actaid: actaId, userid: limaStore.userId }],
  //   getUserActaCopies
  // );
  const { t } = useTranslation();
  const history = useHistory();
  const [showDetail, setShowDetail] = useState<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storeEditEnabled, setStoreEditEnabled] = useState<boolean>(true);
  const [newXcopyLinkingData, setNewXcopyLinkingData] = useState<TaskData>({
    xcopytype: XCOPY_TYPE.FULLXCOPY,
    commentIds: [],
    paraIds: [],
    actaKey: limaStore.actaId,
    attachements: [],
    xcopyKey: "",
  });
  const enableEditStore = useMemo(() => storeEditEnabled, [storeEditEnabled]);
  const { status, error, data, refetch } = useQuery(
    ["userListCopies", { actaid: actaId, userid: limaStore.userId }],
    // () => getUserActaCopies(actaId, limaStore.userId),
    () => getUserActaCopies(actaId),
    {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.UserStatisticActivity, "useQuery get getUserActivitytStat ", data);
        //setPermissionsList(data);
      },
    }
  );

  useEffect(() => {
    void refetch();
  }, [actaId]);

  if (status === "loading") {
    log.info(limaLogTag.FileListCopies, "copies loading: ", data);
    return LimaLoadingComponnet(3, t("tpfile:filecopies.loadingLbl"));

    // return (
    //   <>
    //     <div>... getting Copies ...</div>
    //     <div>
    //       <Shimmer width="80%" />
    //       <Shimmer width="80%" />
    //       <Shimmer width="80%" />
    //     </div>
    //   </>
    // );
  }
  if (status === "error") {
    log.error(limaLogTag.FileListCopies, "copies is loading: ", error);
    return <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>{t("tpmain:error.errorLoadingDataLbl")}</LimaNotice>;
    //return <div>{error}</div>;
  }
  if (status === "success") {
    log.info(limaLogTag.FileListCopies, "copies loaded: ", data);
  }
  if (data === undefined || data === null) {
    log.info(limaLogTag.FileListCopies, "no data: ");
    return <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>{t("tpmain:error.noDataLbl")}</LimaNotice>;
  }

  //------------------
  // Handlers
  //------------------
  const onAddHandler = () => {
    setShowDetail(true);
  };

  const onStore = () => {
    log.info(limaLogTag.FileListCopies, "onStore: onDetialSaveUpdateCallBack ");

    if (newXcopyLinkingData === null || newXcopyLinkingData === undefined) {
      log.error(limaLogTag.FileListCopies, "onStore: null data ", newXcopyLinkingData);
      //return false;
    } else {
      void createActaXCopy(
        limaStore.actaId,
        newXcopyLinkingData.paraIds,
        newXcopyLinkingData.commentIds,
        newXcopyLinkingData.xcopytype
      ).then((xcopyId: string) => {
        log.info(limaLogTag.FileListCopies, "onStore: new xcopyID ", xcopyId);
        // setCopyId(xcopyId);
        history.push(`${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.XCOPY}/${FileStoreFinishV4TypeENUM.CREATE}`);
      });
    }
  };

  const onUpdateAddNewXcopyData = (callBacklinkingTaskData: TaskData) => {
    window.console.log("CCCC", callBacklinkingTaskData);
    log.info(limaLogTag.FileListCopies, "onUpdateAddNewXcopyData: new data ", callBacklinkingTaskData);
    setNewXcopyLinkingData(callBacklinkingTaskData);

    if (callBacklinkingTaskData.xcopytype === XCOPY_TYPE.FULLXCOPY && storeEditEnabled === false) {
      setStoreEditEnabled(true);
    } else if (callBacklinkingTaskData.xcopytype === XCOPY_TYPE.TRIMXCOPY) {
      if (
        callBacklinkingTaskData.commentIds.length + callBacklinkingTaskData.paraIds.length <= 0 &&
        storeEditEnabled === true
      ) {
        setStoreEditEnabled(false);
      } else if (
        storeEditEnabled === false &&
        callBacklinkingTaskData.commentIds.length + callBacklinkingTaskData.paraIds.length > 0
      ) {
        setStoreEditEnabled(true);
      }
    }
    //if (storeEditEnabled === false) setStoreEditEnabled(true);
  };

  //------------------
  // Renders
  //------------------
  const onRenderCell = (singleCopyItem: Xcopy2Acta): JSX.Element => {
    // if (item !== null) {
    return (
      <Stack className={classNamesCommon.LimaFileLists_listItemWraper} data-is-focusable={true}>
        <WorkingCopyListItemV4
          key={singleCopyItem._key}
          xCopy2ActaItem={singleCopyItem}
          isActaOwner={item.isOwner}
          // openFileHandler={onCopyFileClickHandler}
        />
      </Stack>
    );
  };

  const ShowCopiesItems = (): JSX.Element => {
    return (
      <>
        <List items={data} onRenderCell={onRenderCell} />
      </>
    );
  };

  return (
    <LimaHideableSection2
      name={t("tpfile:filecopies.sectionLbl")}
      headerCount={data && String(data.length)}
      addCallback={onAddHandler}
      // setVisible={showAdd}
      // filters={taskFilters}
      addEnabled={true}
      editPanelName={t("tpfile:filecopies.addNew.panelLabel")}
      passShowHideToContent={false}
      onDetialSaveUpdateCallBack={() => {
        log.info(limaLogTag.FileListCopies, "onDetialSaveUpdateCallBack: ", error);
        onStore();
      }}
      editDetailSaveLbl={t("tpfile:filecopies.addNew.saveBtnLbl")}
      editDetailCancelLbl={t("tpfile:filecopies.addNew.cancelBtnLbl")}
      editDetail={
        <WorkingCopiesAddNewV4 linkedData={newXcopyLinkingData} setParamsCallBack={onUpdateAddNewXcopyData} />
      }
      editDetailEnableStore={enableEditStore}
      editDetailOpen={showDetail}
      onDetailBackCallBack={() => {
        log.info(limaLogTag.FileListCopies, "onDetailBackCallback: onDetailBackCallBack ");
        setShowDetail(false);
      }}
    >
      <ShowCopiesItems />
    </LimaHideableSection2>
  );
};

export default FileListCopiesV4;

//----------------------
//   REACT QUERY
//----------------------

// const getUserActaCopies = async (actaid: string, userid: string): Promise<Xcopy2Acta[]> => {
const getUserActaCopies = async (actaid: string): Promise<Xcopy2Acta[]> => {
  log.info(limaLogTag.FileListCopies, "fetching data getUserActaCopies");

  const loaderID = limaLoadeStore.add("Getting acta copies from DB");
  try {
    const response: HttpResponse<Xcopy2Acta[]> = await httpGetAuth<Xcopy2Acta[]>(
      // getAllCopy4Acta(actaid, userid),
      getAllCopy4Acta(actaid),
      loaderID.ac
    );
    log.info(limaLogTag.FileListCopies, "fetching data getUserActaCopies response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.FileListCopies, "getUserActaCopies: asketo to get something", response.parsedBody);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
  }
};
