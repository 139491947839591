import { log } from "missionlog";
import { ActionButton, IIconProps, Stack, StackItem } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { urlActaInsertDocVersion } from "../../../../../api/apilinks";
import { compareXcopy2XcopyByActaKey, InsertDocument_xcopy } from "../../../../../limaCommon/limaInsertDoc";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { getActaXcopyFile, storeToXcopy } from "../../../../../limaCommon/limaXCopyFile";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../../store/limaStore";
import { TaskData, XCOPY_TYPE } from "../../../../../types/Task";
import { useTranslation } from "react-i18next";
import { getXMLPartsInNamespace } from "../../../../../limaCommon/limaWordInteract";
import limaActasStore from "../../../../../store/limaActasStore";
import { observer } from "mobx-react-lite";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";
import { isTaskDataLinkedDocOpen } from "../../../../../limaCommon/limaTaskHelpers";

interface TaskItemGetStoreDocProps {
  hasActa: boolean;
  hasCopy: boolean;
  // enableStoreXcopy: boolean;
  taskData: TaskData;
  isActaOwner: boolean;
}

const openCopyIcon: IIconProps = { iconName: "OpenFile" };
const saveCopyIcon: IIconProps = { iconName: "Save" };
const compareCopyIcon: IIconProps = { iconName: "BranchCompare" };

const TaskItemGetStoreDoc: FunctionComponent<TaskItemGetStoreDocProps> = ({
  hasActa,
  hasCopy,
  // enableStoreXcopy,
  taskData,
  isActaOwner,
}: TaskItemGetStoreDocProps) => {
  // const [linkedXcopyOpenned, setLinkedXcopyOpenned] = useState<boolean>(false);
  const { t } = useTranslation();

  //TEST CHANGES to ENable SaveChanges

  const onStoreXcopyBtn = (): void => {
    log.info(limaLogTag.TaskItemGetStoreDoc, "onStoreXcopyBtn: storing data to xcopy", taskData);
    //TODO: Update

    void storeToXcopy(taskData.actaKey, taskData.xcopyKey).then(() => {
      limaStore.xcopyId = taskData.xcopyKey;
    });
  };

  const onGetXcopyBtn = (): void => {
    // log.info(limaLogTag.TaskItemGetStoreDoc, "onGetXcopyBtn: geting text from xcopy", limaTasksStore.editTask);

    log.info(limaLogTag.TaskItemGetStoreDoc, "getting xcopy for xcopy", taskData);
    void getActaXcopyFile(taskData.actaKey, taskData.xcopyKey);
  };

  const onGetTaskDoc = async () => {
    log.info(limaLogTag.TaskItemGetStoreDoc, "onGetTaskDoc: getting acta for acta", taskData.actaKey);
    const loaderID = limaLoadeStore.add(`Call get Acta from server`);
    limaStore.setDocxIsLoading(true);

    await InsertDocument_xcopy(
      taskData.xcopyKey,
      urlActaInsertDocVersion(taskData.actaKey, null, limaStore.userId, null, true),
      loaderID
    )
      .then(() => {
        limaStore.setActa(taskData.actaKey);
        const actaItem = limaActasStore.getActa(taskData.actaKey);
        limaStore.setXcopyId(actaItem.mainXcopy);
        limaStore.setActaName(actaItem.name);
        limaStore.setXcopyType(XCOPY_TYPE.MAINXCOPY);
        limaStore.setDocTimeStamp(null);
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.MAIN_COPY);
        log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace start");
        void getXMLPartsInNamespace().then(() => {
          log.info(limaLogTag.LimaFileOpen, "FileOpenHandler: getXMLPartsInNamespace procesed");
        });
      })
      .finally(() => {
        limaLoadeStore.remove(loaderID.k);
        limaStore.setDocxIsLoading(false);
      });

    // void insertDoc(
    //   taskData.actaKey,
    //   urlActaInsertDoc(taskData.actaKey, limaStore.userId),
    //   limaStore.actaName,
    //   LimaDocTypeENUM.ACTA,
    //   (data: LimaDocData) => {
    //     // limaStore.setActaId(taskData.actaKey);
    //     limaStore.setActa(taskData.actaKey);
    //     limaStore.setXcopyId(taskData.xcopyKey);
    //     limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.);
    //     limaLoadeStore.remove(loaderID.k);
    //     limaStore.setDocxIsLoading(false);
    //     limaStore.setIsOwner(limaActasStore.isOwner(data.limactaDocID));
    //   }
    // );
  };

  const onCompareActaToCopy = async () => {
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);

    await compareXcopy2XcopyByActaKey(taskData.actaKey, taskData.xcopyKey, limaStore.userId, loaderID)
      .then()
      .finally(() => {
        limaLoadeStore.remove(loaderID.k);
      });

    // await compareDocx_xcopy(null, taskData.actaKey, taskData.xcopyKey, limaStore.userId)
    //   .then()
    //   .finally(() => {
    //     limaLoadeStore.remove(loaderID.k);
    //   });
  };

  const CurrentDocTaskStatus = observer((): JSX.Element => {
    if (isTaskDataLinkedDocOpen(taskData, limaStore.actaId, limaStore.xcopyId)) {
      return <>{t("tptasks:taskedit.taskitem2.getstoredoc.currentDocOpenLbl")}</>;
    } else return <LimaNotice>{t("tptasks:taskedit.taskitem2.getstoredoc.currentDocNotOpenLbl")}</LimaNotice>;
  });

  const UpdateXcopy = observer((): JSX.Element => {
    let linkedXcopyOpenned = false;
    log.info(
      limaLogTag.TaskItemGetStoreDoc,
      "UEF limaStore.xcopyId change: check enable store to xcopy",
      limaStore.xcopyId,
      taskData.xcopyKey
    );
    if (limaStore.xcopyId == taskData.xcopyKey) {
      linkedXcopyOpenned = true;
    }
    //  else {
    //   linkedXcopyOpenned = false
    // }
    return (
      <ActionButton
        iconProps={saveCopyIcon}
        allowDisabledFocus
        disabled={!linkedXcopyOpenned}
        onClick={onStoreXcopyBtn}
      >
        {linkedXcopyOpenned == false
          ? t("tptasks:taskedit.taskitem2.linking.updatelinkedcopyBtnLbl")
          : taskData.xcopytype == XCOPY_TYPE.MAINXCOPY
          ? t("tptasks:taskedit.taskitem2.linking.updatemaincopyBtnLbl")
          : t("tptasks:taskedit.taskitem2.linking.updatexcopyBtnLbl")}
      </ActionButton>
    );
  });

  return (
    <Stack>
      <StackItem>{taskData.xcopyKey != null && <CurrentDocTaskStatus />}</StackItem>
      <StackItem>
        <Stack horizontal>
          <StackItem>
            <ActionButton iconProps={openCopyIcon} allowDisabledFocus disabled={!hasCopy} onClick={onGetXcopyBtn}>
              {t("tptasks:taskedit.taskitem2.linking.opencopyBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            {/* <ActionButton
              iconProps={saveCopyIcon}
              allowDisabledFocus
              disabled={!linkedXcopyOpenned}
              onClick={onStoreXcopyBtn}
            >
              {t("tptasks:taskedit.taskitem2.linking.updatexcopyBtnLbl")}
            </ActionButton> */}
            <UpdateXcopy />
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={openCopyIcon}
              allowDisabledFocus
              title={isActaOwner ? "" : "You are not acta owner"}
              disabled={!hasActa || !isActaOwner}
              onClick={onGetTaskDoc}
            >
              {t("tptasks:taskedit.taskitem2.linking.openmaindocBtnLbl")}
            </ActionButton>
          </StackItem>
          <StackItem>
            <ActionButton
              iconProps={compareCopyIcon}
              allowDisabledFocus
              disabled={!hasCopy || !isActaOwner}
              onClick={onCompareActaToCopy}
            >
              {t("tptasks:taskedit.taskitem2.linking.comparexcopy2actaBtnLbl")}
            </ActionButton>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};

export default TaskItemGetStoreDoc;
