import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import FileSaveAsNewXcopy from "../../Menu/File/FileSave/components/FileSaveAsNewXcopy";
import DragAndDropV4 from "./component/DragAndDropV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaAddActaItemV4Props {}

export const LimaAddActaItemV4: React.FunctionComponent<ILimaAddActaItemV4Props> = () => {
  const { t } = useTranslation();

  return (
    <Pivot aria-label="Add new acta">
      <PivotItem headerText={t("tpfile:filesaveV4.storeasnewPivotLbl")}>
        <FileSaveAsNewXcopy />
      </PivotItem>
      <PivotItem headerText={t("tpfile:filesaveV4.importfilePivotLbl")}>
        <DragAndDropV4 />
      </PivotItem>
    </Pivot>
  );
};
