import * as React from "react";
import { FunctionComponent } from "react";
import { LimaIconFileOpen } from "../../assets/icons/LimaIconFileOpen";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

export interface ILimaActionIconFileOpenProps {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaActionIconFileOpen: FunctionComponent<ILimaActionIconFileOpenProps> = observer(
  (props: ILimaActionIconFileOpenProps) => {
    //const content = `version info FE:${limaStore.versions.limaFEVersionId} / BE:${limaStore.versions.limaBEVersionId} / AI:${limaStore.versions.limaAIVersionId}`;
    const tooltipId = useId("toolTip-FileOpen");
    const calloutProps = { gapSpace: 0 };

    return (
      <TooltipHost content={props.title} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
        <div
          className={`${classNamesCommon.LimaActionIconButton} ${
            limaStore.docxIsLoading
              ? classNamesCommon.LimaActionIconButton_wait
              : props.disabled
              ? classNamesCommon.LimaActionIconButton_disabled
              : classNamesCommon.LimaActionIconButton_enabled
          }`}
          onClick={() => {
            if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
          //title={props.title !== undefined && props.title}
        >
          <LimaIconFileOpen />
        </div>
      </TooltipHost>
    );
  }
);
