import {
  Breadcrumb,
  getTheme,
  IBreadcrumbItem,
  IBreadcrumbStyles,
  Icon,
  ITheme,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { limaLogTag } from "../limaCommon/limaLog";
import limaBreadCrumbStore, {
  LimaBreadCrumbACTIONTYPE,
  // LimaIBreadcrumbItemAction,
  LimaIBreadcrumbItemActionBase,
} from "../store/limaBreadCrumbStore";
import limaStore from "../store/limaStore";
import Routes from "../taskpane/Menu/routes";
import { LimaActionIconFileOpen } from "./LimaActionIcons/LimaActionIconFileOpen";
import { LimaActionIconSaveFile } from "./LimaActionIcons/LimaActionIconSaveFile";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaBreadCrumbV4ExtProps {}

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  limaBreadCrumbActionWrapper: {
    alignSelf: "flex-end",
    paddingRight: 5,
  },
  limaBreadCrumbActionIcon: {
    cursor: "pointer",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});

const limaBreadCrumbStyle: IBreadcrumbStyles = {
  item: {
    marginBottom: 10,
  },
  root: "",
  list: "",
  listItem: {
    fontSize: fonts.large.fontSize,
    cursor: "pointer",
    padding: 5,
    borderRadius: 5,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
        fontWeight: 600,
      },
    },
  },
  chevron: {
    fontSize: fonts.medium.fontSize,
  },
  overflow: "",
  overflowButton: "",
  itemLink: "",
};

// type TActionIcons2Props = {
//   actionIcons: LimaIBreadcrumbItemActionBase[];
// };

const LimaBreadCrumbV4Ext: React.FunctionComponent<ILimaBreadCrumbV4ExtProps> = () => {
  const history = useHistory();
  ///fileSaveFinishRt/acta/errorUpdate
  const location = useLocation();

  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------

  //---------------------
  //    OBSERVERS
  //---------------------

  const ActionIcons2 = observer((): JSX.Element => {
    log.info(
      limaLogTag.LimaBreadCrumb4Ext,
      "ActionIcons2: check on chagnge actaid, xcopyid:",
      limaStore.actaId,
      limaStore.xcopyId,
      limaBreadCrumbStore.currentActions
    );

    return (
      <Stack horizontal>
        {limaBreadCrumbStore.currentActions.map((item: LimaIBreadcrumbItemActionBase) => {
          if (
            item.actionType === LimaBreadCrumbACTIONTYPE.FILE_OPEN &&
            item.isActionEnabled(limaStore.xcopyId, limaStore.openedDocType, location.pathname)
          )
            return (
              <Stack.Item>
                <LimaActionIconFileOpen
                  onClick={() => {
                    window.console.log("open");
                    item.actionAction();
                  }}
                />
              </Stack.Item>
            );
          else if (
            item.actionType === LimaBreadCrumbACTIONTYPE.COPY_SAVE &&
            item.isActionEnabled(limaStore.xcopyId, limaStore.openedDocType, location.pathname)
          )
            return (
              <Stack.Item>
                <LimaActionIconSaveFile
                  onClick={() => {
                    window.console.log("save c");
                    item.actionAction();
                  }}
                />
              </Stack.Item>
            );
          else if (
            item.actionType === LimaBreadCrumbACTIONTYPE.MAIN_COPY_SAVE &&
            item.isActionEnabled(limaStore.xcopyId, limaStore.openedDocType, location.pathname)
          )
            return (
              <Stack.Item>
                <LimaActionIconSaveFile
                  onClick={() => {
                    window.console.log("save m");
                    item.actionAction();
                  }}
                />
              </Stack.Item>
            );
          else return <></>;
        })}
      </Stack>
    );
  });

  const BreadCrumbsList = observer((): JSX.Element => {
    if (limaBreadCrumbStore.breadCrumbExt.length > 1) {
      return (
        <Breadcrumb
          items={limaBreadCrumbStore.getBreadCrumbExt}
          styles={limaBreadCrumbStyle}
          maxDisplayedItems={3}
          overflowIndex={1}
          overflowAriaLabel="More links"
          onRenderItem={onRenderItem}
        />
      );
    } else {
      return <></>;
    }
  });

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  const onRenderItem = (item: IBreadcrumbItem) => {
    log.info(limaLogTag.LimaBreadCrumb4Ext, "onRenderItem:", item);
    if (item.key === "home") {
      return (
        <Icon
          iconName="Home"
          style={{ paddingLeft: 10, paddingRight: 10, cursor: "pointer", fontSize: 18 }}
          onClick={() => {
            history.push(`${Routes.HomeV2Route}`);
          }}
        />
      );
    }
    let itemStyle = { cursor: "pointer", borderBottom: "" };
    if (item.isCurrentItem === true) {
      itemStyle = { ...itemStyle, borderBottom: "1px solid darkgray" };
    }
    return (
      <div
        style={itemStyle}
        onClick={() => {
          log.info(limaLogTag.LimaBreadCrumb4Ext, "on Click:", item);
          if (item.data !== undefined) history.push(item.data);
          //TODO: limaBreadCrumbStore.setActive
        }}
      >
        {item.text}
      </div>
    );
  };

  return (
    <Stack horizontal>
      <Stack.Item grow={1}>
        <BreadCrumbsList />
      </Stack.Item>
      <Stack.Item shrink={1} className={classNames.limaBreadCrumbActionWrapper}>
        <ActionIcons2 />
      </Stack.Item>
    </Stack>
  );
};

export default LimaBreadCrumbV4Ext;
