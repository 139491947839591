import { useState } from "react";

export const useMultiselectSwitch = (initSelectedTimestamp: string) => {
  const [selected, setSelected] = useState<string>(initSelectedTimestamp);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const value = event.target.value;
    const value = event.target.id;
    // window.console.log(">>>>> change", selected, value);
    //const index = selected.indexOf(value);
    if (value === selected) {
      setSelected(null);
    } else {
      setSelected(value);
    }
  };

  const isSelected = (value: string) => {
    //return selected.includes(value);
    // window.console.log(">>>>> search", selected, value);
    return selected === value;
  };

  return { selected, isSelected, onChange };
};
