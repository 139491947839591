import { getTheme, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import User2UserLinksComponentV4, { TOorFROM } from "./components/User2UserLinksComponentV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserInitationsV4Props {}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  toolsStackName: [
    fonts.medium,
    {
      marginLeft: 8,
      marginBottom: 8,
      fontWeight: 600,
      fontColor: "#8E8E93",
    },
  ],
  toolsStacTools: {
    // font: fonts.medium,
  },
});

const UserInitationsV4: React.FunctionComponent<IUserInitationsV4Props> = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack.Item className={classNames.toolsStackName}>
        {t("tpuser:userlinking.invitations.yourRequestsLbl")}
      </Stack.Item>
      <Stack.Item>
        <User2UserLinksComponentV4 type={TOorFROM.FROM} />
      </Stack.Item>
      <Stack.Item className={classNames.toolsStackName}>
        {t("tpuser:userlinking.invitations.requestsFromOthersLbl")}
      </Stack.Item>
      <Stack.Item>
        <User2UserLinksComponentV4 type={TOorFROM.TO} />
      </Stack.Item>
    </Stack>
  );
};

export default UserInitationsV4;
