//import * as React from 'react';
import React = require("react");
import { log } from "missionlog";
import { observer } from "mobx-react-lite";
import {
  FocusZone,
  FocusZoneDirection,
  Icon,
  List,
  ScrollablePane,
  ScrollbarVisibility,
  TextField,
} from "office-ui-fabric-react";
import { getFocusStyle, getTheme, ITheme, mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { getRTL } from "office-ui-fabric-react/lib/Utilities";
import { FunctionComponent } from "react";
// import { judgeActParaItemOK } from "../../../../api/schema/getJudgeActParaResp";
// import { openDialogAsIframe_judge } from "../../../../limaCommon/limaDialogs";
import { advice_textsearch } from "../../../../types/advice";
import limaTagsStore from "../../../../store/limaTagsStore";
// import useDebounce from "../../../../limaCommon/customHooks/useDebounce";

import { equalsArray } from "../../../../limaCommon/utils";
import { LimSelTemp } from "../../../../api/schema/tags/colors";
import { HLSTATE } from "../../../../types/highlighted";
import limaKeywordsStore from "../../../../store/limaKeywordStore";
import { judgeData } from "../../../../types/keywords";
import { openDialogAsIframe_judge2 } from "../../../../limaCommon/limaDialogs";
import { replaceRegexWihtColoring } from "../../../../limaCommon/textHelpers";
import { limaLogTag } from "../../../../limaCommon/limaLog";
//import useDebounce from "../../../../limaCommon/customHooks/useDebounce";
//import * as regex from "./../../../../api/schema/tags/regEx";

// log.init({ KeywordsList: "KeywordsList" });

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

//TODO: Cleanup on styles
const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    padding: 5,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  contFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  itemTitle: {
    fontSize: fonts.small.fontSize,
    fontWeight: "bold",
  },
  itemText: {
    fontSize: fonts.small.fontSize,
  },
  LIMsel00: {},
  LIMsel01: {
    backgroundColor: "#F5DCB4",
    opacity: 1,
  },
  LIMsel02: {
    backgroundColor: "#DCF5B4",
    opacity: 1,
  },
  LIMsel03: {
    backgroundColor: "#B4F5DC",
    opacity: 1,
  },
  LIMsel04: {
    backgroundColor: "#B4B4F5",
    opacity: 1,
  },
  LIMsel05: {
    backgroundColor: "#F5B4F5",
    opacity: 1,
  },
});

//----------------------- COMPONENT --------------------------------------------

const KeywordsList: FunctionComponent = () => {
  //const debouncedHoverActPar = useDebounce<advice_textsearch[] | string[]>(limaTagsStore.tagsHighLighted, 50);

  const renderKeywordsLink = (itemLink: advice_textsearch): JSX.Element => {
    // let style: LimSelTemp = limaTagsStore.getTagsHighLightedRHLStyle(limaTagsStore.judgeItemIdent(itemLink));

    //TODO: CHANGE HERE
    const style: LimSelTemp = limaTagsStore.getTagsHighLightedRHLStyleNew(limaTagsStore.judgeItemIdent(itemLink));

    return (
      <>
        {style !== undefined && style !== null ? (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <div className={classNames[style.limaSel]}>{itemLink.keywordBase}</div>
        ) : (
          itemLink.keywordBase
        )}
      </>
    );
  };

  const onRenderCell = (item: judgeData): JSX.Element => {
    return (
      <div
        id={item.title}
        className={classNames.itemCell}
        data-is-focusable={true}
        onMouseEnter={() => onHooverHandler(item.judiLinks)}
        onClick={() => {
          onClickHandler(item);
        }}
      >
        <div className={classNames.itemContent}>
          <div className={classNames.contFlex}>
            <div className={classNames.itemName}>
              {item.court}({item.date})
            </div>
            <div className={classNames.itemIndex}>
              {item.judiLinks !== null &&
                item.judiLinks.map((itemLink: advice_textsearch) => renderKeywordsLink(itemLink))}
            </div>
          </div>
          <div className={classNames.itemTitle}>{item.word.substring(0, 250) + "..."}</div>
          <div className={classNames.itemText}>
            {item.sentences.map((singleSentence: string, i) => (
              <div key={"sent_" + String(i)}>
                {singleSentence.substring(0, 150) + "..."}
                <br />
                ---{" "}
              </div>
            ))}
          </div>
        </div>
        <Icon className={classNames.chevron} iconName={getRTL() ? "ChevronLeft" : "ChevronRight"} />
      </div>
    );
  };

  const onClickHandler = (item: judgeData) => {
    //log.info(limaLogTag.KeywordsList, "onClickHandler: clicked", item);
    //localStorage.setItem("judge", JSON.stringify(item)); //TODO: Change
    //openDialogAsIframe_judge();

    log.info(limaLogTag.KeywordsList, "onClickHandler: clicked", item);
    item.presentText = item.sentences[0];

    item.judiLinks.forEach((searchItem: advice_textsearch) => {
      log.info(
        limaLogTag.KeywordsList,
        "onClickHandler: SearchActPar single",
        limaTagsStore.getRegexKeyWordsJS(searchItem)
      );
      const style: LimSelTemp = limaTagsStore.getTagsHighLightedRHLStyleNew(limaTagsStore.judgeItemIdent(searchItem));
      item.presentText = replaceRegexWihtColoring(
        item.presentText,
        limaTagsStore.getRegexKeyWordsJS(searchItem),
        style.backgroundColor
      );
    });

    localStorage.setItem("judge", JSON.stringify(item)); //TODO: Change
    //openDialogAsIframe_judge();
    openDialogAsIframe_judge2(item);
  };

  const onMouseLeaveList = () => {
    log.info(limaLogTag.KeywordsList, "onMouseLeaveList: mouse go out");
    // limaTagsStore.clearHighlightedItems();
    limaTagsStore.clearHighlightedItemsNew();
  };

  const onHooverHandler = (hoveredItems: advice_textsearch[]) => {
    //TODO: list in future when we care about multiplae acta par in judi
    log.info(limaLogTag.KeywordsList, "onHooverHandler: on hover over", hoveredItems);
    log.info(
      limaLogTag.KeywordsList,
      "onHooverHandler: on in curent highlighter",
      limaTagsStore.tagsHighLightedHoverStore
    );

    //update
    if (!equalsArray(limaTagsStore.tagsHighLightedHoverStore, hoveredItems)) {
      log.info(limaLogTag.KeywordsList, "onHooverHandler: on hovered and current are not same");
      log.info(limaLogTag.KeywordsList, "onHooverHandler: Claculation of changes START");
      const newItemsRegExList: string[] = [];

      console.log("Hoovered", hoveredItems);

      //to New store only unique not existing
      hoveredItems.forEach((itemHL: advice_textsearch) => {
        //let judge2RegExt = "§?xx?*{1,30}?yy?".replace("?xx?", itemHL.parID).replace("?yy?", itemHL.actID);
        //let judge2RegExt = "?xx?*{1;30}?yy?".replace("?xx?", itemHL.parID).replace("?yy?", itemHL.actID);
        const judge2RegExt = limaTagsStore.judgeItemIdent(itemHL);

        // //TODO: remove after move to another version --- from here
        // if (limaTagsStore.checkIsRegexSelected(judge2RegExt) === 0) {
        //   newItemsRegExList.findIndex((arrayItem: string) => arrayItem === judge2RegExt) === -1 &&
        //     newItemsRegExList.push(judge2RegExt);
        // }
        // //TODO: remove after move to another version --- to here
        if (limaTagsStore.checkIsRegexState(judge2RegExt) === HLSTATE.UNHIGHLIGHTED) {
          newItemsRegExList.findIndex((arrayItem: string) => arrayItem === judge2RegExt) === -1 &&
            newItemsRegExList.push(judge2RegExt);
        }
      });
      log.info(limaLogTag.KeywordsList, "onHooverHandler: Claculation of changes FINISH NEW ADDED");
      log.info(limaLogTag.KeywordsList, "onHooverHandler: list to show", newItemsRegExList);

      //Remove only that are not in hover
      const current2RemoveHLList: string[] = [];
      limaTagsStore.tagsHighLightedHoverStore.forEach((itemHL: advice_textsearch) => {
        const judge2RegExt = limaTagsStore.judgeItemIdent(itemHL);

        hoveredItems.findIndex(
          (itemHoover: advice_textsearch) => itemHoover.actID === itemHL.actID && itemHoover.parID === itemHL.parID
        ) === -1 &&
          current2RemoveHLList.findIndex((arrayItem: string) => arrayItem === judge2RegExt) === -1 &&
          current2RemoveHLList.push(judge2RegExt);
      });
      log.info(
        limaLogTag.KeywordsList,
        "onHooverHandler: Claculation of changes FINISH REMOVING ONLY THAT ARE NOT STILL SELECTED"
      );
      log.info(limaLogTag.KeywordsList, "onHooverHandler: list to remove", current2RemoveHLList);

      console.log("dump before update ");
      limaTagsStore.dumpHighLigtedR();
      limaTagsStore.updateHighLightedR(current2RemoveHLList, newItemsRegExList); //TODO: remove Only not hovered
      console.log("dump after update");
      limaTagsStore.dumpHighLigtedR();
      //limaTagsStore.updateHighLitedInDoc(newItemsRegExList); //TODO: Hovered not in current list
      //limaTagsStore.updateHighLitedInDocTags(newItemsRegExList);
      void limaTagsStore.updateHighLitedInDocTags();
      limaTagsStore.tagsHighLightedHoverStore = hoveredItems;
      log.info(limaLogTag.KeywordsList, "onHooverHandler: Claculation of changes FINISH");
    } else {
      //DO nothing
      log.info(limaLogTag.KeywordsList, "onHooverHandler: on hovered and current are same - DO NOTHING");
    }
  };

  const DataItemTextArray = observer((): JSX.Element => {
    log.info(limaLogTag.KeywordsList, "DataItemTextArray: rendering");
    if (limaKeywordsStore.totalItemCount === undefined || limaKeywordsStore.totalItemCount === 0) return <></>;

    return (
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} onMouseLeave={onMouseLeaveList}>
        <List items={limaKeywordsStore.list} onRenderCell={onRenderCell} />
      </ScrollablePane>
    );
  });
  const FilterLabel: FunctionComponent = observer(() => {
    return <>{`Filter by name (${limaKeywordsStore.list.length}/${limaKeywordsStore.totalItemCount}):`}</>;
  });

  const ShowData: FunctionComponent = observer(() => {
    return <>{limaKeywordsStore.totalItemCount > 0 ? <DataItemTextArray /> : <></>}</>;
  });
  //onMouseOut={() => onHooverHandlerOut()}
  return (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <FilterLabel />
      <TextField
        label={""} // + resultCountText
        // eslint-disable-next-line react/jsx-no-bind
        onChange={limaKeywordsStore.onFilterChanged}
      />
      <div className={classNames.wrapper}>
        <ShowData />
      </div>
    </FocusZone>
  );
};

export default KeywordsList;
