import { useEffect } from "react";
import { FunctionComponent } from "react";
import React = require("react");
import { Separator, Stack, Text } from "@fluentui/react";

import FileDataItem from "./FileDataItem";
import limaStore from "../../../../store/limaStore";
import { useTranslation } from "react-i18next";
import { LimaNotice, LIMA_TEXTNOTICETYPE } from "../../../../limaComponents/LimaNotice";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

// const classNames = mergeStyleSets({
//   rednotice: {
//     color: "red",
//   },
// });

const FileDataPage: FunctionComponent = () => {
  // const [selected, setSelected] = useState<boolean>();
  const { t } = useTranslation();

  useEffect(() => {
    // window.console.log("current doc open typ", limaStore.openedDocType);
    // setSelected(true);
  }, []);

  const disabled = !limaStore.isMainCopyOpen() && limaStore.isAnyXcopyOpen();

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.fileLbl"), t("tpmain:menu.dataObjectsLbl")]} />
      <Stack>
        <Stack.Item>
          <Text variant={"large"}>{t("tpfile:dataitems.labelLbl")}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant={"small"}>{t("tpfile:dataitems.infolabelLbl")}</Text>
          {/* <Text variant={"small"}>
            *To use it just select some part of document or just add to document and fill with text
          </Text> */}
        </Stack.Item>
      </Stack>
      <Separator>{t("tpfile:dataitems.dataitemslistlabelLbl")}</Separator>
      {disabled && (
        <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>
          {/* <Text variant={"small"} className={classNames.rednotice}> */}
          {t("tpfile:dataitems.dataitem.nomaincoyopenlLbl")}
          {/* </Text> */}
        </LimaNotice>
      )}
      {/* {selected} */}
      <FileDataItem
        ccName={"actaName"}
        componentText={t("tpfile:dataitems.dataitem.actanamelabelLbl")}
        disabled={disabled}
      />
      <FileDataItem
        ccName={"actaSide01"}
        componentText={t("tpfile:dataitems.dataitem.sideAlabelLbl")}
        disabled={disabled}
      />
      <FileDataItem
        ccName={"actaSide02"}
        componentText={t("tpfile:dataitems.dataitem.sideBlabelLbl")}
        disabled={disabled}
      />
      <FileDataItem
        ccName={"actaSignatureDate"}
        componentText={t("tpfile:dataitems.dataitem.signatureDatalabelLbl")}
        disabled={disabled}
      />
      {/* <DefaultButton onClick={() => convertToCC("actaName")}>Identify Acta Name</DefaultButton> */}
      {/* <DefaultButton onClick={() => convertToCC("actaSide01")}>Identify first side</DefaultButton>
      <DefaultButton onClick={() => convertToCC("actaSide02")}>Identify second side</DefaultButton>
      <DefaultButton onClick={() => convertToCC("actaSignatureDate")}>Identify signature date</DefaultButton> */}
    </>
  );
};

export default FileDataPage;
