//import * as React from 'react';
import React = require("react");
import { FunctionComponent, useRef } from "react";

import { getRTL } from "office-ui-fabric-react/lib/Utilities";
import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from "office-ui-fabric-react/lib/Styling";
import {
  FocusZone,
  FocusZoneDirection,
  Icon,
  List,
  TextField,
  ScrollablePane,
  ScrollbarVisibility,
} from "office-ui-fabric-react";

import { log } from "missionlog";
import { observer } from "mobx-react-lite";

import { openDialogAsIframe_actPar } from "../../../../limaCommon/limaDialogs";
import { ActParaItemOK } from "../../../../api/schema/getActsParaResp";

import limaActParStore from "../../../../store/limaActParStore";
import limaTagsStore from "../../../../store/limaTagsStore";
import { replaceRegexWihtColoring } from "../../../../limaCommon/textHelpers";
import { defBackGroundColor, LimSelTemp } from "../../../../api/schema/tags/colors";
import { limaLogTag } from "../../../../limaCommon/limaLog";

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

//TODO: Cleanup on styles
const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    padding: 5,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.medium,
    {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: 10,
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  contFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  itemText: {
    fontSize: fonts.small.fontSize,
  },
});

//----------------------- COMPONENT --------------------------------------------

const ActsParaList: FunctionComponent = () => {
  const onRenderCell = (item: ActParaItemOK): JSX.Element => {
    log.info(limaLogTag.ActsParaList, "onRenderCell: rendering item", item);
    return (
      <div
        key={item.actid + "_" + item.parid + "_" + item.supparid}
        className={classNames.itemCell}
        data-is-focusable={true}
        onClick={() => {
          onClickHandler(item);
        }}
      >
        <div className={classNames.itemContent}>
          <div className={classNames.contFlex}>
            <div className={classNames.itemName}>
              § {item.parid} {item.supparid !== "" && "ods:" + item.supparid}
            </div>
            <div className={classNames.itemIndex}></div>
          </div>
          <div className={classNames.itemText}>{item.partext.substring(0, 150) + "..."}</div>
        </div>
        <Icon className={classNames.chevron} iconName={getRTL() ? "ChevronLeft" : "ChevronRight"} />
      </div>
    );
  };

  const onClickHandler = (item: ActParaItemOK) => {
    log.info(limaLogTag.ActsParaList, "onClickHandler: clicked", item);
    item.presentText = item.partext;
    log.info(
      limaLogTag.ActsParaList,
      "onClickHandler: SearchActPar single",
      limaTagsStore.getRegexActParJS({ actID: item.actid, parID: item.parid })
    );
    const style: LimSelTemp = limaTagsStore.getTagsHighLightedRHLStyleNew(
      limaTagsStore.judgeItemIdent({ actID: item.actid, parID: item.parid })
    );
    item.presentText = replaceRegexWihtColoring(
      item.partext,
      limaTagsStore.getRegexActParJS({ actID: item.actid, parID: item.parid }),
      style !== null && style.backgroundColor !== null ? style.backgroundColor : defBackGroundColor
    );
    localStorage.PlayerData = JSON.stringify(item);
    window.localStorage.setItem("actPar", JSON.stringify(item)); //TODO: Change
    window.sessionStorage.setItem("actPar", JSON.stringify(item));
    openDialogAsIframe_actPar(item);
    //localStorage.setItem("actPar", JSON.stringify(item)); //TODO: Change
    //openDialogAsIframe_actPar2();
  };

  const DataItemTextArray = observer((): JSX.Element => {
    log.info(limaLogTag.ActsParaList, "DataItemTextArray: rendering");

    return (
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <List items={limaActParStore.list} onRenderCell={onRenderCell} ref={useRef()} />
      </ScrollablePane>
    );
  });

  const ShowData: FunctionComponent = observer(() => {
    return <>{limaActParStore.totalItemCount > 0 ? <DataItemTextArray /> : <></>}</>;
  });

  return (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <TextField
        label={"Filter by name "} // + resultCountText
        // eslint-disable-next-line react/jsx-no-bind
        onChange={limaActParStore.onFilterChanged}
      />
      <div className={classNames.wrapper}>
        <ShowData />
      </div>
    </FocusZone>
  );
};

export default ActsParaList;
