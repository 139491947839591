import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { FocusZone, FocusZoneDirection } from "office-ui-fabric-react/lib/FocusZone";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { List } from "office-ui-fabric-react/lib/List";
import { ITheme, mergeStyleSets, getTheme } from "office-ui-fabric-react/lib/Styling";
import { IIconProps, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import limaTasksStore, { TASK_LINKED2ME_ENUM } from "../../../../store/limaTasksStore";
import { log } from "missionlog";

// import limaStore from "../../../../store/limaStore";

import { User2Task_withActaXcopy } from "../../../../types/User2Task_withActaXcopy";
import TaskListItem from "./components/TaskListItem";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimaControlledTextSwitch, ControlledSwitchDataItem } from "../../../../limaComponents/LimaControledTextSwitch";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { httpGetAuth, HttpResponse } from "../../../../api/httpAPI";
import { urlTasksGetList } from "../../../../api/apilinks";
import limaStore from "../../../../store/limaStore";
import { useQuery } from "react-query";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { LimaNotice } from "../../../../limaComponents/LimaNotice";

// log.init({ TasksList: "TasksList" });
const RemoveLinkIcon: IIconProps = { iconName: "RemoveLink" };

export interface TaskListV3Pros {
  onClickHandler: (task: User2Task_withActaXcopy) => void;
  onStartFilterOnlyMy?: boolean;
  onStartFilterClosedTasks?: boolean;
  showFilters?: boolean;
}

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  itemsWrapper: {
    position: "relative",
    height: "40vh",
  },

  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
});

const TasksListV3: FunctionComponent<TaskListV3Pros> = observer(
  ({
    onClickHandler,
    onStartFilterOnlyMy,
    onStartFilterClosedTasks: onStartFilterClosed,
    showFilters,
  }: TaskListV3Pros) => {
    const { t } = useTranslation();

    const { isLoading, isRefetching, error, refetch } = useQuery(["taskslist"], () => fetchTasks(), {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.TasksList, "useQuery get tasks ", data);
        // setMessages(data);
      },
    });

    const resultCountText =
      limaTasksStore.fullTasksList == null ||
      limaTasksStore.tasksList == null ||
      limaTasksStore.fullTasksList.length === limaTasksStore.tasksList.length
        ? ""
        : `(${limaTasksStore.tasksList.length} of ${limaTasksStore.fullTasksList.length} shown)`;

    const onRemove = async (taskId: string) => {
      log.info(limaLogTag.TasksList, `onRemove: removeing taskID:'${taskId}'`);
      await limaTasksStore.removeTaskFromList(taskId);
    };

    const onRenderCell = (item: User2Task_withActaXcopy): JSX.Element => {
      log.info(limaLogTag.TasksList, `onRenderCell: on render `, item);
      return (
        <TaskListItem
          onAction={onRemove}
          onClickHandler={() => {
            log.info(limaLogTag.TasksList, `TaskListItem clicked `, item);
            onClickHandler(item);
          }}
          actionIcon={RemoveLinkIcon}
          item={item.task_withActaXcopy}
          actionEnabled={true}
        />
      );
    };

    useEffect(() => {
      log.info(
        limaLogTag.TasksList,
        `useEffect: onStartMy'${String(onStartFilterOnlyMy)}',onStartClosed:'${String(onStartFilterClosed)}' `
      );
      void refetch().then(() => {
        log.info(
          limaLogTag.TasksList,
          `useEffect after refetch: onStartMy'${String(onStartFilterOnlyMy)}',onStartClosed:'${String(
            onStartFilterClosed
          )}' `
        );
        if (onStartFilterOnlyMy !== undefined) limaTasksStore.filterMy = TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY;
        else {
          limaTasksStore.filterMy = TASK_LINKED2ME_ENUM.NOTONLYMY;
        }
        if (onStartFilterClosed !== undefined) limaTasksStore.filterClosed = onStartFilterClosed;
        else {
          limaTasksStore.filterClosed = true;
        }
        if (onStartFilterOnlyMy !== undefined || onStartFilterClosed !== undefined) {
          log.info(limaLogTag.TasksList, `useEffect: call onChanges on startup `);
          limaTasksStore.onFilterChanged();
        }
      });
    }, []);

    const TasksListList = observer((): JSX.Element => {
      return (
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <List items={limaTasksStore.tasksList} onRenderCell={onRenderCell} />
        </ScrollablePane>
      );
    });

    if (isLoading || isRefetching) {
      return LimaLoadingComponnet(3, "tasklist");
    }

    if (error) {
      log.error(limaLogTag.TasksList, "stat loaded error: ", error);
      // return <>Error Getting data </>;
      return <LimaNotice>{t("tpshow:history.errorloadingLbl")}</LimaNotice>;
    }

    return (
      <>
        {showFilters === undefined || showFilters === true ? (
          <>
            {t("tptasks:taskList.filterLbl")}
            <LimaControlledTextSwitch
              values={[
                {
                  key: TASK_LINKED2ME_ENUM.NOTONLYMY,
                  name: t("tptasks:taskList.filter.notonlymytasksLbl"),
                  value: TASK_LINKED2ME_ENUM.NOTONLYMY,
                },
                {
                  key: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
                  name: t("tptasks:taskList.filter.myresponsetasksLbl"),
                  value: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
                },
                {
                  key: TASK_LINKED2ME_ENUM.ICREATEONLY,
                  name: t("tptasks:taskList.filter.onlyimadetasksLbl"),
                  value: TASK_LINKED2ME_ENUM.ICREATEONLY,
                },
              ]}
              defaultValue={TASK_LINKED2ME_ENUM.NOTONLYMY}
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksList, `LimaControlledTextSwitch toggle: switch `, dataItem);

                limaTasksStore.filterMy = TASK_LINKED2ME_ENUM[dataItem.value as keyof typeof TASK_LINKED2ME_ENUM];

                limaTasksStore.onFilterChanged(null);
              }}
            />
            <LimaControlledTextSwitch
              values={[
                { key: "alltasks", name: t("tptasks:taskList.filter.alltasksLbl"), value: "alltasks" },
                {
                  key: "currentactaonly",
                  name: t("tptasks:taskList.filter.currentactatasksLbl"),
                  value: "currentactaonly",
                },
              ]}
              defaultValue="alltasks"
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksList, `LimaControlledTextSwitch toggle: switch `, dataItem);

                if (dataItem.value == "alltasks") limaTasksStore.filterLinked = false;
                else if (dataItem.value == "currentactaonly") limaTasksStore.filterLinked = true;

                limaTasksStore.onFilterChanged(null);
              }}
            />
            <LimaControlledTextSwitch
              values={[
                { key: "onlyopen", name: t("tptasks:taskList.filter.onlyopentaskLbl"), value: "onlyopen" },
                {
                  key: "includingfinished",
                  name: t("tptasks:taskList.filter.includefinishedLbl"),
                  value: "includingfinished",
                },
              ]}
              defaultValue="onlyopen"
              callBack={(dataItem: ControlledSwitchDataItem) => {
                log.info(limaLogTag.TasksList, `LimaControlledTextSwitch toggle: switch `, dataItem);
                if (dataItem.value == "onlyopen") limaTasksStore.filterClosed = true;
                else if (dataItem.value == "includingfinished") limaTasksStore.filterClosed = false;

                limaTasksStore.onFilterChanged(null);
              }}
            />
          </>
        ) : (
          <></>
        )}

        <FocusZone direction={FocusZoneDirection.vertical}>
          <TextField
            label={t("tptasks:taskList.filterTaskNameLbl") + resultCountText}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={limaTasksStore.onFilterChanged}
          />
          <div className={classNames.itemsWrapper}>
            <TasksListList />
          </div>
        </FocusZone>
      </>
    );
  }
);

export default TasksListV3;

//----------------------
//   REACT QUERY
//----------------------

const fetchTasks = async (): Promise<User2Task_withActaXcopy[]> => {
  log.info(limaLogTag.TasksList, "fetching data fetchTasks");
  // const loaderID = limaLoadeStore.add("Getting messages from DB");
  let tasksListData: HttpResponse<User2Task_withActaXcopy[]>;
  const loaderID = limaLoadeStore.add(`Getting Tasks from server`);
  try {
    // const response: HttpResponse<User2Task_withActaXcopy[]> = await httpGet(
    //   urlGetMessages(limaStore.userId, linkedId, linkedType),
    //   loaderID.ac
    // );

    // tasksListData = await httpGet<User2Task_withActaXcopy[]>(urlTasksGetList("", limaStore.userId, ""), loaderID.ac);
    tasksListData = await httpGetAuth<User2Task_withActaXcopy[]>(
      urlTasksGetList("", limaStore.userId, ""),
      loaderID.ac
    );

    if (!tasksListData.ok) {
      throw tasksListData;
    }
    log.info(limaLogTag.LimaTasksStore, "getTasksList: I get Data from call:", tasksListData);
    if (tasksListData.parsedBody !== undefined) {
      limaTasksStore.setFullTasksList(tasksListData.parsedBody);
      limaTasksStore.setTasksList(tasksListData.parsedBody);
      limaTasksStore.setFullTaskListCount(tasksListData.parsedBody.length);
    }
    log.info(limaLogTag.LimaTasksStore, "getTasksList: now have tasksList ", limaTasksStore.tasksList);
    log.info(limaLogTag.LimaTasksStore, "getTasksList: now have fullTasksList: ", limaTasksStore.fullTasksList);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return tasksListData.parsedBody;
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    log.error(limaLogTag.LimaTasksStore, "Error call get User", error);
    throw new Error(error);
  }
};
