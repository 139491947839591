/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from "react";
import { FunctionComponent, useRef, useState } from "react";

import { log } from "missionlog";
import { getTheme, ITheme, mergeStyleSets, Separator } from "office-ui-fabric-react";
import { Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import FileItemImportV4 from "./FileItemImportV4";

const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  container: {
    color: "red",
    display: "flex",
  },
  dropContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 75,
    width: "75%",
    borderColor: "#4aa1f3",
    borderStyle: "dashed",
    borderWidth: 1,
  },
  fileInput: {
    display: "none",
  },
  containerButton: {
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    height: 100,
    flexShrink: 0,
    minWidth: 40,
  },
  containerButtonUpload: {
    height: 100,
    selectors: {
      "&:hover": {
        cursor: "pointer",
        background: palette.neutralLight,
      },
    },
  },
  containerButtonSorry: {
    height: 100,
    backgroundColor: "red",
  },
  containerButtonNone: {
    display: "none",
  },
  fileUploadLine: {
    display: "flex",
  },
  validAttFile: {},
  invalidAttFile: {
    color: "red",
    textDecoration: "underline",
  },
  remFileUpload: {
    selectors: {
      ":hover": {
        cursor: "pointer",
      },
    },
  },
});

interface IDragAndDropV4Props {
  filesToUploadHandler?: unknown;
}

// eslint-disable-next-line no-empty-pattern
const DragAndDropV4: FunctionComponent<IDragAndDropV4Props> = ({}: IDragAndDropV4Props) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const [filterdFiles, setFilterdFiles] = useState<string[]>([]);
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>();

  const fileInputClicked = () => {
    log.info(limaLogTag.DragAndDropV4, `fileInputClicked:`);
    if (fileInputRef != undefined) fileInputRef.current.click();
  };

  const filesSelected = () => {
    log.info(limaLogTag.DragAndDropV4, `filesSelected: `, fileInputRef.current.files);
    if (fileInputRef.current.files.length) {
      //handleFiles(fileInputRef.current.files[0]);
      handleFiles(fileInputRef.current.files);
    }
  };

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };

  const validTypes = [
    "application/msword", // TODO: Does it work?
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const fileDrop = (e: any) => {
    e.preventDefault();
    log.info(limaLogTag.DragAndDropV4, `fileDrop: `, e.dataTransfer.files);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const files = e.dataTransfer.files;
    if (files.length) {
      //handleFiles(files[0]);
      handleFiles(files);
    }
  };

  const handleFiles = (files: FileList) => {
    /*  if (validateFile(file)) { */
    log.info(limaLogTag.DragAndDropV4, `handleFiles: `, files);
    const filteredFiles_New: string[] = filterdFiles;
    const selectedFiles_toAdd: File[] = Array.from(files).filter((file: File) => {
      if (validTypes.indexOf(file.type) == -1) {
        log.warn(limaLogTag.DragAndDropV4, file.type);
        log.warn(limaLogTag.DragAndDropV4, "input allow only docx files");
        filteredFiles_New.push(file.name);
        return false;
      } else {
        /* log.info(tag.DragAndDrop, file.name);
        log.info(tag.DragAndDrop, file.type);
        log.info(tag.DragAndDrop, file.size); */
        return true;
      }
    });
    if (selectedFiles === undefined) setSelectedFiles(selectedFiles_toAdd);
    else setSelectedFiles([...selectedFiles, ...selectedFiles_toAdd]);

    setFilterdFiles(filteredFiles_New);

    //setSelectedFiles();

    /*  } */
    /* const validateFile = (file: File): boolean => {
   
    if (validTypes.indexOf(file.type) == -1) {
      log.warn(tag.DragAndDrop, file.type);
      log.warn(tag.DragAndDrop, "input allow only docx files");
      return false;
    } else { */
    /* log.info(tag.DragAndDrop, file.name);
      log.info(tag.DragAndDrop, file.type);
      log.info(tag.DragAndDrop, file.size); */

    /*       return true;
    } */

    // filesToUploadHandler(file);
  };

  /* const fileSize = (size: number) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }; */

  return (
    <>
      {/* <input ref={fileInputRef} type="file" className={classNames.fileInput} onChange={filesSelected} /> */}
      <input
        key="ddInput"
        ref={fileInputRef}
        type="file"
        className={classNames.fileInput}
        onChange={filesSelected}
        multiple
      />
      <div key="ddContainer" className={classNames.container}>
        <div
          key="ddDropContainer"
          className={classNames.dropContainer}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          {t("tpfile:fileimport.dd.dropdown.dropdownLbl")}
        </div>
      </div>
      <Separator>{t("tpfile:fileimport.dd.importlist.importlistLabel")}</Separator>
      <div key="ddFileContainer" className="fileDisplayContainer">
        <>
          {selectedFiles &&
            selectedFiles.map((file: File | null, index: number) => {
              log.info(limaLogTag.DragAndDropV4, `fileDisplayContainer: `, file);
              return (
                <>
                  <FileItemImportV4 key={"ddFile_" + String(index)} file={file} />
                </>
              );
            })}
        </>
      </div>
      <Separator>Filtered</Separator>
      <Text>{t("tpfile:fileimport.dd.filtered.filteredLabel")}</Text>
      <div key="ddFilterContainer" className="fileDisplayContainer Filtered">
        <>
          {filterdFiles &&
            filterdFiles.map((file: string | null, index: number) => {
              log.info(limaLogTag.DragAndDropV4, `fileDisplayContainerFiltered: `, file);
              return (
                <>
                  <span key={"ddFilterFile_" + String(index)} className="fileName">
                    {file},{" "}
                  </span>
                </>
              );
            })}
        </>
      </div>
    </>
  );
};

export default DragAndDropV4;
