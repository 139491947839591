import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsAddTask = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32.75"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="Create_task" data-name="Create task" transform="translate(-291.57 -83.634)">
        <g id="Group_13249" data-name="Group 13249" transform="translate(293.146 84.384)">
          <g id="Group_13245" data-name="Group 13245" transform="translate(3.729 5.261)">
            <path
              id="Path_8972"
              data-name="Path 8972"
              d="M3855,1267.929l1.2,1.2,2.5-2.5"
              transform="translate(-3855 -1266.628)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_8979"
              data-name="Path 8979"
              d="M3855,1267.929l1.2,1.2,2.5-2.5"
              transform="translate(-3855 -1260.115)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_8980"
              data-name="Path 8980"
              d="M3855,1267.929l1.2,1.2,2.5-2.5"
              transform="translate(-3855 -1253.274)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <g id="Group_12391" data-name="Group 12391" transform="translate(6.219 0.921)">
              <path
                id="Path_8975"
                data-name="Path 8975"
                d="M3876.152,1277.889h10.113"
                transform="translate(-3876.152 -1277.889)"
                fill="none"
                stroke="#8e8e93"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
            </g>
            <g id="Group_12396" data-name="Group 12396" transform="translate(6.219 7.762)">
              <path
                id="Path_8975-2"
                data-name="Path 8975"
                d="M3876.152,1277.889h10.113"
                transform="translate(-3876.152 -1277.889)"
                fill="none"
                stroke="#8e8e93"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
            </g>
            <g id="Group_12393" data-name="Group 12393" transform="translate(6.219 14.349)">
              <path
                id="Path_8975-3"
                data-name="Path 8975"
                d="M3876.875,1277.948h2.811"
                transform="translate(-3876.875 -1277.948)"
                fill="none"
                stroke="#8e8e93"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
            </g>
          </g>
          <path
            id="Path_9011"
            data-name="Path 9011"
            d="M12.906,0,23.439.033A2.5,2.5,0,0,1,25.9,2.582V24.545a2.5,2.5,0,0,1-2.457,2.549L2.457,27.062A2.5,2.5,0,0,1,0,24.512V13.087"
            transform="translate(25.896 27.094) rotate(180)"
            fill="none"
            stroke="#8e8e93"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <g id="Group_13250" data-name="Group 13250" transform="translate(13.706 14.756)">
            <g
              id="Ellipse_1758"
              data-name="Ellipse 1758"
              transform="translate(0)"
              fill="#8e8e93"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <circle cx="8.104" cy="8.104" r="8.104" stroke="none" />
              <circle cx="8.104" cy="8.104" r="7.354" fill="none" />
            </g>
            <g id="Group_12394" data-name="Group 12394" transform="translate(4.967 4.967)">
              <path
                id="Path_8977"
                data-name="Path 8977"
                d="M3886.408,1308.5h6.275"
                transform="translate(-3886.408 -1305.363)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
              <path
                id="Path_8978"
                data-name="Path 8978"
                d="M0,0H6.275"
                transform="translate(3.137 6.275) rotate(-90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
            </g>
          </g>
        </g>
        <rect
          id="Rectangle_24139"
          data-name="Rectangle 24139"
          width="32"
          height="32"
          transform="translate(291.57 84.384)"
          fill="none"
        />
      </g>
    </svg>
  );
};
