import { Pivot, PivotItem, PrimaryButton, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { urlGetMessagesCount, urlGetTask } from "../../../../../api/apilinks";
import { httpGetAuth } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../../types/BASE_Enums";
import { compareTaskFormToTask_withActaXcopy, TaskForm, TASKSTATE, TaskTypeEnum } from "../../../../../types/Task";
import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../../../types/Task_withActaXcopy";
import { User2Task_withActaXcopy, User2Task_withActaXcopy_getNull } from "../../../../../types/User2Task_withActaXcopy";
import AccessComponent from "../../../../CommonComponents/Access/AccessComponent";
import AttachementComponent from "../../../../CommonComponents/Attachment/AttachmentComponent";
import TaskItemEdit2 from "../TaskItemEdit2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SchemaOf } from "yup";
import moment = require("moment");
import limaTasksStore from "../../../../../store/limaTasksStore";
import { User_getNull } from "../../../../../types/User";
import { updateTaskData } from "../../../../../api/calls/updateTaskData";
import MessageComponent from "../../../../CommonComponents/Message/MessageComponent";
import Task2TaskComponent from "./Task2TaskComponent";
import TaskHistoryComponent from "./TaskHistoryComponent";

interface TaskPageProps {
  // taskId?: string;
  user2taskId?: string;
  prefillTaskData?: Task_withActaXcopy;
}

const schema: SchemaOf<TaskForm> = yup.object().shape({
  taskname: yup.string().required("This is required field"),
  taskdescription: yup.string().default("").nullable(),
  taskduedate: yup.date().default(null).nullable(),
  taskstate: yup.string().default(TASKSTATE.NONE).nullable(),
  taskassigned: yup.string().nullable(),
  type: yup.mixed().oneOf([TaskTypeEnum.TASK, TaskTypeEnum.NOTE, TaskTypeEnum.POINT]),
});

const TaskItem: FunctionComponent<TaskPageProps> = ({ user2taskId, prefillTaskData }: TaskPageProps) => {
  const [currentU2Task, setCurrentU2Task] = useState<User2Task_withActaXcopy>(User2Task_withActaXcopy_getNull()); //Downloaded
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number | null>(null);
  const [attchCount, setAttchCount] = useState<number | null>(null);
  const [linkedTasksCount, setLinkedTasksCount] = useState<number | null>(null);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true);
  const [currentTaskData, setCurrentTaskData] = useState<Task_withActaXcopy>(
    prefillTaskData === undefined ? Task_withActaXcopy_getNull() : prefillTaskData
  ); //current task
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, watch } = useForm<TaskForm>({
    defaultValues: {
      taskname: "",
      taskdescription: "",
      taskduedate: null,
      taskstate: TASKSTATE.NONE,
      taskassigned: "",
      type: TaskTypeEnum.TASK,
    },
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  const valueOfTest = watch();

  //Get task
  const { status, error, data, refetch } = useQuery(
    ["getTaskData", { userid: limaStore.userId, user2taskId: user2taskId }],
    () => getTaskData(limaStore.userId, user2taskId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (error2) => {
        log.error(limaLogTag.TaskItem, "useQuery get taskData ", error2);
      },
      onSuccess: (data2) => {
        log.info(limaLogTag.TaskItem, "useQuery get taskData ", data2);
        setCurrentU2Task(data2);
        setCurrentTaskData(data2.task_withActaXcopy);
        setupTaskData(data2.task_withActaXcopy);
        setAttchCount(data2.task_withActaXcopy.taskData.attachements.length);
        setUnsavedChanges(false);
        void getTaskMessagesCount(limaStore.userId, data2.task_withActaXcopy._key, LIMA_ITEM_TYPE.TASK).then(
          (count: number) => {
            setMessagesCount(count);
          }
        );
      },
    }
  );

  //When asket outside for another ID
  useEffect(() => {
    if (user2taskId !== undefined && user2taskId !== null && user2taskId !== "" && user2taskId !== ":taskid") {
      log.info(limaLogTag.TaskItem, "user2taskId: loading", user2taskId);
      void refetch();
    }
  }, [user2taskId]);

  //When asket outside for another prefilled inforamtion
  useEffect(() => {
    log.info(
      limaLogTag.TaskItemEditV2,
      "UEF prefillTaskData: Update data  taskData, prefillTaskData",
      currentTaskData,
      prefillTaskData,
      currentU2Task
    );
    if (prefillTaskData !== undefined) {
      log.info(limaLogTag.TaskItemEditV2, "UEF prefillTaskData: prefillTaskData SET");
      setupTaskData(prefillTaskData);
      setCurrentTaskData({ ...prefillTaskData });
      setUnsavedChanges(true);
    }
  }, [prefillTaskData]);

  //When changes data in Form
  useEffect(() => {
    log.info(
      limaLogTag.TaskItemEditV2,
      "UEF valueOfTest: Update data taskData,valueofTst, taskFromParent",
      currentTaskData,
      valueOfTest,
      currentU2Task.task_withActaXcopy,
      currentU2Task
    );
    //Task data is solved separatly on any change go to unasaved = true
    // if (compareTaskFormToTask_withActaXcopy(valueOfTest, taskData)) {
    if (compareTaskFormToTask_withActaXcopy(valueOfTest, currentTaskData.taskData, currentU2Task.task_withActaXcopy)) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  }, [valueOfTest]);

  //SETUP VALUES IN FORM
  const setupTaskData = (newSetupData: Task_withActaXcopy) => {
    log.info(limaLogTag.TaskItemEditV2, "setupTaskData: setup from taskData", newSetupData);
    //setTaskData({ ...data });
    //SETUP VALUES IN FORM
    setValue("taskname", newSetupData.taskName);
    setValue("taskdescription", newSetupData.taskDescription);
    setValue("taskduedate", new Date(newSetupData.taskDueDate));
    setValue("taskstate", newSetupData.taskState || "");
    setValue("taskassigned", (newSetupData.taskAssignedTo && newSetupData.taskAssignedTo._key) || "");
    setValue("type", (newSetupData.type !== undefined && newSetupData.type) || TaskTypeEnum.TASK);

    setUnsavedChanges(false);
  };

  /**
   * Take care of upddate / store
   */
  const onUpdate = () => {
    log.info(limaLogTag.TaskItemEditV2, "onUpdate : call ");
    void handleSubmit(
      (data3: TaskForm) => {
        log.info(limaLogTag.TaskItemEditV2, "onUpdate: Start: data", data3);
        storeOrUpdateTaskData(data3);
      },
      (err) => {
        console.log(err);
        log.error(limaLogTag.TaskItemEditV2, "onUpdate: error data", err);
      }
    )();
  };

  const storeOrUpdateTaskData = (data4: TaskForm) => {
    log.info(limaLogTag.TaskItemEditV2, "storeOrUpdateTaskData: call procesing ", data4);

    updateTaskData(
      {
        ...currentTaskData,
        //hasLinkedCopy: taskData.hasLinkedCopy, //TODO: change to true/false
        taskName: data4.taskname,
        taskDescription: data4.taskdescription,
        taskDueDate: (data4.taskduedate && moment(data4.taskduedate).toISOString()) || null,
        taskState: data4.taskstate,
        taskAssignedTo:
          (data4.taskassigned && data4.taskassigned != "" && { ...User_getNull(), _key: data4.taskassigned }) || null,
        luIssuedBy: limaStore.userId,
        luTimeStamp: moment().toISOString(),
        type: data4.type,
      },
      limaStore.userId
    )
      .then((resultTask: Task_withActaXcopy) => {
        log.info(limaLogTag.TaskItemEditV2, "storeOrUpdateTaskData: finished - return ", resultTask);
        if (currentTaskData._key === undefined || currentTaskData._key === null || currentTaskData._key === "") {
          setCurrentTaskData({ ...currentTaskData, ...resultTask });
        }

        limaTasksStore.updateSingleTask(resultTask);
        log.info(limaLogTag.TaskItemEditV2, "storeOrUpdateTaskData: unsaved to false ");
        setUnsavedChanges(false);
      })
      .catch((response) => {
        log.warn(limaLogTag.TaskItemEditV2, "onUpdate: call error", response);
      });
  };

  if (status === "loading") {
    log.info(limaLogTag.TaskItem, "Task info loading: ", data);
    return <div>... getting Task data ...</div>;
  }
  if (status === "error") {
    log.error(limaLogTag.TaskItem, "task data loaded: ", error);
    return <div>error</div>;
  }

  const taskName = (): string => {
    switch (currentTaskData.type) {
      case TaskTypeEnum.TASK:
        return t("tptasks:taskedit.taskitem.tasknameTASKLbl");
      case TaskTypeEnum.NOTE:
        return t("tptasks:taskedit.taskitem.tasknameNOTELbl");
      case TaskTypeEnum.POINT:
        return t("tptasks:taskedit.taskitem.tasknamePOINTLbl");
      default:
        return t("tptasks:taskedit.taskitem.tasknameTASKLbl");
    }
  };

  const taskLogoName = (): string => {
    switch (currentTaskData.type) {
      case TaskTypeEnum.TASK:
        return "TaskSolid";
      case TaskTypeEnum.NOTE:
        return "EditNote";
      case TaskTypeEnum.POINT:
        return "StatusCircleExclamation";
      default:
        return "TaskSolid";
    }
  };

  const onPivotChange = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    log.info(limaLogTag.TaskItem, "on pivot change", item.props.id, ev);
    if (item.props.id === "MESSAGES" || item.props.id === "ACCESS") showSubmitButton && setShowSubmitButton(false);
    else {
      !showSubmitButton && setShowSubmitButton(true);
    }
  };

  return (
    <Stack>
      {currentTaskData._key ? (
        <Pivot aria-label="Acta" onLinkClick={onPivotChange}>
          <PivotItem headerText={taskName()} id={"TASKNAME"} itemIcon={taskLogoName()}>
            <TaskItemEdit2
              taskWithActaXcopy={currentU2Task.task_withActaXcopy}
              taskData={currentTaskData}
              setTaskData={setCurrentTaskData}
              setUnsavedChanges={setUnsavedChanges}
              control={control}
              setValue={setValue}
              isActaOwner={currentU2Task.actaOwner}
            />
          </PivotItem>

          <PivotItem headerText={t("tptasks:taskedit.taskitem.accessPivotLbl")} id={"ACCESS"} itemIcon="ContactLock">
            <AccessComponent itemId={currentTaskData._key} type={LIMA_ITEM_TYPE.TASK} />
          </PivotItem>

          <PivotItem
            headerText={t("tptasks:taskedit.taskitem.messagesPivotLbl") + (messagesCount ? `(${messagesCount})` : "")}
            id={"MESSAGES"}
            itemIcon="Message"
          >
            <MessageComponent
              linkedId={currentTaskData._key}
              linkedType={LIMA_ITEM_TYPE.TASK}
              onAddMessageClb={() => setMessagesCount(messagesCount + 1)}
            />
          </PivotItem>

          <PivotItem
            headerText={t("tptasks:taskedit.taskitem.attachmentsPivotLbl") + (attchCount ? `(${attchCount})` : "")}
            id={"ATTACHMENTS"}
            itemIcon="Attach"
          >
            <AttachementComponent
              attKeys={currentTaskData.taskData.attachements}
              setUnsavedChanges={setUnsavedChanges}
              setTaskDataAttCallBack={(newAtts: string[]) => {
                log.info(limaLogTag.TaskItem, "setTaskDataAttCallBack: ", newAtts);
                log.info(limaLogTag.TaskItem, "setTaskDataAttCallBack: ", {
                  ...currentTaskData,
                  taskData: { ...currentU2Task.task_withActaXcopy.taskData, attachements: newAtts },
                });
                setCurrentTaskData({
                  ...currentTaskData,
                  taskData: { ...currentU2Task.task_withActaXcopy.taskData, attachements: newAtts },
                });
                setAttchCount(newAtts.length);
              }}
            />
          </PivotItem>

          <PivotItem
            headerText={
              t("tptasks:taskedit.taskitem.linkedPivotLbl") + (linkedTasksCount ? `(${linkedTasksCount})` : "")
            }
            id={"TasksLinked"}
            itemIcon="Link"
          >
            <Task2TaskComponent
              taskId={currentTaskData._key}
              updateTaskLinkedCount={(count: number) => setLinkedTasksCount(count)}
            />
          </PivotItem>

          <PivotItem headerText={t("tptasks:taskedit.taskitem.historyPivotLbl")} id={"TasksHistory"} itemIcon="History">
            <TaskHistoryComponent taskId={currentTaskData._key} />
          </PivotItem>
        </Pivot>
      ) : (
        <TaskItemEdit2
          taskWithActaXcopy={currentU2Task.task_withActaXcopy}
          taskData={currentTaskData}
          setTaskData={setCurrentTaskData}
          setUnsavedChanges={setUnsavedChanges}
          control={control}
          setValue={setValue}
          isActaOwner={currentU2Task.actaOwner}
        />
      )}

      <Stack.Item>
        <PrimaryButton
          disabled={!unsavedChanges}
          text={
            currentTaskData._key ? t("tptasks:taskedit.taskitem.updateBtn") : t("tptasks:taskedit.taskitem.createBtn")
          }
          onClick={() => onUpdate()}
        />
      </Stack.Item>
    </Stack>
  );
};

export default TaskItem;

//----------------------
//   REACT QUERY
//----------------------

const getTaskData = async (userId: string, user2taskId: string): Promise<User2Task_withActaXcopy> => {
  log.info(limaLogTag.TaskItem, "fetching data getTaskData", userId, user2taskId);
  if (user2taskId == undefined || user2taskId == null || user2taskId == ":taskid" || !userId) {
    log.warn(limaLogTag.TaskItem, "getTaskData: no task id or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  const loaderID = limaLoadeStore.add("Getting getTaskData from DB");
  try {
    const response = await httpGetAuth<User2Task_withActaXcopy>(urlGetTask(user2taskId), loaderID.ac);

    log.info(limaLogTag.TaskItem, "fetching data getTaskData response ...");
    if (!response.ok) throw response;
    if (response.parsedBody !== undefined) {
      return response.parsedBody;
    }

    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

const getTaskMessagesCount = async (userId: string, taskId: string, taskType: LIMA_ITEM_TYPE): Promise<number> => {
  log.info(limaLogTag.TaskItem, "fetching data getTaskMessagesCount", userId, taskId, taskType);
  if (userId == undefined || taskId == null || taskType === null) {
    log.warn(limaLogTag.TaskItem, "getTaskMessagesCount: no task id or userid");
    return new Promise((reject) => {
      reject(-1);
    });
  }
  const loaderID = limaLoadeStore.add("Getting getTaskMessagesCount from DB");
  try {
    const response = await httpGetAuth<number>(urlGetMessagesCount(taskId, taskType), loaderID.ac);

    log.info(limaLogTag.TaskItem, "fetching data getTaskMessagesCount response ...");
    if (!response.ok) throw response;
    if (response.parsedBody !== undefined) {
      return response.parsedBody;
    }

    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
