import React = require("react");
import { FunctionComponent, ReactElement, useEffect } from "react";
import { log } from "missionlog";
// import { useTranslation } from "react-i18next";
import { ITooltipHostStyles, TooltipHost, mergeStyleSets } from "@fluentui/react";
import limaStore from "../../store/limaStore";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { limaLogTag } from "../../limaCommon/limaLog";
import Routes from "../Menu/routes";
import limaTimerStore from "../../store/limaTimerStore";
import moment = require("moment");
import { useId } from "@fluentui/react-hooks";

const classNames = mergeStyleSets({
  logoinfo: {
    fontSize: 16,
    cursor: "pointer",
  },
  logotime: {
    marginLeft: 10,
    fontSize: 12,
    cursor: "pointer",
  },
});

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

/**
 * Identify current run info
 * either show running time or version
 * @returns
 */
const RunInfo: FunctionComponent = () => {
  const history = useHistory();
  const tooltipId = useId("toolTip-runinfo");
  const calloutProps = { gapSpace: 0 };

  const onVersionClick = () => {
    log.info(limaLogTag.VersionsInfo, "onVersionClick: Clicked");
    //history.push("/");
    history.push(Routes.LimactaTimerRoute);
  };

  useEffect(() => {
    log.info(limaLogTag.VersionsInfo, "useEffect: mounted");
  }, []);

  // const ShowDebugServer: FunctionComponent = observer(() => {
  //   useEffect(() => {
  //     log.info(limaLogTag.Header, "useEffect: mounde");
  //   });

  const ShowDebugServer = observer((): ReactElement => {
    if (!limaStore.showTestingInfo) return <></>;
    else return <>(server:{limaStore.baseURL})</>;
  });

  // const versionValue = `${limaStore.versions.limaFEVersionId}/${limaStore.versions.limaBEVersionId}/${limaStore.versions.limaAIVersionId}`;
  const content =
    `version info FE:${limaStore.versions.limaFEVersionId} / BE:${limaStore.versions.limaBEVersionId} / AI:${limaStore.versions.limaAIVersionId} ` +
    `user :${limaStore.userEmail} company:${limaStore.userCompanyKey}`;
  // const versionValue = `${limaStore.versions.limaFEVersionId}`;

  const RunInfoValue = observer(() => (
    <>
      <span className={classNames.logoinfo} onClick={onVersionClick}>
        {`Limacta`}
      </span>
      <>
        {limaTimerStore.timerIsActive && (
          <span className={classNames.logotime}>
            {moment.utc(limaTimerStore.getLimactaSpendTime * 1000).format("HH:mm:ss")}
          </span>
        )}
      </>
      <ShowDebugServer />
    </>
  ));

  return (
    <TooltipHost content={content} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <RunInfoValue />
    </TooltipHost>
  );
};

export default RunInfo;
