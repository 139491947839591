import { Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { LimactaComment } from "../../../../../types/LimactaComment";
import CommentLine from "./CommentLine";

interface CommentGroupProps {
  commentGrp: LimactaComment[];
}

const CommentGroup: FunctionComponent<CommentGroupProps> = ({ commentGrp }: CommentGroupProps) => {
  return (
    <Stack>
      {commentGrp.map((item: LimactaComment) => {
        if (item.mainCommnet === true) {
          return <CommentLine key={item._key} comment={item} />;
        } else {
          return <CommentLine key={item._key} comment={item} />;
        }
      })}
    </Stack>
  );
};

export default CommentGroup;
