import { Text, mergeStyleSets, getTheme, ITheme } from "@fluentui/react";
import { ReactElement, ReactNode } from "react";
import React = require("react");

type ILimaSimleTextLinkProps = {
  onClick: () => void;
  children?: ReactNode;
};

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  baseTextLink: [
    fonts.medium,
    {
      color: "#0078D4",
      cursor: "pointer",
      alignSelf: "center",
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  ],
});

export const LimaSimleTextBtn = (props: ILimaSimleTextLinkProps): ReactElement => {
  return (
    // <span >
    <Text variant="small" className={classNames.baseTextLink} onClick={props.onClick}>
      {props.children}
    </Text>
    // </>
  );
};
