import { Dropdown, IDropdownOption } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect } from "react";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { IUser } from "../../../../../types/User";

interface IOnBehalfSaveV4Props {
  onBehalf: string;
  label: string;
  setOnBehalf: (string) => void;
}

const OnBehalfSaveV4: React.FunctionComponent<IOnBehalfSaveV4Props> = (props: IOnBehalfSaveV4Props) => {
  useEffect(() => {
    void limaUsersStore.checkLastRead();
  }, []);

  const OnBehalfDropDown = observer((): JSX.Element => {
    //window.console.log("XXX-> onbehalf observer");
    const list = limaUsersStore.fullList;

    return (
      <Dropdown
        label={props.label}
        selectedKey={props.onBehalf}
        options={list.map((item: IUser) => {
          return { key: item._key, text: item.userName };
        })}
        onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
          if (option !== undefined) {
            console.log("Dropdown onchange to ", option);
            // onStore(option.key.toString());
            props.setOnBehalf(option.key.toString());
          }
          //return option.key;
        }}
      />
    );
  });

  return <OnBehalfDropDown />;
};

export default OnBehalfSaveV4;
