import { PrimaryButton, Stack, Text } from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { LIMAENUM_AccesLicPermIdent } from "../../../../../limaCommon/limaPermissions";
import { createActaXCopy, createXcopyFromXCopy } from "../../../../../limaCommon/limaXCopyFile";
import limaStore from "../../../../../store/limaStore";
import { TaskData, XCOPY_TYPE } from "../../../../../types/Task";
import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../../../types/Task_withActaXcopy";
import CreateLinking from "../../../Edit/Tasks/components/TaskLinking";
import { FileSaveDebugInfo } from "./FileSaveDebugInfo";
import LimaLicPermShow from "../../../../../limaComponents/LimaLicPermShow";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FileSaveNewCopyProps {}

const FileSaveNewCopy: FunctionComponent<FileSaveNewCopyProps> = () => {
  const [copyId, setCopyId] = useState<string>("");
  const [taskData, setTaskData] = useState<Task_withActaXcopy>(Task_withActaXcopy_getNull());
  const { t } = useTranslation();

  useEffect(() => {
    if (limaStore.actaId != null && limaStore.actaId != "") {
      setTaskData({
        ...taskData,
        taskData: {
          ...taskData.taskData,
          actaKey: limaStore.actaId,
          xcopyKey: "",
          commentIds: [],
          paraIds: [],
          xcopytype: XCOPY_TYPE.FULLXCOPY,
        },
      });
    }
  }, []);

  const onCreate = () => {
    if (limaStore.actaIsOpen) {
      if (taskData.taskData !== null && (taskData.taskData.commentIds !== null || taskData.taskData.paraIds !== null)) {
        void createActaXCopy(
          limaStore.actaId,
          taskData.taskData.paraIds,
          taskData.taskData.commentIds,
          taskData.taskData.xcopytype
        ).then((xcopyId: string) => {
          setCopyId(xcopyId);
        });
      } else {
        void createActaXCopy(limaStore.actaId, [], [], taskData.taskData.xcopytype).then((xcopyId: string) => {
          setCopyId(xcopyId);
        });
      }
    } else if (limaStore.xcopyIsOpen) {
      if (taskData.taskData !== null && (taskData.taskData.commentIds !== null || taskData.taskData.paraIds !== null)) {
        void createXcopyFromXCopy(
          limaStore.actaId,
          limaStore.xcopyId,
          taskData.taskData.paraIds,
          taskData.taskData.commentIds,
          taskData.taskData.xcopytype
        ).then((xcopyId: string) => {
          setCopyId(xcopyId);
        });
      } else {
        void createXcopyFromXCopy(limaStore.actaId, limaStore.xcopyId, [], [], taskData.taskData.xcopytype).then(
          (xcopyId: string) => {
            setCopyId(xcopyId);
          }
        );
      }
    }
  };

  return (
    <Stack>
      <Stack.Item>
        <FileSaveDebugInfo taskData={taskData} copyId={copyId} />
      </Stack.Item>
      <Stack.Item>
        <Text variant="medium">
          {t("tpfile:filesave.createnewxcopy.createnewNoteLbl")} {limaStore.actaName}
        </Text>
      </Stack.Item>
      <Stack.Item>
        {limaStore.actaIsOpen || limaStore.xcopyIsOpen ? (
          //  ||
          // limaPermissionStore.checkPermissionIdent(
          //   LIMAUSER_PERMISSIONS_TYPE.ACTA,
          //   LIMAUSER_ACTA_PERMISSIONS.ACTA_SAVE_CREATE_COPY
          // ) ? (
          <LimaLicPermShow
            accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcActaSaveCreateCopy}
            stateOverride={limaStore.IamDocumentOwner}
            noLicComponentShow={
              <Text variant="small">{t("tpfile:filesave.createnewxcopy.missingpermissionsLbl")}</Text>
            }
          >
            <Stack.Item>
              <CreateLinking
                taskData={taskData && taskData.taskData}
                // enableXcopySetup={true}
                // enableDocLinking={false}
                // disableNoCopy={true}
                onlyCreateXcopy={true}
                // setUnsavedChanges={(state: boolean) => setUnsavedChanges(state)}
                itemsLinkingCallBack={(newTaskData: TaskData) => {
                  // setTaskLinkingChanged(true);
                  setTaskData({ ...taskData, taskData: newTaskData });
                  // setUnsavedChanges(true);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton
                text={t("tpfile:filesave.createnewxcopy.createnewxcopyBtnLbl")}
                onClick={() => onCreate()}
              />
            </Stack.Item>
          </LimaLicPermShow>
        ) : (
          <Text variant="small">{t("tpfile:filesave.createnewxcopy.noactaopenLbl")}</Text>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default FileSaveNewCopy;
