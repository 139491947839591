import React = require("react");
import { FunctionComponent, useEffect } from "react";

import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from "office-ui-fabric-react/lib/Styling";
import {
  // FocusZone,
  // FocusZoneDirection,
  List,
  TextField,
  // ScrollablePane,
  // ScrollbarVisibility,
} from "office-ui-fabric-react"; //Icon

import { log } from "missionlog";
import { observer } from "mobx-react-lite";

// import limaStore from "../../../../store/limaStore";
// import { WORDITEMTYPE } from "../../../../api/apilinks";
// import { userAccListDataTypeLinked } from "../../../../dataModels/userAccListDataType";

import limaUsersStore from "../../../../store/limaUsersStore";
import UserListItem from "./UserListItem";
import { IUser } from "../../../../types/User";
import { useMutation } from "react-query";
import { limaLogTag } from "../../../../limaCommon/limaLog";
// import limaLoadeStore from "../../../../store/limaLoaderStore";
// import { urlPATCHorDELETEUser2UserJoin } from "../../../../api/apilinks";
// import { httpDeleteAuth } from "../../../../api/httpAPI";
// import limaStore from "../../../../store/limaStore";
// import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
// import { Visibilitor } from "../../../../types/Visibilitor";
import { User2User } from "../../../../types/User2User";
import { limaQuery_removeUserLinking } from "../../../../api/calls/limaUse2UserCalls";

log.init({ UsersList: "UsersList" });

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

//TODO: Cleanup on styles
const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  edgeId: {
    display: "none",
  },
  listItemContainer: {
    display: "flex",
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      flexGrow: 1,
      display: "flex",
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemCellIcon: [
    getFocusStyle(theme, { inset: -1 }),
    {
      paddingRight: 30,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemCellIconShort: [
    {
      paddingRight: 0,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemContent: {
    marginLeft: 10,
    minHeight: 30,
    overflow: "hidden",
    flexGrow: 1,
  },
  userNameStyle: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
});

//----------------------- COMPONENT --------------------------------------------

const UsersList: FunctionComponent = () => {
  const { mutate: mutateRemoveLinking } = useRemoveUseLinkingQuery();

  const onRenderCell = (item: IUser) => {
    return <UserListItem item={item} onRemove={() => onRemoveLinking(item)} />;
  };

  const onRemoveLinking = (item: IUser) => {
    mutateRemoveLinking(item);
  };

  const DataItemTextArray = observer((): JSX.Element => {
    return <List items={limaUsersStore.list} onRenderCell={onRenderCell} />;
  });

  const ShowData: FunctionComponent = observer(() => {
    log.info(limaLogTag.UsersList, `Will I show something ? [${limaUsersStore.totalItemCount}]`);
    return <>{limaUsersStore.totalItemCount > 0 ? <DataItemTextArray /> : <></>}</>;
  });

  useEffect(() => {
    // void limaUsersStore.getDataFromDB(limaStore.userId, WORDITEMTYPE.user, limaStore.actaId, WORDITEMTYPE.acta);
    //TODO: do we need it?
    limaUsersStore.checkLastRead();
    //if (limaUsersStore.list.length == 0) void limaUsersStore.getDataFromDB();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  return (
    <>
      {/* <FocusZone direction={FocusZoneDirection.vertical}> */}
      <TextField
        label={"Filter by name "} // + resultCountText
        // eslint-disable-next-line react/jsx-no-bind
        onChange={limaUsersStore.onFilterChanged}
      />
      <div className={classNames.wrapper}>
        <ShowData />
      </div>
    </>
  );
};

export default UsersList;

//----------------------
//   REACT QUERY
//----------------------

const useRemoveUseLinkingQuery = () => {
  return useMutation(limaQuery_removeUserLinking, {
    // onMutate: (user: User) => {
    //   // A mutation is about to happen!
    //   log.info(limaLogTag.UsersList, `start removiung `,user);
    //   return { id: 1 };
    // },
    onSuccess: (data: User2User[]) => {
      log.info(limaLogTag.UsersList, "user removed", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};
