import { log } from "missionlog";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import limaStore from "../../store/limaStore";
import { CALLUSERLINK } from "../../taskpane/uiv2/Users/UserInitations/components/User2UserLinksComponentV4";
import { LIMA_ITEM_TYPE } from "../../types/BASE_Enums";
import { ICallUser, ICallUserInvite, ICallUser_withInvite, IUser, IUserInvite } from "../../types/User";
import { User2User } from "../../types/User2User";
import { VisibilitorUser } from "../../types/Visibilitor";
import {
  urlGetUserByEmail,
  urlPOSTUpdateLang,
  urlPOSTUser2UserJoin,
  urlPatchOrDeleteUser,
  urlPostUser,
} from "../apilinks";
import { httpGetAuth, httpPostAuth, httpPutAuth } from "../httpAPI";
import { userExistOK } from "../schema/user/getUserExists";

/**
 * Method used for call Check if user email exists
 * @param param0
 * @returns
 */

export const limaQuery_getUserByEmail = async (userEmail: string): Promise<userExistOK> => {
  log.info(limaLogTag.LimaUserCalls, "fetching data for user by email", userEmail);
  // const [, { patientID }] = params.queryKey;

  if (userEmail === undefined || userEmail === null || userEmail === "") {
    return { exists: null };
  }
  const loaderID = limaLoadeStore.add(`Check user by email`);
  try {
    const response = await httpGetAuth<userExistOK>(urlGetUserByEmail(userEmail), loaderID.ac);
    log.info(limaLogTag.LimaUserCalls, "fetching data getUserByEmail response", response);
    if (!response.ok) {
      throw response;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return response.parsedBody;
    // }
    // throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * Method used for call creating of new user
 * @param param0
 * @returns
 */

export const limaQuery_postUser = async ({
  userEmail,
  userName,
  invitemessage,
  companyKey,
}: ICallUser_withInvite): Promise<IUser> => {
  log.info(limaLogTag.LimaUserCalls, "fetching data for postUser", userEmail, userName);
  // const [, { patientID }] = params.queryKey;

  if (userEmail === undefined || userEmail === null || userEmail === "") {
    log.warn(limaLogTag.LimaUserCalls, "no email", userEmail, userName);
    return null;
  }

  if (companyKey === undefined || companyKey === null || companyKey === "") {
    log.warn(limaLogTag.LimaUserCalls, "no companyKey", companyKey, userName);
    return null;
  }

  const user: IUserInvite = {
    _id: "",
    _key: "",
    cissuedBy: limaStore.userId,
    ctimeStamp: "",
    dissuedBy: "",
    dtimestamp: "",
    userEmail: userEmail,
    userName: userName,
    lang: null,
    invitemessage: invitemessage,
  };

  const loaderID = limaLoadeStore.add(`ask to create user`);
  try {
    const response = await httpPostAuth<IUser>(urlPostUser(companyKey), user, loaderID.ac);
    log.info(limaLogTag.LimaUserCalls, "fetching data postUser response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    log.error(limaLogTag.LimaUserCalls, "ERRORR: fetching data createUser response", error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * methos Used to call to create linkign between users
 * @param otherUserId
 * @returns
 */
export const limaQuery_postUser2UserLink = async ({
  otherUserId,
  invitemessage,
}: ICallUserInvite): Promise<User2User> => {
  log.info(limaLogTag.LimaUserCalls, "fetching data for postUser2UserLink", otherUserId);
  // const [, { patientID }] = params.queryKey;

  if (otherUserId === undefined || otherUserId === null || otherUserId === "") {
    return null;
  }

  const visibilitor: VisibilitorUser = {
    create: null,
    userid: null, //removed by authetnication
    foruser: otherUserId,
    itemid: limaStore.userId,
    itemtype: LIMA_ITEM_TYPE.USER,
    invitemessage: invitemessage,
    state: CALLUSERLINK.ASK,
  };
  const loaderID = limaLoadeStore.add(`ask user to link`);
  try {
    const response = await httpPostAuth<User2User>(urlPOSTUser2UserJoin(), visibilitor, loaderID.ac);
    log.info(limaLogTag.LimaUserCalls, "fetching data postUser2UserLink response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

/**
 * Upadate user
 */
export const limaQuery_patchUser = async ({ userEmail, userName, userId, lang }: ICallUser): Promise<IUser> => {
  log.info(limaLogTag.LimaUserCalls, "fetching data for patchUser", userEmail, userName, userId, lang);
  // const [, { patientID }] = params.queryKey;

  if (
    userId === undefined ||
    userId === null ||
    userId === "" ||
    userEmail === undefined ||
    userEmail === null ||
    userEmail === ""
  ) {
    log.warn(limaLogTag.LimaUserCalls, "no id or email", userEmail, userName, userId);
    return null;
  }

  const user: IUser = {
    _id: "",
    _key: userId,
    cissuedBy: null, //removed by authenticaito limaStore.userId,
    ctimeStamp: "",
    dissuedBy: "",
    dtimestamp: "",
    userEmail: userEmail,
    userName: userName,
    lang: lang,
  };

  const loaderID = limaLoadeStore.add(`ask to patchUser`);
  try {
    const response = await httpPutAuth<IUser>(urlPatchOrDeleteUser(), user, loaderID.ac);
    log.info(limaLogTag.LimaUserCalls, "fetching data postUser response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};

export const limaQuery_setLang = async ({ userId, lang }: ICallUser): Promise<IUser> => {
  log.info(limaLogTag.LimaUserCalls, "fetching data for postUser", userId, lang);
  // const [, { patientID }] = params.queryKey;

  if (userId === undefined || userId === null || userId === "") {
    log.warn(limaLogTag.LimaUserCalls, "no email", userId);
    return null;
  }

  const userLang: IUser = {
    _key: null, //removed by authentication
    lang: lang,
    _id: null,
    cissuedBy: null,
    ctimeStamp: null,
    dissuedBy: null,
    dtimestamp: null,
    userEmail: null,
    userName: null,
  };
  const loaderID = limaLoadeStore.add(`update language`);
  try {
    const response = await httpPostAuth<IUser>(urlPOSTUpdateLang(), userLang, loaderID.ac);
    log.info(limaLogTag.LimaUserCalls, "fetching data postUser response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
};
