import {
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  mergeStyleSets,
  Stack,
  Text,
} from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { urlUpdateXcopyOwnerShip } from "../../../../../api/apilinks";
import { httpPostAuth } from "../../../../../api/httpAPI";
import { actaListOK } from "../../../../../api/schema/getActaList";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { IUser } from "../../../../../types/User";

interface FileItemChangeOwnerProps {
  item: actaListOK;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeItem: (item: any) => void;
}

const classNames = mergeStyleSets({
  changeOwnerInfo: {
    color: "red",
    weight: "700",
  },
});

const FileItemChangeOwner: FunctionComponent<FileItemChangeOwnerProps> = ({
  item,
  changeItem,
}: FileItemChangeOwnerProps) => {
  const [storedError, setStoreError] = useState<boolean>(false);
  const [innerCurrentOwner, setInnerCurrentOwner] = useState<string>();
  const [oldOwnerFinalRole, setOldOwnerFinalRole] = useState<string | undefined>("NONE");
  const { t } = useTranslation(["tpfile"]);

  useEffect(() => {
    setInnerCurrentOwner(item.ownerKey);
  }, []);

  const onStore = () => {
    updateActaOwnership(item.mainXcopy, innerCurrentOwner, item.lastchange, oldOwnerFinalRole)
      .then(() => {
        setStoreError(false);
        changeItem({ ...item, isOwner: false });
        if (limaStore.actaId == item.id) limaStore.setIsOwner(false);
        else if (limaStore.mainXcopyId == item.mainXcopy) limaStore.setIsOwner(false);
      })
      .catch(() => {
        setStoreError(true);
      });
  };

  const onRoleChange = React.useCallback((_ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setOldOwnerFinalRole(option.key);
  }, []);

  return (
    <Stack>
      <Stack.Item>
        {/* Change owner{item.ownerKey} on acta {item.id} now {innerCurrentOwner}({item.lastchange}) */}
        <Text className={classNames.changeOwnerInfo}>
          {t("tpfile:fileopen.changeowner.changeownerNotificationLbl")}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          label={t("tpfile:fileopen.changeowner.changeownerDropdonwnLbl")}
          selectedKey={innerCurrentOwner}
          options={limaUsersStore.fullList.map((item: IUser) => {
            return { key: item._key, text: item.userName };
          })}
          onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (option !== undefined) {
              log.info(limaLogTag.FileItemChangeOwner, "Dropdown onchange to ", option);
              // onStore(option.key.toString());
              setInnerCurrentOwner(option.key.toString());
            }
            //return option.key;
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <ChoiceGroup
          disabled={innerCurrentOwner === item.ownerKey}
          defaultSelectedKey="NONE"
          options={[
            { key: "NONE", text: t("tpfile:fileopen.changeowner.lastOwnerFinalRoleNoneLbl") },
            { key: "VIEWER", text: t("tpfile:fileopen.changeowner.lastOwnerFinalRoleViewerLbl") },
          ]}
          onChange={onRoleChange}
          label={t("tpfile:fileopen.changeowner.changeownerFinalRoleLbl")}
          required={true}
        />
      </Stack.Item>
      <Stack.Item>
        <DefaultButton
          key="btnChangeOwner"
          className="ms-welcome__action"
          onClick={onStore}
          disabled={innerCurrentOwner == item.ownerKey}
        >
          {t("tpfile:fileopen.changeowner.changeownerButtonLbl")}
        </DefaultButton>
      </Stack.Item>
      {storedError && (
        <Stack.Item>
          <Text variant="small">{t("tpfile:fileopen.changeowner.notOwnerNotificationLbl")}</Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FileItemChangeOwner;

//------------
// QUERIES
//------------

const updateActaOwnership = async (
  xcopyId: string,
  newUserId: string,
  timeStamp: string,
  enableOldToSee: string
): Promise<string> => {
  if (!xcopyId || !xcopyId) {
    log.warn(limaLogTag.FileItemChangeOwner, "updateActaOwnership: no task id or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  const loaderID = limaLoadeStore.add("update ownership of document");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  // return new Promise<string>(async (resolve, rejects) => {
  try {
    // let response: HttpResponse<any>;
    const response = await httpPostAuth<string>(
      urlUpdateXcopyOwnerShip(xcopyId),
      {
        userid: null,
        newownerid: newUserId,
        oldownerid: limaStore.userId,
        oldownerrole: enableOldToSee !== "NONE" ? "VIEWER" : null,
        timestamp: timeStamp,
      },
      loaderID.ac
    );

    log.info(limaLogTag.FileItemChangeOwner, "updateActaOwnership: asketo to get something", response);
    if (!response.ok) {
      // throw new Error("Problem geting data");
      log.error(limaLogTag.FileItemChangeOwner, "updateActaOwnership: Problem geting data");
      return Promise.reject();
    }
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.FileItemChangeOwner, "updateActaOwnership: parsedBody is not null", response.parsedBody);
      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        resolve(response.parsedBody);
      });
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.FileItemChangeOwner, "updateActaOwnership: parsedBody isnull");
      return Promise.reject();
    }
  } catch (response) {
    log.error(limaLogTag.FileItemChangeOwner, "updateActaOwnership Error call ", response);
    // throw new Error("Problem geting data");
    log.error(limaLogTag.FileItemChangeOwner, "updateActaOwnership: Problem geting data");
    return Promise.reject();
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
  // });
};
