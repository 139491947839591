import * as React from "react";

import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import {
  DefaultButton,
  // getFocusStyle,
  // getTheme,
  // IconButton,
  // IIconProps,
  // ITheme,
  // mergeStyleSets,
  // ProgressIndicator,
  Separator,
  TextField,
  Text,
} from "office-ui-fabric-react";
import UsersListReq from "./usersListReq";
import limaUsersReqStore from "../../../store/limaUsersReqStore";
// import { observer } from "mobx-react-lite";
// import FoundUserToLink from "./components/linking/FoundUserToLink";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LIMAENUM_AccesLicPermIdent } from "../../../limaCommon/limaPermissions";
// import limaStore from "../../../store/limaStore";
// import limaPermissionStore from "../../../store/limaPermissionStore";
import LimaLicPermShow from "../../../limaComponents/LimaLicPermShow";
// import { userReqLinkingResp } from "../../../dataModels/userAccListDataType";
// import { userLinkingState } from "../../../api/apilinks";

// log.init({ UsersLinkingPage: "Users/UsersLinkingPage" });

const UsersLinkingPage: FunctionComponent = ({}) => {
  // log.info(limaLogTag.UsersLinkingPage, "hello");
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [searchError, setSearchError] = useState<string>("");

  useEffect(() => {
    log.info(limaLogTag.UsersLinkingPage, "mouted");
    limaUsersReqStore.setFoundUsersFromSearch([]);
  }, []);

  const onSearch = () => {
    limaUsersReqStore
      .checkVisibilityStatus(searchEmail)
      .then((result) => {
        log.info(limaLogTag.UsersLinkingPage, `onSearch: search finished `, result);
        setSearchError("");
      })
      .catch(() => {
        setSearchError("ERROR");
      });
  };

  const changeEmailHandler = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchEmail((event.target as HTMLInputElement).value);
  };

  // const ShowLoading = observer((): JSX.Element => {
  //   return (
  //     <>
  //       {limaUsersReqStore.loadingData && (
  //         <ProgressIndicator key="userlist-progress" label="processing" description="communicating with server ..." />
  //       )}
  //     </>
  //   );
  // });

  return (
    <>
      <Separator alignContent="center">Search user to link</Separator>
      <LimaLicPermShow
        accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserAddLinkUser}
        noLicComponentShow={<Text variant="small">Missing permission to add linking user</Text>}
      >
        <TextField label="Search user by email:" required value={searchEmail} onChange={changeEmailHandler} />
        {searchError != "" ? searchError : ""}
        <DefaultButton
          key="btnSearch"
          className="ms-welcome__action"
          iconProps={{ iconName: "FollowUser" }}
          onClick={onSearch}
        >
          Search
        </DefaultButton>
        <div>
          <br />
        </div>
        <Separator alignContent="center">Found user:</Separator>
        {/* <FoundUserToLink /> */}
        <div>
          <br />
        </div>
        <Separator alignContent="center">Linking reqest form users</Separator>
        <UsersListReq />
      </LimaLicPermShow>
    </>
  );
};

export default UsersLinkingPage;
