import { log } from "missionlog";
import { observer } from "mobx-react";
import * as React from "react";
import limaStore from "../../store/limaStore";
import { ITheme, Icon, Stack, getTheme, mergeStyleSets, Text } from "@fluentui/react";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaActasStore from "../../store/limaActasStore";
import { useTranslation } from "react-i18next";
import LimaActionButtonV4 from "../../taskpane/uiv2/Components/LimaActionButtonV4";
import { useEffect } from "react";
import { openNewWordDocument } from "../../limaCommon/limaWordInteract";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaActaWordDocSomeExistTextProps {}

const theme: ITheme = getTheme();
//const { palette, fonts } = theme;
const { palette } = theme;

const classNames = mergeStyleSets({
  limaActaDocCompareWrapper: {
    padding: 5,
    backgroundColor: "#f9f9bb",
  },
  limaActaDocCompareActionWrapper: {
    alignSelf: "flex-end",
    paddingRight: 5,
    minWidth: 120,
  },
  limaActaDocCompareActionnIcon: {
    cursor: "pointer",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});
// const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const LimaActaWordDocSomeExistText: React.FunctionComponent<ILimaActaWordDocSomeExistTextProps> = observer(() => {
  const { t } = useTranslation();

  //----------------
  // REACTIONS
  //---------------
  useEffect(() => {
    limaActasStore.checkLastRead();
  }, []);

  //----------------
  // HANDLERS
  //----------------

  const onOpenNewWord = () => {
    openNewWordDocument();
  };

  const onDismiss = () => {
    limaStore.setWordDocSomeTextOnStart(false);
  };
  //-----------------
  // OBSERVERS
  //-----------------

  const LimaActaWordDocSomeExistTextState = observer((): JSX.Element => {
    log.info(
      limaLogTag.LimaActaWordDocCompare,
      "check on chagnge actaid, xcopyid:",
      limaStore.actaId,
      limaStore.xcopyId
    );

    return (
      <Stack>
        <Stack.Item>
          <Icon iconName="WarningSolid" style={{ color: "#eddb00" }} />
          <Text variant="medium">
            <b>{t("tpfile:onstarttextexist.onstarttextexist")}</b>
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="small">
            <i>{t("tpfile:onstarttextexist.infoLbl")}</i>
          </Text>
        </Stack.Item>
      </Stack>
    );
  });

  const LimaActaWordDocSomeExistTextStateActionIcons2 = observer((): JSX.Element => {
    return (
      <Stack>
        <Stack.Item>
          <LimaActionButtonV4 onClick={() => onOpenNewWord()} toolTipShow={false}>
            {t("tpfile:onstarttextexist.openNewWordBtnLbl")}
          </LimaActionButtonV4>
        </Stack.Item>
        <Stack.Item>
          <LimaActionButtonV4 onClick={onDismiss} toolTipShow={false}>
            {t("tpfile:onstartdiffile.dismissBtnLbl")}
          </LimaActionButtonV4>
        </Stack.Item>
      </Stack>
    );
  });

  //---------------
  // FUNCTIONS
  //----------------

  //---------------
  // RENDERS
  //----------------
  return (
    <Stack horizontal className={classNames.limaActaDocCompareWrapper}>
      <Stack.Item grow={1}>
        <LimaActaWordDocSomeExistTextState />
      </Stack.Item>
      <Stack.Item shrink={1} className={classNames.limaActaDocCompareActionWrapper}>
        <LimaActaWordDocSomeExistTextStateActionIcons2 />
      </Stack.Item>
    </Stack>
  );
});

export default LimaActaWordDocSomeExistText;
