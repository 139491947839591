import { IconButton, IIconProps, mergeStyleSets } from "@fluentui/react";
import { ReactElement } from "react";
import React = require("react");
import { getColorBaseOnBg } from "../../limaCommon/limaColor";

type LimaObjtagProps = {
  text: string;
  tagColor: string;
  remEnabled?: boolean;
  remCallback?: () => void;
  addEnabled?: boolean;
  addCallback?: () => void;
};

const classNames = mergeStyleSets({
  basetagitem: {
    color: "black",
    margin: 5,
    borderRadius: 5,
    display: "inline-flex",
    height: 24,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: "center",
  },
  // baseTagText: {
  //   color: "#fff",
  //   mixBlendMode: "exclusion",
  // },
  actionIcon: {
    icon: { color: "white", fontSize: 36 },
    marginLeft: 10,
    width: 18,
    height: "75%",
    root: {
      selectors: {
        ":hover .ms-Button-icon": {
          color: "red",
        },
      },
    },
    rootHovered: { backgroundColor: "black" },
  },
});

const removeIcon: IIconProps = { iconName: "Clear" };
const addIcon: IIconProps = { iconName: "Add" };

// export const LimaObjtag = ({
//   text,
//   tagColor,
//   remEnabled,
//   remCallback,
//   addEnabled,
//   addCallback,
// }: LimaObjtagProps): ReactElement => {
export const LimaObjtag = (props: LimaObjtagProps): ReactElement => {
  const onRemClickhandler = () => {
    if (props.remEnabled == true && props.remCallback !== undefined) {
      props.remCallback();
    }
  };

  const onAddClickhandler = () => {
    if (props.addEnabled == true && props.addCallback !== undefined) {
      props.addCallback();
    }
  };

  // if (tagColor === null) {
  //   tagColor = "#c2c2c2";
  // }
  // const colorTagRGBA = hex2rgba(tagColor);
  // const colorTag =
  //   colorTagRGBA[0] * 0.299 + colorTagRGBA[1] * 0.587 + colorTagRGBA[2] * 0.114 > 186 ? "#000000" : "#ffffff";

  return (
    <div
      className={classNames.basetagitem}
      style={{
        backgroundColor: props.tagColor,
        color: getColorBaseOnBg(props.tagColor),
        //color: colorTag,
      }}
    >
      <span>{props.text}</span>
      {props.remEnabled && (
        <IconButton iconProps={removeIcon} className={classNames.actionIcon} onClick={onRemClickhandler}></IconButton>
      )}
      {props.addEnabled && (
        <IconButton iconProps={addIcon} className={classNames.actionIcon} onClick={onAddClickhandler}></IconButton>
      )}
    </div>
  );
};
