import React = require("react");
import { FunctionComponent, useEffect } from "react";
import { IStackProps, Stack, mergeStyleSets } from "@fluentui/react";
import limaStore from "../../../store/limaStore";
import { observer } from "mobx-react-lite";
import { SelectedInWord } from "../../../types/wordObjects";
import { advice_textsearch } from "../../../types/advice";
import limaJudgeStore from "../../../store/limaJudgeStore";
import limaActParStore from "../../../store/limaActParStore";
import { loadFileName, officeFile } from "../../../limaCommon/limaWordInteract";
import limaActasStore from "../../../store/limaActasStore";

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { width: "100%" } },
};

const classNames = mergeStyleSets({
  debugWrapper: {
    background: "lightgrey",
  },
});

const UserLoggedId: FunctionComponent = observer(() => {
  return (
    <>
      User: {limaStore.logged && limaStore.userIdent}
      <br />
      Company: [{limaStore.userCompanyKey}]
    </>
  );
});

const FileOpenedId: FunctionComponent = observer(() => {
  return <>File: {limaStore.logged && limaStore.fileOpenIdent}</>;
});

const ActaParaIDSelect: FunctionComponent = observer(() => {
  // console.log("HEADER LIMASTORE ", limaStore);
  // log.info(limaLogTag.Header, "ActaParaIDSelect: LIMASTORE", limaStore);
  return (
    <>
      selecteId:{limaStore.selectedInWord.map((item: SelectedInWord) => `${item.type}:${item.id},`)}
      <br />
      act:{limaStore.actaName}[{limaStore.actaId}/{limaStore.mainXcopyId}]/{limaStore.actaIsOpen && "opened"}
      <br />
      {/* ver:{limaStore.actaName}[{limaStore.actaId}]/({limaStore.docTimeStamp})/{limaStore.versionIsOpen && "opened"}
        <br /> */}
      xcopy:[{limaStore.xcopyId}]/{limaStore.xcopyIsOpen && "opened"}/{limaStore.xcopyIsOpen && limaStore.xcopyType}
      <br />
      type opend: {limaStore.openedDocType} / xcopytype: {limaStore.xcopyType}
      <br />
      timestampt: {limaStore.docTimeStamp}
      <br />
      isMainCopy:{String(limaStore.isMainCopyOpen())} / isAnyWorkingcopyOpen:
      {String(limaStore.isAnyActiveWorkingcopyOpen())}
      <br />
      <br />
      isComparedResultOpen:{String(limaStore.isComparedResultOpen)}
      <br />
      owner:{limaStore.IamDocumentOwner ? "TRUE" : "false"}
      <br />
      judge A/P:{" "}
      {limaJudgeStore.searchActPar.map((item: advice_textsearch) => "§" + item.parID + "[" + item.actID + "],")}
      <br />
      act A/P:{" "}
      {limaActParStore.searchActPar.map((item: advice_textsearch) => "§" + item.parID + "[" + item.actID + "],")}
    </>
  );
  ////judgeA/P: {limaJudgeStore.actId}/{limaJudgeStore.parId} //TODO: rewrite to Array
});

const DocumentLocation: FunctionComponent = observer(() => {
  useEffect(() => {
    // log.info(limaLogTag.Header, "useEffect: DocumentLocation");
    void loadFileName().then((item: officeFile) => {
      // log.info(limaLogTag.Header, "useEffect: DocumentLocation get", item);
      if (item === null) limaStore.setDocLocation("");
      limaStore.setDocLocation(item.fullUrl);
    });
  }, [limaStore.docLocation]);

  return <>LOCATION: {limaStore.docLocation}</>;
});

const OnStartLoadedDocument: FunctionComponent = observer(() => {
  const actaId = limaActasStore.getActaIdFocUnknowXcopy(limaStore.wordDocIdent.limactaDocID);
  const maincopyID = limaActasStore.getMainCopyIdForActaId(actaId);
  const isMainCopyOwner = limaActasStore.isOwnerMainXcopyId(maincopyID);
  const isMainCopy =
    limaStore.wordDocIdent.limactaDocID && limaStore.wordDocIdent.limactaDocID === maincopyID ? true : false;
  const actaName = limaActasStore.getActaNameActaId(actaId);

  return (
    <>
      {`current in LIMACTA setting actID: '${limaStore.actaId}' XcopyId: '${limaStore.xcopyId}' ActaName: '${limaStore.actaName}'`}
      <br />
      {`current open WORD DOC limactaDocID:'${limaStore.wordDocIdent.limactaDocID}' limactaDocType: '${limaStore.wordDocIdent.limactaDocType}'`}
      <br />
      {`Calulcated WORDopenedDOC Acta Id: '${actaId}' name: '${actaName}'`}
      <br />
      {`Calulcated WORDopenedDOC MainCopy isMainCopy: '${String(isMainCopy)}' isOwner: '${String(isMainCopyOwner)}'`}
      <br />
      {`Calulcated WORDopenedDOC xcopyType: NYI`}
      <br />
    </>
  );
});

const DebugInfo: FunctionComponent = observer(() => {
  // useEffect(() => {
  //   log.info(limaLogTag.Header, "useEffect: mounde");
  // });
  if (!limaStore.showTestingInfo) return <></>;
  else
    return (
      <Stack horizontalAlign="start" {...columnProps} className={classNames.debugWrapper}>
        <Stack.Item>
          <UserLoggedId />
        </Stack.Item>
        <Stack.Item>
          <FileOpenedId />
        </Stack.Item>
        <Stack.Item>
          <DocumentLocation />
        </Stack.Item>
        <Stack.Item>
          <ActaParaIDSelect />
        </Stack.Item>
        <Stack.Item>
          <OnStartLoadedDocument />
        </Stack.Item>
      </Stack>
    );
});

export default DebugInfo;
