import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";
import { urlGetAttachement, urlGetAttachementsList, urlStoreAttachement } from "../../../api/apilinks";
import { httpGetAuth, httpPostMulipartAuth, HttpResponse } from "../../../api/httpAPI";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../limaComponents/LimaHideableSection";
import limaLoadeStore from "../../../store/limaLoaderStore";
import limaStore from "../../../store/limaStore";
import { LimaAttachement } from "../../../types/LimaAttachement";
import AttachementAdd from "./components/AttachementAdd";
import AttachmentItem from "./components/AttachementItem";
import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import { useTranslation } from "react-i18next";

interface AttachementComponentProps {
  attKeys: string[];
  setTaskDataAttCallBack: (newAtts: string[]) => void;
  onTaskAttCountClb?: (count: number) => void;
  setUnsavedChanges?: (state: boolean) => void;
}

type storeAttachment = {
  file: File;
  descr: string;
  name: string;
};

const AttachementComponent: FunctionComponent<AttachementComponentProps> = ({
  attKeys,
  setTaskDataAttCallBack,
  onTaskAttCountClb,
  setUnsavedChanges,
}: AttachementComponentProps) => {
  const [attachements, setAttachements] = useState<LimaAttachement[]>([]);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);
  const { t } = useTranslation();

  const { isLoading, error, refetch } = useQuery(["attachements", attKeys], () => fetchAtachements(attKeys), {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      log.error(limaLogTag.AttachementComponent, "useQuery get attachements ", err);
    },
    onSuccess: (data) => {
      log.info(limaLogTag.AttachementComponent, "useQuery get attachements ", data);
      setAttachements(data);
    },
  });

  const { mutate } = useMutation((item: storeAttachment) => storeAttachment(item), {
    onMutate: (variables: storeAttachment) => {
      // A mutation is about to happen!
      log.info(limaLogTag.AttachementComponent, "call mutate ", variables);
      return { id: 1 };
    },
    onSuccess: (data: LimaAttachement) => {
      log.info(limaLogTag.AttachementComponent, "uploaded succes ", data);
      const newAtt: LimaAttachement[] = [...attachements, data];
      setAttachements(newAtt);
      setTaskDataAttCallBack(newAtt.map((item: LimaAttachement) => item._key));
    },
  });

  useEffect(() => {
    log.info(limaLogTag.AttachementComponent, "UEF attKeys: mounted ", attKeys);
    if (attKeys !== undefined && attKeys !== null && attKeys.length > 0) {
      setAttachements([]);
      void refetch();
    } else {
      log.info(limaLogTag.MessageComponent, "moutedbut missing linkedID ");
    }
  }, [attKeys]);

  useEffect(() => {
    if (setUnsavedChanges !== undefined) setUnsavedChanges(true);
    if (onTaskAttCountClb !== undefined) onTaskAttCountClb(attachements.length);
  }, [attachements]);

  if (isLoading == true) {
    //return <>Loading</>;
    return LimaLoadingComponnet(3);
  }

  if (error) {
    log.error(limaLogTag.AttachementComponent, "loaded error: ", error);
    return <>Error Getting data </>;
  }

  const onDownloadHandler = (key: string) => {
    log.info(limaLogTag.AttachementComponent, "start getting item ", key);
    const newWindow = window.open(
      urlGetAttachement(key),
      // "https://app.limacta.com/LimactaApp/Note/get/att/filename:" + key,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const onUpload = (file: File, name: string, descr: string): boolean => {
    log.info(limaLogTag.AttachementComponent, "uploading ", file);
    mutate({ file: file, name: name, descr: descr });
    return true;
  };

  const onRemoveAtt = (attId: string) => {
    const newAttList: LimaAttachement[] = attachements.filter((item: LimaAttachement) => item._key !== attId);
    setAttachements(newAttList);
    setTaskDataAttCallBack(newAttList.map((item: LimaAttachement) => item._key));
  };

  return (
    <>
      <>{attKeys.map((item: string) => item)}</>
      <LimaHideableSection name={t("tptasks:taskedit.taskitem2.attachment.addAttachmentsLbl")}>
        <AttachementAdd
          fileUploaddHandler={onUpload}
          validFiles={validFiles}
          setValidFiles={setValidFiles}
          unsupportedFiles={unsupportedFiles}
          setUnsupportedFiles={setUnsupportedFiles}
        />
      </LimaHideableSection>

      <LimaHideableSection name={t("tptasks:taskedit.taskitem2.attachment.listAttachmentsLbl")}>
        {attachements.map((item: LimaAttachement) => (
          <AttachmentItem
            key={item._key}
            attachement={item}
            onDownloadHandler={() => onDownloadHandler(item._key)}
            onRemoveAtt={() => onRemoveAtt(item._key)}
          />
        ))}
      </LimaHideableSection>
    </>
  );
};

export default AttachementComponent;

//----------------------
//   REACT QUERY
//----------------------

const fetchAtachements = async (attKeys: string[]): Promise<LimaAttachement[]> => {
  log.info(limaLogTag.AttachementComponent, "fetching data fetchAtachements");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting attachements from DB");
  try {
    // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
    const response: HttpResponse<LimaAttachement[]> = await httpGetAuth(urlGetAttachementsList(attKeys), loaderID.ac);
    log.info(limaLogTag.AttachementComponent, "fetching data fetchAtachements response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.AttachementComponent,
          "fetching data fetchAtachements response.parsedBody",
          response.parsedBody
        );
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

const storeAttachment = async (vars: storeAttachment) => {
  const { name, file, descr } = vars;

  const formData: FormData = new FormData();
  // formData.append("attachments", new Blob([JSON.stringify(attachments)], { type: "application/json" }));
  formData.append("userid", limaStore.userId);
  // formData.append("linkedid", null); //NOT IMPLEMETED YET
  // formData.append("linkedtype", null);//NOT IMPLEMETED YET
  formData.append("description", descr);
  formData.append("name", name);
  formData.append("file", file);

  const loaderID = limaLoadeStore.add("Storing attachement");
  try {
    const response: HttpResponse<LimaAttachement> = await httpPostMulipartAuth<LimaAttachement>(
      urlStoreAttachement(),
      formData,
      loaderID.ac,
      true
    );
    if (!response.ok) {
      log.error(limaLogTag.AttachementComponent, "useMutation post message ", response);
      throw response;
    }
    log.info(limaLogTag.AttachementComponent, "fetching post message response.parsedBody", response.parsedBody);

    const newMessage = response.parsedBody;
    log.info(limaLogTag.AttachementComponent, "fetching post message ", newMessage);

    return newMessage;
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};
