import * as React from "react";
import { Route, RouteProps } from "react-router";
import Login from "../../Header/Login";

export type UnprotectedLoginRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

export default function UnprotectedLoginRoute({
  isAuthenticated,
  // authenticationPath,
  ...routeProps
}: UnprotectedLoginRouteProps) {
  // console.log("HEllo looks like not working lofin");
  return (
    <>
      {!isAuthenticated && <Login />}
      <Route {...routeProps} />
    </>
  );
}
