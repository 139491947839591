import { Icon, Stack, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useTranslation } from "react-i18next";
import limaStore from "../../../../store/limaStore";
import { XCOPY_TYPE } from "../../../../types/Task";
import limaActasStore from "../../../../store/limaActasStore";
import limaUsersStore from "../../../../store/limaUsersStore";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { useEffect } from "react";

interface IDetailActaInfoV4Props {
  itemType: XCOPY_TYPE;
  itemId: string;
}

const classNames = mergeStyleSets({
  warningWrapper: {
    padding: 5,
    background: "#fff4cd",
    margin: 3,
    borderRadius: 5,
  },
});

const DetailActaInfoV4: React.FunctionComponent<IDetailActaInfoV4Props> = observer((props: IDetailActaInfoV4Props) => {
  const { t } = useTranslation();

  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    limaUsersStore.checkLastRead();
  }, []);

  //---------------------
  //    HANDLERS
  //---------------------

  //---------------------
  //    OBSERVERS
  //---------------------
  const IsDetailAndOpenSame = observer((): JSX.Element => {
    let info = "Donno";
    let isWarning = false;
    log.info(
      limaLogTag.DetailActaInfo,
      `IsDetailAndOpenSame: checking [${limaStore.xcopyId}]=[${props.itemId}]`,
      limaStore.xcopyId,
      props.itemId,
      props.itemType
    );
    //if (limaStore.menu_fileId === props.itemId) {
    if (limaStore.xcopyId === props.itemId) {
      if (props.itemType === XCOPY_TYPE.MAINXCOPY) {
        info = t("tpfile:fileitemV4.infonote.maincopycurrentloadedLbl");
      } else if (props.itemType === XCOPY_TYPE.FULLXCOPY || props.itemType === XCOPY_TYPE.TRIMXCOPY) {
        info = t("tpfile:fileitemV4.infonote.somecopycurrentloadedLbl");
      }
    } else {
      isWarning = true;
      if (props.itemType === XCOPY_TYPE.MAINXCOPY) {
        info = t("tpfile:fileitemV4.infonote.maincopycurrentNOTloadedLbl");
      } else if (props.itemType === XCOPY_TYPE.FULLXCOPY || props.itemType === XCOPY_TYPE.TRIMXCOPY) {
        info = t("tpfile:fileitemV4.infonote.somecopycurrentNOTloadedLbl");
      }
    }

    return (
      <Stack horizontal className={classNames.warningWrapper}>
        <Stack.Item>{isWarning && <Icon iconName="WarningSolid" style={{ color: "#eddb00" }} />}</Stack.Item>
        <Stack.Item>{info}</Stack.Item>
      </Stack>
    );
  });

  const IsOwnerOfDocument = observer(() => {
    window.console.log(`XXXXXX1 >> itemId: ${props.itemId} type: ${props.itemType}`);
    if (props.itemType === XCOPY_TYPE.MAINXCOPY) {
      log.error(limaLogTag.DetailActaInfo, "This should not happen");
    }

    const acta = limaActasStore.getActaIdForXcopyIdAndXcopyType2(props.itemId, props.itemType, true);

    if (acta === null || typeof acta === "string" || acta.ownerKey === limaStore.userId) {
      return <></>;
    } else {
      return (
        <Stack horizontal className={classNames.warningWrapper}>
          <Stack.Item>{<Icon iconName="WarningSolid" style={{ color: "#eddb00" }} />}</Stack.Item>
          <Stack.Item>
            {t("tpfile:fileitemV4.infonote.isNotOwnerLbl", { ownerName: limaUsersStore.getUserName(acta.ownerKey) })}
          </Stack.Item>
        </Stack>
      );
    }
  });

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      <Stack.Item>
        <IsDetailAndOpenSame />
      </Stack.Item>
      {props.itemType !== XCOPY_TYPE.FULLXCOPY && props.itemType !== XCOPY_TYPE.TRIMXCOPY && (
        <Stack.Item>
          <IsOwnerOfDocument />
        </Stack.Item>
      )}
    </Stack>
  );
});

export default DetailActaInfoV4;
