import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsSaveFile = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      id="Save_icon"
      data-name="Save icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="Path_9096" data-name="Path 9096" transform="translate(3 2.094)" fill="none" strokeLinejoin="round">
        <path d="M0-.094H14l4,4v16H0Z" stroke="none" />
        <path
          d="M 1 0.906494140625 L 1 18.906494140625 L 17 18.906494140625 L 17 4.320713996887207 L 13.58578014373779 0.906494140625 L 1 0.906494140625 M 0 -0.093505859375 L 14 -0.093505859375 L 18 3.906494140625 L 18 19.906494140625 L 0 19.906494140625 L 0 -0.093505859375 Z"
          stroke="none"
          fill="#0078d4"
        />
      </g>
      <g
        id="Rectangle_24222"
        data-name="Rectangle 24222"
        transform="translate(9 2)"
        fill="none"
        stroke="#0078d4"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <rect width="6" height="4" stroke="none" />
        <rect x="0.5" y="0.5" width="5" height="3" fill="none" />
      </g>
      <g
        id="Rectangle_24223"
        data-name="Rectangle 24223"
        transform="translate(6 13)"
        fill="none"
        stroke="#0078d4"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <rect width="12" height="9" stroke="none" />
        <rect x="0.5" y="0.5" width="11" height="8" fill="none" />
      </g>
      <path
        id="Path_9099"
        data-name="Path 9099"
        d="M5.5,13.5h6"
        transform="translate(3.5 2.5)"
        fill="none"
        stroke="#0078d4"
        strokeWidth="1"
      />
      <path
        id="Path_9100"
        data-name="Path 9100"
        d="M5.5,13.5h6"
        transform="translate(3.5 5.5)"
        fill="none"
        stroke="#0078d4"
        strokeWidth="1"
      />
      <path
        id="Path_9101"
        data-name="Path 9101"
        d="M10.134,1.491v1"
        transform="translate(2.866 1.509)"
        fill="none"
        stroke="#0078d4"
        strokeWidth="1"
      />
      <rect id="Rectangle_24224" data-name="Rectangle 24224" width="24" height="24" fill="none" />
    </svg>
  );
};
