import { log } from "missionlog";
import { Stack, Toggle } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");

import { limaLogTag } from "../../../../../limaCommon/limaLog";
import {
  LIMAUSER_ACTA_PERMISSIONS,
  LIMAUSER_COMPANY_PERMISSIONS,
  LIMAUSER_GENERAL_PERMISSIONS,
  LIMAUSER_PERMISSIONS_TYPE,
} from "../../../../../limaCommon/limaPermissions";
import { limaPermissions, limaPermissionsActa, limaPermissionsCompany } from "../../../../../types/UserPermissions";
import limaPermissionStore from "../../../../../store/limaPermissionStore";

interface UserPermissionsListProps {
  permissionsList?: limaPermissions[] | limaPermissionsCompany[] | limaPermissionsActa[];
  permissionsEnum:
    | typeof LIMAUSER_COMPANY_PERMISSIONS
    | typeof LIMAUSER_ACTA_PERMISSIONS
    | typeof LIMAUSER_GENERAL_PERMISSIONS;
  permissionsEnumTYPE: LIMAUSER_PERMISSIONS_TYPE;
  addPermission: (permissionIdent: string, type: LIMAUSER_PERMISSIONS_TYPE) => void;
  removePermission: (permission: limaPermissions | limaPermissionsCompany, type: LIMAUSER_PERMISSIONS_TYPE) => void;
}

const UserPermissionsList: FunctionComponent<UserPermissionsListProps> = ({
  permissionsList,
  permissionsEnum,
  permissionsEnumTYPE,
  addPermission,
  removePermission,
}: UserPermissionsListProps) => {
  // useEffect(() => {
  //   log.info(limaLogTag.UserPermissionsAdd, "PermisionsAddList: on update ", permissionsList,permissionsEnumTYPE);
  // }, [permissionsList]);

  const PermisionsAddList = () => {
    const list = [];
    let stackKey = 0;
    log.info(
      limaLogTag.UserPermissionsAdd,
      "PermisionsAddList: lets start filtering ",
      permissionsList,
      permissionsEnumTYPE,
      permissionsEnum
    );
    for (const permissionItem in permissionsEnum) {
      let foundItems = 0;
      let StackToggle = null;
      log.info(limaLogTag.UserPermissionsAdd, "PermisionsAddList: processing ", permissionItem);
      if (permissionItem.toUpperCase() === "DEFAULT") continue;
      if (
        permissionItem.toUpperCase() === "USER_SUPERADMIN" &&
        !limaPermissionStore.checkPermissionIdent(
          LIMAUSER_PERMISSIONS_TYPE.GENERAL,
          LIMAUSER_GENERAL_PERMISSIONS.USER_SUPERADMIN
        )
      )
        continue;

      if (permissionsList !== undefined && permissionsList.length !== 0) {
        log.info(limaLogTag.UserPermissionsAdd, "PermisionsAddList: lets filter");
        foundItems = permissionsList.filter((item: limaPermissions) => {
          //item.permissionIdent === permissionItem && item.actualState === true
          log.info(limaLogTag.UserPermissionsAdd, "PermisionsAddList: filter", item);
          if (item.permissionIdent === permissionItem && item.actualState === true) {
            StackToggle = (
              <Toggle
                inlineLabel
                label={permissionItem}
                defaultChecked
                onChange={(_ev: React.MouseEvent<HTMLElement, MouseEvent>, _checked?: boolean) => {
                  //!checked && removePermission(item, permissionsEnumTYPE)
                  console.log("REMOVE");
                  removePermission(item, permissionsEnumTYPE);
                }}
              />
            );
            return true;
          }
          return false;
        }).length;
      }

      log.info(limaLogTag.UserPermissionsAdd, "PermisionsAddList: filter finish found:", foundItems);

      list.push(
        <Stack.Item key={stackKey}>
          {foundItems <= 0 ? (
            <Toggle
              inlineLabel
              label={permissionItem}
              onChange={(_ev: React.MouseEvent<HTMLElement, MouseEvent>, _checked?: boolean) => {
                //checked &&
                console.log("ADD");
                addPermission(permissionItem, permissionsEnumTYPE);
              }}
            />
          ) : (
            StackToggle
          )}
        </Stack.Item>
      );
      stackKey++;
    }

    return <>{list}</>;
  };

  return (
    <Stack>
      <PermisionsAddList />
    </Stack>
  );
};

export default UserPermissionsList;
