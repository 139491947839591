import * as React from "react";
import limaStore from "../store/limaStore";

//import { observer } from 'mobx-react-lite';
import { log } from "missionlog";
// import { useTranslation } from "react-i18next";
import { Dropdown, IDropdownOption, IDropdownStyles } from "office-ui-fabric-react";
import { limaLogTag } from "../limaCommon/limaLog";
// import { CallUser, User } from "../types/User";
// import { useMutation } from "react-query";
// import limaUsersStore from "../store/limaUsersStore";
// import { urlPOSTUpdateLang } from "../api/apilinks";
// import { httpPostAuth } from "../api/httpAPI";
// import limaLoadeStore from "../store/limaLoaderStore";
import ReactCountryFlag from "react-country-flag";
import { LANGUAGES } from "../types/User";
import { useEffect } from "react";
// import { limaQuery_setLang } from "../api/calls/limaUserCalls";

//log.init({ Header: "Header" });

// eslint-disable-next-line @typescript-eslint/ban-types
export type ILanguageSwitcherProps = {
  lang?: LANGUAGES;
  onChange: (langKey: LANGUAGES) => void;
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    // width: 78,
  },
};

const getFlagLangCodeFromEnum = (lang: LANGUAGES): string => {
  switch (lang) {
    case LANGUAGES.CZ:
      return "CZ";
    case LANGUAGES.EN:
      return "US";
  }
};

const getFlagLangCodeFromString = (langKey: string): string => {
  switch (langKey) {
    case "cs-CZ":
      return getFlagLangCodeFromEnum(LANGUAGES.CZ);
    case "en":
      return getFlagLangCodeFromEnum(LANGUAGES.EN);
  }
  return getFlagLangCodeFromEnum(LANGUAGES.EN);
};

const getLangFromString = (langKey: string): LANGUAGES => {
  switch (langKey) {
    case "cs-CZ":
      return LANGUAGES.CZ;
    case "en":
      return LANGUAGES.EN;
  }
  return LANGUAGES.EN;
};

const options: IDropdownOption[] = [
  { key: LANGUAGES.CZ, text: "Czech" },
  { key: LANGUAGES.EN, text: "English" },
];

export const LimaLanguageSwitcher: React.FC<ILanguageSwitcherProps> = ({ lang, onChange }: ILanguageSwitcherProps) => {
  //TODO: change React.FC
  // const { mutateAsync: mutateSetUserLang } = useUpdateLanguage();

  //-------------------
  // reactions
  //-------------------
  useEffect(() => {
    log.info(limaLogTag.LanguageSwitcher, `UEF: language on start and onLimaStore`, lang, limaStore.language);
  }, []);
  //-------------------
  // Handlers
  //-------------------
  const changeLanguage = (_ev, option: IDropdownOption) => {
    log.info(
      limaLogTag.LanguageSwitcher,
      `changeLanguage: changing language to ${option.key}`,
      getLangFromString(option.key.toString())
    );
    if (onChange !== undefined) onChange(getLangFromString(option.key.toString()));
    //change language if is current user
  };

  //-------------------
  // Renders
  //-------------------

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    log.info(
      limaLogTag.LanguageSwitcher,
      `onRenderOption: changing language to ${String(option.key)}`,
      option.key,
      getFlagLangCodeFromEnum(getLangFromString(option.key.toString()))
    );
    return (
      <div>
        <ReactCountryFlag
          //countryCode={"CZ"}
          countryCode={getFlagLangCodeFromString(option.key.toString())}
          svg
          style={{
            margin: "0px",
            marginRight: "5px",
            height: "1em",
            width: "1.5em",
            fontSize: "13px",
          }}
          // aria-label={option.text}
          // title={option.text}
        />
        <span>{option.text}</span>
      </div>
    );
  };

  const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];
    return onRenderOption(option);
  };

  return (
    <>
      <Dropdown
        placeholder="lang"
        defaultSelectedKey={lang !== undefined ? lang : limaStore.language}
        options={options}
        onChange={changeLanguage}
        styles={dropdownStyles}
        onRenderOption={onRenderOption}
        onRenderTitle={onRenderTitle}
      />
    </>
  );
};

export default LimaLanguageSwitcher;

//      <button onClick={() => changeLanguage("cs-CZ")}>cz</button>
//<button onClick={() => changeLanguage("en")}>en</button>
