import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsCheckdoc = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="Checkdoc" transform="translate(-291.57 -84.384)">
        <rect
          id="Rectangle_24139"
          data-name="Rectangle 24139"
          width="32"
          height="32"
          transform="translate(291.57 84.384)"
          fill="none"
        />
        <g id="Group_13362" data-name="Group 13362" transform="translate(294.262 85.747)">
          <g id="Group_13280" data-name="Group 13280">
            <path
              id="Path_9040"
              data-name="Path 9040"
              d="M24.3,19.427V6.4L18.838,0H0V27.949l18.3.1"
              fill="#f5f7fa"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
          </g>
          <g id="Group_13363" data-name="Group 13363" transform="translate(-0.578 1)">
            <path
              id="Path_9037"
              data-name="Path 9037"
              d="M301.5,92.836h5.411"
              transform="translate(-296.193 -89.198)"
              fill="#fff"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <path
              id="Path_9038"
              data-name="Path 9038"
              d="M301.5,92.935H314.78"
              transform="translate(-296.193 -83.298)"
              fill="#fff"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <path
              id="Path_9067"
              data-name="Path 9067"
              d="M301.5,92.935H314.78"
              transform="translate(-296.193 -79.298)"
              fill="#fff"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <path
              id="Path_9068"
              data-name="Path 9068"
              d="M301.5,92.935H314.78"
              transform="translate(-296.193 -75.298)"
              fill="#fff"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <path
              id="Path_9069"
              data-name="Path 9069"
              d="M301.5,92.935h9.839"
              transform="translate(-296.193 -71.298)"
              fill="#fff"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
          </g>
          <path
            id="Path_9041"
            data-name="Path 9041"
            d="M308.7,89.919V96.06h5.141"
            transform="translate(-289.541 -89.385)"
            fill="none"
            stroke="#8e8e93"
            strokeWidth="1.5"
          />
        </g>
        <path
          id="Path_9066"
          data-name="Path 9066"
          d="M3855,1269.861l2.974,2.974,6.207-6.207"
          transform="translate(-3542.025 -1160.458)"
          fill="none"
          stroke="#8e8e93"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
