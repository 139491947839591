import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsJudi = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.704 32"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="Judicates" transform="translate(-290.82 -84.384)">
        <rect
          id="Rectangle_24139"
          data-name="Rectangle 24139"
          width="32"
          height="32"
          transform="translate(291.57 84.384)"
          fill="none"
        />
        <g id="Group_13270" data-name="Group 13270" transform="translate(-131.195 -297.939)">
          <g id="Group_13269" data-name="Group 13269" transform="translate(429.108 383.885)">
            <rect
              id="Rectangle_24189"
              data-name="Rectangle 24189"
              width="11.257"
              height="7.336"
              transform="matrix(0.501, -0.865, 0.865, 0.501, 1.158, 10.411)"
              fill="none"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <path
              id="Rectangle_24190"
              data-name="Rectangle 24190"
              d="M0,0H4.037a0,0,0,0,1,0,0V14.447a2.019,2.019,0,0,1-2.019,2.019h0A2.019,2.019,0,0,1,0,14.447V0A0,0,0,0,1,0,0Z"
              transform="translate(9.315 10.966) rotate(-59.909)"
              fill="none"
              stroke="#8e8e93"
              strokeWidth="1.5"
            />
            <line
              id="Line_411"
              data-name="Line 411"
              x2="8.527"
              y2="4.941"
              transform="translate(5.644)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_412"
              data-name="Line 412"
              x2="8.527"
              y2="4.941"
              transform="translate(0 9.74)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_413"
              data-name="Line 413"
              y1="5.461"
              x2="3.165"
              transform="translate(19.457 12.696)"
              fill="none"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
          <rect
            id="Rectangle_24191"
            data-name="Rectangle 24191"
            width="15.489"
            height="2.832"
            transform="translate(424.276 409.929)"
            fill="none"
            stroke="#8e8e93"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_24192"
            data-name="Rectangle 24192"
            width="10.029"
            height="2.832"
            transform="translate(427.005 407.097)"
            fill="none"
            stroke="#8e8e93"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_414"
            data-name="Line 414"
            x2="27.829"
            transform="translate(422.764 412.761)"
            fill="none"
            stroke="#8e8e93"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 32 34.074"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <g id="Acts" transform="translate(-291.57 -83.634)">
    //     <rect
    //       id="Rectangle_24139"
    //       data-name="Rectangle 24139"
    //       width="32"
    //       height="32"
    //       transform="translate(291.57 84.384)"
    //       fill="none"
    //     />
    //     <g id="Group_13268" data-name="Group 13268" transform="translate(-73.236 -296.328)">
    //       <path
    //         id="Path_9026"
    //         data-name="Path 9026"
    //         d="M373.891,410.018h-1.683a2.348,2.348,0,0,1-2.348-2.348V383.06a2.348,2.348,0,0,1,2.348-2.348h19.544v29.306h-12.1"
    //         fill="none"
    //         stroke="#8e8e93"
    //         strokeWidth="1.5"
    //       />
    //       <path
    //         id="Path_9027"
    //         data-name="Path 9027"
    //         d="M373.613,421.49H372.16a2.3,2.3,0,0,1-2.3-2.3v-3.16a2.3,2.3,0,0,1,2.3-2.3h19.592v7.76h-11.43"
    //         transform="translate(0 -11.471)"
    //         fill="none"
    //         stroke="#8e8e93"
    //         strokeWidth="1.5"
    //       />
    //       <path
    //         id="Path_9028"
    //         data-name="Path 9028"
    //         d="M376.037,420.4v7.069l2.847-1.681,2.913,1.746V420.4Z"
    //         transform="translate(-2.146 -14.82)"
    //         fill="none"
    //         stroke="#8e8e93"
    //         strokeWidth="1.5"
    //       />
    //       <line
    //         id="Line_406"
    //         data-name="Line 406"
    //         x2="7.299"
    //         transform="translate(373.121 405.578)"
    //         fill="none"
    //         stroke="#8e8e93"
    //         strokeLinecap="round"
    //         strokeWidth="1.5"
    //       />
    //       <line
    //         id="Line_407"
    //         data-name="Line 407"
    //         x2="5.809"
    //         transform="translate(385.943 405.578)"
    //         fill="none"
    //         stroke="#8e8e93"
    //         strokeLinecap="round"
    //         strokeWidth="1.5"
    //       />
    //       <g id="Group_13268-2" data-name="Group 13268" transform="translate(372.125 384.307)">
    //         <rect
    //           id="Rectangle_24187"
    //           data-name="Rectangle 24187"
    //           width="7.604"
    //           height="4.955"
    //           transform="matrix(0.501, -0.865, 0.865, 0.501, 0.782, 7.033)"
    //           fill="none"
    //           stroke="#8e8e93"
    //           strokeWidth="1.5"
    //         />
    //         <path
    //           id="Rectangle_24188"
    //           data-name="Rectangle 24188"
    //           d="M0,0H2.727a0,0,0,0,1,0,0V9.759a1.363,1.363,0,0,1-1.363,1.363h0A1.363,1.363,0,0,1,0,9.759V0A0,0,0,0,1,0,0Z"
    //           transform="translate(6.292 7.407) rotate(-59.909)"
    //           fill="none"
    //           stroke="#8e8e93"
    //           strokeWidth="1.5"
    //         />
    //         <line
    //           id="Line_408"
    //           data-name="Line 408"
    //           x2="5.76"
    //           y2="3.338"
    //           transform="translate(3.813)"
    //           fill="none"
    //           stroke="#8e8e93"
    //           strokeLinecap="round"
    //           strokeWidth="1.5"
    //         />
    //         <line
    //           id="Line_409"
    //           data-name="Line 409"
    //           x2="5.76"
    //           y2="3.338"
    //           transform="translate(0 6.579)"
    //           fill="none"
    //           stroke="#8e8e93"
    //           strokeLinecap="round"
    //           strokeWidth="1.5"
    //         />
    //         <line
    //           id="Line_410"
    //           data-name="Line 410"
    //           y1="3.689"
    //           x2="2.138"
    //           transform="translate(13.143 8.576)"
    //           fill="none"
    //           stroke="#8e8e93"
    //           strokeLinecap="round"
    //           strokeWidth="1.5"
    //         />
    //       </g>
    //     </g>
    //   </g>
    // </svg>
  );
};
