import * as React from "react";
import { FunctionComponent, useMemo, useState } from "react";
import { XCOPY_TYPE } from "../../../../types/Task";
import limaActasStore from "../../../../store/limaActasStore";
import { LimaHideableSection2 } from "../../../../limaComponents/LimaHideableSection2";
import { actaListOK } from "../../../../api/schema/getActaList";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { compareXcopy2XcopyByActaKey } from "../../../../limaCommon/limaInsertDoc";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";
import { Stack } from "@fluentui/react";
import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";
import EditStates from "../../../Menu/Edit/States/components/EditStates";
import MainCopyNewVersionV4 from "../Save/MainCopyNewVersionV4";
import { LimaDocData } from "../../../../types/docxData";

interface IWorkingCopiesDetailV4Props {
  xcopyId: string;
  xcopyType: XCOPY_TYPE;
}

const WorkingCopiesDetailV4: FunctionComponent<IWorkingCopiesDetailV4Props> = (props) => {
  const [save2MainCopyEnabled, setSave2MainCopyEnabled] = useState<boolean>(false);
  const [compare2MainCopyEnabled, setCompare2MainCopyEnable] = useState<boolean>(false);
  //---------------------
  //    REACTIONS
  //---------------------

  const acta = useMemo((): actaListOK | null => {
    const actaItem = limaActasStore.getActaIdForXcopyIdAndXcopyType2(props.xcopyId, props.xcopyType, true);
    if (actaItem === null) return null;
    if (typeof actaItem === "string") return limaActasStore.getActa(actaItem);
    return actaItem;
  }, [props.xcopyId, props.xcopyType]);

  //---------------------
  //    HANDLERS
  //---------------------

  const onCompareFinish = (data: LimaDocData) => {
    log.info(limaLogTag.WorkingCopiesDetailV4, "onCompareFinish: on click ", data);
    setCompare2MainCopyEnable(true);
    limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY_2_MAINCOPY_COMPARE);
  };

  const onXcopyCompare = () => {
    // eslint-disable-next-line react/prop-types
    log.info(limaLogTag.WorkingCopiesDetailV4, "onXcopyCompare: on click ");
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
    compareXcopy2XcopyByActaKey(acta.id, props.xcopyId, limaStore.userId, loaderID, undefined, onCompareFinish)
      .then(() => {
        log.info(limaLogTag.WorkingCopiesDetailV4, "onSaveToMaincopy: on click ", acta.mainXcopy);
      })
      .finally(() => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.UNKNOWN);
        // limaLoadeStore.remove(loaderID.k);
      });
  };

  const onSaveToMaincopy = () => {
    log.info(limaLogTag.WorkingCopiesDetailV4, "onSaveToMaincopy: on click ", acta.mainXcopy);
    if (save2MainCopyEnabled === false) setSave2MainCopyEnabled(true);
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  if (acta === null) {
    return <LimaHideableSection2 name="Merge to parent - dont found parent" addEnabled={false} isDisabled={true} />;
  }
  //expecting acta id objec

  if (acta.isOwner === true) {
    return (
      <LimaHideableSection2 name="Working copy detail" addEnabled={false} isDisabled={false}>
        <Stack>
          <Stack.Item>document name : {acta.name}</Stack.Item>
          <Stack.Item>
            <LimaActionButtonV4 onClick={onXcopyCompare} toolTipShow={false}>
              Do compare
            </LimaActionButtonV4>
          </Stack.Item>
          {compare2MainCopyEnabled === true && (
            <Stack.Item>
              <EditStates />
            </Stack.Item>
          )}

          <Stack.Item>
            {save2MainCopyEnabled ? (
              <MainCopyNewVersionV4 actaId={acta.id} xcopyId={acta.mainXcopy} actaName={acta.name} />
            ) : (
              <LimaActionButtonV4 onClick={onSaveToMaincopy} toolTipShow={false} disabled={!compare2MainCopyEnabled}>
                Do Save to main copy
              </LimaActionButtonV4>
            )}
          </Stack.Item>
        </Stack>
      </LimaHideableSection2>
    );
  } else {
    return <LimaHideableSection2 name="Merge to parent - you are not owner" addEnabled={false} isDisabled={true} />;
  }
};
export default WorkingCopiesDetailV4;

//----------------------
//   REACT QUERY
//----------------------
