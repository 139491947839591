import { urlCheckDocx, urlStoreDocx3 } from "../api/apilinks";
//import { limaDocIDLabel, limaDocNameLabel, limaDocTypeLabel } from "../limaCommon/limaEnv";
import { httpPostMulipartAuth, updateURL } from "../api/httpAPI";
import { actaDocOK, isActaDocOKDataType } from "../api/schema/getActaDoc";
import { log } from "missionlog";
import limaStore from "../store/limaStore";
// import { createNewActaID } from "./limaSaveDoc";
import { getBase64, InsertDocument2Word } from "./limaInsertDoc";
import { getDocxData } from "./limaFile";
import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
import { limaLogTag } from "./limaLog";
import { onFileOpenHandlerWrapper } from "./limaFileOpen";
import { LoaderListItem } from "../store/limaLoaderStore";
//import { removeEvalParas, removeTechnicalParas } from "./limaToolWordLoader";

log.init({ limaUploadDoc: "limaCommon/limaUploadDoc" });

// --------------------------------------------------------------------------->>>
/* Step 1. compare file's, if exist return actaId */
// --------------------------------------------------------------------------->>>

/**
 * Reading local file and send it to server to check
 * @param file File identified file
 * @param cbDocId callbackID where will be ID stored
 * @returns
 */
//export const uploadFromLocal = (file: File, cbDocId?: any, cbLoader?: any, cbFilePicker?: any) => {
export const uploadFromLocal = (file: File, loaderID: LoaderListItem): Promise<string | null> => {
  log.info(limaLogTag.limaUploadDoc, "uploadFromLocal: start");

  return new Promise((resolve, reject) => {
    //log.info(tag.limaUploadDoc, "uploadFromLocal: start iner promise");
    if (!file) reject;

    const reader = new FileReader(); //Reading local file
    reader.onload = async (readerEvt) => {
      const binaryString: string = readerEvt.target.result as string;
      //log.info(tag.limaUploadDoc, "binary string:", binaryString);

      try {
        void getDocxData(binaryString);
        resolve(await loadDocxFromLocal(btoa(binaryString), loaderID));
        //resolve(await loadDocxFromLocal(binaryString));
      } catch (err) {
        log.error(limaLogTag.limaUploadDoc, "uploadFromLocal: loadDocxFromLocal call error:", err);
        reject;
      }
    };

    reader.onerror = reject;
    //reader.readAsArrayBuffer(file);
    reader.readAsBinaryString(file);
  });
};

//export const loadDocxFromLocal = async (file: string, cbDocId?: any, cbLoader?: any, cbFilePicker?: any) => {
export const loadDocxFromLocal = async (file: string, loaderID: LoaderListItem): Promise<string | null> => {
  //log.info(tag.limaUploadDoc, "loadDocxFromLocal");
  //log.info(tag.limaUploadDoc, "loadDocxFromLocal: binary string:", file);
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  // return new Promise(async (resolve, reject) => {
  const url = updateURL(urlCheckDocx());
  const blob = new Blob([file], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });

  const dataOut = new FormData();
  dataOut.append("file", blob, "output.docx");
  //log.info(tag.limaUploadDoc, "loadDocxFromLocal: binary string:", dataOut);

  await httpPostMulipartAuth<actaDocOK>(url, dataOut, loaderID.ac, true)
    .then((data) => {
      if (data.parsedBody["actaId"] != null) {
        log.info(limaLogTag.limaUploadDoc, "loadDocxFromLocal: recieved data", data.parsedBody["actaId"]);
        return Promise.resolve(data.parsedBody["actaId"]);
      } else {
        log.info(limaLogTag.limaUploadDoc, "loadDocxFromLocal: recieved data null");
        return Promise.resolve(null);
      }
    })
    .catch(() => {
      return Promise.reject();
    });
  return Promise.reject();
};

// --------------------------------------------------------------------------->>>
/* Step 2. compare content from files on server */
// --------------------------------------------------------------------------->>>
/**
 * Method send file to server eitherto Compare or to uplad to server
 * @param file File that will be uploaded to server
 * @param originId ID of acta or null or ""
 * @param storeOrCompare true if Store to server, false if just compare
 * @param newFileName new Filename
 * @param cbCallBack callBack when
 * @param {string?} onBehalf when storing as another user
 * @returns Promise
 *
 * @autor MS (formal FJ)
 */
export function compareOnServer(
  file: File,
  originId: string | null,
  originType: LimaDocTypeENUM,
  comapareType: LimaDocTypeENUM,
  storeOrCompare: STOREorCOMPARE_ENUM,
  newFileName: string,
  loaderID: LoaderListItem,
  cbCallBack?: (data: LimaDocData) => void,
  onBehalf?: string
) {
  log.info(limaLogTag.limaUploadDoc, "compareOnServer()");

  return new Promise((resolve, reject) => {
    log.info(limaLogTag.limaUploadDoc, "uploadFromLocal: start iner promise");
    if (!file) reject;
    const reader = new FileReader(); //Reading local file

    reader.onload = async (readerEvt) => {
      const binaryString: string = readerEvt.target.result as string;
      try {
        resolve(
          // await compareOrUploadDocx(
          //   btoa(binaryString),
          //   originId,
          //   originType,
          //   comapareType,
          //   storeOrCompare,
          //   newFileName,
          //   cbCallBack,
          //   onBehalf
          // )
          await compareOrUploadDocxX3(
            btoa(binaryString),
            originId,
            originType,
            comapareType,
            storeOrCompare,
            newFileName,
            loaderID,
            onBehalf,
            cbCallBack
          )
        );
      } catch (err) {
        log.error(limaLogTag.limaUploadDoc, "uploadFromLocal: loadDocxFromLocal call error:", err);
        reject;
      }
    };

    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}

// async function compareOrUploadDocx(
//   fileAsString: string,
//   originId: string | null,
//   originType: LimaDocTypeENUM,
//   comapareType: LimaDocTypeENUM,
//   store: boolean,
//   newFileName: string,
//   cbCallBack?: (data: LimaDocData) => void,
//   onBehalf?: string
// ) {
//   log.info(limaLogTag.limaUploadDoc, `compareOrUploadDocx() start with actaId[${originId}]`);

//   if (originId === null || originId === "") {
//     log.warn(limaLogTag.limaUploadDoc, `compareOrUploadDocx() will use null as ActaId[${originId}]`);
//     originId = null;
//   }

//   //Store or Compare file
//   const my_file_as_base64 = await compareOrUploadBase642File(
//     fileAsString,
//     originId,
//     originType,
//     comapareType,
//     newFileName,
//     store,
//     onBehalf
//   );
//   localStorage.setItem("actaId", originId);

//   if (store === false)
//     //in case of compare inset into document
//     InsertDocument2Word(my_file_as_base64, originId, originType, newFileName, cbCallBack);
//   else {
//     //in case of store, reload from server
//     void insertDoc(
//       originId,
//       urlActaInsertDoc(originId, limaStore.userId),
//       newFileName,
//       originType, //LimaDocTypeENUM.ACTA,
//       cbCallBack
//     );
//   }
// }

export const compareOrUploadBase642File = async (
  file: string,
  originId: string,
  originType: LimaDocTypeENUM,
  comapareType: LimaDocTypeENUM,
  actaName: string,
  store: boolean,
  loaderID: LoaderListItem,
  onBehalf?: string
): Promise<unknown> => {
  log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642File start");

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  // return new Promise(async (resolve, reject) => {
  //const url = updateURL(urlStoreDocx2());
  const url = updateURL(urlStoreDocx3());
  const blob = new Blob([file], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });

  const dataOut = new FormData();

  dataOut.append("userid", limaStore.userId); //TODO: add as user ID
  dataOut.append("asuserid", limaStore.userId); //TODO: add as user ID
  dataOut.append("actaid", originId);
  dataOut.append("actaname", actaName);
  dataOut.append("baseid", originId);
  dataOut.append("basetype", originType.toUpperCase());
  dataOut.append("newtype", comapareType.toUpperCase());
  log.info(limaLogTag.limaUploadDoc, "loadDocxFromLocal: binary string:", dataOut);
  dataOut.append("file", blob, "output.docx");
  if (onBehalf !== undefined) dataOut.append("onbehalf", onBehalf);
  if (store === true) dataOut.append("store", "true");
  else dataOut.append("store", "false");

  await httpPostMulipartAuth<actaDocOK>(url, dataOut, loaderID.ac, true)
    .then((response) => {
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642File response", response);
      void response.blob().then((blob) => {
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642File blob", blob);
        // resolve(getBase64(blob));
        return Promise.resolve(getBase64(blob, parseInt(response.headers.get("content-data")), true));
      });
    })
    .catch((err) => {
      log.error(limaLogTag.limaUploadDoc, "compareOrUploadBase642File error ", err);
      return Promise.reject();
    });
  // });
  return Promise.reject();
};

//-----------------------------------------
//  NEW VERSION FOR STOREx3
//-------------------------------------------
//TODO: rewrite to cleaner way

export function compareOnServerX3(
  file: File,
  originId: string | null,
  originType: LimaDocTypeENUM,
  comapareType: LimaDocTypeENUM,
  storeOrCompare: STOREorCOMPARE_ENUM,
  newFileName: string,
  loaderID: LoaderListItem,
  onBehalf?: string,
  cbCallBack?: (data: LimaDocData) => void
) {
  log.info(limaLogTag.limaUploadDoc, "compareOnServer()");

  return new Promise((resolve, reject) => {
    log.info(limaLogTag.limaUploadDoc, "compareOnServerX3: start iner promise");
    if (!file) reject;
    const reader = new FileReader(); //Reading local file

    reader.onload = async (readerEvt) => {
      const binaryString: string = readerEvt.target.result as string;
      try {
        log.info(limaLogTag.limaUploadDoc, "compareOnServerX3: start calling");
        resolve(
          await compareOrUploadDocxX3(
            btoa(binaryString), //Buffer.from(binaryString, 'base64'), //btoa(binaryString), //binaryString.toString("base64"), //
            originId,
            originType,
            comapareType,
            storeOrCompare,
            newFileName,
            loaderID,
            onBehalf,
            cbCallBack
          )
        );
      } catch (err) {
        log.error(limaLogTag.limaUploadDoc, "compareOnServerX3: call error:", err);
        reject;
      }
    };

    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}

async function compareOrUploadDocxX3(
  fileAsString: string,
  originId: string | null,
  originType: LimaDocTypeENUM,
  comapareType: LimaDocTypeENUM,
  storeOrCompare: STOREorCOMPARE_ENUM,
  newFileName: string,
  loaderID: LoaderListItem,
  onBehalf?: string,
  cbCallBack?: (data: LimaDocData) => void
) {
  log.info(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: start with actaId[${originId}]`);
  // log.info(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: file as string`, fileAsString);

  if (originId === null || originId === "") {
    log.warn(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: will use null as ActaId[${originId}]`);
    originId = null;
  }

  //Store or Compare file
  const my_file_as_base64: actaDocOK | unknown = await compareOrUploadBase642FileStoreX3(
    fileAsString,
    originId,
    originType,
    comapareType,
    storeOrCompare,
    newFileName,
    loaderID,
    onBehalf
  );
  localStorage.setItem("actaId", originId);

  log.info(
    limaLogTag.limaUploadDoc,
    `compareOrUploadDocxX3: compareOrUploadBase642FileStoreX3 finish with`,
    my_file_as_base64
  );
  if (storeOrCompare === STOREorCOMPARE_ENUM.COMPARE) {
    //in case of compare inset into document
    log.debug(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: compare only [${originId}]`);
    InsertDocument2Word(my_file_as_base64, originId, originType, newFileName, cbCallBack);
    // log.info(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: compare Inser finish [${originId}]`);
  } else if (isActaDocOKDataType(my_file_as_base64)) {
    log.debug(limaLogTag.limaUploadDoc, `compareOrUploadDocxX3: store [${originId}]`);
    onFileOpenHandlerWrapper(my_file_as_base64.actaId, my_file_as_base64.actaName, my_file_as_base64.xcopyId).finally(
      () => {
        // export interface LimaDocData {
        //   limactaDocID: string;
        //   limactaDocType: LimaDocTypeENUM;
        //   limactaDocName: string;
        // }
        cbCallBack({
          limactaDocID: my_file_as_base64.xcopyId,
          limactaDocName: my_file_as_base64.actaName,
          limactaDocType: LimaDocTypeENUM.XCOPY,
        });
      }
    );

    //in case of store, reload from server
    //void insertDoc(originId, urlActaInsertDoc(originId, limaStore.userId), newFileName, originType, cbCallBack);
  }
}

export enum STOREorCOMPARE_ENUM {
  STORE = "store",
  COMPARE = "compare",
}

export const compareOrUploadBase642FileStoreX3 = async (
  fileAsString: string,
  originId: string,
  originType: LimaDocTypeENUM,
  comapareType: LimaDocTypeENUM,
  storeOrCompare: STOREorCOMPARE_ENUM,
  newFileName: string,
  loaderID: LoaderListItem,
  onBehalf?: string
): Promise<actaDocOK | unknown> => {
  log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 start");

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  //return new Promise(async (resolve, reject) => {
  const url = updateURL(urlStoreDocx3());
  const blob = new Blob([fileAsString], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });

  const dataOut = new FormData();

  dataOut.append("userid", limaStore.userId); //TODO: add as user ID
  if (onBehalf !== undefined && onBehalf !== null && onBehalf !== "") dataOut.append("onbehalf", onBehalf);
  dataOut.append("baseid", originId);
  dataOut.append("basetype", originType.toUpperCase());
  dataOut.append("newid", originId);
  dataOut.append("newtype", comapareType.toUpperCase());
  if (newFileName !== null && newFileName !== "") dataOut.append("actaname", newFileName);
  if (storeOrCompare === STOREorCOMPARE_ENUM.STORE) dataOut.append("store", "true");
  else dataOut.append("store", "false");
  //dataOut.append("justdocx", false);
  //dataOut.append("timestamp", ....);
  dataOut.append("file", blob, "output.docx");

  //log.info(tag.limaUploadDoc, "loadDocxFromLocal: binary string:", dataOut);

  try {
    const response = await httpPostMulipartAuth<actaDocOK>(
      url,
      dataOut,
      loaderID.ac,
      storeOrCompare === STOREorCOMPARE_ENUM.STORE ? true : false
    );
    log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 response", response);

    if (storeOrCompare === STOREorCOMPARE_ENUM.STORE) {
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should store ");
      return response.parsedBody;
    } else {
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should comapre ");
      const blob = await response.blob();
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should habe blob ");
      // response.blob().then((blob) => {
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 file and return ", blob);
      // resolve(getBase64(blob));
      const base64 = await getBase64(blob, parseInt(response.headers.get("content-data")), true);
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 file and return out ", base64);
      return base64;
      // })
      // .catch(() => {
      //   log.error(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 error on blob");
      //   return Promise.reject();
      // });
    }
    //dataReply.parsedBody.forEach((item) => { log.info(tag.taskEdit, item) });
  } catch (error) {
    log.error(limaLogTag.limaUploadDoc, "Error", error);

    return null;
  }

  /*
  await httpPostMulipartAuth<actaDocOK>(url, dataOut, loaderID.ac)
    .then(async (response) => {
      log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 response", response);
      // log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 response");
      //in case of store
      if (storeOrCompare === STOREorCOMPARE_ENUM.STORE) {
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should store ");
        return Promise.resolve(response.parsedBody);
      } else {
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should comapre ");
        const blob = await response.blob();
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 should habe blob ");
        // response.blob().then((blob) => {
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 file and return ", blob);
        // resolve(getBase64(blob));
        const base64 = await getBase64(blob, parseInt(response.headers.get("content-data")), true);
        log.info(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 file and return out ", base64);
        return Promise.resolve(base64);
        // })
        // .catch(() => {
        //   log.error(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 error on blob");
        //   return Promise.reject();
        // });
      }
      //return Promise.reject();
    })
    .catch((err) => {
      log.error(limaLogTag.limaUploadDoc, "compareOrUploadBase642FileStoreX3 error", err);
      return Promise.reject();
    });
  //});

  return Promise.reject();*/
};
