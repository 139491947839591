import { log } from "missionlog";
import moment = require("moment");
import { Shimmer, Stack, StackItem } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");
import { useQuery } from "react-query";
import { urlGetActaBasicStatistic } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { BasicStatistic, STAT_ACTIVITY_LIST, STAT_ACTIVITY_TYPE } from "../../../../../types/Statistic";

interface FileInfoBasicStatProps {
  actaId: string;
}

const FileInfoBasicStat: FunctionComponent<FileInfoBasicStatProps> = ({ actaId }: FileInfoBasicStatProps) => {
  // const { status, error, data } = useQuery<BasicStatistic[], Error>(
  //   ["actaBasicStat", { actaid: actaId, userid: limaStore.userId }],
  //   getActaBasicStat
  // );

  const { status, error, data } = useQuery(
    ["messages", { actaid: actaId, userid: limaStore.userId }],
    () => getActaBasicStat(actaId),
    {
      enabled: true,
      onSuccess: (data) => {
        log.info(limaLogTag.FileInfoBasicStat, "useQuery get message ", data);
        // setMessages(data);
      },
    }
  );

  const getData = (type: STAT_ACTIVITY_LIST): string => {
    // log.info(limaLogTag.FileInfoBasicStat, "getData: asketo search", type);
    const found: Array<BasicStatistic> = data.filter((item: BasicStatistic) => {
      // log.info(limaLogTag.FileInfoBasicStat, "getData: filter ?", item.activity, type);
      if (item.activity === type) return true;
      return false;
    });
    // log.info(limaLogTag.FileInfoBasicStat, "getData: found", found);
    let outText = "";
    if (found.length > 0) {
      if (found[0].type === STAT_ACTIVITY_TYPE.NUMBER) {
        outText += `${String(found[0].sum)} in ${String(found[0].count)} round`;
      } else {
        outText += `${String(found[0].count)}`;
      }

      const minDateText = moment(found[0].min).format("YYYY-MM-DD");
      const maxDateText = moment(found[0].max).format("YYYY-MM-DD");
      outText += `(${minDateText}`;
      if (maxDateText !== minDateText) outText += ` - ${maxDateText}`;

      outText += `)`;
    }
    return outText;
  };

  if (status === "loading") {
    log.info(limaLogTag.FileInfoBasicStat, "stat is loading: ", data);
    return (
      <>
        <div>... getting stat ...</div>
        <div>
          <Shimmer width="80%" />
          <Shimmer width="80%" />
          <Shimmer width="80%" />
        </div>
      </>
    );
  }
  if (status === "error") {
    log.error(limaLogTag.FileInfoBasicStat, "stat loaded error: ", error);
    return <>Error Getting data </>;
  }
  // if (status === "success") {
  //   log.info(limaLogTag.FileInfoBasicStat, "stat loaded: ", data);
  //   log.info(limaLogTag.FileInfoBasicStat, "stat loaded: ", getData(STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_OPEN));
  // }

  return (
    <>
      <Stack>
        <StackItem>DOCUMENT OPENED: {getData(STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_OPEN)}</StackItem>
        <StackItem>DOCUMENT STORED: {getData(STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_STORE)}</StackItem>
        <StackItem>DOCUMENT WORD ADD: {getData(STAT_ACTIVITY_LIST.DOCUMENT_WORD_ADD)}</StackItem>
        <StackItem>DOCUMENT WORD DELETE: {getData(STAT_ACTIVITY_LIST.DOCUMENT_WORD_DEL)}</StackItem>
        <StackItem>DOCUMENT COMMENT ADD: {getData(STAT_ACTIVITY_LIST.DOCUMENT_COMMENT_ADD)}</StackItem>
        <StackItem>DOCUMENT COMMENT DELETE: {getData(STAT_ACTIVITY_LIST.DOCUMENT_COMMENT_UPDEL)}</StackItem>
      </Stack>
    </>
  );
};

export default FileInfoBasicStat;

//----------------------
//   REACT QUERY
//----------------------

// async function getActaBasicStat(actaid: string, userid: string) {
async function getActaBasicStat(actaid: string) {
  log.info(limaLogTag.FileInfoBasicStat, "fetching data getActaBasicStat");

  const loaderID = limaLoadeStore.add("Getting stat from DB");
  let response: HttpResponse<BasicStatistic[]>;
  try {
    // response = await httpGet<BasicStatistic[]>(urlGetActaBasicStatistic(actaid, userid), loaderID.ac);
    response = await httpGetAuth<BasicStatistic[]>(urlGetActaBasicStatistic(actaid), loaderID.ac);
    if (!response.ok) {
      // throw response;
      log.error(limaLogTag.FileInfoBasicStat, "getActaBasicStat: resposne not ok");
      return null;
    }
    log.info(limaLogTag.FileInfoBasicStat, "getActaBasicStat: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.FileInfoBasicStat, "getActaBasicStat: parsedBody is not null", response.parsedBody);
      // const userListCopies = response.parsedBody;
      return response.parsedBody;
    } else {
      log.error(limaLogTag.FileInfoBasicStat, "getActaBasicStat: parsedBody is not", response.parsedBody);
      // throw new Error("...No data...");
      return null;
    }
  } catch (error) {
    log.error(limaLogTag.FileInfoBasicStat, "getActaBasicStat Error call", error);
    // throw new Error("Problem geting basic Stat");
    return null;
  } finally {
    //this.setLoadingData(false);
    limaLoadeStore.remove(loaderID.k);
  }
}
