import React = require("react");
import { FunctionComponent } from "react";

import { ProgressIndicator, Separator, Stack, Toggle, ITheme, getTheme, mergeStyleSets } from "office-ui-fabric-react";

import { log, tag } from "missionlog";
import { observer } from "mobx-react";

import ActsList from "./ActsList";
import limaActParStore from "../../../../store/limaActParStore";
import { useTranslation } from "react-i18next";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

log.init({ ActsParaSearchPage: "ActsParaSearchPage" });

const theme: ITheme = getTheme();
const { fonts } = theme;

const classNames = mergeStyleSets({
  itemName: [
    fonts.medium,
    {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
});

const ActsParaSearchPage: FunctionComponent = () => {
  const { t } = useTranslation(["tpadvice"]);

  const onChangeActParsEnable = (ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(tag.ActsParaSearchPage, "onChangeActParsEnable: toggle is", checked ? "checked" : "not checked");
    log.info(tag.ActsParaSearchPage, "onChangeActParsEnable: env", ev);
    if (checked) {
      limaActParStore.setInlinSearch(true);
      void limaActParStore.getActSigns();
    } else {
      limaActParStore.setInlinSearch(false);
    }
  };

  const RenderTopLbl = (): JSX.Element => {
    return (
      <div key="topLbl" className={classNames.itemName}>
        § {limaActParStore.fullList[0].actid} [{limaActParStore.fullList[0].actid} -{" "}
        {limaActParStore.fullList[0].actname}/{limaActParStore.fullList[0].actyear}]
      </div>
    );
  };

  const ShowProgress: FunctionComponent = observer(() => {
    return (
      <>
        {limaActParStore.loadingData && (
          <ProgressIndicator label="Search act paragraph" description="I am searching on server" />
        )}
      </>
    );
  });

  const ShowHeader: FunctionComponent = observer(() => {
    return <>{limaActParStore.totalItemCount > 0 ? <RenderTopLbl /> : <></>}</>;
  });

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.advisorLbl"), t("tpmain:menu.advisor.actsearchLbl")]} />
      <Separator alignContent="center">{t("tpadvice:actsearch.label")}</Separator>
      <Stack horizontal horizontalAlign="center">
        <Toggle
          key="searchActParInstantToggle"
          onText={t("tpadvice:actsearch.searchtoggleonlbl")}
          offText={t("tpadvice:actsearch.searchtoggleofflbl")}
          onChange={onChangeActParsEnable}
          role="checkbox"
          defaultChecked={limaActParStore.isInlineSearchActive}
        />
      </Stack>
      <Stack horizontalAlign="center">
        <ShowHeader />
      </Stack>
      <ShowProgress />
      <Separator alignContent="center">Acts List</Separator>
      <ActsList />
    </>
  );
};

export default ActsParaSearchPage;
