import { log } from "missionlog";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { createOrUpadteTaskWithAtt } from "../../../api/calls/updateTaskData";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import { IAttachmentFileWithInfo } from "../../../types/LimaAttachement";
import { TaskTypeEnum, XCOPY_TYPE } from "../../../types/Task";
import { Task_withActaXcopy } from "../../../types/Task_withActaXcopy";
// import {
//   convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy,
//   Task_withActaXcopy_getNull,
// } from "../../../types/Task_withActaXcopy";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../types/User2Task_withActaXcopy";
import AttachementComponentV4 from "../Tasks/Attachment/AttachementComponentV4";
// import AttachementAddV4 from "../Tasks/Attachment/components/AttachementAddV4";
// import { TaskItemV4 } from "../Tasks/TaskItemV4";
import KnowledgetemV4 from "./KnowledgeItemV4";
import KnowledgeListV4 from "./KnowledgeListV4";
import limaActasStore from "../../../store/limaActasStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IKnowledgePageV4Props {
  countCllBck?: (knowledges: number) => void;
  xcopyId: string;
  xcopyType: XCOPY_TYPE;
}

const KnowledgePageV4: FunctionComponent<IKnowledgePageV4Props> = (props: IKnowledgePageV4Props) => {
  const { t } = useTranslation();
  const [knowCount, setKnowCount] = useState<number>(null);
  const [storeEditEnabled, setStoreEditEnabled] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [listIsLoading, setListIsLoading] = useState<boolean>(true);
  const [startEditvalidFiles, setStartEditValidFiles] = useState<IAttachmentFileWithInfo[]>([]);
  const [showDetailTask, setShowDetailTask] =
    useState<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements | null>(null);

  const [dropdownCleanup, setDropDownCleanup] = useState<number>(0);

  const enableEditStore = useMemo(() => storeEditEnabled, [storeEditEnabled]);

  //--------------
  // Reactions
  //--------------
  useEffect(() => {
    log.info(limaLogTag.KnowledgePageV4, `UEF: on mount `, props);
    if (props.countCllBck !== undefined) props.countCllBck(0);
  }, []);

  useEffect(() => {
    log.info(limaLogTag.KnowledgePageV4, `UEF: on startEditvalidFiles change `, startEditvalidFiles);
    if (startEditvalidFiles.length > 0 && showDetail === false) setShowDetail(true);
  }, [startEditvalidFiles]);

  // useEffect(() => {
  //   setShowDetail(true);
  // }, [showDetailTask]);

  useEffect(() => {
    setStartEditValidFiles([]);

    //count update
    let active = true;
    void load();
    return () => {
      active = false;
    };

    async function load() {
      //if (limaTasksStoreV4.fullTasksList === undefined || limaTasksStoreV4.fullTasksList === null) setKnowledgeList([]);
      if (!active) {
        return;
      }
      await limaTasksStoreV4.checkLastRead();
      log.info(limaLogTag.KnowledgePageV4, "UEF: knowledgeList ", props.xcopyId, xcopyRealKey());
      log.info(limaLogTag.KnowledgePageV4, "UEF: knowledgeList ", limaTasksStoreV4.fullTasksList);
      const list = limaTasksStoreV4.fullTasksList.filter(
        (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
          if (
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey === xcopyRealKey() &&
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.type === TaskTypeEnum.NOTE
          ) {
            return true;
          }
          return false;
        }
      );

      log.info(limaLogTag.KnowledgePageV4, "UEF: knowledgeList ", list);
      //setKnowledgeList(list);
      if (listIsLoading === true) setListIsLoading(false);
      if (list !== null) setKnowCount(list.length);
    }
  }, [props.xcopyId]);

  //--------------
  // Handlers
  //--------------

  const onAddKnowledgeIconHandler = () => {
    //setShowAdd(!showAdd);
    //------------------------------------------------------------------------------------------------------------------ON CLICK
    setShowDetailTask(null);
    setShowDetail(true);
    // setShowDetail(true);
  };

  const onCountCllBck = (count: number) => {
    if (listIsLoading === true) setListIsLoading(false);
    setKnowCount(count);
  };

  const onStoreTask = () => {
    log.info(
      limaLogTag.KnowledgePageV4,
      "onStore: onDetialSaveUpdateCallBack taskdata",
      limaTasksStoreV4.editTask_withActaXcopy
    );
    log.info(
      limaLogTag.KnowledgePageV4,
      "onStore: onDetialSaveUpdateCallBack files",
      limaTasksStoreV4.editTask_withActaXcopy_files
    );
    // window.console.log("ADDDDDDDDDDDDDDDDDDDDDDD");
    void createOrUpadteTaskWithAtt(
      limaTasksStoreV4.editTask_withActaXcopy,
      limaTasksStoreV4.editTask_withActaXcopy_files
    ).then((task: Task_withActaXcopy) => {
      // window.console.log("I HAVE TASK UPDATED", task, filters);
      log.info(limaLogTag.TasksList, "onStore: updated Task", task);
      //STORE FILES TO TASK

      if (
        limaTasksStoreV4.editTask_withActaXcopy._key === null ||
        limaTasksStoreV4.editTask_withActaXcopy._key === ""
      ) {
        window.console.log("I HAVE TASK UPDATED reload DB");
        //TODO: In future simple way - it will be hard
        void limaTasksStoreV4.checkLastRead().then(() => {
          // void limaTasksStoreV4.getTasksListFromDB().then(() => {
          window.console.log("LOAD FINISH - DO IT BETTER like push new");
          // inner_updateLinkedTask();
        });
      } else {
        window.console.log("I HAVE TASK UPDATED single task");
        limaTasksStoreV4.updateSingleTask_withTask_withActaXcopy(task);
        // inner_updateLinkedTask();
      }
    });
  };

  const xcopyRealKey = (): string => {
    if (props.xcopyType === XCOPY_TYPE.FULLXCOPY || props.xcopyType === XCOPY_TYPE.TRIMXCOPY) return props.xcopyId;
    else if (props.xcopyType === XCOPY_TYPE.MAINXCOPY) {
      return limaActasStore.getMainCopyIdForActaId(props.xcopyId);
    }
    return null;
  };

  //--------------
  // Renders
  //--------------
  return (
    <LimaHideableSection2
      name={t("tpfile:fileitemV4.sectionKnowledgeLbl")}
      headerCount={knowCount && String(knowCount)}
      headerCountIsLoading={listIsLoading}
      addCallback={onAddKnowledgeIconHandler}
      // filters={taskFilters}
      addEnabled={true}
      editPanelName={t("tpfile:fileitemV4.sectionKnowledgeLbl")}
      passShowHideToContent={false}
      onDetialSaveUpdateCallBack={() => {
        log.info(limaLogTag.KnowledgePageV4, "onDetialSaveUpdateCallBack: ");
        onStoreTask();
      }}
      onHideVisibleElements={
        <AttachementComponentV4
          attKeys={[]}
          // setUnsavedChanges={setUnsavedChanges}
          onTaskAttCountClb={(attCount: number) => {
            log.info(limaLogTag.KnowledgePageV4, "onTaskAttCountClb: ", attCount);
          }}
          setTaskDataAttCallBack={(newAtts: string[]) => {
            log.info(limaLogTag.KnowledgePageV4, "setTaskDataAttCallBack: ", newAtts);
          }}
          minimal={true}
          onValidFilesUpdateClbk={(files: IAttachmentFileWithInfo[]) => {
            log.info(limaLogTag.KnowledgePageV4, "validatedFiles: ", files);
            setStartEditValidFiles(files);
          }}
          onOpenValidFiles={[]}
          enableUploadSingleFile={false}
          dropdownCleanup={dropdownCleanup}
        />
      }
      editDetail={
        <KnowledgetemV4
          user2task={showDetailTask}
          xcopyKey={props.xcopyId}
          xcopyType={props.xcopyType}
          // task={
          //   showDetailTask
          //     ? convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy(
          //         showDetailTask?.task_withActaXcopyParasCommentsTasksMessagesAttachements
          //       )
          //     : Task_withActaXcopy_getNull()
          // }
          enableStore={setStoreEditEnabled}
          onStartValidFiles={startEditvalidFiles}
        />
      }
      editDetailOpen={showDetail}
      editDetailEnableStore={enableEditStore}
      // editDetailSaveLbl={storeEditSaveLbl}
      onDetailBackCallBack={() => {
        log.info(limaLogTag.KnowledgePageV4, "onDetailCallback: onDetailBackCallBack ");
        setShowDetail(false);
        setStartEditValidFiles([]);
        log.info(limaLogTag.KnowledgePageV4, "onDetailCallback: update ", dropdownCleanup + 1);
        setDropDownCleanup(dropdownCleanup + 1);
        // updateTaskList();
      }}
    >
      <>
        <AttachementComponentV4
          attKeys={[]}
          // setUnsavedChanges={setUnsavedChanges}
          onTaskAttCountClb={(attCount: number) => {
            log.info(limaLogTag.TaskItem, "onTaskAttCountClb: ", attCount);
          }}
          setTaskDataAttCallBack={(newAtts: string[]) => {
            log.info(limaLogTag.TaskItem, "setTaskDataAttCallBack: ", newAtts);
          }}
          minimal={true}
          onValidFilesUpdateClbk={(files: IAttachmentFileWithInfo[]) => {
            log.info(limaLogTag.KnowledgePageV4, "validatedFiles: ", files);
            setStartEditValidFiles(files);
          }}
          enableUploadSingleFile={false}
          dropdownCleanup={dropdownCleanup}
        />
        <KnowledgeListV4
          xcopyId={xcopyRealKey()}
          countCllBck={onCountCllBck}
          onClickHandlerCallBack={(knowledgeTask: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
            setShowDetailTask(knowledgeTask);
            setShowDetail(true);
            //setStartEditValidFiles([]);
          }}
        />
      </>
    </LimaHideableSection2>
  );
};

export default KnowledgePageV4;
