import { Stack, TextField } from "@fluentui/react";
import { log } from "missionlog";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { versionStringToNumbers } from "../../../../../limaCommon/limaVersionHelper";
import { LimaSimpleTextField } from "../../../../../limaComponents/LimaSimpleTextField";

interface INewVersionFormProps {
  changeNoteClb: (newValue: string) => void;
  changeVersionClb: (newValue: string) => void;
  versionLast: number[];
  actaName: string;
}

const NewVersionForm: React.FunctionComponent<INewVersionFormProps> = (props) => {
  const [historyNote, setHistoryNote] = useState<string>("");
  const [versionVal, setVersionVal] = useState<string>();
  // const [versionLast, setVersionLast] = useState<number[]>([0, 0]);
  const { t } = useTranslation();

  //----------------------------
  // Reactions
  //----------------------------
  React.useEffect(() => {
    checkMinimal(null);
  }, [props.versionLast]);

  React.useEffect(() => {
    if (props.changeVersionClb) props.changeVersionClb(versionVal);
  }, [versionVal]);
  //---------------------
  // Handlers
  //---------------------

  const onChangeNote = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    log.info(limaLogTag.NewVersionFormV4, `onVersionTexChange: value`, newValue);
    if (props.changeNoteClb) props.changeNoteClb(newValue);
    setHistoryNote(newValue);
  };

  const onVersionTextChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    log.info(limaLogTag.NewVersionFormV4, `onVersionTexChange: value`, newValue);
    const sampleRegEx = /^[0-9]{0,3}\.?[0-9]{0,3}$/;
    if (sampleRegEx.test(newValue)) {
      setVersionVal(newValue);
      // if (props.changeVersionClb) props.changeVersionClb(newValue);
    }
  };

  const onVersionTextBlur = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const splited = event.currentTarget.value.split(".");
    log.info(limaLogTag.MainCopyNewVersionV4, `onBlur: value`, splited, event);
    let major = "0";
    let minor = "0";
    if (splited.length > 0) {
      if (splited[0] !== "") {
        major = splited[0];
      }
      if (splited.length > 1 && splited[1] !== "") {
        minor = splited[1];
      }
    }
    setVersionVal(checkMinimal([Number(major), Number(minor)]));
  };

  const updateVersion = (event: React.WheelEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    log.info(limaLogTag.MainCopyNewVersionV4, `updateVersion: value`, event);
    log.info(limaLogTag.MainCopyNewVersionV4, `updateVersion: value`, event.deltaX);
    log.info(limaLogTag.MainCopyNewVersionV4, `updateVersion: value`, event.deltaY);
    log.info(limaLogTag.MainCopyNewVersionV4, `updateVersion: value`, event.deltaZ);

    const splited = versionStringToNumbers(versionVal);
    const major = splited[0];
    let minor = Number(splited[1]);
    if (event.deltaY < 0) {
      minor++;
    } else if (event.deltaY > 0 && minor > 0) {
      minor--;
    }

    setVersionVal(checkMinimal([Number(major), minor]));
  };

  const checkMinimal = (version: string | null | number[]): string => {
    if (version === null) {
      setVersionVal(`${String(props.versionLast[0])}.${String(props.versionLast[1] + 1)}`);
      return `${String(props.versionLast[0])}.${String(props.versionLast[1] + 1)}`;
    }
    let versionTemp = [0, 0];
    if (typeof version === "string") {
      const splited = versionVal.split(".");
      versionTemp = [Number(splited[0]), Number(splited[1])];
    } else {
      versionTemp = [version[0], version[1]];
    }

    if (versionTemp[0] === props.versionLast[0] && versionTemp[1] <= props.versionLast[1]) {
      versionTemp[1] = props.versionLast[1] + 1;
    } else if (versionTemp[0] < props.versionLast[0]) {
      versionTemp[0] = props.versionLast[0];
      versionTemp[1] = props.versionLast[1] + 1;
    }
    return `${String(versionTemp[0])}.${String(versionTemp[1])}`;
  };

  return (
    <>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item grow={1}>
            <TextField
              value={versionVal}
              onChange={onVersionTextChange}
              onBlur={onVersionTextBlur}
              onWheel={updateVersion}
            />
          </Stack.Item>
          <Stack.Item grow={3}>
            <LimaSimpleTextField defaultValue={props.actaName} disabled={true} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <LimaSimpleTextField
          placeholder={t("tpfile:fileitemV4.newVersion.placeholder")}
          multiline
          height={"2em"}
          onChange={onChangeNote}
          value={historyNote}
        />
      </Stack.Item>
    </>
  );
};

export default NewVersionForm;
