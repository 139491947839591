import React = require("react");
import { FunctionComponent } from "react";
import limaStore from "../../../store/limaStore";
import ReactCountryFlag from "react-country-flag";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import Routes from "../../Menu/routes";
import { useTranslation } from "react-i18next";

const LangFlag: FunctionComponent = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const LanguageFlag = observer(() => {
    let countryCode = "US";
    let title = "English";

    switch (limaStore.language) {
      case "cs-CZ":
        countryCode = "CZ";
        title = "Čeština";
        break;
      default:
    }

    // if (limaStore.language !== "") {
    //   window.console.log(">>>>>>>>>>>>>>>>>>>AAAAAAAAAAAAAAAAAAAAA", i18n.language, limaStore.language);
    // } else {
    //   window.console.log(">>>>>>>>>>>>>>>>>>>BBBBBBBBBBBBBBB", i18n.language, limaStore.language);
    // }
    if (i18n.language !== limaStore.language) {
      // window.console.log(">>>>>>>>>>>>>>>>>>>1111111111", i18n.language, limaStore.language);
      void i18n.changeLanguage(limaStore.language);
    }

    return (
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          margin: "0px",
          marginRight: "5px",
          height: "1em",
          width: "1.5em",
          fontSize: "13px",
        }}
        aria-label={title}
        title={title}
        onClick={() => {
          history.push(Routes.EditMenuHelperRoute);
        }}
      />
    );
  });

  return <LanguageFlag />;
};

export default LangFlag;
