import { FocusZone, FocusZoneDirection, IIconProps, List, mergeStyleSets, ScrollablePane } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import { TaskTypeEnum } from "../../../types/Task";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../types/User2Task_withActaXcopy";
// import KnowledgeListItemV4 from "./components/2DL_KnowledgeListItemV4";
import TaskListItemV4, { E_TASKPARAMS } from "../Tasks/components/TaskListItemV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IKnowledgeListV4Props {
  xcopyId?: string;
  countCllBck?: (knowledges: number) => void;
  onClickHandlerCallBack?: (task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => void;
}

interface IListKnowledgeProps {
  inner_List: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[];
}

const classNames = mergeStyleSets({
  scrollPane: {
    position: "relative",
    selectors: {
      " .ms-ScrollablePane--contentContainer": {
        position: "relative",
      },
    },
  },
});

const detailIcon: IIconProps = { iconName: "ChevronRightMed" };
// const removeIcon: IIconProps = { iconName: "Clear" };

const KnowledgeListV4: FunctionComponent<IKnowledgeListV4Props> = (props: IKnowledgeListV4Props) => {
  const [knowledgeList, setKnowledgeList] = useState<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>(
    []
  );

  useEffect(() => {
    let active = true;
    void load();
    return () => {
      active = false;
    };

    async function load() {
      if (limaTasksStoreV4.fullTasksList === undefined || limaTasksStoreV4.fullTasksList === null) setKnowledgeList([]);
      await limaTasksStoreV4.checkLastRead();
      log.info(limaLogTag.KnowledgeListV4, "UEF: knowledgeList ", props.xcopyId);
      log.info(limaLogTag.KnowledgeListV4, "UEF: knowledgeList ", limaTasksStoreV4.fullTasksList);
      const list = limaTasksStoreV4.fullTasksList.filter(
        (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements) => {
          if (
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.xcopyKey === props.xcopyId &&
            item.task_withActaXcopyParasCommentsTasksMessagesAttachements.type === TaskTypeEnum.NOTE
          ) {
            return true;
          }
          return false;
        }
      );

      log.info(limaLogTag.KnowledgeListV4, "UEF: knowledgeList ", list);
      if (!active) {
        return;
      }
      setKnowledgeList(list);
    }
  }, [props.xcopyId]);

  useEffect(() => {
    log.info(limaLogTag.KnowledgeListV4, "UEF: knowledgeList change ", knowledgeList);
    if (props.countCllBck !== undefined) props.countCllBck(knowledgeList.length);
  }, [knowledgeList]);

  const onRenderCell = (item: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements): JSX.Element => {
    log.info(limaLogTag.KnowledgeListV4, `onRenderCell: on render `, item);
    // return <KnowledgeListItemV4 item={item} />;
    return (
      <TaskListItemV4
        item={item.task_withActaXcopyParasCommentsTasksMessagesAttachements}
        hideTaskParams={[E_TASKPARAMS.CREATEDATE, E_TASKPARAMS.DUEDATE, E_TASKPARAMS.DETAILINFOBITS]}
        actionsList={[
          // {
          //   action_ClickClb: () => onRemoveLinking(item.task_withActaXcopyParasCommentsTasksMessagesAttachements._key),
          //   action_icon: removeIcon,
          //   disabled: false,
          //   label: "Remove from list",
          //   tooltip: "Remove item from your task list",
          // },
          {
            action_ClickClb: () => {
              log.info(limaLogTag.TasksList, `TaskListItem clicked `, item);
              //------------------------------------------------------------------------------------------------------------------
              if (props.onClickHandlerCallBack !== undefined) props.onClickHandlerCallBack(item);
            },
            action_icon: detailIcon,
            disabled: false,
            label: "Show detail",
            tooltip: "Show task detail",
          },
        ]}
      />
    );
  };

  const KnowledgeListV4List = ({ inner_List }: IListKnowledgeProps) => {
    log.info(limaLogTag.KnowledgeListV4, `TaskListV4List: redraw tasklist`);
    return (
      <FocusZone direction={FocusZoneDirection.vertical}>
        <ScrollablePane className={classNames.scrollPane}>
          <List items={inner_List} onRenderCell={onRenderCell} />
        </ScrollablePane>
      </FocusZone>
    );
  };

  return (
    <>
      <KnowledgeListV4List inner_List={knowledgeList} />
    </>
  );
};

export default KnowledgeListV4;
// //----------------------
// //   REACT QUERY
// //----------------------

// async function getKnowledgesForXcopy(
//     itemid: string,
//     _itemtype: WORDITEMTYPE,
//   ) {
//     // const [, { itemid, userid, datefrom, dateto }] = params.queryKey;

//     if (itemid === null) {
//       log.info(limaLogTag.KnowledgeListV4, "getTasksForPara: itemid is null");
//       return null;
//     }
//     if (datefrom === null || dateto === null) {
//       log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: datefrom is null or dateto is null");
//       return null;
//     }
//     const loaderID = limaLoadeStore.add("Getting getTasksForPara from DB");
//     let response: HttpResponse<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>;
//     try {
//       response = await httpGetAuth<User2Task_withActaXcopyParasCommentsTasksMessagesAttachements[]>(
//         urlGetSimpleTaskList(itemid, true, true),
//         loaderID.ac
//       );
//       log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: asketo to get something", response);
//       if (response.parsedBody !== undefined) {
//         log.info(limaLogTag.HistParaItemParaText, "getTasksForPara: parsedBody is not null", response.parsedBody);
//         //   const userListCopies = response.parsedBody;
//         return response.parsedBody;
//       } else {
//         log.error(limaLogTag.HistParaItemParaText, "getTasksForPara Error no data", response);
//         // throw new Error("...No data...");
//         return null;
//       }
//     } catch (response) {
//       log.error(limaLogTag.HistParaItemParaText, "getTasksForPara Error call get data", response);
//       // throw new Error("Problem geting data");
//       return null;
//     } finally {
//       //this.setLoadingData(false);
//       limaLoadeStore.remove(loaderID.k);
//     }
//   }
