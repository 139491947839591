import { getFocusStyle, getTheme, ITheme, mergeStyleSets } from "@fluentui/react";

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

export const classNamesCommon = mergeStyleSets({
  LimaActionIconButton: {
    minWidht: 32,
    minHeight: 32,
    // padding: 2,
    borderRadius: 10,
    display: "flex",
    verticalAlign: "middle",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  LimaActionIconButton_enabled: [
    getFocusStyle(theme),
    {
      cursor: "pointer",
      selectors: {
        "&:hover": {
          //background: palette.neutralLight,
          background: palette.neutralLighter,
        },
      },
    },
  ],
  LimaActionIconiconBase: {
    width: 32,
    color: "#0078d4",
    fontSize: 20,
  },
  LimaActionIconButton_wait: {
    cursor: "wait",
  },
  LimaActionIconButton_disabled: [
    getFocusStyle(theme),
    {
      cursor: "not-allowed",
      /*selectors: {
        "&:hover": {
          //background: palette.neutralLight,
          background: palette.neutralLighter,
        },
      },*/
    },
  ],
  LimaFileLists_listItemWraper: [
    fonts.medium,
    getFocusStyle(theme, { inset: -1 }),
    {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingLeft: 8,
      paddingBottom: 4,
      paddingTop: 4,
      // marginLeft: 8,
      // marginBottom: 8,
      fontWeight: 600,
      fontColor: "#8E8E93",
      cursor: "default",
      borderBottom: "1px solid #e3e3e3",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  LimaFileLists_itemWrapper: {
    flexGrow: 1,
  },
  LimaFileLists_itemIdent: {
    flexGrow: 1,
    paddingLeft: 8,
  },
  LimaFileLists_itemActions: {
    display: "flex",
    flexDirection: "row",
  },
});
