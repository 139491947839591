/**
 * Component shows single file item history timeFrame link
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
import { log } from "missionlog";
import {
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  ITheme,
  mergeStyleSets,
  Stack,
  // TextField,
} from "office-ui-fabric-react";
import { FunctionComponent } from "react";
// import { urlPutActaHistItem } from "../../../../../api/apilinks";
// import Moment from "react-moment";
// import { httpPut, HttpResponse } from "../../../../../api/httpAPI";
// import { Spinner, SpinnerSize } from "@fluentui/react";
import { HistAppendixStateENUM, HistAppendixStateItem } from "./FileItemHistAppendix";
// import { observer } from "mobx-react-lite";
// import limaStore from "../../../../../store/limaStore";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import FileItemOpenHistFileName from "./FileItemOpenHistFileName";
import { XcopyHistory } from "../../../../../types/XcopyHistory";
import LimaFileOpenComponent from "../../../../../limaComponents/LimaFileOpenComponent";
import { XCOPY_TYPE } from "../../../../../types/Task";
import LimaFileGetPDF from "../../../../../limaComponents/LimaFileGetPDF";

// const OpenActaHistoryIcon: IIconProps = { iconName: "Download" };
// const AppendixFromHere: IIconProps = { iconName: "AddNotes" };

log.init({ FileItemOpenHistItem: "FileItemOpenHistItem" });

const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  historyItemsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
  },
  itemFileContainer: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
  itemFileIconsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "20%",
  },
  itemFileIconOpenContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: 45,
  },
  // itemName: {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "80%",
  // },
  // itemNameTextField: {
  //   width: "100%",
  //   marginRight: 20,
  // },
  itemDate: {
    fontWeight: "400",
    fontSize: "10px",
    width: "20%",
  },
  itemStyle: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "80%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyleHistory: {
    paddingLeft: "25px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "20%",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyleMax: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "100%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
});

type FileItemOpenListProp = {
  actaName: string;
  isActaOwner: boolean;
  isLast: boolean;
  historyItem: XcopyHistory;
  xcopyType: XCOPY_TYPE;
  // openFileHandler: (fileId: string, fileName: string, xCopyId?: string, timestamp?: string) => void;
  onFileOpenClickHandler: (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE
  ) => void;
  getAppendix: (itemID: string) => boolean;
  appendixData: HistAppendixStateItem;
};

const FileItemOpenHistItem: FunctionComponent<FileItemOpenListProp> = ({
  actaName,
  historyItem,
  onFileOpenClickHandler,
  // isActaOwner,
  getAppendix,
  xcopyType,
  appendixData,
  isLast,
}: FileItemOpenListProp) => {
  const getIconProp = (): IIconProps => {
    let iconNameOut = "MultiSelect";
    if (appendixData.appendixType === HistAppendixStateENUM.OUT) iconNameOut = "MoreVertical";
    else if (appendixData.appendixType === HistAppendixStateENUM.NOTSETYET) iconNameOut = "MultiSelect";
    else if (appendixData.appendixType === HistAppendixStateENUM.STARTWITHOUTEND) iconNameOut = "SwitcherStartEnd";
    else if (appendixData.appendixType === HistAppendixStateENUM.START) iconNameOut = "GroupedDescending";
    else if (appendixData.appendixType === HistAppendixStateENUM.BETWEEN) iconNameOut = "MultiSelect";
    else if (appendixData.appendixType === HistAppendixStateENUM.END) iconNameOut = "GroupedAscending";

    const out: IIconProps = { iconName: iconNameOut };
    return out;
  };

  return (
    // <div key={historyItem._id} className={classNames.historyItemsContainer}>
    <Stack horizontal>
      <Stack.Item grow={10}>
        <FileItemOpenHistFileName historyItem={historyItem} enableChangeVersions={isLast} />
      </Stack.Item>
      <Stack.Item grow={1} className={classNames.itemFileIconOpenContainer}>
        {!isLast ? (
          <LimaFileOpenComponent
            actaId={historyItem.actaId}
            actaName={actaName}
            xcopyId={historyItem.xcopyId}
            // isActaOwner={isActaOwner}
            historyTimeStamp={historyItem.historyTimeStamp}
            openFileHandler={onFileOpenClickHandler}
            xcopyType={xcopyType}
          />
        ) : (
          <></>
        )}
      </Stack.Item>
      <Stack.Item>
        {isLast ? (
          <LimaFileGetPDF
            actaId={historyItem.actaId}
            xcopyId={historyItem.xcopyId}
            historyTimeStamp={historyItem.historyTimeStamp}
            name={historyItem.historyName}
          />
        ) : (
          <></>
        )}
      </Stack.Item>

      <Stack.Item grow={1}>
        <IconButton
          className={classNames.itemCellIcon}
          iconProps={getIconProp()}
          title={"Get Appendix from here"}
          disabled={appendixData.appendixType === HistAppendixStateENUM.OUT}
          onClick={() => {
            //let state = getAppendix(historyItem.id, setAppendixStateFalse);
            const state = getAppendix(historyItem._id);
            log.info(limaLogTag.FileItemOpenHistItem, " -> onclick state ", state);
            // setAppendixState(state);
          }}
        />
      </Stack.Item>
    </Stack>
    // </div>
  );
};
export default FileItemOpenHistItem;
