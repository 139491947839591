import { Stack } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");
import limaStore from "../../../../store/limaStore";
import { XCOPY_TYPE } from "../../../../types/Task";
import EditStates from "../../../Menu/Edit/States/components/EditStates";
// import FileSaveToXCopy from "../../../Menu/File/FileSave/components/FileSaveToCopy";
// import FileSaveToExists from "../../../Menu/File/FileSave/components/FileSaveToExists";
import MainCopyNewVersionV4 from "../../FileItem/Save/MainCopyNewVersionV4";
import WorkingCopySaveExistV4 from "../../FileItem/Save/WorkingCopySaveExistV4";

interface IFileItemCompareHandlersV4Props {
  onStore: () => void;
  versionLast: number[];
  type: XCOPY_TYPE;
  xcopyId: string;
}

const FileItemCompareHandlersV4: FunctionComponent<IFileItemCompareHandlersV4Props> = (
  props: IFileItemCompareHandlersV4Props
) => {
  return (
    <Stack>
      <Stack.Item>Last version {`[${props.versionLast[0]}.${props.versionLast[1]}]`}</Stack.Item>
      <Stack.Item>
        Store as{" "}
        {`type: ${props.type} - open doc [${String(limaStore.actaIsOpen)}/${props.xcopyId}] open xcopy[${String(
          limaStore.xcopyIsOpen
        )}]`}
      </Stack.Item>
      <Stack.Item>
        <EditStates />
      </Stack.Item>
      {props.type === XCOPY_TYPE.MAINXCOPY && limaStore.xcopyIsOpen && (
        <Stack.Item>
          <MainCopyNewVersionV4 lastVersion={props.versionLast} />
        </Stack.Item>
      )}
      {(props.type === XCOPY_TYPE.FULLXCOPY || props.type === XCOPY_TYPE.TRIMXCOPY) &&
        limaStore.xcopyIsOpen &&
        props.xcopyId !== undefined && (
          <Stack.Item>
            <WorkingCopySaveExistV4 xcopyid={props.xcopyId} />
          </Stack.Item>
        )}

      {/* {limaStore.actaIsOpen && (
        <Stack.Item>
          <FileSaveToExists />
        </Stack.Item>
      )}
      {limaStore.xcopyIsOpen && (
        <Stack.Item>
          <FileSaveToXCopy />
        </Stack.Item>
      )} */}
    </Stack>
  );
};

export default FileItemCompareHandlersV4;
