import * as React from "react";
import { log } from "missionlog";
import { urlActaSearchGet } from "../../../../api/apilinks";
import { httpGetAuth } from "../../../../api/httpAPI";
import { actaListOK } from "../../../../api/schema/getActaList";
import { actaSearchOK } from "../../../../api/schema/getSearchActas";
import { useState, useEffect, FunctionComponent } from "react";
import {
  Toggle,
  TextField,
  mergeStyleSets,
  ScrollablePane,
  ScrollbarVisibility,
  FocusZone,
  FocusZoneDirection,
  Separator,
  ITheme,
  getTheme,
  getFocusStyle,
} from "office-ui-fabric-react";
import { Stack, IStackProps, IStackStyles, IStackTokens } from "office-ui-fabric-react/lib/Stack";
import { DefaultPalette, PrimaryButton } from "office-ui-fabric-react";
import { keyPressHandler } from "../../../../limaCommon/keyboardHandler";
import limaStore from "../../../../store/limaStore";
import { udpateVisilibity, UpdateVisibilityTypes } from "../../../../limaCommon/limaVisibilityCalls";
import { WORDITEMTYPE } from "../../../../api/apilinks";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { limaActaListGet } from "../../../../limaCommon/limaActaList";
import { convertDate } from "../../../../limaCommon/limaCommon";
import LimaFileList from "./components/LimaFileList";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useTranslation } from "react-i18next";
import { LimaHideableSection } from "../../../../limaComponents/LimaHideableSection";

// log.init({ FileOpen: "File/FileOpen" });

const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    color: DefaultPalette.black,
  },
};

const theme: ITheme = getTheme();
const { palette } = theme;

const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 30,
      minHeight: 40,
      verticalAlign: "medium",
    },
  ],
  wrapper: {
    position: "relative",
    display: "flex",
    height: "60vh",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  itemDate: {
    fontWeight: "400",
    fontSize: "10px",
    width: "100%",
  },
  itemStyle: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "80%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyleMax: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "100%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyle2: {
    width: "20%",
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  textAlign: {
    borderBottom: "1px solid lightgrey",
    color: "#000",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "12px",
    fontWeight: "bold",
    justifyContent: "space-between",
    padding: "5px",
  },
  showHideSearch: {
    fontWeight: "bold",
    textAlign: "center",
    selectors: {
      "&:hover": {
        backgroundColor: DefaultPalette.neutralLight,
        cursor: "pointer",
      },
    },
  },
});

const customSpacingStackTokens: IStackTokens = {
  childrenGap: "5%",
  padding: "s1 5%",
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
};

export interface ItemProp {
  message: string;
}

const FileOpen: FunctionComponent = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [fuzzy, setFuzzy] = useState<boolean>(false);
  const [actas, setActas] = useState<actaListOK[]>();
  const [fileItems, setFileItems] = useState<actaListOK[]>();
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [edgeId, setEdgeId] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    void getActaList();
  }, []);

  const remove = (actaId: string, edgeId: string) => {
    log.info(limaLogTag.FileOpen, `remove: actaId['${actaId}'] edgeId ['${edgeId}']`);
    void udpateVisilibity(
      "dUser/" + limaStore.userId,
      "dActa/" + actaId,
      null,
      limaStore.userId,
      null,
      new Date().toISOString(),
      WORDITEMTYPE.acta,
      UpdateVisibilityTypes.COMMON,
      edgeId
    ).then(() => {
      const updatedItems = actas.filter((i) => i.edgeId !== edgeId);
      setActas(updatedItems);
      setFileItems(updatedItems);
    });
  };

  const getActaList = () => {
    setEdgeId(true);
    const loaderID = limaLoadeStore.add("getting acta list");
    // limaActaListGet(limaStore.userId)
    limaActaListGet()
      .then((data: actaListOK[] | void) => {
        log.info(limaLogTag.FileOpen, "getActaList: limaActaListGet finish", data);
        if (data) {
          setActas(data);
          setFileItems(data);
        }
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      })
      .catch(() => {
        log.error(limaLogTag.FileOpen, "getActaList: limaActaListGet finish with error");
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      });
    // .finally(() => {});
  };

  const searchTextChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line prefer-const
    let timer: NodeJS.Timeout;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(ev.target.value);
    }, 250);
  };

  const getActaSearch = async () => {
    //TODO: rewrite
    setEdgeId(false);
    const loaderID = limaLoadeStore.add("Filtering acta list");
    // limaStore.setLoader(true);
    let searchType: string;

    if (!fuzzy) {
      log.info(limaLogTag.FileOpen, "like search");
      searchType = "like";
    } else {
      log.info(limaLogTag.FileOpen, "fuzzy search");
      searchType = "fuzzy";
    }

    try {
      log.info(limaLogTag.FileOpen, "getActaSearch");
      const actaData: actaListOK[] = [];
      const getSearchActas = await httpGetAuth<any[]>(
        urlActaSearchGet("", "", searchText, searchType, ""),
        loaderID.ac
      );
      Object.entries(getSearchActas.parsedBody).map((item: actaSearchOK[]) => {
        item.forEach((res: actaSearchOK) => {
          if (res.aId != undefined) {
            let lastChange: string;
            res.lastChange != undefined ? (lastChange = convertDate(res.lastChange)) : (lastChange = res.lastChange);
            //TODO: correct on search
            actaData.push({
              id: res.aId,
              edgeId: null,
              name: res.aName,
              lastchange: lastChange,
              isOwner: res.isOwner,
              copiesList: [],
            });
          }
        });
      });

      setActas(actaData);
      setFileItems(actaData);
    } catch (response) {
      log.error(limaLogTag.FileOpen, response);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  };

  // function getItems() {
  //   return items;
  // }

  const onFilterChanged = (_: any, text: string): void => {
    // eslint-disable-next-line prefer-const
    let timer: NodeJS.Timeout;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setFileItems(actas.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0));
    }, 400);
  };

  return (
    <div>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.fileLbl"), t("tpmain:menu.fileOpenLbl")]} />
      {/* <Separator alignContent="center">{t("tpfile:fileopen.filelist.searchLbl")}</Separator> */}
      <Stack key="fileOpenMain" tokens={stackTokens} styles={stackStyles}>
        <LimaHideableSection name={t("tpfile:fileopen.filelist.searchLbl")} defaultHidden={true}>
          <Stack key="fileListSearch" horizontal disableShrink tokens={customSpacingStackTokens}>
            <Stack.Item>
              <TextField
                key="textFieldFileOpenSearch"
                onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) =>
                  keyPressHandler(ev, "Enter", () => getActaSearch())
                }
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  searchTextChangeHandler(ev);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <PrimaryButton text={t("tpfile:fileopen.filelist.searchBtn")} onClick={() => getActaSearch()} />
            </Stack.Item>
          </Stack>

          <Stack key="fileListFuzzy" tokens={customSpacingStackTokens}>
            <Stack.Item>
              <Toggle
                label={t("tpfile:fileopen.filelist.fazzysearchTgl")}
                inlineLabel
                onText="On"
                offText="Off"
                onClick={() => {
                  setFuzzy(!fuzzy);
                }}
              />
            </Stack.Item>
          </Stack>
        </LimaHideableSection>
        {/* <Stack key="fileOpenMain" tokens={stackTokens} styles={stackStyles}> */}
        <Stack {...columnProps} key="fileListAll">
          {/* {isOpen == true ? (
            <span className={classNames.showHideSearch} onClick={() => setIsOpen(!isOpen)}>
              Hide Acta Search
            </span>
          ) : (
            <span className={classNames.showHideSearch} onClick={() => setIsOpen(!isOpen)}>
              Show Acta Search
            </span>
          )}
          {isOpen == true ? (
            <>
              
            </>
          ) : (
            <></>
          )} */}
          <Separator alignContent="center">{t("tpfile:fileopen.filelist.listLbl")}</Separator>

          <Stack key="fileListList">
            <FocusZone direction={FocusZoneDirection.vertical}>
              <TextField onChange={onFilterChanged} label={t("tpfile:fileopen.filelist.filterTxtBxLbl")} />

              {edgeId == true ? (
                <div className={classNames.textAlign}>
                  <span>{t("tpfile:fileopen.filelist.nameColLbl")}</span>
                  <span>{t("tpfile:fileopen.filelist.actionsColLbl")}</span>
                </div>
              ) : (
                <div className={classNames.textAlign}>
                  <span>{t("tpfile:fileopen.filelist.nameColLbl")}</span>
                </div>
              )}

              <div className={classNames.wrapper}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  <LimaFileList items={fileItems} remove={remove} />
                </ScrollablePane>
              </div>
            </FocusZone>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default FileOpen;
