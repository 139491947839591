import React = require("react");
import { FunctionComponent } from "react";
import { Stack, Label, IStackStyles, DefaultPalette } from "office-ui-fabric-react";
import { log } from "missionlog";

// import { DefaultButton } from "@fluentui/react";
// import { limaLogTag } from "../../../../limaCommon/limaLog";
// import { selectSameState } from "../../../../limaCommon/limaWordInteract";
import EditStates from "./components/EditStates";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useTranslation } from "react-i18next";

log.init({ Helper: "Helper" });

const stackTokens = { childrenGap: 10 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    color: DefaultPalette.black,
    padding: "15px",
  },
};

const EditStatesPage: FunctionComponent = () => {
  const { t } = useTranslation();
  // const onAcceptState = (acceptOrReject: boolean): void => {
  //   log.info(limaLogTag.EditStatesPage, "onAcceptState: clicked");
  //   selectSameState(acceptOrReject);
  // };

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.editLbl"), t("tpmain:menu.edit.comparerevisionsLbl")]} />
      <Stack key="States" tokens={stackTokens} styles={stackStyles}>
        <Stack.Item>
          <Label>{t("tpmain:comparerevis.processstatesLbl")}</Label>
        </Stack.Item>
        <EditStates />
      </Stack>
    </>
  );
};

export default EditStatesPage;
