import { FontIcon, getTheme, IStackTokens, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { XCOPY_TYPE } from "../../../../types/Task";

interface IWorkingCopiesListItemNameV4Props {
  xcopytype: XCOPY_TYPE;
  xcopyName: string;
  xcopyTitle?: string;
  xcopyCreateTimeStamp?: string;
  useIconNotName?: boolean;
  children?: React.ReactNode;
}

const theme: ITheme = getTheme();
//const { palette, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  itemIcon: {
    fontSize: 16,
    height: 16,
    width: 20,
    margin: "4px 25px",
    cursor: "default",
  },
  items: {
    alignSelf: "center",
    marginLeft: 10,
    flexShrink: 0,
  },
  itemHistoryFileIdent_nameRows: {
    cursor: "default",
  },
  itemHistoryFileIdent_prefix: [
    fonts.medium,
    {
      fontWeight: 400,
      display: "flex",
      alignSelf: "flex-end",
    },
  ],
  itemHistoryFileIdent_name: [
    fonts.mediumPlus,
    {
      fontWeight: 600,
      marginLeft: 10,
      display: "flex",
      alignSelf: "flex-end",
    },
  ],
  itemCopyFileIdent_note: [
    fonts.smallPlus,
    {
      paddingLeft: 40,
      color: "#AEAEB2",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
});

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 2,
};

const WorkingCopiesListItemNameV4: FunctionComponent<IWorkingCopiesListItemNameV4Props> = ({
  xcopytype,
  xcopyName,
  xcopyTitle,
  xcopyCreateTimeStamp,
  useIconNotName,
  children,
}: IWorkingCopiesListItemNameV4Props) => {
  const { t } = useTranslation();
  //------------------
  // Renders
  //-------------------

  const CopyIcon = (): JSX.Element => {
    if (xcopytype != null) {
      if (xcopytype == XCOPY_TYPE.FULLXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="FullCircleMask" title="Full copy" />;
      else if (xcopytype == XCOPY_TYPE.TRIMXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="CircleHalfFull" title="Trimmed copy" />;
      else if (xcopytype == XCOPY_TYPE.MAINXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="Crown" title="Main copy" />;
      else return <FontIcon className={classNames.itemIcon} iconName="StatusCircleBlock" title="no copy" />;
    }
    return <FontIcon className={classNames.itemIcon} iconName="UnknownSolid" title="Unknow copy typ" />;
  };

  const copyPrefix = (): string => {
    if (xcopytype != null) {
      if (xcopytype == XCOPY_TYPE.FULLXCOPY) return t("tpfile:filecopies.fullcopylbl");
      else if (xcopytype == XCOPY_TYPE.TRIMXCOPY) return t("tpfile:filecopies.partialcopylbl");
      else if (xcopytype == XCOPY_TYPE.MAINXCOPY) return t("tpfile:filecopies.maincopylbl");
      else return "Not a copy: ";
    }
    return "Unknown type: ";
  };

  return (
    <Stack className={classNames.itemHistoryFileIdent_nameRows}>
      <Stack.Item>
        <Stack horizontal tokens={stackTokens}>
          {/* <div key="fileContainer" className={classNames.itemFileContainer}>
      <div key="name" className={classNames.itemName}> */}
          <Stack.Item shrink className={classNames.itemHistoryFileIdent_prefix}>
            {useIconNotName === true ? <CopyIcon /> : copyPrefix()}
          </Stack.Item>
          <Stack.Item grow className={classNames.itemHistoryFileIdent_name} title={xcopyTitle ? xcopyTitle : xcopyName}>
            {xcopyName}
            {/* <Label title={xcopyTitle ? xcopyTitle : xcopyName}>{xcopyName}</Label> */}
          </Stack.Item>
          {children && <Stack.Item>{children}</Stack.Item>}
        </Stack>
      </Stack.Item>
      <Stack.Item className={classNames.itemCopyFileIdent_note}>{xcopyCreateTimeStamp}</Stack.Item>
    </Stack>
  );
};

export default WorkingCopiesListItemNameV4;
