import { IStackProps, mergeStyleSets, Stack } from "@fluentui/react";
// import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import React = require("react");
import limaStore from "../../../../store/limaStore";
import { Task_withActaXcopy } from "../../../../types/Task_withActaXcopy";

// export default LimaHideableSection;
type KnowledgetemDebugInfoProps = {
  taskData: Task_withActaXcopy;
  taskCreator: boolean;
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { width: "100%" } },
};

const classNames = mergeStyleSets({
  wrapper: {
    background: "lightgrey",
  },
});

export const KnowledgetemDebugInfo = ({ taskData, taskCreator }: KnowledgetemDebugInfoProps): ReactElement => {
  return (
    <>
      {limaStore.showTestingInfo && (
        <Stack horizontalAlign="start" {...columnProps} className={classNames.wrapper}>
          <Stack.Item>
            <>Key: {taskData && taskData._key}</>
          </Stack.Item>
          <Stack.Item>
            <>ActaKey: {taskData && taskData.taskData && taskData.taskData.actaKey}</>
          </Stack.Item>
          <Stack.Item>
            <>XcopyKey: {taskData && taskData.taskData && taskData.taskData.xcopyKey}</>
          </Stack.Item>
          <Stack.Item>
            <>XcopyType: {taskData && taskData.taskData && taskData.taskData.xcopytype}</>
          </Stack.Item>
          <Stack.Item>
            <>Attachments: {taskData && taskData.taskData && taskData.taskData.attachements}</>
          </Stack.Item>
          <Stack.Item>
            <>taskCreator: {String(taskCreator)}</>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};
