export interface Statistic {
  key: string;
  id: string;
  userKey: string;
  actaKey: string;
  copyKey: string;
  activityIdent: STAT_ACTIVITY_LIST;
  timeStamp: string;
  data: string;
  dataType: string;
  note: string;
}

export enum STAT_ACTIVITY_LIST {
  DOCUMENT_ACCEESS_OPEN = "DOCUMENT_ACCEESS_OPEN",
  DOCUMENT_ACCEESS_STORE = "DOCUMENT_ACCEESS_STORE",
  DOCUMENT_ACCEESS_COMPARE = "DOCUMENT_ACCEESS_COMPARE",
  DOCUMENT_WORD_ADD = "DOCUMENT_WORD_ADD",
  DOCUMENT_WORD_DEL = "DOCUMENT_WORD_DEL",
  DOCUMENT_WORD_CHANGE = "DOCUMENT_WORD_CHANGE",
  DOCUMENT_COMMENT_ADD = "DOCUMENT_COMMENT_ADD",
  DOCUMENT_COMMENT_UPDEL = "DOCUMENT_COMMENT_UPDEL",
  DOCUMENT_NOTE_ADD = "DOCUMENT_NOTE_ADD",
  DOCUMENT_NOTE_ADDCHANGE = "DOCUMENT_NOTE_ADDCHANGE",
}

export enum STAT_ACTIVITY_TYPE {
  NUMBER = "NUMBER",
  JSON = "JSON",
}

export interface BasicStatistic {
  activity: STAT_ACTIVITY_LIST;
  count: number;
  type: STAT_ACTIVITY_TYPE;
  sum: number;
  min: string;
  max: string;
}

export interface StatisticActivity {
  timestamp: string;
  changes: number;
  activity: number;
}

export interface UserStatisticHeatMapType {
  date: string | null | undefined | Date;
  count: number;
}
