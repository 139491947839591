import { FunctionComponent, useState } from "react";
import { log, tag } from "missionlog";
// import {
//   // addActaFullVisibility,
//   // addVisibility,
//   udpateVisilibity,
//   UpdateVisibilityTypes,
// } from "../../../../limaCommon/limaVisibilityCalls";
// import limaUsersStore from "../../../../store/limaUsersStore";
import limaStore from "../../../../store/limaStore";
// import { userAccListDataTypeLinked } from "../../../../dataModels/userAccListDataType";
// import { WORDITEMTYPE } from "../../../../api/apilinks";
// import { insertDoc } from "../../../limaCommon/limaInsertDoc";
import React = require("react");
import {
  // ActionButton,
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  IStackTokens,
  ITheme,
  mergeStyleSets,
  Pivot,
  PivotItem,
  // Separator,
  Stack,
  // Toggle,
} from "@fluentui/react";
import UserContextEdit from "../UserContextEdit";
import UserPermissionEditPage from "../../../uiv2/Users/UsersList/userPermissions/UserPermissionEditPage";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";

import UserStatisticActivity from "../../../uiv2/Users/UsersList/statistic/UserStatisticActivity";
import { IUser } from "../../../../types/User";
import LimaLicPermShow from "../../../../limaComponents/LimaLicPermShow";

log.init({ UsersList: "UsersList" });

export interface UserListItemProps {
  item: IUser;
  onRemove?: () => void;
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  edgeId: {
    display: "none",
  },
  listItemContainer: {
    display: "flex",
  },
  itemCellUserName: [
    getFocusStyle(theme, { inset: -1 }),
    {
      //flexGrow: 1,
      //display: "flex",
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      minWidth: 300,
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemCellUserActions: [
    {
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      minWidth: 130,
    },
  ],
  itemCellIcon: [
    getFocusStyle(theme, { inset: -1 }),
    {
      paddingRight: 30,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemCellIconShort: [
    {
      paddingRight: 0,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemContent: {
    marginLeft: 10,
    minHeight: 30,
    overflow: "hidden",
    flexGrow: 1,
  },
  userNameStyle: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
});

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

const RemoveLinkIcon: IIconProps = { iconName: "RemoveLink" };
// const AddLinkIcon: IIconProps = { iconName: "AddNotes" };
// const OpenAs: IIconProps = { iconName: "fileComment" };
// const ReOpen: IIconProps = { iconName: "generate" };

// const showIcon: IIconProps = { iconName: "RedEye" };
// const hideIcon: IIconProps = { iconName: "Hide" };

const UserListItem: FunctionComponent<UserListItemProps> = ({ item, onRemove }: UserListItemProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  // const [showPermissions, setShowPermissions] = useState<boolean>(false);

  const onRemoveLinkingHandler = (item: IUser) => {
    log.info(tag.UsersList, "onRemoveLinkingHandler: clicked", item);
    //TODO: REMOVE USER FROM MY LIST
    onRemove();

    // void udpateVisilibity(
    //   "dUser/" + limaStore.userId,
    //   item.userId,
    //   null,
    //   limaStore.userId,
    //   null,
    //   new Date().toISOString(),
    //   WORDITEMTYPE.user,
    //   UpdateVisibilityTypes.COMMON,
    //   item.edgeId
    // ).then(() => {
    //   item.docEdgeId = "";
    //   limaUsersStore.removeListSingleItem(item.userId);
    // });
  };

  return (
    <div key={item._key} className={classNames.listItemContainer}>
      <Stack tokens={stackTokens}>
        <Stack.Item>
          <Stack horizontal tokens={stackTokens} key={item._key + "_inner"}>
            <Stack.Item grow={7} className={classNames.itemCellUserName}>
              <div
                className={classNames.itemContent}
                data-is-focusable={true}
                key={item._key + "_content"}
                onClick={() => {
                  setVisible(!visible);
                  log.info(tag.UsersList, "renderCell: clicked on ", item);
                  log.info(tag.UsersList, `renderCell: clicked on [${item._key}] is visible [${String(visible)}]?`);
                }}
              >
                <div key={item._key + "_name"} className={classNames.userNameStyle}>
                  {item.userName}
                </div>
              </div>
              {/* </div> */}
            </Stack.Item>
            <Stack.Item grow={5} className={classNames.itemCellUserActions}>
              <Stack horizontal>
                {onRemove && (
                  <Stack.Item grow={1}>
                    <IconButton
                      iconProps={RemoveLinkIcon}
                      key={item._key + "_remLinkBtn"}
                      className={classNames.itemCellIcon}
                      title="Remove link"
                      ariaLabel="Remove link"
                      onClick={() => {
                        onRemoveLinkingHandler(item);
                      }}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          {visible && (
            <Pivot aria-label="UserPivo">
              <PivotItem headerText="Edit">
                <UserContextEdit userId={item._key} />
              </PivotItem>
              <LimaLicPermShow
                accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcUserPermissions}
                dontShowOnNoLic={true}
              >
                <PivotItem headerText="Permissions">
                  <UserPermissionEditPage
                    userId={item._key}
                    actaId={limaStore.actaId}
                    companyKey={limaStore.userCompanyKey}
                  />
                </PivotItem>
              </LimaLicPermShow>
              <LimaLicPermShow
                accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcUserStatistic}
                dontShowOnNoLic={true}
              >
                <PivotItem headerText="Statistic">
                  <UserStatisticActivity userId={item._key} />
                </PivotItem>
              </LimaLicPermShow>
            </Pivot>
          )}
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default UserListItem;
