import { log } from "missionlog";
import { Shimmer } from "office-ui-fabric-react";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useQuery } from "react-query";
import { getAllCopy4Acta } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { actaListOK } from "../../../../../api/schema/getActaList";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { XCOPY_TYPE } from "../../../../../types/Task";
import { Xcopy2Acta } from "../../../../../types/Xcopy";
import FileItemOpenCopyItem from "./FileItemOpenCopyItem";

interface FileListCopiesProps {
  actaId: string;
  // onFileClickHandler: (fileId: string, fileName: string, xCopyId: string, timestamp?: string) => void;
  onFileClickHandler: (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE
  ) => void;
  item: actaListOK;
}

const FileListCopies: FunctionComponent<FileListCopiesProps> = ({
  actaId,
  item,
  onFileClickHandler,
}: FileListCopiesProps) => {
  // const { status, error, data } = useQuery<Xcopy2Acta[], Error>(
  //   ["userListCopies", { actaid: actaId, userid: limaStore.userId }],
  //   getUserActaCopies
  // );
  const { status, error, data, refetch } = useQuery(
    ["userListCopies", { actaid: actaId, userid: limaStore.userId }],
    // () => getUserActaCopies(actaId, limaStore.userId),
    () => getUserActaCopies(actaId),
    {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.UserStatisticActivity, "useQuery get getUserActivitytStat ", data);
        //setPermissionsList(data);
      },
    }
  );

  useEffect(() => {
    void refetch();
  }, [actaId]);

  if (status === "loading") {
    log.info(limaLogTag.FileListCopies, "copies loading: ", data);
    return (
      <>
        <div>... getting Copies ...</div>
        <div>
          <Shimmer width="80%" />
          <Shimmer width="80%" />
          <Shimmer width="80%" />
        </div>
      </>
    );
  }
  if (status === "error") {
    log.error(limaLogTag.FileListCopies, "copies is loading: ", error);
    return <div>{error}</div>;
  }
  if (status === "success") {
    log.info(limaLogTag.FileListCopies, "copies loaded: ", data);
  }
  if (data === undefined || data === null) {
    log.info(limaLogTag.FileListCopies, "no data: ");
    return <div>No data</div>;
  }

  return (
    <>
      {data &&
        data.map((singleCopyItem: Xcopy2Acta) => (
          // <FileListCopyItem key={singleCopyItem.key} xCopy2ActaItem={singleCopyItem} />
          <FileItemOpenCopyItem
            key={singleCopyItem._key}
            xCopy2ActaItem={singleCopyItem}
            isActaOwner={item.isOwner}
            openFileHandler={onFileClickHandler}
          />
        ))}
    </>
  );
};

export default FileListCopies;

//----------------------
//   REACT QUERY
//----------------------

// const getUserActaCopies = async (actaid: string, userid: string): Promise<Xcopy2Acta[]> => {
const getUserActaCopies = async (actaid: string): Promise<Xcopy2Acta[]> => {
  log.info(limaLogTag.FileListCopies, "fetching data getUserActaCopies");

  const loaderID = limaLoadeStore.add("Getting activities from DB");
  try {
    const response: HttpResponse<Xcopy2Acta[]> = await httpGetAuth<Xcopy2Acta[]>(
      // getAllCopy4Acta(actaid, userid),
      getAllCopy4Acta(actaid),
      loaderID.ac
    );
    log.info(limaLogTag.FileListCopies, "fetching data getUserActaCopies response", response);
    if (!response.ok) {
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.FileListCopies, "getUserActaCopies: asketo to get something", response.parsedBody);

      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

// //----------------------
// //   REACT QUERY
// //----------------------

// type Params = {
//   queryKey: [string, { actaid: string; userid: string }];
// };

// async function getUserActaCopies(params: Params) {
//   const [, { actaid, userid }] = params.queryKey;

//   let loaderID = limaLoadeStore.add("Getting users from DB");
//   let response: HttpResponse<Xcopy2Acta[]>;
//   try {
//     response = await httpGet<Xcopy2Acta[]>(getAllCopy4Acta(actaid, userid));

//     log.info(limaLogTag.FileListCopies, "getUserActaCopies: asketo to get something", response);
//     if (response.parsedBody !== undefined) {
//       log.info(limaLogTag.FileListCopies, "getUserActaCopies: parsedBody is not null", response.parsedBody);
//       const userListCopies = response.parsedBody;
//       return userListCopies;
//     } else {
//       throw new Error("...No data...");
//     }
//   } catch (response) {
//     log.error(limaLogTag.FileListCopies, "getUserPermissions Error call get User", response);
//     throw new Error("Problem geting permissions");
//   } finally {
//     //this.setLoadingData(false);
//     limaLoadeStore.remove(loaderID.k);
//   }
// }
