import * as React from "react";
import { Pivot, PivotItem, IIconProps } from "@fluentui/react";
import { useState, useEffect, FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { log } from "missionlog";
import FileSaveExistInfo from "./FileSaveExistInfo";
// import FileSaveAsNew from "./components/FileSaveAsNew";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";
import FileSaveToXCopy from "./components/FileSaveToCopy";
import FileSaveNewCopy from "./components/FileSaveNewCopy";
// import FileSaveToExists from "./components/FileSaveToExists";
import { useTranslation } from "react-i18next";
import FileSaveAsNewXcopy from "./components/FileSaveAsNewXcopy";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

log.init({ FileSave: "File/FileSave" });

const CopyIcon: IIconProps = { iconName: "Copy" };
const FileIcon: IIconProps = { iconName: "Page" };

type FileSavePageParams = {
  savetype?: string;
};

const FileSave: FunctionComponent = () => {
  const [actaIdOnOpenDoc, setActaIdOnOpenDoc] = useState<boolean>(false);
  let showSave = true;
  let showNew = true;
  let showCreateCopy = true;
  const { t } = useTranslation();
  let place = [t("tpmain:menu.fileLbl"), t("tpmain:menu.fileSaveLbl")];

  const { savetype } = useParams<FileSavePageParams>();

  useEffect(() => {
    if (limaStore.actaId !== undefined && limaStore.actaId !== null && limaStore.actaId !== "") {
      setActaIdOnOpenDoc(true);
    }
  }, [limaStore.actaId]);

  if (savetype === "save") {
    showSave = true;
    showNew = false;
    showCreateCopy = false;
    place = [t("tpmain:menu.fileLbl"), t("tpmain:menu.fileSaveLbl"), t("tpmain:menu.filesave.saveLbl")];
  } else if (savetype === "new") {
    showSave = false;
    showNew = true;
    showCreateCopy = false;
    place = [t("tpmain:menu.fileLbl"), t("tpmain:menu.fileSaveLbl"), t("tpmain:menu.filesave.savenewLbl")];
  } else if (savetype === "createcopy") {
    showSave = false;
    showNew = false;
    showCreateCopy = true;
    place = [t("tpmain:menu.fileLbl"), t("tpmain:menu.fileSaveLbl"), t("tpmain:menu.filesave.createcopyLbl")];
  } else {
    showSave = true;
    showNew = true;
    showCreateCopy = true;
    place = [t("tpmain:menu.fileLbl"), t("tpmain:menu.fileSaveLbl")];
  }
  // TODO: vyřešit otevírání souboru dvakrát za sebou, když ukládám existující

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={place} />
      {limaStore.showTestingInfo && <FileSaveExistInfo fileName={limaStore.actaName} type={limaStore.openedDocType} />}
      {/* <span>Current: {savetype}</span> */}
      {/* base,save,new,createcopy */}
      <Pivot aria-label={t("tpfile:filesave.savePivotAriaLbl")}>
        {showSave &&
        actaIdOnOpenDoc &&
        (limaStore.openedDocType === OPENEDFILETYPE_ENUM.COPY ||
          ((limaStore.openedDocType === OPENEDFILETYPE_ENUM.ACTA ||
            limaStore.openedDocType === OPENEDFILETYPE_ENUM.MAIN_COPY) &&
            limaStore.IamDocumentOwner)) ? (
          <PivotItem headerText={t("tpfile:filesave.savePivotLbl")} itemIcon={CopyIcon.iconName}>
            <FileSaveToXCopy />
          </PivotItem>
        ) : (
          <span>Save requested but unabled</span>
        )}
        {showNew && (
          <PivotItem headerText={t("tpfile:filesave.saveasnewPivotLbl")} itemIcon={FileIcon.iconName}>
            <FileSaveAsNewXcopy />
          </PivotItem>
        )}
        {showCreateCopy &&
        actaIdOnOpenDoc &&
        (limaStore.openedDocType === OPENEDFILETYPE_ENUM.ACTA ||
          limaStore.openedDocType === OPENEDFILETYPE_ENUM.MAIN_COPY) ? (
          <PivotItem headerText={t("tpfile:filesave.createnewxcopyPivotLbl")} itemIcon={CopyIcon.iconName}>
            <FileSaveNewCopy />
          </PivotItem>
        ) : (
          <span>Create copy requested but unabled</span>
        )}
      </Pivot>
    </>
  );
};

export default FileSave;
