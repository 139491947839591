import { mergeStyleSets, Stack } from "@fluentui/react";
import { log } from "missionlog";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { getFileAsyncInternal } from "../../../../limaCommon/limaSaveDoc";
import { versionStringToNumbers } from "../../../../limaCommon/limaVersionHelper";
import { MenuSelectedENUM } from "../../../../limaComponents/LimaMenu";
import { LimaSimpleButtonV4 } from "../../../../limaComponents/LimaSimpleButtonV4";
import { ENUM_LogstoreLogType } from "../../../../store/limaLogsStore";
import limaLoadeStore from "../../../../store/limaLoaderStore";
// import limaActasStore from "../../../../store/limaActasStore";
import limaStore from "../../../../store/limaStore";
// import limaUsersStore from "../../../../store/limaUsersStore";
import { LimaDocTypeENUM } from "../../../../types/docxData";
// import { IUser } from "../../../../types/User";
import { XcopyHistory, xcopyHistory_null } from "../../../../types/XcopyHistory";
import Routes from "../../../Menu/routes";
import FileVersionsV4 from "../Versions/FileVersionsV4";
import NewVersionForm from "./components/NewVersionFormV4";
import OnBehalfSaveV4 from "./components/OnBehalfSaveV4";
import { FileStoreFinishV4TypeENUM } from "./FileStoredFinish";
import limaLogsStore from "../../../../store/limaLogsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMainCopyNewVersionV4Props {
  lastVersion?: number[];
  actaId?: string;
  xcopyId?: string;
  actaName?: string;
}

const classNames = mergeStyleSets({
  saveActionsWrapper: {
    display: "flex",
  },
  saveActionsButton: {
    margin: 5,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainCopyNewVersionV4: FunctionComponent<IMainCopyNewVersionV4Props> = (props: IMainCopyNewVersionV4Props) => {
  //const [versionID, setVersionID] = useState<string>();
  const [onBehalf, setOnBehalf] = useState<string | null>(null);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [versionLast, setVersionLast] = useState<number[]>(
    props.lastVersion === undefined ? [0, 0] : props.lastVersion
  );
  const [versionVal, setVersionVal] = useState<string>();
  const [historyNote, setHistoryNote] = useState<string>("");
  const history = useHistory();
  const { t } = useTranslation();

  //----------------------------
  // Reactions
  //----------------------------
  React.useEffect(() => {
    log.info(limaLogTag.MainCopyNewVersionV4, `UEF: set Action visibility`);

    limaStore.setMenuOpen(MenuSelectedENUM.FILE);
    // void limaUsersStore.checkLastRead();
    return () => {
      log.info(limaLogTag.MainCopyNewVersionV4, `UEF: set Action visibility false on destruct`);
      limaStore.setMenuOpen(MenuSelectedENUM.NONE);
    };
  }, []);

  React.useEffect(() => {
    log.info(limaLogTag.MainCopyNewVersionV4, `UEF-version: version`, versionVal);
  }, [versionVal]);

  //---------------------
  // Handlers
  //---------------------
  const saveExistingFile = async () => {
    log.info(limaLogTag.MainCopyNewVersionV4, "saveExistingFile: Saving existing file START");
    const loaderID = limaLoadeStore.add("Storing existing file");
    const xcopyHistory = xcopyHistory_null();
    const splittedVersion = versionStringToNumbers(versionVal);

    xcopyHistory.versionMajor = splittedVersion[0];
    xcopyHistory.versionMinor = splittedVersion[1];
    xcopyHistory.historyNote = historyNote;

    const actaIdToSave = props.actaId === undefined || props.actaId === null ? limaStore.actaId : props.actaId;
    const actaNameToSave =
      props.actaName === undefined || props.actaName === null ? limaStore.actaName : props.actaName;
    const xcopyIdToSave = props.xcopyId === undefined || props.xcopyId === null ? limaStore.xcopyId : props.xcopyId;

    await getFileAsyncInternal(
      actaIdToSave, //limaStore.actaId,
      xcopyIdToSave, //limaStore.xcopyId,
      LimaDocTypeENUM.XCOPY,
      xcopyIdToSave, //limaStore.xcopyId,
      LimaDocTypeENUM.XCOPY,
      actaNameToSave, //limaStore.actaName,
      limaStore.userId,
      xcopyHistory, //version
      () => {
        limaLoadeStore.remove(loaderID.k);
        log.info(limaLogTag.MainCopyNewVersionV4, "saveExistingFile: Saving existing file Finish");
        history.push(`${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.ACTA}/${FileStoreFinishV4TypeENUM.UPDATE}`);
      },
      () => {
        limaLoadeStore.remove(loaderID.k);
        // setIsError(true);
        log.info(limaLogTag.MainCopyNewVersionV4, "saveExistingFile: Saving existing file Finish with error");
        limaLogsStore.add(
          "Save new file to new version error",
          "Some error on saving new version  file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.MainCopyNewVersionV4
        );
        limaLogsStore.add(
          "Save exist xcopy error",
          "Some error on saving to exist xcopy  file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.WorkingCopySaveExistV4
        );
        log.info(
          limaLogTag.WorkingCopySaveExistV4,
          `push to ${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.ACTA}/${FileStoreFinishV4TypeENUM.ERROR_UPDATE} / '/fileSaveFinishRt/:documenttype/:storetype'`
        );
        window.console.log(
          `<<<<< push to ${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.ACTA}/${FileStoreFinishV4TypeENUM.ERROR_UPDATE} / '/fileSaveFinishRt/:documenttype/:storetype'`
        );
        history.push(
          `${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.ACTA}/${FileStoreFinishV4TypeENUM.ERROR_UPDATE}`
        );
      },
      null,
      onBehalf
    );
    log.info(limaLogTag.MainCopyNewVersionV4, "saveExistingFile: Saving existing file END");
  };

  //------------------
  // Renders
  //-------------------
  return (
    <Stack>
      <Stack.Item>
        <NewVersionForm
          changeNoteClb={setHistoryNote}
          // changeVersionClb={setVersionVal}
          changeVersionClb={(newVersion: string) => {
            setVersionVal(newVersion);
            window.console.log("AAAAAAAAAAAA - new version", newVersion);
          }}
          actaName={limaStore.actaName}
          versionLast={versionLast}
        />
      </Stack.Item>
      <Stack.Item>
        <OnBehalfSaveV4 onBehalf={onBehalf} label={"Change on behalf:"} setOnBehalf={setOnBehalf} />
      </Stack.Item>
      <Stack.Item className={classNames.saveActionsWrapper}>
        <div className={classNames.saveActionsButton} style={{ flexGrow: 1 }}>
          <LimaSimpleButtonV4 width={"100%"} disabled={true}>
            {t("tpfile:fileitemV4.newVersion.discardBtnLbl")}
          </LimaSimpleButtonV4>
        </div>
        <div className={classNames.saveActionsButton} style={{ flexGrow: 4 }}>
          <LimaSimpleButtonV4 width={"100%"} onClick={saveExistingFile} disabled={saveDisabled}>
            {t("tpfile:fileitemV4.newVersion.saveBtnLbl")}
          </LimaSimpleButtonV4>
        </div>
      </Stack.Item>
      <Stack.Item>{t("tpfile:fileitemV4.newVersion.latestVersionLbl")} </Stack.Item>
      <Stack.Item>
        <FileVersionsV4
          item={{
            edgeId: "",
            name: limaStore.actaName,
            isOwner: limaStore.isOwner,
            id: limaStore.actaId,
            copiesList: [],
          }}
          listOnly={true}
          onLoadFinishClb={(items: XcopyHistory[]) => {
            window.console.log(`items callback`);
            window.console.log(`items ${items.length}`);
            if (saveDisabled === true) {
              setSaveDisabled(false);
            }
            if (items && items.length > 0) {
              const lastVersionId = `${items[0].versionMajor}.${items[0].versionMinor}`;
              window.console.log(`set last version ${lastVersionId}`);
              const splited = lastVersionId.split(".");
              setVersionLast([Number(splited[0]), Number(splited[1])]);
            }
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default MainCopyNewVersionV4;
