import { log } from "missionlog";
import moment = require("moment");
import { Statistic, STAT_ACTIVITY_LIST } from "../types/Statistic";
import { limaLogTag } from "./limaLog";

export const StatConverToText = (item: Statistic): string => {
  //  console.log(text);
  log.info(limaLogTag.LimaStat, `StatConverToText: start translate to text`);
  const timeStamp = moment(item.timeStamp).format("Do MMMM YYYY [at] h:mma");
  let text = "" + timeStamp;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: JSON = JSON.parse(item.data);
  switch (item.activityIdent) {
    //ACTA ACCESS
    case STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_OPEN:
      text += " user open ";
      if (data["doctype"] == "ACTA") text += ` dokument '${String(data["name"])}'`;
      else if (data["doctype"] == "XCOPY") text += `kopii '${String(data["name"])}'`;

      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_STORE:
      text += " user store document " + item.actaKey;
      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_ACCEESS_COMPARE:
      text += " user do compare on document " + item.actaKey;
      break;
    //WORDS
    case STAT_ACTIVITY_LIST.DOCUMENT_WORD_ADD:
      text += " user added " + item.data + " words to document " + item.actaKey;
      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_WORD_CHANGE:
      text += " user changed " + item.data + " words in document " + item.actaKey;
      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_WORD_DEL:
      text += " user deleted " + item.data + " words from document " + item.actaKey;
      break;
    //COMMENTS
    case STAT_ACTIVITY_LIST.DOCUMENT_COMMENT_ADD:
      text += " user added " + item.data + " comments to document " + item.actaKey;
      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_COMMENT_UPDEL:
      text += " user changed or remove " + item.data + " comments in document " + item.actaKey;
      break;
    //NOTES
    case STAT_ACTIVITY_LIST.DOCUMENT_NOTE_ADD:
      text += " user added " + item.data + " notes to document " + item.actaKey;
      break;
    case STAT_ACTIVITY_LIST.DOCUMENT_NOTE_ADDCHANGE:
      text += " user changed or remove " + item.data + " notes in document " + item.actaKey;
      break;
    default:
      text += ` user do something importat >>  ${String(item.activityIdent)}`;
  }

  if (item.copyKey != null) text += "(copy: " + item.copyKey + ")";
  return text;
};
