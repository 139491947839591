import { log } from "missionlog";
import { urlGetAsPDF } from "../apilinks";
import limaStore from "../../store/limaStore";
import { EXPORT_TYPE } from "../../types/Acta";
import { limaLogTag } from "../../limaCommon/limaLog";
import FileSaver = require("file-saver");
import limaLoadeStore, { LoaderItemEnd } from "../../store/limaLoaderStore";
import { httpGetAuth_noParse } from "../httpAPI";

// ?actaid=1023123633738481664&userid=979&timestamp=null&output=word&xcopy=1023123633738481666&justdocx=true&exporttype=PDF&addhelpertext=false
// export const onDownloadPFHandler = (
export const onDownloadPFHandler = async (
  actaID: string,
  timestamp: string,
  xcopyId: string,
  exporttype: EXPORT_TYPE,
  name: string
): Promise<Blob> => {
  log.info(limaLogTag.LimaGetForDownload, "start getting item ", actaID);

  const userID = limaStore.getUser().userId;
  const output = "word";
  const justdocx = true;
  const addhelpertext = false;
  const loaderID = limaLoadeStore.add("Getting attachement from server");
  if (name === undefined || name === null || name === "") {
    name = "limaDownload.pdf";
  }
  if (name.slice(-3).toLowerCase() !== "pdf") name = `${name}.pdf`;
  try {
    // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
    const response: Response = await httpGetAuth_noParse(
      urlGetAsPDF(actaID, userID, timestamp, output, xcopyId, justdocx, exporttype, addhelpertext),
      loaderID.ac
    );
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response respo", response);
    const respBlob = await response.blob();
    log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response blob", respBlob);
    //const response = httpGetAuth().then
    FileSaver.saveAs(respBlob, name);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return respBlob;
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
  }

  // const newWindow = window.open(
  //   urlGetAsPDF(actaID, userID, timestamp, output, xcopyId, justdocx, exporttype, addhelpertext),
  //   // "https://app.limacta.com/LimactaApp/Note/get/att/filename:" + key,
  //   "_blank",
  //   "noopener,noreferrer"
  // );
  // if (newWindow) newWindow.opener = null;
};

// export const getAtachement = async (itemKey: string, name: string): Promise<Blob> => {
//   log.info(limaLogTag.AttachementComponentV4, "fetching data getAtachement");
//   // const [, { patientID }] = params.queryKey;
//   const loaderID = limaLoadeStore.add("Getting attachement from server");
//   try {
//     // const response:HttpResponse<any> =  await httpGetExtToken(urlGetSurveyAnamnesis(patientID,2))
//     const response: Response = await httpGetAuth_noParse(urlGetAttachement(itemKey), loaderID.ac);
//     if (!response.ok) {
//       throw response;
//     }
//     log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response respo", response);
//     const respBlob = await response.blob();
//     log.info(limaLogTag.AttachementComponentV4, "fetching data fetchAtachements response blob", respBlob);
//     //const response = httpGetAuth().then
//     FileSaver.saveAs(respBlob, name);
//     return respBlob;
//   } catch (error) {
//     throw new Error(error);
//   } finally {
//     limaLoadeStore.remove(loaderID.k);
//   }
// };
