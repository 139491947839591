import * as React from "react";
import { FunctionComponent } from "react";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { Icon, ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

export interface ILimaActionIconCompareCopyProps {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}

// const compareCopyIcon: IIconProps = { iconName: "BranchCompare" };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaActionIconCompareCopy: FunctionComponent<ILimaActionIconCompareCopyProps> = (
  props: ILimaActionIconCompareCopyProps
) => {
  const tooltipId = useId("toolTip-FileOpen");
  const calloutProps = { gapSpace: 0 };

  return (
    <TooltipHost content={props.title} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <div
        className={`${classNamesCommon.LimaActionIconButton} ${
          limaStore.docxIsLoading
            ? classNamesCommon.LimaActionIconButton_wait
            : classNamesCommon.LimaActionIconButton_enabled
        }`}
        onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick();
          }
        }}
      >
        {/* <LimaIconFileOpen /> */}
        {/* <Icon {...compareCopyIcon} style={{ color: "#0078D4", fontSize: 22 }} /> */}
        <Icon iconName="BranchCompare" aria-label="Compare" className={classNamesCommon.LimaActionIconiconBase} />
      </div>
    </TooltipHost>
  );
};
