import { FocusZone, FocusZoneDirection, List, TextField } from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { limaQuery_removeUserLinking } from "../../../../api/calls/limaUse2UserCalls";
import { limaQuery_patchUser, limaQuery_postUser } from "../../../../api/calls/limaUserCalls";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaUsersStore from "../../../../store/limaUsersStore";
import { ICallUser, IUser } from "../../../../types/User";
import { User2User } from "../../../../types/User2User";
import { LimaEditItemBaseV4 } from "../../Tasks/LimaEditItemBaseV4";
import UserDetailV4 from "./components/UserDetailV4";
import UserListItemV4 from "./components/UserListItemV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUsersListV4Props {}

type observedUsers = {
  filter: string;
};

const UsersListV4: React.FunctionComponent<IUsersListV4Props> = () => {
  const { mutate: mutateRemoveLinking } = useRemoveUseLinkingQuery();
  const { mutateAsync: mutateCreateUser } = useAskToCreateUserQuery();
  const { mutateAsync: mutatePatchUser } = useAskToPathUserQuery();
  const [filter, setFilter] = React.useState<string>("");
  const [filterCount, setFilterCount] = React.useState<number>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [storeEditEnabled, setStoreEditEnabled] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDetailUser, setShowDetailUser] = useState<IUser | null>(null);

  const enableEditStore = useMemo(() => storeEditEnabled, [storeEditEnabled]);
  //-------------------
  // Reactions
  //---------------------
  useEffect(() => {
    log.info(limaLogTag.UsersListV4, "UEF: call to check");
    setFilter("");
    void limaUsersStore.checkLastRead().then(() => {
      log.info(limaLogTag.UsersListV4, "UEF: check finish", limaUsersStore.lastDBReadDate);
    });
  }, []);

  const resultCountText = useMemo(() => {
    if (filterCount === null) return "";

    const maxCount = limaUsersStore.getAllUsersCount;

    if (filterCount === maxCount) return "";

    return ` (${String(filterCount)} of ${String(maxCount)} shown)`;
  }, [filterCount]);

  //-------------------
  //  Handlers
  //-------------------
  const onFilterChanged = (_: never, text: string): void => {
    setFilter(text);
    // setItems(actaList.filter((item: actaListOK) => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0));
  };

  const onRemoveLinking = (item: IUser) => {
    mutateRemoveLinking(item);
  };

  const onStore = () => {
    log.info(
      limaLogTag.UsersListV4,
      `onStore:  Store?[${String(limaUsersStore.currentUser._key === null)}]`,
      limaUsersStore.currentUser
    );

    if (limaUsersStore.currentUser._key === null) {
      void mutateCreateUser({
        userEmail: limaUsersStore.currentUser.userEmail,
        userName: limaUsersStore.currentUser.userName,
        userId: null,
        lang: limaUsersStore.currentUser.lang,
      })
        .then((dataOnCreate: IUser) => {
          limaUsersStore.setCurrentUser(dataOnCreate);
          limaUsersStore.addListSingleItem(dataOnCreate);
        })
        .catch(() => {
          log.error(limaLogTag.UsersListV4, "on create user: error");
        });
    } else {
      void mutatePatchUser({
        userEmail: limaUsersStore.currentUser.userEmail,
        userName: limaUsersStore.currentUser.userName,
        userId: limaUsersStore.currentUser._key,
        lang: limaUsersStore.currentUser.lang,
      })
        .then((dataOnCreate: IUser) => {
          limaUsersStore.setCurrentUser(dataOnCreate);
          limaUsersStore.updListSingleItem(dataOnCreate._key, dataOnCreate);
        })
        .catch(() => {
          log.error(limaLogTag.UsersListV4, "on create user: error");
        });
    }
  };

  //----------------------
  // Renderes
  //------------------
  const onRenderUserListCell = (item: IUser): JSX.Element => {
    // if (item !== null) {
    return (
      <UserListItemV4
        user={item}
        enableDetail={true}
        onDetailClick={() => {
          setShowDetailUser(item);
          setShowDetail(true);
        }}
        enableUnlink={true}
        onUnlinkClick={() => {
          window.console.info("AAAAAAAAAAAAAAAAAAAAAA");
          onRemoveLinking(item);
        }}
      />
    );
  };

  const UsersList = observer(({ filter }: observedUsers) => {
    log.info(limaLogTag.UsersListV4, "redraw user UsersList", filter);
    const items = limaUsersStore.list.filter((item: IUser) => {
      if (item.userName.toLowerCase().indexOf(filter.toLowerCase()) >= 0) return true;
      else return false;
    });

    if (filterCount !== items.length) {
      setFilterCount(items.length);
    }

    return <List items={items} onRenderCell={onRenderUserListCell} />;
  });

  return (
    <>
      <FocusZone direction={FocusZoneDirection.vertical}>
        <div style={{ marginBottom: 7 }}>
          <TextField
            label={"Filter by name" + resultCountText}
            // label={"Filter by name"}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onFilterChanged}
          />
        </div>
        <UsersList filter={filter} />
      </FocusZone>
      <LimaEditItemBaseV4
        detail={
          showDetailUser ? (
            <UserDetailV4 user={showDetailUser} enableEditUser={true} enableStore={setStoreEditEnabled} />
          ) : (
            <></>
          )
        }
        open={showDetail}
        panelName="User detail"
        editDetailEnableStore={enableEditStore} //{editDetailEnableStore}
        onSaveUpdateCallBack={() => {
          log.info(limaLogTag.UsersListV4, "LimaEditItemBaseV4 onSaveUpdateCallBack ....");
          onStore();
        }}
        onDetialDismissCallBack={() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          log.info(limaLogTag.UsersListV4, "LimaEditItemBaseV4 onDetialDismissCallBack ....");
          setShowDetail(false);
        }}
      />
    </>
  );
};

export default UsersListV4;

//----------------------
//   REACT QUERY
//----------------------

const useRemoveUseLinkingQuery = () => {
  return useMutation(limaQuery_removeUserLinking, {
    onSuccess: (data: User2User[]) => {
      log.info(limaLogTag.UsersListV4, "user removed", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const useAskToCreateUserQuery = () => {
  return useMutation(limaQuery_postUser, {
    onMutate: ({ userEmail, userName }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UsersListV4, "call mutate useAskToCreateUserQuery ", userEmail, userName);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UsersListV4, "call mutate useAskToCreateUserQuery ", data);
      //Update listUsers
      limaUsersStore.addListSingleItem(data);
    },
  });
};

const useAskToPathUserQuery = () => {
  return useMutation(limaQuery_patchUser, {
    onMutate: ({ userEmail, userName, userId, lang }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UsersListV4, "call mutate useAskToPachUserQuery ", userEmail, userName, userId, lang);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UsersListV4, "call mutate useAskToPachUserQuery ", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};
