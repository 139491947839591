import { Stack } from "@fluentui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import limaStore from "../../../../store/limaStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICopyNewVersionV4Props {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CopyNewVersionV4: FunctionComponent<ICopyNewVersionV4Props> = (_props) => {
  //----------------------------
  // Reactions
  //----------------------------
  React.useEffect(() => {
    window.console.log("HERE I AM ON CopyNewVersionV4");
  }, []);

  //------------------
  // Renders
  //-------------------
  return (
    <Stack>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>{limaStore.actaName} - COPY</Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>Popis</Stack.Item>
      <Stack.Item>LINKING</Stack.Item>
    </Stack>
  );
};

export default CopyNewVersionV4;
