import * as React from "react";
import { FunctionComponent } from "react";
// import { LimaIconFileOpen } from "../../assets/icons/LimaIconFileOpen";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { Icon, IIconProps, ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

// ICONS
//

export interface ILimaActionIconDefaultProps {
  onClick?: () => void;
  size?: number;
  color?: string;
  title?: string;
  disabled?: boolean;
  type?: LIMA_ACTION_BUTTON_TYPE;
}

export enum LIMA_ACTION_BUTTON_TYPE {
  SESSION_OTHER_SIGNED_USER = "SESSION_OTHER_SIGNED_USER",
  LICENCES_PAGE = "LICENCES_PAGE",
  TIMERS_PAGE = "TIMERS_PAGE",
  COMPANY_PAGE = "COMPANY_PAGE",
}
//const licencemanagerIcon: IIconProps = { iconName: "AccountManagement" };

interface actionButtonIcons {
  key: LIMA_ACTION_BUTTON_TYPE;
  icon: IIconProps;
  defSize: number;
  defColor: string;
}

const actionButtonIcons: actionButtonIcons[] = [
  {
    key: LIMA_ACTION_BUTTON_TYPE.SESSION_OTHER_SIGNED_USER,
    icon: { iconName: "ReleaseGateError" },
    defSize: 24,
    defColor: "#8e8e93",
  },
  {
    key: LIMA_ACTION_BUTTON_TYPE.LICENCES_PAGE,
    icon: { iconName: "AccountManagement" },
    defSize: 32,
    defColor: "#8e8e93",
  },
  {
    key: LIMA_ACTION_BUTTON_TYPE.TIMERS_PAGE,
    icon: { iconName: "Stopwatch" },
    defSize: 32,
    defColor: "#8e8e93",
  },
  {
    key: LIMA_ACTION_BUTTON_TYPE.COMPANY_PAGE,
    icon: { iconName: "CityNext2" },
    defSize: 32,
    defColor: "#8e8e93",
  },
];

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

export const LimaActionIconDefault: FunctionComponent<ILimaActionIconDefaultProps> = (
  props: ILimaActionIconDefaultProps
) => {
  const tooltipId = useId("toolTipIcon");
  const calloutProps = { gapSpace: 0 };
  const defIcon = actionButtonIcons.filter((item) => item.key === props.type)[0];
  return (
    <TooltipHost content={props.title} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <div
        className={`${classNamesCommon.LimaActionIconButton} ${
          limaStore.docxIsLoading
            ? classNamesCommon.LimaActionIconButton_wait
            : props.disabled
            ? classNamesCommon.LimaActionIconButton_disabled
            : classNamesCommon.LimaActionIconButton_enabled
        }`}
        onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick();
          }
        }}
        //title={props.title !== undefined && props.title}
      >
        <Icon
          {...defIcon.icon}
          style={{
            color: props.color !== undefined ? props.color : defIcon.defColor,
            fontSize: props.size !== undefined ? props.size : defIcon.defSize,
          }}
        />
      </div>
    </TooltipHost>
  );
};
