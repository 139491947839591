import { ITimePerActa, ITimerPartion } from "../types/Timer";

export const mergeByActa = (partitions: ITimerPartion[]): ITimePerActa[] => {
  const outPartitions: ITimePerActa[] = [];

  partitions.forEach((item: ITimerPartion) => {
    const existPartPerActa = outPartitions.find(
      (outPartActa: ITimePerActa) => outPartActa.itemMainCopyId === item.itemMainCopyId
    );
    if (existPartPerActa !== undefined && existPartPerActa !== null) {
      existPartPerActa.timerPartitions = [...existPartPerActa.timerPartitions, item];
      existPartPerActa.actaTimerValue += item.timerValue;
    } else {
      outPartitions.push({
        itemMainCopyId: item.itemMainCopyId,
        actaTimerValue: item.timerValue,
        timerPartitions: [item],
      });
    }
  });

  return outPartitions;
};
