import { Icon, getFocusStyle, getTheme, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
// import Moment from "react-moment";
import { WORDITEMTYPE } from "../../../../../api/apilinks";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../../limaCommon/limaWordInteract";
// import limaStore from "../../../../../store/limaStore";
// import limaStore from "../../../../../store/limaStore";
import {
  isSentanceAnalyseData_COMMITMENT_resp,
  isSentanceAnalyseData_COMMITMENT_withmissingObj,
  isSentanceAnalyseData_COMMITMENT_withmissingSubj,
  SentanceAnalyseData,
} from "../../../../../types/SentanceAnalyseData";
// import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../../../types/Task_withActaXcopy";
import { SelectedInWord } from "../../../../../types/wordObjects";
// import TaskItemEdit2 from "../../../Edit/Tasks/TaskItemEdit2";

interface CommitmentComponentProps {
  item: SentanceAnalyseData;
}

// const ShowCreateTaskIcon: IIconProps = { iconName: "ChevronDown" };
// const HideCreateTaskIcon: IIconProps = { iconName: "ChevronUp" };

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemRowTaskInfoPart: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    },
  ],
  itemRowTaskIconsPart: {
    display: "flex",
    flexDirection: "row",
    width: 25,
  },
  itemRowActionIconsPart: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  itemRowTaskPartContentWrapper: {
    marginLeft: 10,
    overflow: "hidden",

    display: "flex",
    flexDirection: "row",
    width: "70%",
  },
  sentanceType: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
  sentance: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // fontWeight: "bold",
    },
  ],
});

const CommitmentComponent: FunctionComponent<CommitmentComponentProps> = ({ item }: CommitmentComponentProps) => {
  const selItem: SelectedInWord = { id: item.paraId, type: WORDITEMTYPE.para };
  // const [createTaskShow, setCreateTaskShow] = useState<boolean>(false);
  // const [taskPrefilData, setTaskPrefilData] = useState<Task_withActaXcopy | undefined>(undefined);

  const onHooverIn = () => {
    console.log("par IN", item.paraId);

    void highlightTaskLinks3([selItem], true, HIGHLIGHT_TYPE.HIGHLIGHT, true);
    // if (checked === true) {
    //   limaStore.setLocationChangeCallBack(() => {
    //     highlightTaskLinks3(hightlightItems, false, highlight);
    //   });
    //   highlightTaskLinks3(hightlightItems, true, highlight);
    // } else {
    //   highlightTaskLinks3(hightlightItems, false, highlight);
    //   limaStore.setLocationChangeCallBack(null);
    // }
  };
  const onHooverOut = () => {
    // console.log("par OU", item.paraId);
    void highlightTaskLinks3([selItem], false, HIGHLIGHT_TYPE.HIGHLIGHT);
  };

  // const onCreateTaskShow = () => {
  //   // console.log("Create task", item.paraId);
  //   setCreateTaskShow(true);
  // };

  // const onCreateTaskHide = () => {
  //   // console.log("Hide task", item.paraId);
  //   setCreateTaskShow(false);
  // };

  // useEffect(() => {}, [item]);

  const commIssue = (issueType: string): string => {
    if (issueType == "COMMITMENT_SUBJ_IS_MISSING") {
      return "!! missing subject";
    } else if (issueType == "COMMITMENT_OBJ_IS_MISSING") {
      return "!! missing object";
    } else {
      return "!! other error";
    }
  };

  return (
    <Stack>
      <Stack.Item>
        <Stack
          data-is-focusable={true}
          horizontal
          onMouseEnter={onHooverIn}
          onMouseLeave={onHooverOut}
          className={classNames.itemRowTaskInfoPart}
        >
          <Stack.Item className={classNames.itemRowTaskIconsPart}>
            <Icon iconName="UserWarning" aria-label="Warning" />
          </Stack.Item>
          <Stack.Item className={classNames.itemRowTaskPartContentWrapper}>
            <Stack>
              <Stack.Item>
                <span className={classNames.sentanceType}>{item.found}</span>
                {item.otherData != undefined &&
                  isSentanceAnalyseData_COMMITMENT_resp(item.otherData) &&
                  "-> " + String(item.otherData.reponsible)}
                {item.otherData != undefined &&
                  isSentanceAnalyseData_COMMITMENT_withmissingSubj(item.otherData) &&
                  " .... " + String(commIssue(item.otherData.missingsubject))}
                {item.otherData != undefined &&
                  isSentanceAnalyseData_COMMITMENT_withmissingObj(item.otherData) &&
                  " .... " + String(commIssue(item.otherData.missingobj))}
              </Stack.Item>
              <Stack.Item>
                <span className={classNames.sentance}>{item.sentace}...</span>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item className={classNames.itemRowActionIconsPart}>
            {/* {!createTaskShow ? (
              <IconButton iconProps={ShowCreateTaskIcon} title={"Create task"} onClick={onCreateTaskShow} />
            ) : (
              <IconButton iconProps={HideCreateTaskIcon} title={"Hide task"} onClick={onCreateTaskHide} />
            )} */}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {/* {createTaskShow && (
        <Stack.Item>
          <TaskItemEdit2 prefillTaskData={taskPrefilData} />
        </Stack.Item>
      )} */}
    </Stack>
  );
};

export default CommitmentComponent;
