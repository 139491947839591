import * as React from "react";
import { FunctionComponent } from "react";
import { mergeStyleSets } from "office-ui-fabric-react";

// import { observer } from "mobx-react-lite";
// import { log } from "missionlog";

// import limaStore from "../../store/limaStore";
// import limaJudgeStore from "../../store/limaJudgeStore";
// import { advice_textsearch } from "../../types/advice";
// import limaActParStore from "../../store/limaActParStore";

import Logout from "./Logout";
import RunInfo from "./RunInfo";
import { Stack } from "@fluentui/react";
// import { limaLogTag } from "../../limaCommon/limaLog";
// import { loadFileName, officeFile } from "../../limaCommon/limaWordInteract";
// import DocName from "./components/DocName";
// import { SelectedInWord } from "../../types/wordObjects";
import LoaderComponent2 from "./components/LoaderComponent2";
import LangFlag from "./components/LangFlag";
import DebugInfo from "./components/DebugInfo";

// const columnProps: Partial<IStackProps> = {
//   tokens: { childrenGap: 10 },
//   styles: { root: { width: "100%" } },
// };

const classNames = mergeStyleSets({
  logoWrapper: {
    backgroundColor: "rgba(73, 0, 112, 1)",
    color: "#fff",
    textAlign: "center",
    width: "100%",
    zIndex: 100,
    //fontSize: "16px",
    height: 20,
  },
  // logoinfo: {
  //   fontSize: "12px",
  // },
  // logoimg: {
  //   marginLeft: "20px",
  // },
  loaderWrapper: {
    position: "absolute",
    top: "28px",
    left: "3px",
    zIndex: "100",
  },
  // headerLink: {
  //   fontSize: "12px",
  //   cursor: "pointer",
  // },
});

// const Header: FunctionComponent<HeaderProps> = ({}: HeaderProps) => {
const Header: FunctionComponent = () => {
  return (
    <Stack>
      <Stack.Item className={classNames.logoWrapper}>
        <Stack horizontal>
          <Stack.Item>
            <LangFlag />
          </Stack.Item>
          <Stack.Item grow={1}>
            <RunInfo />
          </Stack.Item>
          <Stack.Item>
            <Logout />
          </Stack.Item>
        </Stack>
        {/* </span> */}
        {/* </div> */}
      </Stack.Item>
      <Stack.Item className={classNames.loaderWrapper}>
        {/* <LoaderComponent showPrograssBar showRunnuingTasks /> */}
        <LoaderComponent2 />
      </Stack.Item>
      <Stack.Item>
        <DebugInfo />
      </Stack.Item>
    </Stack>
  );
};

export default Header;
