import { log } from "missionlog";
import moment = require("moment");
import { mergeStyleSets, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import * as CalendarHM from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";
import { limaLogTag } from "../../../../../limaCommon/limaLog";

import { UserStatisticHeatMapType } from "../../../../../types/Statistic";

interface UserStatisticHMcompProps {
  startingDate: Date;
  monthShift: number;
  data: UserStatisticHeatMapType[];
  valueSufix: string;
  rangeLimits: number[];
  colorVariant: string;
  changeStartDateCallBack: (date: Date) => void;
  onHMClick: (date: Date) => void;
}

function shiftDate(date: Date, numMonths: number): Date {
  return moment(date).add(numMonths, "M").toDate();
}

const classNames = mergeStyleSets({
  stackItemCenter: {
    textAlign: "center",
  },
  heatmapNoData: {
    fill: "#eeeeee",
  },
  heatmapData_0_A: {
    fill: "#c8b6ea",
  },
  heatmapData_1_A: {
    fill: "#c0aaec",
  },
  heatmapData_2_A: {
    fill: "#ad90f0",
  },
  heatmapData_3_A: {
    fill: "#906ff5",
  },
  heatmapData_4_A: {
    fill: "#7556f8",
  },
  heatmapData_5_A: {
    fill: "#6348f9",
  },
  heatmapData_0_B: {
    fill: "#d5ddd6",
  },
  heatmapData_1_B: {
    fill: "#cad9cd",
  },
  heatmapData_2_B: {
    fill: "#adcdb5",
  },
  heatmapData_3_B: {
    fill: "#7dba8e",
  },
  heatmapData_4_B: {
    fill: "#4da96c",
  },
  heatmapData_5_B: {
    fill: "#2ea05c",
  },
});

const UserStatisticHMcomp: FunctionComponent<UserStatisticHMcompProps> = ({
  startingDate,
  monthShift,
  data,
  valueSufix,
  rangeLimits,
  colorVariant,
  changeStartDateCallBack,
  onHMClick,
}: UserStatisticHMcompProps) => {
  // const [startDate, setStartDate] = useState<Date>(startingDate);

  useEffect(() => {
    log.info(limaLogTag.UserStatisticHMcomp, `UEF ${colorVariant} change data `, data);
  }, [data]);

  // useEffect(() => {
  //   // console.log(`UEF ${valueSufix} change startdate`, startDate);
  //   log.info(limaLogTag.UserStatisticHMcomp, `UEF ${colorVariant} change startdate `, startDate);
  //   changeStartDateCallBack(startDate);
  // }, [startDate]);

  const getClassName = (value: UserStatisticHeatMapType): string => {
    if (!value || value.count === 0) {
      return classNames["heatmapNoData"];
    } else if (value.count < rangeLimits[0]) return classNames["heatmapData_0_" + colorVariant];
    else if (value.count < rangeLimits[1]) return classNames["heatmapData_1_" + colorVariant];
    else if (value.count < rangeLimits[2]) return classNames["heatmapData_2_" + colorVariant];
    else if (value.count < rangeLimits[3]) return classNames["heatmapData_3_" + colorVariant];
    else if (value.count < rangeLimits[4]) return classNames["heatmapData_4_" + colorVariant];
    else return classNames["heatmapData_5_" + colorVariant];

    return "heatmapNoData";
  };

  const isDisabled = (): boolean => {
    if (startingDate !== undefined) {
      const momentStartDate = moment(startingDate).endOf("month");
      const nowDate = moment().endOf("month");
      const diff = nowDate.diff(momentStartDate, "months");
      if (diff > 0) return false;
    }

    return true;
  };

  return (
    <Stack>
      <Stack.Item>
        <Stack horizontal className={classNames.stackItemCenter}>
          <Stack.Item grow={true} className={classNames.stackItemCenter}>
            <button
              onClick={() => {
                // setStartDate(moment(startDate).add(-1, "M").toDate());
                changeStartDateCallBack(moment(startingDate).add(-1, "M").toDate());
              }}
            >
              -1 M
            </button>
          </Stack.Item>
          <Stack.Item grow={true} className={classNames.stackItemCenter}>
            <button
              disabled={isDisabled()}
              onClick={() => {
                // setStartDate(moment(startDate).add(1, "M").toDate());
                changeStartDateCallBack(moment(startingDate).add(1, "M").toDate());
              }}
            >
              +1 M
            </button>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item grow={true}>
        <CalendarHM
          // startDate={new Date("2016-01-01")}
          // endDate={new Date("2016-12-31")}
          startDate={shiftDate(startingDate, monthShift * -1)}
          endDate={startingDate}
          // values={dateValues}
          classForValue={getClassName}
          values={data}
          weekdayLabels={["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"]}
          showWeekdayLabels
          gutterSize={2}
          onClick={(value) => {
            console.log(`CLICKED: ${value.count}`, value);
            onHMClick(value.date);
          }}
          // titleForValue={(value) => {
          //   return value?.count ? `${value?.date} > ${value?.count}` : `No work registered for this day`;
          // }}
          tooltipDataAttrs={(value) => {
            if (value !== null && value?.date !== null)
              return {
                "data-tip": `${value?.date.toISOString().slice(0, 10)}: ${value?.count} ${valueSufix}`,
              };

            return {
              "data-tip": `no data`,
            };
          }}
        />
      </Stack.Item>
      <ReactTooltip />
    </Stack>
  );
};

export default UserStatisticHMcomp;
//
