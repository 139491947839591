import * as React from "react";
import limaStore from "../../../store/limaStore";
import { Route, RouteProps } from "react-router";
import LoginSheet from "../../components/LoginSheet";
import HomeCompLic from "../../uiv2/Home/HomeCompLic";
// import { useKeycloak } from "@react-keycloak/web";

export type ProtectedRouteProps = {
  //isAuthenticated: boolean;
  authenticationPath?: string;
} & RouteProps;

export default function ProtectedRoute({ ...routeProps }: ProtectedRouteProps): JSX.Element {
  //window.console.log("HEllo looks like not working lofin", routeProps);
  //authenticationPath
  // const { keycloak } = useKeycloak();

  // return (
  //   <Route {...routeProps} render={(props) => (keycloak?.authenticated ? <Route {...props} /> : keycloak.login())} />
  // );

  // return (
  //   <Route
  //     {...rest}
  //     render={props => (
  //       keycloak?.authenticated ? <Component {...props} /> : keycloak.login()
  //     )}
  //   />
  // )

  if (limaStore.logged === true && limaStore.userCompanyKey !== null && limaStore.userLicenced === true) {
    return <Route {...routeProps} />;
  } else if (limaStore.logged === true && (limaStore.userCompanyKey === null || limaStore.userLicenced === false)) {
    return <HomeCompLic />;
  } else {
    return <LoginSheet />;
    //<Redirect to={{ pathname: "./LoginSheet" }} />;
  }
}
