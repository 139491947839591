import * as React from "react";
import { EqualsParaState_ENUM, getEqualsPara } from "../../../../api/schema/getEqualsPara";
import { ITheme, Stack, Text, getTheme, mergeStyleSets } from "@fluentui/react";
import { LimaActionIconFileOpen } from "../../../../limaComponents/LimaActionIcons/LimaActionIconFileOpen";
// import Moment from "react-moment";
// import limaStore from "../../../../store/limaStore";
import { LimaIconToolsEqualParasSame } from "../../../../assets/icons/LimaIconToolsEqualParasSame";
import { LimaIconToolsEqualParasOrigin } from "../../../../assets/icons/LimaIconToolsEqualParasOrigin";
import { LimaIconToolsEqualParasNotTested } from "../../../../assets/icons/LimaIconToolsEqualParasNotTested";
import { LimaIconToolsEqualParasNotSame } from "../../../../assets/icons/LimaIconToolsEqualParasNotSame";
import { LimaActionIconCompareCopy } from "../../../../limaComponents/LimaActionIcons/LimaActionIconCompareCopy";
import { LimaActionIconSaveOther } from "../../../../limaComponents/LimaActionIcons/LimaActionIconSaveOther";
import moment = require("moment");
import limaStore from "../../../../store/limaStore";
// import { LimaActionIconSaveOther } from "../../../../assets/icons/LimaIconSaveOther";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISingleSameParaItemProps {
  item: getEqualsPara;
  onOpenFile: (item: getEqualsPara) => void;
  onUpdateFile: (item: getEqualsPara) => void;
  onShowCompareFile: () => void;
}

const theme: ITheme = getTheme();
const { palette } = theme;

//TODO: show only around changes
// textDecoration: "strikeout",
// backgrounColor: "lightred",
const classNames = mergeStyleSets({
  singleSameParaItemWrapper: {
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  actaName: {
    fontWeight: 500,
    color: "#969696",
    cursor: "default",
  },
  actaNameActive: {
    color: "black",
  },
});

export const SingleSameParaItem: React.FunctionComponent<ISingleSameParaItemProps> = (props) => {
  // const { t } = useTranslation();
  const isActive = props.item.compareState === EqualsParaState_ENUM.FOUND_SAME_PARA ? true : false;
  //-------------------
  //  REACTIONS
  //-------------------

  //-------------------
  //  HANDLERS
  //-------------------
  const onFileOpen = () => {
    props.onOpenFile(props.item);
  };

  const onFileUpdate = () => {
    props.onUpdateFile(props.item);
  };

  const onFileShow = () => {
    props.onShowCompareFile();
  };

  //---------------------
  //  RENDERS
  //-------------------

  const IconEqalState = (): JSX.Element => {
    if (props.item.compareState === EqualsParaState_ENUM.FOUND_SAME_PARA) {
      return <LimaIconToolsEqualParasSame size={12} title="Found" />;
    } else if (props.item.compareState === EqualsParaState_ENUM.NOT_SAME_PARACOUNT_SKIPPED_ACTA) {
      return <LimaIconToolsEqualParasNotTested size={12} title="Skipped - not same para count in doc" />;
    } else if (props.item.compareState === EqualsParaState_ENUM.NO_SAME_PARA_IN_ACTA) {
      return <LimaIconToolsEqualParasNotSame size={12} title="Not found" />;
    } else if (props.item.compareState === EqualsParaState_ENUM.ORIGIN_ACTA) {
      return <LimaIconToolsEqualParasOrigin size={12} title="Origin document" />;
    }
    return <></>;
  };

  return (
    <Stack className={classNames.singleSameParaItemWrapper}>
      <Stack.Item>
        <Stack horizontal style={{ alignItems: "center" }}>
          <Stack.Item style={{ minWidth: 17 }}>
            <IconEqalState />
          </Stack.Item>
          <Stack.Item
            style={{ flexGrow: 1 }}
            title={`last change date ${moment(props.item.lastChange).format(limaStore.userDateFormat).toString()}`}
          >
            <Text
              variant={"medium"}
              className={`${classNames.actaName} ${isActive === true && classNames.actaNameActive}`}
            >
              {props.item.actaName}
            </Text>
          </Stack.Item>
          {/* <Stack.Item>
            <Moment format={limaStore.userDateFormat}>{props.item.lastChange}</Moment>
          </Stack.Item> */}
          <Stack.Item style={{ minWidth: 96 }}>
            {props.item.compareState === EqualsParaState_ENUM.FOUND_SAME_PARA && (
              <Stack horizontal>
                <Stack.Item>
                  <LimaActionIconFileOpen onClick={onFileOpen} title="Open identical para document" />
                </Stack.Item>
                <Stack.Item>
                  <LimaActionIconCompareCopy
                    onClick={onFileShow}
                    title="NYI: Show compare on identical para document"
                    disabled={true}
                  />
                </Stack.Item>
                <Stack.Item>
                  <LimaActionIconSaveOther onClick={onFileUpdate} title="Merge into document" />
                </Stack.Item>
              </Stack>
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        {props.item.compareState === EqualsParaState_ENUM.FOUND_SAME_PARA && (
          <Text variant={"small"}>{`"${props.item.equalParaText}"`}</Text>
        )}
      </Stack.Item>
    </Stack>
  );
};

//----------------------
//   REACT QUERY
//----------------------
