import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { useTranslation } from "react-i18next";
import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../types/User2Task_withActaXcopy";
// import moment = require("moment");
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import { TaskData, TaskTypeEnum, XCOPY_TYPE } from "../../../types/Task";
import { IStackTokens, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { isTaskCreatorU2T } from "../../../limaCommon/limaTaskHelpers";
// import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import limaActasStore from "../../../store/limaActasStore";
import {
  convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy,
  Task_withActaXcopy_clone,
  Task_withActaXcopy_getNull,
} from "../../../types/Task_withActaXcopy";
import { reaction } from "mobx";
import AttachementComponentV4 from "../Tasks/Attachment/AttachementComponentV4";
import { observer } from "mobx-react-lite";
import { KnowledgetemDebugInfo } from "./components/KnowledgetemDebugInfo";
import React = require("react");
import { LimaControledTextFieldV4, LimaTextFiledTitlePlace } from "../../../limaComponents/LimaControledTextFieldV4";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../store/limaStore";
import { IAttachmentFileWithInfo } from "../../../types/LimaAttachement";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import ItemLinkingV4 from "../Tasks/components/ItemLinkingV4";
import { useTranslation } from "react-i18next";

interface IKnowledgetemV4Props {
  user2task: User2Task_withActaXcopyParasCommentsTasksMessagesAttachements | null;
  enableStore?: (enableStore: boolean) => void;
  xcopyKey: string;
  xcopyType: XCOPY_TYPE;
  onStartValidFiles: IAttachmentFileWithInfo[];
  // onValidFilesUpdateClbk: (files: File[]) => void;
}

const themedSmallStackTokens: IStackTokens = {
  childrenGap: "s1",
  padding: "s1",
};

const schemaKnowledge = yup.object().shape({
  taskName: yup.string().min(5, "tperrormsg:taskedit.tasknameshort"),
  taskDescription: yup.string().notRequired(),
});

const classNames = mergeStyleSets({
  knowledgeDescription: {
    // top: 191;
    // left: 191px;
    // width: 320px;
    // height: 58px;

    /* UI Properties */

    border: "0.5px solid #3A3A3C4D",
    borderRadius: "2",
    opacity: "1",
  },
  knowledgeName: {
    border: "0.5px solid #3A3A3C4D",
    borderRadius: "2",
    opacity: "1",
  },
});

const KnowledgetemV4: FunctionComponent<IKnowledgetemV4Props> = (props: IKnowledgetemV4Props) => {
  const { t } = useTranslation();
  const [dataWasChanged, setDataWasChanged] = useState<boolean>(null);

  const task = useMemo(() => {
    if (props.user2task === null) return Task_withActaXcopy_getNull();
    return convert_Task_withActaXcopyParasCommentsTasksMessagesAttachements2Task_withActaXcopy(
      props.user2task.task_withActaXcopyParasCommentsTasksMessagesAttachements
    );
  }, [props.user2task]);
  const isCreator = useMemo(
    () => (props.user2task === null ? true : isTaskCreatorU2T(props.user2task)),
    [props.user2task]
  );
  // const taskID = useMemo(() => task._key, [task._key]);
  // const [attachementsCount, setAttachementsCount] = useState<number | null>(null);
  const [linkedParasCount, setLinkedParasCount] = useState<number | null>(null);

  //--------------
  // Forms
  //--------------
  const methods = useForm({
    defaultValues: {
      taskName: task.taskName ? task.taskName : "",
      // taskAssigned: task.taskAssignedTo?._key ? task.taskAssignedTo._key : limaStore.userId,
      // taskDuedate: task.taskDueDate ? moment(task.taskDueDate).toDate() : "",
      taskDescription: task.taskDescription ? task.taskDescription : "",
    },
    resolver: yupResolver(schemaKnowledge),
    mode: "onSubmit",
    //reValidateMode: "onChange",
    // criteriaMode: "all",
  });
  const {
    // register,
    // handleSubmit,
    control,
    // setValue,
    // trigger,
    // getValues,
    // setError,
    watch,
    formState: { errors, isValid },
  } = methods;

  const valueOfTest = watch();
  //--------------
  // Reactions
  //--------------
  useEffect(() => {
    log.info(limaLogTag.TaskItem, `UEF taskItem: `, task);

    //CORRECTIONS
    // if (task.taskState === undefined || task.taskState === null || task.taskState === "") task.taskState = "open";

    //setup new temp edit data
    limaTasksStoreV4.setEditTask_withActaXcopy(Task_withActaXcopy_clone(task));
    if (limaTasksStoreV4.editTask_withActaXcopy.type !== TaskTypeEnum.NOTE) {
      limaTasksStoreV4.setEditTask_withActaXcopy({
        ...limaTasksStoreV4.editTask_withActaXcopy,
        type: TaskTypeEnum.NOTE,
      });
    }
    if (limaTasksStoreV4.editTask_withActaXcopy.taskData.xcopyKey === null) {
      limaTasksStoreV4.setEditTask_withActaXcopy({
        ...limaTasksStoreV4.editTask_withActaXcopy,
        taskData: {
          ...limaTasksStoreV4.editTask_withActaXcopy.taskData,
          actaKey: limaActasStore.getActaIdForXcopyIdAndXcopyType(
            props.xcopyKey,
            props.xcopyType === XCOPY_TYPE.MAINXCOPY ? OPENEDFILETYPE_ENUM.MAIN_COPY : OPENEDFILETYPE_ENUM.COPY
          ),
          xcopyKey:
            props.xcopyType === XCOPY_TYPE.MAINXCOPY
              ? limaActasStore.getMainCopyIdForActaId(props.xcopyKey)
              : props.xcopyKey,
          xcopytype: props.xcopyType,
        },
      });
    }
    //set default values
    setDataWasChanged(false);
    // if (enableStore !== undefined) enableStore(false);
    //update usersListIfNeeded
    // void limaUsersStore.checkLastRead().then(() => {
    //   log.info(limaLogTag.TaskItem, `check last readed user list: `, task, limaTasksStoreV4.editTask_withActaXcopy);
    //   if (limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo._key !== null) {
    //     setValue("taskAssigned", limaTasksStoreV4.editTask_withActaXcopy.taskAssignedTo._key);
    //   } else {
    //     setValue("taskAssigned", limaStore.userId);
    //   }
    // });
    void limaActasStore.checkLastRead().then(() => {
      log.info(limaLogTag.KnowledgetemV4, `check last readed acta acta loaded checked: `, task);
    });

    if (limaTasksStoreV4.editTask_withActaXcopy !== null) {
      // setAttachementsCount(limaTasksStoreV4.editTask_withActaXcopy.taskData.attachements.length);
      setLinkedParasCount(limaTasksStoreV4.editTask_withActaXcopy.taskData.paraIds.length);
    }

    //setAssignedUserOptions(limaUsersStore.getUsersListForAssignIncludeMe);

    const updateTaskStoreReactionDisposer = reaction(
      () => limaTasksStoreV4.editTask_withActaXcopy,
      () => {
        log.info(limaLogTag.KnowledgetemV4, `UEF: reaction on LimaTaskStrore Start`, isValid);
        log.info(
          limaLogTag.KnowledgetemV4,
          `UEF: reaction on LimaTaskStrore Edit`,
          limaTasksStoreV4.editTask_withActaXcopy
        );
        // checkIsSame();
        if (limaTasksStoreV4.isSameAsEditTask(task)) {
          //do nothing
          log.info(limaLogTag.KnowledgetemV4, `UEF: reaction on LimaTaskStrore Editast: are same`, task);
          //console.log("EDIT TASK Are same", editTask);
          if (dataWasChanged !== false) setDataWasChanged(false);
        } else {
          log.info(
            limaLogTag.TaskItem,
            `MobxReact: reaction on LimaTaskStrore Editast: are not same`,
            limaTasksStoreV4.editTask_withActaXcopy
          );
          if (dataWasChanged !== true) setDataWasChanged(true);
          //console.log("I'm not hungry!");
        }
      },
      { delay: 500 }
    );

    return () => {
      updateTaskStoreReactionDisposer();
    };
  }, []);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.KnowledgetemV4, `UEF updateReady change `, dataWasChanged, isValid, errors);
    if (props.enableStore !== undefined) {
      if (isValid) {
        props.enableStore(dataWasChanged);
      } else {
        props.enableStore(false);
      }
    }

    // if(updateReady! == isChanged)  enableStoreState(isChanged);
  }, [dataWasChanged, isValid]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.KnowledgetemV4, `UEF taskDueDate `, valueOfTest.taskName);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskName: valueOfTest.taskName,
    });
  }, [valueOfTest.taskName]);

  useEffect(() => {
    // window.console.log(`UEF errors`, errors);
    log.debug(limaLogTag.KnowledgetemV4, `UEF taskDescription `, valueOfTest.taskDescription);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskDescription: valueOfTest.taskDescription,
    });
  }, [valueOfTest.taskDescription]);

  useEffect(() => {
    log.info(limaLogTag.KnowledgetemV4, "UEF: validFiles", props.onStartValidFiles);
    // const startupFiles: IAttachmentFileWithInfo[] = [];
    // if (props.onStartValidFiles.length > 0) {
    //   props.onStartValidFiles.forEach((singleFile: File) =>
    //     startupFiles.push({ file: singleFile, filedescription: "", filename: singleFile.name })
    //   );
    // }
    limaTasksStoreV4.setEditTask_withActaXcopy_files(props.onStartValidFiles);
  }, [props.onStartValidFiles]);

  //-------------------------
  //----HANDLERS-------------
  //-------------------------

  const updateTaskData = (newTaskData: TaskData) => {
    log.info(limaLogTag.KnowledgetemV4, "updateTaskData setTask callback :SetData ", newTaskData);
    limaTasksStoreV4.setEditTask_withActaXcopy({
      ...limaTasksStoreV4.editTask_withActaXcopy,
      taskData: newTaskData,
    });
  };

  const updateTaskAttachemnts = (files: IAttachmentFileWithInfo[]) => {
    limaTasksStoreV4.setEditTask_withActaXcopy_files(files);
  };

  //-------------------------
  //----OBSERVERS-------------
  //-------------------------
  const KnowledgeItemDebuf_inner = observer(() => {
    if (limaTasksStoreV4.editTask_withActaXcopy) {
      return <KnowledgetemDebugInfo taskData={limaTasksStoreV4.editTask_withActaXcopy} taskCreator={isCreator} />;
    } else {
      return <></>;
    }
  });

  const LinkingObserver = observer(() => (
    <ItemLinkingV4
      itemData={limaTasksStoreV4.editTask_withActaXcopy?.taskData && limaTasksStoreV4.editTask_withActaXcopy.taskData}
      itemKey={limaTasksStoreV4.editTask_withActaXcopy?._key && limaTasksStoreV4.editTask_withActaXcopy._key}
      isItemOwner={limaTasksStoreV4.editTask_withActaXcopy?.cIssuedBy == limaStore.userId}
      enableAlwaysLinkParts={task.taskData.xcopytype !== XCOPY_TYPE.TRIMXCOPY ? true : false}
      onlyCreateXcopy={false}
      itemsLinkingCallBack={(newTaskData: TaskData) => {
        log.info(limaLogTag.KnowledgetemV4, "ItemLinking callback :SetData ", newTaskData);
        updateTaskData(newTaskData);
        //setTaskData({ ...taskData, taskData: { ...newTaskData } });
      }}
    />
  ));

  return (
    <Stack tokens={themedSmallStackTokens}>
      <Stack.Item>
        <KnowledgeItemDebuf_inner />
        <AttachementComponentV4
          attKeys={
            props.user2task !== null
              ? props.user2task.task_withActaXcopyParasCommentsTasksMessagesAttachements.taskData.attachements
              : []
          }
          // setUnsavedChanges={setUnsavedChanges}
          onTaskAttCountClb={(attCount: number) => {
            // setAttachementsCount(attCount);
            log.info(limaLogTag.KnowledgetemV4, "onTaskAttCountClb: ", attCount);
          }}
          setTaskDataAttCallBack={(newAtts: string[]) => {
            log.info(limaLogTag.KnowledgetemV4, "setTaskDataAttCallBack: ", newAtts);
            updateTaskData({
              ...limaTasksStoreV4.editTask_withActaXcopy.taskData,
              attachements: newAtts,
            });
          }}
          onValidFilesUpdateClbk={updateTaskAttachemnts}
          onOpenValidFiles={props.onStartValidFiles}
          enableUploadSingleFile={false}
          dropdownCleanup={0}
        />
        {/* <Stack.Item>{`linkedParas: ${linkedParasCount}`}</Stack.Item> */}
        <FormProvider {...methods}>
          <form>
            <>
              <Stack.Item>
                <LimaControledTextFieldV4
                  control={control}
                  labelPosition={LimaTextFiledTitlePlace.VERTICAL}
                  className={classNames.knowledgeName}
                  name="taskName"
                  limaLabel={t("tptasks:taskedit.taskitemV4.taskName.nameLbl")}
                  autoFocus={true}
                />
              </Stack.Item>
              <Stack.Item>
                <Text variant="small">{t("tptasks:taskedit.taskitemV4.taskName.nameLenghtInfo")}</Text>
              </Stack.Item>
              <Stack.Item>
                <LimaControledTextFieldV4
                  // limaLabel={t("tptasks:taskedit.taskitem2.taskdescrLbl")}
                  className={classNames.knowledgeDescription}
                  name="taskDescription"
                  multiline
                  height={"4em"}
                  control={control}
                  // underlined
                />
              </Stack.Item>
            </>
          </form>
        </FormProvider>
      </Stack.Item>
      <Stack.Item>
        <LimaHideableSection2 headerCount={String(linkedParasCount)} name="Linking" addEnabled={false}>
          <LinkingObserver />
        </LimaHideableSection2>
      </Stack.Item>
    </Stack>
  );
};

export default KnowledgetemV4;
