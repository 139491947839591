// import { log } from "missionlog";
import { Stack } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

import UserAddEdit from "../components/UserAddEdit";
import User2UserLinkComponent, { TOorFROM } from "./User2UserLinkComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserLinkingPageProps {}

const UserLinkingPage: FunctionComponent<UserLinkingPageProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.usersLbl"), t("tpmain:menu.users.adduserLbl")]} />

      <Stack>
        <Stack.Item>
          <UserAddEdit />
        </Stack.Item>
        <Stack.Item>
          <User2UserLinkComponent type={TOorFROM.FROM} />
        </Stack.Item>
        <Stack.Item>
          <User2UserLinkComponent type={TOorFROM.TO} />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default UserLinkingPage;

//----------------------
//   REACT QUERY
//----------------------
