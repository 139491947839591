import {
  DefaultButton,
  getFocusStyle,
  getTheme,
  IButtonStyles,
  IconButton,
  IIconProps,
  ITheme,
  mergeStyleSets,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { urlGetXcopyHistoryListForActa } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { actaListOK } from "../../../../../api/schema/getActaList";
import { insertAppendixFromTo2 } from "../../../../../limaCommon/limaAppendix";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../../types/BASE_Enums";
import { XCOPY_TYPE } from "../../../../../types/Task";
import { XcopyHistory } from "../../../../../types/XcopyHistory";
import { HistAppendixStateENUM, HistAppendixStateItem } from "./FileItemHistAppendix";
import FileItemOpenHistItem from "./FileItemOpenHistItem";

interface FileListVersionsProps {
  item: actaListOK;
  onFileClickHandler: (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE
  ) => void;
}

const AscOrderHistoryIcon: IIconProps = { iconName: "GroupedAscending" };
const DescOrderHistoryIcon: IIconProps = { iconName: "GroupedDescending" };

// log.init({ FileItemOpenList: "FileItemOpenList" });

const theme: ITheme = getTheme();
const { palette } = theme;

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    marginTop: 10,
  },
};

const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      //paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  itemFileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemFileContainerDisabled: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    fontWeight: "bold",
    color: palette.neutralDark,
  },
  itemFileIconsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "20%",
  },
  itemDate: {
    fontWeight: "400",
    fontSize: "10px",
    width: "100%",
  },
  itemStyleMax: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "100%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  historyItemsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  historyItemsSort: {
    width: 30,
  },
  historyItemsFiles: {
    flexGrow: 1,
  },
  shimmerWrapper: {
    padding: 2,
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  },
});

const FileListVersions: FunctionComponent<FileListVersionsProps> = ({
  item,
  onFileClickHandler,
}: FileListVersionsProps) => {
  // const [showHistoryListShimmer, setShowHistoryListShimmer] = useState<boolean>(false);
  const [histList, setHistoryList] = useState<XcopyHistory[]>([]);
  const [histListAppState, setHistoryListAppState] = useState<HistAppendixStateItem[]>([]);
  const [isDescOrderHistory, setIsDescOrderHistory] = useState<boolean>(true);
  const [getAppendixSTART, setGetAppendixSTART] = useState<string>("");
  const [getAppendixEND, setGetAppendixEND] = useState<string>("");
  const [createAppendix, setCreateAppendix] = useState<boolean>(false);
  const { isLoading, refetch } = useQuery(
    ["filehistory", item.id],
    () => fetchFileVersions(item.id, isDescOrderHistory),
    {
      enabled: false,

      onSuccess: (data: XcopyHistory[]) => {
        log.info(limaLogTag.FileListVersions, "useQuery get message ", data);
        setHistoryList(data);
        recalctHistListAppState("", "");
      },
      // onSettled: () => {
      //   setShowHistoryListShimmer(false);
      // },
    }
  );
  const { t } = useTranslation();

  const AppendixCreateBtn = observer((): JSX.Element => {
    return (
      <DefaultButton
        styles={buttonStyles}
        disabled={!createAppendix || limaStore.docxIsLoading}
        text={t("tpfile:fileopen.history.createappendixBtn")}
        onClick={() => onGetAppendixBtn()}
      />
    );
  });

  const onGetAppendixBtn = () => {
    //insertAppendixFromTo();
    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: ");
    let endActHist: XcopyHistory | null = null;
    let startActHist: XcopyHistory | null = null;

    histList.every((item: XcopyHistory) => {
      if (item._id === getAppendixSTART || item._id === getAppendixEND) {
        if (endActHist === null) endActHist = item;
        else if (startActHist === null) {
          startActHist = item;
          return false;
        }
      }
      return true;
    });

    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: start ", startActHist);
    log.info(limaLogTag.FileListVersions, "onGetAppendixBtn: end ", endActHist);

    void insertAppendixFromTo2(
      startActHist.xcopyId,
      LIMA_ITEM_TYPE.XCOPY,
      startActHist.historyTimeStamp,
      endActHist.xcopyId,
      LIMA_ITEM_TYPE.XCOPY,
      endActHist.historyTimeStamp,
      // limaStore.userId,
      startActHist.historyName,
      true
    );
  };

  /**
   * Recalculate all Hist appendix States
   */
  const recalctHistListAppState = (appItemFirst: string, appItemSecond: string) => {
    log.info(
      limaLogTag.FileListVersions,
      `recalctHistListAppState: getAppendixSTART[${appItemFirst}] getAppendixEND[${appItemSecond}]`
    );

    const outHisListState: HistAppendixStateItem[] = [];
    let startAndEndReady = false;
    if (appItemFirst !== "" && appItemSecond !== "") {
      !createAppendix && setCreateAppendix(false);
      startAndEndReady = true;
    } else {
      createAppendix && setCreateAppendix(false);
    }

    let isOut = true;
    histList.forEach((item: XcopyHistory) => {
      log.info(limaLogTag.FileListVersions, `histList.every: item[${item._id}]`);
      //From out to start
      if (isOut == true && (item._id == appItemFirst || item._id == appItemSecond)) {
        isOut = false;
        if (startAndEndReady) outHisListState.push({ appendixType: HistAppendixStateENUM.START });
        else outHisListState.push({ appendixType: HistAppendixStateENUM.STARTWITHOUTEND });
      }
      //not yet only one
      else if (!startAndEndReady) {
        log.info(limaLogTag.FileListVersions, `histList.every: no startEnd[${item._id}]`);
        outHisListState.push({ appendixType: HistAppendixStateENUM.NOTSETYET });
      }
      //From in to  end
      else if (isOut == false && (item._id == appItemFirst || item._id == appItemSecond)) {
        isOut = true;
        outHisListState.push({ appendixType: HistAppendixStateENUM.END });
      } else if (isOut == true) {
        outHisListState.push({ appendixType: HistAppendixStateENUM.OUT });
      } else if (isOut == false) {
        outHisListState.push({ appendixType: HistAppendixStateENUM.BETWEEN });
      }
    });
    log.info(
      limaLogTag.FileListVersions,
      `recalctHistListAppState: getAppendixSTART[${appItemFirst}] getAppendixEND[${appItemSecond}]`,
      outHisListState
    );
    setHistoryListAppState(outHisListState);
  };

  const onGetAppendixSubComponent = (itemID: string) => {
    //no start yet on getAppendixSTART -> item = START
    log.info(
      limaLogTag.FileListVersions,
      `onGetAppendixSubComponent: item [${itemID}] start[${getAppendixSTART}] end[${getAppendixEND}]`
    );
    if (getAppendixSTART === "") {
      recalctHistListAppState(itemID, "");
      setGetAppendixSTART(itemID);
      //return true;
      log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: ADD FIRST [${itemID}]`);
    }
    //have already getAppendixSTART and getAppendixEND && ID == getAppendixEND -> remove second
    else if (itemID === getAppendixEND) {
      recalctHistListAppState(getAppendixSTART, "");
      setGetAppendixEND("");
      createAppendix && setCreateAppendix(false);
      log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: REMOVE SECOND [${itemID}]`);
    }
    //click on getAppendixSTART 1. already END-> END->START, 2. no END->remove start
    else if (itemID === getAppendixSTART) {
      //REmove one from two
      if (getAppendixEND !== "") {
        recalctHistListAppState(getAppendixEND, "");
        setGetAppendixSTART(getAppendixEND);
        setGetAppendixEND("");
        createAppendix && setCreateAppendix(false);
        log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: REMOVE FIRST [${itemID}]`);
      }
      //REMOVE all
      else {
        recalctHistListAppState("", "");
        setGetAppendixSTART("");
        //createAppendix && setCreateAppendix(false);
        log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: REMOVE ALL [${itemID}]`);
      }
    }
    //have already getAppendixSTART but no END -> itemID = END
    else if (getAppendixEND === "") {
      setGetAppendixEND(itemID);
      recalctHistListAppState(getAppendixSTART, itemID);
      setCreateAppendix(true);
      log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: ADD SECOND [${itemID}]`);
      //return false;
    }
    //have already getAppendixSTART and getAppendixEND && ID != START ANI END
    else if (getAppendixEND !== "" && itemID !== getAppendixEND) {
      //DO NOTHING
      log.info(limaLogTag.FileListVersions, `onGetAppendixSubComponent: DO NOTHING [${itemID}]`);
    }

    // log.info(
    //   limaLogTag.FileListVersions,
    //   `onGetAppendix: dono getAppendixOne[${getAppendixSTART}] getAppendixTwo[${getAppendixEND}]  [${itemID}]`
    // );
    return true;
  };

  const changeHistoryOrderHandler = () => {
    setIsDescOrderHistory(!isDescOrderHistory);
  };

  useEffect(() => {
    void refetch();
  }, [isDescOrderHistory]);

  const ShowHistItems = (): JSX.Element => {
    return (
      <div key="histItemsWrapper" className={classNames.historyItemsWrapper}>
        <div key="sortorder" className={classNames.historyItemsSort}>
          {isDescOrderHistory ? (
            <IconButton
              className={classNames.itemCellIcon}
              iconProps={DescOrderHistoryIcon}
              onClick={changeHistoryOrderHandler}
            />
          ) : (
            <IconButton
              className={classNames.itemCellIcon}
              iconProps={AscOrderHistoryIcon}
              onClick={changeHistoryOrderHandler}
            />
          )}
        </div>
        <div key="histItems" className={classNames.historyItemsFiles}>
          {histList.map((singleHistItem: XcopyHistory, index: number, array: XcopyHistory[]) => (
            <FileItemOpenHistItem
              key={singleHistItem._id}
              // eslint-disable-next-line react/prop-types
              actaName={item.name}
              isActaOwner={item.isOwner}
              historyItem={singleHistItem}
              isLast={isDescOrderHistory ? index === 0 : index === array.length - 1}
              onFileOpenClickHandler={onFileClickHandler}
              getAppendix={onGetAppendixSubComponent}
              xcopyType={XCOPY_TYPE.MAINXCOPY}
              //appendixData={isAppendixPart(singleHistItem._id)}
              appendixData={histListAppState && histListAppState.length === array.length && histListAppState[index]}
            />
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // void getHistoryLinks();
    // setShowHistoryListShimmer(true);
    void refetch();
  }, []);

  if (isLoading) {
    return LimaLoadingComponnet(3);
  }

  return (
    <>
      <AppendixCreateBtn />
      {/* {showHistoryListShimmer ? (
        <div className={classNames.shimmerWrapper}>
          <Shimmer width="80%" />
          <Shimmer width="80%" />
          <Shimmer width="80%" />
        </div>
      ) : ( */}
      <ShowHistItems />
      {/* )} */}
    </>
  );
};

export default FileListVersions;

//----------------------
//   REACT QUERY
//----------------------

const fetchFileVersions = async (xcopyId: string, isDescOrderHistory: boolean): Promise<XcopyHistory[]> => {
  log.info(limaLogTag.FileListVersions, "fetchFileVersion data");
  const loaderID = limaLoadeStore.add("Getting fetchFileVersion from DB");
  try {
    // const response: HttpResponse<ActaHistory[]> = await httpGet(
    //   urlGetActaHistList(xcopyId, isDescOrderHistory ? "desc" : "asc", limaStore.userId, "", "")
    // );
    const response: HttpResponse<XcopyHistory[]> = await httpGetAuth(
      // urlGetXcopyHistoryListForActa(xcopyId, isDescOrderHistory ? "desc" : "asc", limaStore.userId, "", ""),
      urlGetXcopyHistoryListForActa(xcopyId, isDescOrderHistory ? "desc" : "asc", "", ""),
      loaderID.ac
    );

    if (!response.ok) {
      throw response;
    }
    log.info(limaLogTag.FileListVersions, "getHistoryLinks: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.FileListVersions, "getHistoryLinks: parsedBody is not null", response.parsedBody);
      return response.parsedBody;
    }

    throw new Error("no data");
  } catch (error) {
    throw new Error(error);
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
};

// const getHistoryLinks = async () => {
//   let response: HttpResponse<ActaHistory[]>;

//   // setShowHistoryListShimmer(true);

//   try {
//     response = await httpGet(
//       urlGetActaHistList(item.id, isDescOrderHistory ? "desc" : "asc", limaStore.userId, "", "")
//     );
//     log.info(limaLogTag.FileListVersions, "getHistoryLinks: asketo to get something", response);
//     if (response.parsedBody !== undefined) {
//       log.info(limaLogTag.FileListVersions, "getHistoryLinks: parsedBody is not null", response.parsedBody);
//       setHistoryList(response.parsedBody);
//       recalctHistListAppState("", "");
//       setShowHistoryListShimmer(false);
//     }
//   } catch (error) {
//     log.error(limaLogTag.FileListVersions, "getHistoryLinks: error whe calling ", error);
//     //setShowHistoryList(false);
//   }
// };
