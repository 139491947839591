import { Stack } from "@fluentui/react";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LimaIconToolsAccess } from "../../../../assets/icons/LimaIconToolsAccess";
import { LimaIconToolsAddUser } from "../../../../assets/icons/LimaIconToolsAddUser";
import { LimaIconToolsUsersList } from "../../../../assets/icons/LimaIconToolsUsersList";
import Routes from "../../../Menu/routes";
import { TOorFROM, useGetUser2UserListQuery } from "../../Users/UserInitations/components/User2UserLinksComponentV4";
import LimaToolItem from "./components/LimactaToolItem";
// import { LimaIconToolsTimer } from "../../../../assets/icons/LimaIconToolsTimer";
// import { LimaActionIconLicencesPage } from "../../../../limaComponents/LimaActionIcons/LimaActionIconLicencesPage";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";
// import { LimaActionIconTimersPage } from "../../../../limaComponents/LimaActionIcons/LimaActionIconTimersPage";
// import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";
import {
  LIMA_ACTION_BUTTON_TYPE,
  LimaActionIconDefault,
} from "../../../../limaComponents/LimaActionIcons/LimaActionIconDefault";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToolsUsersPartProps {}

const ToolsUsersPart: React.FunctionComponent<IToolsUsersPartProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { refetch, data } = useGetUser2UserListQuery(TOorFROM.COUNT);

  const linkingReqCount = useMemo(() => {
    return data ? data.count : "-";
  }, [data]);
  //----------------------
  // Reactions
  //----------------------

  useEffect(() => {
    void refetch();
  }, []);

  return (
    <Stack horizontal wrap={true}>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.user.inviteuserLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.UserInviteRoute}`);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserAddLinkUser}
        >
          <LimaIconToolsAddUser title={t("tpmain:tools.user.inviteuserLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "ContactLink" }}
          toolName={t("tpmain:tools.user.initations4userLbl")}
          count={linkingReqCount}
          info="Tady je super info"
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.UserInvitionsRoute}`);
          }}
        ></LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolIcon={{ iconName: "ContactList" }}
          toolName={t("tpmain:tools.user.listusersLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(`${Routes.UserListRoute}`);
          }}
        >
          <LimaIconToolsUsersList title={t("tpmain:tools.user.listusersLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem toolName={t("tpmain:tools.users.accessToolLbl")} toolItemWidth={75} notYetImplemented>
          <LimaIconToolsAccess title={t("tpmain:tools.users.accessToolLbl")} size={32} />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.actions.userLicenceslLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(Routes.UserLicencesRoute);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserLicenceManager}
          dontShowOnNoLic={true}
        >
          <LimaActionIconDefault
            type={LIMA_ACTION_BUTTON_TYPE.LICENCES_PAGE}
            title={t("tpmain:tools.actions.userLicenceslLbl")}
            size={32}
          />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.actions.userCompanyLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(Routes.UserCompaniesRoute);
          }}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserCompanyManager}
          dontShowOnNoLic={true}
        >
          <LimaActionIconDefault
            type={LIMA_ACTION_BUTTON_TYPE.COMPANY_PAGE}
            title={t("tpmain:tools.actions.userCompanyLbl")}
            size={32}
          />
        </LimaToolItem>
      </Stack.Item>
      <Stack.Item>
        <LimaToolItem
          toolName={t("tpmain:tools.actions.timersToolLbl")}
          toolItemWidth={75}
          onClickClb={() => {
            history.push(Routes.LimactaTimerRoute);
          }}
        >
          <LimaActionIconDefault
            type={LIMA_ACTION_BUTTON_TYPE.TIMERS_PAGE}
            title={t("tpmain:tools.actions.timersToolLbl")}
            size={32}
          />
        </LimaToolItem>
      </Stack.Item>
    </Stack>
  );
};

export default ToolsUsersPart;
