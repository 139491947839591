import { Pivot, PivotItem } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import AccessComponent from "../../../CommonComponents/Access/AccessComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UniversalAccessProps {}

const UniversalAccess: FunctionComponent<UniversalAccessProps> = () => {
  const { t } = useTranslation();

  const onPivotChange = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    log.info(limaLogTag.UniversalAccess, "on pivot change", item.props.id, ev);
    // if (item.props.id === "TASK") showSubmitButton && setShowSubmitButton(false);
    // else {
    //   !showSubmitButton && setShowSubmitButton(true);
    // }
  };

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.showLbl"), t("tpmain:menu.show.accessLbl")]} />
      {/* <AccessComponent enableChange showLinkedItem /> */}
      <Pivot aria-label="Acta" onLinkClick={onPivotChange}>
        <PivotItem headerText={t("tpshow:access.pivot.taskPivotLbl")} id={"TASK"} itemIcon="ContactLock">
          <AccessComponent type={LIMA_ITEM_TYPE.TASK} showLinkedItem={true} />
        </PivotItem>
        <PivotItem headerText={t("tpshow:access.pivot.xcopyPivotLbl")} id={"XCOPY"} itemIcon="ContactLock">
          <AccessComponent type={LIMA_ITEM_TYPE.XCOPY} showLinkedItem={true} />
        </PivotItem>
      </Pivot>
    </>
  );
};

export default UniversalAccess;
