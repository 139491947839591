import * as React from "react";
import { FunctionComponent } from "react";
// import { LimaIconFileOpen } from "../../assets/icons/LimaIconFileOpen";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { Icon, IIconProps } from "@fluentui/react";

export interface ILimaActionIconGetPDFProps {
  onClick: () => void;
}

const getPDFIcon: IIconProps = { iconName: "PDF" };

export const LimaActionIconGetPDF: FunctionComponent<ILimaActionIconGetPDFProps> = (
  props: ILimaActionIconGetPDFProps
) => {
  return (
    <div
      className={`${classNamesCommon.LimaActionIconButton} ${
        limaStore.docxIsLoading
          ? classNamesCommon.LimaActionIconButton_wait
          : classNamesCommon.LimaActionIconButton_enabled
      }`}
      onClick={() => {
        if (props.onClick !== undefined) {
          props.onClick();
        }
      }}
    >
      <Icon {...getPDFIcon} style={{ color: "#0078D4", fontSize: 20 }} />
    </div>
  );
};
