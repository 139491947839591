import { log } from "missionlog";
import { FunctionComponent, useEffect } from "react";
import React = require("react");
import { useHistory } from "react-router";
import { limaLogTag } from "../../limaCommon/limaLog";
import limaStore from "../../store/limaStore";
import limaUsersStore from "../../store/limaUsersStore";
import Routes from "../Menu/routes";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginProcessingProps {}

const LoginProcessing: FunctionComponent<LoginProcessingProps> = () => {
  const history = useHistory();

  useEffect(() => {
    log.info(limaLogTag.LoginProcessing, "onLogin: logging");
    limaStore.setLogged(true);
    limaStore.selectionSubscribe();
    void limaUsersStore.getDataFromDB().then(() => {
      window.console.log("HELLLOOO LoginProcessing UEF");
      history.push(`${Routes.HomeV2Route}`);
    });
  }, [limaStore.userId]);

  return <>Processing login, please wait....</>;
};

export default LoginProcessing;
