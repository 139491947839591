import { log, tag } from "missionlog";
import { urlGetAppendixInsertDocTS } from "../api/apilinks";
import limaLoadeStore, { LoaderItemEnd } from "../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../store/limaStore";
import { LimaDocData, LimaDocTypeENUM } from "../types/docxData";
import { insertDoc } from "./limaInsertDoc";

log.init({ limaAppendix: "limaCommon/limaAppendix" });

// /**
//  * generate and insert into document appendix
//  * @param fileIdOrigin id of origin file Starting point
//  * @param timestampOrigin timestamp of origin file Starting point
//  * @param fileIdFinal id of final file Ending point
//  * @param timestampFinal timestamp of final file Ending point
//  * @param fileName fileneme
//  *
//  * @author MS
//  */
// export const insertAppendixFromTo = async (
//   fileIdOrigin: string,
//   timestampOrigin: string,
//   fileIdFinal: string,
//   timestampFinal: string,
//   fileName: string
// ) => {
//   //limaStore.setLoader(true);
//   let loaderID = limaLoadeStore.add("get appendix");
//   log.info(tag.limaAppendix, "insertAppendixFromTo start");

//   await insertDoc(
//     fileIdOrigin,
//     urlGetAppendixInsertDocTS(fileIdOrigin, timestampOrigin, fileIdFinal, timestampFinal, limaStore.userId),
//     fileName,
//     LimaDocTypeENUM.ACTA,
//     (data: LimaDocData) => {
//       limaStore.setActaId(data.limactaDocID);
//       limaStore.setActaName(data.limactaDocName);
//       limaLoadeStore.remove(loaderID.k);
//     }
//   );
// };

/**
 * generate and insert into document appendix
 * @param originId origin object ID
 * @param originType orgin object Type (XCOPY?)
 * @param originTS origin timestamp
 * @param compareId final object ID
 * @param compareType final  object Type (XCOPY?)
 * @param compareTS final timestamp
 * @param userId userID who ask to create apppendinx
 * @param fileName filename used
 * @param autoAddin instruct limacta to open addin weith document
 *
 * @author MS
 */
export const insertAppendixFromTo2 = async (
  originId: string,
  originType: string,
  originTS: string,
  compareId: string,
  compareType: string,
  compareTS: string,
  // userId: string,
  fileName: string,
  autoAddin: boolean
): Promise<void> => {
  //limaStore.setLoader(true);
  const loaderID = limaLoadeStore.add("get appendix");
  log.info(tag.limaAppendix, "insertAppendixFromTo start");

  await insertDoc(
    originId,
    // urlGetAppendixInsertDocTS(fileIdOrigin, timestampOrigin, fileIdFinal, timestampFinal, limaStore.userId),
    urlGetAppendixInsertDocTS(originId, originType, originTS, compareId, compareType, compareTS, autoAddin),
    fileName,
    LimaDocTypeENUM.APPENDIX,
    (data: LimaDocData) => {
      limaStore.setActaId(data.limactaDocID);
      limaStore.setActaName(data.limactaDocName);
      limaStore.setMainXcopyId("");
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.APPENDIX);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      // limaLoadeStore.remove(loaderID.k);
    },
    () => {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    },
    loaderID
  );

  return;
};

/**
 * generate and insert into document appendix
 * @param originId origin object ID
 * @param originType orgin object Type (XCOPY?)
 * @param originTS origin timestamp
 * @param compareId final object ID
 * @param compareType final  object Type (XCOPY?)
 * @param compareTS final timestamp
 * @param userId userID who ask to create apppendinx
 * @param fileName filename used\
 * @param autoaddin instruct limacta to set docuemtn to autopen addin
 *
 * @author MS
 */
export const openAppendixFromTo2 = async (
  originId: string,
  originType: string,
  originTS: string,
  compareId: string,
  compareType: string,
  compareTS: string,
  fileName: string,
  autoaddin: boolean
): Promise<void> => {
  //limaStore.setLoader(true);
  const loaderID = limaLoadeStore.add("get appendix");
  log.info(tag.limaAppendix, "openAppendixFromTo2 start");

  await insertDoc(
    originId,
    urlGetAppendixInsertDocTS(originId, originType, originTS, compareId, compareType, compareTS, autoaddin),
    fileName,
    LimaDocTypeENUM.APPENDIX,
    (data: LimaDocData) => {
      limaStore.setActaId(data.limactaDocID);
      limaStore.setActaName(data.limactaDocName);
      limaStore.setMainXcopyId("");
      limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.APPENDIX);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      // limaLoadeStore.remove(loaderID.k);
    },
    () => {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    },
    loaderID,
    true
  );

  return;
};
