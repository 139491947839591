import React = require("react");
import { FunctionComponent } from "react";
import { log, tag } from "missionlog";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";
import { onLoggedOut } from "../../limaCommon/limaUser";
import { useTranslation } from "react-i18next";
import { ITheme, getTheme, mergeStyleSets } from "@fluentui/react";

log.init({ Logout: "Logout" });

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette } = theme;

const classNames = mergeStyleSets({
  logoLogout: {
    fontSize: 12,
    fontWeight: 700,
    cursor: "pointer",
    paddingLeft: 5,
    paddingRight: 25,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});

const Logout: FunctionComponent = () => {
  //let history = useHistory();
  const { t } = useTranslation();

  const onLogout = () => {
    log.info(tag.Logout, "onLogout: logging out");
    onLoggedOut();
  };

  const LogoutLogin = observer(() => {
    if (limaStore.logged)
      return (
        <span onClick={onLogout} className={classNames.logoLogout}>
          {t("tpmain:login.lougoutBtnLbl")}
        </span>
      );
    else return <></>;
  });

  return <LogoutLogin />;
};

export default Logout;
