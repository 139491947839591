import * as React from "react";
import { FunctionComponent } from "react";
// import { LimaIconFileOpen } from "../../assets/icons/LimaIconFileOpen";
import { classNamesCommon } from "../LimaComponentsCommonStyles";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";
import { LimaIconToolsSaveFile } from "../../assets/icons/LimaIconToolsSaveFile";

export interface ILimaActionIconSaveFileProps {
  onClick: () => void;
}

// const compareCopyIcon: IIconProps = { iconName: "BranchCompare" };

export const LimaActionIconSaveFile: FunctionComponent<ILimaActionIconSaveFileProps> = observer(
  (props: ILimaActionIconSaveFileProps) => {
    return (
      <div
        className={`${classNamesCommon.LimaActionIconButton} ${
          limaStore.docxIsLoading
            ? classNamesCommon.LimaActionIconButton_wait
            : classNamesCommon.LimaActionIconButton_enabled
        }`}
        onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick();
          }
        }}
      >
        {/* <LimaIconFileOpen /> */}
        <LimaIconToolsSaveFile size={24} />
      </div>
    );
  }
);
