import React = require("react");
import { FunctionComponent } from "react";
// import { Toggle, Label, IStackStyles, DefaultPalette } from "office-ui-fabric-react";
import LimaLanguageSwitcher from "../../../limaComponents/LimaLanguageSwitcher";
import { log } from "missionlog";
import limaStore, { UI_VERSION } from "../../../store/limaStore";
import limaTagsStore from "../../../store/limaTagsStore";
import APISwitch from "./components/APISwitch";
import { DefaultButton, DefaultPalette, IStackStyles, Label, Stack, Text, Toggle } from "@fluentui/react";
import limaLoadeStore from "../../../store/limaLoaderStore";
import { useTranslation } from "react-i18next";
// import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";
import { useMutation } from "react-query";
import limaUsersStore from "../../../store/limaUsersStore";
import { limaQuery_setLang } from "../../../api/calls/limaUserCalls";
import { ICallUser, LANGUAGES, IUser } from "../../../types/User";
import limaTimerStore from "../../../store/limaTimerStore";
import { observer } from "mobx-react-lite";
import { LIMAENUM_AccesLicPermIdent } from "../../../limaCommon/limaPermissions";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import UserPermissionEditPage from "../Users/UsersList/userPermissions/UserPermissionEditPage";
import { limaLogTag } from "../../../limaCommon/limaLog";

const stackTokens = { childrenGap: 10 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    color: DefaultPalette.black,
    padding: "15px",
  },
};

const UserSetting: FunctionComponent = observer(() => {
  const { t, i18n } = useTranslation();
  const { mutateAsync: mutateSetUserLang } = useUpdateLanguage();

  //-------------------
  // Handlers
  //-------------------

  const onChangeDebugInfo = (ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(limaLogTag.UserSetting, "onChangeDebugInfo: toggle is", checked ? "checked" : "not checked");
    log.info(limaLogTag.UserSetting, "onChangeDebugInfo: env", ev);

    if (checked) {
      limaStore.setShowTestingInfo(true);
    } else {
      limaStore.setShowTestingInfo(false);
    }
  };

  const onChangeHighlight = (ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(limaLogTag.UserSetting, "onChangeHighlight: toggle is", checked ? "checked" : "not checked");
    log.info(limaLogTag.UserSetting, "onChangeHighlight: env", ev);
  };

  const onChangeVersion = (_ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    log.info(limaLogTag.UserSetting, "onChangeVersion: toggle is", checked ? "checked" : "not checked");
    //log.info(limaLogTag.UserSetting, "onChangeHighlight: env", ev);

    if (checked) {
      limaStore.setUIVersion(UI_VERSION.VERSION_4);
    } else {
      limaStore.setUIVersion(UI_VERSION.VERSION_2);
    }
  };

  const onLangChange = (langKey: LANGUAGES) => {
    //if (userId === undefined || userId === limaStore.userId) {
    log.info(limaLogTag.UserSetting, "onLangChange ", langKey);

    //}
    void mutateSetUserLang({
      userId: limaStore.userId,
      lang: langKey,
      userEmail: null,
      userName: null,
    }).then((changeUser) => {
      void i18n.changeLanguage(changeUser.lang);
      limaStore.setLanguage(changeUser.lang); //TODO: asi nepotrebujeme
    });
  };

  const onChangeTimer = (_ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    limaTimerStore.setTimerIsActive(checked);
  };

  return (
    <>
      {/* <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.editLbl"), t("tpmain:menu.edit.settingLbl")]} /> */}
      <Stack key="SettingStack" tokens={stackTokens} styles={stackStyles}>
        <Stack.Item>
          <Text variant={"large"}>{t("tpmain:settings.setingsLbl")}</Text>
        </Stack.Item>
        <Stack.Item>
          <Label>{t("tpmain:settings.setlangLbl")}</Label>
          <LimaLanguageSwitcher onChange={onLangChange} />
        </Stack.Item>
        <Stack.Item>
          <Label>{t("tpmain:settings.timerTglLbl")}</Label>
          <Toggle
            key="timerToggle"
            onChange={onChangeTimer}
            role="checkbox"
            defaultChecked={limaTimerStore.timerIsActive}
          />
        </Stack.Item>
        <LimaHideableSection2
          name={t("tpuser:userdetial.permissionsLbl")}
          addEnabled={false}
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcUserPermissions}
          dontShowOnNoLic={true}
        >
          <p>{t("tpuser:userdetial.permissionsInfoLbl")}</p>
          <UserPermissionEditPage
            userId={limaStore.userId}
            actaId={limaStore.actaId}
            companyKey={limaStore.userCompanyKey}
          />
        </LimaHideableSection2>
        <LimaHideableSection2
          name="DEVELOP SETTING"
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}
          dontShowOnNoLic={true}
        >
          <Stack.Item>
            <Label>UI VERSION 2/4</Label>
            <Toggle
              key="uiVersionChange"
              onChange={onChangeVersion}
              role="checkbox"
              defaultChecked={limaStore.ui_version === UI_VERSION.VERSION_4}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>Allow Highlight</Label>
            <Toggle
              key="searchActParInstantToggle"
              onChange={onChangeHighlight}
              role="checkbox"
              defaultChecked={limaTagsStore.highlightOption}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>Show debug info</Label>
            <Toggle
              key="searchActParInstantToggle"
              onChange={onChangeDebugInfo}
              role="checkbox"
              defaultChecked={limaStore.showTestingInfo}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>Set language</Label>
            <LimaLanguageSwitcher onChange={onLangChange} />
          </Stack.Item>
          <Stack.Item>
            <APISwitch hideSwitch={false} />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              // disabled={docId ? false : true}
              text="Clear loader"
              onClick={() => limaLoadeStore.cleanup()}
            />
          </Stack.Item>
        </LimaHideableSection2>
      </Stack>
    </>
  );
});

export default UserSetting;

//-------------------------------------------
//  QUERIES
//------------------------------

const useUpdateLanguage = () => {
  return useMutation(limaQuery_setLang, {
    onMutate: ({ userId, lang }: ICallUser) => {
      log.info(limaLogTag.UserSetting, "call mutate useUpdateLanguage ", userId, lang);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserSetting, "call mutate useUpdateLanguage ", data);
      //Update listUsers
      limaUsersStore.currentUser.lang = data.lang;
      //limaUsersStore.addListSingleItem(data);
    },
  });
};
