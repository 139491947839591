import {
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  mergeStyleSets,
  Stack,
  Text,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { urlUpdateXcopyOwnerShip } from "../../../../api/apilinks";
import { httpPostAuth } from "../../../../api/httpAPI";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../store/limaLoaderStore";
import limaStore from "../../../../store/limaStore";
import limaUsersStore from "../../../../store/limaUsersStore";
import { IUser } from "../../../../types/User";

interface FileItemChangeOwnerV4Props {
  // item: actaListOK;
  ownerKey: string;
  mainXcopy: string;
  xcopyTimeStamp: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeItem: (ownerKey: string) => void;
}

const classNames = mergeStyleSets({
  changeOwnerInfo: {
    color: "red",
    weight: "700",
  },
});

const FileItemChangeOwnerV4: FunctionComponent<FileItemChangeOwnerV4Props> = (props: FileItemChangeOwnerV4Props) => {
  const [storedError, setStoreError] = useState<boolean>(false);
  const [innerNewOwner, setInnerNewOwner] = useState<string>();
  const [oldOwnerFinalRole, setOldOwnerFinalRole] = useState<string | undefined>("NONE");
  const { t } = useTranslation(["tpfile"]);

  //--------------
  // Reactions
  //--------------

  useEffect(() => {
    setInnerNewOwner(props.ownerKey);
    limaUsersStore.checkLastRead();
  }, []);

  const onRoleChange = React.useCallback((_ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setOldOwnerFinalRole(option.key);
  }, []);

  //--------------
  // Handlers
  //--------------

  const onStore = () => {
    log.info(limaLogTag.FileItemChangeOwnerV4, "onStore: ", props, innerNewOwner, oldOwnerFinalRole);
    updateActaOwnership(props.mainXcopy, innerNewOwner, props.xcopyTimeStamp, oldOwnerFinalRole)
      .then(() => {
        setStoreError(false);
        props.changeItem(innerNewOwner);
        // if (limaStore.actaId == item.id) limaStore.setIsOwner(false);
        if (limaStore.mainXcopyId == props.mainXcopy && innerNewOwner !== limaStore.userId) limaStore.setIsOwner(false);
      })
      .catch(() => {
        setStoreError(true);
      });
  };

  //--------------
  // Renders
  //--------------

  const NewOwnerList = observer(() => {
    return (
      <Dropdown
        label={t("tpfile:fileopen.changeowner.changeownerDropdonwnLbl")}
        selectedKey={innerNewOwner}
        options={limaUsersStore.fullList.map((userItem: IUser) => {
          return { key: userItem._key, text: userItem.userName };
        })}
        onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
          if (option !== undefined) {
            log.info(limaLogTag.FileItemChangeOwnerV4, "Dropdown onchange to ", option);
            setInnerNewOwner(option.key.toString());
          }
        }}
      />
    );
  });

  return (
    <Stack>
      <Stack.Item>
        <Text className={classNames.changeOwnerInfo}>
          {t("tpfile:fileopen.changeowner.changeownerNotificationLbl")}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <NewOwnerList />
      </Stack.Item>
      <Stack.Item>
        <ChoiceGroup
          disabled={innerNewOwner === props.ownerKey}
          defaultSelectedKey="NONE"
          options={[
            { key: "NONE", text: t("tpfile:fileopen.changeowner.lastOwnerFinalRoleNoneLbl") },
            { key: "VIEWER", text: t("tpfile:fileopen.changeowner.lastOwnerFinalRoleViewerLbl") },
          ]}
          onChange={onRoleChange}
          label={t("tpfile:fileopen.changeowner.changeownerFinalRoleLbl")}
          required={true}
        />
      </Stack.Item>
      <Stack.Item>
        <DefaultButton
          key="btnChangeOwner"
          className="ms-welcome__action"
          onClick={onStore}
          disabled={innerNewOwner == props.ownerKey}
        >
          {t("tpfile:fileopen.changeowner.changeownerButtonLbl")}
        </DefaultButton>
      </Stack.Item>
      {storedError && (
        <Stack.Item>
          <Text variant="small">{t("tpfile:fileopen.changeowner.notOwnerNotificationLbl")}</Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default FileItemChangeOwnerV4;

//------------
// QUERIES
//------------

const updateActaOwnership = async (
  xcopyId: string,
  newUserId: string,
  timeStamp: string,
  enableOldToSee: string
): Promise<string> => {
  if (!xcopyId || !xcopyId) {
    log.warn(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: no task id or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  const loaderID = limaLoadeStore.add("update ownership of document");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  try {
    // let response: HttpResponse<any>;
    const response = await httpPostAuth<string>(
      urlUpdateXcopyOwnerShip(xcopyId),
      {
        userid: null,
        newownerid: newUserId,
        oldownerid: limaStore.userId,
        oldownerrole: enableOldToSee !== "NONE" ? "VIEWER" : null,
        timestamp: timeStamp,
      },
      loaderID.ac
    );

    log.info(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: asketo to get something", response);
    if (!response.ok) {
      // throw new Error("Problem geting data");
      log.error(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: Problem geting data");
      return Promise.reject();
    }
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: parsedBody is not null", response.parsedBody);
      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        resolve(response.parsedBody);
      });
    } else {
      // throw new Error("...No data...");
      log.error(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: parsedBody isnull");
      return Promise.reject();
    }
  } catch (response) {
    log.error(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership Error call ", response);
    // throw new Error("Problem geting data");
    log.error(limaLogTag.FileItemChangeOwnerV4, "updateActaOwnership: Problem geting data");
    return Promise.reject();
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
  // });
};
