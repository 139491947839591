import * as React from "react";
import { FunctionComponent } from "react";
import { LIMAENUM_AccesLicPermIdent } from "../limaCommon/limaPermissions";
import limaPermissionStore from "../store/limaPermissionStore";

interface ILimaLicPermShowProps {
  accLicPermIdent?: LIMAENUM_AccesLicPermIdent;
  dontShowOnNoLic?: boolean;
  children?: React.ReactNode;
  noLicComponentShow?: React.ReactNode;
  stateOverride?: boolean;
}

const LimaLicPermShow: FunctionComponent<ILimaLicPermShowProps> = (props: ILimaLicPermShowProps) => {
  let isAccesable =
    props.accLicPermIdent === undefined
      ? true
      : limaPermissionStore.checkAccLicenceAndPermissions(props.accLicPermIdent);

  if (props.stateOverride !== undefined) {
    if (props.stateOverride !== isAccesable) {
      isAccesable = props.stateOverride;
    }
  }
  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  if (isAccesable === false) {
    if (props.dontShowOnNoLic !== undefined && props.dontShowOnNoLic === true) {
      return <></>;
    } else if (props.noLicComponentShow !== undefined) return <>{props.noLicComponentShow}</>;
  }

  return <>{props.children}</>;
};
export default LimaLicPermShow;

//----------------------
//   REACT QUERY
//----------------------
