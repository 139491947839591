import { Pivot, PivotItem, Text } from "@fluentui/react";
import * as React from "react";
// import { LimaEqualParasList } from "./component/LimaEqualParasList";
import { PrimaryButton, Stack } from "office-ui-fabric-react";
import limaStore from "../../../store/limaStore";
import { WORDITEMTYPE } from "../../../api/apilinks";
// import { observer } from "mobx-react-lite";
import { SelectedInWord } from "../../../types/wordObjects";
import { useTranslation } from "react-i18next";
import HistParaItemParaTextItems from "../History/components/HistParaItemParaTextItems";
import moment = require("moment");
import { useEffect, useState } from "react";
import { reaction } from "mobx";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../limaComponents/LimaNotice";
import { LimaEqualParasList2 } from "./component/LimaEqualParasList2";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaEqualChangePageProps {}

export const LimaEqualChangePage: React.FunctionComponent<ILimaEqualChangePageProps> = () => {
  const { t } = useTranslation();
  const [paraKey, setParaKey] = React.useState<string>(null);
  const [dateFrom, setDateFrom] = React.useState<moment.Moment>(moment().subtract("1", "y"));
  const [dateTo, setDateTo] = React.useState<moment.Moment>(moment());
  const [timeStamp, setTimeStamp] = React.useState<string>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<SelectedInWord | null>(
    (limaStore.selectedInWord && limaStore.selectedInWord[0]) || null
  );

  //-------------------
  //  REACTIONS/OBSERVERS
  //-------------------
  // const SelectedPara = observer((): JSX.Element => {
  //   const firstParaInSelection = limaStore.selectedInWord.find(
  //     (item: SelectedInWord) => item.type === WORDITEMTYPE.para
  //   );
  //   if (firstParaInSelection !== undefined) return <>{firstParaInSelection.id}</>;
  //   return <>---</>;
  // });
  useEffect(() => {
    const disposer = reaction(
      () => limaStore.selectedInWord,
      (selected) => {
        log.info(limaLogTag.LimaEqualChangePage, "LimaEqualChangePage show data: ", selected);
        selected && setSelectedItem(selected[0]);
      }
    );
    setDateTo(moment());
    setDateFrom(moment().subtract("1", "y"));

    return function cleanup() {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (timeStamp !== null && selectedItem !== null && isDisabled === true) {
      setIsDisabled(false);
      //setIsDisabled(true);
    } else if (isDisabled === false) {
      setIsDisabled(true);
    }
  }, [timeStamp, selectedItem]);

  //-------------------
  //  HANDLERS
  //-------------------
  const onReload = () => {
    const firstParaInSelection = limaStore.selectedInWord.find(
      (item: SelectedInWord) => item.type === WORDITEMTYPE.para
    );

    if (firstParaInSelection !== undefined && firstParaInSelection.id !== paraKey) setParaKey(firstParaInSelection.id);
    else if (firstParaInSelection === undefined && paraKey !== undefined) setParaKey(undefined);
    //else setParaKey(undefined);
  };

  const onChangeSelected = (selectedTimeastamp: string) => {
    log.info(limaLogTag.LimaEqualChangePage, "LimaEqualChangePage/onChangeSelected show data: ", selectedTimeastamp);
    setTimeStamp(selectedTimeastamp);
  };

  //-------------------
  //  RENDERS
  //-------------------
  return (
    <Stack>
      <Stack.Item>
        <Text variant={"large"}>{t("tpfile:tools.equalsParas.labelLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant={"small"}>{t("tpfile:tools.equalsParas.noteLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>{t("tpfile:tools.equalsParas.fortesingonlyLbl")}</LimaNotice>
      </Stack.Item>
      {/* <Stack.Item>now searichn for:{paraKey}</Stack.Item>
      <Stack.Item>
        selectednow searichn for:
        <SelectedPara />
      </Stack.Item> */}
      <Stack.Item>
        <HistParaItemParaTextItems
          dateFrom={dateFrom}
          dateTo={dateTo}
          selectedItem={selectedItem}
          showSelect={true}
          initSelectedTimestamp={timeStamp}
          onSelectCallBack={onChangeSelected}
        />
      </Stack.Item>
      <Stack.Item>
        <PrimaryButton
          text={t("tpfile:tools.equalsParas.reloadBtnLbl")}
          onClick={onReload}
          disabled={isDisabled}
          title={t("tpfile:tools.equalsParas.reloadBtnTitl")}
        />
      </Stack.Item>
      {/* <Stack.Item>
        {`timestamp ${timeStamp !== null ? timeStamp : "NULL"} parakey ${
          selectedItem !== null ? selectedItem.id : "NULL"
        } `}
      </Stack.Item> */}
      <Stack.Item>
        <Pivot aria-label="Equal Change">
          <PivotItem headerText={t("tpfile:tools.equalsParas.equalChangeCurrParaPivotLb")}>
            {/* <LimaEqualParasList paraKey={paraKey} xcopyHistoryTimeStamp={timeStamp} /> */}
            <LimaEqualParasList2 paraKey={paraKey} xcopyHistoryTimeStamp={timeStamp} />
            <></>
          </PivotItem>
          <PivotItem
            headerText={t("tpfile:tools.equalsParas.equalChangeAllchangesPivotLb")}
            headerButtonProps={{
              disabled: true,
              style: { color: "grey" },
            }}
          ></PivotItem>
        </Pivot>
      </Stack.Item>
    </Stack>
  );
};
