/**
 * Component shows single file item Copy
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
import { log } from "missionlog";
import {
  // FontIcon,
  getFocusStyle,
  getTheme,
  Icon,
  IconButton,
  IIconProps,
  IStackTokens,
  ITheme,
  // Label,
  mergeStyleSets,
  Stack,
  // TextField,
} from "office-ui-fabric-react";
import { FunctionComponent } from "react";
// import Moment from "react-moment";
// import { Spinner, SpinnerSize } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import limaStore from "../../../../../store/limaStore";
import { Xcopy2Acta } from "../../../../../types/Xcopy";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import { compareXcopy2XcopyByActaKey } from "../../../../../limaCommon/limaInsertDoc";
import { XCOPY_TYPE } from "../../../../../types/Task";
import moment = require("moment");
import FileOpenName from "./FileOpenName";

const OpenActaHistoryIcon: IIconProps = { iconName: "Download" };

log.init({ FileItemOpenHistItem: "FileItemOpenHistItem" });

const theme: ITheme = getTheme();
const { palette } = theme;

const compareCopyIcon: IIconProps = { iconName: "BranchCompare" };

const classNames = mergeStyleSets({
  items: {
    alignSelf: "center",
    marginLeft: 10,
    flexShrink: 0,
  },
  item_time: {
    alignSelf: "center",
    flexShrink: 0,
    fontSize: 16,
    minWidth: 32,
    cursor: "default",
  },
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
  historyItemsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
  },
  itemFileContainer: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
  itemFileIconsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "20%",
  },
  itemName: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    borderBottom: "1px solid #eaeaea",
    paddingBottom: "3px",
  },
  itemIcon: {
    fontSize: 16,
    height: 16,
    width: 20,
    margin: "4px 25px",
    cursor: "default",
  },

  itemNameTextField: {
    width: "100%",
    marginRight: 20,
  },
  itemDate: {
    fontWeight: "400",
    fontSize: "10px",
    width: "20%",
  },
  itemStyle: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "80%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyleHistory: {
    paddingLeft: "25px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "20%",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyleMax: {
    paddingLeft: "5px",
    paddingBottom: "3px",
    borderBottom: "1px solid #eaeaea",
    width: "100%",
    fontWeight: "bold",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  itemStyle2: {
    selectors: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
});

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 2,
};

type FileItemOpenCopyItemProp = {
  xCopy2ActaItem: Xcopy2Acta;
  isActaOwner: boolean;
  // openFileHandler: (fileId: string, fileName: string, xcopyId: string, timestamp?: string, xcopyName?: string) => void;
  openFileHandler: (
    fileId: string,
    fileName: string,
    // isOwner: boolean,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE
  ) => void;
};

const FileItemOpenCopyItem: FunctionComponent<FileItemOpenCopyItemProp> = ({
  xCopy2ActaItem,
  isActaOwner,
  openFileHandler,
}: FileItemOpenCopyItemProp) => {
  const OpenFileComponent = observer((): JSX.Element => {
    //TODO: rewrite to LimaFileOpenComponent
    return (
      <IconButton
        className={classNames.itemCellIcon}
        iconProps={OpenActaHistoryIcon}
        title={"Open Copy file"}
        disabled={limaStore.docxIsLoading}
        onClick={() => {
          // eslint-disable-next-line react/prop-types
          log.info(limaLogTag.FileItemOpenCopyItem, "OpenFileComponent: on click ", xCopy2ActaItem);
          // console.log("HEEERREEE2 ", xCopy2ActaItem, XCOPY_TYPE.TRIMXCOPY === xCopy2ActaItem.xcopy.xcopytype);
          openFileHandler(
            xCopy2ActaItem.acta._key,
            xCopy2ActaItem.acta.actaname,
            // isActaOwner,
            xCopy2ActaItem.xcopy._key,
            null,
            null,
            xCopy2ActaItem.xcopy.xcopytype
          );
        }}
      />
    );
  });

  // const CopyIcon = (): JSX.Element => {
  //   if (xCopy2ActaItem.xcopy != null && xCopy2ActaItem.xcopy.xcopytype != null) {
  //     if (xCopy2ActaItem.xcopy.xcopytype == XCOPY_TYPE.FULLXCOPY)
  //       return <FontIcon className={classNames.itemIcon} iconName="FullCircleMask" title="Full copy" />;
  //     else if (xCopy2ActaItem.xcopy.xcopytype == XCOPY_TYPE.TRIMXCOPY)
  //       return <FontIcon className={classNames.itemIcon} iconName="CircleHalfFull" title="Trimmed copy" />;
  //     else if (xCopy2ActaItem.xcopy.xcopytype == XCOPY_TYPE.MAINXCOPY)
  //       return <FontIcon className={classNames.itemIcon} iconName="Crown" title="Main copy" />;
  //     else return <FontIcon className={classNames.itemIcon} iconName="StatusCircleBlock" title="no copy" />;
  //   }
  //   return <FontIcon className={classNames.itemIcon} iconName="UnknownSolid" title="Unknow copy typ" />;
  // };

  const XcopyName = `copy of : ${xCopy2ActaItem.acta.actaname} [${
    xCopy2ActaItem.xcopy.xcopyIdent && xCopy2ActaItem.xcopy.xcopyIdent
  } ]`;

  return (
    <Stack horizontal tokens={stackTokens}>
      {/* <div key="fileContainer" className={classNames.itemFileContainer}>
        <div key="name" className={classNames.itemName}> */}
      <FileOpenName xcopytype={xCopy2ActaItem.xcopy.xcopytype} xcopyName={XcopyName} />
      {/* <Stack.Item shrink className={classNames.items}>
        <CopyIcon />
      </Stack.Item>
      <Stack.Item grow className={classNames.items}>
        <Label title={XcopyName}>{XcopyName}</Label>
      </Stack.Item> */}
      <Stack.Item className={classNames.item_time}>
        <Icon
          iconName={"Clock"}
          aria-hidden="true"
          title={moment(xCopy2ActaItem.xcopy.cTimeStamp).format("Do MMMM YYYY [at] h:mma")}
        />
      </Stack.Item>

      <Stack.Item className={classNames.items}>
        <OpenFileComponent />
      </Stack.Item>
      <Stack.Item className={classNames.items}>
        {isActaOwner && (
          <div key="copyCompare" className={classNames.itemStyle2}>
            <IconButton
              className={classNames.itemCellIcon}
              iconProps={compareCopyIcon}
              title={"Compare to actual document"}
              disabled={limaStore.docxIsLoading}
              onClick={async () => {
                // eslint-disable-next-line react/prop-types
                log.info(limaLogTag.FileItemOpenCopyItem, "OpenFileComponent: on click ");
                const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
                await compareXcopy2XcopyByActaKey(
                  xCopy2ActaItem.acta._key,
                  xCopy2ActaItem.xcopy._key,
                  limaStore.userId,
                  loaderID
                )
                  .then()
                  .finally(() => {
                    limaLoadeStore.remove(loaderID.k);
                  });
                /*await compareDocx_xcopy(null, xCopy2ActaItem.acta._key, xCopy2ActaItem.xcopy._key, limaStore.userId)
                  .then()
                  .finally(() => {
                    limaLoadeStore.remove(loaderID.k);
                  });*/
              }}
            />
          </div>
        )}
      </Stack.Item>
      {/* <div key="fileIcons" className={classNames.itemFileIconsContainer}> */}
      {/* <div key="openActHist" className={classNames.itemStyle2}>
          <OpenFileComponent />
        </div> */}

      {/* </div> */}
    </Stack>
  );
};
export default FileItemOpenCopyItem;
