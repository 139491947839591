import { Icon, getFocusStyle, getTheme, IconButton, IIconProps, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import Moment from "react-moment";
import { WORDITEMTYPE } from "../../../../../api/apilinks";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../../limaCommon/limaWordInteract";
import limaStore from "../../../../../store/limaStore";
// import limaStore from "../../../../../store/limaStore";
import { isSentanceAnalyseData_TIME, SentanceAnalyseData } from "../../../../../types/SentanceAnalyseData";
import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../../../types/Task_withActaXcopy";
import { SelectedInWord } from "../../../../../types/wordObjects";
import TaskItem from "../../../Edit/Tasks/components/TaskItem";
// import TaskItemEdit2 from "../../../Edit/Tasks/TaskItemEdit2";

interface TimeTaskComponentProps {
  item: SentanceAnalyseData;
}

const ShowCreateTaskIcon: IIconProps = { iconName: "ChevronDown" };
const HideCreateTaskIcon: IIconProps = { iconName: "ChevronUp" };

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemRowTaskInfoPart: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    },
  ],
  itemRowTaskIconsPart: {
    display: "flex",
    flexDirection: "row",
    width: 25,
  },
  itemRowActionIconsPart: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  itemRowTaskPartContentWrapper: {
    marginLeft: 10,
    overflow: "hidden",

    display: "flex",
    flexDirection: "row",
    width: "70%",
  },
  sentanceDate: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
  sentance: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // fontWeight: "bold",
    },
  ],
});

const TimeTaskComponent: FunctionComponent<TimeTaskComponentProps> = ({ item }: TimeTaskComponentProps) => {
  const selItem: SelectedInWord = { id: item.paraId, type: WORDITEMTYPE.para };
  const [createTaskShow, setCreateTaskShow] = useState<boolean>(false);
  const [taskPrefilData, setTaskPrefilData] = useState<Task_withActaXcopy | undefined>(undefined);

  const onHooverIn = () => {
    console.log("par IN", item.paraId);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    highlightTaskLinks3([selItem], true, HIGHLIGHT_TYPE.HIGHLIGHT, true);
    // if (checked === true) {
    //   limaStore.setLocationChangeCallBack(() => {
    //     highlightTaskLinks3(hightlightItems, false, highlight);
    //   });
    //   highlightTaskLinks3(hightlightItems, true, highlight);
    // } else {
    //   highlightTaskLinks3(hightlightItems, false, highlight);
    //   limaStore.setLocationChangeCallBack(null);
    // }
  };
  const onHooverOut = () => {
    // console.log("par OU", item.paraId);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    highlightTaskLinks3([selItem], false, HIGHLIGHT_TYPE.HIGHLIGHT);
  };

  const onCreateTaskShow = () => {
    // console.log("Create task", item.paraId);
    setCreateTaskShow(true);
  };

  const onCreateTaskHide = () => {
    // console.log("Hide task", item.paraId);
    setCreateTaskShow(false);
  };

  useEffect(() => {
    let tempTaskPrefilData = Task_withActaXcopy_getNull();

    // tempTaskPrefilData = { ...tempTaskPrefilData, taskName: "TEST" };
    tempTaskPrefilData = {
      ...tempTaskPrefilData,
      taskData: {
        ...tempTaskPrefilData.taskData,
        actaKey: limaStore.actaId,
        paraIds: [item.paraId],
        commentIds: [],
        attachements: [],
      },
    };
    if (item.otherData)
      if (
        item.otherData != undefined &&
        isSentanceAnalyseData_TIME(item.otherData) &&
        item.otherData.date != undefined
      ) {
        tempTaskPrefilData = { ...tempTaskPrefilData, taskDueDate: item.otherData.date };
      }

    setTaskPrefilData(tempTaskPrefilData);
  }, [item]);

  return (
    <Stack>
      <Stack.Item>
        <Stack
          data-is-focusable={true}
          horizontal
          onMouseEnter={onHooverIn}
          onMouseLeave={onHooverOut}
          className={classNames.itemRowTaskInfoPart}
        >
          <Stack.Item className={classNames.itemRowTaskIconsPart}>
            <Icon iconName="ReminderTime" aria-label="Time defined task" />
          </Stack.Item>
          <Stack.Item className={classNames.itemRowTaskPartContentWrapper}>
            <Stack>
              {item.otherData != undefined && isSentanceAnalyseData_TIME(item.otherData) && (
                <Stack.Item>
                  <Moment format={limaStore.userDateFormat} className={classNames.sentanceDate}>
                    {item.otherData.date}
                  </Moment>
                  {/* <span className={classNames.sentanceDate}>{item.otherData.date}</span> */}
                </Stack.Item>
              )}
              <Stack.Item>
                <span className={classNames.sentance}>{item.sentace}...</span>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item className={classNames.itemRowActionIconsPart}>
            {!createTaskShow ? (
              <IconButton iconProps={ShowCreateTaskIcon} title={"Create task"} onClick={onCreateTaskShow} />
            ) : (
              <IconButton iconProps={HideCreateTaskIcon} title={"Hide task"} onClick={onCreateTaskHide} />
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {createTaskShow && (
        <Stack.Item>
          <TaskItem prefillTaskData={taskPrefilData} />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default TimeTaskComponent;
