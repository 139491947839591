import { IStackProps, Label, Stack } from "@fluentui/react";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import React = require("react");
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaStore from "../../../../store/limaStore";
import { Task_withActaXcopy } from "../../../../types/Task_withActaXcopy";

import { LimaControledTextField } from "../../../../limaComponents/LimaControledTextField";
import { Control, UseFormSetValue } from "react-hook-form";

import { LimaControledDatePickerField } from "../../../../limaComponents/LimaControledDatePickerField";
import {
  dropdownControlledTaskStateOptions,
  TaskData,
  TaskForm,
  TaskTypeEnum,
  XCOPY_TYPE,
} from "../../../../types/Task";
import { LimaControledDropDownField } from "../../../../limaComponents/LimaControledDropDownField";
import TaskItemGetStoreDoc from "./components/TaskItemGetStoreDoc";
import CreateLinking from "./components/TaskLinking";
import TaskItemEditAssigned from "./components/TaskItemAssigned";

import { TaskItemDebugInfo } from "./components/TaskItemDebugInfo";
import TaskItemType from "./components/TaskItemType";
import { nameof } from "../../../../limaCommon/utils";
import { isTaskLinked } from "../../../../limaCommon/limaTaskHelpers";
import { LimaNotice } from "../../../../limaComponents/LimaNotice";

interface TaskItemEdit2Props {
  // taskId?: string;
  // user2taskId?: string;
  taskWithActaXcopy?: Task_withActaXcopy; //ForComapre only
  // prefillTaskData?: Task_withActaXcopy;
  //attachments?: string[];
  // setTaskIdCallBack?: (taskId: string) => void;
  // setU2TaskCallBack?: (task: Task_withActaXcopy) => void;
  taskData: Task_withActaXcopy;
  setTaskData: (task: Task_withActaXcopy) => void;
  setUnsavedChanges: (state: boolean) => void;
  control: Control<TaskForm>;
  setValue: UseFormSetValue<TaskForm>;
  isActaOwner: boolean;
}

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { width: "100%", padding: 5 } },
};

const TaskItemEdit2: React.FunctionComponent<TaskItemEdit2Props> = ({
  taskWithActaXcopy,
  taskData,
  setTaskData,
  //setUnsavedChanges,
  control,
  setValue,
  isActaOwner,
}: TaskItemEdit2Props) => {
  // const [taskLinkingChanged, setTaskLinkingChanged] = useState<boolean>(false);
  const [hasActa, sethasActa] = useState<boolean>(false); //setting on load of task data
  const [hasXcopy, sethasXcopy] = useState<boolean>(false); //setting on load of task data
  //const [linkedXcopyOpenned, setLinkedXcopyOpenned] = useState<boolean>(false);
  const [taskCreator, setTaskCreator] = useState<boolean>(false);

  const [showTaskAssigment, setShowTaskAssigment] = useState<boolean>(true);
  const [showTaskState, setShowTaskState] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    log.info(
      limaLogTag.TaskItemEditV2,
      "UEF taskWithActaXcopy: task data loaded mounted taskData,taskWithActaXcopy",
      taskData,
      taskWithActaXcopy
    );

    if (
      taskData === null ||
      taskData.cIssuedBy === null ||
      taskData.cIssuedBy === "" ||
      taskData.cIssuedBy === limaStore.userId
    ) {
      log.info(limaLogTag.TaskItemEditV2, "UEF []: SET CREATOR: TRUE", taskData);
      setTaskCreator(true);
    } else {
      log.info(limaLogTag.TaskItemEditV2, "UEF taskWithActaXcopy: SET CREATOR: FALSE", taskData);
      setTaskCreator(false);
    }
    if (taskData.taskData.actaKey != null && taskData.taskData.actaKey != "") sethasActa(true);
    if (taskData.taskData.xcopyKey != null && taskData.taskData.xcopyKey != "") sethasXcopy(true);
    // if (prefillTaskData !== undefined) {
    //   log.info(limaLogTag.TaskItemEditV2, "UEF taskWithActaXcopy: prefill", prefillTaskData);
    //   log.info(limaLogTag.TaskItemEditV2, "UEF taskWithActaXcopy: current", taskData);
    //   log.info(limaLogTag.TaskItemEditV2, "UEF taskWithActaXcopy: final", { ...taskData, ...prefillTaskData });
    //   //setTaskData({ ...taskData, ...prefillTaskData });
    //   setupTaskData(prefillTaskData);
    //   setUnsavedChanges(true);
    // }´

    // if (limaStore.xcopyId == taskData.taskData.xcopyKey) {
    //   !linkedXcopyOpenned && setLinkedXcopyOpenned(true);
    // } else {
    //   linkedXcopyOpenned && setLinkedXcopyOpenned(false);
    // }

    checkVisibleItemsByType(taskData.type);
  }, []);

  useEffect(() => {
    log.info(limaLogTag.TaskItemEditV2, "UEF taskData: taskData changed to ", taskData);
  }, [taskData]);

  const checkVisibleItemsByType = (type: TaskTypeEnum) => {
    if (type === TaskTypeEnum.TASK) setShowTaskAssigment(true);
    else setShowTaskAssigment(false);
    if (type === TaskTypeEnum.TASK || type === TaskTypeEnum.POINT) setShowTaskState(true);
    else setShowTaskState(false);
  };

  //SETUP TASK TYPE
  const setTaskType = (type: TaskTypeEnum) => {
    log.info(limaLogTag.TaskItemEditV2, "setTaskType : type ", type);
    setTaskData({ ...taskData, type: type });
    //SETUP VALUE IN FORM
    setValue("type", type);

    //Update form
    checkVisibleItemsByType(type);
  };

  return (
    <>
      <Stack {...columnProps}>
        <Stack.Item>
          <TaskItemDebugInfo taskData={taskData} taskCreator={taskCreator} />
        </Stack.Item>
        <Stack.Item>
          <TaskItemType type={taskData.type} changeType={setTaskType} />
        </Stack.Item>
        <Stack.Item>
          <LimaControledTextField
            limaLabel={t("tptasks:taskedit.taskitem2.tasknameLbl")}
            id="taskname"
            required={true}
            control={control}
            name={nameof<TaskForm>("taskname")}
            autoFocus={true}
            underlined
          />
        </Stack.Item>
        {showTaskState && (
          <Stack.Item>
            <LimaControledDropDownField
              limaLabel={t("tptasks:taskedit.taskitem2.taskstateLbl")}
              control={control}
              name={nameof<TaskForm>("taskstate")}
              // eslint-disable-next-line react/jsx-no-bind
              placeholder={t("tptasks:taskedit.taskitem2.taskstatePlchld")}
              options={dropdownControlledTaskStateOptions}
            />
          </Stack.Item>
        )}
        {showTaskAssigment && (
          <Stack.Item>
            <Stack horizontal={true}>
              <Stack.Item grow={1}>
                <Label>{t("tptasks:taskedit.taskitem2.taskassignedLbl")}</Label>
              </Stack.Item>
              <Stack.Item grow={11}>
                <TaskItemEditAssigned
                  control={control}
                  name={nameof<TaskForm>("taskassigned")}
                  assignedKey={(taskData && taskData.taskAssignedTo && taskData.taskAssignedTo._key) || ""}
                  user2taskId={taskData._key}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        )}
        <Stack.Item>
          <LimaControledDatePickerField
            limaLabel={t("tptasks:taskedit.taskitem2.taskduedateLbl")}
            showDatePickerPopup={() => {
              return;
            }}
            showToggle={true}
            toggleChacked={taskData && taskData.taskDueDate !== null && taskData.taskDueDate !== "" ? true : false}
            control={control}
            name={nameof<TaskForm>("taskduedate")}
            focus={() => {
              console.log("focus");
            }}
            reset={() => {
              console.log("reset");
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <LimaControledTextField
            limaLabel={t("tptasks:taskedit.taskitem2.taskdescrLbl")}
            id="taskdescription"
            multiline
            required={false}
            control={control}
            name={nameof<TaskForm>("taskdescription")}
            underlined
          />
        </Stack.Item>
        <Stack.Item>
          <CreateLinking
            taskData={taskData && taskData.taskData}
            taskKey={taskWithActaXcopy && taskWithActaXcopy._key}
            isTaskOwner={taskCreator}
            // enableXcopySetup={taskCreator}
            // enableDocLinking={taskCreator}
            // disableNoCopy={false}
            enableAlwaysLinkParts={true}
            onlyCreateXcopy={false}
            itemsLinkingCallBack={(newTaskData: TaskData) => {
              log.info(limaLogTag.TaskItemEditV2, "TaskLinking setTask callback :SetData ", newTaskData);
              setTaskData({ ...taskData, taskData: { ...newTaskData } });
            }}
          />
        </Stack.Item>
        <Stack.Item>
          {taskData._key === undefined || taskData._key === null || taskData._key === "" ? (
            <LimaNotice>{t("tptasks:taskedit.taskitem2.noopentaskyetLbl")}</LimaNotice>
          ) : isTaskLinked(taskData.taskData) ? (
            <TaskItemGetStoreDoc
              hasActa={hasActa}
              hasCopy={hasXcopy && taskData.taskData.xcopytype != XCOPY_TYPE.MAINXCOPY}
              //enableStoreXcopy={linkedXcopyOpenned}
              taskData={taskData && taskData.taskData}
              isActaOwner={isActaOwner}
            />
          ) : (
            <LimaNotice>{t("tptasks:taskedit.taskitem2.notlinkedtaskLbl")}</LimaNotice>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};

export default TaskItemEdit2;
