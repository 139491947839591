export const getColorBaseOnBg = (bgColor: string): string => {
  if (bgColor === null) {
    bgColor = "#c2c2c2";
  }
  const colorTagRGBA = hex2rgba(bgColor);
  const colorTag =
    colorTagRGBA[0] * 0.299 + colorTagRGBA[1] * 0.587 + colorTagRGBA[2] * 0.114 > 186 ? "#000000" : "#ffffff";

  return colorTag;
};

export const hex2rgba = (hex: string, alpha = 1): [number, number, number, number] => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  // return `rgba(${r},${g},${b},${alpha})`;
  return [r, g, b, alpha];
};
