import { Acta } from "./Acta";
import { BASE_LimaData } from "./BASE_LimaData";
import { XCOPY_TYPE } from "./Task";

export interface Xcopy extends BASE_LimaData {
  xcopyName: string;
  xcopytype: XCOPY_TYPE;
  xcopyIdent?: string;
}

export interface Xcopy2Acta extends BASE_LimaData {
  xcopy: Xcopy;
  acta: Acta;
}

export function isXcopy(item: unknown): item is Xcopy {
  return (item as Xcopy).xcopytype !== undefined;
}

export interface XcopyWithActa extends Xcopy {
  linkedActa: Acta[];
}
