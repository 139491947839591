import { log, tag } from "missionlog";
import { action, makeObservable, observable } from "mobx";
import {
  urlAccessAddVisibility,
  urlGetUserByEmail,
  urlGetVisibilityUsersFiltered,
  urlUpdateVisibility,
  userLinkingState,
} from "../api/apilinks";
import { httpGetAuth, httpPostAuth, HttpResponse } from "../api/httpAPI";
// import { getLoginDataOK } from "../api/schema/getLoginData";
import {
  userReqLinkingResp,
  userReqLinkingRespEdge,
  userReqLinkingRespEdgeDef,
  userReqLinkingRespItemDef,
} from "../dataModels/userAccListDataType";
import { LimaDocTypeENUM } from "../types/docxData";
import { IUser } from "../types/User";
import limaLoadeStore from "./limaLoaderStore";
import limaStore, { LimaStore } from "./limaStore";

log.init({ LimaUsersReqStore: "LimaUsersReqStore" });

class LimaUsersReqStore {
  limaStore: LimaStore;
  reqOfferUsers: userReqLinkingResp[] = [];
  foundUserFromSearch: userReqLinkingResp[] = null;

  // loadingData: boolean = false;
  totalItemCount = 0;

  constructor() {
    makeObservable(this, {
      reqOfferUsers: observable,
      foundUserFromSearch: observable,
      totalItemCount: observable,
      // loadingData: observable,

      // setLoadingData: action,
      setTotalItemCount: action,
      setReqOfferUsers: action,
      setFoundUsersFromSearch: action,
      removeItemFromList: action,
      acceptUserLinking: action,
      rejectUserLinking: action,
      getDataFromDB: action,
      checkVisibilityStatus: action,
    });
    this.limaStore = limaStore;
  }

  // setLoadingData(trueOrfalse: boolean) {
  //   this.loadingData = trueOrfalse;
  // }
  setTotalItemCount(newCount: number) {
    this.totalItemCount = newCount;
  }
  setReqOfferUsers(newList: userReqLinkingResp[]) {
    this.reqOfferUsers = newList;
  }

  setFoundUsersFromSearch(newFound: userReqLinkingResp[]) {
    this.foundUserFromSearch = newFound;
  }

  async getDataFromDB() {
    if (this.limaStore.userId == null || this.limaStore.userId == "") {
      log.warn(tag.LimaUsersReqStore, `getDataFromDB: userId ${this.limaStore.userId} is null/"" `);
      return null;
    }
    // this.setLoadingData(true);
    const loaderID = limaLoadeStore.add("Get user from DB");
    let listData: HttpResponse<userReqLinkingResp[]>;
    try {
      listData = await httpPostAuth(
        urlGetVisibilityUsersFiltered(this.limaStore.userId, "INBOUND"),
        {
          "e.state": userLinkingState.OFFERED,
          "e.type": "user",
        },
        loaderID.ac
      );
      log.info(tag.LimaUsersReqStore, "getDataFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaUsersReqStore, "getDataFromDB: parsedBody is not null");
        log.info(tag.LimaUsersReqStore, "getDataFromDB: data", listData.parsedBody);
        this.setReqOfferUsers(listData.parsedBody);
        this.setTotalItemCount(listData.parsedBody.length);
      }
      // this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaUsersReqStore, "getDataFromDB Error call get User", listData);
      // this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  async getFoundUserByEmail(userEmail: string) {
    let listData: HttpResponse<IUser>;
    const loaderID = limaLoadeStore.add("Check visibility ");
    try {
      // this.setLoadingData(true);

      listData = await httpGetAuth(urlGetUserByEmail(userEmail, false), loaderID.ac);
      log.info(tag.LimaUsersReqStore, "getUserByEmail: asketo to get something");
      if (listData.parsedBody !== undefined && listData.parsedBody != null) {
        log.info(tag.LimaUsersReqStore, "getUserByEmail: parsedBody is not null");
        log.info(tag.LimaUsersReqStore, "getUserByEmail: data", listData.parsedBody);
        if (listData.parsedBody != null) {
          const updatedItems: userReqLinkingResp[] = [
            {
              ...userReqLinkingRespItemDef,
              item: {
                ...userReqLinkingRespItemDef,
                _key: listData.parsedBody._key,
                userEmail: listData.parsedBody.userEmail,
                userName: listData.parsedBody.userName,
              },
              edge: { ...userReqLinkingRespEdgeDef },
            },
          ];
          log.info(tag.LimaUsersReqStore, "getUserByEmail: setting to setFoundUsersFromSearch", updatedItems);
          this.setFoundUsersFromSearch(updatedItems);
        } else {
          log.info(tag.LimaUsersReqStore, "getUserByEmail: no Data", listData);
        }
      }
      // this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaUsersReqStore, "checkVisibilityStatus Error call get User", listData);
      this.setFoundUsersFromSearch([]);
      // this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  async checkVisibilityStatus(userEmail: string) {
    let listData: HttpResponse<userReqLinkingResp[]>;
    const loaderID = limaLoadeStore.add("Check visibility ");
    try {
      // this.setLoadingData(true);
      listData = await httpPostAuth(
        urlGetVisibilityUsersFiltered(limaStore.userId, "OUTBOUND"),
        {
          "e.type": "user",
          "v.userEmail": userEmail,
        },
        loaderID.ac
      );
      log.info(tag.LimaUsersReqStore, "checkVisibilityStatus: asketo to get something");
      if (listData.parsedBody !== undefined && listData.parsedBody != null) {
        log.info(tag.LimaUsersReqStore, "checkVisibilityStatus: parsedBody is not null");
        log.info(tag.LimaUsersReqStore, "checkVisibilityStatus: data", listData.parsedBody);
        if (listData.parsedBody.length > 0) {
          this.setFoundUsersFromSearch(listData.parsedBody);
        } else void this.getFoundUserByEmail(userEmail);
      }
      // this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaUsersReqStore, "checkVisibilityStatus Error call get User", listData);
      this.setFoundUsersFromSearch([]);
      // this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  async offerUserVisiblity(userToKey: string, edgeKey: string) {
    if (edgeKey != "") {
      log.info(tag.LimaUsersReqStore, `offerUserVisiblity: found edgeKey '${edgeKey}' `);
      void this.updateLinking(edgeKey, userLinkingState.OFFERED, "dUser/" + userToKey);
      return;
    }

    log.info(tag.LimaUsersReqStore, `offerUserVisiblity: found userToKey '${userToKey}' edgeKey '${edgeKey}' `);
    let listData: HttpResponse<userReqLinkingRespEdge>;
    const loaderID = limaLoadeStore.add("Check visibility ");
    try {
      // this.setLoadingData(true);
      listData = await httpPostAuth(
        urlAccessAddVisibility(),
        {
          cIssuedBy: limaStore.userId,
          state: userLinkingState.OFFERED,
          cTimeStamp: new Date().toISOString(),
          dTimeStamp: "",
          dIssuedBy: "",
          type: "user",
          _from: "dUser/" + limaStore.userId,
          _to: "dUser/" + userToKey,
        },
        loaderID.ac
      );
      log.info(tag.LimaUsersReqStore, "offerUserVisiblity: asketo to get something");
      if (listData.parsedBody !== undefined && listData.parsedBody != null) {
        log.info(tag.LimaUsersReqStore, "offerUserVisiblity: parsedBody is not null");
        log.info(tag.LimaUsersReqStore, "offerUserVisiblity: data", listData.parsedBody);
        if (listData.parsedBody != null) {
          const updatedItem = [{ ...this.foundUserFromSearch[0], edge: listData.parsedBody }];
          this.setFoundUsersFromSearch(updatedItem);
        }
      }
      // this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaUsersReqStore, "offerUserVisiblity Error call get User", listData);
      this.setFoundUsersFromSearch([]);
      // this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }

  removeItemFromList(item: userReqLinkingResp) {
    log.info(tag.LimaUsersReqStore, `removeItemFromList:  itemid ${String(item.edge._id)} to remove`, item);
    const updatedItems = this.reqOfferUsers.filter((el) => el.edge._key !== item.edge._key);
    this.setReqOfferUsers(updatedItems);
    this.setTotalItemCount(updatedItems.length);
  }

  async acceptUserLinking(item: userReqLinkingResp) {
    log.info(tag.LimaUsersReqStore, `acceptUserLinking: found item with edge '${String(item.edge._id)}' `, item);
    await this.updateLinking(item.edge._key, userLinkingState.ACCEPTED, item.edge._from)
      .then(() => {
        this.removeItemFromList(item);
      })
      .catch(() => {
        log.info(tag.LimaUsersReqStore, `acceptUserLinking: error updating`);
      });
  }
  async rejectUserLinking(item: userReqLinkingResp) {
    log.info(tag.LimaUsersReqStore, `rejectUserLinking: found item with edge '${String(item.edge._id)}' `, item);
    await this.updateLinking(item.edge._key, userLinkingState.REJECTED, item.edge._from)
      .then(() => {
        this.removeItemFromList(item);
      })
      .catch(() => {
        log.info(tag.LimaUsersReqStore, `acceptUserLinking: error updating`);
      });
  }

  async updateLinking(edgeKey: string, state: userLinkingState, toUser: string) {
    if (edgeKey == null || edgeKey == "") {
      log.warn(tag.LimaUsersReqStore, `updateLinking: edgeKey ${edgeKey} is null/"" `);
      return null;
    }
    log.info(tag.LimaUsersReqStore, `updateLinking: edgeKey ${edgeKey}, new state '${state}' and user '${toUser}' `);

    // this.setLoadingData(true);
    const loaderID = limaLoadeStore.add("update linking ");
    let listData: HttpResponse<any>; //TODO: correct any type
    try {
      listData = await httpPostAuth(
        urlUpdateVisibility(edgeKey),
        {
          state: state,
          _to: this.limaStore.userId,
          _from: toUser,
          dTimeStamp: "",
          dIssuedBy: "",
          type: LimaDocTypeENUM.USER,
        },
        loaderID.ac
      );
      log.info(tag.LimaUsersReqStore, "getDataFromDB: asketo to get something");
      if (listData.parsedBody !== undefined) {
        log.info(tag.LimaUsersReqStore, "getDataFromDB: parsedBody is not null");
        log.info(tag.LimaUsersReqStore, "getDataFromDB: data", listData.parsedBody);
        this.setReqOfferUsers(listData.parsedBody);
      }
      // this.setLoadingData(false);
    } catch (response) {
      log.error(tag.LimaUsersReqStore, "getDataFromDB Error call get User", listData);
      // this.setLoadingData(false);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  }
}

const limaUsersReqStore = new LimaUsersReqStore();
export default limaUsersReqStore;
