import { DefaultButton, Stack, Text } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useMutation, useQuery } from "react-query";
import { SchemaOf } from "yup";
import {
  urlGetUserByEmail,
  urlPOSTUser2UserJoin,
  urlPatchOrDeleteUser,
  urlPostUser,
  urlUserGet,
} from "../../../../api/apilinks";
import { httpGetAuth, httpPostAuth, httpPutAuth } from "../../../../api/httpAPI";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { LimaControledTextField } from "../../../../limaComponents/LimaControledTextField";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { ICallUser, ICallUser_withInvite, IUser, UserForm } from "../../../../types/User";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { nameof } from "../../../../limaCommon/utils";
import { useDebounceFn } from "../../../../limaCommon/customHooks/useDebounce";
import { User2User } from "../../../../types/User2User";
import { Visibilitor } from "../../../../types/Visibilitor";
import limaStore from "../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../types/BASE_Enums";
import limaUsersStore from "../../../../store/limaUsersStore";
import { useTranslation } from "react-i18next";
import { CALLUSERLINK } from "../UserLinking/User2UserLinkComponent";
// import debounce = require("debounce");

interface UserAddEdditProps {
  userId?: string;
}

const schema: SchemaOf<UserForm> = yup.object().shape({
  useremail: yup.string().email().lowercase().not([limaStore.userEmail.toUpperCase()], "This is your email"),
  username: yup.string().required("This is required field"),
  invitemessage: yup.string(),
});

const UserAddEdit: FunctionComponent<UserAddEdditProps> = ({ userId }: UserAddEdditProps) => {
  const [innerUserId, setInnerUserId] = useState<string>(userId);
  const { isLoading, error, refetch, data: dataUserQuery } = useGetUserQuery(userId);
  const [enabledToEdit, setEnabledToEdit] = useState<boolean>(false);
  const { mutate: mutateCheckEmail, data: dataCheckEmail } = useGetUserByEmailQuery();
  const { mutateAsync: mutateAskToLink } = useAskToLinkQuery();
  const { mutateAsync: mutateCreateUser } = useAskToCreateUserQuery();
  const { mutateAsync: mutatePatchUser } = useAskToPathUserQuery();
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, watch, formState } = useForm<UserForm>({
    defaultValues: {
      useremail: "",
      username: "",
    },
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  const valueOfTest = watch();

  useDebounceFn(
    valueOfTest.useremail,
    () => {
      // log.info(limaLogTag.UserAddEdit, "debounce call valueOfTest: ", valueOfTest.useremail);
      mutateCheckEmail(valueOfTest.useremail);
    },
    500
  );

  useEffect(() => {
    if (userId) {
      //Check permissions to edit
      setEnabledToEdit(true);
      void refetch();
    }
  }, []);

  useEffect(() => {
    if (!dataUserQuery) {
      log.warn(limaLogTag.UserAddEdit, "no data yet: ");
    } else {
      log.info(limaLogTag.UserAddEdit, "data User Query: ", dataUserQuery);
      setValue("username", dataUserQuery.userName);
      setValue("useremail", dataUserQuery.userEmail);
    }
  }, [dataUserQuery]);

  if (isLoading == true) {
    return LimaLoadingComponnet(2);
  }

  if (error) {
    log.error(limaLogTag.UserAddEdit, "stat loaded error: ", error);
    return <Text variant={"medium"}>{t("tpuser:adduser.errorgetdataLbl")} </Text>;
  }

  const onStore = () => {
    void handleSubmit(
      (dataOnStore: UserForm) => {
        log.info(limaLogTag.UserAddEdit, "onStore: Valid data", dataOnStore);
        void mutateCreateUser({
          userEmail: dataOnStore.useremail,
          userName: dataOnStore.username,
          userId: null,
          lang: null,
        })
          .then((dataOnCreate: IUser) => setInnerUserId(dataOnCreate._key))
          .catch(() => {
            log.error(limaLogTag.UserAddEdit, "on create user: error");
          });
      },
      (err) => {
        console.log(err);
        log.error(limaLogTag.UserAddEdit, "onStore: error data", err);
      }
    )();
  };

  const onUpdate = () => {
    void handleSubmit(
      (data: UserForm) => {
        log.info(limaLogTag.UserAddEdit, "onUpdate: Valid data", data);
        void mutatePatchUser({
          userEmail: data.useremail,
          userName: data.username,
          userId: innerUserId,
          lang: null,
        });
      },
      (err) => {
        console.log(err);
        log.error(limaLogTag.UserAddEdit, "onUpdate: error data", err);
      }
    )();
  };

  const onClear = () => {
    setValue("username", "");
    setValue("useremail", "");
    setInnerUserId(null);
  };

  const onAskToLink = () => {
    if (!dataCheckEmail) return;

    void mutateAskToLink(dataCheckEmail.exists).then((data: User2User) => {
      log.info(limaLogTag.UserAddEdit, "onAskToLink: asked succedd ", data);
      setValue("useremail", "");
    });
  };

  return (
    <Stack>
      <Stack.Item>
        {"userId" + String(innerUserId) + " email:" + String(dataCheckEmail && dataCheckEmail.exists)}
      </Stack.Item>
      <Stack.Item>{"can edit:" + String(enabledToEdit)}</Stack.Item>
      <Stack.Item>
        <LimaControledTextField
          limaLabel={innerUserId ? t("tpuser:adduser.useremailchangeLbl") : t("tpuser:adduser.useremailLbl")}
          id="useremail"
          required={true}
          control={control}
          name={nameof<UserForm>("useremail")}
          autoFocus={true}
          underlined
          disabled={false}
        />
      </Stack.Item>
      <Stack.Item>
        {!dataCheckEmail || dataCheckEmail.exists === null || dataCheckEmail.exists === "" ? (
          <LimaControledTextField
            limaLabel={innerUserId ? t("tpuser:adduser.usernamechangeLbl") : t("tpuser:adduser.usernameLbl")}
            id="username"
            required={true}
            control={control}
            name={"username"} //nameof<UserForm>("username")
            underlined
            disabled={false}
          />
        ) : (
          <>{t("tpuser:adduser.alreadyexistLbl")} </>
        )}
      </Stack.Item>
      {innerUserId && (
        <Stack.Item>
          <DefaultButton onClick={onUpdate} disabled={!formState.isValid}>
            {t("tpuser:adduser.updateBtn")} {innerUserId}
          </DefaultButton>
        </Stack.Item>
      )}
      {innerUserId && (
        <Stack.Item>
          <DefaultButton onClick={onClear} disabled={!formState.isValid}>
            {t("tpuser:adduser.clearuserBtn")} {innerUserId}
          </DefaultButton>
        </Stack.Item>
      )}
      {!innerUserId && (
        <Stack.Item>
          {!dataCheckEmail || dataCheckEmail.exists === null || dataCheckEmail.exists === "" ? (
            <DefaultButton onClick={onStore} disabled={!formState.isValid}>
              {t("tpuser:adduser.addNewUserBtn")}
            </DefaultButton>
          ) : (
            <DefaultButton onClick={onAskToLink}>{t("tpuser:adduser.asktolinkBtn")}</DefaultButton>
          )}
        </Stack.Item>
      )}
    </Stack>
  );
};

export default UserAddEdit;

//----------------------
//   REACT QUERY
//----------------------

const useGetUserQuery = (userId: string) => {
  return useQuery(["getuser", userId], () => getUser(userId), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "useQuery/onSuccess data,type ", data);
    },
  });
};

const useGetUserByEmailQuery = () => {
  return useMutation((email: string) => getUserByEmail(email), {
    onMutate: (email: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useGetUserByEmailQuery", email);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useGetUserByEmailQuery", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const useAskToLinkQuery = () => {
  return useMutation((otherUserId: string) => postUser2UserLink(otherUserId), {
    onMutate: (otherUserId: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToLinkQuery ", otherUserId);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToLinkQuery ", data);
    },
    onError: (error) => {
      log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    },
  });
};

const useAskToCreateUserQuery = () => {
  return useMutation(postUser, {
    onMutate: ({ userEmail, userName }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToCreateUserQuery ", userEmail, userName);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToCreateUserQuery ", data);
      //Update listUsers
      limaUsersStore.addListSingleItem(data);
    },
  });
};

const useAskToPathUserQuery = () => {
  return useMutation(patchUser, {
    onMutate: ({ userEmail, userName, userId }: ICallUser) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToPathUserQuery ", userEmail, userName, userId);
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToPathUserQuery ", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

//--------------QUERIES -------------

const getUser = async (userId: string): Promise<IUser> => {
  log.info(limaLogTag.UserAddEdit, "fetching data fetchAccessList", userId);

  const loaderID = limaLoadeStore.add(`Getting user from DF`);

  try {
    const response = await httpGetAuth<IUser>(urlUserGet(userId), loaderID.ac);
    log.info(limaLogTag.UserAddEdit, "fetching data fetchAccessList response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    // return response.parsedBody;
    if (response.parsedBody !== undefined) {
      // if (Array.isArray(response.parsedBody))
      //   log.info(
      //     limaLogTag.User2UserLinkComponent,
      //     "fetching data fetchAccessList response.parsedBody",
      //     response.parsedBody
      //   );
      // //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const getUserByEmail = async (userEmail: string): Promise<{ exists: string }> => {
  log.info(limaLogTag.UserLinkingPage, "fetching data for user by email", userEmail);
  // const [, { patientID }] = params.queryKey;

  if (userEmail === undefined || userEmail === null || userEmail === "") {
    return { exists: null };
  }
  const loaderID = limaLoadeStore.add(`Check user by email`);
  try {
    const response = await httpGetAuth<{ exists: string }>(urlGetUserByEmail(userEmail), loaderID.ac);
    log.info(limaLogTag.UserLinkingPage, "fetching data getUserByEmail response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
    return response.parsedBody;
    // }
    // throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const postUser2UserLink = async (otherUserId: string): Promise<User2User> => {
  log.info(limaLogTag.UserLinkingPage, "fetching data for postUser2UserLink", otherUserId);
  // const [, { patientID }] = params.queryKey;

  if (otherUserId === undefined || otherUserId === null || otherUserId === "") {
    return null;
  }

  const visibilitor: Visibilitor = {
    create: null,
    userid: null, //removed by authetnication
    foruser: otherUserId,
    itemid: limaStore.userId,
    itemtype: LIMA_ITEM_TYPE.USER,
    state: CALLUSERLINK.ASK,
  };
  const loaderID = limaLoadeStore.add(`ask user to link`);
  try {
    const response = await httpPostAuth<User2User>(urlPOSTUser2UserJoin(), visibilitor, loaderID.ac);
    log.info(limaLogTag.UserLinkingPage, "fetching data postUser2UserLink response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const postUser = async ({ userEmail, userName, companyKey }: ICallUser_withInvite): Promise<IUser> => {
  log.info(limaLogTag.UserLinkingPage, "fetching data for postUser", userEmail, userName);
  // const [, { patientID }] = params.queryKey;

  if (userEmail === undefined || userEmail === null || userEmail === "") {
    log.warn(limaLogTag.UserLinkingPage, "no email", userEmail, userName);
    return null;
  }

  if (companyKey === undefined || companyKey === null || companyKey === "") {
    log.warn(limaLogTag.UserLinkingPage, "no companyKey", companyKey, userName);
    return null;
  }

  const user: IUser = {
    _id: "",
    _key: "",
    cissuedBy: limaStore.userId,
    ctimeStamp: "",
    dissuedBy: "",
    dtimestamp: "",
    userEmail: userEmail,
    userName: userName,
    lang: null,
  };

  const loaderID = limaLoadeStore.add(`ask to create user`);
  try {
    const response = await httpPostAuth<IUser>(urlPostUser(companyKey), user, loaderID.ac);
    log.info(limaLogTag.UserLinkingPage, "fetching data postUser response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    log.error(limaLogTag.UserLinkingPage, "ERRORR: fetching data createUser response", error);
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};

const patchUser = async ({ userEmail, userName, userId }: ICallUser): Promise<IUser> => {
  log.info(limaLogTag.UserLinkingPage, "fetching data for patchUser", userEmail, userName, userId);
  // const [, { patientID }] = params.queryKey;

  if (
    userId === undefined ||
    userId === null ||
    userId === "" ||
    userEmail === undefined ||
    userEmail === null ||
    userEmail === ""
  ) {
    log.warn(limaLogTag.UserLinkingPage, "no id or email", userEmail, userName, userId);
    return null;
  }

  const user: IUser = {
    _id: "",
    _key: userId,
    cissuedBy: null, //removed by authenticaito limaStore.userId,
    ctimeStamp: "",
    dissuedBy: "",
    dtimestamp: "",
    userEmail: userEmail,
    userName: userName,
    lang: null,
  };

  const loaderID = limaLoadeStore.add(`ask to patchUser`);
  try {
    const response = await httpPutAuth<IUser>(urlPatchOrDeleteUser(), user, loaderID.ac);
    log.info(limaLogTag.UserLinkingPage, "fetching data postUser response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
