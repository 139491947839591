import * as React from "react";
import { CommandBar, ICommandBarItemProps, IIconProps } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Routes from "./routes";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";

const taskAdd: IIconProps = { iconName: "Add", style: { fontSize: 14 } };
const taskList: IIconProps = { iconName: "TaskManager", style: { fontSize: 16 } };

const MainMenu: FunctionComponent = observer(() => {
  const { t } = useTranslation();

  // const limFileOpen =
  //   limaStore.actaId !== undefined && limaStore.actaId !== null && limaStore.actaId !== "" ? true : false;

  // const limFileVersionOpen = limaStore.isVersionOpen() ? true : false;

  // const limaActaOpen = limaStore.isMainCopyOpen();
  // const limaActaOpen = limaStore.isAnyXcopyOpen();

  const items: ICommandBarItemProps[] = [
    // {
    //   key: Routes.HomeV2,
    //   name: t("tpmain:menu.homeLbl"),
    //   href: `#${Routes.HomeV2}`,
    //   disabled: !limaStore.logged,
    //   // iconProps: { iconName: "Download", style: { fontSize: 16 } },
    // },
    {
      key: Routes.FileMenuSub,
      name: t("tpmain:menu.fileLbl"),
      href: `#${Routes.FileMenuSub}`,
      disabled: !limaStore.logged,
      iconProps: {
        iconName: "TextDocument",
        style: { fontSize: 16 },
      },
      subMenuProps: {
        items: [
          {
            key: Routes.FileMenuOpenRoute,
            name: t("tpmain:menu.fileOpenLbl"),
            href: `#${Routes.FileMenuOpenRoute}`,
            iconProps: { iconName: "Download", style: { fontSize: 16 } },
          },
          {
            key: Routes.FileMenuImportRoute,
            name: t("tpmain:menu.fileImportLbl"),
            href: `#${Routes.FileMenuImportRoute}`,
            iconProps: { iconName: "OpenFile", style: { fontSize: 16 } },
          },
          {
            key: Routes.FileMenuSaveRoute,
            name: t("tpmain:menu.fileSaveLbl"),
            subMenuProps: {
              items: [
                {
                  key: "save",
                  name: t("tpmain:menu.filesave.saveLbl"),
                  title: t("tpmain:menu.filesave.saveTtl"),
                  href: `#${Routes.FileMenuSaveRouteBase}save`,
                  disabled: !limaStore.isMainCopyOpen() && !limaStore.isAnyActiveWorkingcopyOpen(),
                },
                {
                  key: "savenew",
                  name: t("tpmain:menu.filesave.savenewLbl"),
                  title: t("tpmain:menu.filesave.savenewTtl"),
                  href: `#${Routes.FileMenuSaveRouteBase}new`,
                },
                {
                  key: "createcopy",
                  name: t("tpmain:menu.filesave.createcopyLbl"),
                  title: t("tpmain:menu.filesave.createcopyTtl"),
                  href: `#${Routes.FileMenuSaveRouteBase}createcopy`,
                  disabled: !limaStore.isMainCopyOpen(),
                },
              ],
            },
            // href: `#${Routes.FileMenuSaveRoute}base`,
            iconProps: { iconName: "Upload", style: { fontSize: 16 } },
          },
          {
            key: Routes.FileMenuDataRoute,
            name: t("tpmain:menu.dataObjectsLbl"),
            href: `#${Routes.FileMenuDataRoute}`,
            iconProps: { iconName: "EntitlementPolicy", style: { fontSize: 16 } },
          },
          {
            key: Routes.FileMenuHome,
            name: t("tpmain:menu.homeLbl"),
            href: `#${Routes.FileMenuHome}`,
            iconProps: { iconName: "Home", style: { fontSize: 16 } },
          },
          {
            key: Routes.FileMenuAbout,
            name: t("tpmain:menu.aboutLbl"),
            href: `#${Routes.FileMenuAbout}`,
            iconProps: { iconName: "Info", style: { fontSize: 16 } },
          },
        ],
      },
    },
    {
      key: Routes.UserMenuSub,
      name: t("tpmain:menu.usersLbl"),
      href: `#${Routes.UserMenuSub}`,
      disabled: !limaStore.logged,
      iconProps: { iconName: "People", style: { fontSize: 16 } },
      subMenuProps: {
        items: [
          {
            key: Routes.UserMenuAddRoute,
            name: t("tpmain:menu.users.adduserLbl"),
            iconProps: { iconName: "AddFriend", style: { fontSize: 16 } },
            href: `#${Routes.UserMenuAddRoute}`,
          },
          {
            key: Routes.UserMenuListRoute,
            name: t("tpmain:menu.users.userlistLbl"),
            iconProps: { iconName: "ContactList", style: { fontSize: 16 } },
            href: `#${Routes.UserMenuListRoute}`,
          },
          // {
          //   key: Routes.UserMenuLinkingRoute,
          //   name: "Users linking",
          //   iconProps: { iconName: "ConnectContacts", style: { fontSize: 16 } },
          //   href: `#${Routes.UserMenuLinkingRoute}`,
          // },
        ],
      },
    },
    {
      key: Routes.EditMenuSub,
      name: t("tpmain:menu.editLbl"),
      href: `#${Routes.EditMenuSub}`,
      disabled: !limaStore.logged,
      iconProps: { iconName: "Edit", style: { fontSize: 16 } },
      subMenuProps: {
        items: [
          // {
          //   key: Routes.EditMenuTasksRoute,
          //   name: "_Tasks_OLD_DONT USE",
          //   iconProps: { iconName: "TaskLogo", style: { fontSize: 16 } },
          //   href: `#${Routes.EditMenuTasksRoute}`,
          // },
          {
            key: Routes.EditMenuTaskAddRoute,
            name: t("tpmain:menu.edit.addtaskLbl"),
            iconProps: taskAdd, // { iconName: "TaskAdd", style: { fontSize: 16 } },
            href: `#${Routes.EditMenuTaskAddRoute}`,
          },
          // {
          //   key: Routes.EditMenuNotesRoute,
          //   name: "Notes",
          //   iconProps: { iconName: "EditNote", style: { fontSize: 16 } },
          //   href: `#${Routes.EditMenuNotesRoute}`,
          // },
          {
            key: Routes.EditMenuSearchRoute,
            name: t("tpmain:menu.edit.searchLbl"),
            iconProps: { iconName: "DocumentSearch", style: { fontSize: 16 } },
            href: `#${Routes.EditMenuSearchRoute}`,
          },
          {
            key: Routes.EditMenuStatesPage,
            name: t("tpmain:menu.edit.comparerevisionsLbl"),
            iconProps: { iconName: "DocumentSearch", style: { fontSize: 16 } },
            href: `#${Routes.EditMenuStatesPage}`,
          },
          {
            key: Routes.EditMenuHelperRoute,
            name: t("tpmain:menu.edit.settingLbl"),
            iconProps: { iconName: "ChatBot", style: { fontSize: 16 } },
            href: `#${Routes.EditMenuHelperRoute}`,
          },
        ],
      },
    },
    {
      key: Routes.ShowMenuSub,
      name: t("tpmain:menu.showLbl"),
      href: `#${Routes.ShowMenuSub}`,
      disabled: !limaStore.logged,
      iconProps: {
        iconName: "Diagnostic",
        style: { fontSize: 16 },
      },
      subMenuProps: {
        items: [
          {
            key: Routes.EditMenuTasks2Route,
            name: t("tpmain:menu.edit.tasklistLbl"),
            iconProps: taskList, // { iconName: "TaskList", style: { fontSize: 16 } },
            href: `#${Routes.EditMenuTasks2Route}`,
          },
          {
            key: Routes.ShowMenuHistoryRoute2,
            name: t("tpmain:menu.show.showHistoryLbl"),
            href: `#${Routes.ShowMenuHistoryRoute2}`,
            iconProps: { iconName: "History", style: { fontSize: 16 } },
          },
          {
            key: Routes.ShowMenuAccess,
            name: t("tpmain:menu.show.accessLbl"),
            href: `#${Routes.ShowMenuAccess}`,
            iconProps: { iconName: "RedEye", style: { fontSize: 18 } },
          },

          {
            key: Routes.ShowMenuHelper,
            name: t("tpmain:menu.show.testhelpLbl"),
            iconProps: { iconName: "RedEye", style: { fontSize: 18 } },
            onClick: () => {
              limaStore.setShowTestingInfo(!limaStore.showTestingInfo);
            },
          },
        ],
      },
    },
    {
      key: Routes.AdviceMenuSub,
      name: t("tpmain:menu.advisorLbl"),
      href: `#${Routes.AdviceMenuSub}`,
      iconProps: { iconName: "Edit", style: { fontSize: 16 } },
      subMenuProps: {
        items: [
          {
            key: Routes.AdviceMenuAdviceActSearchRoute,
            name: t("tpmain:menu.advisor.actsearchLbl"),
            iconProps: { iconName: "CompareUneven", style: { fontSize: 16 } },
            href: `#${Routes.AdviceMenuAdviceActSearchRoute}`,
          },
          {
            key: Routes.AdviceMenuAdviceJudgeSearchRoute,
            name: t("tpmain:menu.advisor.judgementsearchLbl"),
            disabled: !limaStore.logged,
            iconProps: { iconName: "DecisionSolid", style: { fontSize: 16 } },
            href: `#${Routes.AdviceMenuAdviceJudgeSearchRoute}`,
          },
          {
            key: Routes.AdviceMenuAdviceKeywordsRoute,
            name: t("tpmain:menu.advisor.keywordsearchLbl"),
            disabled: !limaStore.logged,
            iconProps: { iconName: "TextOverflow", style: { fontSize: 16 } },
            href: `#${Routes.AdviceMenuAdviceKeywordsRoute}`,
          },
          {
            key: Routes.AdviceMenuAdviceCheckDocRoute,
            name: t("tpmain:menu.advisor.checkactaLbl"),
            disabled: !limaStore.logged,
            iconProps: { iconName: "EntitlementRedemption", style: { fontSize: 16 } },
            href: `#${Routes.AdviceMenuAdviceCheckDocRoute}`,
          },
        ],
      },
    },
  ];

  return (
    <>
      {limaStore.logged && (
        <CommandBar ariaLabel="Use left and right arrow keys to navigate between commands" items={items} />
      )}
    </>
  );
});

export default MainMenu;
