import { log } from "missionlog";
import * as React from "react";
import limaStore from "../../store/limaStore";
import { ITheme, Icon, Stack, getTheme, mergeStyleSets, Text } from "@fluentui/react";
import { limaLogTag } from "../../limaCommon/limaLog";
// import { useId } from "@fluentui/react-hooks";
import LimaActionButtonV4 from "../../taskpane/uiv2/Components/LimaActionButtonV4";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaActaWordOpenNewWindowProps {}

const theme: ITheme = getTheme();
//const { palette, fonts } = theme;
const { palette } = theme;

const classNames = mergeStyleSets({
  limaActaDocTopNotifyWrapper: {
    margin: 2,
    padding: 5,
    backgroundColor: "#f9f9bb",
  },
  limaActaDocTopNotifyActionWrapper: {
    alignSelf: "flex-end",
    paddingRight: 5,
  },
  limaActaDocCompareActionnIcon: {
    cursor: "pointer",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});
// const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const LimaActaWordOpenNewWindow: React.FunctionComponent<ILimaActaWordOpenNewWindowProps> = () => {
  // const tooltipId = useId("toolTip-WordLimaNewWindow");
  // const calloutProps = { gapSpace: 0 };
  const { t } = useTranslation();

  //----------------
  // HANDLERS
  //----------------

  const onOpenConfirm = () => {
    if (limaStore.openFileNewWindowConfirmFnc !== undefined) {
      limaStore.openFileNewWindowConfirmFnc();
    }
  };

  const onOpenCurrentConfirm = () => {
    if (limaStore.openFileCurrentWindowConfirmFnc !== undefined) {
      limaStore.openFileCurrentWindowConfirmFnc();
    }
  };

  const onOpenCancel = () => {
    limaStore.openFileNewWindowConfirmFnc = undefined;
    limaStore.setOpenFileNewWindow(false);
  };

  //-----------------
  // OBSERVERS
  //-----------------

  const LimaActaWordDocNewWindowState = () => {
    log.info(
      limaLogTag.LimaActaWordOpenNewWindow,
      "check on chagnge actaid, xcopyid:",
      limaStore.actaId,
      limaStore.xcopyId
    );

    // if (limaStore.isWordDocIdentIsOpenInLima() === true) {
    //   return <></>;
    // }
    return (
      <>
        {/* <TooltipHost content={`Open file in new window`} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}> */}
        <Icon iconName="WarningSolid" style={{ color: "#eddb00" }} />
        <Text variant="medium">{t("tpfile:opennotify.opennotifyTitleLbl")}</Text>
        {/* </TooltipHost> */}
      </>
    );
  };

  const LimaActaWordDocNewWindowAction = () => {
    return (
      <>
        <LimaActionButtonV4 onClick={onOpenConfirm} toolTipShow={false}>
          {t("tpfile:opennotify.newWindowBtnLbl")}
        </LimaActionButtonV4>
        <LimaActionButtonV4 onClick={onOpenCurrentConfirm} toolTipShow={false}>
          {t("tpfile:opennotify.currentWindowBtnLbl")}
        </LimaActionButtonV4>
        <LimaActionButtonV4 onClick={onOpenCancel} toolTipShow={false}>
          {t("tpfile:opennotify.cancelBtnLbl")}
        </LimaActionButtonV4>
      </>
    );
  };

  //---------------
  // FUNCTIONS
  //----------------

  //---------------
  // RENDERS
  //----------------
  return (
    <Stack horizontal className={classNames.limaActaDocTopNotifyWrapper}>
      <Stack.Item grow={1}>
        <LimaActaWordDocNewWindowState />
      </Stack.Item>
      <Stack.Item shrink={1} className={classNames.limaActaDocTopNotifyActionWrapper}>
        <LimaActaWordDocNewWindowAction />
      </Stack.Item>
    </Stack>
  );
};

export default LimaActaWordOpenNewWindow;
