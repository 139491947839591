import React = require("react");
import { mergeStyleSets, PrimaryButton, Stack, StackItem, Text } from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import limaStore from "../../../../../store/limaStore";
// import { storeToXcopy } from "../../../../../limaCommon/limaXCopyFile";
import { useTranslation } from "react-i18next";
// import limaUsersStore from "../../../../../store/limaUsersStore";
// import { IUser } from "../../../../../types/User";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { log } from "missionlog";
import { LimaDocTypeENUM } from "../../../../../types/docxData";
import limaLoadeStore, { LoaderItemEnd } from "../../../../../store/limaLoaderStore";
import { getFileAsyncInternal } from "../../../../../limaCommon/limaSaveDoc";
import limaLogsStore, { ENUM_LogstoreLogType } from "../../../../../store/limaLogsStore";
import { LimaNotice } from "../../../../../limaComponents/LimaNotice";
import { xcopyHistory_null } from "../../../../../types/XcopyHistory";
import OnBehalfSaveV4 from "../../../../uiv2/FileItem/Save/components/OnBehalfSaveV4";
import { useHistory } from "react-router";
import Routes from "../../../routes";
import { FileStoreFinishV4TypeENUM } from "../../../../uiv2/FileItem/Save/FileStoredFinish";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FileSaveToXCopyProps {}

const classNames = mergeStyleSets({
  infoText: {
    width: "100%",
    margin: 10,
  },
});

const FileSaveToXCopy: FunctionComponent<FileSaveToXCopyProps> = () => {
  const { t } = useTranslation();
  const [storeDisabled, setStoreDisabled] = useState<boolean>(true);
  const [onBehalf, setOnBehalf] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const history = useHistory();

  const storeHandler = async () => {
    setStoreDisabled(true);
    setIsError(false);
    log.info(limaLogTag.FileSaveToXCopy, "Saving existing file");

    const loaderID = limaLoadeStore.add(`Storing xcopy to server`);

    await getFileAsyncInternal(
      limaStore.actaId,
      limaStore.xcopyId,
      LimaDocTypeENUM.XCOPY,
      limaStore.xcopyId,
      LimaDocTypeENUM.XCOPY,
      "",
      limaStore.userId,
      xcopyHistory_null(), //version
      () => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
        setStoreDisabled(false);
        history.push(`${Routes.FileSaveFinishRoute_base}${LimaDocTypeENUM.ACTA}/${FileStoreFinishV4TypeENUM.UPDATE}`);
      },
      () => {
        limaLoadeStore.remove(loaderID.k);
        setStoreDisabled(false);
        setIsError(true);
        limaLogsStore.add(
          "Save new file to copy error",
          "Some error on saving new file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.FileSaveToXCopy
        );
        log.error(limaLogTag.LimaXcopyFile, "onStoreToXcopy: error on store");
      },
      limaStore.xcopyId,
      onBehalf
    );
  };

  useEffect(() => {
    if (limaStore.xcopyIsOpen || limaStore.actaIsOpen) setStoreDisabled(false);
  }, []);

  return (
    <Stack>
      <StackItem className={classNames.infoText}>
        <Text variant="mediumPlus">
          {limaStore.isAnyActiveWorkingcopyOpen()
            ? t("tpfile:filesave.savexcopy.storingactiveCopyNoteLbl")
            : t("tpfile:filesave.savexcopy.storingactiveNoteLbl")}
        </Text>
      </StackItem>
      <Stack.Item>
        <OnBehalfSaveV4
          label={t("tpfile:filesave.savexcopy.storingonbehalfLbl")}
          onBehalf={onBehalf}
          setOnBehalf={setOnBehalf}
        />
        {/* <Dropdown
          label={t("tpfile:filesave.savexcopy.storingonbehalfLbl")}
          selectedKey={onBehalf}
          options={limaUsersStore.fullList.map((item: IUser) => {
            return { key: item._key, text: item.userName };
          })}
          onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (option !== undefined) {
              console.log("Dropdown onchange to ", option);
              setOnBehalf(option.key.toString());
            }
            //return option.key;
          }}
        /> */}
      </Stack.Item>
      {isError && (
        <Stack.Item>
          <LimaNotice>{t("tpfile:filesave.savexcopy.storingerrorLbl")}</LimaNotice>
        </Stack.Item>
      )}
      <StackItem>
        {limaStore.xcopyIsOpen || limaStore.actaIsOpen ? (
          <PrimaryButton
            disabled={storeDisabled}
            text={t("tpfile:filesave.savexcopy.saveBtnLbl")}
            onClick={storeHandler}
          />
        ) : (
          <Text variant="small">{t("tpfile:filesave.savexcopy.nocopyopenNotify")}</Text>
        )}
      </StackItem>
    </Stack>
  );
};

export default FileSaveToXCopy;
