import { getFocusStyle, getTheme, IconButton, IIconProps, ITheme, mergeStyleSets } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { onDownloadPFHandler } from "../api/calls/LimaGetForDownload";
import limaStore from "../store/limaStore";
import { EXPORT_TYPE } from "../types/Acta";

interface LimaFileGetPDFProps {
  actaId: string;
  xcopyId: string;
  historyTimeStamp: string;
  name: string;
}
const theme: ITheme = getTheme();

const OpenActaPDFIcon: IIconProps = { iconName: "PDF" };
const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 20,
      minHeight: 30,
      verticalAlign: "left",
    },
  ],
});

const LimaFileGetPDF: FunctionComponent<LimaFileGetPDFProps> = ({
  actaId,
  xcopyId,
  historyTimeStamp,
  name,
}: LimaFileGetPDFProps) => {
  const { t } = useTranslation(["tp"]);

  return (
    <IconButton
      className={classNames.itemCellIcon}
      iconProps={OpenActaPDFIcon}
      title={t("tpfile:fileopen.getPDFIconLbl")}
      disabled={limaStore.docxIsLoading}
      onClick={() => onDownloadPFHandler(actaId, historyTimeStamp, xcopyId, EXPORT_TYPE.PDF, name)}
    />
  );
};

export default LimaFileGetPDF;
