import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { Control } from "react-hook-form";
// import { useQuery } from "react-query";
// import { urlGetUsersForItemISee } from "../../../../../api/apilinks";
// import { httpGet, HttpResponse } from "../../../../../api/httpAPI";
// import { tasksUsersListItemOK } from "../../../../../api/schema/getTasksUsersListResponse";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaControledDropDownField } from "../../../../../limaComponents/LimaControledDropDownField";
import limaStore from "../../../../../store/limaStore";
// import limaLoadeStore from "../../../../../store/limaLoaderStore";
// import limaStore from "../../../../../store/limaStore";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { TaskForm } from "../../../../../types/Task";
import { IUser } from "../../../../../types/User";

interface TaskItemEditAssignedProps {
  control: Control<TaskForm>;
  name: string;
  user2taskId: string;
  assignedKey: string;
}

const TaskItemEditAssigned: FunctionComponent<TaskItemEditAssignedProps> = ({
  name,
  control,
  // user2taskId: taskId,
  assignedKey,
}: TaskItemEditAssignedProps) => {
  const [taskUsersAll, setTaskUsersAll] = useState<IUser[]>([]);
  const [editTaskListUsers, setEditTaskUserList] = useState<
    {
      key: string;
      text: string;
    }[]
  >([]);

  // const { status, error, data } = useQuery<tasksUsersListItemOK[], Error>(
  //   ["getTaskUsersAll", { userid: limaStore.userId, itemid: limaStore.userId, type: "user" }],
  //   getTaskUsers,
  //   { refetchOnWindowFocus: false }
  // );

  /*const { isLoading, error, refetch } = useQuery(
    ["getTaskUsersAll", { userid: limaStore.userId, itemid: limaStore.userId, type: "user" }],
    () => getTaskUsers(limaStore.userId, limaStore.userId, "user"),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        log.error(limaLogTag.TaskItemAssigned, "useQuery get attachements ", err);
      },
      onSuccess: (data: tasksUsersListItemOK[]) => {
        log.info(limaLogTag.TaskItemAssigned, "useQuery get attachements ", data);
        if (data !== undefined && data !== null) {
          const dropdownOptions = [];
          log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: Update data", taskUsersAll);
          setTaskUsersAll(data);
          data.map((userItem: tasksUsersListItemOK) => {
            dropdownOptions.push({
              key: userItem.userId,
              text: userItem.userName,
            });
          });

          log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: assigned to ", assignedKey);
          setEditTaskUserList(dropdownOptions);
        }
      },
    }
  );*/

  useEffect(() => {
    //void refetch();
    const dropdownOptions = [];
    log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: Update data", taskUsersAll);
    setTaskUsersAll(limaUsersStore.list);
    //add me first
    dropdownOptions.push({
      key: limaStore.userId,
      text: "ME",
    });

    limaUsersStore.list.map((userItem: IUser) => {
      dropdownOptions.push({
        key: userItem._key,
        text: userItem.userName,
      });
    });

    log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: assigned to ", assignedKey);
    setEditTaskUserList(dropdownOptions);
  }, []);
  // log.info(limaLogTag.TaskItemAssigned, "TaskUsers loading: for task", taskId);
  //cover users
  // if (taskId !== undefined && taskId !== null) {
  // if (status === "loading") {
  //   log.info(limaLogTag.TaskItemAssigned, "TaskUsers loading: ", data);
  //   return <div>... getting Task data ...</div>;
  // }
  // if (status === "error") {
  //   return <div>{error.message}</div>;
  // }
  // if (isLoading == true) {
  //   log.info(limaLogTag.TaskItemAssigned, "TaskUsers loading: ");
  //   return <>Loading</>;
  // }
  // if (error) {
  //   log.error(limaLogTag.AttachementComponent, "loaded error: ", error);
  //   return <>Error Getting data </>;
  // }
  if (limaUsersStore.list == null) {
    log.error(limaLogTag.TaskItemAssigned, "no limaUsersStore data: ");
    return <>Error Getting data </>;
  }

  // if (status === "success" && taskUsersAll !== data) {
  //   log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: ", data);
  //   if (data !== undefined && data !== null) {
  //     const dropdownOptions = [];
  //     log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: Update data", taskUsersAll);
  //     setTaskUsersAll(data);
  //     data.map((userItem) => {
  //       dropdownOptions.push({
  //         key: userItem.userId,
  //         text: userItem.userName,
  //       });
  //     });

  //     log.info(limaLogTag.TaskItemAssigned, "TaskUsers loaded: assigned to ", assignedKey);
  //     setEditTaskUserList(dropdownOptions);
  //   } else {
  //     return <div>NO data readed yet</div>;
  //   }
  // }

  if (taskUsersAll === undefined || taskUsersAll === null) {
    log.warn(limaLogTag.TaskItemAssigned, "TaskUsers loaded: current state date", taskUsersAll);
    return <>no data yet</>;
  }

  return (
    <LimaControledDropDownField
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      control={control}
      name={String(name)}
      // eslint-disable-next-line react/jsx-no-bind
      placeholder="Assigned person"
      // selectedItem={taskData && taskData.taskState}
      options={editTaskListUsers}
    />
  );
};

export default TaskItemEditAssigned;

//----------------------
//   REACT QUERY
//----------------------
// type Params = {
//   queryKey: [string, { userid: string; itemid: string; type: string }];
// };
/*
async function getTaskUsers(userid: string, itemid: string, type: string): Promise<tasksUsersListItemOK[]> {
  // const [, { userid, itemid, type }] = params.queryKey;

  if (!itemid || !userid) {
    log.warn(limaLogTag.TaskItemAssigned, "getTaskUsers: no item id or userid");
    return new Promise((reject) => {
      reject(null);
    });
  }
  const loaderID = limaLoadeStore.add("Getting usersAll from DB");
  let response: HttpResponse<tasksUsersListItemOK[]>;
  try {
    response = await httpGet<tasksUsersListItemOK[]>(urlGetUsersForItemISee(userid, itemid, type));

    log.info(limaLogTag.TaskItemAssigned, "getTaskUsers: asketo to get something", response);
    if (response.parsedBody !== undefined) {
      log.info(limaLogTag.TaskItemAssigned, "getTaskUsers: parsedBody is not null", response.parsedBody);
      return new Promise((resolve) => {
        resolve(response.parsedBody);
      });
    } else {
      throw new Error("...No data...");
    }
  } catch (response) {
    log.error(limaLogTag.TaskItemAssigned, "getTaskUsers: Error call get User", response);
    throw new Error("Problem geting data");
  } finally {
    limaLoadeStore.remove(loaderID.k);
  }
}
*/
