import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsAddUser = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="Add_user" data-name="Add user" transform="translate(-247.817 -284)">
        <g id="Group_13260" data-name="Group 13260" transform="translate(249.318 286)">
          <path
            id="Icon_metro-user"
            data-name="Icon metro-user"
            d="M19.854,23.791V22.149a8.982,8.982,0,0,0,3.839-7.4c0-4.95,0-8.963-5.758-8.963S12.176,9.8,12.176,14.747a8.982,8.982,0,0,0,3.839,7.4v1.643C9.5,24.344,4.5,27.663,4.5,31.677H31.37c0-4.014-5.005-7.333-11.516-7.886Z"
            transform="translate(-4.499 -5.784)"
            fill="none"
            stroke="#8e8e93"
            strokeWidth="1.5"
          />
          <g id="Group_13252" data-name="Group 13252" transform="translate(16.373 15.591)">
            <g
              id="Ellipse_1758"
              data-name="Ellipse 1758"
              fill="#8e8e93"
              stroke="#8e8e93"
              strokeLinecap="round"
              strokeWidth="1.5"
            >
              <circle cx="6.813" cy="6.813" r="6.813" stroke="none" />
              <circle cx="6.813" cy="6.813" r="6.063" fill="none" />
            </g>
            <g id="Group_12394" data-name="Group 12394" transform="translate(3.625 3.625)">
              <path
                id="Path_8977"
                data-name="Path 8977"
                d="M3886.408,1308.5h6.377"
                transform="translate(-3886.408 -1305.311)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
              <path
                id="Path_8978"
                data-name="Path 8978"
                d="M0,0H6.377"
                transform="translate(3.189 6.377) rotate(-90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="1.5"
              />
            </g>
          </g>
        </g>
        <rect
          id="Rectangle_24141"
          data-name="Rectangle 24141"
          width="32"
          height="32"
          transform="translate(247.817 284)"
          fill="none"
        />
      </g>
    </svg>
  );
};
