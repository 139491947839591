import * as React from "react";
import { FunctionComponent } from "react";

import { ActivityItem, getTheme, ITheme, mergeStyleSets } from "office-ui-fabric-react";

import { paraHistoryOK, paraHistTestTypeEnum } from "../../../api/schema/getParaHistoryResp";
import limaUsersStore from "../../../store/limaUsersStore";
import moment = require("moment");
import { useTranslation } from "react-i18next";
import { Checkbox, Stack } from "@fluentui/react";
// import { useMultiselect } from "../../../limaCommon/customHooks/useMultiselect";
// import { useMultiselectSwitch } from "../../../limaCommon/customHooks/useMultiselectSwitch";

export interface HistItemTextRowProps {
  index?: number;
  showChanges: boolean;
  paraHistoryRow: paraHistoryOK;
  showSelect?: boolean;
  // initSelectedTimestamp?: string;
  // onSelectCallBack?: (selectedItems: string) => void;
  isSelected?: boolean;
  onSelectChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const theme: ITheme = getTheme();
const { palette } = theme;

//TODO: show only around changes
// textDecoration: "strikeout",
// backgrounColor: "lightred",
// backgrounColor: "coral",
const classNames = mergeStyleSets({
  parItemRow: {
    flexGrow: 1,

    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    paddingTop: 5,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  parItemRowDel: {
    background: palette.red,
    textDecoration: "line-through",
  },
  parItemRowAdd: {
    //backgroundColor: "lightgreen",
    background: palette.greenLight,
  },
  parItemRowUpper: {
    verticalAlign: "super",
    fontSize: "smaller",
  },
  parItemRowItem: {},
  parItemRowText: {},
});

const HistParaItemTextRow: FunctionComponent<HistItemTextRowProps> = (props: HistItemTextRowProps) => {
  const { t } = useTranslation(["tpshow"]);
  console.log("HistParaItemTextRow");
  console.log(props.paraHistoryRow);
  // const { selected, isSelected, onChange } = useMultiselectSwitch(props.initSelectedTimestamp);
  let paraHistText = "";

  let lastType: paraHistTestTypeEnum = paraHistTestTypeEnum.ORIGIN;
  let lastCu = "";
  let lastCub = "";
  let lastCt = "";
  let lastDu = "";
  let lastDub = "";
  let space = false;
  let open = false;
  let upperActive = false;
  // let change: boolean = false;

  let title = "";
  for (const singleTextItem of props.paraHistoryRow.text) {
    console.log("TEXT>>", singleTextItem.t);
    title = "";
    //if (lastType != singleTextItem.t  && showChanges) {
    //if (showChanges) {
    if (
      lastType != singleTextItem.t ||
      lastCu != singleTextItem.cu ||
      lastCt != singleTextItem.ct ||
      lastCub != singleTextItem.cub ||
      lastDu != singleTextItem.du ||
      lastDub !== singleTextItem.dub
    ) {
      if (upperActive == true) {
        upperActive = false;
        paraHistText += "</span>";
      }
      if (open === true) paraHistText += "</span>";
      //set title
      if (singleTextItem.cu !== undefined && singleTextItem.cu !== null && singleTextItem.cu !== "") {
        title += `${t("tpshow:history.history.creatorparttxt")} ${limaUsersStore.getUserName(singleTextItem.cu)}`;
        if (
          singleTextItem.cub !== undefined &&
          singleTextItem.cub !== null &&
          singleTextItem.cub !== "" &&
          singleTextItem.cub !== "null"
        ) {
          title += ` (${t("tpshow:history.history.behalfparttxt")} ${limaUsersStore.getUserName(singleTextItem.cub)})`;
        }
        const dateString = moment(singleTextItem.ct).utc().format("DD/MM/YYYY HH:mm");
        title += ` [${dateString}]`;
      }
      if (singleTextItem.du !== undefined && singleTextItem.du !== null && singleTextItem.du !== "") {
        title += `${t("tpshow:history.history.deletedparttxt")} ${limaUsersStore.getUserName(singleTextItem.du)}`;
        if (
          singleTextItem.dub !== undefined &&
          singleTextItem.dub !== null &&
          singleTextItem.dub !== "" &&
          singleTextItem.dub !== "null"
        )
          title += ` (${t("tpshow:history.history.behalfparttxt")} ${limaUsersStore.getUserName(singleTextItem.dub)})`;
        const dateString = moment(singleTextItem.dt).utc().format("DD/MM/YYYY HH:mm");
        title += ` [${dateString}]`;
      }

      //Extend title for notes
      if (singleTextItem.wt !== undefined) {
        if (singleTextItem.ws === "bkms")
          title += ` ... ${t("tpshow:history.history.bkmtitleparttxt")}: ${singleTextItem.wt} ...`;
        else if (singleTextItem.ws === "upper")
          title += ` ... ${t("tpshow:history.history.notetitleparttxt")}: ${singleTextItem.wt} ...`;
        else title += ` ... ${singleTextItem.wt} ...`;
      }

      switch (singleTextItem.t) {
        case paraHistTestTypeEnum.NEW:
          // paraHistText += `<span class='${classNames.itemRowAdd}' title='${title}'>`;
          paraHistText += `<span ${props.showChanges && "class='" + classNames.parItemRowAdd + "'"} title='${title}'>`;

          open = true;
          break;
        case paraHistTestTypeEnum.ORIGIN:
          open = false;
          break;
        case paraHistTestTypeEnum.DELETE:
          //paraHistText += `<span class='${classNames.itemRowDel}' title='${title}' >`;
          paraHistText += `<span ${props.showChanges && "class='" + classNames.parItemRowDel + "'"} title='${title}'>`;
          open = true;
          break;
        default:
      }
    }

    //Set upper for notes for ex
    if (singleTextItem.ws !== undefined) {
      if (singleTextItem.ws == "upper") {
        paraHistText += `<span class='parItemRowUpper'>`;
        upperActive = true;
      }
      if (singleTextItem.ws == "bkms") {
        paraHistText += ``;
        //upperActive = true;
      }
      if (singleTextItem.ws == "bkme") {
        paraHistText += ``;
        //upperActive = true;
      }
    }
    if (space) paraHistText += " ";

    paraHistText += singleTextItem.w;
    space = true;
    lastType = singleTextItem.t;
    lastCu = singleTextItem.cu;
    lastCt = singleTextItem.ct;
    lastCub = singleTextItem.cub;
    lastDu = singleTextItem.du;
    lastDub = singleTextItem.dub;
  }
  if (upperActive) paraHistText += "</span>";
  if (open) paraHistText += "</span>";

  console.log("paraHist text", paraHistText);
  const date = new Date(props.paraHistoryRow.ts);

  // useEffect(() => {
  //   console.log("??? on selected callback", selected);
  //   if (props.onSelectCallBack !== undefined) props.onSelectCallBack(selected);
  // }, [selected]);

  const activityItemComment = {
    key: props.paraHistoryRow.order,
    activityDescription: [
      <span key={1}> Changed on </span>,
      <span key={2} className="classTest">
        {date.toLocaleDateString("en-GB")}
      </span>,
    ],
    comments: <div className={classNames.parItemRowText} dangerouslySetInnerHTML={{ __html: paraHistText }} />,
  };

  return (
    <section key={props.index} className={classNames.parItemRow}>
      <Stack horizontal>
        <Stack.Item style={{ minWidth: 24 }}>
          {props.showSelect !== undefined && props.showSelect == true && (
            <Checkbox id={props.paraHistoryRow.ts} checked={props.isSelected} onChange={props.onSelectChange} />
          )}
        </Stack.Item>
        <Stack.Item>
          <ActivityItem
            {...activityItemComment}
            key={props.paraHistoryRow.order}
            className={classNames.parItemRowItem}
          />
        </Stack.Item>
      </Stack>
    </section>
  );
};
export default HistParaItemTextRow;
