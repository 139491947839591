import React = require("react");
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IStackStyles,
  IStackTokens,
  Label,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Text,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { keyPressHandler } from "../../limaCommon/keyboardHandler";
import { log } from "missionlog";
import { useTranslation } from "react-i18next";
import { urlLogin } from "../../api/apilinks";
// import { getLoginDataOK } from "../../api/schema/getLoginData";
import { httpGet } from "../../api/httpAPI";
import limaStore from "../../store/limaStore";
import { useHistory } from "react-router-dom";
import { LimaControledTextField } from "../../limaComponents/LimaControledTextField";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { nameof } from "../../limaCommon/utils";
import { limaLogTag } from "../../limaCommon/limaLog";
import APISwitch from "../uiv2/UserSetting/components/APISwitch";
// import limaUsersStore from "../../store/limaUsersStore";
import Routes from "../Menu/routes";
import limaLoadeStore from "../../store/limaLoaderStore";
import { IUser } from "../../types/User";
import { openDialogAsIframe_login } from "../../limaCommon/limaDialogs";
import { observer } from "mobx-react";
import About from "../Menu/File/About/About";

const stackStyles: IStackStyles = {
  root: {},
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const classNames = mergeStyleSets({
  badCredentials: {
    color: "#f00",
  },
  wrongLoginOrPassBckg: {
    background: "#f00",
  },
  wrongLoginOrPassColor: {
    color: "white",
  },
});

type Form = {
  email: string;
  password: string;
};

const schema: SchemaOf<Form> = yup.object().shape({
  email: yup.string().required("This is required field").email("This is not a valid email address"),
  password: yup.string().default(""),
});

type LoginFormObserType = {
  badCredentialsInner: boolean;
};

const LoginSheet: FunctionComponent = () => {
  const { i18n } = useTranslation();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [verify, setVerify] = useState<boolean>(false);
  const [badCredentials, setBadCredentials] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  //TODO: remove when no login by email
  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  useEffect(() => {
    // window.console.log("HELLLOOO LOGIN SHEET UEF");
    //TODO: HERE IS ISSUE WITH OPENNING NEW WINDOWD IN Firefox
    history.replace(Routes.HomeV2Route);
    //history.replace(Routes.HomeV2Route);
  }, []);

  const onLogin = () => {
    //TODO: remove in next verrsions check if is old or new login set
    if (limaStore.loginTypeIAM === true) {
      openDialogAsIframe_login(null);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSubmit(
        async (data) => {
          console.log(data);
          log.info(limaLogTag.LoginSheet, "onLogin: data", data);
          await loginWithEmail(data);
        },
        (err) => {
          console.log(err);
          log.error(limaLogTag.LoginSheet, "onLogin: data", err);
        }
      )();
    }
  };

  const loginWithEmail = async (data: Form) => {
    const loaderID = limaLoadeStore.add("login with email");
    try {
      setVerify(true);
      const userLogin = await httpGet<IUser>(urlLogin(data.email), loaderID.ac);
      log.info(limaLogTag.LoginSheet, "loginWithEmail: userLogin data", userLogin);
      if (userLogin.status === 200 && userLogin.parsedBody !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        void i18n.changeLanguage(userLogin.parsedBody.lang);
        limaStore.setLoginUser(userLogin.parsedBody["key"], userLogin.parsedBody.lang, userLogin.parsedBody.userName);

        history.push(`${Routes.LoginProcessing}`);
      } else {
        log.warn(
          limaLogTag.LoginSheet,
          "loginWithEmail: not logged status, user",
          userLogin.status,
          limaStore.getUser()
        );
        setPassword("");
        setBadCredentials(true);
        setVerify(false);
      }
    } catch (error) {
      log.error(limaLogTag.LoginSheet, "loginWithEmail:error: ", error);
      setPassword("");
      setBadCredentials(true);
      setVerify(false);
    } finally {
      //setVerify(false);
      limaLoadeStore.remove(loaderID.k);
    }
  };

  const LoginForm = observer(({ badCredentialsInner }: LoginFormObserType): JSX.Element => {
    if (limaStore.loginTypeIAM) return <></>;
    return (
      <>
        <Stack.Item>
          <Label required>{t("tpmain:login.usernameLbl")}</Label>
          <LimaControledTextField
            // limaLabel={t("tpmain:login.usernameLbl")}
            id="email"
            required={true}
            // label="This field is required and accepts only emails"
            control={control}
            name={nameof<Form>("email")}
            value={username}
            autoFocus={true}
            // {...register(nameof<Form>("email"))}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setUsername(ev.target.value)}
            /*onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) =>
        keyPressHandler(ev, "Enter", () => setFocus("password"))
      }*/
          />
        </Stack.Item>
        <Stack.Item>
          <Label required>{t("tpmain:login.passwordLbl")}</Label>
          <TextField
            type="password"
            value={password}
            // {...register(nameof<Form>("password"))}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setPassword(ev.target.value)}
            onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => keyPressHandler(ev, "Enter", () => onLogin())}
          />
        </Stack.Item>
        {badCredentialsInner && (
          <Stack.Item align="center">
            <Text key="logginError" className={classNames.badCredentials} variant={"smallPlus"}>
              {t("tpmain:login.errText")}
            </Text>
          </Stack.Item>
        )}
      </>
    );
  });

  return (
    <>
      <About />

      <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
        <LoginForm badCredentialsInner={badCredentials} />

        <Stack.Item align="center">
          <PrimaryButton value={password} text={t("tpmain:login.loginButtonTxt")} onClick={() => onLogin()} />
        </Stack.Item>
        <Stack.Item align="center">
          <APISwitch hideSwitch={true} />
        </Stack.Item>

        {verify && (
          <Stack.Item>
            <Spinner label={t("tpmain:login.verifingLoginSpinnerLbl")} />
          </Stack.Item>
        )}
      </Stack>
    </>
  );
};

export default LoginSheet;
