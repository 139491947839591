import { log } from "missionlog";
import * as React from "react";
import { useState } from "react";
import { updateTaskData } from "../../../api/calls/updateTaskData";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaSimpleButtonV4 } from "../../../limaComponents/LimaSimpleButtonV4";
import limaStore from "../../../store/limaStore";
import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import { Task_withActaXcopy, Task_withActaXcopy_getNull } from "../../../types/Task_withActaXcopy";
// import { LimaEditItemBaseV4 } from "./LimaEditItemBaseV4";
import { TaskItemV4 } from "./TaskItemV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITastItemV4SingleProps {
  open: boolean;
}

const onStoreTask = () => {
  log.info(limaLogTag.TaskItemV4Single, "onStore: onDetialSaveUpdateCallBack ");
  // window.console.log("ADDDDDDDDDDDDDDDDDDDDDDD");
  void updateTaskData(limaTasksStoreV4.editTask_withActaXcopy, limaStore.userId).then((task: Task_withActaXcopy) => {
    // window.console.log("I HAVE TASK UPDATED", task, filters);
    log.info(limaLogTag.TasksList, "onStore: updated Task", task);
    if (limaTasksStoreV4.editTask_withActaXcopy._key === null || limaTasksStoreV4.editTask_withActaXcopy._key === "") {
      window.console.log("I HAVE TASK UPDATED reload DB");
      //TODO: In future simple way
      void limaTasksStoreV4.getTasksListFromDB().then(() => {
        window.console.log("LOAD FINISH - DO IT BETTER like push new");
      });
    } else {
      window.console.log("I HAVE TASK UPDATED single task");
      limaTasksStoreV4.updateSingleTask_withTask_withActaXcopy(task);
    }
  });
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TastItemV4Single: React.FunctionComponent<ITastItemV4SingleProps> = (_props) => {
  const [storeEnabled, setStoreEditEnabled] = useState<boolean>(false);

  return (
    <>
      <TaskItemV4
        task={Task_withActaXcopy_getNull()}
        enableStore={(enable: boolean) => {
          window.console.log(`TASIEM SINGLE ENABLED STORE ${String(enable)}`);
          setStoreEditEnabled(enable);
        }}
      />
      <LimaSimpleButtonV4 disabled={!storeEnabled} onClick={() => onStoreTask()}>
        STORE TASK
      </LimaSimpleButtonV4>
    </>
  );
};

export default TastItemV4Single;
