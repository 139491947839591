import { log } from "missionlog";
import { Separator, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { urlAccessGetVisibility2, urlAccessPostVisibility2, urlAccessPutVisibility2 } from "../../../api/apilinks";
import { httpDeleteByPUTAuth, httpGetAuth, httpPostAuth, HttpResponse } from "../../../api/httpAPI";
import { userAccListDataTypeLinked } from "../../../dataModels/userAccListDataType";
import { changeOnArray } from "../../../limaCommon/limaArrayHelpers";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaHideableSection } from "../../../limaComponents/LimaHideableSection";
import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import limaLoadeStore, { LoaderItemEnd } from "../../../store/limaLoaderStore";
import limaStore from "../../../store/limaStore";
import limaUsersStore from "../../../store/limaUsersStore";
import { LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { IUser } from "../../../types/User";
import { User2Task } from "../../../types/User2Task";
import { User2User } from "../../../types/User2User";
import { User2Xcopy } from "../../../types/User2Xcopy";
import { Visibilitor } from "../../../types/Visibilitor";
import { CALLUSERLINK } from "../../Menu/Users/UserLinking/User2UserLinkComponent";
import AccessItem from "./components/AccessItem";
import AccessItemChooser from "./components/AccessItemChooser";

interface AccessComponentProps {
  itemId?: string;
  type?: LIMA_ITEM_TYPE;
  showLinkedItem?: boolean;
  enableChange?: boolean;
}

const AccessComponent: FunctionComponent<AccessComponentProps> = ({
  itemId,
  type,
  showLinkedItem,
  enableChange,
}: AccessComponentProps) => {
  const { t } = useTranslation(["tpshow"]);
  // const [usersAccess] = useState<userAccListDataTypeLinked[]>(Object.assign([], limaUsersStore.fullList));
  const [innerType, setInnerType] = useState<LIMA_ITEM_TYPE>(type ? type : LIMA_ITEM_TYPE.USER);
  const [innerItemId, setInnerItemId] = useState<string>(itemId);
  const [usersAccess, setUsersAccess] = useState<userAccListDataTypeLinked[]>([]);

  const { isLoading, error, refetch } = useQuery(
    ["messages", {}],
    () => fetchAccessList<User2Task | User2Xcopy | User2User>(innerItemId, innerType),
    {
      enabled: false,
      onError: (err) => {
        log.error(limaLogTag.AccessComponent, "stat loaded error: ", err);
        setUsersAccess([]);
      },
      onSuccess: (data: (User2Task | User2Xcopy | User2User)[]) => {
        log.info(limaLogTag.AccessComponent, "useQuery/onSuccess data,type ", data, innerType);
        log.info(limaLogTag.AccessComponent, "useQuery/onSuccess  access ", usersAccess);
        let userList = limaUsersStore.fullList.map((item: IUser): userAccListDataTypeLinked => {
          return { docEdgeId: "", edgeId: "", position: "", userId: item._key, userName: item.userName };
        });

        data.forEach((item) => {
          userList = changeOnArray<userAccListDataTypeLinked, string, string>(
            userList,
            "userId",
            item.user._key,
            "edgeId",
            item._key
          );
        });
        log.info(limaLogTag.AccessComponent, "useQuery get access ", userList);
        setUsersAccess(userList);
      },
    }
  );

  const { mutate } = useMutation(
    async (message: Visibilitor) => {
      if (message.itemtype === LIMA_ITEM_TYPE.TASK) return addRemoveLinking<User2Task>(message);
      else if (message.itemtype === LIMA_ITEM_TYPE.USER) return addRemoveLinking<User2User>(message);
      else if (message.itemtype === LIMA_ITEM_TYPE.XCOPY) return addRemoveLinking<User2Xcopy>(message);
      else return null;
    },

    {
      onSuccess: (data: User2Task) => {
        //setMessages([...messages, data]);
        window.console.log("Message>> useMutation onSuccess", data);
        setUsersAccess(
          changeOnArray<userAccListDataTypeLinked, string, string>(
            usersAccess,
            "userId",
            data.user._key,
            "edgeId",
            data._key
          )
        );
      },
    }
  );

  useEffect(() => {
    if ((itemId !== undefined && itemId !== null) || (innerItemId !== undefined && innerItemId !== null)) {
      void refetch();
    }
  }, [itemId, innerItemId]);

  const handleClickAccess = (userId: string, edgeId: string): void => {
    log.info(limaLogTag.AccessComponent, "clicked on user and edge", userId, edgeId);
    if (edgeId === undefined || edgeId === null || edgeId === "") {
      log.info(limaLogTag.AccessComponent, "clicked create on user", userId);
      mutate({
        userid: limaStore.userId,
        foruser: userId,
        itemid: innerItemId,
        itemtype: innerType,
        create: true,
        state: CALLUSERLINK.ASK,
      });
    } else {
      mutate({
        userid: limaStore.userId,
        foruser: userId,
        itemid: innerItemId,
        itemtype: innerType,
        create: false,
        state: CALLUSERLINK.ASK,
      });
      log.info(limaLogTag.AccessComponent, "clicked remove on user and edge", userId, edgeId);
    }
  };

  return (
    <Stack>
      <Stack.Item>
        {t("tpshow:access.uniaccess.labelLbl")}- {innerType && innerType}/{innerItemId && innerItemId}
      </Stack.Item>
      {showLinkedItem && (
        <Stack.Item>
          <LimaHideableSection name={t("tpshow:access.uniaccess.linkitemSectionLbl")}>
            <AccessItemChooser
              enableChange={enableChange}
              itemId={itemId}
              type={type}
              onChangeCallBack={(type: LIMA_ITEM_TYPE, id: string) => {
                log.info(limaLogTag.AccessComponent, "setting new type and id  ", type, id, innerType, innerItemId);
                innerType !== type && setInnerType(type);
                if (id !== null) {
                  innerItemId !== id && setInnerItemId(id);
                } else {
                  setInnerItemId(null);
                }
              }}
            />
          </LimaHideableSection>
        </Stack.Item>
      )}
      {showLinkedItem && (
        <Stack.Item>
          <Separator>{t("tpshow:access.uniaccess.userslistLbl")}</Separator>
        </Stack.Item>
      )}
      {innerItemId === undefined || innerItemId === null ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.notifyitemfirstLbl")}</Stack.Item>
      ) : isLoading === true ? (
        <Stack.Item>{LimaLoadingComponnet(3, "accComp")}</Stack.Item>
      ) : error === true ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.errorgettindataLbl")}</Stack.Item>
      ) : usersAccess === null || usersAccess.length === 0 ? (
        <Stack.Item>{t("tpshow:access.uniaccess.userslist.nousersLbl")}</Stack.Item>
      ) : (
        <Stack.Item>
          {usersAccess &&
            usersAccess.map((item: userAccListDataTypeLinked) => (
              <AccessItem key={item.userId} userItem={item} onClickCallback={handleClickAccess} />
            ))}
        </Stack.Item>
      )}
    </Stack>
  );
};

export default AccessComponent;

//----------------------
//   REACT QUERY
//----------------------

async function fetchAccessList<T>(linkedId: string, type: LIMA_ITEM_TYPE): Promise<T[]> {
  log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList");
  // const [, { patientID }] = params.queryKey;
  const loaderID = limaLoadeStore.add("Getting acceslist from DB");
  let response: HttpResponse<T[]>;
  try {
    if (type === LIMA_ITEM_TYPE.TASK || type === LIMA_ITEM_TYPE.USER || type === LIMA_ITEM_TYPE.XCOPY)
      response = await httpGetAuth<T[]>(urlAccessGetVisibility2(linkedId, type), loaderID.ac);
    else {
      log.warn(limaLogTag.AccessComponent, "not implemented type - ", type);
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(limaLogTag.AccessComponent, "fetching data fetchAccessList response.parsedBody", response.parsedBody);
      //response.parsedBody.forEach((item:any)=>assertIsDB_Survey(item))
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  }
}

async function addRemoveLinking<T>(item: Visibilitor): Promise<T> {
  let response: HttpResponse<T>;
  // const user2Item: User2Task | User2Xcopy | User2User;
  if (item.itemid === null || item.itemid === undefined) {
    log.error(limaLogTag.AccessComponent, "useMutation post/put missing id ", item);
    throw null;
  }

  if (
    item.itemtype === LIMA_ITEM_TYPE.TASK ||
    item.itemtype === LIMA_ITEM_TYPE.USER ||
    item.itemtype === LIMA_ITEM_TYPE.XCOPY
  ) {
    const loaderID = limaLoadeStore.add("add/change visilibtility on item");
    try {
      log.info(limaLogTag.AccessComponent, "useMutation post/put visibility item type", item.itemtype);
      if (item.create === true) response = await httpPostAuth<T>(urlAccessPostVisibility2(), item, loaderID.ac);
      else response = await httpDeleteByPUTAuth<T>(urlAccessPutVisibility2(), item, loaderID.ac);
    } finally {
      limaLoadeStore.remove(loaderID.k);
    }
  } else {
    log.error(limaLogTag.AccessComponent, "useMutation post/put visibility item unknown type", item.itemtype);
    return null;
  }

  if (!response.ok) {
    log.error(limaLogTag.AccessComponent, "useMutation post/put visibility item ", response);
    throw response;
  }
  log.info(limaLogTag.AccessComponent, "fetching post visibility item response.parsedBody", response.parsedBody);

  const itemOut = response.parsedBody;
  if (item.create === false) itemOut["_key"] = "";

  return itemOut;
}
