import { Icon, getFocusStyle, getTheme, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { WORDITEMTYPE } from "../../../../../api/apilinks";
import { highlightTaskLinks3, HIGHLIGHT_TYPE } from "../../../../../limaCommon/limaWordInteract";

import {
  isSentanceAnalyseData_ISSUE,
  SENTACE_ISSUE_TYPE,
  SentanceAnalyseData,
} from "../../../../../types/SentanceAnalyseData";
import { SelectedInWord } from "../../../../../types/wordObjects";

interface IssueComponentProps {
  item: SentanceAnalyseData;
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemRowTaskInfoPart: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    },
  ],
  itemRowTaskIconsPart: {
    display: "flex",
    flexDirection: "row",
    width: 25,
  },
  itemRowActionIconsPart: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  itemRowTaskPartContentWrapper: {
    marginLeft: 10,
    overflow: "hidden",

    display: "flex",
    flexDirection: "row",
    width: "70%",
  },
  sentanceType: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
  sentance: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // fontWeight: "bold",
    },
  ],
});

const IssueComponent: FunctionComponent<IssueComponentProps> = ({ item }: IssueComponentProps) => {
  const selItem: SelectedInWord = { id: item.paraId, type: WORDITEMTYPE.para };
  const { t } = useTranslation();

  const onHooverIn = () => {
    console.log("par IN", item.paraId);

    void highlightTaskLinks3([selItem], true, HIGHLIGHT_TYPE.HIGHLIGHT, true);
  };
  const onHooverOut = () => {
    void highlightTaskLinks3([selItem], false, HIGHLIGHT_TYPE.HIGHLIGHT);
  };

  const issueType = (type: SENTACE_ISSUE_TYPE) => {
    if (type === SENTACE_ISSUE_TYPE.SENTANCE_SUB_IS_MISSING) {
      return t("tpadvice:checkdoc.issues.sentancesubmissingLbl");
    } else if (type === SENTACE_ISSUE_TYPE.SENTANCE_SUB_IS_PRON_DET) {
      return t("tpadvice:checkdoc.issues.sentancesubprondetLbl");
    } else if (type === SENTACE_ISSUE_TYPE.SENTANCE_OBJ_IS_MISSING) {
      return t("tpadvice:checkdoc.issues.sentanceobjmissingLbl");
    } else if (type === SENTACE_ISSUE_TYPE.SENTANCE_OBJ_IS_PRON_DET) {
      return t("tpadvice:checkdoc.issues.sentanceobjprondetLbl");
    } else if (type === SENTACE_ISSUE_TYPE.COMMITMENT_SUBJ_IS_MISSING) {
      return t("tpadvice:checkdoc.issues.sentancecommitwithoutsubLbl");
    }

    return "Other issue";
  };

  return (
    <Stack>
      <Stack.Item>
        <Stack
          data-is-focusable={true}
          horizontal
          onMouseEnter={onHooverIn}
          onMouseLeave={onHooverOut}
          className={classNames.itemRowTaskInfoPart}
        >
          <Stack.Item className={classNames.itemRowTaskIconsPart}>
            <Icon iconName="UserWarning" aria-label="Warning" />
          </Stack.Item>
          <Stack.Item className={classNames.itemRowTaskPartContentWrapper}>
            <Stack>
              <Stack.Item>
                <span className={classNames.sentanceType}>{item.found}</span>
                {item.otherData != undefined &&
                  isSentanceAnalyseData_ISSUE(item.otherData) &&
                  `: ${issueType(item.otherData.issueType)}`}
              </Stack.Item>
              <Stack.Item>
                <span className={classNames.sentance}>{item.sentace}...</span>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item className={classNames.itemRowActionIconsPart}>
            {/* {!createTaskShow ? (
              <IconButton iconProps={ShowCreateTaskIcon} title={"Create task"} onClick={onCreateTaskShow} />
            ) : (
              <IconButton iconProps={HideCreateTaskIcon} title={"Hide task"} onClick={onCreateTaskHide} />
            )} */}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {/* {createTaskShow && (
        <Stack.Item>
          <TaskItemEdit2 prefillTaskData={taskPrefilData} />
        </Stack.Item>
      )} */}
    </Stack>
  );
};

export default IssueComponent;
