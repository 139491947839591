import * as React from "react";
// import { FunctionComponent } from "react";

import { log, tag } from "missionlog";
import {
  getFocusStyle,
  getTheme,
  GroupedList,
  Icon,
  IGroup,
  IGroupFooterProps,
  IGroupHeaderProps,
  IStackTokens,
  ITheme,
  Label,
  Link,
  mergeStyles,
  mergeStyleSets,
  Stack,
  StackItem,
} from "@fluentui/react";
import { observer } from "mobx-react-lite";
import limaSearchStore from "../../../../store/limaSearchStore";
import { searchResult } from "../../../../api/schema/search/searchResp";
import { useTranslation } from "react-i18next";
import moment = require("moment");
import LimaFileOpenComponent from "../../../../limaComponents/LimaFileOpenComponent";
import { onFileOpenHandlerWrapper } from "../../../../limaCommon/limaFileOpen";
import FileOpenName from "../../File/FileOpen/components/FileOpenName";
import { XCOPY_TYPE } from "../../../../types/Task";

log.init({ SearchResultItem: "SearchResultItem" });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface SearchResultItemProps {}
//({ logo, message, title }: HistItemProps)

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const iconClass = mergeStyles({
  fontSize: 30,
  height: 30,
  width: 30,
  margin: "0 10px",
});

const spacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.mediumPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemTimestamp: [
    fonts.mediumPlus,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  searchWord: {
    fontWeight: "bold",
    background: palette.greenLight,
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        cursor: "pointer",
      },
    },
  },
  iconScoreBest: [{ color: "deepskyblue" }, iconClass],
  iconScoreGood: [{ color: "greenyellow" }, iconClass],
  iconScoreOk: [{ color: "salmon" }, iconClass],
  iconScoreSomehow: [{ color: "salmon" }, iconClass],
  itemFooter: [
    fonts.small,
    {
      flexGrow: 1,
    },
  ],
  itemHeader: [
    fonts.mediumPlus,
    {
      flexGrow: 1,
      marginTop: 5,
    },
  ],
  itemHeaderFilename: [
    fonts.mediumPlus,
    {
      flexGrow: 1,
      marginBottom: 5,
      fontWeight: "bold",
    },
  ],
});

//TODO: add click to open File
//TODO: add onClick search result to open file in history moment and search item
//TODO: on hover if file id is same, check if exist in document adn hover there

const SearchResultItem = (): JSX.Element => {
  log.info(tag.SearchResultItem, "SearchResultItem: Mounted", limaSearchStore.list);
  const { t } = useTranslation(["tpsearch"]);

  const onRenderCell = (_nestingDept: unknown, item: searchResult, index: number): JSX.Element => {
    const score = Math.ceil(item.scoreSUM / item.scoreCount);
    let scoreIcon = classNames.iconScoreSomehow;
    if (score >= 95) scoreIcon = classNames.iconScoreBest;
    else if (score >= 75) scoreIcon = classNames.iconScoreGood;
    else if (score >= 50) scoreIcon = classNames.iconScoreOk;

    const XcopyName =
      item.xType !== XCOPY_TYPE.MAINXCOPY ? `copy of : ${item.aName} [${item.xIdent}]` : `${item.aName}`;

    //log.info(tag.SearchResultItem, `onRenderCell: depth [${_nestingDept}] item`, item);
    return item.aId != null && item.aId != "" ? (
      <Stack tokens={spacingStackTokens} horizontal>
        <StackItem>
          <div key={`row_${index}_img`} className={classNames.itemImage}>
            <Icon iconName="BarChart4" className={scoreIcon} aria-label="Score" />
            <br />
            {`${score} %`}
          </div>
        </StackItem>
        <StackItem grow={10}>
          <div key={`row_${index}_content`} className={classNames.itemContent} title={item.xId}>
            <FileOpenName xcopyName={XcopyName} xcopytype={item.xType} xcopyTitle={item.xId}>
              <Label title={t("tpsearch:searchResults.timestampLbl")}>
                {moment(item.timeStamp).format("YYYY-MM-DD h:mma")}
              </Label>
            </FileOpenName>
            {/* <div className={classNames.itemName} key={`row_${index}_contentTS`}>
              {`${t("tpsearch:searchResults.timestampLbl")}: ${moment(item.timeStamp).format("YYYY-MM-DD h:mma")}`}
            </div> */}
            {/* <div className={classNames.itemTimestamp} key={`row_${index}_contentTS`}>
              {`${item.xId}`}
            </div> */}
            <div
              key={`row_${index}_contentTxt`}
              dangerouslySetInnerHTML={{
                __html: item.text.replace(/class='searchWord'/g, `class='${classNames.searchWord}'`),
              }}
            ></div>
          </div>
        </StackItem>
        <StackItem>
          <LimaFileOpenComponent
            actaId={item.aId}
            actaName={item.aName}
            xcopyId={item.xId}
            xcopyType={item.xType}
            //isActaOwner={isOwnerisActaOwner}
            historyTimeStamp={item.timeStamp}
            openFileHandler={onFileOpenHandlerWrapper}
          />
        </StackItem>
      </Stack>
    ) : (
      <></>
    );
  };

  const ListItem = observer((): JSX.Element => {
    //return <List items={limaSearchStore.list} onRenderCell={onRenderCell} />;
    const groups: IGroup[] = groupsGenerator(limaSearchStore.list);

    return limaSearchStore.totalItemCount == 0 ? (
      <>{t("tpsearch:searchResults.noDataLbl")}</>
    ) : (
      <GroupedList
        items={limaSearchStore.list}
        // eslint-disable-next-line react/jsx-no-bind
        onRenderCell={onRenderCell}
        groupProps={groupedListProps}
        groups={groups}
        compact={true}
      />
    );
  });

  return <ListItem />;
};

export default SearchResultItem;

const onRenderFooter = (props?: IGroupFooterProps): JSX.Element | null => {
  return props ? (
    <div key={"foot_" + props.group.key} className={classNames.itemFooter}>
      Found {props.group.count}x in this File and history
    </div>
  ) : null;
};

const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
  if (props) {
    const toggleCollapse = (): void => {
      props.onToggleCollapse(props.group);
    };
    return (
      <div key={"head_" + props.group.key} className={classNames.itemHeader}>
        <Icon iconName="TextDocument" aria-label="Acta name" />
        <span className={classNames.itemHeaderFilename} title={props.group.key}>
          {props.group.name}
        </span>
        <Link
          key={"link_" + props.group.key}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={toggleCollapse}
        >
          {props.group.isCollapsed ? (
            <Icon iconName="ChevronDown" aria-label="Show list" />
          ) : (
            <Icon iconName="ChevronUp" aria-label="Hide list" />
          )}
        </Link>
      </div>
    );
  }
  return null;
};

const groupedListProps = {
  onRenderHeader,
  onRenderFooter,
};

interface gnType {
  gnId: string;
  gnName: string;
}

function groupsGenerator(itemsList: searchResult[]): IGroup[] {
  // Array of group objects
  const groupObjArr: IGroup[] = [];

  // Get the group names from the items list
  /*let lastGnType:gnType = {gnId:"",gnName:""};
  const groupNames: gnType[] = [
    ...itemsList.map((item: searchResult) => {
      { gnId: item.aId, gnName: item.aName }})
  ];*/

  const groupNames: gnType[] = [];

  itemsList.forEach(function (item: searchResult) {
    const i = groupNames.findIndex((x: gnType) => x.gnId == item.aId);
    if (i <= -1) {
      groupNames.push({ gnId: item.aId, gnName: item.aName });
    }
  });
  //console.log(resArr);

  // Iterate through each group name to build proper group object
  let count = 0;
  groupNames.forEach((gn: gnType) => {
    // Count group items
    const groupLength = itemsList.filter((item: searchResult) => item.aId === gn.gnId).length;

    // Find the first group index
    const groupIndex = itemsList.map((item: searchResult) => item.aId).indexOf(gn.gnId);

    // Generate a group object
    groupObjArr.push({
      key: gn.gnId,
      name: gn.gnName,
      level: 0,
      count: groupLength,
      startIndex: groupIndex,
      isCollapsed: count > 5 ? true : false,
    });
    count++;
  });

  // The final groups array returned
  return groupObjArr;
}
