import { log } from "missionlog";
import { observer } from "mobx-react";
import * as React from "react";
import limaStore from "../../store/limaStore";
import { ITheme, Icon, Stack, getTheme, mergeStyleSets, Text } from "@fluentui/react";
import { limaLogTag } from "../../limaCommon/limaLog";
import { onFileOpenHandlerWrapper } from "../../limaCommon/limaFileOpen";
import limaActasStore from "../../store/limaActasStore";
import { useHistory } from "react-router";
import Routes from "../../taskpane/Menu/routes";
import { XCOPY_TYPE } from "../../types/Task";
import limaBreadCrumbStore from "../../store/limaBreadCrumbStore";
import limaTimerStore from "../../store/limaTimerStore";
import { LimaObjectTypesEnum } from "../../types/BASE_Enums";
import { useTranslation } from "react-i18next";
import LimaActionButtonV4 from "../../taskpane/uiv2/Components/LimaActionButtonV4";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILimaActaWordDocCompareProps {}

const theme: ITheme = getTheme();
//const { palette, fonts } = theme;
const { palette } = theme;

const classNames = mergeStyleSets({
  limaActaDocCompareWrapper: {
    padding: 5,
    backgroundColor: "#f9f9bb",
  },
  limaActaDocCompareActionWrapper: {
    alignSelf: "flex-end",
    paddingRight: 5,
    minWidth: 120,
  },
  limaActaDocCompareActionnIcon: {
    cursor: "pointer",
    selectors: {
      "&:hover": {
        background: palette.neutralLight,
        color: "#0078D4",
      },
    },
  },
});
// const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const LimaActaWordDocCompare: React.FunctionComponent<ILimaActaWordDocCompareProps> = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();

  //----------------
  // REACTIONS
  //---------------
  useEffect(() => {
    limaActasStore.checkLastRead();
  }, []);

  //----------------
  // HANDLERS
  //----------------

  const onSetAsActiveFile = (actaId: string, mainCopyId: string, actaName: string, xcopyType: XCOPY_TYPE) => {
    void onFileOpenHandlerWrapper(
      actaId,
      actaName,
      limaStore.wordDocIdent.limactaDocID,
      null,
      null,
      xcopyType,
      true,
      true
    ).then(() => {
      log.info(limaLogTag.ActaListV4, `Set active timer to acta ${actaId}`);
      //Set timer
      limaTimerStore.setCurrentActivePartion({
        itemId: actaId,
        itemMainCopyId: mainCopyId,
        type: LimaObjectTypesEnum.ACTA,
        userKey: limaStore.userId,
        _key: null,
      });
      //Setu active document
      limaStore.setActa(actaId);
      limaStore.setXcopyType(xcopyType);
      limaStore.setXcopyId(mainCopyId);
      limaStore.setActiveOpenType_setup(actaId, mainCopyId, xcopyType, null);
      limaStore.setIsOwner();

      openDetailViewOfFile(actaId, mainCopyId, actaName);
    });
  };

  const onOpenMostRecentVersionFromLimacta = (
    actaId: string,
    mainCopyId: string,
    actaName: string,
    xcopyType: XCOPY_TYPE
  ) => {
    void onFileOpenHandlerWrapper(
      actaId,
      actaName,
      limaStore.wordDocIdent.limactaDocID,
      null,
      null,
      xcopyType,
      false,
      true
    );
    log.info(limaLogTag.ActaListV4, `Set active timer to acta ${actaId}`);
    limaTimerStore.setCurrentActivePartion({
      itemId: actaId,
      itemMainCopyId: mainCopyId,
      type: LimaObjectTypesEnum.ACTA,
      userKey: limaStore.userId,
      _key: null,
    });
    openDetailViewOfFile(actaId, mainCopyId, actaName);
  };

  const onDismiss = () => {
    limaStore.setWordIdentReplaced(true);
  };
  //-----------------
  // OBSERVERS
  //-----------------

  const LimaActaWordDocCompareState = observer((): JSX.Element => {
    log.info(
      limaLogTag.LimaActaWordDocCompare,
      "check on chagnge actaid, xcopyid:",
      limaStore.actaId,
      limaStore.xcopyId
    );

    const actaId = limaActasStore.getActaIdFocUnknowXcopy(limaStore.wordDocIdent.limactaDocID);
    //const actaName = limaActasStore.getActaNameActaId(actaId);
    const acta = limaActasStore.getActa(actaId);

    const isOwner = acta !== null && acta.ownerKey === limaStore.userId ? true : false;

    return (
      <Stack>
        <Stack.Item>
          <Icon iconName="WarningSolid" style={{ color: "#eddb00" }} />

          <Text variant="medium">
            <b>{t("tpfile:onstartdiffile.onstartdiffileTitleLbl")}</b>
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="small">
            {isOwner ? (
              <i>{t("tpfile:onstartdiffile.infoLbl", { docName: acta.name })}</i>
            ) : (
              <i>{t("tpfile:onstartdiffile.infoNotOwnerLbl")}</i>
            )}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="small">
            <i>{t("tpfile:onstartdiffile.warningSubTitleLbl")}</i>
          </Text>
        </Stack.Item>
      </Stack>
    );
  });

  const LimaActaWordDocCompareActionIcons2 = observer((): JSX.Element => {
    if (limaStore.wordDocIdentReplaced === false && limaStore.isWordDocIdentIsOpenInLima() === false) {
      const actaId = limaActasStore.getActaIdFocUnknowXcopy(limaStore.wordDocIdent.limactaDocID);
      const maincopyID = limaActasStore.getMainCopyIdForActaId(actaId);
      const isMainCopyOwner = limaActasStore.isOwnerMainXcopyId(maincopyID);
      const isMainCopy = limaStore.wordDocIdent.limactaDocID === maincopyID ? true : false;
      const acta = limaActasStore.getActa(actaId);
      const isOwner = acta !== null && acta.ownerKey === limaStore.userId ? true : false;
      const xcopyType = XCOPY_TYPE.MAINXCOPY;

      return (
        <Stack>
          {isOwner && (
            <Stack.Item>
              <LimaActionButtonV4
                onClick={() => onOpenMostRecentVersionFromLimacta(actaId, maincopyID, acta.name, xcopyType)}
                toolTipShow={false}
                disabled={!(isMainCopyOwner && isMainCopy)}
              >
                {t("tpfile:onstartdiffile.openLastFromLimaBtnLbl")}
              </LimaActionButtonV4>
            </Stack.Item>
          )}
          {isOwner && (
            <Stack.Item>
              <LimaActionButtonV4
                onClick={() => onSetAsActiveFile(actaId, maincopyID, acta.name, xcopyType)}
                toolTipShow={false}
                disabled={!(isMainCopyOwner && isMainCopy)}
              >
                {t("tpfile:onstartdiffile.setFromCurerntBtnLbl")}
              </LimaActionButtonV4>
            </Stack.Item>
          )}
          <Stack.Item>
            <LimaActionButtonV4 onClick={onDismiss} toolTipShow={false}>
              {t("tpfile:onstartdiffile.dismissBtnLbl")}
            </LimaActionButtonV4>
          </Stack.Item>
        </Stack>
      );
    }
    return <></>;
  });

  //---------------
  // FUNCTIONS
  //----------------

  const openDetailViewOfFile = (actaId: string, _xcopyId: string, actaName: string) => {
    limaBreadCrumbStore.addNewBreadCrumbExt({
      key: `file_${actaId}`,
      lima_itemid: actaId,
      text: actaName,
      data: `${Routes.ActaDetailRoute_base}${actaId}/${XCOPY_TYPE.MAINXCOPY}`,
      lima_Actions: [
        // {
        //   actionType: LimaBreadCrumbACTIONTYPE.MAIN_COPY_SAVE,
        //   isActionEnabled: (currentOpenFileId, _currentOpenDoctType): boolean => {
        //     log.info(limaLogTag.ActaListV4, `Set active timer to acta ${actaId}`);
        //     return currentOpenFileId === xcopyId ? true : false;
        //   },
        //   actionAction: () => {
        //     log.info(limaLogTag.ActaListV4,`>>>>> HELLO SAVE FILE MAINCOPY `, xcopyId);
        //     history.push(`${Routes.FileSaveNewVersionRoute}`);
        //   },
        // },
      ],
    });
    history.push(`${Routes.ActaDetailRoute_base}${actaId}/${XCOPY_TYPE.MAINXCOPY}`);
  };

  //---------------
  // RENDERS
  //----------------
  return (
    <Stack horizontal className={classNames.limaActaDocCompareWrapper}>
      <Stack.Item grow={1}>
        <LimaActaWordDocCompareState />
      </Stack.Item>
      <Stack.Item shrink={1} className={classNames.limaActaDocCompareActionWrapper}>
        <LimaActaWordDocCompareActionIcons2 />
      </Stack.Item>
    </Stack>
  );
});

export default LimaActaWordDocCompare;
