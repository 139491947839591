import { IStackProps, mergeStyleSets, Stack } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import React = require("react");
import limaStore from "../../../../../store/limaStore";
import { Task_withActaXcopy } from "../../../../../types/Task_withActaXcopy";

// export default LimaHideableSection;
type FileSaveDebugInfoProps = {
  copyId: string;
  taskData: Task_withActaXcopy;
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { width: "100%" } },
};

const classNames = mergeStyleSets({
  wrapper: {
    background: "lightgrey",
  },
});

export const FileSaveDebugInfo = observer(({ copyId, taskData }: FileSaveDebugInfoProps): ReactElement => {
  return (
    <>
      {limaStore.showTestingInfo && (
        <Stack horizontalAlign="start" {...columnProps} className={classNames.wrapper}>
          <Stack.Item>
            <>Acta id: {limaStore.actaId}</>
          </Stack.Item>
          <Stack.Item>
            <>Open file ident: {limaStore.fileOpenIdent}</>
          </Stack.Item>
          <Stack.Item>
            <>copy id: {copyId}</>
          </Stack.Item>

          <Stack.Item>
            <>taskData: {taskData && taskData.taskData && JSON.stringify(taskData.taskData)}</>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
});
