import React = require("react");
import { FunctionComponent, useEffect } from "react";

import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from "office-ui-fabric-react/lib/Styling";
import {
  FocusZone,
  FocusZoneDirection,
  List,
  ScrollablePane,
  ScrollbarVisibility,
  IconButton,
  IIconProps,
} from "office-ui-fabric-react"; //Icon

import { log, tag } from "missionlog";
import { observer } from "mobx-react-lite";

// import limaStore from "../../../store/limaStore";
// import { itemType } from "../../../api/apilinks";
import { userReqLinkingResp } from "../../../dataModels/userAccListDataType";

import limaUsersStore from "../../../store/limaUsersStore";
// import { udpateVisilibity, UpdateVisibilityTypes } from "../../../limaCommon/limaVisibilityCalls";
import limaUsersReqStore from "../../../store/limaUsersReqStore";

log.init({ UsersListReq: "UsersListReq" });

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

//TODO: Cleanup on styles
const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  edgeId: {
    display: "none",
  },
  listItemContainer: {
    display: "flex",
  },
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      flexGrow: 1,
      display: "flex",
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemCellIcon: [
    getFocusStyle(theme, { inset: -1 }),
    {
      paddingRight: 30,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemContent: {
    marginLeft: 10,
    minHeight: 30,
    overflow: "hidden",
    flexGrow: 1,
  },
  userNameStyle: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
});

const RemoveLinkIcon: IIconProps = { iconName: "UserRemove" };
const AcceptLinkIcon: IIconProps = { iconName: "AddFriend" };

//----------------------- COMPONENT --------------------------------------------

const UsersListReq: FunctionComponent = () => {
  const onRenderCell = (item: userReqLinkingResp): JSX.Element => {
    return (
      <div key={item.edge._key} className={classNames.listItemContainer}>
        <div key={item.edge._key + "_inner"} className={classNames.itemCell}>
          <div key={item.edge._key + "_userEdge"} className={classNames.edgeId}>
            edge:{item.edge._key}
          </div>
          <div key={item.edge._key + "_name"} className={classNames.userNameStyle}>
            {item.item.userName} ({item.item.userEmail})
          </div>
        </div>
        <IconButton
          iconProps={AcceptLinkIcon}
          key={item.edge._key + "_acceptLinkBtn"}
          className={classNames.itemCellIcon}
          title="Accept link"
          ariaLabel="Accept link"
          onClick={() => {
            onAcceptLinkingHandler(item);
          }}
        />
        <IconButton
          iconProps={RemoveLinkIcon}
          key={item.edge._key + "_remLinkBtn"}
          className={classNames.itemCellIcon}
          title="Remove link"
          ariaLabel="Remove link"
          onClick={() => {
            onRemoveLinkingHandler(item);
          }}
        />
      </div>
    );
  };

  const onAcceptLinkingHandler = (item: userReqLinkingResp) => {
    log.info(tag.UsersListReq, "onAcceptLinkingHandler: clicked", item);
    limaUsersReqStore.acceptUserLinking(item);
    return null;
  };

  const onRemoveLinkingHandler = (item: userReqLinkingResp) => {
    log.info(tag.UsersListReq, "onRemoveLinkingHandler: clicked", item);
    limaUsersReqStore.rejectUserLinking(item);
    return null;
    //TODO: do it on store
    // udpateVisilibity(
    //   "dUser/" + limaStore.userId,
    //   item.item._id,
    //   "",
    //   limaStore.userId,
    //   "",
    //   new Date().toISOString(),
    //   itemType.user,
    //   UpdateVisibilityTypes.COMMON,
    //   item.edge._id
    // ).then(() => {
    //   item.edge._id = "";
    //   limaUsersStore.removeListSingleItem(item.item._id);
    // });
  };

  const DataItemTextArray = observer((): JSX.Element => {
    return (
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <List items={limaUsersReqStore.reqOfferUsers} onRenderCell={onRenderCell} />
      </ScrollablePane>
    );
  });

  const ShowData: FunctionComponent = observer(() => {
    log.info(tag.UsersListReq, `Will I show something ? [${limaUsersStore.totalItemCount}]`);

    return <>{limaUsersReqStore.totalItemCount > 0 ? <DataItemTextArray /> : <>No linking request now</>}</>;
  });

  useEffect(() => {
    limaUsersReqStore.getDataFromDB();
    return () => {};
  }, []);

  return (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <div className={classNames.wrapper}>
        <ShowData />
      </div>
    </FocusZone>
  );
};

export default UsersListReq;
