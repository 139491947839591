import { FunctionComponent, useEffect, useState } from "react";
import { log } from "missionlog";
import React = require("react");
import { getFocusStyle, getTheme, IStackTokens, ITheme, Label, mergeStyleSets, Stack } from "@fluentui/react";

import { LANGUAGES, IUser, UserForm } from "../../../../../types/User";
import { useMutation } from "react-query";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { limaQuery_getUserByEmail } from "../../../../../api/calls/limaUserCalls";
import { SchemaOf } from "yup";
import * as yup from "yup";
import limaStore from "../../../../../store/limaStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDebounceFn } from "../../../../../limaCommon/customHooks/useDebounce";
import { nameof } from "../../../../../limaCommon/utils";
import { LimaHideableSection2 } from "../../../../../limaComponents/LimaHideableSection2";
import { useTranslation } from "react-i18next";
import UserStatisticActivity from "../statistic/UserStatisticActivity";
import { LIMAENUM_AccesLicPermIdent } from "../../../../../limaCommon/limaPermissions";
import UserPermissionEditPage from "../userPermissions/UserPermissionEditPage";
import { LimaControledTextFieldV4 } from "../../../../../limaComponents/LimaControledTextFieldV4";
import LimaLanguageSwitcher from "../../../../../limaComponents/LimaLanguageSwitcher";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../../limaComponents/LimaNotice";

export interface IUserListDetailV4Props {
  user?: IUser;
  enableEditUser: boolean;
  //onRemove?: () => void;
  enableStore?: (enableStore: boolean) => void;
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    height: "60vh",
  },
  edgeId: {
    display: "none",
  },
  listItemContainer: {
    display: "flex",
  },
  itemCellUserName: [
    getFocusStyle(theme, { inset: -1 }),
    {
      //flexGrow: 1,
      //display: "flex",
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      minWidth: 300,
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
        },
      },
    },
  ],
  itemCellUserActions: [
    {
      minHeight: 30,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      verticalAlign: "medium",
      minWidth: 130,
    },
  ],
  itemCellIcon: [
    getFocusStyle(theme, { inset: -1 }),
    {
      paddingRight: 30,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemCellIconShort: [
    {
      paddingRight: 0,
      minHeight: 30,
      verticalAlign: "medium",
    },
  ],
  itemContent: {
    marginLeft: 10,
    minHeight: 30,
    overflow: "hidden",
    flexGrow: 1,
  },
  userNameStyle: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
    },
  ],
});

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

const schemaUser: SchemaOf<UserForm> = yup.object().shape({
  useremail: yup.string().email().lowercase().not([limaStore.userEmail.toUpperCase()], "This is your email"),
  username: yup.string().min(3, "minimal lenght").required("This is required field"),
  invitemessage: yup.string(),
});

const UserDetailV4: FunctionComponent<IUserListDetailV4Props> = ({
  user,
  enableEditUser,
  enableStore,
}: IUserListDetailV4Props) => {
  const [innerUserId, setInnerUserId] = useState<string | null>(null);
  const [updateReady, setUpdateReady] = useState<boolean>(false);
  // const [isNewEmailUsed, setIsNewEmailUsed] = useState<boolean>(false);
  const { mutate: mutateCheckEmail, data: dataCheckEmail } = useGetUserByEmailQuery();
  const { t } = useTranslation();
  // const { isLoading, error, refetch, data: dataUserQuery } = useGetUserQuery(userId);

  //--------------
  // Forms
  //--------------

  const methods = useForm<UserForm>({
    defaultValues: {
      useremail: user !== undefined ? user.userEmail : "",
      username: user !== undefined ? user.userName : "",
      // lang: user !== undefined ? user.lang : "",
    },
    resolver: yupResolver(schemaUser),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  // const { setValue, setError, watch } = methods;
  const {
    watch,
    control,
    formState: { isValid },
  } = methods;

  const valueOfTest = watch();

  //----------------------
  // Reactions
  //-----------------------

  useEffect(() => {
    log.info(limaLogTag.UserDetailV4, `UEF: UserDetailV4: `, user);

    //set default values
    setUpdateReady(false);
    //update usersListIfNeeded
    if (user !== undefined) setInnerUserId(user._key);
  }, []);

  useEffect(() => {
    log.info(limaLogTag.UserDetailV4, `UEF: UserDetailV4: `, user);
    limaUsersStore.setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    if (enableEditUser !== true) return; //this should never happen
    testChange();
  }, [isValid]);

  useEffect(() => {
    log.debug(limaLogTag.TaskItem, `UEF updateReady change `, updateReady);
    if (enableStore !== undefined) enableStore(updateReady);
  }, [updateReady]);

  //-------------------------
  //---- DEBAUNCERS    ------
  //-------------------------

  useDebounceFn(
    valueOfTest.useremail,
    () => {
      if (user === undefined) {
        log.info(limaLogTag.UserDetailV4, "debounce call useremail: ", valueOfTest.useremail);
        mutateCheckEmail(valueOfTest.useremail);
        limaUsersStore.setCurrentUser({ ...limaUsersStore.currentUser, userEmail: valueOfTest.useremail });
        testChange();
        //limaUsersStore;
      }
    },
    500
  );

  useDebounceFn(
    valueOfTest.username,
    () => {
      if (user !== undefined) {
        log.info(limaLogTag.UserDetailV4, "debounce call username: ", valueOfTest.username);
        limaUsersStore.setCurrentUser({ ...limaUsersStore.currentUser, userName: valueOfTest.username });
        testChange();
      }
    },
    500
  );

  //-------------------------
  //----HANDLERS-------------
  //-------------------------

  const isChanged = (): boolean => {
    log.info(limaLogTag.UserDetailV4, "isChanged >>", user, limaUsersStore.currentUser);
    if (user.userName !== limaUsersStore.currentUser.userName) {
      log.info(
        limaLogTag.UserDetailV4,
        "isChanged NAME NOT SAME>>",
        user.userName,
        limaUsersStore.currentUser.userName
      );
      return true;
    }
    if (user.userEmail !== limaUsersStore.currentUser.userEmail) {
      log.info(
        limaLogTag.UserDetailV4,
        "isChanged Email NOT SAME>>",
        user.userEmail,
        limaUsersStore.currentUser.userEmail
      );
      return true;
    }
    if (user.lang !== limaUsersStore.currentUser.lang) {
      log.info(limaLogTag.UserDetailV4, "isChanged Lang NOT SAME>>", user.lang, limaUsersStore.currentUser.lang);
      return true;
    }
    return false;
  };

  const testChange = (): void => {
    if (enableEditUser !== true) {
      log.info(limaLogTag.UserDetailV4, "testChange enableEdit not true ", enableEditUser);
      return; //this should never happen
    }

    if (isValid === false && updateReady === true) {
      log.info(limaLogTag.UserDetailV4, "testChange isValid false ", isValid, updateReady);
      setUpdateReady(false);
    } else if (isValid === true && isChanged() === true && updateReady === false) {
      log.info(limaLogTag.UserDetailV4, "testChange isValid true ", isValid, updateReady);
      setUpdateReady(true);
    } else if (isChanged() === false && updateReady === true) {
      log.info(limaLogTag.UserDetailV4, "testChange isValid true ", isValid, updateReady);
      setUpdateReady(false);
    } else {
      log.info(limaLogTag.UserDetailV4, "testChange nothing has to happen ", isValid, isChanged(), updateReady);
    }
  };

  const onLanguagechange = (langKey: LANGUAGES) => {
    log.info(limaLogTag.UserDetailV4, "onLanguagechange ", langKey, limaUsersStore.currentUser);
    limaUsersStore.setCurrentUser({ ...limaUsersStore.currentUser, lang: langKey });
    log.info(limaLogTag.UserDetailV4, "onLanguagechange ", langKey, limaUsersStore.currentUser);
    testChange();
  };

  //-------------------------
  //----OBSERVERS-------------
  //-------------------------

  //--------------------
  //  Renderss
  //-------------------

  return (
    <FormProvider {...methods}>
      <form>
        {/*updateReady ? "update" : "dissable"*/}
        <Stack tokens={stackTokens}>
          {limaStore.showTestingInfo && <Stack.Item></Stack.Item>}

          <Stack.Item>
            <div key={user._key + "_name"} className={classNames.userNameStyle}>
              {user.userName}
            </div>
          </Stack.Item>
          <Stack.Item>
            <LimaControledTextFieldV4
              limaLabel={t("tpuser:adduser.useremailLbl")}
              id="useremail"
              required={true}
              control={control}
              name={nameof<UserForm>("useremail")}
              autoFocus={true}
              // underlined
              disabled={innerUserId !== null}
            />
            {!dataCheckEmail || dataCheckEmail.exists === null || dataCheckEmail.exists === "" ? (
              <LimaControledTextFieldV4
                limaLabel={
                  innerUserId !== null ? t("tpuser:adduser.usernamechangeLbl") : t("tpuser:adduser.usernameLbl")
                }
                id="username"
                required={true}
                control={control}
                name={"username"} //nameof<UserForm>("username")
                // underlined
                disabled={enableEditUser !== true}
              />
            ) : (
              <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>{t("tpuser:adduser.alreadyexistV4Lbl")} </LimaNotice>
            )}
            {innerUserId !== null && (
              <Stack.Item>
                <Label>{t("tpmain:settings.setlangLbl")}</Label>
                <LimaLanguageSwitcher
                  lang={user !== undefined && user.lang !== null ? user.lang : LANGUAGES.CZ}
                  onChange={onLanguagechange}
                />
              </Stack.Item>
            )}
          </Stack.Item>
          <Stack.Item>
            <LimaHideableSection2
              name={t("tpuser:userdetial.permissionsLbl")}
              addEnabled={false}
              accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcUserPermissions}
              dontShowOnNoLic={true}
            >
              <p>{t("tpuser:userdetial.permissionsInfoLbl")}</p>
              <UserPermissionEditPage
                userId={user._key}
                actaId={limaStore.actaId}
                companyKey={limaStore.userCompanyKey}
              />
            </LimaHideableSection2>
          </Stack.Item>
          <Stack.Item>
            <LimaHideableSection2
              name={t("tpuser:userdetial.statisticLbl")}
              addEnabled={false}
              accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_funcUserStatistic}
              dontShowOnNoLic={true}
            >
              <UserStatisticActivity userId={user._key} />
            </LimaHideableSection2>
          </Stack.Item>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default UserDetailV4;

//----------------------
//   REACT QUERY
//----------------------

const useGetUserByEmailQuery = () => {
  return useMutation((email: string) => limaQuery_getUserByEmail(email), {
    onMutate: (email: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useGetUserByEmailQuery", email);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useGetUserByEmailQuery", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};
