import { FontIcon, getFocusStyle, getTheme, IIconProps, ITheme, mergeStyleSets } from "@fluentui/react";
import * as React from "react";
import { FunctionComponent, ReactNode } from "react";
import { LIMAENUM_AccesLicPermIdent } from "../../../../../limaCommon/limaPermissions";
import { observer } from "mobx-react-lite";
import limaPermissionStore from "../../../../../store/limaPermissionStore";
// import { computed } from "mobx";
import { useTranslation } from "react-i18next";

interface ILimaToolItemProps {
  toolIcon?: IIconProps;
  toolName: string;
  notYetImplemented?: boolean;
  toolItemWidth?: number;
  count?: string | number;
  info?: string;
  onClickClb?: () => void;
  children?: ReactNode;
  accLicPermIdent?: LIMAENUM_AccesLicPermIdent;
  dontShowOnNoLic?: boolean;
}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  toolItemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      maxWidth: 80,
      minWidht: 75,
      minHeight: 80,
      height: "100%",
      padding: 3,
      paddingTop: 10,
      borderRadius: 5,
      margin: 5,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "default",
      // selectors: {
      //   "&:hover": { background: palette.neutralLight },
      // },
    },
  ],
  toolItemCellActive: [
    //getFocusStyle(theme, { inset: -1 }),
    {
      selectors: {
        "&:hover": {
          background: palette.neutralLight,
          cursor: "pointer",
          // border: `1px solid ${semanticColors.bodyDivider}`,
        },
      },
    },
  ],
  toolItemIconCell: {},
  toolItemIcon: {
    fontSize: 25,
    // margin: "5px 15px",
  },
  toolItemName: [
    fonts.medium,
    {
      // whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 400,
      fontColor: "#333333",
      textAlign: "center",
      lineHeight: "1.1em",
    },
  ],
  toolItemCount: [
    fonts.small,
    {
      background: "#490070",
      color: palette.white,
      borderRadius: 10,
      position: "absolute",
      top: 0,
      right: 10,
      minWidth: 16,
      textAlign: "center",
    },
  ],
  toolWithoutLicences: [
    fonts.small,
    {
      position: "absolute",
      top: 27,
      backgroundColor: "#bab7b78f",
    },
  ],
});

const LimaToolItem: FunctionComponent<ILimaToolItemProps> = observer((props: ILimaToolItemProps) => {
  const { t } = useTranslation();
  const isAccesable =
    props.accLicPermIdent === undefined
      ? true
      : limaPermissionStore.checkAccLicenceAndPermissions(props.accLicPermIdent);
  //------------------------
  // Handlers
  //-------------------------
  //const [accPermited, setAccPermited] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClickHandler = (_e: unknown) => {
    if (props.onClickClb !== undefined && props.notYetImplemented !== true) props.onClickClb();
  };

  // const isAccesable = computed(() => {
  //   if (props.accLicPermIdent === undefined) return true;
  //   return limaPermissionStore.checkAccLicenceAndPermissions(props.accLicPermIdent);
  // }).get();

  if (isAccesable === false && props.dontShowOnNoLic !== undefined && props.dontShowOnNoLic === true) {
    return <></>;
  }

  return (
    <div
      className={`${classNames.toolItemCell} ${
        props.notYetImplemented !== true && isAccesable === true ? classNames.toolItemCellActive : ""
      }`}
      style={props.toolItemWidth !== undefined ? { width: props.toolItemWidth } : {}}
      data-is-focusable={true}
      onClick={onClickHandler}
    >
      {props.toolIcon !== undefined ? (
        <div className={classNames.toolItemIconCell}>
          <FontIcon {...props.toolIcon} className={classNames.toolItemIcon}></FontIcon>
        </div>
      ) : (
        <>{props.children}</>
      )}
      <div className={classNames.toolItemName}>{props.toolName}</div>
      {props.count !== undefined && <div className={classNames.toolItemCount}>{props.count}</div>}
      {isAccesable === false && (
        <div className={classNames.toolWithoutLicences} title={t("tpmain:component.tool.noAccessTtl")}>
          {t("tpmain:component.tool.noAccessLbl")}
        </div>
      )}
    </div>
  );
});

export default LimaToolItem;
