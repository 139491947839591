import { ActionButton, IIconProps, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import {
  convertBackCCToOrigin,
  convertSelecedToCC,
  getWordCCByName,
  navigateToCC,
} from "../../../../limaCommon/limaWordInteract";

interface FileDataItemProps {
  ccName: string;
  componentText: string;
  disabled: boolean;
}

const classNames = mergeStyleSets({
  itemNameCell: {
    width: "20%",
  },
  itemRow: {
    borderBottom: "1px solid #deecf9",
    marginBottom: 5,
  },
});

//TODO: identify if is something selected

const convertIcon: IIconProps = { iconName: "PlugConnected" };
const convertBackIcon: IIconProps = { iconName: "PlugDisconnected" };
const navigateIcon: IIconProps = { iconName: "DocumentSearch" };

const FileDataItem: FunctionComponent<FileDataItemProps> = ({ ccName, componentText, disabled }: FileDataItemProps) => {
  const { t } = useTranslation();
  const [ccExistInWord, setCCExistInWord] = useState<boolean>(false);
  const [ccContent, setCCContent] = useState<string>("");

  useEffect(() => {
    log.info(limaLogTag.FileDataItem, `useEffect:mounting with cc [${ccName}]`);
    getWordCCByName(ccName)
      .then((ccText) => {
        if (ccText === null) {
          setCCExistInWord(false);
          setCCContent("");
        } else {
          setCCExistInWord(true);
          setCCContent(ccText);
        }
      })
      .catch((error) => {
        log.info(limaLogTag.FileDataItem, `useEffect: error getting cc [${ccName}]`, error);
      });
  }, []);

  const onConvertToCC = () => {
    log.info(limaLogTag.FileDataItem, `onConvertToCC: click `);
    void convertSelecedToCC(ccName).then((text) => {
      setCCExistInWord(true);
      setCCContent(text);
    });
  };

  const onConvertBackfromCC = () => {
    log.info(limaLogTag.FileDataItem, `onConvertBackfromCC: click `);
    void convertBackCCToOrigin(ccName).then(() => {
      setCCExistInWord(false);
      setCCContent("");
    });
  };

  const onNavigateToCC = () => {
    log.info(limaLogTag.FileDataItem, `onNavigateToCC: click `);
    void navigateToCC(ccName);
  };

  return (
    <>
      <Stack horizontal className={classNames.itemRow}>
        <Stack.Item grow={1} className={classNames.itemNameCell}>
          <Text variant={"mediumPlus"}>{componentText}:</Text>
        </Stack.Item>
        <Stack.Item grow={11}>
          <Stack>
            <Stack.Item>
              {!ccExistInWord ? (
                <ActionButton iconProps={convertIcon} allowDisabledFocus disabled={disabled} onClick={onConvertToCC}>
                  {t("tpfile:dataitems.dataitem.insertbtnLbl")}
                </ActionButton>
              ) : (
                <>
                  <ActionButton iconProps={convertBackIcon} onClick={onConvertBackfromCC}>
                    {t("tpfile:dataitems.dataitem.replacebackbtnLbl")}
                  </ActionButton>
                  <ActionButton iconProps={navigateIcon} onClick={onNavigateToCC}>
                    {t("tpfile:dataitems.dataitem.findbtnLbl")}
                  </ActionButton>
                </>
              )}
            </Stack.Item>
            <Stack.Item>Current context: {ccContent || "Not yet identified"}</Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default FileDataItem;
