/**
 * Type definition for tags
 */

export enum TagsTypes {
  PERSONAL = "PERSONAL",
  ALLVISIBLE = "ALLVISIBLE",
  ALLVISIBLE_DELETABLE = "ALLVISIBLE_DELETABLE",
}

export interface Tags {
  _key: string;
  _id: string;
  cissuedby: string;
  tagname: string;
  tagcolor: string;
  type: TagsTypes;
  active: boolean;
}
