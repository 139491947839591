import { Separator, Stack, Text } from "@fluentui/react";
import limaLoaderStore, { LoaderListItem } from "../../../store/limaLoaderStore";
import React = require("react");
import { observer } from "mobx-react-lite";
import LimaActionButtonV4 from "../Components/LimaActionButtonV4";
import moment = require("moment");

interface IcallList {
  callInProgress: boolean;
}

export const CallPage = (): JSX.Element => {
  //---------------------
  //    HANDLERS
  //---------------------

  const onACEnd = (item: LoaderListItem) => {
    if (item.ac) {
      item.ac.abort();
      console.log("Download aborted");
    }
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  const CallsList = observer<IcallList>(({ callInProgress }: IcallList) => {
    //const diff = moment().diff(moment(item.f), "seconds");

    const listItems = callInProgress === true ? limaLoaderStore.loadersList : limaLoaderStore.getReversedForeverList;

    return (
      <Stack>
        <Stack.Item>
          <Stack horizontal>
            <Stack.Item grow={1}>
              <b>IDENT</b>
            </Stack.Item>
            <Stack.Item style={{ width: "15%" }}>
              <b>START</b>
            </Stack.Item>
            <Stack.Item style={{ width: "15%" }}>
              <b>CALL</b>
            </Stack.Item>
            <Stack.Item style={{ width: "15%" }}>
              <b>{callInProgress === true ? `ABORT` : `STATE`}</b>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {listItems !== null &&
          listItems.map((item: LoaderListItem) => {
            const now2 = moment();
            const diff = now2.diff(moment(item.f), "seconds");
            return (
              <Stack.Item key={item.k}>
                <Stack horizontal>
                  <Stack.Item grow={1}>{item.t}</Stack.Item>
                  <Stack.Item style={{ width: "15%" }}>{moment(item.f).format("h:mma")}</Stack.Item>
                  <Stack.Item style={{ width: "15%" }}>
                    {callInProgress === true ? `${diff} sec` : `${String((item.f2 / 1000).toFixed(2))} sec`}
                  </Stack.Item>
                  <Stack.Item style={{ width: "15%" }}>
                    {callInProgress === false ? (
                      String(item.end)
                    ) : (
                      <LimaActionButtonV4 onClick={() => onACEnd(item)} toolTipShow={false}>
                        Abort
                      </LimaActionButtonV4>
                    )}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            );
          })}
      </Stack>
    );
  });
  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      <Stack.Item>
        <Text variant="medium">Calls</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="small">Used for testing only reasons to see all Calls and enable to stop them if needed</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="smallPlus">Calls in progress</Text>
        <CallsList callInProgress={true} />
        <Separator />
      </Stack.Item>
      <Stack.Item>
        <Text variant="smallPlus">Finished calls</Text>
        <CallsList callInProgress={false} />
      </Stack.Item>
    </Stack>
  );
};
