import { Separator, Stack, Text } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import limaStore from "../../../../store/limaStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AboutProps {}

const About: FunctionComponent<AboutProps> = () => {
  const { t } = useTranslation();

  const Versions = observer(() => {
    return (
      <>
        <Stack.Item>Limacta Plugin: {limaStore.versions.limaFEVersionId}</Stack.Item>
        <Stack.Item>Limacta Server: {limaStore.versions.limaBEVersionId}</Stack.Item>
        <Stack.Item>Limacta AI Server: {limaStore.versions.limaAIVersionId}</Stack.Item>
      </>
    );
  });

  return (
    <>
      <div style={{ display: "grid", justifyContent: "center" }}>
        {/* <Text variant={"large"}>Limacta plugin</Text> */}
        {/* <img src={limaLogo} alt="lima_Logo" style={{ marginTop: 20, marginBottom: 10 }} />
        <img src={limaLogo2} alt="lima_Logo" style={{ marginTop: 20, marginBottom: 10 }} /> */}
        <img src="./assets/lima128bb.png" alt="lima_Logo" style={{ marginTop: 20, marginBottom: 10 }} />
      </div>
      <div style={{ display: "flex", justifyContent: "left", margin: 10 }}>
        <Text variant={"medium"}>{t("tpfile:about.baseinfoLbl")}</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "left", margin: 10 }}></div>
      {limaStore.logged && (
        <>
          <Separator alignContent="center">Logged as</Separator>
          <Stack style={{ display: "flex", justifyContent: "left", margin: 10 }}>
            <Stack.Item>User id: {limaStore.userId}</Stack.Item>
            <Stack.Item>User email: {limaStore.userEmail}</Stack.Item>
            <Stack.Item>User lang: {limaStore.language}</Stack.Item>
          </Stack>
        </>
      )}
      {/* <Separator alignContent="center">Versions</Separator> */}
      <Stack style={{ display: "flex", justifyContent: "left", margin: 10 }}>
        <Versions />
        {/* <Stack.Item>Limacta Plugin: {limaStore.versions.limaFEVersionId}</Stack.Item>
        <Stack.Item>Limacta Server: {limaStore.versions.limaBEVersionId}</Stack.Item>
        <Stack.Item>Limacta AI Server: {limaStore.versions.limaAIVersionId}</Stack.Item> */}
      </Stack>
      {/* <Separator alignContent="center">@Copyright</Separator> */}
      <Stack style={{ display: "flex", justifyContent: "left", margin: 10, fontSize: 10 }}>
        <Stack.Item>
          <Text variant={"small"}>@Copyright 2021 Limacta and Nearfuture</Text>
        </Stack.Item>
      </Stack>
    </>
  );
};

export default About;
