import { getTheme, ITheme, mergeStyleSets, Persona, PersonaSize, Stack, Text } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import Moment from "react-moment";
import limaStore from "../../../../../store/limaStore";
import { LimactaComment } from "../../../../../types/LimactaComment";

interface CommentLineProps {
  comment: LimactaComment;
}

const theme: ITheme = getTheme();
const { fonts } = theme;

const classNames = mergeStyleSets({
  commReply: {
    marginLeft: 24,
  },
  commDeleted: [
    fonts.small,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textDecoration: "line-through",
      fontWeight: "bold",
    },
  ],
});

const CommentLine: FunctionComponent<CommentLineProps> = ({ comment }: CommentLineProps) => {
  return (
    <Stack horizontal className={comment.reply === true ? classNames.commReply : ""}>
      <Stack.Item>
        <Persona imageInitials={comment.cuserInitials} size={PersonaSize.size24} />
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <Stack.Item>
            <Text variant="small">
              <b>
                {comment.cuserName} <Moment format={limaStore.userDateTimeFormat}>{comment.cTimeStamp}</Moment>
                {comment.state === "DELETE" && (
                  <>
                    {" "}
                    - <Moment format={limaStore.userDateTimeFormat}>{comment.dtimestamp}</Moment>
                  </>
                )}
              </b>
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="smallPlus" className={comment.state === "DELETE" ? classNames.commDeleted : ""}>
              {comment.text}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default CommentLine;
