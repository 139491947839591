export interface actaDocFailed {
  msg: string;
}

export interface actaDocOK {
  actaId: string;
  xcopyId: string;
  actaLastChange?: string;
  actaName?: string;
  state?: string;
}

export function isActaDocOKDataType(model: unknown): model is actaDocOK {
  //return model.hasOwnProperty("actaId") && model.hasOwnProperty("xcopyId") && model.hasOwnProperty("actaName");
  if (model === undefined) return false;

  return (
    (model as actaDocOK).actaId !== undefined &&
    (model as actaDocOK).xcopyId !== undefined &&
    (model as actaDocOK).actaName !== undefined
  );
  //eturn (item as Task_withActaXcopyParasCommentsTasksMessagesAttachements).linkedPara !== undefined;
}
