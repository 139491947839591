import * as React from "react";
import limaStore from "../../../store/limaStore";
import { useEffect, useState } from "react";

import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import { log } from "missionlog";
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaBreadCrumbStore from "../../../store/limaBreadCrumbStore";
import { MenuSelectedENUM } from "../../../limaComponents/LimaMenu";
import TasksListV4, { TASKFILTERITEM_ENUM } from "../Tasks/TaskListV4";
import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStore";
import { TASKSTATE } from "../../../types/Task";
import { useTranslation } from "react-i18next";
import { mergeStyleSets, PrimaryButton, Stack } from "@fluentui/react";
import { LimaSimleTextBtn } from "../../../limaComponents/LimaSimleTextBtn";
// import { LimaLoadingComponnet } from "../../../limaComponents/LimaLoadingComponnet";
import { useHistory } from "react-router";
import Routes from "../../Menu/routes";
import ActaListV4 from "../ActaList/ActaListV4";
import { defWelcomeLastFileLimit } from "../../../limaCommon/limaEnv";

interface IHomeV2Props {
  fileCount?: number;
  taskCount?: number;
  systemMessages?: number;
}

const classNames = mergeStyleSets({
  actListFrame: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
  },
});

const HomeV4: React.FunctionComponent<IHomeV2Props> = ({ fileCount, systemMessages }: IHomeV2Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showFilesAll, setShowFilesAll] = useState<boolean>(false);
  //-----------------
  // REACTION
  //-----------------

  useEffect(() => {
    limaStore.setMenuOpen(MenuSelectedENUM.HOME);
    limaStore.isFirstStart(); // TODO: better place?
  }, []);

  useEffect(() => {
    log.info(limaLogTag.Home2, `listing files ${fileCount}`);

    limaBreadCrumbStore.setBreadCrumbListExt([
      {
        key: "home",
        lima_itemid: "home",
        text: "HOME",
        lima_Actions: [],
      },
    ]);

    limaBreadCrumbStore.setCurrentActions([]);

    return () => {
      limaStore.setMenuOpen(MenuSelectedENUM.NONE);
    };
  }, [limaStore.userId]);

  //------------------
  // HENDLERS
  //------------------

  const onAddNew = () => {
    log.info(limaLogTag.Home2, `Adding new acta`);
    history.push(Routes.FileAddNewActaRoute);
  };

  const onShowMoreLess = () => {
    log.info(limaLogTag.Home2, `onShowMoreLess click`);
    setShowFilesAll(!showFilesAll);
  };

  //-----------------
  // FUNCTIONS
  //-----------------

  //-----------------
  // OBSERVERS
  //-----------------

  return (
    <Stack>
      <Stack.Item>{showFilesAll ? <ActaListV4 /> : <ActaListV4 countLimit={defWelcomeLastFileLimit} />}</Stack.Item>
      <Stack.Item className={classNames.actListFrame}>
        <LimaSimleTextBtn onClick={onShowMoreLess}>
          {showFilesAll ? t("tpmain:lists.showLessLbl") : t("tpmain:lists.showAllLbl")}
        </LimaSimleTextBtn>{" "}
        <PrimaryButton text={t("tpmain:actaList.addNewBtn")} onClick={onAddNew} />
      </Stack.Item>
      {/* {taskListHomePage} */}
      <TasksListV4
        defaultFilters={[
          {
            taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
            taksItemFilterValue: TASK_LINKED2ME_ENUM.IRESPONSIBLEONLY,
          },
          { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC, taksItemFilterValue: false },
          { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE, taksItemFilterValue: TASKSTATE.OPEN },
          //{ taskItemFilterIdent: TASKFILTERITEM.FILTER_TASKCOUNT, taksItemFilterValue: defWelcomeLastFileLimit },
        ]}
        includeHidable={true}
      ></TasksListV4>
      <Stack.Item>
        {systemMessages && <LimaHideableSection2 name={"System messages"}></LimaHideableSection2>}
      </Stack.Item>
      {/* <Stack.Item>{LimaLoadingComponnet(2, "TASKS", true)}</Stack.Item> */}
    </Stack>
  );
};

export default HomeV4;
