import React = require("react");
import { FunctionComponent, useEffect } from "react";

import { Switch } from "react-router-dom";

import Routes from "../Menu/routes";

import FileOpen from "../Menu/File/FileOpen/FileOpenPage";
import FileSave from "../Menu/File/FileSave/FileSavePage";
import FileImportPage from "../Menu/File/FileImport/FileImportPage";
// import TaskEditPage from "../Menu/Edit/Tasks/TaskEditPage";
import TaskItemsPage2 from "../Menu/Edit/Tasks/TaskItemPage2";
import UserSetting from "../uiv2/UserSetting/UserSetting";
import ActsParaSearchPage from "../Menu/Advice/Acts/ActsParaSearchPage";
import JudgmentSearchPage from "../Menu/Advice/Judgments/JudgmentSearchPage";
// import UsersAddPage from "../Menu/Users/2DL_usersAdd";
import UsersListPage from "../Menu/Users/UserList/userListPage";
import SearchPage from "../Menu/Edit/Search/SearchPage";
import UsersLinkingPage from "../Menu/Users/userSearchPage";
// import HistoryPage from "../Menu/Show/history/HistParaItemsHolder";
// import AccessPage from "../Menu/Show/access/ShowUserAccessPage";
// import ShowUserAccessPage2 from "../Menu/Show/access/2DL_ShowUserAccessPage2";
import KeywordSearchPage from "../Menu/Advice/Keywords/KeywordsSearchPage";
import FileDataPage from "../Menu/File/FileData/FileDataPage";
// import TasksVersionsPage from "../Menu/Show/tasksXcopy/TasksXcopyPage";
// import NotesPage from "../Menu/Edit/Notes/NotesPage";
import WelcomePage from "../Menu/File/Home/WelcomePage";
import ProtectedRoute from "../Menu/TaskPaneComponents/ProtectedRoute";

import { useLocation } from "react-router-dom";
import UnprotectedLoginRoute from "../Menu/TaskPaneComponents/UnprotectedLoginRoute";
import limaStore from "../../store/limaStore";
import { observer } from "mobx-react-lite";
import About from "../Menu/File/About/About";
import TaskAddPage from "../Menu/Edit/Tasks/TaskAddPage";
import HistParaItemPage from "../Menu/Show/history/HistParaItemPage";
import EditStatesPage from "../Menu/Edit/States/EditStatesPage";
import AdviceCheckDocPage from "../Menu/Advice/Check/AdviceCheckDocPage";
import UserLinkingPage from "../Menu/Users/UserLinking/UserLinkingPage";
import UniversalAccess from "../Menu/Show/universalAccess/UniversalAccess";
import LoginProcessing from "./LoginProcessing";
import HomeV4 from "../uiv2/Home/HomePageV4";
import { FileItemV4 } from "../uiv2/FileItem/FileItemV4";
import ToolsV2 from "../uiv2/Tools/ToolsV2";
import UserInviteV4 from "../uiv2/Users/UserInvite/UserInviteV4";
import UserInitationsV4 from "../uiv2/Users/UserInitations/UserInitationsV4";
import UsersListV4 from "../uiv2/Users/UsersList/UsersListV4";
import UserInviteFinishV4 from "../uiv2/Users/UserInvite/UserInviteFinishV4";
import UserCreateFinishV4 from "../uiv2/Users/UserInvite/UserCreateFinishV4";
// import MainCopyNewVersionV4 from "../uiv2/FileItem/Save/MainCopyNewVersionV4";
import CopyNewVersionV4 from "../uiv2/FileItem/Save/CopyNewVersionV4";
import MainCopyNewVersionV4 from "../uiv2/FileItem/Save/MainCopyNewVersionV4";
import FileStoreFinishV4 from "../uiv2/FileItem/Save/FileStoredFinish";
import WorkingCopySaveExistV4 from "../uiv2/FileItem/Save/WorkingCopySaveExistV4";
import TimerV4 from "../uiv2/Timers/TimerV4";
import TaskListV4Single from "../uiv2/Tasks/TaskListV4Single";
import TastItemV4Single from "../uiv2/Tasks/TastItemV4Single";
import { LimaAddActaItemV4 } from "../uiv2/AddActa/LimaAddActaItemV4";
import TimersV4report from "../uiv2/Timers/TimersV4report";
import HistParaItemPageV4 from "../uiv2/History/HistParaItemPageV4";
import HomeCompLic from "../uiv2/Home/HomeCompLic";
import { LimaEqualChangePage } from "../uiv2/EqualChange/LimaEqualChangePage";
import UserLicencesPage from "../uiv2/Users/UserLicences/UserLicencesPage";
import CompaniesEditPage from "../uiv2/Users/Companies/CompaniesEditPage";
import { CallPage } from "../uiv2/TestingHelpers/CallPage";
import { LogsPage } from "../uiv2/TestingHelpers/LogsPage";
import DataPage from "../uiv2/TestingHelpers/DataPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TaskPaneMainRouterSwitchProps {}

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    limaStore.processLocationChangeCallBack();
  }, [location]);
}

const TaskPaneMainRouterSwitch: FunctionComponent<TaskPaneMainRouterSwitchProps> = observer(() => {
  usePageViews();

  //------------------
  // REACTIONS
  //-----------------

  return (
    <Switch>
      <ProtectedRoute path={`${Routes.LoginProcessing}`} component={LoginProcessing} />
      <ProtectedRoute path={`${Routes.HomeCompLic}`} component={HomeCompLic} />
      <ProtectedRoute path={`${Routes.HomeV2Route}`} component={HomeV4} />
      {/* <Route path={`${Routes.HomeV2Route}`} component={HomeV4} /> */}
      <ProtectedRoute path={`${Routes.ToolsV2Route}`} component={ToolsV2} />
      <ProtectedRoute path={`${Routes.UserInviteRoute}`} component={UserInviteV4} />
      <ProtectedRoute path={`${Routes.UserInitateFinishRoute}`} component={UserInviteFinishV4} />
      <ProtectedRoute path={`${Routes.UserCreateUserFinishRoute}`} component={UserCreateFinishV4} />

      <ProtectedRoute path={`${Routes.UserInvitionsRoute}`} component={UserInitationsV4} />
      <ProtectedRoute path={`${Routes.UserListRoute}`} component={UsersListV4} />
      <ProtectedRoute path={`${Routes.UserLicencesRoute}`} component={UserLicencesPage} />
      <ProtectedRoute path={`${Routes.UserCompaniesRoute}`} component={CompaniesEditPage} />

      <ProtectedRoute path={`${Routes.FileSaveNewVersionRoute}`} component={MainCopyNewVersionV4} />
      <ProtectedRoute path={`${Routes.FileSaveNewCopyRoute}`} component={CopyNewVersionV4} />
      <ProtectedRoute path={`${Routes.FileSaveExitCopyRoute}`} component={WorkingCopySaveExistV4} />
      <ProtectedRoute path={`${Routes.FileSaveFinishRoute}`} component={FileStoreFinishV4} />

      <ProtectedRoute path={`${Routes.ActaDetailRoute}`} component={FileItemV4} />

      <ProtectedRoute path={`${Routes.LimactaTimerRoute}`} component={TimerV4} />
      <ProtectedRoute path={`${Routes.LimactaTimersReportRoute}`} component={TimersV4report} />

      <ProtectedRoute path={`${Routes.ToolsTaskListRoute}`} component={TaskListV4Single} />
      <ProtectedRoute path={`${Routes.ToolsTaskAddRoute}`} component={TastItemV4Single} />

      <ProtectedRoute path={`${Routes.FileAddNewActaRoute}`} component={LimaAddActaItemV4} />

      <ProtectedRoute path={`${Routes.ShowMenuHistoryRoutev4}`} component={HistParaItemPageV4} />
      <ProtectedRoute path={`${Routes.ToolsLimaEqualChangeRoute}`} component={LimaEqualChangePage} />

      {/* ORIGIN ROUTES */}
      <ProtectedRoute path={`${Routes.FileMenuImportRoute}`} component={FileImportPage} />
      <ProtectedRoute path={`${Routes.FileMenuSaveRoute}`} component={FileSave} />
      <ProtectedRoute path={`${Routes.FileMenuOpenRoute}`} component={FileOpen} />

      <ProtectedRoute path={`${Routes.FileMenuDataRoute}`} component={FileDataPage} />
      <ProtectedRoute path={`${Routes.FileMenuHome}`} component={WelcomePage} />
      <ProtectedRoute path={`${Routes.FileMenuAbout}`} component={About} />
      <ProtectedRoute path={`${Routes.FileMenuDataRoute}`} component={FileDataPage} />

      <ProtectedRoute path={`${Routes.UserMenuAddRoute}`} component={UserLinkingPage} />
      <ProtectedRoute path={`${Routes.UserMenuListRoute}`} component={UsersListPage} />
      <ProtectedRoute path={`${Routes.UserMenuLinkingRoute}`} component={UsersLinkingPage} />

      <ProtectedRoute path={`${Routes.EditMenuHelperRoute}`} component={UserSetting} />
      {/* <ProtectedRoute path={`/${Routes.EditMenuTasksRoute}`} component={TaskEditPage} /> */}
      <ProtectedRoute path={`${Routes.EditMenuTaskAddRoute}`} component={TaskAddPage} />
      <ProtectedRoute path={`${Routes.EditMenuTasks2Route}`} component={TaskItemsPage2} />
      {/* <ProtectedRoute path={`/${Routes.EditMenuNotesRoute}`} component={NotesPage} /> */}
      <ProtectedRoute path={`${Routes.EditMenuSearchRoute}`} component={SearchPage} />
      <ProtectedRoute path={`${Routes.EditMenuStatesPage}`} component={EditStatesPage} />

      {/* <ProtectedRoute path={`/${Routes.ShowMenuHistoryRoute}`} component={HistoryPage} /> */}
      <ProtectedRoute path={`${Routes.ShowMenuHistoryRoute2}`} component={HistParaItemPage} />
      <ProtectedRoute path={`${Routes.ShowMenuAccess}`} component={UniversalAccess} />
      <ProtectedRoute path={`${Routes.ToolsTestingCallPageRoute}`} component={CallPage} />
      <ProtectedRoute path={`${Routes.ToolsTestingErrsPageRoute}`} component={LogsPage} />
      <ProtectedRoute path={`${Routes.ToolsTestingDataPageRoute}`} component={DataPage} />

      <UnprotectedLoginRoute
        isAuthenticated={limaStore.logged}
        path={`${Routes.AdviceMenuAdviceActSearchRoute}`}
        component={ActsParaSearchPage}
      />
      <ProtectedRoute path={`${Routes.AdviceMenuAdviceJudgeSearchRoute}`} component={JudgmentSearchPage} />
      <ProtectedRoute path={`${Routes.AdviceMenuAdviceKeywordsRoute}`} component={KeywordSearchPage} />
      <ProtectedRoute path={`${Routes.AdviceMenuAdviceCheckDocRoute}`} component={AdviceCheckDocPage} />

      <ProtectedRoute path="/" component={HomeV4} />
    </Switch>
  );
});

export default TaskPaneMainRouterSwitch;
