import { DefaultButton, IIconProps, Stack } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { TaskTypeEnum } from "../../../../../types/Task";

interface TaskItemTypeProps {
  type: TaskTypeEnum;
  changeType: (type: TaskTypeEnum) => void;
}

const taskIcon: IIconProps = { iconName: "TaskSolid" };
const noteIcon: IIconProps = { iconName: "EditNote" };
const pointIcon: IIconProps = { iconName: "StatusCircleExclamation" };

const TaskItemType: FunctionComponent<TaskItemTypeProps> = ({ type, changeType }: TaskItemTypeProps) => {
  const { t } = useTranslation();
  const [innerType, setInnerType] = useState<TaskTypeEnum>(type);
  const onClickType = (type2: TaskTypeEnum) => {
    setInnerType(type2);
    changeType(type2);
  };
  return (
    <Stack horizontal grow={12}>
      <Stack.Item grow={innerType == TaskTypeEnum.TASK ? 6 : 3}>
        <DefaultButton
          toggle
          checked={innerType == TaskTypeEnum.TASK}
          text={
            innerType == TaskTypeEnum.TASK
              ? t("tptasks:taskList.taskItem.tasktype.taskselectedlbl")
              : t("tptasks:taskList.taskItem.tasktype.taskbasiclbl")
          }
          iconProps={taskIcon}
          onClick={() => onClickType(TaskTypeEnum.TASK)}
          allowDisabledFocus
        />
      </Stack.Item>
      <Stack.Item grow={innerType == TaskTypeEnum.NOTE ? 6 : 3}>
        <DefaultButton
          toggle
          checked={innerType == TaskTypeEnum.NOTE}
          text={
            innerType == TaskTypeEnum.NOTE
              ? t("tptasks:taskList.taskItem.tasktype.noteselectedlbl")
              : t("tptasks:taskList.taskItem.tasktype.notebasiclbl")
          }
          iconProps={noteIcon}
          onClick={() => onClickType(TaskTypeEnum.NOTE)}
          allowDisabledFocus
        />
      </Stack.Item>
      <Stack.Item grow={innerType == TaskTypeEnum.POINT ? 6 : 3}>
        <DefaultButton
          toggle
          checked={innerType == TaskTypeEnum.POINT}
          text={
            innerType == TaskTypeEnum.POINT
              ? t("tptasks:taskList.taskItem.tasktype.pointselectedlbl")
              : t("tptasks:taskList.taskItem.tasktype.pointbasiclbl")
          }
          iconProps={pointIcon}
          onClick={() => onClickType(TaskTypeEnum.POINT)}
          allowDisabledFocus
        />
      </Stack.Item>
    </Stack>
  );
};

export default TaskItemType;
