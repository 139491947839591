import React = require("react");
import { LimaIconProps } from "./DefLimaIconType";

export const LimaIconToolsHistory = ({
  size = 32, // or any default size of your choice
  color = "black", // or any color of your choice
  title = "", //title
}: LimaIconProps): JSX.Element => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    //   aria-labelledby="title"
    // >
    //   <title id="title">{title}</title>
    //   <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
    //   <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.75 26.5"
      width={size} // added size here
      height={size} // added size here
      fill={color} // added color here
      aria-labelledby="title"
    >
      <title id="title">{title}</title>
      <g id="History" transform="translate(-304 -221.25)">
        <path
          id="Path_8967"
          data-name="Path 8967"
          d="M12.5,0a12.642,12.642,0,0,1,8.664,3.489A12.3,12.3,0,0,1,25,12.5,12.5,12.5,0,0,1,12.5,25a12.666,12.666,0,0,1-7.616-2.587A12.336,12.336,0,0,1,0,12.5"
          transform="translate(309 247) rotate(-90)"
          fill="none"
          stroke="#8e8e93"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <g
          id="Polygon_1"
          data-name="Polygon 1"
          transform="translate(314 240) rotate(180)"
          fill="#8e8e93"
          strokeLinecap="round"
        >
          <path
            d="M 8.398711204528809 5.249997615814209 L 1.601287245750427 5.249997615814209 L 5 1.171549439430237 L 8.398711204528809 5.249997615814209 Z"
            stroke="none"
          />
          <path
            d="M 5 2.343072414398193 L 3.202562808990479 4.499997615814209 L 6.797436237335205 4.499997615814209 L 5 2.343072414398193 M 5 0.2810254096984863 C 5.142079830169678 0.2810254096984863 5.284160137176514 0.3409929275512695 5.384109973907471 0.4609279632568359 L 9.316590309143066 5.17990779876709 C 9.587980270385742 5.50556755065918 9.356399536132812 5.999997615814209 8.932479858398438 5.999997615814209 L 1.067520141601562 5.999997615814209 C 0.6436004638671875 5.999997615814209 0.4120197296142578 5.50556755065918 0.6834096908569336 5.17990779876709 L 4.615890026092529 0.4609279632568359 C 4.715839862823486 0.3409929275512695 4.857920169830322 0.2810254096984863 5 0.2810254096984863 Z"
            stroke="none"
            fill="#8e8e93"
          />
        </g>
        <path
          id="Path_8968"
          data-name="Path 8968"
          d="M3878.5,1408.772v9.5l4.282,4.282"
          transform="translate(-3557 -1183)"
          fill="none"
          stroke="#8e8e93"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
