import * as React from "react";
import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStoreV4";
// import { TASK_LINKED2ME_ENUM } from "../../../store/limaTasksStore";
import { TASKSTATE } from "../../../types/Task";
import TasksListV4, { TASKFILTERITEM_ENUM } from "./TaskListV4";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITaskListV4SingleProps {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskListV4Single: React.FunctionComponent<ITaskListV4SingleProps> = (_props) => {
  return (
    <TasksListV4
      defaultFilters={[
        {
          taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_CREATOR,
          taksItemFilterValue: TASK_LINKED2ME_ENUM.NOTONLYMY,
        },
        { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_LINKTOACTIVEDOC, taksItemFilterValue: false },
        { taskItemFilterIdent: TASKFILTERITEM_ENUM.FILTER_TASKSTATE, taksItemFilterValue: TASKSTATE.OPEN },
      ]}
      includeHidable={false}
    ></TasksListV4>
  );
};

export default TaskListV4Single;
