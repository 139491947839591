import { log } from "missionlog";
// import { observer } from "mobx-react";
import { mergeStyleSets, Stack } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaObjtag } from "../../../limaComponents/Leafs/LimaObjtag";
import { LimaHideableSection2 } from "../../../limaComponents/LimaHideableSection2";
import limaObjTagsStore from "../../../store/limaObjTagsStore";

// import limaStore from "../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { Tags } from "../../../types/Tags";
import { TagsAddComponent } from "./TagsAddComponent";

interface TagsComponentProps {
  linkedId: string;
  linkedType: LIMA_ITEM_TYPE;
  tagsKeyList?: string[];
  onAddCallback?: (tagKey: string) => void;
  onRemoveCallback?: (tagKey: string) => void;
}

const classNames = mergeStyleSets({
  tagsList: {
    maxHeight: 300,
    overflow: "auto",
  },
});

const TagsComponent: FunctionComponent<TagsComponentProps> = ({
  linkedId,
  linkedType,
  tagsKeyList,
  onAddCallback,
  onRemoveCallback,
}: TagsComponentProps) => {
  // const [showAdd, setShowAdd] = useState<boolean>();
  const [tagsList, setTagsList] = useState<string[]>(tagsKeyList ? tagsKeyList : []);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const { t } = useTranslation();
  //--------------
  // Reactions
  //--------------
  useEffect(() => {
    log.info(limaLogTag.TagsComponent, "UEF: linked item: ", linkedId, String(linkedType));
    log.info(limaLogTag.TagsComponent, "UEF: linked tags: ", tagsKeyList);
    if (tagsKeyList === undefined || tagsKeyList === null) setTagsList([]);
  }, []);

  useEffect(() => {
    log.info(limaLogTag.TagsComponent, "UEF: on taglist change: ", tagsKeyList);
  }, [tagsList]);

  //--------------
  // Handlers
  //--------------

  const onAddTagHandler = () => {
    log.info(limaLogTag.TagsComponent, "onAddTagHandler: clicked ");
    if (showDetail !== true) setShowDetail(true);
  };

  const onRemTag = (tagToUnlink: Tags) => {
    const updatedTagsList = tagsList.filter((item: string) => item !== tagToUnlink._key);
    //store link
    void limaObjTagsStore
      .updateTagsOnItem(linkedType, linkedId, updatedTagsList)
      .then(() => {
        setTagsList(updatedTagsList);
        if (onRemoveCallback !== undefined) onRemoveCallback(tagToUnlink._key);
      })
      .catch((err) => {
        log.error(limaLogTag.TagsComponent, "onRemTag: some error ", err);
      });
  };

  //-------------
  // renderes
  //-------------

  if (linkedId === undefined || linkedId === null || linkedId === "") {
    return <>{t("tpmain:tags.tagslinkingIDmissingLbl")}</>;
  }

  return (
    <LimaHideableSection2
      name={t("tpmain:tags.tagsLbl")}
      headerCount={tagsList && String(tagsList.length)}
      addCallback={onAddTagHandler}
      // e={showAdd}
      addEnabled={true}
      editPanelhideFooter={true}
      editPanelName={t("tpmain:tags.addTags.tagPanelLabel")}
      passShowHideToContent={false}
      onDetialSaveUpdateCallBack={() => {
        log.info(limaLogTag.TasksList, "onDetialSaveUpdateCallBack: ");
        //TODO: onStore();
      }}
      editDetail={
        <TagsAddComponent
          onAddCallback={(tagKey: string) => {
            log.info(limaLogTag.TagsComponent, "onTagsAddin tag component adding: key ", tagKey, tagsList);
            setTagsList([...tagsList, tagKey]);
            if (onAddCallback !== undefined) onAddCallback(tagKey);
          }}
          onRemoveCallback={(tagKey: string) => {
            log.info(limaLogTag.TagsComponent, "onRemoveCallback tag component remove: key ", tagKey, tagsList);
            setTagsList(tagsList.filter((item: string) => item !== tagKey));
            if (onRemoveCallback !== undefined) onRemoveCallback(tagKey);
          }}
          maxTagsShowCount={5}
          linkedId={linkedId}
          linkedType={linkedType}
          currentTagsList={tagsList}
        />
      }
      editDetailOpen={showDetail}
      editDetailEnableStore={false}
      onDetailBackCallBack={() => {
        log.info(limaLogTag.TasksList, "onDetailBackCallBack: onDetailBackCallBack ");
        setShowDetail(false);
        // updateTaskList();
      }}
    >
      <Stack>
        <Stack.Item className={classNames.tagsList}>
          {tagsList &&
            tagsList.map((item: string) => {
              const itemTag = limaObjTagsStore.getTagByKey(item);
              log.info(limaLogTag.TagsComponent, "on render tag: change to ", item, itemTag);
              if (itemTag === null) {
                return <></>;
              }
              return (
                <LimaObjtag
                  key={"tagL_" + String(itemTag._key)}
                  text={itemTag.tagname}
                  tagColor={itemTag.tagcolor}
                  remEnabled={true}
                  remCallback={() => onRemTag(itemTag)}
                />
              );
            })}
        </Stack.Item>
      </Stack>
    </LimaHideableSection2>
  );
};

export default TagsComponent;
