import { mergeStyleSets } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");

interface FileItemHistAppendixProps {
  data: HistAppendixStateItem;
}
export interface HistAppendixStateItem {
  // id: string;
  state?: boolean;
  appendixType: HistAppendixStateENUM;
}

export interface HistAppendixStateItemV4 {
  id: string;
  // state?: boolean;
  // appendixType: HistAppendixStateENUM;
}

export enum HistAppendixStateENUM {
  NOTSETYET,
  STARTWITHOUTEND,
  START,
  BETWEEN,
  END,
  OUT,
}
const classNames = mergeStyleSets({
  itemsBackground: {
    backgroundColor: "lightgray",
  },
});

const FileItemHistAppendix: FunctionComponent<FileItemHistAppendixProps> = ({ data }: FileItemHistAppendixProps) => {
  // React.useEffect(() => {
  //   // console.log("FileItemHistAppendix mounted", data);
  // }, []);

  const contentText = () => {
    let out = "A";

    if (data === undefined || data === null) return out;

    if (data.appendixType == HistAppendixStateENUM.OUT) out = " ";
    else if (data.appendixType == HistAppendixStateENUM.BETWEEN) out = "|";
    else if (data.appendixType == HistAppendixStateENUM.END) out = "->";
    else if (data.appendixType == HistAppendixStateENUM.START) out = "->";
    else if (data.appendixType == HistAppendixStateENUM.STARTWITHOUTEND) out = "?";
    else if (data.appendixType == HistAppendixStateENUM.NOTSETYET) out = "+";

    /*
    switch (data.appendixType) {
      case HistAppendixStateENUM.OUT:
        out = "";
        break;
      case HistAppendixStateENUM.START:
        out = "->";
        break;
      case HistAppendixStateENUM.BETWEEN:
        out = "| -";
        break;
      case HistAppendixStateENUM.END:
        out = "->";
        break;
    }*/

    return out;
  };

  return <span className={classNames.itemsBackground}>{contentText()}</span>;
};

export default FileItemHistAppendix;
