import { Dropdown, IDropdownOption, PrimaryButton, Stack, Text } from "@fluentui/react";
import { log } from "missionlog";
import { FunctionComponent, useState } from "react";
import React = require("react");
// import { userAccListDataTypeLinked } from "../../../../../dataModels/userAccListDataType";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { getFileAsyncInternal } from "../../../../../limaCommon/limaSaveDoc";
import { ENUM_LogstoreLogType } from "../../../../../store/limaLogsStore";
import limaLoadeStore from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import limaUsersStore from "../../../../../store/limaUsersStore";
import { LimaDocTypeENUM } from "../../../../../types/docxData";
import { IUser } from "../../../../../types/User";
import { xcopyHistory_null } from "../../../../../types/XcopyHistory";
import limaLogsStore from "../../../../../store/limaLogsStore";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FileSaveToExistsProps {}

const FileSaveToExists: FunctionComponent<FileSaveToExistsProps> = () => {
  const [onBehalf, setOnBehalf] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const saveExistingFile = async () => {
    setIsError(false);
    log.info(limaLogTag.FileSaveToExists, "Saving existing file");
    //limaStore.setLoader(true);
    const loaderID = limaLoadeStore.add("Storing existing file");
    // setInDoc(true);
    // setSaveExisting(true);
    await getFileAsyncInternal(
      limaStore.actaId,
      limaStore.actaId,
      LimaDocTypeENUM.ACTA,
      limaStore.actaId,
      LimaDocTypeENUM.ACTA,
      limaStore.actaName,
      limaStore.userId,
      xcopyHistory_null(), //version
      () => {
        limaLoadeStore.remove(loaderID.k);
      },
      () => {
        limaLoadeStore.remove(loaderID.k);
        setIsError(true);
        limaLogsStore.add(
          "Save new file to exist error",
          "Some error on saving existing  file",
          ENUM_LogstoreLogType.ERROR,
          limaLogTag.FileSaveToExists
        );
      },
      null,
      onBehalf
    );
  };

  return (
    <Stack>
      <Stack.Item>
        <Text variant="medium">Uploading changes to existing document on limacta server.....</Text>
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          label={"Change on behalf:"}
          selectedKey={onBehalf}
          options={limaUsersStore.fullList.map((item: IUser) => {
            return { key: item._key, text: item.userName };
          })}
          onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (option !== undefined) {
              console.log("Dropdown onchange to ", option);
              // onStore(option.key.toString());
              setOnBehalf(option.key.toString());
            }
            //return option.key;
          }}
        />
      </Stack.Item>
      {isError && (
        <Stack.Item>
          <Text variant="small">Some error on storing</Text>
        </Stack.Item>
      )}
      <Stack.Item>
        <PrimaryButton
          text="Upload to server - Update"
          onClick={saveExistingFile}
          disabled={!limaStore.IamDocumentOwner}
        />
      </Stack.Item>
      {!limaStore.IamDocumentOwner && (
        <>
          <Stack.Item>
            <Text variant="small">You are not owner of document</Text>
          </Stack.Item>
        </>
      )}
    </Stack>
  );
};

export default FileSaveToExists;
