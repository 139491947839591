import { log, tag } from "missionlog";
log.init({ textHelpers: "textHelpers" });

export const replaceSecialCharacters = (text: string): string => {
  //  console.log(text);
  log.info(tag.textHelpers, `replaceSecialCharacters: start replace ADD, DEL`);
  text = text.replace(/\[\#ADD\:([^\@]*)\@\]/gm, "<span style='background-color:#90ee90'>$1</span>");
  text = text.replace(
    /\[\#DEL\:([^\@]*)\@\]/gm,
    "<span style='background-color:#ee9090;text-decoration: line-through;'>$1</span>"
  );

  //(\w*)
  return text;
};

export const replaceRegexWihtColoring = (text: string, regex: string, backGroundColor: string): string => {
  log.info(tag.textHelpers, `replaceRegexWihtColoring: regext '${regex}'`);
  if (regex === undefined || regex === "") return text;

  //log.info(tag.textHelpers, `replaceRegexWihtColoring: origin text'${text}'`);
  var myregexp = new RegExp(regex, "g");
  /*console.log(myregexp);
  let result;

  while ((result = myregexp.exec(text)) !== null) {
    });
  }*/

  //text = text.replace(/\[\#ADD\:(\w*)\@\]/gm, "<span style='background-color:"+backGroundColor+"'>$1</span>");
  let outtext = text.replace(myregexp, "<span style='background-color:" + backGroundColor + "'><b>$1</b></span>");
  //log.info(tag.textHelpers, `replaceRegexWihtColoring: Output text'${outtext}'`);

  return outtext;
};
