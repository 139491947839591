import { Label, List, mergeStyleSets, Stack, Toggle } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import moment = require("moment");
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { actaListOK } from "../../../api/schema/getActaList";
import { LimaSimleTextLink } from "../../../limaComponents/LimaSimleTextLink";
import limaActasStore from "../../../store/limaActasStore";
// import limaTasksStoreV4 from "../../../store/limaTasksStoreV4";
import limaTimerStore from "../../../store/limaTimerStore";
// import { LimaObjectTypesEnum } from "../../../types/BASE_Enums";
import { ITimerPartionBase, ITimePerActa } from "../../../types/Timer";
// import { User2Task_withActaXcopyParasCommentsTasksMessagesAttachements } from "../../../types/User2Task_withActaXcopy";
import Routes from "../../Menu/routes";
import TimerPartion from "./components/TimerPartion";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITimerV4Props {}

const classNames = mergeStyleSets({
  time: {
    flex: "none",
  },
  historyLbl: {
    cursor: "pointer",
  },
  actaDetailSubItemRow: {
    paddingLeft: 8,
  },
  actaDetailItemRow: {
    paddingTop: 8,
  },
});

const TimerV4: React.FunctionComponent<ITimerV4Props> = observer(() => {
  const { t } = useTranslation();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  //---------------
  // Handlers
  //---------------
  const onChangeTimer = (_ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
    limaTimerStore.setTimerIsActive(checked);
  };

  const onShowStateTogle = () => {
    setShowHistory(!showHistory);
  };
  //------------------
  // Renders
  //--------------------
  const actaName = (actaId: string, long?: boolean): JSX.Element => {
    const itemActa: actaListOK = limaActasStore.getActa(actaId);
    if (itemActa === null) return <></>;
    if (long === true) {
      return <div>{`${t("tpmain:timer.maindocumentLbl")} ${itemActa.name}`}</div>;
    }
    return <div style={{ fontWeight: itemActa.isOwner ? 600 : 400 }}>{`${itemActa.name}`}</div>;
  };

  // const renderTimerPartion = (partionItem: ITimePerActa) => {
  //   return (
  //     <Stack>
  //       <Stack.Item className={classNames.actaDetailItemRow}>
  //         <Stack horizontal>
  //           <Stack.Item>{actaName(partionItem.itemMainCopyId)}</Stack.Item>
  //           <Stack.Item className={classNames.time}>{`: ${moment
  //             .utc(partionItem.actaTimerValue * 1000)
  //             .format("HH:mm:ss")}`}</Stack.Item>
  //         </Stack>
  //       </Stack.Item>
  //       <Stack.Item>
  //         {partionItem.timerPartitions.map((item: ITimerPartion, index: number) => {
  //           return (
  //             <Stack key={`tv${item.itemId}_${index}`} horizontal className={classNames.actaDetailSubItemRow}>
  //               {/* <Stack.Item>{`${item.itemId}/${item.type}`}</Stack.Item> */}
  //               {item.type === LimaObjectTypesEnum.TASK && <Stack.Item>{taskName(item.itemId)}</Stack.Item>}
  //               {item.type === LimaObjectTypesEnum.XCOPY && <Stack.Item>{copyName(item.itemMainCopyId)}</Stack.Item>}
  //               {item.type === LimaObjectTypesEnum.ACTA && <Stack.Item>{actaName(item.itemId, true)}</Stack.Item>}

  //               <Stack.Item className={classNames.time}>{`: ${moment
  //                 .utc(item.timerValue * 1000)
  //                 .format("HH:mm:ss")}`}</Stack.Item>
  //             </Stack>
  //           );
  //         })}
  //       </Stack.Item>
  //     </Stack>
  //   );
  // };

  // const renderTimerMainItem = (partionItem: TimerPartion) => {
  //   return (
  //     <Stack horizontal>
  //       <Stack.Item>{actaName(partionItem.itemMainCopyId)}</Stack.Item>
  //       <Stack.Item>{` : ${moment.utc(partionItem.timerValue * 1000).format("HH:mm:ss")}`}</Stack.Item>
  //     </Stack>
  //   );
  // };

  const renderTimerPartionHistory = (partionItem: ITimerPartionBase) => {
    return (
      <Stack horizontal>
        <Stack.Item>{actaName(partionItem.itemMainCopyId)}</Stack.Item>
        <Stack.Item>{` - [${partionItem.itemId}] (type:${String(partionItem.type)})`}</Stack.Item>
      </Stack>
    );
  };

  return (
    <Stack>
      <Stack.Item>
        <Label>{t("tpmain:timer.timerLbl")}</Label>
        <Toggle
          key="timerToggle"
          onChange={onChangeTimer}
          role="checkbox"
          defaultChecked={limaTimerStore.timerIsActive}
        />
      </Stack.Item>
      <Stack.Item>
        <LimaSimleTextLink linkTo={Routes.LimactaTimersReportRoute} bold={true}>
          {t("tpmain:timer.timerRerpotLink.timerreportlinkLbl")}
        </LimaSimleTextLink>
      </Stack.Item>
      <Stack.Item className={classNames.time}>
        {`${t("tpmain:timer.timeinlimaTxt")} ${moment
          .utc(limaTimerStore.getLimactaSpendTime * 1000)
          .format("HH:mm:ss")}`}
      </Stack.Item>
      {limaTimerStore.currentTimerPartions.length > 0 && (
        <Stack.Item>
          <Label>{t("tpmain:timer.timerSplitlbl")}</Label>
          <List
            items={limaTimerStore.getPartitionPerAct}
            onRenderCell={(partionItem: ITimePerActa) => <TimerPartion partionItem={partionItem} />}
          />
        </Stack.Item>
      )}
      {/* <Stack.Item>historical</Stack.Item> */}
      {limaTimerStore.lastTimerPartions.length > 0 && (
        <Stack.Item>
          <Label onClick={onShowStateTogle} className={classNames.historyLbl}>
            {t("tpmain:timer.timerHistorylbl")}
          </Label>
          {showHistory && <List items={limaTimerStore.lastTimerPartions} onRenderCell={renderTimerPartionHistory} />}
        </Stack.Item>
      )}
    </Stack>
  );
});

export default TimerV4;
