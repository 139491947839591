import React = require("react");
import { FunctionComponent, useCallback, useEffect } from "react";
import { IChoiceGroupOption, ChoiceGroup } from "office-ui-fabric-react";
import limaStore from "../../../../store/limaStore";
import { log } from "missionlog";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { observer } from "mobx-react";

interface IAPISwitchProp {
  hideSwitch: boolean;
}

const APISwitch: FunctionComponent<IAPISwitchProp> = observer((props) => {
  // const urlBaseOptions: IChoiceGroupOption[] = [
  //   // { key: process.env.REACT_APP_SERVER_HOST_1, text: "DEV test", defaultChecked: true },
  //   { key: process.env.REACT_APP_SERVER_HOST_1, text: "DEV test" },
  //   { key: process.env.REACT_APP_SERVER_HOST_2, text: "Localhost 8082" },
  //   { key: process.env.REACT_APP_SERVER_HOST_3, text: "Customer test" },
  // ];
  const servername = process.env.REACT_APP_LIMA_NAME;
  const ips = process.env.REACT_APP_SERVERS_LISTIPS.split(",");
  const names = process.env.REACT_APP_SERVERS_LISTNAMES.split(",");
  const urlBaseOptions: IChoiceGroupOption[] = ips.map((ips_item, ipsIndex) => {
    return { key: ips_item, text: names[ipsIndex] };
  });

  const showOnLogin = process.env.REACT_APP_SERVER_HOST_SHOWONSTART;

  window.console.log(">>>> APISWITCH  ", urlBaseOptions, limaStore.baseURL, servername, showOnLogin);
  // const [selectedURL, setSelectedURL] = React.useState<string | undefined>(limaStore.baseURL);

  useEffect(() => {
    log.info(limaLogTag.APISwitch, "useEffect: mounted set url to ", limaStore.baseURL); //process.env.REACT_APP_SERVER_HOST_1
    limaStore.setLoginTypeIAM(true);
    limaStore.setBaseUrl(limaStore.baseURL);
    //limaStore.setBaseUrl(process.env.REACT_APP_SERVER_HOST_1);
  }, []);

  const onChange = useCallback((_ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    log.info(limaLogTag.APISwitch, `onChange: change to: [${option.key}]`);
    limaStore.setBaseUrl(option.key);
    limaStore.setLoginTypeIAM(true);
    // if (option.key === process.env.REACT_APP_SERVER_HOST_2 || option.key === process.env.REACT_APP_SERVER_HOST_1)
    //   limaStore.setLoginTypeIAM(true);
    // else {
    //   limaStore.setLoginTypeIAM(false);
    // }
    // setSelectedURL(option.key);
  }, []);

  if (props.hideSwitch === true && showOnLogin === "false") {
    return <div key={`aps_${servername}`}>...</div>;
  }
  return (
    <div key={`aps_${servername}`}>
      <ChoiceGroup
        //defaultSelectedKey={limaStore.baseURL}
        options={urlBaseOptions}
        //selectedKey={selectedURL}
        selectedKey={limaStore.baseURL}
        defaultSelectedKey={limaStore.baseURL}
        onChange={onChange}
        label="API"
        required={true}
      />
    </div>
  );
});

export default APISwitch;
