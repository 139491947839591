import * as React from "react";
import { FunctionComponent, useState } from "react";
import { ILimaUserLicences, LimaCompLicActive } from "../../../../types/LimaLicences";
import { ITheme, Icon, Stack, getTheme, mergeStyleSets } from "@fluentui/react";
import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";
import limaStore from "../../../../store/limaStore";
import { useTranslation } from "react-i18next";

interface ILimaFC_CompLicItemProps {
  company: LimaCompLicActive;
  onContinue: (usedlicence: ILimaUserLicences) => void;
}

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

//TODO: Cleanup on styles
const classNames = mergeStyleSets({
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  licenceRow: {
    alignItems: "center",
  },
  licenceRow_handler: {
    minWidht: 24,
  },
  licenceRow_counts: {
    padding: 5,
  },
  licenceRow_concIcon: {
    minWidht: 24,
  },
  licenceRow_info: {
    padding: 5,
    flexGrow: 1,
  },
  licenceRow_action: {
    width: "30%",
  },
});

const LimaFC_CompLicItem: FunctionComponent<ILimaFC_CompLicItemProps> = (props) => {
  const { t } = useTranslation();
  const [showCompanyDetail, setShowCompayDetail] = useState<boolean>(false);
  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------

  const onShowDetail = () => {
    setShowCompayDetail(!showCompanyDetail);
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------
  // const getUsed = (compLicType,compLicIdent)=>{
  //   return props.company.currentLicUsed.filter((item)=>item.licenceType===compLicType && item.licenceid === compLicIdent)
  // }
  const getUserUsing = (compLicIdent) => {
    return props.company.currentUsersActiveList.filter((item) => item.licenceusedid === compLicIdent);
  };

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      {props.company.compLicences.map((compExistLic, index) => {
        // const used = getUsed(compExistLic.licenceType,compExistLic.licenceid);
        const used = getUserUsing(compExistLic.licenceid);
        const urlogged = used.filter((item) => item.userKey === limaStore.userId).length > 0 ? true : false;
        // window.console.log(">>>> TEST ", props.company.yourLicences, compExistLic.licenceid);
        const havAccess =
          props.company.yourLicences.filter(
            (licItem: ILimaUserLicences) => licItem.licenceid === compExistLic.licenceid
          ).length > 0
            ? true
            : false;
        // window.console.log(">>>> logged ", used, urlogged, props.company);

        return (
          <Stack key={`lic_${compExistLic.licenceid}x${index}`}>
            <Stack.Item></Stack.Item>
            <Stack horizontal className={classNames.licenceRow}>
              <Stack.Item className={classNames.licenceRow_handler}>
                <LimaActionButtonV4
                  onClick={() => onShowDetail()}
                  toolTipShow={true}
                  disabled={used.length === 0}
                  toolTipContent={t("tpmain:homecomplic.showlicencedetailBtnLbl")}
                >
                  {used.length > 0 && (
                    <Icon
                      className={classNames.chevron}
                      iconName={showCompanyDetail === true ? "ChevronUp" : "ChevronDown"}
                    />
                  )}
                </LimaActionButtonV4>
              </Stack.Item>
              <Stack.Item className={classNames.licenceRow_concIcon}>{compExistLic.concurent}</Stack.Item>
              <Stack.Item
                className={classNames.licenceRow_counts}
              >{`[${used.length}/${compExistLic.licencecount}]`}</Stack.Item>
              <Stack.Item className={classNames.licenceRow_info}>{compExistLic.licenceType}</Stack.Item>

              <Stack.Item className={classNames.licenceRow_action}>
                {havAccess && (
                  <LimaActionButtonV4
                    onClick={() =>
                      props.onContinue({
                        companyKey: props.company.companyKey,
                        licenceid: compExistLic.licenceid,
                        licenceType: compExistLic.licenceType,
                      })
                    }
                    toolTipShow={true}
                    disabled={!(used.length < compExistLic.licencecount || urlogged === true)}
                  >
                    {urlogged === true
                      ? t("tpmain:homecomplic.continueAlreadyLoggedBtnLbl")
                      : t("tpmain:homecomplic.continueBtnLbl")}
                  </LimaActionButtonV4>
                )}
              </Stack.Item>
            </Stack>
            {showCompanyDetail === true && (
              <Stack.Item>
                <Stack>
                  {used.map((licUser) => (
                    <Stack.Item key={licUser.userKey}>
                      <Stack horizontal>
                        <Stack.Item style={{ width: "10%" }}>{licUser.licenceType}</Stack.Item>
                        <Stack.Item style={{ width: "40%", textAlign: "center" }}>{licUser.userName}</Stack.Item>
                        <Stack.Item style={{ width: "50%" }}>{licUser.userEmail}</Stack.Item>
                      </Stack>
                    </Stack.Item>
                  ))}
                </Stack>
              </Stack.Item>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
export default LimaFC_CompLicItem;

//----------------------
//   REACT QUERY
//----------------------
