import {
  FocusZone,
  FocusZoneDirection,
  getFocusStyle,
  getTheme,
  ITheme,
  List,
  mergeStyleSets,
  Pivot,
  PivotItem,
  TextField,
} from "@fluentui/react";
import { log } from "missionlog";
import { observer } from "mobx-react";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { actaListOK } from "../../../api/schema/getActaList";
import { onFileOpenHandlerWrapper } from "../../../limaCommon/limaFileOpen";
import { limaLogTag } from "../../../limaCommon/limaLog";
import limaActasStore from "../../../store/limaActasStore";
import limaBreadCrumbStore from "../../../store/limaBreadCrumbStore";
import limaStore from "../../../store/limaStore";
import limaTimerStore from "../../../store/limaTimerStore";
import { LimaObjectTypesEnum } from "../../../types/BASE_Enums";
// import { LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { XCOPY_TYPE } from "../../../types/Task";
// import limaStore from "../../../store/limaStore";
import Routes from "../../Menu/routes";
import ActaListActions from "./components/ActaListActions";
import LimaTagsMiniListCmp from "../../CommonComponents/Tags/LimaTagsMiniListCmp";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IActaListV4Props {
  // actaList: actaListOK[];
  countLimit?: number;
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 42,
      padding: 2,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  // itemImage: {
  //   flexShrink: 0,
  // },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemContentOwner: {
    cursor: "pointer",
  },
  itemTags: {},
  itemActions: {
    minWidth: 96,
  },
  itemDescr: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary, //"#8E8E93",
  },
  itemName: [
    fonts.medium,
    {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#333333",
    },
  ],
  itemNameOwner: [{ fontWeight: 700 }],
  // itemIndex: {
  //   fontSize: fonts.small.fontSize,
  //   color: palette.neutralTertiary,
  //   marginBottom: 10,
  // },
});

type observedFiles = {
  // actaItems: actaListOK[];
  filter: string;
  filterOnly: PivotFilter;
  filterCountLimit?: number;
};

enum PivotFilter {
  ALL = "all",
  FAVORITONLY = "favorit",
  SHAREDONLY = "shared",
}

const ActaListV4: FunctionComponent<IActaListV4Props> = (props: IActaListV4Props) => {
  const [filter, setFilter] = React.useState("");
  const [filterCount, setFilterCount] = React.useState<number>(null);
  const [pivotFilter, setPivotFilter] = React.useState<PivotFilter>(PivotFilter.ALL);
  const [selectedKey, setSelectedKey] = React.useState("all");
  const history = useHistory();
  const { t } = useTranslation();

  //const resultCountText = items.length === actaList.length ? "" : ` (${items.length} of ${actaList.length} shown)`;

  //-------------------
  // Reactions
  //---------------------
  useEffect(() => {
    void limaActasStore.checkLastRead();
  }, []);

  useEffect(() => {
    log.info(limaLogTag.ActaListV4, `UEF: props.countLimit change`, props.countLimit);
  }, [props.countLimit]);

  //const resultCountText = useMemo(() => {
  const resultCountText = () => {
    if (filterCount === null) return "";
    let maxCount = limaActasStore.getActasCount;
    if (pivotFilter === PivotFilter.FAVORITONLY) {
      maxCount = limaActasStore.getFavoritActasCount;
    } else if (pivotFilter === PivotFilter.SHAREDONLY) {
      maxCount = limaActasStore.getSharedActasCount;
    }
    if (filterCount === maxCount) return "";
    return t("tpfile:homeV4.openFiles.valuesLbl", { filtercount: String(filterCount), maxcount: String(maxCount) }); // (${} of ${} shown)`;
  };
  //}, [filterCount, pivotFilter]);

  const FileList = ({ filter, filterOnly, filterCountLimit }: observedFiles) => {
    return <List items={inner_filter(filter, filterOnly, filterCountLimit)} onRenderCell={onRenderFileListCell} />;
  };

  //-------------------
  //  Handlers
  //-------------------
  const onFilterChanged = (_: never, text: string): void => {
    setFilter(text);
    // setItems(actaList.filter((item: actaListOK) => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0));
  };

  const onFileClicked = (fileItem: actaListOK): void => {
    log.info(limaLogTag.ActaListV4, `onClick: clicked -> now`, fileItem);

    limaStore.openFileNewWindowConfirmFnc = () => {
      log.info(limaLogTag.ActaListV4, `onClick: clicked -> Open in new window`, fileItem);
      if (fileItem.isOwner) {
        void onFileOpenHandlerWrapper(
          fileItem.id,
          fileItem.name,
          fileItem.mainXcopy,
          null,
          null,
          XCOPY_TYPE.MAINXCOPY,
          false,
          false,
          true,
          true
        );

        log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileItem.id}`);

        limaStore.setOpenFileNewWindow(false);
      }
    };
    limaStore.openFileCurrentWindowConfirmFnc = () => {
      log.info(limaLogTag.ActaListV4, `onClick: clicked -> Open in current window`, fileItem);
      if (fileItem.isOwner) {
        void onFileOpenHandlerWrapper(
          fileItem.id,
          fileItem.name,
          fileItem.mainXcopy,
          null,
          null,
          XCOPY_TYPE.MAINXCOPY,
          false,
          true,
          false,
          false
        ).then(() => {
          // limaStore.setMenuFile(
          //   props.xCopy2ActaItem.xcopy._key,
          //   `copy ${props.xCopy2ActaItem.acta.actaname}`,
          //   props.xCopy2ActaItem.xcopy.xcopytype
          // );
          log.info(limaLogTag.ActaListV4, `Set active timer to actakez from file ${fileItem.id}`);
          limaTimerStore.setCurrentActivePartion({
            itemId: fileItem.id,
            itemMainCopyId: fileItem.id,
            type: LimaObjectTypesEnum.ACTA,
            userKey: limaStore.userId,
            _key: null,
          });
          limaStore.setOpenFileNewWindow(false);
        });
        //limaStore.setMenuFile(item.id, item.name, XCOPY_TYPE.MAINXCOPY);
      }
    };

    limaStore.setOpenFileNewWindow(true);
  };

  //--------------------
  // OTHER Functions
  //-------------------

  const inner_filter = (filter: string, filterOnly: PivotFilter, filterCountLimit?: number): actaListOK[] => {
    log.info(limaLogTag.ActaListV4, `linner_filter start`, limaActasStore.actaList);
    let items = limaActasStore.actaList.filter((item: actaListOK) => {
      if (filterOnly === PivotFilter.FAVORITONLY && item.favorite !== true) {
        return false;
      }
      if (filterOnly === PivotFilter.SHAREDONLY && item.isOwner === true) {
        return false;
      }
      if (item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) return true;
      else return false;
    });

    if (filterCountLimit !== undefined && items.length > filterCountLimit) {
      items = items.slice(0, filterCountLimit);
    }

    if (filterCount !== items.length) {
      setFilterCount(items.length);
    }

    return items;
  };

  //----------------------
  // Renderes
  //------------------
  const onRenderFileListCell = (item: actaListOK): JSX.Element => {
    // log.info(limaLogTag.ActaListV4, `onRenderCell>>> ${String(item.favorite)}`, item);
    return (
      <div className={classNames.itemCell} data-is-focusable={true}>
        {/* <Image className={classNames.itemImage} src={item.thumbnail} width={50} height={50} imageFit={ImageFit.cover} /> */}
        <div
          className={`${classNames.itemContent} ${item.isOwner === true ? classNames.itemContentOwner : ""}`}
          onClick={() => {
            onFileClicked(item);
          }}
        >
          <div className={classNames.itemName && item.isOwner ? classNames.itemNameOwner : ""}>{item.name}</div>
          <div className={classNames.itemDescr}>
            {"Modified "}
            <Moment fromNow>{item.lastchange}</Moment>
          </div>
        </div>
        <div className={classNames.itemTags}>
          <LimaTagsMiniListCmp tagsIds={item.tags} />
        </div>
        <div className={classNames.itemActions}>
          <ActaListActions
            onDetailClickClb={() => {
              log.info(limaLogTag.ActaListV4, `onDetailClick: clicked -> now`, item);
              limaBreadCrumbStore.addNewBreadCrumbExt({
                key: `file_${item.id}`,
                lima_itemid: item.id,
                text: item.name,
                // data: `${Routes.ActaDetailRoute_base}${item.id}/${OPENEDFILETYPE_ENUM.MAIN_COPY}`,
                data: `${Routes.ActaDetailRoute_base}${item.id}/${XCOPY_TYPE.MAINXCOPY}`,
                lima_Actions: [],
              });
              // history.push(`${Routes.ActaDetailRoute_base}${item.id}/${OPENEDFILETYPE_ENUM.MAIN_COPY}`);
              history.push(`${Routes.ActaDetailRoute_base}${item.id}/${XCOPY_TYPE.MAINXCOPY}`);
            }}
            isFavorite={item.favorite}
            onFavoriteClickClb={() => {
              log.info(limaLogTag.ActaListV4, `onFavoriteClick: clicked -> now`, item);
              limaActasStore.setFavorite(item, !item.favorite);
            }}
            onRemoveClickClb={() => {
              log.info(limaLogTag.ActaListV4, `onFavoriteClick: clicked -> now`, item);
              void limaActasStore.removeFromList(item);
            }}
          />
        </div>
      </div>
    );
  };

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      if (item.props.itemKey === "all") {
        setPivotFilter(PivotFilter.ALL);
      }
      if (item.props.itemKey === "favorit") {
        setPivotFilter(PivotFilter.FAVORITONLY);
      }
      if (item.props.itemKey === "shared") {
        setPivotFilter(PivotFilter.SHAREDONLY);
      }
      setSelectedKey(item.props.itemKey);
    }
  };

  return (
    <>
      <Pivot
        aria-label="Separately Rendered Content Pivot Example"
        selectedKey={selectedKey}
        // eslint-disable-next-line react/jsx-no-bind
        onLinkClick={handleLinkClick}
        headersOnly={true}
        // getTabId={getTabId}
      >
        <PivotItem
          headerText={t("tpfile:homeV4.openFiles.allPivotLbl")}
          itemCount={limaActasStore.getActasCount}
          itemKey="all"
        />
        <PivotItem
          headerText={t("tpfile:homeV4.openFiles.fovoritePivotLbl")}
          itemCount={limaActasStore.getFavoritActasCount}
          itemKey="favorit"
        />
        <PivotItem
          headerText={t("tpfile:homeV4.openFiles.sharePivotLbl")}
          itemCount={limaActasStore.getSharedActasCount}
          itemKey="shared"
        />
      </Pivot>
      <FocusZone direction={FocusZoneDirection.vertical}>
        <TextField
          label={`${t("tpfile:homeV4.openFiles.filterbynameLbl")} ${resultCountText()}`}
          // label={"Filter by name"}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onFilterChanged}
        />
        <FileList filter={filter} filterOnly={pivotFilter} filterCountLimit={props.countLimit} />
      </FocusZone>
    </>
  );
};

export default observer(ActaListV4);
