/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SentanceAnalyseData {
  sentace: string;
  found: ANALYSED_TEXT_FOUND;
  paraId: string;
  otherData: SentanceAnalyseData_TIME | SentanceAnalyseData_COMMITMENT | null;
}

export enum ANALYSED_TEXT_FOUND {
  TIME_FOR_TASK = "TIME_FOR_TASK",
  COMMITMENT = "COMMITMENT",
  ISSUE = "ISSUE",
}

export interface SentanceAnalyseData_TIME {
  date: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSentanceAnalyseData_TIME(obj: any): obj is SentanceAnalyseData_TIME {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return obj.date !== undefined;
}

export interface SentanceAnalyseData_COMMITMENT {
  reponsible: string;
  missingsubject?: string;
  missingobj?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSentanceAnalyseData_COMMITMENT_resp(obj: any): obj is SentanceAnalyseData_COMMITMENT {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return obj.reponsible !== undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSentanceAnalyseData_COMMITMENT_withmissingSubj(obj: any): obj is SentanceAnalyseData_COMMITMENT {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return obj.missingsubject !== undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSentanceAnalyseData_COMMITMENT_withmissingObj(obj: any): obj is SentanceAnalyseData_COMMITMENT {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return obj.missingobj !== undefined;
}

export interface SentanceAnalyseData_ISSUE {
  issueType: SENTACE_ISSUE_TYPE;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSentanceAnalyseData_ISSUE(obj: any): obj is SentanceAnalyseData_ISSUE {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return obj.issueType !== undefined;
}

export enum SENTACE_ISSUE_TYPE {
  SENTANCE_SUB_IS_MISSING = "SENTANCE_SUB_IS_MISSING",
  SENTANCE_SUB_IS_PRON_DET = "SENTANCE_SUB_IS_PRON_DET",
  SENTANCE_OBJ_IS_MISSING = "SENTANCE_OBJ_IS_MISSING",
  SENTANCE_OBJ_IS_PRON_DET = "SENTANCE_OBJ_IS_PRON_DET",
  COMMITMENT_SUBJ_IS_MISSING = "COMMITMENT_SUBJ_IS_MISSING",
}
