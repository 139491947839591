import { log } from "missionlog";
import * as React from "react";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { LIMA_LICENCE_TYPE, LimaCompLicActive, LimaCompLicUsers } from "../../../../types/LimaLicences";
import {
  limaQuery_delUserLicence,
  limaQuery_getComLicUState,
  limaQuery_getUsersLicences,
  limaQuery_setUserLicence,
} from "../../../../api/calls/limaLicenceCalls";
import { LimaLoadingComponnet } from "../../../../limaComponents/LimaLoadingComponnet";
import { useTranslation } from "react-i18next";
import UserLicenceItem from "./components/UserLicenceItem";
import { Dropdown, IDropdownOption, Stack, Text } from "@fluentui/react";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../limaComponents/LimaNotice";
import limaStore from "../../../../store/limaStore";
import { CALLLOGINTYPE, limactaCallLoginLogout } from "../../../../limaCommon/limaUser";
import { fetchCompanies } from "../../../../api/calls/LimaCompaniesCalls";
import { ILimaCompany } from "../../../../types/LimaCompany";
import LimaActionButtonV4 from "../../Components/LimaActionButtonV4";
import limaUsersStore from "../../../../store/limaUsersStore";

// interface IUserLicencesPageProps {}

type CompanyIdentProps = {
  companies: LimaCompLicActive[];
};

const UserLicencesPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [companyKey, setCompanyKey] = useState<string>(limaStore.userCompanyKey);
  const [usersLicences, setUsersLicences] = useState<LimaCompLicUsers[]>([]); //users in licence
  const [companyActiveUsedLicences, setCompanyActiveUsedLicences] = useState<LimaCompLicActive[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [comapniesOptions, setCompaniesOptions] = useState<IDropdownOption[]>([]); //for switching companies
  const [companiesList, setCompaniesList] = useState<ILimaCompany[]>([]);
  const [compLicOptions, setCompLicOptions] = useState<IDropdownOption[]>([]); //For dropdown

  const addNewDisabled: boolean = useMemo(() => {
    if (companyKey === undefined || companyKey === null || companyKey === "") {
      return true;
    }

    if (usersLicences.filter((item) => item.userKey === null).length > 0) {
      return true;
    }
    return false;
  }, [usersLicences]);

  //Geting list of userlicences
  const { isLoading, error, refetch } = useQuery(
    ["licences", companyKey],
    () => limaQuery_getUsersLicences(companyKey),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        log.error(limaLogTag.UserLicencesPage, "useQuery get fetchUsersAndLicences ", err);
      },
      onSuccess: (data) => {
        log.info(limaLogTag.UserLicencesPage, "useQuery get fetchUsersAndLicences ", data);
        setUsersLicences(data);
      },
    }
  );

  //Geting list of used licences
  const {
    isLoading: isLoadingCurrentUsage,
    error: errorCurrentUsage,
    refetch: refetchCurrentUsage,
  } = useQuery(["licencesUsage", limaStore.userId, companyKey], () => limaQuery_getComLicUState(companyKey), {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (err) => {
      log.error(limaLogTag.UserLicencesPage, "useQuery get fetchUsersAndLicences ", err);
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserLicencesPage, "useQuery get fetchUsersAndLicences ", data);
      setCompanyActiveUsedLicences([...data]);
    },
  });

  //Geting list of companies
  const {
    isLoading: isLoadingCompanies,
    error: errorCompanies,
    refetch: refetchCompanies,
  } = useQuery(["compsList"], () => fetchCompanies(), {
    enabled: false,
    onSuccess: (data) => {
      log.info(limaLogTag.UserLicencesPage, "useQuery get companies ", data);
      // setMessages(data);
      setCompaniesList(data.map((item) => item.company));
      setCompaniesOptions(
        data.map((item) => {
          return { key: item.company._key, text: item.company.companyname };
        })
      );
    },
  });
  //---------------------
  //    REACTIONS
  //---------------------

  useEffect(() => {
    void limaUsersStore.checkLastRead();

    void refetch();
    log.info(limaLogTag.UserLicencesPage, "UEF get refetching users usage ");
    void refetchCurrentUsage();
    void refetchCompanies().then(() => {
      log.info(limaLogTag.UserLicencesPage, "UEF refetchCompanies finish");
    });
    // }
  }, []);

  useEffect(() => {
    void refetch();
    log.info(limaLogTag.UserLicencesPage, "UEF get refetching users usage for specific companyKey ");
    void refetchCurrentUsage();
    updateCompanyLicOptions();
  }, [companyKey]);

  useEffect(() => {
    if (companyKey !== undefined && companyKey !== null && companyKey !== "") {
      log.info(limaLogTag.UserLicencesPage, "UEF on companiesList set lic options for company key", companyKey);
      updateCompanyLicOptions();
    } else {
      log.warn(limaLogTag.UserLicencesPage, "UEF on companiesList don thave companyKey ");
    }
  }, [companiesList]);

  //---------------------
  //    HANDLERS
  //---------------------

  const onLicenceTypeUpdate = (updateUser: LimaCompLicUsers) => {
    if (updateUser.userKey === null) {
      log.warn(limaLogTag.UserLicencesPage, "update without user key not sending");
    } else {
      limaQuery_setUserLicence(updateUser, companyKey)
        .then((updatedUser) => {
          log.info(limaLogTag.UserLicencesPage, "stored licence type for user before update ", usersLicences);
          const updatedLicencesList = usersLicences.map((licenceItem) =>
            licenceItem.company2userKey === null ? updatedUser : licenceItem
          );
          log.info(limaLogTag.UserLicencesPage, "stored licence type for user", updatedLicencesList);
          setUsersLicences([...updatedLicencesList]);
          if (isError === true) setIsError(false);
        })
        .catch(() => {
          log.error(limaLogTag.UserLicencesPage, "error stored licence type for user");
          if (isError === false) setIsError(true);
        });
    }
  };

  const onUserLicenceLogout = (updateUser: LimaCompLicUsers) => {
    log.info(
      limaLogTag.UserLicencesPage,
      `logout user licence type for user userID: ${updateUser.userKey} licenceID:${updateUser.licenceusedid}`
    );
    limactaCallLoginLogout(CALLLOGINTYPE.LOGOUT, companyKey, updateUser.licenceusedid, updateUser.userKey)
      .then(() => {
        log.info(limaLogTag.UserLicencesPage, "logout licence type for user");
        void refetchCurrentUsage();
        if (isError === true) setIsError(false);
      })
      .catch(() => {
        log.error(limaLogTag.UserLicencesPage, "error logout licence type for user");
        if (isError === false) setIsError(true);
      });
  };

  const onCompanyChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<unknown>) => {
    if (option !== undefined && typeof option.key === "string") {
      const company = companiesList.filter((compItem) => compItem._key === option.key);
      if (company.length > 0) {
        setCompanyKey(company[0]._key);
      } else {
        log.error(limaLogTag.UserLicencesPage, "onCompanyChange unable to find company");
      }
    }
  };

  const onUserLicenceRemove = (remUser: LimaCompLicUsers) => {
    limaQuery_delUserLicence(remUser, companyKey)
      .then((userResult) => {
        log.info(limaLogTag.UserLicencesPage, "stored new user ");
        setUsersLicences([
          ...usersLicences.filter((usersLic) => usersLic.company2userKey !== userResult.company2userKey),
        ]);
        if (isError === true) setIsError(false);
      })
      .catch(() => {
        log.error(limaLogTag.UserLicencesPage, "stored new user ");
        if (isError === false) setIsError(true);
      });
  };

  const onUserLicenceAdd = (newUser?: LimaCompLicUsers) => {
    if (newUser === undefined) {
      newUser = {
        company2userKey: null,
        dtimestamp: null,
        licenceType: LIMA_LICENCE_TYPE.NONE,
        licenceusedid: "",
        userKey: null,
        userEmail: "",
        userName: "",
      };
    }
    setUsersLicences([...usersLicences, newUser]);
    // limaQuery_setUserLicence(newUser, companyKey)
    //   .then((userResult) => {
    //     log.info(limaLogTag.UserLicencesPage, "stored new user ");
    //     setUsersLicences([...usersLicences, userResult]);
    //     if (isError === true) setIsError(false);
    //   })
    //   .catch(() => {
    //     log.error(limaLogTag.UserLicencesPage, "stored new user ");
    //     if (isError === false) setIsError(true);
    //   });
  };

  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  const updateCompanyLicOptions = (): void => {
    log.warn(limaLogTag.UserLicencesPage, "updateCompanyLicOptions update options start");
    const compFilter = companiesList.filter((company) => company._key === companyKey);
    log.warn(limaLogTag.UserLicencesPage, "updateCompanyLicOptions update found ", compFilter);
    if (compFilter !== undefined && compFilter.length > 0) {
      setCompLicOptions([
        {
          key: "",
          text: "not set",
          data: { type: LIMA_LICENCE_TYPE.NONE },
        },
        ...compFilter[0].complicences.map((companyFiltLic) => {
          return {
            key: companyFiltLic.licenceid,
            text: `${companyFiltLic.licenceType} [${companyFiltLic.licencecount}] ${
              companyFiltLic.concurent === true ? "conc" : "peruser"
            }`,
            data: { type: companyFiltLic.licenceType },
          };
        }),
      ]);
      log.warn(limaLogTag.UserLicencesPage, "updateCompanyLicOptions set ");
    }
  };
  //---------------------
  //    RENDERS
  //---------------------

  if (isLoading === true || isLoadingCurrentUsage === true || isLoadingCompanies === true) {
    return LimaLoadingComponnet(3, "Loading licences list");
  }

  if (error || errorCurrentUsage === true || errorCompanies === true) {
    log.error(limaLogTag.UserLicencesPage, "loaded error: ", error);
    return (
      <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>
        {t("tpmain:error.errorLoadingDataLbl")}: {error}
      </LimaNotice>
    );
  }

  const CompanyIdent: React.FunctionComponent<CompanyIdentProps> = ({ companies }): JSX.Element => {
    if (companies.length === 1) {
      return <span title={companies[0].companyKey}>{companies[0].companyName}</span>;
    }

    return <></>;
  };

  return (
    <Stack style={{ padding: 5 }}>
      <Stack.Item>
        <Text variant={"large"}>{t("tpuser:tools.licences.labelLbl")}</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant={"small"}>
          Update licences for each of company user{" "}
          <b>
            <CompanyIdent companies={companyActiveUsedLicences} />
          </b>
        </Text>
        <br />
        <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>Only logout working and need to reload</LimaNotice>
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          label={"company:"}
          disabled={companyKey === null || companyKey === ""}
          defaultSelectedKey={companyKey}
          options={comapniesOptions}
          onChange={onCompanyChange}
        />
      </Stack.Item>
      {isError === true && (
        <Stack.Item>
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.ERROR}>{t("tpmain:error.errorStoringDataLbl")}</LimaNotice>
        </Stack.Item>
      )}
      <Stack.Item>
        <LimaActionButtonV4 onClick={() => onUserLicenceAdd()} toolTipShow={false} disabled={addNewDisabled}>
          {t("tpuser:tools.licences.addUserBtn")}
        </LimaActionButtonV4>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          {usersLicences &&
            usersLicences.map((userLicenceItem: LimaCompLicUsers, index: number) => {
              const isLogged =
                companyActiveUsedLicences.length === 0
                  ? false
                  : companyActiveUsedLicences[0].currentUsersActiveList &&
                    companyActiveUsedLicences[0].currentUsersActiveList.filter(
                      (innerItem: LimaCompLicUsers) => innerItem.userKey === userLicenceItem.userKey
                    ).length > 0
                  ? true
                  : false;
              return (
                <Stack.Item style={{ paddingTop: 5 }} key={`lic${index}`}>
                  <UserLicenceItem
                    user={userLicenceItem}
                    onUpdate={onLicenceTypeUpdate}
                    isLogged={isLogged}
                    onLoggout={onUserLicenceLogout}
                    limaLicenceTypeOptions={compLicOptions}
                    onRemove={onUserLicenceRemove}
                  />
                </Stack.Item>
              );
            })}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
export default UserLicencesPage;

//----------------------
//   REACT QUERY
//----------------------
