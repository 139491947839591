import * as React from "react";
import { FunctionComponent, useState } from "react";
import { getWordHaveSomeText, getWordIsSaved } from "../../../limaCommon/limaWordInfo";
import { Stack } from "@fluentui/react";
import LimaActionButtonV4 from "../Components/LimaActionButtonV4";

interface IDataPageProps {}

const DataPage: FunctionComponent<IDataPageProps> = () => {
  const [isSomeTextInDoc, setIsSomeTextInDoc] = useState<boolean | null>(null);
  const [isDocSaved, setIsDocSaved] = useState<boolean | null>(null);

  //---------------------
  //    REACTIONS
  //---------------------

  //---------------------
  //    HANDLERS
  //---------------------
  const checkSomeTextInDoc = () => {
    getWordHaveSomeText().then((someTextInWord: boolean) => {
      if (someTextInWord !== isSomeTextInDoc) setIsSomeTextInDoc(someTextInWord);
    });
  };

  const checkDocumentIsSaved = () => {
    getWordIsSaved().then((docSaved: boolean) => {
      if (docSaved !== isDocSaved) setIsDocSaved(docSaved);
    });
  };
  //---------------------
  //    OBSERVERS
  //---------------------

  //---------------------
  //    FUNCTIONS
  //---------------------

  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      <Stack.Item>
        Is does have wordk something to say: {`${String(isSomeTextInDoc)}`}{" "}
        <LimaActionButtonV4 onClick={checkSomeTextInDoc} toolTipShow={false}>
          RECHECK
        </LimaActionButtonV4>
      </Stack.Item>
      <Stack.Item>
        Is document saved: {`${String(isDocSaved)}`}{" "}
        <LimaActionButtonV4 onClick={checkDocumentIsSaved} toolTipShow={false}>
          RECHECK
        </LimaActionButtonV4>
      </Stack.Item>
    </Stack>
  );
};
export default DataPage;

//----------------------
//   REACT QUERY
//----------------------
