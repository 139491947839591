/**
 * Component shows single file item Copy
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
import { log } from "missionlog";
import { IStackTokens, Stack } from "office-ui-fabric-react";
import { FunctionComponent } from "react";

import moment = require("moment");
import { Xcopy2Acta } from "../../../../types/Xcopy";
import limaStore from "../../../../store/limaStore";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaLoadeStore, { LoaderItemEnd } from "../../../../store/limaLoaderStore";
import { compareXcopy2XcopyByActaKey } from "../../../../limaCommon/limaInsertDoc";
import WorkingCopiesListItemNameV4 from "./WorkingCopiesListItemNameV4";
import { LimaActionIconCompareCopy } from "../../../../limaComponents/LimaActionIcons/LimaActionIconCompareCopy";
import { LimaActionIconFileOpen } from "../../../../limaComponents/LimaActionIcons/LimaActionIconFileOpen";
import { classNamesCommon } from "../../../../limaComponents/LimaComponentsCommonStyles";
import limaBreadCrumbStore from "../../../../store/limaBreadCrumbStore";
import Routes from "../../../Menu/routes";
import { useHistory } from "react-router";
import { onFileOpenHandlerWrapper } from "../../../../limaCommon/limaFileOpen";
import limaTimerStore from "../../../../store/limaTimerStore";
import { LimaObjectTypesEnum } from "../../../../types/BASE_Enums";
import { LimaActionIconItemDetail } from "../../../../limaComponents/LimaActionIcons/LimaActionIconItemDetail";
// import { XCOPY_TYPE } from "../../../../types/Task";

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 2,
};

type IWorkingCopyListItemV4Prop = {
  xCopy2ActaItem: Xcopy2Acta;
  isActaOwner: boolean;
};

const WorkingCopyListItemV4: FunctionComponent<IWorkingCopyListItemV4Prop> = (props) => {
  const history = useHistory();

  //-------------------
  // Handlers
  //-------------------

  const onXcopyOpen = () => {
    log.info(limaLogTag.WorkingCopyListItemV4, "OpenFileComponent: on click ", props.xCopy2ActaItem);
    limaStore.setOpenFileNewWindow(true);

    limaStore.openFileNewWindowConfirmFnc = () => {
      log.info(limaLogTag.WorkingCopyListItemV4, `onClick: clicked -> open new window - xcopy`, props.xCopy2ActaItem);
      onFileOpenHandlerWrapper(
        props.xCopy2ActaItem.acta._key,
        props.xCopy2ActaItem.acta.actaname,
        props.xCopy2ActaItem.xcopy._key,
        null,
        null,
        props.xCopy2ActaItem.xcopy.xcopytype,
        false,
        false,
        true,
        true
      );

      limaStore.setOpenFileNewWindow(false);
    };

    limaStore.openFileCurrentWindowConfirmFnc = () => {
      log.info(
        limaLogTag.WorkingCopyListItemV4,
        `onClick: clicked -> open current window - xcopy`,
        props.xCopy2ActaItem
      );

      onFileOpenHandlerWrapper(
        props.xCopy2ActaItem.acta._key,
        props.xCopy2ActaItem.acta.actaname,
        props.xCopy2ActaItem.xcopy._key,
        null,
        null,
        props.xCopy2ActaItem.xcopy.xcopytype,
        false,
        true,
        false,
        false
      )
        .then(() => {
          limaTimerStore.setCurrentActivePartion({
            itemId: props.xCopy2ActaItem.xcopy._key,
            type: LimaObjectTypesEnum.XCOPY,
            itemMainCopyId: props.xCopy2ActaItem.acta._key,
            userKey: limaStore.userId,
            _key: null,
          });

          // limaStore.setMenuFile(
          //   props.xCopy2ActaItem.xcopy._key,
          //   `copy ${props.xCopy2ActaItem.acta.actaname}`,
          //   props.xCopy2ActaItem.xcopy.xcopytype
          // );

          limaBreadCrumbStore.addNewBreadCrumbExt({
            key: `copy_${props.xCopy2ActaItem.xcopy._key}`,
            lima_itemid: props.xCopy2ActaItem.xcopy._key,
            text: `copy ${props.xCopy2ActaItem.acta.actaname}`,
            // data: `${Routes.ActaDetailRoute_base}${xCopy2ActaItem.xcopy._key}/${OPENEDFILETYPE_ENUM.COPY}`,
            data: `${Routes.ActaDetailRoute_base}${props.xCopy2ActaItem.xcopy._key}/${props.xCopy2ActaItem.xcopy.xcopytype}`,
            lima_Actions: [],
          });

          history.push(
            `${Routes.ActaDetailRoute_base}${props.xCopy2ActaItem.xcopy._key}/${props.xCopy2ActaItem.xcopy.xcopytype}`
          );
        })
        .catch((e) => {
          log.error(limaLogTag.WorkingCopyListItemV4, ` Some error open file`, e);
        });
      //limaStore.setMenuFile(item.id, item.name, XCOPY_TYPE.MAINXCOPY);
      // log.info(limaLogTag.ActaListV4, `Set active timer to actakez from task ${fileItem.id}`);

      limaStore.setOpenFileNewWindow(false);
    };

    // onFileOpenHandlerWrapper(
    //   xCopy2ActaItem.acta._key,
    //   xCopy2ActaItem.acta.actaname,
    //   xCopy2ActaItem.xcopy._key,
    //   null,
    //   null,
    //   xCopy2ActaItem.xcopy.xcopytype,
    //   false,
    //   true
    // )
    //   .then(() => {
    //   })
    //   .catch((e) => {
    //   });

    // limaBreadCrumbStore.addNewBreadCrumbExt({
    //   key: `copy_${xCopy2ActaItem.xcopy._key}`,
    //   lima_itemid: xCopy2ActaItem.xcopy._key,
    //   text: `copy ${xCopy2ActaItem.acta.actaname}`,
    //   // data: `${Routes.ActaDetailRoute_base}${xCopy2ActaItem.xcopy._key}/${OPENEDFILETYPE_ENUM.COPY}`,
    //   data: `${Routes.ActaDetailRoute_base}${xCopy2ActaItem.xcopy._key}/${xCopy2ActaItem.xcopy.xcopytype}`,
    //   lima_Actions: [],
    // });

    // log.info(limaLogTag.WorkingCopyListItemV4, `Set active timer to actak from task ${xCopy2ActaItem.acta._key}`);
  };

  const onXcopyCompare = () => {
    // eslint-disable-next-line react/prop-types
    log.info(limaLogTag.WorkingCopyListItemV4, "onXcopyCompare: on click ");
    const loaderID = limaLoadeStore.add(`Call to compare acta and xcopy`);
    compareXcopy2XcopyByActaKey(
      props.xCopy2ActaItem.acta._key,
      props.xCopy2ActaItem.xcopy._key,
      limaStore.userId,
      loaderID
    )
      .then()
      .finally(() => {
        limaLoadeStore.remove(loaderID.k, LoaderItemEnd.UNKNOWN);
        // limaLoadeStore.remove(loaderID.k);
      });
  };

  const onXcopyDetail = () => {
    log.info(limaLogTag.ActaListV4, `onXcopyDetail: clicked -> now`);
    limaBreadCrumbStore.addNewBreadCrumbExt({
      key: `copy_${props.xCopy2ActaItem.xcopy._key}`,
      lima_itemid: props.xCopy2ActaItem.xcopy._key,
      text: `copy ${props.xCopy2ActaItem.acta.actaname}`,
      // data: `${Routes.ActaDetailRoute_base}${xCopy2ActaItem.xcopy._key}/${OPENEDFILETYPE_ENUM.COPY}`,
      data: `${Routes.ActaDetailRoute_base}${props.xCopy2ActaItem.xcopy._key}/${props.xCopy2ActaItem.xcopy.xcopytype}`,
      lima_Actions: [],
    });

    history.push(
      `${Routes.ActaDetailRoute_base}${props.xCopy2ActaItem.xcopy._key}/${props.xCopy2ActaItem.xcopy.xcopytype}`
    );
  };

  //-------------------
  // Renders
  //-------------------

  const XcopyName = `copy of : ${props.xCopy2ActaItem.acta.actaname} [${
    props.xCopy2ActaItem.xcopy.xcopyIdent && props.xCopy2ActaItem.xcopy.xcopyIdent
  } ]`;

  return (
    <Stack horizontal tokens={stackTokens} className={classNamesCommon.LimaFileLists_itemWrapper}>
      <Stack.Item className={classNamesCommon.LimaFileLists_itemIdent}>
        <WorkingCopiesListItemNameV4
          xcopytype={props.xCopy2ActaItem.xcopy.xcopytype}
          xcopyName={XcopyName}
          xcopyCreateTimeStamp={moment(props.xCopy2ActaItem.xcopy.cTimeStamp).format("Do MMMM YYYY [at] h:mma")}
        />
      </Stack.Item>

      <Stack.Item className={classNamesCommon.LimaFileLists_itemActions}>
        {props.isActaOwner && <LimaActionIconCompareCopy onClick={onXcopyCompare} />}

        <LimaActionIconFileOpen onClick={onXcopyOpen} />
        <LimaActionIconItemDetail onClick={onXcopyDetail} />
      </Stack.Item>
    </Stack>
  );
};
export default WorkingCopyListItemV4;
