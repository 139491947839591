import { FC } from "react";
import { Controller } from "react-hook-form";
// import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { HookFormProps } from "./HookFormProps";
import { ITextFieldProps, TextField, Label, Stack } from "@fluentui/react";
import { LimaNotice } from "./LimaNotice";
import { useTranslation } from "react-i18next";

type LimaControledTextFieldType = {
  limaLabel?: string;
  horizontal?: boolean;
};

export const LimaControledTextField: FC<HookFormProps & ITextFieldProps & LimaControledTextFieldType> = (
  props: HookFormProps & ITextFieldProps & LimaControledTextFieldType
) => {
  const newLocal: string = typeof props.defaultValue === "string" ? props.defaultValue : "";
  /*console.log("LimaControledTextField: ", props);*/

  const { t } = useTranslation();

  return (
    <Stack horizontal={props.horizontal}>
      {props.limaLabel && (
        <Stack.Item>
          <Label>{props.limaLabel} </Label>
        </Stack.Item>
      )}
      <Stack.Item grow={1}>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={newLocal}
          render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
            <>
              <TextField
                {...props}
                onChange={onChange}
                value={typeof value == "string" ? value : ""}
                onBlur={onBlur}
                name={fieldName}
                // errorMessage={error && error.message}
                defaultValue={undefined}
              />
              {error && <LimaNotice>{t(`tpmain:error.${error.type}`)}</LimaNotice>}
            </>
          )}
        />
      </Stack.Item>
    </Stack>
  );
};
