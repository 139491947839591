import {
  ActionButton,
  ChoiceGroup,
  IChoiceGroupOption,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  // SwatchColorPicker,
  TextField,
} from "@fluentui/react";
import { log } from "missionlog";
import { useEffect, useState } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../limaCommon/limaLog";
import { LimaObjtag } from "../../../limaComponents/Leafs/LimaObjtag";
import limaObjTagsStore from "../../../store/limaObjTagsStore";
import { LIMA_ITEM_TYPE } from "../../../types/BASE_Enums";
import { Tags, TagsTypes } from "../../../types/Tags";
import { TagsSelectColor } from "./TagsSelectColor";

interface TagsAddComponentProps {
  onAddCallback?: (tagKey: string) => void;
  onRemoveCallback?: (tagKey: string) => void;
  linkedId: string;
  linkedType: LIMA_ITEM_TYPE;
  currentTagsList: string[];
  maxTagsShowCount: number;
}

const classNames = mergeStyleSets({
  tagsAdd: {
    maxHeight: 300,
    overflow: "auto",
  },
  tagsAddList: {
    maxHeight: 300,
    overflow: "auto",
    borderBottom: "solid 1px #eee",
    marginBottom: 8,
  },
  tagsList: {},
});

//https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// const colorTagsBaseList = [
//   { id: "a", label: "orange", color: "#ca5010" },
//   { id: "b", label: "cyan", color: "#038387" },
//   { id: "c", label: "blueMagenta", color: "#8764b8" },
//   { id: "d", label: "magenta", color: "#881798" },
//   { id: "e", label: "lightBlue", color: "#4fc3f7" },
//   { id: "f", label: "lightTeal", color: "#80cbc4" },
//   { id: "g", label: "lightPink", color: "#f48fb1" },
//   { id: "h", label: "lightBrown", color: "#a1887f" },
// ];

export const TagsAddComponent = ({
  linkedId,
  linkedType,
  currentTagsList,
  onAddCallback,
  onRemoveCallback,
  maxTagsShowCount,
}: TagsAddComponentProps): JSX.Element => {
  const [addTagName, setAddTagName] = useState<string>("");
  const [isSameTagNameExist, setIsSameTagNameExist] = useState<string | null>(null);
  const [addTagColor, setAddTagColor] = useState<string>();
  const [addTagType, setAddTagType] = useState<TagsTypes>(TagsTypes.ALLVISIBLE);
  const [filteredTags, setFilteredTags] = useState<Tags[]>(limaObjTagsStore.tags);
  const [showAll, setShowAll] = useState<boolean>(false);
  // const [isSameTagNameExist, setIsSameTagNameExist] = useState<boolean>(false);
  const { t } = useTranslation();

  const tagTypeOption: IChoiceGroupOption[] = [
    { key: TagsTypes.ALLVISIBLE, text: t("tpmain:tags.addTags.tagType.allnotDeletableLbl") },
    { key: TagsTypes.ALLVISIBLE_DELETABLE, text: t("tpmain:tags.addTags.tagType.allDeletableLbl") },
    { key: TagsTypes.PERSONAL, text: t("tpmain:tags.addTags.tagType.onlyPersonalLbl") },
  ];
  //---------------
  // Reactions
  //--------------

  useEffect(() => {
    const notHiddenTags = filteredTags.filter((item: Tags) => {
      if (currentTagsList.includes(item._key) === true) return false;

      return true;
    });
    setFilteredTags(notHiddenTags);
  }, [currentTagsList]);

  //--------------
  // Handlers
  //--------------

  const onStoreTag = () => {
    const newTag: Tags = {
      _id: null,
      _key: null,
      active: true,
      cissuedby: "101",
      tagcolor: addTagColor,
      tagname: addTagName,
      type: addTagType,
    };

    log.info(limaLogTag.TagsAddComponent, "onStoreTag: storing tag ", newTag);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    void limaObjTagsStore.addNewTagCall(newTag).then((value: Tags) => {
      log.info(limaLogTag.TagsAddComponent, "onStoreTag: stored new tag", value);
      log.info(limaLogTag.TagsAddComponent, "onStoreTag: linking tag to item", linkedType);
      log.info(limaLogTag.TagsAddComponent, "onStoreTag: linking tag to item", linkedId);
      log.info(limaLogTag.TagsAddComponent, "onStoreTag: linking tag to item", [...currentTagsList, value._key]);
      void limaObjTagsStore
        .updateTagsOnItem(linkedType, linkedId, [...currentTagsList, value._key])
        .then(() => {
          if (onAddCallback !== undefined) onAddCallback(value._key);
        })
        .catch((err) => {
          log.error(limaLogTag.TagsAddComponent, "onStoreTag: some error ", err);
        });

      if (onAddCallback !== undefined) onAddCallback(value._key);
    });
  };

  const onTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newText = ev.target.value;
    log.info(limaLogTag.TagsAddComponent, "onTextChange: newText ", newText);

    //ADD debouncer
    setAddTagName(newText);
    // let existSameName = false;
    let sameTagNameChange = null;
    const filteredTagsItem = limaObjTagsStore.tags.filter((item: Tags) => {
      log.info(limaLogTag.TagsAddComponent, "onTextChange: change to ", item.tagname);
      if (item.tagname === newText) sameTagNameChange = newText;
      return item.tagname.includes(newText);
      //return newTextSplitted.every((w: string) => item.tagname.includes(w));
    });

    if (isSameTagNameExist !== sameTagNameChange) setIsSameTagNameExist(sameTagNameChange);

    setFilteredTags(filteredTagsItem);
  };

  const onAddTag = (tagToLink: Tags) => {
    //store link
    void limaObjTagsStore
      .updateTagsOnItem(linkedType, linkedId, [...currentTagsList, tagToLink._key])
      .then(() => {
        if (onAddCallback !== undefined) onAddCallback(tagToLink._key);
      })
      .catch((err) => {
        log.error(limaLogTag.TagsAddComponent, "onAddTag: some error ", err);
      });
  };

  const onRemTag = (tagToUnlink: Tags) => {
    // const updatedTagsList = tagsList.filter((item: string) => item !== tagToUnlink._key);
    //store link
    void limaObjTagsStore
      .updateTagsOnItem(
        linkedType,
        linkedId,
        currentTagsList.filter((key: string) => key !== tagToUnlink._key)
      )
      .then(() => {
        // setTagsList(updatedTagsList);
        if (onRemoveCallback !== undefined) onRemoveCallback(tagToUnlink._key);
      })
      .catch((err) => {
        log.error(limaLogTag.TagsComponent, "onRemTag: some error ", err);
      });
  };

  const ontTagTypeChange = (_ev: React.FormEvent<HTMLElement | HTMLInputElement>, option: IChoiceGroupOption) => {
    setAddTagType(TagsTypes[option.key]);
  };
  //--------------
  // RENDERS
  //--------------

  const RenderTag = (tagItem: Tags): JSX.Element => {
    return tagItem ? (
      <LimaObjtag
        key={`tag_${tagItem._key}`}
        text={tagItem.tagname}
        tagColor={tagItem.tagcolor}
        addEnabled
        addCallback={() => {
          onAddTag(tagItem);
        }}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <Stack.Item>
        <Stack horizontal style={{ alignItems: "flex-end" }}>
          <Stack.Item className={classNames.tagsAdd} grow={1}>
            <TextField label={t("tpmain:tags.addTags.tagIdenLbl")} onChange={onTextChange} />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton onClick={onStoreTag} disabled={addTagName === ""}>
              {t("tpmain:tags.addTags.addtagBtnLbl")}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {isSameTagNameExist !== null && (
        <Stack.Item className={classNames.tagsAddList}>
          {RenderTag(limaObjTagsStore.getTagByName(isSameTagNameExist))}
        </Stack.Item>
      )}
      {filteredTags?.length > 0 && (
        <Stack.Item className={classNames.tagsAddList}>
          {filteredTags.map((tagItem: Tags, index: number) => {
            if (!showAll && index >= maxTagsShowCount) {
              return null;
            }
            return RenderTag(tagItem);
          })}
          {showAll ? (
            <ActionButton
              onClick={() => {
                setShowAll(false);
              }}
            >
              {t("tpmain:tags.addTags.showLessactionbtnLbl")}
            </ActionButton>
          ) : (
            <ActionButton
              onClick={() => {
                setShowAll(true);
              }}
            >
              {t("tpmain:tags.addTags.showMoreactionbtnLbl")}
            </ActionButton>
          )}
        </Stack.Item>
      )}

      <Stack.Item>
        <TagsSelectColor
          colorCallback={(color: string) => {
            log.info(limaLogTag.TagsAddComponent, "colorCallback: change to ", color);
            color && setAddTagColor(color);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <ChoiceGroup
          label="Tag type"
          options={tagTypeOption}
          defaultSelectedKey={tagTypeOption[0].key}
          onChange={ontTagTypeChange}
        />
      </Stack.Item>

      {/* </>
      )} */}
      <Stack.Item>{t("tpmain:tags.addTags.showLinkedTabLbl")}</Stack.Item>
      <Stack.Item className={classNames.tagsList}>
        {currentTagsList.map((item: string) => {
          const tagItem = limaObjTagsStore.getTagByKey(item);
          return (
            <LimaObjtag
              key={`tag_${tagItem._key}`}
              text={tagItem.tagname}
              tagColor={tagItem.tagcolor}
              remEnabled
              remCallback={() => {
                onRemTag(tagItem);
              }}
            />
          );
        })}
      </Stack.Item>
    </>
  );
};
