import { getTheme, ITheme, mergeStyleSets } from "@fluentui/react";
import { Stack } from "office-ui-fabric-react";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuSelectedENUM } from "../../../limaComponents/LimaMenu";
import limaStore from "../../../store/limaStore";
import ToolsActionsPart from "./toolsparts/ToolsActionsPart";
import ToolsAdvisorPart from "./toolsparts/ToolsAdvisorPart";
import ToolsOtherPart from "./toolsparts/ToolsOtherPart";
import ToolsUsersPart from "./toolsparts/ToolsUsersPart";
import { LIMAENUM_AccesLicPermIdent } from "../../../limaCommon/limaPermissions";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../limaComponents/LimaNotice";
import LimaLicPermShow from "../../../limaComponents/LimaLicPermShow";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToolsV2Props {}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  toolsStackName: [
    fonts.medium,
    {
      marginLeft: 8,
      fontWeight: 600,
      fontColor: "#8E8E93",
    },
  ],
  toolsStackNotify: [
    fonts.small,
    {
      marginLeft: 8,
      fontWeight: 400,
      fontColor: "#8E8E93",
    },
  ],
  toolsStacTools: {
    // font: fonts.medium,
  },
});

const ToolsV2: React.FunctionComponent<IToolsV2Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    limaStore.setMenuOpen(MenuSelectedENUM.TOOLS);
    return () => {
      if (limaStore.menuOpen === MenuSelectedENUM.FILE) limaStore.setMenuOpen(MenuSelectedENUM.NONE);
    };
  }, []);

  return (
    <Stack>
      <Stack.Item className={classNames.toolsStackName}>{t("tpmain:tools.main.ActionLbl")}</Stack.Item>
      <Stack.Item className={classNames.toolsStacTools}>
        <ToolsActionsPart />
      </Stack.Item>
      <Stack.Item className={classNames.toolsStackName}>{t("tpmain:tools.main.UserLbl")}</Stack.Item>
      <Stack.Item className={classNames.toolsStacTools}>
        <ToolsUsersPart />
      </Stack.Item>
      <Stack.Item className={classNames.toolsStackName}>{t("tpmain:tools.main.AdvisorLbl")}</Stack.Item>
      <Stack.Item className={classNames.toolsStackNotify}>{t("tpmain:tools.main.AdvisorNotify")}</Stack.Item>
      <Stack.Item className={classNames.toolsStacTools}>
        <LimaLicPermShow
          accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsAdvisorsAll}
          noLicComponentShow={
            <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.WARN}>{t("tpmain:access.noAccessLbl")}</LimaNotice>
          }
        >
          <ToolsAdvisorPart />
        </LimaLicPermShow>
      </Stack.Item>
      <Stack.Item className={classNames.toolsStackName}>{t("tpmain:tools.main.MoreLbl")}</Stack.Item>
      <Stack.Item className={classNames.toolsStacTools}>
        <ToolsOtherPart />
      </Stack.Item>
    </Stack>
  );
};

export default ToolsV2;
