import {
  ActionButton,
  ColorPicker,
  getColorFromString,
  IColor,
  IColorPickerStyles,
  Stack,
  SwatchColorPicker,
} from "@fluentui/react";
import { log } from "missionlog";
import React = require("react");
import { useTranslation } from "react-i18next";
import { limaLogTag } from "../../../limaCommon/limaLog";

interface ITagsSelectColorProps {
  colorCallback?: (color: string) => void;
}

const white = getColorFromString("#ffffff")!;

const colorPickerStyles: Partial<IColorPickerStyles> = {
  panel: { padding: 10 },
  root: {
    maxWidth: 260,
    minWidth: 160,
  },
  colorRectangle: { height: 160 },
  table: {
    display: "none",
  },
};

//https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
const colorTagsBaseList = [
  { id: "a", label: "orange", color: "#ca5010" },
  { id: "b", label: "cyan", color: "#038387" },
  { id: "c", label: "blueMagenta", color: "#8764b8" },
  { id: "d", label: "magenta", color: "#881798" },
  { id: "e", label: "lightBlue", color: "#4fc3f7" },
  { id: "f", label: "lightTeal", color: "#80cbc4" },
  { id: "g", label: "lightPink", color: "#f48fb1" },
  { id: "h", label: "lightBrown", color: "#a1887f" },
];

export const TagsSelectColor: React.FunctionComponent<ITagsSelectColorProps> = (props: ITagsSelectColorProps) => {
  const [color, setColor] = React.useState(white);
  const [showMoreColors, setShowMoreColor] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const updateColor = React.useCallback((_ev: any, colorObj: IColor) => {
    setColor(colorObj);
    if (props.colorCallback !== undefined) props.colorCallback(colorObj.str);
  }, []);

  //-----------------
  // Handlers
  //------------------

  const onTagColorChange = (id?: string, color?: string) => {
    log.info(limaLogTag.TagsSelectColor, "onTagColorChange: change to ", id, color);
    color && setColor(getColorFromString(color));
    if (props.colorCallback !== undefined) props.colorCallback(color);
  };

  return (
    <Stack>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item grow={1}>
            <SwatchColorPicker
              columnCount={8}
              cellShape={"square"}
              colorCells={colorTagsBaseList}
              onColorChanged={onTagColorChange}
            />
          </Stack.Item>
          <Stack.Item>
            {showMoreColors ? (
              <ActionButton
                onClick={() => {
                  setShowMoreColor(false);
                }}
              >
                {t("tpmain:tags.addTags.showLessactionbtnLbl")}
              </ActionButton>
            ) : (
              <ActionButton
                onClick={() => {
                  setShowMoreColor(true);
                }}
              >
                {t("tpmain:tags.addTags.showMoreactionbtnLbl")}
              </ActionButton>
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {showMoreColors && (
        <Stack.Item>
          <ColorPicker
            color={color}
            onChange={updateColor}
            alphaType={"none"}
            showPreview={true}
            styles={colorPickerStyles}
            strings={{
              hueAriaLabel: "Hue",
            }}
          />
        </Stack.Item>
      )}
    </Stack>
  );
};
