import {
  getFocusStyle,
  getTheme,
  IconButton,
  IIconProps,
  ITheme,
  ITooltipHostStyles,
  mergeStyleSets,
  Persona,
  PersonaSize,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as React from "react";
import { getInitialsGlued } from "../../../../../limaCommon/limaStringTools";
import { IUser } from "../../../../../types/User";
import LimaLicPermShow from "../../../../../limaComponents/LimaLicPermShow";
import { LIMAENUM_AccesLicPermIdent } from "../../../../../limaCommon/limaPermissions";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserListItemV4Props {
  user: IUser;
  onUnlinkClick?: () => void;
  enableUnlink?: boolean;
  onDetailClick?: () => void;
  enableDetail: boolean;
}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { palette, fonts } = theme;

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
const DeatilaIcon: IIconProps = { iconName: "ChevronRightMed" };
const UnlinkIcon: IIconProps = { iconName: "Clear" };

const classNames = mergeStyleSets({
  userListItemWraper: [
    fonts.medium,
    getFocusStyle(theme, { inset: -1 }),
    {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 8,
      paddingBottom: 4,
      paddingTop: 4,
      // marginLeft: 8,
      // marginBottom: 8,
      fontWeight: 600,
      fontColor: "#8E8E93",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
    },
  ],
  userListItemName: {
    /*margin: 5,*/
    maxWidth: "70%",
    flexGrow: 1,
  },
  userListItemActions: {
    /*margin: 5,*/
  },
});

const UserListItemV4: React.FunctionComponent<IUserListItemV4Props> = ({
  user,
  onUnlinkClick,
  enableUnlink,
  onDetailClick,
  enableDetail,
}: IUserListItemV4Props) => {
  const removeId = useId("rem");
  const detailId = useId("detail");
  //---------------
  // Handlers
  //---------------

  return (
    <Stack className={classNames.userListItemWraper} data-is-focusable={true}>
      <Stack.Item className={classNames.userListItemName}>
        <Persona
          imageInitials={getInitialsGlued(user.userName)}
          text={user.userName}
          size={PersonaSize.size24}
          imageAlt={user.userName}
        />
      </Stack.Item>
      <Stack.Item className={classNames.userListItemActions}>
        <Stack horizontal>
          <LimaLicPermShow
            accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsUserRemoveLinkUser}
            dontShowOnNoLic={true}
          >
            <Stack.Item>
              <TooltipHost
                content="Unlink from list"
                id={removeId}
                calloutProps={calloutProps}
                styles={hostStyles}
                setAriaDescribedBy={false}
              >
                <IconButton
                  // className={classNames.itemCellIcon}
                  iconProps={UnlinkIcon}
                  title={"Unlink"}
                  disabled={!(enableUnlink !== undefined && enableUnlink === true)}
                  onClick={() => {
                    if (onUnlinkClick !== undefined) onUnlinkClick();
                  }}
                />
              </TooltipHost>
            </Stack.Item>
          </LimaLicPermShow>
          <Stack.Item>
            <TooltipHost
              content="Show detail"
              id={detailId}
              calloutProps={calloutProps}
              styles={hostStyles}
              setAriaDescribedBy={false}
            >
              <IconButton
                // className={classNames.itemCellIcon}
                iconProps={DeatilaIcon}
                title={"Detail"}
                disabled={!(enableDetail !== undefined && enableDetail === true)}
                onClick={() => {
                  if (onDetailClick !== undefined) onDetailClick();
                }}
              />
            </TooltipHost>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default UserListItemV4;
