import * as React from "react";
import { FunctionComponent, useState } from "react";

import { log, tag } from "missionlog";
import SearchResultItem from "./SearchResultItem";
import limaSearchStore from "../../../../store/limaSearchStore";
import limaStore from "../../../../store/limaStore";
import {
  ChoiceGroup,
  DefaultPalette,
  getTheme,
  IChoiceGroupOption,
  IStackItemStyles,
  IStackProps,
  IStackStyles,
  IStackTokens,
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  // ProgressIndicator,
  Separator,
  Stack,
  TextField,
  Toggle,
} from "office-ui-fabric-react";

import { keyPressHandler } from "../../../../limaCommon/keyboardHandler";
// import { observer } from "mobx-react-lite";
import { searchTypeENUM } from "../../../../api/apilinks";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import moment = require("moment");
import HistParaDateRange from "../../Show/history/components/HistParaDateRange";
import { LimeBreadCrumb } from "../../../../limaComponents/LimeBreadCrumb";

log.init({ SearchPage: "SearchPage" });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SearchPageProps {}

const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    color: DefaultPalette.neutralPrimary,
  },
};

// const options: IChoiceGroupOption[] = [
//   { key: "STRICT", text: "Strict" },
//   { key: "LIKE", text: "Like" },
//   { key: "FUZZY", text: "Fuzzy" },
// ];
/*const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    display: "flex",
    height: "60vh"
  }
});*/

const customSpacingStackTokens: IStackTokens = {
  childrenGap: "5%",
  padding: "s1 5%",
};

const stackItemStyles: IStackItemStyles = {
  root: {},
};

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: {
    root: {
      /*width:300*/
    },
  },
};
const theme: ITheme = getTheme();
const { fonts } = theme;

const classNames = mergeStyleSets({
  searchShortErr: {
    marginTop: -5,
    fontSize: fonts.small.fontSize,
    display: "flex",
  },
});

const SearchPage: FunctionComponent<SearchPageProps> = () => {
  const [minLenghtErr, setMinLenghtErr] = useState<boolean>(false);
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("STRICT");
  const [currentActaOnly, setCurrentActaOnly] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Moment>(moment().subtract("1", "M"));
  const [dateTo, setDateTo] = useState<Moment>(moment());

  const { t } = useTranslation(["tpsearch", "tpmain"]);

  //log.info(tag.SearchPage, "SearchPage: Mounted");

  const searchTextChecker = (value: string): boolean => {
    const stringText: string = value;

    log.info(tag.SearchPage, `searchTextChecker: Search checker ${stringText}`);
    if (stringText == undefined || stringText == null) {
      setSearchDisabled(true);
      return false;
    } else if (stringText == "") {
      setSearchDisabled(true);
      setMinLenghtErr(false);
    } else if (stringText.replace(" ", "").length <= 3) {
      setSearchDisabled(true);
      setMinLenghtErr(true);
      return false;
    } else {
      setSearchDisabled(false);
      setMinLenghtErr(false);
    }
    return true;
  };

  const updateDate = (fromDate: Moment, toDate: Moment) => {
    setDateFrom(fromDate);
    setDateTo(toDate);
  };

  const searchTextChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.info(tag.SearchPage, `searchTextChanged: Search text ${event.target.value}`);
    searchTextChecker(event.target.value);
    setSearchText(event.target.value);
  };

  const onChangeSearchType = (_ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => {
    log.info(tag.SearchPage, `onChangeSearchType: Search type change to ${option.text}[${option.key}]`);

    limaSearchStore.setSearchType(searchTypeENUM[option.key], option.key);
    setSearchType(option.key);
  };

  const onSearch = () => {
    log.info(tag.SearchPage, `onSearch: Search text`);
    //TODO: Fromdata, TOdata, actaonly
    if (searchTextChecker(searchText)) {
      log.info(tag.SearchPage, `onSearch: Search checker ok`, searchText, searchText);
      if (currentActaOnly)
        void limaSearchStore.getListFromDB(
          // limaStore.userId,
          searchText.split(" "),
          limaStore.actaId,
          dateFrom.toISOString(),
          dateTo.toISOString()
        );
      else
        void limaSearchStore.getListFromDB(
          // limaStore.userId,
          searchText.split(" "),
          null,
          dateFrom.toISOString(),
          dateTo.toISOString()
        );
    }
  };

  // const ShowProgress = observer((): JSX.Element => {
  //   log.info(tag.NotesPage, "ShowProgress: show progress change");
  //   return <>{limaSearchStore.loadingData && <ProgressIndicator label="Search" description="Searching on server" />}</>;
  // });

  return (
    <>
      <LimeBreadCrumb bradCrumbsStr={[t("tpmain:menu.editLbl"), t("tpmain:menu.edit.searchLbl")]} />
      <Separator alignContent="center">{t("tpsearch:searchPageLbl")}</Separator>
      <Stack key="searchPanel" tokens={stackTokens} styles={stackStyles}>
        <Stack {...columnProps} key="searchPanelContainer">
          <Stack.Item>
            <HistParaDateRange
              fromDate={dateFrom}
              toDate={dateTo}
              setDate={updateDate}
              hideUpdateBtn={true}
              setOnChange={true}
            />
          </Stack.Item>
          <Stack key="searchMenuSearchField" horizontal disableShrink tokens={customSpacingStackTokens}>
            <Stack.Item styles={stackItemStyles}>
              <TextField
                key="searchMenuSearchFieldTxt"
                onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) =>
                  keyPressHandler(ev, "Enter", () => onSearch())
                }
                onChange={searchTextChangedHandler}
              />
              {minLenghtErr && (
                <div key="searchMenuSearchTextErr" className={classNames.searchShortErr}>
                  {t("tpsearch:minimaSearchLenghtWarningLbl")}
                </div>
              )}
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
              <PrimaryButton
                key="searchMenuSearchFieldBtn"
                text={t("tpsearch:searchBtn")}
                disabled={searchDisabled}
                onClick={() => onSearch()}
              />
            </Stack.Item>
          </Stack>

          <Stack key="searchMenuSearchActaOnly" horizontal tokens={customSpacingStackTokens}>
            <Stack.Item>
              <Toggle
                inlineLabel
                disabled={!searchDisabled && limaStore.actaId != null && limaStore.actaId != "" ? false : true}
                onText={t("tpsearch:searchIncurrentOnlyToggle")}
                offText={t("tpsearch:searchInAllHistoryToggle")}
                onClick={() => {
                  setCurrentActaOnly(!currentActaOnly);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ChoiceGroup
                defaultSelectedKey={searchTypeENUM.STRICT}
                disabled={searchDisabled}
                options={[
                  { key: "STRICT", text: t("tpsearch:searchTypeEnum.strictLbl") },
                  { key: "LIKE", text: t("tpsearch:searchTypeEnum.likeLbl") },
                  { key: "FUZZY", text: t("tpsearch:searchTypeEnum.fuzzyLbl") },
                ]}
                selectedKey={searchType}
                onChange={onChangeSearchType}
              />
            </Stack.Item>
          </Stack>
          <Separator alignContent="center">{t("tpsearch:searchResultsLbl")}</Separator>
          {/* <Stack key="searchProgressBar">
            <ShowProgress />
          </Stack> */}
          <Stack key="fileListList">
            <SearchResultItem />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchPage;
