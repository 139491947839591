import { PartialTheme } from "@fluentui/react-theme-provider";

export const appTheme: PartialTheme = {
  defaultFontStyle: { fontFamily: "Segoe UI", fontWeight: "regular" },
  fonts: {
    small: {
      fontSize: "10px",
    },
    smallPlus: {
      fontSize: "11px",
    },
    medium: {
      fontSize: "14px",
      fontWeight: 400,
      color: "2C2C2E",
    },
    mediumPlus: {
      fontSize: "14px",
      fontWeight: 600,
      color: "2C2C2E",
    },
    large: {
      fontSize: "16px",
      fontWeight: 600,
      color: "2C2C2E",
    },
    xLarge: {
      fontSize: "22px",
      fontWeight: "semibold",
    },
  },
  semanticColors: {
    bodyBackground: "#f5f7fa",
    // inputBackground: "#f5f7fa",
    inputBorderHovered: "solid 1px #FF9595",
    inputText: "#F00",
    smallInputBorder: "solid 1px #959595",
    inputBackgroundCheckedHovered: "solid 1px #FF9595",
    inputBorder: "solid 1px #959595",
  },
  palette: {
    themePrimary: "#0078d4",
    themeLighterAlt: "#eff6fc",
    themeLighter: "#deecf9",
    themeLight: "#c7e0f4",
    themeTertiary: "#71afe5",
    themeSecondary: "#2b88d8",
    themeDarkAlt: "#106ebe",
    themeDark: "#005a9e",
    themeDarker: "#004578",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
};
