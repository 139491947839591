import { Stack, Text } from "@fluentui/react";
import React = require("react");
import { observer } from "mobx-react-lite";
import LimaLogsStore, { LogstoreListItem } from "../../../store/limaLogsStore";

export const LogsPage = (): JSX.Element => {
  //---------------------
  //    HANDLERS
  //---------------------

  //---------------------
  //    OBSERVERS
  //---------------------
  const ErrorsList = observer(() => {
    return (
      <Stack>
        {LimaLogsStore.errorsList.map((item: LogstoreListItem) => (
          <Stack.Item key={item.key}>
            <Stack>
              <Stack.Item>
                <Stack horizontal>
                  <Stack.Item>{item.type}</Stack.Item>
                  <Stack.Item>{item.title}</Stack.Item>
                  <Stack.Item>{item.comp}</Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>{item.msg}</Stack.Item>
            </Stack>
          </Stack.Item>
        ))}
      </Stack>
    );
  });
  //---------------------
  //    RENDERS
  //---------------------

  return (
    <Stack>
      <Stack.Item>
        <Text variant="medium">Errors</Text>
      </Stack.Item>
      <Stack.Item>
        <Text variant="small">Used for testing only reasons to see all Errors</Text>
      </Stack.Item>
      <Stack.Item>
        <ErrorsList />
      </Stack.Item>
    </Stack>
  );
};
