import { FontIcon, IStackTokens, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import React = require("react");
import { XCOPY_TYPE } from "../../../../../types/Task";

interface FileOpenNameProps {
  xcopytype: XCOPY_TYPE;
  xcopyName: string;
  xcopyTitle?: string;
  children?: React.ReactNode;
}

const classNames = mergeStyleSets({
  itemIcon: {
    fontSize: 16,
    height: 16,
    width: 20,
    margin: "4px 25px",
    cursor: "default",
  },
  items: {
    alignSelf: "center",
    marginLeft: 10,
    flexShrink: 0,
  },
});

const stackTokens: IStackTokens = {
  childrenGap: 1,
  padding: 2,
};

const FileOpenName: FunctionComponent<FileOpenNameProps> = ({
  xcopytype,
  xcopyName,
  xcopyTitle,
  children,
}: FileOpenNameProps) => {
  const CopyIcon = (): JSX.Element => {
    if (xcopytype != null) {
      if (xcopytype == XCOPY_TYPE.FULLXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="FullCircleMask" title="Full copy" />;
      else if (xcopytype == XCOPY_TYPE.TRIMXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="CircleHalfFull" title="Trimmed copy" />;
      else if (xcopytype == XCOPY_TYPE.MAINXCOPY)
        return <FontIcon className={classNames.itemIcon} iconName="Crown" title="Main copy" />;
      else return <FontIcon className={classNames.itemIcon} iconName="StatusCircleBlock" title="no copy" />;
    }
    return <FontIcon className={classNames.itemIcon} iconName="UnknownSolid" title="Unknow copy typ" />;
  };

  return (
    <Stack horizontal tokens={stackTokens}>
      {/* <div key="fileContainer" className={classNames.itemFileContainer}>
      <div key="name" className={classNames.itemName}> */}
      <Stack.Item shrink className={classNames.items}>
        <CopyIcon />
      </Stack.Item>
      <Stack.Item grow className={classNames.items}>
        <Label title={xcopyTitle ? xcopyTitle : xcopyName}>{xcopyName}</Label>
      </Stack.Item>
      {children && <Stack.Item>{children}</Stack.Item>}
    </Stack>
  );
};

export default FileOpenName;
