import { Separator, Stack, StackItem } from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import React = require("react");
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { actaListOK } from "../../../../../api/schema/getActaList";
import limaStore from "../../../../../store/limaStore";
import { LIMA_ITEM_TYPE } from "../../../../../types/BASE_Enums";
import TagsComponent from "../../../../CommonComponents/Tags/TagsComponent";
import FileInfoBasicStat from "./FileBasicStat";
import FileItemChangeOwner from "./FileItemChangeOwner";

interface FileItemInfoProps {
  item: actaListOK;
  changeItem: (item: actaListOK) => void;
}

const FileItemInfo: FunctionComponent<FileItemInfoProps> = ({ item, changeItem }: FileItemInfoProps) => {
  const { t } = useTranslation(["tpfile"]);
  return (
    <>
      <Separator>{t("tpfile:fileopen.fileitem.info.infolbl")}</Separator>
      <Stack>
        <StackItem>
          {t("tpfile:fileopen.fileitem.info.filenamelbl")} {item.name}
        </StackItem>
        <StackItem>
          {t("tpfile:fileopen.fileitem.info.verionscountlbl")} {item.histCount}
        </StackItem>
        <StackItem>
          {t("tpfile:fileopen.fileitem.info.visiblefromlbl")}{" "}
          <Moment format={limaStore.userDateTimeFormat}>{item.linkedFrom}</Moment>
        </StackItem>
      </Stack>
      <Separator>{t("tpfile:fileopen.fileitem.info.changeownwerlbl")}</Separator>=
      <FileItemChangeOwner item={item} changeItem={changeItem} />
      <Separator>{t("tpfile:fileopen.fileitem.info.Statlbl")}</Separator>
      <FileInfoBasicStat actaId={item.id} />
      <Separator />
      <TagsComponent linkedId={item.mainXcopy} linkedType={LIMA_ITEM_TYPE.XCOPY} tagsKeyList={item.tags} />
    </>
  );
};

export default FileItemInfo;
