/**
 * Component shows single file item history timeFrame link
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
import { log } from "missionlog";
import {
  DefaultButton,
  getFocusStyle,
  getTheme,
  IButtonStyles,
  IconButton,
  IIconProps,
  ITheme,
  mergeStyleSets,
  TextField,
} from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import FileItemCompareHandleV4 from "./FileItemCompareHandlersV4";
import { IStackTokens, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { LimaDocData, LimaDocTypeENUM } from "../../../../types/docxData";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import { getDocxData, getFileFromLocal } from "../../../../limaCommon/limaFile";
import { checkDocOwner } from "../../../../limaCommon/limaFileOpen";
import limaLoadeStore from "../../../../store/limaLoaderStore";
import limaStore, { OPENEDFILETYPE_ENUM } from "../../../../store/limaStore";
import { CheckActaOwner } from "../../../../types/Acta";
import { compareOnServer, compareOnServerX3, STOREorCOMPARE_ENUM } from "../../../../limaCommon/limaUploadDoc";
import limaActasStore from "../../../../store/limaActasStore";
import { XCOPY_TYPE } from "../../../../types/Task";
import LimaLicPermShow from "../../../../limaComponents/LimaLicPermShow";
import { LIMAENUM_AccesLicPermIdent } from "../../../../limaCommon/limaPermissions";

const theme: ITheme = getTheme();

const classNames = mergeStyleSets({
  itemCellIcon: [
    getFocusStyle(theme),
    {
      paddingRight: 20,
      minHeight: 30,
      marginTop: 10,
      verticalAlign: "left",
    },
  ],
  ItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
  },
  ItemRemoveLine: {
    textDecoration: "line-through",
  },

  ItemTextName: {
    marginTop: 10,
  },
  ItemTop: {
    marginTop: 10,
  },
});

const RemoveFileIcon: IIconProps = { iconName: "PageRemove" };

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    marginTop: 10,
    width: 100,
  },
};

const spacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 0,
  maxWidth: "95%",
};

interface IFileItemImportV4Prop {
  file: File;
}

const FileItemImportV4: FunctionComponent<IFileItemImportV4Prop> = ({ file }: IFileItemImportV4Prop) => {
  const [limactaDocumentID, setLimactaDocumentID] = useState<string>("");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [hasLimaParams, setHasLimaParams] = useState<boolean>(false);
  const [actaNameNew, setActaNameNew] = useState<string>("");
  const [actaTypeNew, setActaTypeNew] = useState<LimaDocTypeENUM>(LimaDocTypeENUM.none);
  const [isStoredNow, setIsStoredNow] = useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [onBehalf, _setOnBehalf] = useState<string | null>(null);
  const [compareHandlers, setShowCompareHandlers] = useState<boolean>(false);
  const [compareBtnTxt, setCompareBtnTxt] = useState<string>("");
  const [lastVersion, seLastVersion] = useState<number[]>([0, 0]);
  const [xcopyType, setXcopyType] = useState<XCOPY_TYPE>(XCOPY_TYPE.NOXCOPY);
  const { t } = useTranslation();

  useEffect(() => {
    log.info(limaLogTag.FileItemImport, "useEffect: ", file);
    getFileFromLocal(file)
      .then((binaryString) => {
        getDocxData(binaryString)
          .then((limaData: LimaDocData) => {
            if (limaData === null) {
              log.info(limaLogTag.FileItemImport, "useEffect/getDocxData: no lima data");
              setHasLimaParams(false);
              setLimactaDocumentID("");
              setActaNameNew(file.name.replace(/\..+$/, ""));
            } else {
              log.info(limaLogTag.FileItemImport, "useEffect/getDocxData: lima data", limaData);
              setHasLimaParams(true);
              setLimactaDocumentID(limaData.limactaDocID);
              setActaNameNew(limaData.limactaDocName);
              setActaTypeNew(limaData.limactaDocType);
              const loaderID = limaLoadeStore.add("Check doc owner");

              try {
                if (limaData.limactaDocType === LimaDocTypeENUM.ACTA) {
                  void checkDocOwner(limaData.limactaDocID, limaStore.userId, "", loaderID).then(
                    (ownershipResponse: CheckActaOwner) => {
                      setIsOwner(ownershipResponse.isowner);
                      setCompareBtnTxt("Compare Acta");
                      setIsStoredNow(ownershipResponse.actaexists);
                      seLastVersion([ownershipResponse.lastversion_major, ownershipResponse.lastversion_minor]);
                      setXcopyType(ownershipResponse.type);
                      limaStore.setIsOwner(ownershipResponse.isowner);
                    }
                  );
                } else if (limaData.limactaDocType === LimaDocTypeENUM.XCOPY) {
                  void checkDocOwner("", limaStore.userId, limaData.limactaDocID, loaderID).then(
                    (ownershipResponse: CheckActaOwner) => {
                      setIsOwner(ownershipResponse.isowner);
                      setCompareBtnTxt("Compare Xcopy");
                      setIsStoredNow(ownershipResponse.actaexists);
                      seLastVersion([ownershipResponse.lastversion_major, ownershipResponse.lastversion_minor]);
                      setXcopyType(ownershipResponse.type);

                      // get name of existed
                      if (ownershipResponse.isowner === true) {
                        const actaId = limaActasStore.getActaIdFocUnknowXcopy(limaData.limactaDocID);
                        const acta = limaActasStore.getActa(actaId);
                        if (acta !== null && acta.name !== undefined && acta.name !== null) {
                          setActaNameNew(acta.name);
                        }
                      }
                    }
                  );
                }
              } finally {
                limaLoadeStore.remove(loaderID.k);
              }
              // limaStore.setIsOwner(limaActasStore.isOwner(limaData.limactaDocID)); //TODO: we have also interface limaFileOpen.checkDocOwner
              // setIsOwner(limaStore.isOwner);
              log.info(limaLogTag.FileItemImport, "useEffect/getDocxData: haveData");
            }
          })
          .catch(() => {
            log.warn(limaLogTag.FileItemImport, "useEffect/getDocxData: getDocxData rejct");
            setHasLimaParams(false);
            setLimactaDocumentID("");
            setActaNameNew(file.name.replace(/\..+$/, ""));
          })
          .finally(() => {
            log.info(limaLogTag.FileItemImport, "useEffect/getDocxData: final");
          });
      })
      .catch((err) => {
        log.error(limaLogTag.FileItemImport, "useEffect: some error in accessing to file", err);
      });
  }, [file]);

  const onNewActaNameChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    //TODO: do we need to update it this way? or just on update
    setActaNameNew(newValue);
  };

  const handleRemoveFileInList = () => {
    setIsRemoved(true);
  };

  /**
   * When store new docx
   * @param file file
   * @param id id of file
   * @param _fileName filename
   *
   */
  const newStoreDocx2 = (file: File, id: string, _fileName = "") => {
    // loaderHandler(true);
    log.info(limaLogTag.FileItemImport, "newStoreDocx2: storing id ", id);
    const loaderID = limaLoadeStore.add(`Store new document to server '${_fileName}'`);
    compareOnServer(
      file,
      id,
      LimaDocTypeENUM.XCOPY, //LimaDocTypeENUM.none,
      LimaDocTypeENUM.XCOPY, //LimaDocTypeENUM.none,
      STOREorCOMPARE_ENUM.STORE,
      actaNameNew,
      loaderID,
      (data: LimaDocData) => {
        limaLoadeStore.remove(loaderID.k);
        setIsStoredNow(true);
        limaStore.setActa(data.limactaDocID);
        // limaStore.setActaId(data.limactaDocID);
        // limaStore.setActaName(data.limactaDocName);
        log.info(limaLogTag.FileItemImport, "newStoreDocx2: haveData type ", data.limactaDocType);
        limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA); //TODO: WHAT IF COPY
        limaStore.setIsOwner(limaActasStore.isOwnerActaId(data.limactaDocID));
      },
      onBehalf
    )
      .then(() => {
        // limaLoadeStore.remove(loaderID.k);
        log.info(limaLogTag.FileItemImport, "newStoreDocx2: success");
      })
      .catch((error) => {
        // limaLoadeStore.remove(loaderID.k);
        log.error(limaLogTag.FileItemImport, "newStoreDocx2: error", error);
      });
  };

  const compareStoreDocx3 = (
    file: File,
    originId: string,
    originType: LimaDocTypeENUM,
    comapareType: LimaDocTypeENUM
  ) => {
    // loaderHandler(true);
    const loaderID = limaLoadeStore.add(`Compare document on server`);
    compareOnServerX3(
      file,
      originId,
      originType,
      comapareType,
      STOREorCOMPARE_ENUM.COMPARE,
      actaNameNew,
      loaderID,
      onBehalf,
      (data: LimaDocData) => {
        limaLoadeStore.remove(loaderID.k);

        limaStore.setActaName(data.limactaDocName);
        if (comapareType === LimaDocTypeENUM.XCOPY) {
          //TODO: this is not ok >]
          limaStore.setActaId(null);
          limaStore.setXcopyId(data.limactaDocID);
          limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.COPY);
        } else if (comapareType === LimaDocTypeENUM.ACTA) {
          limaStore.setActaId(data.limactaDocID);
          limaStore.setXcopyId(null);
          limaStore.setActiveOpenType(OPENEDFILETYPE_ENUM.ACTA);
        }

        setShowCompareHandlers(true);
      }
    )
      .then(() => {
        log.info(limaLogTag.FileItemImport, "compareStoreDocx3: success");
      })
      .catch((error) => {
        log.error(limaLogTag.FileItemImport, "compareStoreDocx3: error", error);
      });
  };

  const onCompare = () => {
    //If compare to Acta
    if (actaTypeNew == LimaDocTypeENUM.ACTA) {
      //compareStoreDocx2(file, limactaDocumentID);
      compareStoreDocx3(file, limactaDocumentID, LimaDocTypeENUM.ACTA, LimaDocTypeENUM.ACTA);
    }
    //If compare to Copy
    else if (actaTypeNew == LimaDocTypeENUM.XCOPY) {
      compareStoreDocx3(file, limactaDocumentID, LimaDocTypeENUM.XCOPY, LimaDocTypeENUM.XCOPY);
    } else {
      log.error(limaLogTag.FileItemImport, `CompareStore: dont know what to do`);
    }
  };

  const showPossibilities = (isStore: boolean, isOwner: boolean): JSX.Element => {
    log.info(
      limaLogTag.FileItemImport,
      `showPossibilities final store [${String(isStore)}] owner [${String(isOwner)}] `
    );
    if (isOwner === true) {
      return (
        <DefaultButton
          styles={buttonStyles}
          // disabled={docId ? false : true}
          text={compareBtnTxt} //"Compare"
          size={10}
          onClick={onCompare}
        />
      );
    } else {
      if (isStore === true) {
        return <> {t("tpfile:fileimport.compare.notownerandstoredNotify")}</>;
      } else {
        return (
          <DefaultButton
            styles={buttonStyles}
            // disabled={docId ? false : true}
            text={t("tpfile:fileimport.compare.saveasnewBtnLbl")}
            size={10}
            disabled={isStoredNow ? true : false}
            onClick={() => newStoreDocx2(file, limactaDocumentID, actaNameNew)}
          />
        );
      }
    }
    return <> ??? </>;
  };

  return (
    <div className={classNames.ItemContainer}>
      {isRemoved === true ? (
        <Stack horizontal tokens={spacingStackTokens}>
          <Stack.Item className={classNames.ItemRemoveLine}> Removed : ${file.name}</Stack.Item>
        </Stack>
      ) : (
        <Stack>
          <Stack.Item>
            <Stack horizontal tokens={spacingStackTokens}>
              <Stack.Item>
                <TextField
                  // eslint-disable-next-line react/jsx-no-bind
                  size={30}
                  value={actaNameNew}
                  className={classNames.ItemTextName}
                  onChange={onNewActaNameChange}
                  disabled={!isStoredNow || !isOwner ? false : true}
                />
              </Stack.Item>
              <Stack.Item>{showPossibilities(hasLimaParams, isOwner)}</Stack.Item>

              <Stack.Item>
                <IconButton
                  className={classNames.itemCellIcon}
                  iconProps={RemoveFileIcon}
                  title={"Remove from list"}
                  onClick={handleRemoveFileInList}
                />
              </Stack.Item>
              {/* <Stack.Item
                className={classNames.ItemTop}
              >{`(${file.name}/${actaNameNew}[${limactaDocumentID}][${actaTypeNew}])`}
              </Stack.Item> */}
            </Stack>
          </Stack.Item>
          <LimaLicPermShow accLicPermIdent={LIMAENUM_AccesLicPermIdent.ACC_toolsOtherTester}>
            <Stack.Item>{`(${file.name}/${actaNameNew}[${limactaDocumentID}][${actaTypeNew}])`}</Stack.Item>
          </LimaLicPermShow>
          {compareHandlers && (
            <Stack.Item>
              <FileItemCompareHandleV4
                onStore={() => newStoreDocx2(file, limactaDocumentID, actaNameNew)}
                versionLast={lastVersion}
                type={xcopyType}
                xcopyId={limactaDocumentID}
              />
            </Stack.Item>
          )}
        </Stack>
      )}
    </div>
  );
};
export default FileItemImportV4;
