import { log } from "missionlog";
import moment = require("moment");
import { Separator, Stack, StackItem } from "office-ui-fabric-react";
import { FunctionComponent, useEffect, useState } from "react";
import React = require("react");
import { useQuery } from "react-query";
import { getuseractivitystatoffset } from "../../../../../api/apilinks";
import { httpGetAuth, HttpResponse } from "../../../../../api/httpAPI";
import { limaLogTag } from "../../../../../limaCommon/limaLog";
import { LimaLoadingComponnet } from "../../../../../limaComponents/LimaLoadingComponnet";

import limaLoadeStore, { LoaderItemEnd } from "../../../../../store/limaLoaderStore";
import limaStore from "../../../../../store/limaStore";
import { StatisticActivity, UserStatisticHeatMapType } from "../../../../../types/Statistic";
// import { CalendarHeatmap, HeatmapSeries, HeatmapCell, ChartTooltip, formatValue } from "reaviz";
import UserStatisticHMcomp from "./UserStatisticHMcomp";
import UserStatisticList from "./UserStatisticList";

interface UserStatisticActivityProps {
  userId: string;
  startDate?: Date;
}

const UserStatisticActivity: FunctionComponent<UserStatisticActivityProps> = ({
  userId,
  startDate,
}: UserStatisticActivityProps) => {
  const [detailDate, setDetailDate] = useState<Date>();
  const [activityStartDate, setActivityStartDate] = useState<Date>();

  const { status, error, data, refetch } = useQuery(
    ["getUserActivitytStat", { userid: limaStore.userId, foruserid: userId, date: activityStartDate }],
    () => getUserActivitytStat(userId, activityStartDate),
    {
      enabled: false,
      onSuccess: (data) => {
        log.info(limaLogTag.UserStatisticActivity, "useQuery get getUserActivitytStat ", data);
      },
    }
  );

  useEffect(() => {
    log.info(limaLogTag.UserStatisticActivity, "UEF mounted ", userId, startDate);
    let startDateLocal: Date;
    if (startDate === undefined) startDateLocal = moment().toDate();
    else startDateLocal = startDate;

    setDetailDate(startDateLocal);
    setActivityStartDate(startDateLocal);
  }, []);

  useEffect(() => {
    if (userId !== undefined && activityStartDate !== undefined) {
      log.info(
        limaLogTag.UserStatisticActivity,
        "UEF get getUserActivitytStat call refetch ",
        userId,
        activityStartDate
      );
      void refetch();
    } else {
      log.warn(
        limaLogTag.UserStatisticActivity,
        "UEF get getUserActivitytStat missing userid or activity startdate",
        userId,
        activityStartDate
      );
    }
  }, [userId, activityStartDate]);

  if (status === "loading") {
    log.info(limaLogTag.UserStatisticActivity, "stat is loading: ", data);
    return (
      <>
        <div>... getting stat ...</div>
        {LimaLoadingComponnet(3)}
      </>
    );
  }
  if (status === "error") {
    log.error(limaLogTag.UserStatisticActivity, "stat is loading: ", error);
    return <div>{error}</div>;
  }
  if (status === "success") {
    log.info(limaLogTag.UserStatisticActivity, "stat loaded: ", data);
  }
  if (data === undefined || data === null) {
    log.info(limaLogTag.UserStatisticActivity, "no data: ");
    return <div>No data</div>;
  }

  const onHMClick = (date: Date) => {
    setDetailDate(date);
  };

  const onChangeStartDate = (date: Date) => {
    log.info(limaLogTag.UserStatisticActivity, "onChangeStartDate: set start date: ", date);
    if (date !== undefined || date !== null) {
      log.info(limaLogTag.UserStatisticActivity, "onChangeStartDate: set start date: - setting - is not null ");
      if (activityStartDate !== date) {
        setActivityStartDate(date);
      } else {
        log.info(
          limaLogTag.UserStatisticActivity,
          "onChangeStartDate: set start date: - NOT SETTING - is same",
          activityStartDate,
          date
        );
      }
    }
  };

  return (
    <>
      Statictis
      <Separator />
      <Stack>
        <StackItem>
          <Stack horizontal>
            <StackItem grow={true} style={{ textAlign: "center" }}>
              Activity
            </StackItem>
            <StackItem grow={true} style={{ textAlign: "center" }}>
              Changes
            </StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem grow={true} style={{ textAlign: "center" }}>
              <UserStatisticHMcomp
                startingDate={activityStartDate}
                monthShift={3}
                rangeLimits={[10, 20, 40, 80, 160]}
                colorVariant="A"
                changeStartDateCallBack={onChangeStartDate}
                onHMClick={onHMClick}
                data={
                  data &&
                  data
                    .map((item: StatisticActivity) => {
                      if (item.activity > 0) return { date: new Date(item.timestamp), count: item.activity };
                      return null;
                    })
                    .filter((item: UserStatisticHeatMapType | null) => item != null)
                }
                valueSufix="activities"
              />
            </StackItem>
            <StackItem grow={true} style={{ textAlign: "center" }}>
              <UserStatisticHMcomp
                startingDate={activityStartDate}
                monthShift={3}
                rangeLimits={[40, 80, 160, 320, 640]}
                colorVariant="B"
                onHMClick={onHMClick}
                changeStartDateCallBack={onChangeStartDate}
                data={
                  data &&
                  data
                    .map((item: StatisticActivity) => {
                      if (item.changes > 0) return { date: new Date(item.timestamp), count: item.changes };
                      return null;
                    })
                    .filter((item: UserStatisticHeatMapType | null) => item != null)
                }
                valueSufix="changes"
              />
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <Separator />
          <UserStatisticList userId={userId} startDate={detailDate} />
        </StackItem>
      </Stack>
    </>
  );
};

export default UserStatisticActivity;

//----------------------
//   REACT QUERY
//----------------------

const getUserActivitytStat = async (foruserid: string, date: Date): Promise<StatisticActivity[]> => {
  log.info(limaLogTag.UserStatisticActivity, "fetching data getUserActivitytStat");
  // let startDateLocal = date;
  if (date === undefined || date === null) date = moment().toDate();
  const startDate = moment(date, "DD/MM/YYYY").endOf("month");
  const nowDate = moment().endOf("month");
  const diff = nowDate.diff(startDate, "months");
  log.info(limaLogTag.UserStatisticActivity, "start date diff ", diff);

  //console.log(diff);
  const loaderID = limaLoadeStore.add("Getting user statics from DB");
  try {
    const response: HttpResponse<StatisticActivity[]> = await httpGetAuth<StatisticActivity[]>(
      getuseractivitystatoffset(foruserid, diff, 3),
      //getuseractivitystatdate(userid, foruserid, startDateLocal.toISOString()),
      loaderID.ac
    );
    log.info(limaLogTag.UserStatisticActivity, "fetching data getUserActivitytStat response", response);
    if (!response.ok) {
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
      throw response;
    }
    if (response.parsedBody !== undefined) {
      if (Array.isArray(response.parsedBody))
        log.info(
          limaLogTag.UserPermissionsEditPage,
          "getUserPermissions: asketo to get something",
          response.parsedBody
        );
      limaLoadeStore.remove(loaderID.k, LoaderItemEnd.OK);
      return response.parsedBody;
    }
    throw new Error("no data");
  } catch (error) {
    limaLoadeStore.remove(loaderID.k, LoaderItemEnd.FAILED);
    throw new Error(error);
  } finally {
    // limaLoadeStore.remove(loaderID.k);
  }
};
