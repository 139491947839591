import React = require("react");
import { FunctionComponent } from "react";
import { log } from "missionlog";
import { mergeStyleSets } from "office-ui-fabric-react";
// import limaStore from "../../store/limaStore";
// import limaLoadeStore from "../../store/limaLoaderStore";
import { useHistory } from "react-router-dom";
import { limaLogTag } from "../../limaCommon/limaLog";

// log.init({ Login: "Login" });

const classNames = mergeStyleSets({
  logoLogin: {
    fontWeight: 700,
    cursor: "pointer",
  },
});

const Login: FunctionComponent = () => {
  const history = useHistory();

  const onLogin = () => {
    log.info(limaLogTag.Login, "onLogin: logging");
    // limaStore.setUser("", "", "");
    // localStorage.setItem("userId", "");
    //TODO: acta cleanup
    //TODO: task cleanup

    //Load users
    // log.info(limaLogTag.Login, "onLogin: loading usersList");
    // void limaUsersStore.getDataFromDB();

    // limaLoadeStore.cleanup();
    // limaStore.setLogged(false);
    history.push("/");
  };

  return (
    <>
      <span onClick={onLogin} className={classNames.logoLogin}>
        LOGIN
      </span>
    </>
  );
};

export default Login;
