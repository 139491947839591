import { getTheme, Icon, ITheme, mergeStyleSets, Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { LimaDocTypeENUM } from "../../../../types/docxData";
// import { LimaSimleTextLink } from "../../../../limaComponents/LimaSimleTextLink";
// import Routes from "../../../Menu/routes";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFileStoreFinishV4Props {
  documenttype: LimaDocTypeENUM;
  storetype: FileStoreFinishV4TypeENUM;
}

export enum FileStoreFinishV4TypeENUM {
  CREATE = "create",
  UPDATE = "update",
  ERROR_UPDATE = "errorUpdate",
  ERROR_CREATE = "errorCreate",
}

const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  toolsStackName: [
    fonts.large,
    {
      marginLeft: 8,
      marginBottom: 10,
      fontWeight: 600,
      fontColor: "#8E8E93",
    },
  ],
  toolsStacWhole: {
    margin: 5,
  },
});

const FileStoreFinishV4: React.FunctionComponent<IFileStoreFinishV4Props> = () => {
  const { t } = useTranslation();

  const { documenttype, storetype } = useParams<IFileStoreFinishV4Props>();

  //---------------
  // Renders
  //---------------

  const SuccessMsg = (): JSX.Element => {
    if (storetype === FileStoreFinishV4TypeENUM.UPDATE) {
      if (documenttype === LimaDocTypeENUM.ACTA)
        return (
          <>
            <Icon iconName="Completed" style={{ marginRight: 10 }} />
            {t("tpfile:filesave.finish.newVersionLbl")}
          </>
        );
      else
        return (
          <>
            <Icon iconName="Completed" style={{ marginRight: 10 }} />
            {t("tpfile:filesave.finish.updateCopyLbl")}
          </>
        );
    } else if (storetype === FileStoreFinishV4TypeENUM.CREATE) {
      if (documenttype === LimaDocTypeENUM.ACTA)
        return (
          <>
            <Icon iconName="Completed" style={{ marginRight: 10 }} />
            {t("tpfile:filesave.finish.newActaLbl")}
          </>
        );
      else
        return (
          <>
            <Icon iconName="Completed" style={{ marginRight: 10 }} />
            {t("tpfile:filesave.finish.newCopyLbl")}
          </>
        );
    } else if (storetype === FileStoreFinishV4TypeENUM.ERROR_CREATE) {
      if (documenttype === LimaDocTypeENUM.ACTA)
        return (
          <>
            <Icon iconName="Error" style={{ color: "#eddb00", marginRight: 10 }} />
            {t("tpfile:filesave.finish.newActaNotCreateLbl")}
          </>
        );
      else
        return (
          <>
            <Icon iconName="Error" style={{ color: "#eddb00", marginRight: 10 }} />
            {t("tpfile:filesave.finish.newCopyNotCreateLbl")}
          </>
        );
    } else if (storetype === FileStoreFinishV4TypeENUM.ERROR_UPDATE) {
      if (documenttype === LimaDocTypeENUM.ACTA)
        return (
          <>
            <Icon iconName="Error" style={{ color: "#eddb00", marginRight: 10 }} />
            {t("tpfile:filesave.finish.newActaNotUpdatedLbl")}
          </>
        );
      else
        return (
          <>
            <Icon iconName="Error" style={{ color: "#eddb00", marginRight: 10 }} />
            {t("tpfile:filesave.finish.newCopyNotUpdatedLbl")}
          </>
        );
    }
    return t("tpfile:filesave.finish.donnoLbl");
  };

  return (
    <Stack className={classNames.toolsStacWhole}>
      <Stack.Item className={classNames.toolsStackName}>
        <SuccessMsg />
      </Stack.Item>
    </Stack>
  );
};

export default FileStoreFinishV4;
