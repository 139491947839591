import { yupResolver } from "@hookform/resolvers/yup";
import { log } from "missionlog";
import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { SchemaOf } from "yup";
import {
  limaQuery_getUserByEmail,
  limaQuery_postUser,
  limaQuery_postUser2UserLink,
} from "../../../../api/calls/limaUserCalls";
import { useDebounceFn } from "../../../../limaCommon/customHooks/useDebounce";
import { limaLogTag } from "../../../../limaCommon/limaLog";
import limaStore from "../../../../store/limaStore";
import { ICallUserInvite, ICallUser_withInvite, IUser, UserForm } from "../../../../types/User";
import { LimaControledTextField } from "../../../../limaComponents/LimaControledTextField";
import { DefaultButton, mergeStyleSets, Stack } from "@fluentui/react";
import { nameof } from "../../../../limaCommon/utils";
import limaUsersStore from "../../../../store/limaUsersStore";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Routes from "../../../Menu/routes";
import { User2User } from "../../../../types/User2User";
import { LIMA_TEXTNOTICETYPE, LimaNotice } from "../../../../limaComponents/LimaNotice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserInviteV4Props {}

const schema: SchemaOf<UserForm> = yup.object().shape({
  useremail: yup.string().email().lowercase().not([limaStore.userEmail.toUpperCase()], "This is your email"),
  username: yup.string().required("This is required field"),
  invitemessage: yup.string(),
  // userpassword: yup.string(),
});

type AddUserSubmitBtnType = {
  exist: string;
};

//const theme: ITheme = getTheme();
// const { palette, semanticColors, fonts } = theme;
//const { palette, fonts } = theme;

const classNames = mergeStyleSets({
  stackItem: [
    {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
    },
  ],
});

const UserInviteV4: React.FunctionComponent<IUserInviteV4Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // const [innerUserId, setInnerUserId] = useState<string | null>(null);
  const { mutate: mutateCheckEmail, data: dataCheckEmail } = useGetUserByEmailQuery();
  const { mutateAsync: mutateAskToLink } = useAskToLinkQuery();
  const { mutateAsync: mutateCreateUser } = useAskToCreateUserQuery();

  // const { handleSubmit, control, setValue, watch, formState } = useForm<UserForm>({
  const { handleSubmit, control, setValue, watch, formState } = useForm<UserForm>({
    defaultValues: {
      useremail: "",
      username: "",
      invitemessage: "",
    },
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  const valueOfTest = watch();

  //------------------
  // REACTIONS
  //------------------
  useEffect(() => {
    void limaUsersStore.checkLastRead();
  }, []);

  useEffect(() => {
    if (!dataCheckEmail) {
      log.warn(limaLogTag.UserAddEdit, "no data yet: ");
    } else {
      log.info(limaLogTag.UserAddEdit, "data User Query: ", dataCheckEmail);
      setValue("username", dataCheckEmail.userName ? dataCheckEmail.userName : "");
      //setValue("useremail", dataUserQuery.userEmail);
    }
  }, [dataCheckEmail]);

  //------------------
  // DEBAUCERS
  //-----------------

  useDebounceFn(
    valueOfTest.useremail,
    () => {
      // log.info(limaLogTag.UserAddEdit, "debounce call valueOfTest: ", valueOfTest.useremail);
      mutateCheckEmail(valueOfTest.useremail);
    },
    500
  );
  //-----------------
  // HANDLERS
  //-----------------

  const onStore = () => {
    void handleSubmit(
      (dataOnStore: UserForm) => {
        log.info(limaLogTag.UserAddEdit, "onStore: Valid data", dataOnStore);
        void mutateCreateUser({
          userEmail: dataOnStore.useremail,
          userName: dataOnStore.username,
          userId: null,
          lang: null,
          invitemessage: valueOfTest.invitemessage,
          companyKey: limaStore.userCompanyKey,
        })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then((_dataOnCreate: IUser) => {
            history.push(`${Routes.UserCreateUserFinishRoute}`);
            // setInnerUserId(dataOnCreate._key))
          })
          .catch(() => {
            log.error(limaLogTag.UserAddEdit, "on create user: error");
          });
      },
      (err) => {
        console.log(err);
        log.error(limaLogTag.UserAddEdit, "onStore: error data", err);
      }
    )();
  };

  const onAskToLink = () => {
    if (!dataCheckEmail) return;

    void mutateAskToLink({ otherUserId: dataCheckEmail.exists, invitemessage: valueOfTest.invitemessage }).then(
      (data: User2User) => {
        log.info(limaLogTag.UserAddEdit, "onAskToLink: asked succedd ", data);
        //setValue("useremail", "");
        history.push(`${Routes.UserInitateFinishRoute}`);
      }
    );
  };

  //----------------------
  // Render components
  //----------------------

  const SubmitButton = ({ exist }: AddUserSubmitBtnType): JSX.Element => {
    if (exist === undefined || exist === "") {
      return (
        <DefaultButton onClick={onStore} disabled={!formState.isValid}>
          {t("tpuser:adduser.addNewUserBtn")}
        </DefaultButton>
      );
    } else {
      const linked = limaUsersStore.fullList.filter((userInList) => userInList._key === exist).length;
      // log.info(
      //   limaLogTag.UserInviteV4,
      //   `check existend ${String(linked)} for '${String(exist)}'`,
      //   limaUsersStore.fullList
      // );
      return (
        <DefaultButton onClick={onAskToLink} disabled={!formState.isValid || linked > 0}>
          {linked > 0 ? t("tpuser:adduser.alreadyLinkedBtn") : t("tpuser:adduser.asktolinkBtn")}
        </DefaultButton>
      );
    }
  };

  return (
    <Stack>
      {limaStore.showTestingInfo && (
        <Stack.Item>
          <LimaNotice noticeType={LIMA_TEXTNOTICETYPE.INFO}>
            INFO:
            {`Exists: ${String(dataCheckEmail && dataCheckEmail.exists)} name?: ${String(
              dataCheckEmail && dataCheckEmail.userName && dataCheckEmail.userName
            )}`}
          </LimaNotice>
        </Stack.Item>
      )}
      <Stack.Item className={classNames.stackItem}>
        <LimaControledTextField
          limaLabel={t("tpuser:adduser.useremailLbl")}
          id="useremail"
          required={true}
          control={control}
          name={nameof<UserForm>("useremail")}
          autoFocus={true}
          disabled={false}
        />
      </Stack.Item>
      <Stack.Item className={classNames.stackItem}>
        <LimaControledTextField
          limaLabel={t("tpuser:adduser.usernameLbl")}
          id="username"
          required={true}
          control={control}
          name={nameof<UserForm>("username")}
          //autoFocus={true}
          disabled={dataCheckEmail && dataCheckEmail.exists !== ""}
        />
      </Stack.Item>
      <Stack.Item className={classNames.stackItem}>
        <LimaControledTextField
          limaLabel={t("tpuser:adduser.userInviteMessageLbl")}
          id="invitemessage"
          required={true}
          control={control}
          name={nameof<UserForm>("invitemessage")}
          multiline
          autoAdjustHeight
          //autoFocus={true}
          //disabled={dataCheckEmail && dataCheckEmail.exists !== ""}
        />
      </Stack.Item>
      <Stack.Item className={classNames.stackItem}>
        <SubmitButton exist={dataCheckEmail && dataCheckEmail.exists} />
      </Stack.Item>
    </Stack>
  );
};

export default UserInviteV4;

//----------------------
//   REACT QUERY
//----------------------

const useGetUserByEmailQuery = () => {
  return useMutation((email: string) => limaQuery_getUserByEmail(email), {
    onMutate: (email: string) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserInviteV4, "call mutate useGetUserByEmailQuery", email);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserInviteV4, "call mutate useGetUserByEmailQuery", data);
    },
    // onError: (error) => {
    //   // log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    // },
  });
};

const useAskToCreateUserQuery = () => {
  return useMutation(limaQuery_postUser, {
    onMutate: ({ userEmail, userName, invitemessage, companyKey }: ICallUser_withInvite) => {
      // A mutation is about to happen!
      log.info(
        limaLogTag.UserAddEdit,
        "call mutate useAskToCreateUserQuery ",
        userEmail,
        userName,
        invitemessage,
        companyKey
      );
      return { id: 1 };
    },
    onSuccess: (data: IUser) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToCreateUserQuery ", data);
      //Update listUsers
      limaUsersStore.addListSingleItem(data);
    },
  });
};

const useAskToLinkQuery = () => {
  return useMutation(limaQuery_postUser2UserLink, {
    onMutate: ({ otherUserId, invitemessage }: ICallUserInvite) => {
      // A mutation is about to happen!
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToLinkQuery ", otherUserId, invitemessage);
      return { id: 1 };
    },
    onSuccess: (data) => {
      log.info(limaLogTag.UserAddEdit, "call mutate useAskToLinkQuery ", data);
    },
    onError: (error) => {
      log.error(limaLogTag.UserAddEdit, "call mutate ", error);
    },
  });
};
