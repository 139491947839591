/**
 * Component shows single file item history timeFrame link
 *
 * @type Component
 *
 * @author MS
 */

import React = require("react");
// import { log } from "missionlog";
import {
  // getFocusStyle,
  getTheme,
  // IconButton,
  // IIconProps,
  ITheme,
  mergeStyleSets,
  Stack,
  // TextField,
} from "office-ui-fabric-react";
import { FunctionComponent } from "react";
import { XCOPY_TYPE } from "../../../../types/Task";
import { XcopyHistory } from "../../../../types/XcopyHistory";
// import LimaFileOpenComponent from "../../../../limaComponents/LimaFileOpenComponent";
// import LimaFileGetPDF from "../../../../limaComponents/LimaFileGetPDF";
import { LimaActionIconFileOpen } from "../../../../limaComponents/LimaActionIcons/LimaActionIconFileOpen";
import { LimaActionIconGetPDF } from "../../../../limaComponents/LimaActionIcons/LimaActionIconGetPDF";
import { onDownloadPFHandler } from "../../../../api/calls/LimaGetForDownload";
import { EXPORT_TYPE } from "../../../../types/Acta";
import { classNamesCommon } from "../../../../limaComponents/LimaComponentsCommonStyles";
// import { limaLogTag } from "../../../../limaCommon/limaLog";

// log.init({ FileItemOpenHistItem: "FileItemOpenHistItem" });

const theme: ITheme = getTheme();
//const { palette, fonts } = theme;
const { fonts } = theme;

const classNames = mergeStyleSets({
  // itemHistoryWrapper: {
  //   flexGrow: 1,
  // },
  // itemHistoryFileIdent: {
  //   flexGrow: 1,
  //   paddingLeft: 8,
  // },
  // itemHistoryFileActions: {},
  itemHistoryFileIdent_nameRows: {
    cursor: "default",
  },
  itemHistoryFileIdent_versions: [
    fonts.mediumPlus,
    {
      fontWeight: 600,
    },
  ],
  itemHistoryFileIdent_separator: [fonts.medium, {}],
  itemHistoryFileIdent_name: [fonts.medium, {}],
  itemHistoryFileIdent_note: [
    fonts.medium,
    {
      color: "#AEAEB2",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  ],
});

type IFileItemHistItemV4Props = {
  actaName: string;
  isActaOwner: boolean;
  isLast: boolean;
  historyItem: XcopyHistory;
  xcopyType: XCOPY_TYPE;
  // openFileHandler: (fileId: string, fileName: string, xCopyId?: string, timestamp?: string) => void;
  // openFileHandler: (fileId: string, fileName: string, xCopyId?: string, timeStamp?: string, xcopyName?: string, xcopyType?: XCOPY_TYPE, withoutLoadingFile?: boolean, setMenuToFile?: boolean, openInNewWindow?: boolean, runLimaOnOpenNewWindow?: boolean)
  onFileOpenClickHandler: (
    fileId: string,
    fileName: string,
    xCopyId?: string,
    timestamp?: string,
    xcopyName?: string,
    xcopyType?: XCOPY_TYPE,
    withoutLoadingFile?: boolean,
    setMenuToFile?: boolean,
    openInNewWindow?: boolean,
    runLimaOnOpenNewWindow?: boolean
  ) => void;
};

export const FileItemHistItemV4: FunctionComponent<IFileItemHistItemV4Props> = ({
  actaName,
  historyItem,
  onFileOpenClickHandler,
  xcopyType,
  isLast,
}: IFileItemHistItemV4Props) => {
  //----------------
  // Reactions
  //--------------

  //--------------------------------
  // Handler
  //----------------------

  return (
    <Stack horizontal className={classNamesCommon.LimaFileLists_itemWrapper}>
      <Stack.Item className={classNamesCommon.LimaFileLists_itemIdent}>
        <div className={classNames.itemHistoryFileIdent_nameRows}>
          <span
            className={classNames.itemHistoryFileIdent_versions}
          >{`${historyItem.versionMajor}.${historyItem.versionMinor}`}</span>
          <span className={classNames.itemHistoryFileIdent_separator}>{` | `}</span>
          <span className={classNames.itemHistoryFileIdent_name}>{`${
            historyItem.historyName === null ? "_" : historyItem.historyName
          }[${actaName}]`}</span>
        </div>
        <div className={classNames.itemHistoryFileIdent_nameRows}>
          <span className={classNames.itemHistoryFileIdent_note}>
            {historyItem.historyNote ? historyItem.historyNote : ""}
          </span>
        </div>
      </Stack.Item>
      <Stack.Item className={classNamesCommon.LimaFileLists_itemActions}>
        {!isLast ? (
          <LimaActionIconFileOpen
            onClick={() => {
              onFileOpenClickHandler(
                historyItem.actaId,
                actaName,
                historyItem.xcopyId,
                historyItem.historyTimeStamp,
                null,
                xcopyType,
                false,
                true,
                true,
                true
              );
            }}
          />
        ) : (
          <></>
        )}

        <LimaActionIconGetPDF
          onClick={() => {
            void onDownloadPFHandler(
              historyItem.actaId,
              historyItem.historyTimeStamp,
              historyItem.xcopyId,
              EXPORT_TYPE.PDF,
              actaName
            );
          }}
        />
      </Stack.Item>
    </Stack>
    // </div>
  );
};
export default FileItemHistItemV4;
