import { ITextField, ITextFieldProps, Label, mergeStyleSets, Stack, TextField } from "@fluentui/react";
// import { ITextField } from "office-ui-fabric-react";
import { forwardRef, ReactElement } from "react";
import React = require("react");
import { Control, Controller, FieldValues } from "react-hook-form";
// import { useTranslation } from "react-i18next";

type LimaSimpleTextFieldProps = {
  limaLabel?: string;
  horizontal?: boolean;
  onChangeSecCallBack?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  control?: Control<FieldValues, unknown>;
};

const classNames = mergeStyleSets({
  inputTextField: {
    selectors: {
      " .ms-TextField-fieldGroup": {
        border: "solid 1px #d6d6d6",
      },
    },
  },
});

const LimaSimpleTextField_inner = (
  // export const LimaSimpleTextField = (
  props: ITextFieldProps & LimaSimpleTextFieldProps,
  ref: React.Ref<ITextField>
): ReactElement => {
  return (
    <Stack horizontal={props.horizontal ? true : false}>
      {props.limaLabel && (
        <Stack.Item>
          <Label>{props.limaLabel} </Label>
        </Stack.Item>
      )}
      <Stack.Item grow={1}>
        {props.control === undefined ? (
          <TextField {...props} componentRef={ref} className={classNames.inputTextField} />
        ) : (
          <Controller
            render={({ field }) => <TextField className={classNames.inputTextField} {...field} />}
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
          />
        )}
        {/* <TextField {...props} componentRef={ref} className={classNames.inputTextField} onChange={onChangeHandler} /> */}
      </Stack.Item>
    </Stack>
  );
};

LimaSimpleTextField_inner.displayName = "LimaSimpleTextField";
export const LimaSimpleTextField = forwardRef(LimaSimpleTextField_inner);
